import { BaseSyntheticEvent } from 'react';
import useAppModal from 'hooks/useAppModal';
import { Property } from 'models/Properties';
import { FormContextParts } from 'components/forms/form/Form.types';
import ServiceProviderEditModal from './sections/modals/ServiceProviderBulkEditModal';
import { ServiceProvidersFormValues } from './PropertySettingsServiceProvidersTab.type';
import { serviceProviderEditModalId } from './PropertySettingsServiceProvidersTab.constants';
import useServiceProviderMutation from './sections/modals/useServiceProviderMutation';

const useServiceProvidersFormSubmit = ({
  property,
}: {
  property: Property;
}) => {
  const { openModal } = useAppModal();
  const { updateServiceProvider } = useServiceProviderMutation();

  return async (
    formValues: ServiceProvidersFormValues,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    { nativeEvent }: BaseSyntheticEvent<SubmitEvent> | undefined,
    {
      formState: { dirtyFields },
    }: FormContextParts<ServiceProvidersFormValues>,
  ) => {
    const isBulkSave = nativeEvent.submitter.id === 'bulk-save-button';

    if (isBulkSave) {
      openModal({
        id: serviceProviderEditModalId,
        customContent: (
          <ServiceProviderEditModal
            formValues={structuredClone(formValues)}
            dirtyFields={structuredClone(dirtyFields)}
            property={property}
          />
        ),
      });

      return undefined;
    }

    const payload = {
      updateContext: {
        applyToAll: false,
        propertyUids: [property.uid],
      },
      maintenanceNotes: {
        notes: formValues.serviceProviders.notes,
      },
      serviceProviderPropertyStatuses:
        formValues.serviceProviders.providers.map((sp) => ({
          serviceProviderUid: sp.uid,
          enabled: sp.isActive,
        })),
    };

    return updateServiceProvider(payload);
  };
};

export default useServiceProvidersFormSubmit;
