import styled from 'styled-components';

export const NotificationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 8px;

  p {
    font-weight: bold;
    font-size: 16px;
  }

  time {
    font-size: 14px;
  }
`;
