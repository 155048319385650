import { format } from 'date-fns';
import useOpenTemplateModal from 'components/domain/template/modal/useOpenTemplateModal';
import { TemplateName } from 'components/domain/template/modal/TemplateModal.types';
import { getUnitType } from 'utils/lead/leadUtils';
import { Property } from 'models/Properties';
import useCreateReadUpdateDeleteJob from './useJobActions';

export interface UseJobModalProps {
  modalId: string;
  jobUid?: string;
  availableProperties: Property[];
}

function formatDateForSaving(date: Date, time: Date) {
  return `${format(date, 'yyyy-MM-dd')}T${format(time, 'HH:mm:ss')}`;
}

const useJobModal = ({
  jobUid,
  modalId,
  availableProperties,
}: UseJobModalProps) => {
  const { createJob, updateJob, isUpdateType, deleteJob, isAnyRequestLoading } =
    useCreateReadUpdateDeleteJob({ modalId, jobUid });
  const { createTemplatedModal } = useOpenTemplateModal();

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleSubmit = async (formValues: any, { nativeEvent }) => {
    const { date, startTime, endTime, notes, currentPropertyUid, serviceUid } =
      formValues;

    const unitType = getUnitType(availableProperties, currentPropertyUid);

    const jobPayload = {
      status: 'NEW',
      propertyUid: unitType?.uid || currentPropertyUid,
      startLocalDateTime: formatDateForSaving(date, startTime),
      endLocalDateTime: formatDateForSaving(date, endTime),
      notes,
      serviceUid,
      ...(unitType && { unitUid: currentPropertyUid }),
    };

    if (isUpdateType) {
      updateJob({
        ...jobPayload,
        uid: jobUid,
      });
    } else {
      const job = await createJob(jobPayload);

      if (nativeEvent.submitter.id === 'submit-open-mailer') {
        createTemplatedModal({
          jobUid: job.uid,
          templateName: TemplateName.EMAIL_SERVICE_PROVIDER,
        });
      }
    }
  };

  return {
    handleSubmit,
    deleteJob,
    isAnyRequestLoading,
    isUpdateType,
  };
};

export default useJobModal;
