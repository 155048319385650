/* eslint-disable react/require-default-props */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { setHours } from 'date-fns';
import TextField from 'components/forms/textField/TextField';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';
import SelectField from 'components/forms/selectField/SelectField';
import { Service } from 'models/Service';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import { JobLeadParams } from 'models/Jobs';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import { Property } from 'models/Properties';
import { getJobEndDate, getJobStartDate } from 'utils/job/jobUtils';
import { JobWithService } from '../multiplesJobModal/MultiplesJobsModal';
import jobSchema from './JobModal.schema';
import {
  PropertyWrapper,
  FooterWrapper,
  DatePickerWrapper,
  Container,
  JobWrapper,
  ColWithMargin,
} from './JobModal.styles';
import useJobModal from './useJobModal';

interface JobModalProps {
  modalId: string;
  properties: Property[];
  services: Service[];
  job?: JobWithService;
  leadParams?: JobLeadParams;
}

const JobModal: React.FC<JobModalProps> = ({
  modalId,
  properties,
  services,
  job,
  leadParams,
}) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { handleSubmit, deleteJob, isAnyRequestLoading, isUpdateType } =
    useJobModal({ modalId, jobUid: job?.uid, availableProperties: properties });

  const defaultStartTime =
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    (isUpdateType && getJobStartDate(job)) || setHours(leadParams.jobDate, 12);

  const defaultEndTime =
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    (isUpdateType && getJobEndDate(job)) || setHours(leadParams.jobDate, 13);

  const defaultPropertyUid = isUpdateType
    ? // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      job.propertyUid
    : // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      leadParams.propertyUid;

  const defaultService = isUpdateType
    ? // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      services.find((service) => service.uid === job.serviceUid)?.uid
    : services[0]?.uid;

  return (
    <Container
      data-testid="create-update-job-test-id"
      id="create-update-job-form"
      key={`${JSON.stringify(job)}`}
      schema={jobSchema()}
      horizontal
      onSubmit={handleSubmit}
      defaultValues={{
        date: defaultStartTime,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
        notes: job?.notes || '',
        currentPropertyUid: defaultPropertyUid,
        serviceUid: defaultService || '',
        unitUid: job?.unitUid || '',
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {isUpdateType
            ? t('pageCalendar.stacked.editJob', {
                status: job?.status,
              })
            : t('pageCalendar.stacked.createAJob')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PropertyWrapper>
          <PropertySelectField
            colSmInput={6}
            colSmLabel={4}
            containerClassName="property-container"
            name="currentPropertyUid"
            pictureAsLabel
            properties={properties}
            enableHotels={false}
            enableUnitTypes={false}
            shouldShowUnitDefault
          />
        </PropertyWrapper>

        <JobWrapper>
          {defaultService && (
            <SelectField
              data-testid="job-modal-service"
              name="serviceUid"
              label={t('pageCalendar.stacked.service')}
              options={
                services?.map(({ uid, title }: any) => ({
                  value: uid,
                  label: title,
                })) || []
              }
              disabled={isUpdateType}
              required
            />
          )}

          {!defaultService && (
            <DatePickerWrapper>
              <ColWithMargin xs={2} textRight>
                <b>{t('pageCalendar.stacked.service')}</b>
              </ColWithMargin>
              <ColWithMargin xs={6} data-testid="job-modal-no-service-help">
                <Trans
                  i18nKey="componentLead.modal.form.noServiceHelp"
                  defaults="Please create a service <link1>here</link1>."
                  components={{
                    link1: (
                      // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                      <a
                        target="_blank"
                        data-testid="page-agency-setting"
                        href="#/agency-settings"
                      />
                    ),
                  }}
                />
              </ColWithMargin>
            </DatePickerWrapper>
          )}

          <DatePickerWrapper>
            <DateTimePickerField
              name="date"
              label={t('pageCalendar.stacked.date')}
            />
          </DatePickerWrapper>

          <DatePickerWrapper>
            <DateTimePickerField
              name="startTime"
              label={t('pageCalendar.stacked.startTime')}
              timeOnly
            />
          </DatePickerWrapper>

          <DatePickerWrapper>
            <DateTimePickerField
              name="endTime"
              label={t('pageCalendar.stacked.endTime')}
              timeOnly
            />
          </DatePickerWrapper>

          <TextField
            name="notes"
            label={t('pageCalendar.stacked.notes')}
            componentClass="textarea"
            rows="5"
          />
        </JobWrapper>
      </Modal.Body>

      <Modal.Footer>
        <FooterWrapper>
          <div>
            {isUpdateType && (
              <Button
                onClick={() => deleteJob()}
                bsStyle="danger"
                id="delete-button"
              >
                {t('pageCalendar.stacked.delete')}
              </Button>
            )}
          </div>

          <div>
            <Button onClick={() => closeModal(modalId)}>
              {t('pageCalendar.stacked.close')}
            </Button>
            <Button
              bsStyle="primary"
              type="submit"
              disabled={isAnyRequestLoading || !defaultService}
            >
              {isUpdateType
                ? t('pageCalendar.stacked.update')
                : t('pageCalendar.stacked.save')}
            </Button>
            {!isUpdateType && (
              <Button
                bsStyle="primary"
                id="submit-open-mailer"
                disabled={isAnyRequestLoading || !defaultService}
                type="submit"
              >
                {t(`componentLead.modal.form.createAndOpenMailer`)}
              </Button>
            )}
          </div>
        </FooterWrapper>
      </Modal.Footer>
    </Container>
  );
};

export default JobModal;
