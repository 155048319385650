import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DisplayField from 'pages/property/common/form/DisplayField';
import PriceRulesChannelsPerType from '../PriceRulesChannelsPerType';
import { PriceRulesAddEditFormValues } from './PriceRulesAddEditModal.schema';

const PriceRulesAddEditModalCompatibleChannels = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    priceRule: { type },
  } = useWatch<PriceRulesAddEditFormValues>();

  return (
    <DisplayField
      colSmInput={8}
      colSmLabel={4}
      colXsInput={8}
      colXsLabel={4}
      id="priceRule.channel"
      label={t('pageProperty.pricing.priceRules.modal.form.compatibleChannels')}
      value={<PriceRulesChannelsPerType type={type} />}
    />
  );
};

export default PriceRulesAddEditModalCompatibleChannels;
