import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { orderBy } from 'utils/arrayUtils';

const PROPERTY_PRICING_TYPE_ORDER = [
  PropertyPricingType.LOS_DISCOUNT,
  PropertyPricingType.LAST_MINUTE_DISCOUNT,
  PropertyPricingType.SHORT_STAY_PREMIUM,
  PropertyPricingType.EARLY_BIRD_DISCOUNT,
];

const PriceRulesAddEditModalTypeField = () => {
  const { t } = useTranslation();
  const sortedPropertyPricingType = orderBy(
    Object.values(PropertyPricingType),
    [
      {
        field: (type) => PROPERTY_PRICING_TYPE_ORDER.indexOf(type),
        order: 'asc',
      },
    ],
  );

  const options = sortedPropertyPricingType.map((value) => ({
    label: t(`pageProperty.pricing.priceRules.typeOptions.${value}`),
    value,
  }));

  return (
    <SelectField
      label={t('pageProperty.pricing.priceRules.modal.form.type')}
      name="priceRule.type"
      options={options}
      colSmInput={8}
      colSmLabel={4}
      required
    />
  );
};

export default PriceRulesAddEditModalTypeField;
