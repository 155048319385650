import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { usePropertiesCount } from 'components/domain/propertiesList/usePropertiesCount';
import getPropertiesQuery, {
  PropertiesQueryFilter,
} from 'utils/property/getPropertiesQuery';
import API from 'services/API';
import {
  GetPropertiesForPropertiesBodyResponseTO,
  PropertyForPropertiesBody,
} from '../Properties.types';
import { propertyForBodyQueryFields } from './PropertiesBody.constants';

export const PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY = 'properties-body';

interface QueryData {
  properties: PropertyForPropertiesBody[];
  offset: number;
}

type ReactQueryOptions = Omit<
  UseInfiniteQueryOptions<QueryData>,
  'queryKey' | 'queryFn'
>;

const useFetchPropertiesForPropertiesBody = (
  filters: Omit<PropertiesQueryFilter, 'offset' | 'limit'>,
  options?: ReactQueryOptions,
) => {
  const limit = 25;
  const { data: allPropertiesCount, isLoading } = usePropertiesCount();

  return useInfiniteQuery<QueryData>({
    queryKey: [PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY, filters],
    queryFn: async ({ pageParam: offset = 0 }) => {
      const response = await API.post<GetPropertiesForPropertiesBodyResponseTO>(
        '/v3/graphql',
        {
          operationName: 'PropertiesList',
          query: `{
            properties(${getPropertiesQuery({
              filters: { ...filters, offset, limit },
            })}) {
              ${propertyForBodyQueryFields}
            }
          }`,
        },
      );

      return {
        properties: response.data.data.properties ?? [],
        offset,
      };
    },
    getNextPageParam: (lastPage) => {
      const nextBatchOffset = lastPage.offset + limit;
      const hasNextPage =
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        nextBatchOffset < allPropertiesCount && lastPage.properties?.length > 0;
      return hasNextPage ? nextBatchOffset : undefined;
    },
    enabled: !isLoading,
    staleTime: 30000,
    ...(options ?? {}),
  });
};

export default useFetchPropertiesForPropertiesBody;
