import { useEffect, useState } from 'react';
import { isSameDay } from 'date-fns';
import { isBetween } from 'utils/dateTimeUtils';
import useCalendarMode from 'pages/stackedCalendar/useCalendarMode';
import { CalendarDayCellSelectionType } from './CalendaBodySelection.types';
import CalendarBodySelectionService from './CalendarBodySelectionService';

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function getSelectionTypeToSet(dayDate, min, max) {
  if (isSameDay(dayDate, min) && isSameDay(dayDate, max)) {
    return CalendarDayCellSelectionType.START_AND_END;
  }
  if (isSameDay(dayDate, min)) {
    return CalendarDayCellSelectionType.START;
  }
  if (isSameDay(dayDate, max)) {
    return CalendarDayCellSelectionType.END;
  }
  if (isBetween(dayDate, min, max)) {
    return CalendarDayCellSelectionType.ONGOING;
  }
  return null;
}

const useCalendarDayCellSelection = ({
  dayDate,
  propertyUid,
}: {
  dayDate: Date;
  propertyUid: string;
}) => {
  const { isPricingMode, isBookingMode, isCombinedMode } = useCalendarMode();
  const [selectionType, setSelectionType] =
    useState<CalendarDayCellSelectionType>();

  useEffect(() => {
    const subscription = CalendarBodySelectionService.subscribe((selection) => {
      if (selection && (isBookingMode || isCombinedMode)) {
        const { min, max, propertyUids } = selection;

        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        if (propertyUids.includes(propertyUid)) {
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          setSelectionType(getSelectionTypeToSet(dayDate, min, max));
          return;
        }
      }

      if (selection && isPricingMode) {
        const { min, max, propertyUids } = selection;

        if (
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          propertyUids.includes(propertyUid) &&
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          isBetween(dayDate, min, max)
        ) {
          setSelectionType(CalendarDayCellSelectionType.PRICING);
          return;
        }
      }

      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setSelectionType(null);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dayDate, propertyUid, isBookingMode, isPricingMode, isCombinedMode]);

  return { selectionType };
};

export default useCalendarDayCellSelection;
