import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import { PropertyBusinessType } from 'models/Properties';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import FormFooter from 'pages/property/common/FormFooter';
import {
  getFormDefaultValues,
  propertySettingsPricingTabSchema,
} from 'pages/property/pricing/PropertySettingsPricingTab.schema';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import PriceSettingsLoader from './PriceSettingsLoader';
import PriceSettingsFormElements from './PriceSettingsFormElements';
import { FormWithProviderStyled } from './PriceSettings.styles';
import PriceSettingsEventListeners from './PriceSettingsEventListeners';
import usePropertyPriceSettingsFormSubmit from './usePropertyPriceSettingsFormSubmit';
import useFetchPriceSettingsData from './useFetchPriceSettingsData';

const PriceSettings = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();
  const { isLoading: isFetchingPropertyMainSettings, data: priceSettingsData } =
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    useFetchPriceSettingsData({ propertyUid });
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onSubmit = usePropertyPriceSettingsFormSubmit(propertyUid);

  const isLoading = isLoadingInitData || isFetchingPropertyMainSettings;

  if (isLoading) {
    return <PriceSettingsLoader />;
  }

  const isUnitType = property?.businessType === PropertyBusinessType.UNIT_TYPE;

  return (
    <FormWithProviderStyled
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      defaultValues={getFormDefaultValues({ priceSettingsData })}
      disabled={!propertiesUpdatingAllowed}
      resolver={propertySettingsPricingTabSchema()}
      onSubmit={onSubmit}
      dirtyChecker
      noValidate
    >
      <fieldset disabled={!propertiesUpdatingAllowed}>
        <PriceSettingsEventListeners />
        <CollapseSectionStyled
          data-testid="price-settings"
          defaultExpanded
          label={t('pageProperty.pricing.priceSettings.title')}
          variant="card"
        >
          <PriceSettingsFormElements />
        </CollapseSectionStyled>
        <FormFooter
          noBulkSave={isUnitType}
          saveButtonLabel={t(
            'pageProperty.pricing.priceSettings.savePriceSettings',
          )}
        />
      </fieldset>
    </FormWithProviderStyled>
  );
};

export default PriceSettings;
