import { ReactNode } from 'react';
import { useFormState } from 'react-hook-form';

interface ManageLanguagesModalFieldsetProps {
  children: ReactNode;
}
const ManageLanguagesModalFieldset = ({
  children,
}: ManageLanguagesModalFieldsetProps) => {
  const { isSubmitting } = useFormState();
  return <fieldset disabled={isSubmitting}>{children}</fieldset>;
};

export default ManageLanguagesModalFieldset;
