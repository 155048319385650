import useAppMutation from 'hooks/useAppMutation';
import { PropertyAmenitiesUpdatePayload } from 'models/Amenity';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';

const useUpdatePropertyAmenities = () => {
  return useAppMutation({
    mutationFn: ({
      payload,
      property: { businessType, uid },
    }: {
      payload: PropertyAmenitiesUpdatePayload;
      property: Pick<PropertyBase, 'businessType' | 'uid'>;
    }) => {
      const uidType =
        businessType === PropertyBusinessType.HOTEL ? 'HOTEL' : 'PROPERTY';
      const resourceUid = uid;

      return API.post<BaseErrorResponse>(
        '/api/internal/amenities/batch-update',
        payload,
        {
          params: {
            uidType,
            resourceUid,
          },
        },
      );
    },
  });
};

export default useUpdatePropertyAmenities;
