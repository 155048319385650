import useAppModal from 'hooks/useAppModal';
import useConvertToUnitTypeMutation from './useConvertToUnitTypeMutation';
import { convertToUnitTypeModalId } from './ConvertToUnitTypeModal.constants';

const useConvertToUnitTypeModalForm = (propertyUid: string) => {
  const { closeModal } = useAppModal();
  const { mutate: convertToUnitType, isLoading: isConvertingToUnitType } =
    useConvertToUnitTypeMutation({
      onSuccess: () => closeModal(convertToUnitTypeModalId),
    });

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleSubmit = (formValues) => {
    convertToUnitType({ propertyUid, ...formValues });
  };

  return {
    handleSubmit,
    isUpdating: isConvertingToUnitType,
  };
};

export default useConvertToUnitTypeModalForm;
