import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';

type OverrideAirbnbPricingRulesArgs = {
  propertyUid: string;
  shouldOverrideAirbnbPricingRules: boolean;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<unknown, unknown, OverrideAirbnbPricingRulesArgs>,
  'mutationFn' | 'mutationKey'
>;

const overrideAirbnbPricingRules = ({
  propertyUid,
  shouldOverrideAirbnbPricingRules,
}: OverrideAirbnbPricingRulesArgs) => {
  return API.put(
    `/api/internal/pricing-rules/${propertyUid}/override-airbnb-pricing-rules`,
    { shouldOverrideAirbnbPricingRules },
  );
};

const useOverrideAirbnbPricingRules = (
  options: ReactQueryMutationOptions = {},
) => {
  return useAppMutation({
    mutationFn: overrideAirbnbPricingRules,
    ...options,
  });
};

export default useOverrideAirbnbPricingRules;
