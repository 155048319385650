import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getPaginationParams } from 'components/table/utils/pagination';
import { getSortParam } from 'components/table/utils/sorting';
import { GetHouseRulesResponse, HouseRule } from 'models/HouseRules';
import API from 'services/API';

export const HOUSE_RULES_BASE_QUERY_KEY = 'house-rules';

export const houseRulesQuery = ({
  propertyUid,
  pageIndex,
  sorting,
  pageSize,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    HOUSE_RULES_BASE_QUERY_KEY,
    'all',
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<HouseRule>> => {
    const sort = getSortParam(sorting, {
      description: 'SORT_BY_DESCRIPTION',
    });

    const response = await API.get<GetHouseRulesResponse>(
      '/api/internal/house-rules',
      {
        params: {
          objectUid: propertyUid,
          ...getPaginationParams({ pageIndex, pageSize }),
          ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
        },
      },
    );

    const { houseRules, _metadata } = response.data;

    return {
      _metadata,
      data: houseRules,
    };
  },
  enabled: !!propertyUid,
});
