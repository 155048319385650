import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getLabelSource,
  getLeadCheckInDate,
  getLeadCheckOutDate,
} from 'utils/lead/leadUtils';
import { Lead } from 'models/Leads';
import useAppConfig from 'hooks/useAppConfig';
import useAppUser from '../../../../hooks/useAppUser';
import RichTextEditor from '../../../../components/editor/RichTextEditor';
import InboxContext from '../../InboxContext';
import InboxMessagesContext from '../InboxMessagesContext';
import { normalizeKey } from '../../../../utils/localeUtils';
import {
  MessageSenderType,
  MessagingChannel,
} from '../../../../models/inbox/Messages';
import Tooltip from '../../../../components/tooltip/Tooltip';
import {
  InboxMessageEditorContainer,
  InboxMessageEditorErrorContainer,
  InboxMessageEditorTemplateSelectIcon,
} from './InboxMessageEditor.styles';
import useInboxAvailableMessagingChannels from './useInboxAvailableMessagingChannels';
import useSendInboxMessage from './useSendInboxMessage';
import useInboxMessageEditorAttachments from './useInboxMessageEditorAttachments';
import InboxMessageEditorAttachments from './InboxMessageEditorAttachments';
import useInboxMessageSuggestionMessage from './useInboxMessageSuggestionMessage';
import { ChatHistory } from './InboxMessageSuggestion.types';

function getSendButtonId(channel: MessagingChannel) {
  return `send-button-${channel}`;
}

const InboxMessageEditor = () => {
  const { isEmployee } = useAppUser();
  const { hasAiEnabled } = useAppConfig();
  const { activeLead } = useContext(InboxContext);
  const {
    selectedTemplate,
    setSelectedTemplate,
    toggleTemplateSelector,
    suggestion,
    setSuggestion,
    messages,
  } = useContext(InboxMessagesContext);
  const { t } = useTranslation();
  const { channels, defaultMessagingChannel, isLoadingMessagingChannels } =
    useInboxAvailableMessagingChannels();
  const { errorMessage, isSending, sendMessage } = useSendInboxMessage();
  const {
    attachments,
    isUploading: isUploadingAttachment,
    deleteAttachments,
    uploadAttachments,
  } = useInboxMessageEditorAttachments();
  const { isLoading: suggestionButtonLoading, getSuggestions } =
    useInboxMessageSuggestionMessage();

  const contents = selectedTemplate?.contents?.length
    ? selectedTemplate.contents[0].content
    : suggestion?.suggestedChatMessage || '';

  if (contents) {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setSelectedTemplate(null);
  }

  const buttonsDisabled = isSending || isUploadingAttachment;

  const buttons = channels.map((channel) => ({
    id: getSendButtonId(channel),
    disabled: buttonsDisabled,
    label: t(
      isSending
        ? 'pageInbox.messages.editor.sending'
        : normalizeKey(`pageInbox.messages.editor.channels.send_${channel}`),
    ),
    onClickHandler: (
      htmlContent: HTMLElement,
      clearEditorContents: () => void,
    ) => {
      const attachmentsToSend = attachments.filter(({ uri }) => !!uri);
      const cleanUpEditor = () => {
        clearEditorContents();
        const attachmentsUids = attachmentsToSend.map(({ uid }) => uid);
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        deleteAttachments(attachmentsUids);
      };
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setSuggestion(null);
      sendMessage(channel, htmlContent, cleanUpEditor, attachmentsToSend);
    },
  }));

  const buttonsSwitcher =
    buttons.length > 1
      ? {
          initialButtonId: getSendButtonId(defaultMessagingChannel),
          items: channels.map((channel) => ({
            buttonId: getSendButtonId(channel),
            label: t(
              normalizeKey(`pageInbox.messages.editor.channels.${channel}`),
            ),
          })),
        }
      : undefined;

  const toggleTemplateSelectorIcon = useMemo(
    () => (
      <Tooltip
        id="toggle-template-selector-icon-tooltip"
        text={t('pageInbox.messages.templates.selectTemplate')}
      >
        <InboxMessageEditorTemplateSelectIcon
          disabled={buttonsDisabled}
          data-testid="toggle-template-selector-icon"
          key="toggle-template-selector-icon"
          onClick={() => !buttonsDisabled && toggleTemplateSelector()}
        />
      </Tooltip>
    ),
    [buttonsDisabled],
  );

  const customIcons =
    isEmployee && !!activeLead
      ? [
          {
            id: 'toggle-template-selector',
            iconElement: toggleTemplateSelectorIcon,
          },
        ]
      : null;

  const getGlobalContext = (lead: Lead) => {
    if (!lead) {
      return '';
    }

    return `This booking has ${lead.adultCount} adults, ${
      lead.childrenCount
    } children, ${lead.infantCount} infants and ${
      lead.petCount
    } pets. The booking is coming from ${
      lead.channel?.bestProfile
    }. The check-in date is on ${getLeadCheckInDate(
      lead,
    )} and check-out date on ${getLeadCheckOutDate(
      lead,
    )}. The guest (user) email is ${lead.email}. The agreement has ${
      !lead.agreementSigned ? 'not' : ''
    } been signed. The pre-arrival form has ${
      !lead.agreementSigned ? 'not' : ''
    } been completed. the cost of this booking is ${
      lead.order?.totalAmount
    }. This booking is coming from ${getLabelSource(lead.source)}.`;
  };

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const globalContext = getGlobalContext(activeLead);

  const suggestionButtonDisabled =
    (messages.length > 0 &&
      messages[0].senderDetails?.senderType !== MessageSenderType.GUEST) ||
    suggestionButtonLoading ||
    messages.length === 0 ||
    !!suggestion;

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onAttachmentSelected = useCallback((event) => {
    uploadAttachments(event.target.files);
  }, []);

  const transformHtmlInText = (text: string) => {
    const div = document.createElement('div');
    div.innerHTML = text;

    // Remove style elements
    const styleElements = div.querySelectorAll('style');
    styleElements.forEach((styleElement) => {
      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      styleElement.parentNode.removeChild(styleElement);
    });

    return div.textContent || div.innerText || '';
  };

  const reverseMessages = messages.slice().reverse();

  const controlsSettings = {
    attachmentsAvailable: true,
    attachmentIconDisabled: buttonsDisabled,
    suggestAnswerAvailable:
      hasAiEnabled &&
      isEmployee &&
      !!messages.find(
        (message) =>
          message.senderDetails?.senderType === MessageSenderType.GUEST,
      ),
    buttons,
    buttonsSwitcher,
    customIcons,
    isLoading: isLoadingMessagingChannels,
    clearEditorButton: true,
    globalContext,
    activeLead,
    onAttachmentSelected,
    chatHistory: reverseMessages.slice(0, 30).map(
      (message) =>
        ({
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          message: transformHtmlInText(message.content.text),
          role:
            message.senderDetails?.senderType === MessageSenderType.AGENCY
              ? 'assistant'
              : 'user',
        } as ChatHistory),
    ),
    setSuggestion,
    suggestionButtonLoading,
    suggestionButtonDisabled,
    getSuggestions,
  };

  return (
    <InboxMessageEditorContainer>
      <RichTextEditor
        id="inbox-message-editor"
        contents={contents}
        placeholderText={t('pageInbox.messages.editor.typeMessagePlaceholder')}
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        controlsSettings={controlsSettings}
      />
      <InboxMessageEditorAttachments
        attachments={attachments}
        deleteAttachments={deleteAttachments}
      />
      {errorMessage && (
        <InboxMessageEditorErrorContainer>
          {errorMessage}
        </InboxMessageEditorErrorContainer>
      )}
    </InboxMessageEditorContainer>
  );
};

export default InboxMessageEditor;
