import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';

interface LegacyPageState {
  isLegacyPageLoading: boolean;
}

const initialState: LegacyPageState = {
  isLegacyPageLoading: true,
};

const legacyPageSlice = createSlice({
  name: 'legacyPage',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setIsLegacyPageLoading: (
      state,
      { payload: isLoading }: PayloadAction<boolean>,
    ) => {
      state.isLegacyPageLoading = isLoading;
    },
  },
});

export const { setIsLegacyPageLoading } = legacyPageSlice.actions;

export default legacyPageSlice.reducer;
