import { useCallback, useContext } from 'react';
import {
  addDays,
  addMonths,
  endOfMonth,
  getDate,
  getDay,
  getMonth,
  isSameDay,
  startOfMonth,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { PropertyBusinessType } from 'models/Properties';
import useDateTimeInternationalization from 'i18n/useDateTimeInternationalization';
import OffsetCell from '../cells/OffsetCell';
import DayStringCell from '../cells/DayStringCell';
import DayCell from '../cells/DayCell';
import DayCellUnit from '../cells/DayCellUnit';
import { calendarConstants } from '../Calendar.constant';
import StandaloneCalendarContext from '../CalendarContext';
import useJobsData from '../useJobsData';
import useLeadsData from '../useLeadsData';
import usePropertyCalendarsData from '../usePropertyCalendarsData';
import CalendarContext from '../../stackedCalendar/CalendarContext';
import useCalendarBodyMouseEvents from '../common/body/selection/useCalendarBodyMouseEvents';
import useHighlightUpdatedPricing from '../../stackedCalendar/body/days/useHighlightUpdatedPricing';
import { getLimitEndDate, getLimitStartDate } from '../Calendar.utils';
import {
  BodyWrapper,
  DayStringCellWrapper,
  Month,
  MonthInner,
  MonthsWrapper,
  MonthWrapper,
} from './Body.style';
import CalendarBodyContext from './CalendarBodyContext';
import { CalendarDay } from './CalendarBody.types';
import useStandaloneCalendarBodyUpdateEvents from './useStandaloneCalendarBodyUpdateEvents';

const Body = () => {
  useJobsData();
  useLeadsData();
  const { isLoading } = usePropertyCalendarsData();
  const { t } = useTranslation();
  const { abbreviatedWeekdays } = useDateTimeInternationalization();
  const { daysCalendar } = useContext(CalendarBodyContext);
  const { calendarFilter } = useContext(StandaloneCalendarContext);
  const { selectedProperty, mode, propertiesMap } = useContext(CalendarContext);
  // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const property = propertiesMap[selectedProperty];

  const selectionLimitProvider = useCallback(
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    (dayDate, propertyUid) => ({
      start: getLimitStartDate(
        dayDate,
        daysCalendar[propertyUid]?.daysCalendar,
        mode,
      ),
      end: getLimitEndDate(
        dayDate,
        daysCalendar[propertyUid]?.daysCalendar,
        mode,
      ),
    }),
    [daysCalendar, mode],
  );
  const { onMouseDown, onMouseUp } = useCalendarBodyMouseEvents(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    selectionLimitProvider,
  );
  useStandaloneCalendarBodyUpdateEvents();
  useHighlightUpdatedPricing();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const getOffset = (date) => {
    return getDay(startOfMonth(date));
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const getEmptyDayCells = (date) => {
    const offset = getOffset(date);

    const offsetElt = [];

    for (let i = 0; i < offset; i += 1) {
      offsetElt.push(<OffsetCell key={`offset_${i}`} />);
    }

    return offsetElt;
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const getDayStringCell = (date) => {
    const daysElt = [];
    const offset = getOffset(date);

    for (let l = 0; l < abbreviatedWeekdays.length; l += 1) {
      daysElt.push(
        <DayStringCell
          key={abbreviatedWeekdays[l]}
          hasBorder={offset <= l}
          str={abbreviatedWeekdays[l]}
        />,
      );
    }
    return daysElt;
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const getDayCell = (d) => {
    const calendarDay: CalendarDay = daysCalendar[ // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      selectedProperty
    ]?.daysCalendar
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      ?.find((dayCalendar) => isSameDay(dayCalendar.date, d));

    const key = `${getMonth(d)}_${getDate(d)}`;
    const isUnit = property?.businessType === PropertyBusinessType.UNIT;

    if (!calendarDay) {
      return (
        <DayCell
          key={key}
          calendarDay={
            // @ts-expect-error TS2352 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            {
              isLoading: true,
              date: d,
              leads: [],
              jobs: [],
            } as CalendarDay
          }
        />
      );
    }

    if (isUnit) {
      return <DayCellUnit key={key} calendarDay={calendarDay} />;
    }

    return <DayCell key={key} calendarDay={calendarDay} />;
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const getDays = (start, end) => {
    const daysArray = Array.from(Array(getDate(end)).keys());

    // @ts-expect-error TS7034 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const rows = [];

    daysArray.forEach(() => {
      rows.push(getDayCell(start));
      start = addDays(start, 1);
    });

    // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return rows;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BodyWrapper
      data-testid="body-wrapper"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <MonthsWrapper>
        {[...Array(calendarConstants.numberOfMonthsToDisplayOnCalendar)].map(
          (x, i) => {
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            const currentMonth = addMonths(calendarFilter.start, i);
            const start = startOfMonth(currentMonth);
            const end = endOfMonth(currentMonth);

            return (
              <MonthWrapper key={`${currentMonth.getMonth()}_month`}>
                <MonthInner>
                  {t('common.monthFullYear', { date: start })}
                </MonthInner>

                <DayStringCellWrapper>
                  {getDayStringCell(start)}
                </DayStringCellWrapper>

                <Month>
                  {getEmptyDayCells(start)}
                  {getDays(start, end)}
                </Month>
              </MonthWrapper>
            );
          },
        )}
      </MonthsWrapper>
    </BodyWrapper>
  );
};

export default Body;
