import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/table/CommonTable';
import { PropertyInternalFee } from 'models/PropertyFees';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import { propertyFeesQuery } from './PropertyFeesAndTaxes.utils';
import PropertyFeesAndTaxesHeader from './PropertyFeesAndTaxesHeader';

import ActionsCell from './cells/ActionsCell';
import AmountCell from './cells/AmountCell';
import ScopeCell from './cells/ScopeCell';
import AppliesToCell from './cells/AppliesToCell';
import TypeCell from './cells/TypeCell';

const PropertyFeesAndTaxes = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<PropertyInternalFee>();

  const columns = [
    columnHelper.accessor('name', {
      header: t('common.name'),
    }),
    columnHelper.accessor('type', {
      header: t('common.type'),
      cell: TypeCell,
    }),
    columnHelper.accessor('amount', {
      header: t('pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.value'),
      cell: AmountCell,
    }),
    columnHelper.accessor('taxationRate', {
      enableSorting: false,
      header: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.taxRate',
      ),
      cell: ({ getValue }) => `${getValue()}%`,
    }),
    columnHelper.accessor('scope', {
      header: t('pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scope'),
      cell: ScopeCell,
    }),
    columnHelper.display({
      id: 'appliesTo',
      header: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.appliesTo',
      ),
      cell: AppliesToCell,
    }),
    // actions column
    columnHelper.display({
      id: 'actions',
      header: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.actions',
      ),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getQuery: (options) => propertyFeesQuery({ propertyUid, ...options }),
    pageSize: 10,
  });

  return (
    <div data-testid="property-fees-and-taxes-section">
      <PropertyFeesAndTaxesHeader />
      <CommonTable
        tableInstance={tableInstance}
        isLoading={isLoading}
        emptyState={t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.emptyTable',
        )}
        data-testid="property-fees-and-taxes-table"
      />
    </div>
  );
};

export default PropertyFeesAndTaxes;
