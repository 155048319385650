import useCurrentLocale from 'i18n/useCurrentLocale';
import { getCountryFlagEmoji } from 'models/Country.utils';
import {
  CURRENCIES_WITHOUT_NONE,
  Currency,
  currencyToFlagCode,
  CurrencyWithoutNone,
} from 'models/Currency';
import { orderBy } from 'utils/arrayUtils';
import SelectField from '../selectField/SelectField';
import { SelectFieldProps } from '../selectField/SelectField.types';

export interface CurrencySelectFieldProps
  extends Omit<SelectFieldProps, 'options' | 'optionGroups'> {
  excludeCurrencies?: Currency[];
}

export interface CurrencyOption {
  value: CurrencyWithoutNone;
  label: string;
}

const CurrencySelectField = ({
  excludeCurrencies,
  ...props
}: CurrencySelectFieldProps) => {
  const locale = useCurrentLocale();
  const formatter = new Intl.DisplayNames(locale, { type: 'currency' });

  const options = CURRENCIES_WITHOUT_NONE.filter(
    (currency) => !excludeCurrencies?.includes(currency) ?? true,
  ).map((value) => {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const flagEmoji = getCountryFlagEmoji(currencyToFlagCode[value]);

    return { value, label: `${flagEmoji} ${formatter.of(value)}` };
  });

  const sortedOptions = orderBy(options, [
    { field: ({ label }) => label.substring(label.indexOf(' ')), order: 'asc' },
  ]);

  return <SelectField options={sortedOptions} {...props} />;
};

CurrencySelectField.defaultProps = {
  excludeCurrencies: undefined,
};

export default CurrencySelectField;
