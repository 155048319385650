import { useContext, useEffect, useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { addDays, startOfDay, subDays } from 'date-fns';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';
import { setVisibleDates } from 'store/slices/stackedCalendar';
import CalendarContext from '../CalendarContext';
import { stackedCalendarStyleConstants } from '../Calendar.constants';
import { StackedCalendarMode } from '../Calendar.types';
import { startDateOffsetByMode } from '../CalendarContextProvider';
import useStackedCalendarStartDate from '../useStackedCalendarStartDate';
import {
  NavigationButtonLeft,
  NavigationButtonRight,
  NavigationContainer,
  NavigationDatesInnerWrapper,
  NavigationDatesWrapper,
  NavigationDatesWrapperScrollable,
  NavigationDaysContainer,
} from './StackedCalendarNavigation.styles';
import NavigationDayCell from './NavigationDayCell';
import NavigationPropertyCell from './NavigationPropertyCell';
import NavigationMonths from './NavigationMonths';
import useStackedCalendarNavigationScroll from './useStackedCalendarNavigationScroll';
import { getDaysNumberWithinViewport } from './StackedCalendarNavigation.utils';

function createDatesArray(startDate: Date, itemsNumber: number) {
  return Array.from(new Array(itemsNumber)).map((_, index) =>
    addDays(startDate, index),
  );
}

const initialNumberOfDaysByMode = {
  [StackedCalendarMode.BOOKING]: 135,
  [StackedCalendarMode.PRICING]: 90,
  [StackedCalendarMode.COMBINED]: 90,
};

const StackedCalendarNavigation = () => {
  const dispatch = useAppDispatch();
  const { bodyWidth, mode } = useContext(CalendarContext);
  const visibleDates = useAppSelector(
    (state) => state.stackedCalendar.visibleDates,
  );
  const startDate = useStackedCalendarStartDate();
  const previousMode = usePrevious(mode);
  const scrollRef = useRef<HTMLDivElement>();
  const { scrollLeft, scrollRight, currentScrollPositionRef } =
    useStackedCalendarNavigationScroll(scrollRef);

  const cellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];
  const totalWidth = cellWidth * visibleDates.length;

  const dispatchGetLeadEvent = (dates: Date[]) => {
    const event = new CustomEvent('loadLeadsFromFilterDateEvent', {
      detail: { fromDate: dates[1], toDate: dates[dates.length - 2] },
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (bodyWidth && !visibleDates.length) {
      const daysNumberWithinViewPort = getDaysNumberWithinViewport({
        bodyWidth,
        mode,
      });
      const dates = createDatesArray(
        startDate,
        daysNumberWithinViewPort + initialNumberOfDaysByMode[mode],
      );

      dispatch(setVisibleDates(dates));
    }

    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const handleFilterEvent = (event) => {
      const { date } = event.detail;
      if (!date) return;

      const daysNumberWithinViewPort = getDaysNumberWithinViewport({
        bodyWidth,
        mode,
      });

      const filterStartDate = subDays(
        startOfDay(new Date(date)),
        startDateOffsetByMode[mode],
      );

      const dates = createDatesArray(
        filterStartDate,
        daysNumberWithinViewPort + initialNumberOfDaysByMode[mode],
      );

      dispatchGetLeadEvent(dates);
      dispatch(setVisibleDates(dates));
    };

    window.addEventListener('scrollToDateEvent', handleFilterEvent);

    return () => {
      window.removeEventListener('scrollToDateEvent', handleFilterEvent);
    };
  }, [bodyWidth, mode, visibleDates]);

  useEffect(() => {
    const currentScrollPosition = currentScrollPositionRef.current;
    const previousCellWidth =
      stackedCalendarStyleConstants.daysCellWidth[previousMode];
    const newCellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];

    const firstVisibleIndex = Math.floor(
      currentScrollPosition / previousCellWidth,
    );

    // when initially mounted, currentScrollPosition is 0
    if (currentScrollPosition) {
      const newScrollPosition = firstVisibleIndex * newCellWidth;
      (scrollRef.current?.firstChild as HTMLDivElement).scrollLeft =
        newScrollPosition;
    }
  }, [mode]);

  return (
    <NavigationContainer>
      <NavigationPropertyCell />
      <NavigationButtonLeft
        onClick={scrollLeft}
        data-testid="stacked-calendar-navigation-arrow-left"
      />
      {/*
       // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <NavigationDatesWrapper ref={scrollRef}>
        <ScrollSyncPane>
          <NavigationDatesWrapperScrollable data-testid="stacked-calendar-navigation-dates-scrollable">
            <NavigationDatesInnerWrapper $width={totalWidth}>
              <NavigationMonths scrollRef={scrollRef} />
              <NavigationDaysContainer data-testid="stacked-calendar-days-container">
                {visibleDates.map((date) => (
                  <NavigationDayCell
                    key={`nav-day-cell-${date.getTime()}`}
                    dayDate={date}
                    width={cellWidth}
                  />
                ))}
              </NavigationDaysContainer>
            </NavigationDatesInnerWrapper>
          </NavigationDatesWrapperScrollable>
        </ScrollSyncPane>
      </NavigationDatesWrapper>
      <NavigationButtonRight
        onClick={scrollRight}
        data-testid="stacked-calendar-navigation-arrow-right"
      />
    </NavigationContainer>
  );
};

export default StackedCalendarNavigation;
