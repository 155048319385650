import { useTranslation } from 'react-i18next';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { AvailableRule } from 'models/Rule';
import {
  AmenitiesList,
  AmenityCategoryCollapse,
} from '../CategorizedAmenities.styles';
import AmenityItem from '../AmenityItem';

const RulesSection = ({
  availableRules = [],
}: {
  availableRules: AvailableRule[];
}) => {
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();

  const amountOfColumns = isXS ? 2 : 3;
  const amountOfRows = Math.ceil(availableRules.length / amountOfColumns);

  if (!availableRules?.length) {
    return null;
  }

  return (
    <AmenityCategoryCollapse
      variant="card"
      label={t(`amenities.categories.RULES`)}
      defaultExpanded
      data-testid="amenities-collapse-RULES"
    >
      <AmenitiesList $amountOfRows={amountOfRows}>
        {availableRules?.map((rule, index) => {
          const isTheLastInItsRow = (index + 1) % 3 === 0;
          const columnPosition = index % 3;

          return (
            <AmenityItem
              columnPosition={columnPosition}
              isTheLastInItsRow={isTheLastInItsRow}
              key={rule.rule}
              amenity={rule}
            />
          );
        })}
      </AmenitiesList>
    </AmenityCategoryCollapse>
  );
};

export default RulesSection;
