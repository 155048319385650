import styled from 'styled-components';
import CommonTable from 'components/table/CommonTable';
import { AirbnbGuestReview } from 'models/Reviews';
import { ContainerTitle } from '../AirbnbGuestReviews.styles';

export const GuestReviewsContainer = styled(ContainerTitle)`
  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 16px;
  }
`;

export const GuestReviewsTableStyled = styled(CommonTable<AirbnbGuestReview>)`
  .column-creationDate {
    width: 120px;
    min-width: 120px;
  }

  .column-guestName {
    width: 150px;
    min-width: 150px;
  }

  .column-rating {
    width: 80px;
    min-width: 80px;
  }

  .column-publicReview {
    min-width: 200px;
    width: 100%;
  }

  .body-cell-guestName > button {
    padding: 0;
  }
`;
