import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import {
  PropertyPriceSettingsBulkUpdatePayload,
  PropertyPriceSettingsBulkUpdateResponse,
} from 'pages/property/pricing/sections/priceSettings/PriceSettings.types';
import API from 'services/API';

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    PropertyPriceSettingsBulkUpdateResponse,
    unknown,
    PropertyPriceSettingsBulkUpdatePayload
  >,
  'mutationFn' | 'mutationKey'
>;

const bulkUpdatePropertyPriceSettings = (
  payload: PropertyPriceSettingsBulkUpdatePayload,
) => {
  return API.post<PropertyPriceSettingsBulkUpdateResponse, BaseErrorResponse>(
    '/api/internal/properties/pricing-settings/bulk-update',
    payload,
  );
};

const useBulkUpdatePropertyPriceSettings = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: bulkUpdatePropertyPriceSettings,
    ...options,
  });
};

export default useBulkUpdatePropertyPriceSettings;
