import { useContext } from 'react';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import {
  LeadModalHeaderStyled,
  LeadModalHeaderContainer,
  LeadModalHeaderSection,
  LeadModalChannelIconContainer,
} from 'components/domain/lead/modal/LeadModalHeader.styles';
import LeadModalHeaderTitle from 'components/domain/lead/modal/LeadModalHeaderTitle';
import LeadCircle from 'components/domain/lead/status/LeadCircle';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';

const AirbnbAlterationRequestModalHeader = () => {
  const { lead } = useContext(AirbnbAlterationRequestModalContext);

  return (
    <LeadModalHeaderStyled>
      <LeadModalHeaderContainer>
        <LeadModalHeaderSection>
          {lead && (
            <>
              <LeadCircle lead={lead} />
              <LeadModalChannelIconContainer>
                {/*
                 // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                <LeadChannelIcon channel={lead.channel?.channel} />
              </LeadModalChannelIconContainer>
            </>
          )}
          <LeadModalHeaderTitle />
        </LeadModalHeaderSection>
      </LeadModalHeaderContainer>
    </LeadModalHeaderStyled>
  );
};

export default AirbnbAlterationRequestModalHeader;
