import useAppConfig from './useAppConfig';
import useAppUser from './useAppUser';
import useFavicon from './useFavicon';

const useAppFavicon = () => {
  const {
    customSettings: { faviconUrl },
  } = useAppConfig();
  const { isGuest, isOwner, isServiceProvider } = useAppUser();

  const faviconUrlToSet =
    isGuest || isOwner || isServiceProvider ? faviconUrl : null;
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  useFavicon(faviconUrlToSet);
};

export default useAppFavicon;
