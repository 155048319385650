import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export enum DefinitionRadioOptions {
  AGENCY = 'AGENCY',
  PROPERTY = 'PROPERTY',
}

export type EditRentalConditionsFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof editSchema>>
>;

export const editSchema = () => ({
  // appliesTo: Yup.string().required(),
  // appliesToProperties: Yup.array()
  //   .of(
  //     Yup.object({
  //       label: Yup.string(),
  //       value: Yup.string(),
  //       isFixed: Yup.boolean().optional(),
  //     }),
  //   )
  //   .label('properties')
  //   .when('bulkSave', {
  //     is: PROPERTIES_SELECTION.ALL_PROPERTIES,
  //     then: (schema) => schema.optional().nullable(true),
  //     otherwise: (schema) => schema.min(1).required(),
  //   }),
  rentalConditions: Yup.string().when('definition', {
    is: DefinitionRadioOptions.PROPERTY,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional().nullable(true),
  }),
  definition: Yup.mixed<
    DefinitionRadioOptions.AGENCY | DefinitionRadioOptions.PROPERTY
  >()
    .oneOf([DefinitionRadioOptions.AGENCY, DefinitionRadioOptions.PROPERTY])
    .required(),
});
