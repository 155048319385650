import useAppMutation from 'hooks/useAppMutation';
import { GetImportablePropertiesResponseTO } from 'models/bookingDotCom/ImportableProperties';
import API from 'services/API';

const useBookingDotComOperationsImportImportablePropertiesMutation = () => {
  const { mutate: getImportableProperties, isLoading } = useAppMutation(
    (_: { successCallback?: Function }) => {
      return API.get<GetImportablePropertiesResponseTO>(
        `/api/internal/bookingdotcom-operations/importable-properties`,
      );
    },
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.([...response.data]);
      },
    },
  );

  return { isLoading, getImportableProperties };
};

export default useBookingDotComOperationsImportImportablePropertiesMutation;
