import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SidePanel from 'components/sidePanel/SidePanel';
import { SidePanelDirection } from 'components/sidePanel/SidePanel.types';
import useSidePanel from 'components/sidePanel/useSidePanel';
import { normalizeKey } from 'utils/localeUtils';
import type { NavigationProps } from '../Header.types';
import Footer from '../../footer/Footer';
import ProfileAvatar from '../profileAvatar/ProfileAvatar';
import NavigationLogo from '../navigation/NavigationLogo';
import AddLeadButton from '../navigation/AddLeadButton';
import useNavigationDropdownTitle from '../useNavigationDropdownTitle';
import useNavigationMethods from '../useNavigationMethods';
import {
  Container,
  HamburgerMenuButton,
  MenuItem,
  DropdownTrigger,
  DropdownMenu,
  AccountContainer,
  CloseButton,
} from './MobileNavigation.styles';

const sidePanelId = 'mobile-navigation-panel';

const MobileNavigation = ({
  navItems,
  dropdownItems,
  dropdownSecondaryItems,
}: NavigationProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { toggleSidePanel, closeSidePanel, triggerClassName } = useSidePanel({
    sidePanelId,
  });
  const { isDropdownActive, isNavItemActive } = useNavigationMethods();
  const navDropdownTitle = useNavigationDropdownTitle();

  const handleCloseMenu = () => {
    closeSidePanel();
    setIsDropdownOpen(false);
  };

  const redirectTo = (path: string) => {
    navigate(path.slice(1));
    handleCloseMenu();
  };

  return (
    <Container data-testid="mobile-navigation" id="webapp-header">
      <NavigationLogo />
      <HamburgerMenuButton
        data-testid="mobile-navigation-menu-icon"
        className={triggerClassName}
        onClick={toggleSidePanel}
      />

      <SidePanel
        data-testid="mobile-navigation-menu-container"
        direction={SidePanelDirection.RIGHT_TO_LEFT}
        sidePanelId={sidePanelId}
      >
        <AccountContainer>
          <div>
            <ProfileAvatar onClick={handleCloseMenu} />
            <AddLeadButton onMobileClick={handleCloseMenu} />
            <CloseButton
              onClick={handleCloseMenu}
              data-testid="mobile-navigation-close-icon"
            />
          </div>
        </AccountContainer>
        {navItems.map((item) => (
          <MenuItem
            active={isNavItemActive(item.path)}
            key={item.path}
            onClick={() => redirectTo(item.path)}
          >
            {t(normalizeKey(item.labelKey))}
          </MenuItem>
        ))}

        <DropdownTrigger
          isOpen={isDropdownOpen}
          onClick={() => setIsDropdownOpen((s) => !s)}
          active={isDropdownActive({ dropdownItems, dropdownSecondaryItems })}
        >
          <div>{navDropdownTitle}</div>
        </DropdownTrigger>
        <DropdownMenu
          itemsAmount={
            (dropdownItems?.length || 0) + (dropdownSecondaryItems?.length || 0)
          }
          isOpen={isDropdownOpen}
        >
          {dropdownItems?.map((item) => (
            <MenuItem
              active={isNavItemActive(item.path)}
              key={item.path}
              onClick={() => redirectTo(item.path)}
            >
              {t(normalizeKey(item.labelKey))}
            </MenuItem>
          ))}
          {dropdownSecondaryItems?.map((item) => (
            <MenuItem
              active={isNavItemActive(item.path)}
              key={item.path}
              onClick={() => redirectTo(item.path)}
            >
              {t(normalizeKey(item.labelKey))}
            </MenuItem>
          ))}
        </DropdownMenu>
        <Footer data-testid="mobile-navigation-footer" />
      </SidePanel>
    </Container>
  );
};

export default MobileNavigation;
