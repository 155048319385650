import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { useFormContext } from 'react-hook-form';
import { Nav } from 'react-bootstrap';
import useAnalyticsData from 'pages/analytics/useAnalyticsData';
import AnalyticsContext from 'pages/analytics/AnalyticsContext';
import { isCardDataApplicable } from 'pages/analytics/Analytics.utils';
import { AnalyticsKeyMetric } from 'pages/analytics/types/AnalyticsKeyMetrics.types';
import {
  ChartType,
  FinancialPerformanceMetric,
  PropertyPerformanceMetric,
  TeamPerformanceMetric,
} from '../Charts.types';
import { ChartsFilterFormWrapper } from './ChartsFilters.styles';

const ChartsFilters = () => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { type, metric, offset, limit } = getValues();
  const [chartsDataOptions, setChartsDataOptions] = useState([]);
  const { allProperties, filter } = useContext(AnalyticsContext);
  const {
    isPropertyLoading,
    propertyError,
    isInitialPropertyLoading,
    propertyData,
  } = useAnalyticsData();

  const chartsTypeOptions = Object.values(ChartType).map(
    (chartType: ChartType) => ({
      label: t(`pageAnalytics.chart.type.${chartType}`),
      value: chartType,
    }),
  );

  const onNext = () => {
    setValue('offset', offset + limit);
  };

  const onPrevious = () => {
    setValue('offset', offset - limit);
  };

  useEffect(() => {
    if (type === ChartType.FINANCIAL_PERFORMANCE) {
      setChartsDataOptions(
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        Object.values(FinancialPerformanceMetric).map(
          (data: FinancialPerformanceMetric) => ({
            label: t(`pageAnalytics.chart.metric.${data}`),
            value: data,
          }),
        ),
      );
      setValue(
        'metric',
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        FinancialPerformanceMetric[metric]
          ? metric
          : FinancialPerformanceMetric.RENTAL_REVENUE,
      );
    }

    if (type === ChartType.PROPERTY_PERFORMANCE) {
      setChartsDataOptions(
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        Object.values(PropertyPerformanceMetric)
          .filter((ppm) =>
            isCardDataApplicable(filter, ppm as unknown as AnalyticsKeyMetric),
          )
          .map((data: PropertyPerformanceMetric) => ({
            label: t(`pageAnalytics.chart.metric.${data}`),
            value: data,
          })),
      );
      setValue(
        'metric',
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        PropertyPerformanceMetric[metric] &&
          isCardDataApplicable(filter, metric)
          ? metric
          : PropertyPerformanceMetric.RENTAL_REVENUE,
      );
      setValue('offset', 0);
    }

    if (type === ChartType.TEAM_PERFORMANCE) {
      setValue(
        'metric',
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        TeamPerformanceMetric[metric]
          ? metric
          : TeamPerformanceMetric.LEADS_ASSIGNED,
      );

      setChartsDataOptions(
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        Object.values(TeamPerformanceMetric).map(
          (data: TeamPerformanceMetric) => ({
            label: t(`pageAnalytics.chart.metric.${data}`),
            value: data,
          }),
        ),
      );
    }
  }, [type, filter]);

  return (
    <ChartsFilterFormWrapper>
      <SelectField
        inline
        name="type"
        options={chartsTypeOptions}
        className="btn btn-chart-type"
        required
      />
      <SelectField
        inline
        name="metric"
        options={chartsDataOptions}
        className="btn btn-chart-metric"
        required
      />

      {type === ChartType.PROPERTY_PERFORMANCE &&
        // @ts-expect-error TS2532 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyData?.reduce(
          // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          (acc, metricValue) => [...acc, metricValue.propertyUid],
          [],
        ) > limit &&
        !propertyError &&
        !isInitialPropertyLoading && (
          <Nav>
            <ul className="pager">
              <li className="previous">
                <button
                  disabled={offset === 0 || isPropertyLoading}
                  type="button"
                  className="btn btn-link"
                  onClick={onPrevious}
                >
                  <span aria-hidden="true">&larr;</span>
                  {t(`pageAnalytics.previous`)}
                </button>
              </li>
              <li className="next">
                <button
                  disabled={
                    offset + limit > allProperties.length || isPropertyLoading
                  }
                  type="button"
                  className="btn btn-link"
                  onClick={onNext}
                >
                  {t(`pageAnalytics.next`)}
                  <span aria-hidden="true">&rarr;</span>
                </button>
              </li>
            </ul>
          </Nav>
        )}
    </ChartsFilterFormWrapper>
  );
};

export default ChartsFilters;
