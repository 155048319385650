import { useTranslation } from 'react-i18next';
import { Service } from 'models/order/Service';
import {
  HasOrderItems,
  HasServiceItem,
} from 'models/hasOrderItems/HasOrderItems';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Services = ({ order }: { order: HasOrderItems }) => {
  const { t } = useTranslation();
  const { services, currency } = order;

  const getServices = (otherServices: HasServiceItem[]): PriceDetailsItem[] => {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return otherServices.map((service: Service) => ({
      label: service.title,
      amount: service.grossPrice,
      currency,
    }));
  };

  return (
    <PriceDetailsList data-testid="price-details-list-services">
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.services')}
        priceDetailsItems={[
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          ...getServices(services),
          {
            label: t('componentOrder.totalServices'),
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            amount: services.reduce((acc, fee) => acc + fee.grossPrice, 0),
            isTotalAmount: true,
            currency,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default Services;
