import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import {
  PricingRuleBulkDeletePayload,
  PricingRuleBulkDeleteResponse,
} from 'models/pricing/PricingRule';
import API from 'services/API';

type BulkDeletePricingRuleArgs = PricingRuleBulkDeletePayload & {
  uid: string;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    PricingRuleBulkDeleteResponse,
    unknown,
    BulkDeletePricingRuleArgs
  >,
  'mutationFn'
>;

const bulkDeletePricingRule = ({
  uid,
  ...payload
}: BulkDeletePricingRuleArgs) => {
  return API.post<PricingRuleBulkDeleteResponse, BaseErrorResponse>(
    `/api/internal/pricing-rules/${uid}/bulk-delete`,
    payload,
  );
};

const useBulkDeletePricingRule = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: bulkDeletePricingRule,
    ...options,
  });
};

export default useBulkDeletePricingRule;
