import { useTranslation } from 'react-i18next';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import RangeField, {
  RangeFieldProps,
} from 'components/forms/rangeField/RangeField';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';

interface BedroomsRangeFieldProps extends Omit<RangeFieldProps, 'min' | 'max'> {
  filterOptions?: PropertiesFilterOptions;
}

const BedroomsRangeField = ({
  filterOptions: filterOptionsProp,
  ...rest
}: BedroomsRangeFieldProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPropertiesFilterOptions({
    enabled: !filterOptionsProp,
  });
  const filterOptions = filterOptionsProp ?? data;

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <RangeField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.bedrooms')}
      // @ts-expect-error TS2783 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      name="bedrooms"
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      min={filterOptions?.minBedrooms}
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      max={filterOptions?.maxBedrooms}
      {...rest}
    />
  );
};

BedroomsRangeField.defaultProps = {
  filterOptions: undefined,
};

export default BedroomsRangeField;
