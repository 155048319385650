import { useContext, useEffect, useState } from 'react';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import {
  AvailableTemplate,
  GetTemplatesResponseTO,
  TemplateType,
} from '../../../../models/Templates';
import useAppQuery from '../../../../hooks/useAppQuery';
import API from '../../../../services/API';
import useAppUser from '../../../../hooks/useAppUser';
import InboxContext from '../../InboxContext';
import InboxMessagesContext from '../InboxMessagesContext';

const INCOMPLETE_TEMPLATE_ERROR = {
  'Rental Agreement Notification':
    'componentTemplate.incompleteTemplates.RENTAL_AGREEMENT_NOTIFICATION',
};

const useSelectInboxMessageTemplate = () => {
  const [requestedTemplate, setRequestedTemplate] =
    useState<AvailableTemplate>();
  const { notifyError } = useNotify();
  const { isEmployee, agencyUid } = useAppUser();
  const { activeLead } = useContext(InboxContext);
  const { setSelectedTemplate, toggleTemplateSelector } =
    useContext(InboxMessagesContext);
  const { t } = useTranslation();

  const { uid: leadUid, property } = activeLead || {};
  const { uid: propertyUid } = property || {};

  const { data: fetchedTemplate, isInitialLoading: isLoadingTemplate } =
    useAppQuery(
      ['template', agencyUid, leadUid, propertyUid, requestedTemplate],
      async () => {
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const { name, templateType, uid } = requestedTemplate;
        const baseParams = {
          agencyUid,
          leadUid,
          propertyUid,
          filled: true,
        };
        const params = uid // uid is supposed to be present for custom templates only
          ? {
              ...baseParams,
              uid,
              templateType: TemplateType.CUSTOM_TEMPLATE,
            }
          : {
              ...baseParams,
              name,
              templateType,
            };

        const response = await API.get<GetTemplatesResponseTO>(
          '/v3/templates',
          { params },
        );

        return response.data.templates[0];
      },
      {
        enabled: isEmployee && !!leadUid && !!requestedTemplate,
        onError: (_err: any) => {
          if (_err.response?.status === 409) {
            // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            if (INCOMPLETE_TEMPLATE_ERROR[requestedTemplate.name]) {
              // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              notifyError(t(INCOMPLETE_TEMPLATE_ERROR[requestedTemplate.name]));
            }
          }
        },
      },
    );

  useEffect(() => {
    if (fetchedTemplate) {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setRequestedTemplate(null);
      setSelectedTemplate(fetchedTemplate);
      toggleTemplateSelector();
    }
  }, [fetchedTemplate]);

  const selectTemplate = (template: AvailableTemplate) => {
    setRequestedTemplate(template);
  };

  return { isLoadingTemplate, selectTemplate };
};

export default useSelectInboxMessageTemplate;
