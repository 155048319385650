import styled, { css } from 'styled-components';
import { Lead } from 'models/Leads';
import theme from 'styles/theme';
import {
  getColorSource,
  getOpacity,
  getZIndexFromOpacity,
} from 'utils/lead/leadUtils';
import { rowWidthDynamicFormula } from '../LeadView.utils';
import { CalendarDayCellSelectionType } from '../common/body/selection/CalendaBodySelection.types';

export const LeadViewWrapper = styled.div<{
  $date: Date;
  $lead: Lead;
  $isCheckinVisible: boolean;
  $isCheckoutVisible?: boolean;
}>`
  height: 30px;
  position: absolute;
  top: 33px;
  left: 0px;
  ${(props) => props.$isCheckinVisible && `left: 40%`};
  ${(props) =>
    `width: ${rowWidthDynamicFormula(
      props.$date,
      props.$lead,
      props.$isCheckinVisible,
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      props.$isCheckoutVisible,
    )}`}
`;

export const SelectionPricingViewContainer = styled.div.attrs({
  'data-testid': 'day-cell-pricing-selected',
})`
  background-color: transparent;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 10;
  border: 2px solid ${theme.colors.hostfullyBlue};
`;

export const SelectionViewContainer = styled.div<{
  $selectionType: CalendarDayCellSelectionType;
}>`
  background-color: #fff;
  height: 50%;
  position: absolute;
  top: 33px;

  ${({ $selectionType }) =>
    $selectionType === CalendarDayCellSelectionType.END
      ? css`
          left: 0;
          width: 17%;

          & > div {
            width: 100%;
          }
        `
      : ''}
  ${({ $selectionType }) =>
    $selectionType === CalendarDayCellSelectionType.ONGOING
      ? css`
          left: 0;
          width: 100%;
        `
      : ''}
  ${({ $selectionType }) =>
    $selectionType === CalendarDayCellSelectionType.START
      ? css`
          right: 0;
          width: 83%;

          & > div {
            width: 74%;
          }
        `
      : ''}
  ${({ $selectionType }) =>
    $selectionType === CalendarDayCellSelectionType.START_AND_END
      ? css`
          left: auto;
          right: auto;
          width: calc(100% - 10px);

          & > div {
            width: 100%;
          }
        `
      : ''}
`;

export const SelectionViewWrapper = styled.div<{
  $lead: Lead;
  $selectionStart: boolean;
  $selectionEnd: boolean;
  $inSelectionRange?: boolean;
  $isSelectionOnSameDay?: boolean;
}>`
  height: 30px;
  position: absolute;
  top: 0;
  height: 30px;
  ${({ $lead }) => $lead && `opacity: ${getOpacity($lead.status)};`}
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  ${({ $lead }) => $lead && `background: ${getColorSource(null, $lead)};`}
  ${({ $inSelectionRange }) =>
    $inSelectionRange && `width: 100%; border-radius: 0px;`};
  ${({ $selectionStart, $selectionEnd }) =>
    $selectionStart &&
    $selectionEnd &&
    `width: calc(100% - 10px); right: auto; left: auto; border-radius: 5px`};
  ${({ $selectionStart, $selectionEnd }) =>
    $selectionStart &&
    !$selectionEnd &&
    `width: 37%; right: 0; left: auto; border-radius: 5px 0 0 5px`};
  ${({ $selectionStart, $selectionEnd }) =>
    $selectionEnd &&
    !$selectionStart &&
    `width: 17%; right: auto; left: 0; border-radius: 0 5px 5px 0px`};
  ${({ $isSelectionOnSameDay }) =>
    $isSelectionOnSameDay &&
    `width: calc(100% - 10px); right: auto; left: auto; border-radius: 5px`};
`;

export const LeadSpan = styled.span<{
  $isFromICS: boolean;
  $calendarStyleConstants: any;
}>`
  font-style: normal;
  font-size: 18px;
  line-height: 33px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  ${({ $calendarStyleConstants }) =>
    $calendarStyleConstants &&
    `color:${$calendarStyleConstants.leadLabelColor.default};`}
  ${({ $isFromICS, $calendarStyleConstants }) =>
    $isFromICS && `${$calendarStyleConstants.leadLabelColor.ICS}`}
`;

export const LeadViewBody = styled.div.attrs({
  className: 'lead-view-body-standalone',
})<{
  $lead?: Lead;
  $isCheckinVisible?: boolean;
  $isCheckoutVisible?: boolean;
}>`
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  ${({ $lead }) => $lead && `background: ${getColorSource(null, $lead)};`}
  ${({ $lead }) => $lead && `opacity: ${getOpacity($lead.status)};`}
  ${({ $lead }) =>
    $lead && `z-index: ${getZIndexFromOpacity(getOpacity($lead.status))};`}
  ${({ $isCheckinVisible }) =>
    $isCheckinVisible && `border-bottom-left-radius: 5px;`}
  ${({ $isCheckinVisible }) =>
    $isCheckinVisible && `border-top-left-radius: 5px;`}
  ${({ $isCheckoutVisible }) =>
    $isCheckoutVisible && `border-top-right-radius: 5px;`}
  ${({ $isCheckoutVisible }) =>
    $isCheckoutVisible && `border-bottom-right-radius: 5px;`}
`;
