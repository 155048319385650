import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import PipelineContext from '../PipelineContext';
import usePrevious from '../../../hooks/usePrevious';
import { areArraysEqual } from '../../../utils/arrayUtils';
import { convertPipelineFilterFormValues } from './PipelineFilters.utils';

const PipelineFilterFormWatcher = () => {
  const { setFilter } = useContext(PipelineContext);
  const { lead } = useWatch();
  const previousLead = usePrevious(lead);

  useEffect(() => {
    // prevents running the search on initial render and makes sure that the search is run on every further user interaction, including changing back to initial value
    // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const leadUids = lead?.map(({ uid }) => uid) || [];
    // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const previousLeadUids = previousLead?.map(({ uid }) => uid) || [];

    if (!areArraysEqual(leadUids, previousLeadUids)) {
      setFilter((currentFilter) => ({
        ...currentFilter,
        ...convertPipelineFilterFormValues({ lead }),
      }));
    }
  }, [lead]);

  return null;
};

export default PipelineFilterFormWatcher;
