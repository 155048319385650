import PencilSquare from 'assets/images/icons/pencil-square.svg?react';
import { useTranslation } from 'react-i18next';
import { AvailableAmenity } from 'models/Amenity';
import { AvailableRule } from 'models/Rule';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import Tooltip from 'components/tooltip/Tooltip';
import PropertyEditActionWrapper from '../../../../common/PropertyEditActionWrapper';
import { AmenityDescriptionIconContainer } from './AmenityDescription.styles';
import useEditAmenityDescriptionModal from './useEditAmenityDescriptionModal';

const AmenityDescriptionIcon = ({
  amenity,
}: {
  amenity: AvailableAmenity | AvailableRule;
}) => {
  const type = 'amenityType' in amenity ? amenity.amenityType : amenity.rule;
  const { openEditDescriptionModal } = useEditAmenityDescriptionModal(amenity);
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();
  const { t } = useTranslation();

  const tooltipId = `edit-description-tooltip-${type}`;

  const icon = (
    <AmenityDescriptionIconContainer
      $disabled={!propertiesUpdatingAllowed}
      onClick={propertiesUpdatingAllowed ? openEditDescriptionModal : undefined}
      data-testid={`amenity-description-icon-${type}`}
    >
      <PencilSquare />
    </AmenityDescriptionIconContainer>
  );

  const iconWrapped = propertiesUpdatingAllowed ? (
    <Tooltip
      text={t('pageProperty.amenities.editDescriptionTooltip')}
      id={tooltipId}
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  );

  return (
    <PropertyEditActionWrapper tooltipId={tooltipId}>
      {iconWrapped}
    </PropertyEditActionWrapper>
  );
};

export default AmenityDescriptionIcon;
