import { useContext } from 'react';
import Button from 'components/button/Button';
import CalendarContext from '../CalendarContext';
import { PropertyBusinessType } from '../../../models/Properties';
import {
  PropertyListItemAvatar,
  PropertyListItemWrapper,
} from './PropertiesList.styles';
import PropertyListItemName from './PropertyListItemName';
import PropertyListItemEditIcon from './PropertyListItemEditIcon';
import PropertyListItemExpandIcon from './PropertyListItemExpandIcon';

const PropertyListItem = ({ propertyUid }: { propertyUid: string }) => {
  const { propertiesMap, setSelectedProperty } = useContext(CalendarContext);
  const property = propertiesMap[propertyUid];
  const {
    name,
    mainPicture,
    businessType,
    numberOfSubUnits,
    unitTypes,
    units,
  } = property;

  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const isSubUnit = businessType === PropertyBusinessType.SUB_UNIT;
  const isUnitType = businessType === PropertyBusinessType.UNIT_TYPE;
  const isUnit = businessType === PropertyBusinessType.UNIT;

  const canBeExpanded =
    (isHotel && !!unitTypes?.length) ||
    (isUnitType && !!units?.length) ||
    numberOfSubUnits > 0;

  const selectProperty = () => {
    if (isHotel) return;

    setSelectedProperty(propertyUid);
  };

  return (
    <PropertyListItemWrapper
      className="property-list-item"
      data-property-uid={propertyUid}
      data-testid={`property-list-item-${propertyUid}`}
      isSubUnit={isSubUnit}
      isUnitType={isUnitType}
      isUnit={isUnit}
      isHotel={isHotel}
      hasExpandIcon={canBeExpanded}
    >
      <Button
        bsStyle="link"
        className="property-avatar-name-button"
        onClick={selectProperty}
      >
        {!isSubUnit && !isUnit && (
          <PropertyListItemAvatar
            // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            src={mainPicture?.tinyThumbnail || ''}
            circle
          />
        )}
        <PropertyListItemName
          hasExpandIcon={canBeExpanded}
          name={name}
          uid={propertyUid}
        />
      </Button>

      {!isUnit && <PropertyListItemEditIcon property={property} />}
      {canBeExpanded && <PropertyListItemExpandIcon uid={propertyUid} />}
    </PropertyListItemWrapper>
  );
};

export default PropertyListItem;
