import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { getMessageChannelForSending } from 'pages/inbox/messages/editor/useSendInboxMessage';
import useAppMutation from '../../../../hooks/useAppMutation';
import API from '../../../../services/API';
import { normalizeKey } from '../../../../utils/localeUtils';
import {
  MessageState,
  MessagingChannel,
  SendMessageResponseTO,
} from '../../../../models/inbox/Messages';
import { TemplateName } from './TemplateModal.types';

export interface SendTemplatePayload {
  templateName: TemplateName;
  message: {
    threadUid: string;
    content: {
      text: HTMLElement;
      subject: string;
    };
    channel: MessagingChannel;
    settings: {
      shouldCopyAgency: boolean;
    };
  };
}

enum MessageValidationError {
  EMPTY = 'EMPTY',
}

function validateMessageContent(messageHtmlContent: HTMLElement) {
  if (!messageHtmlContent.innerText.trim()) {
    return MessageValidationError.EMPTY;
  }

  return null;
}

const useSendTemplate = () => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { notifySuccess } = useNotify();

  const { mutate: sendTemplateHandler, isLoading: isSending } = useAppMutation(
    ['sendTemplateHandler'],
    (templatePayload: SendTemplatePayload) =>
      API.post(`/v3/messages`, {
        ...templatePayload.message,
        content: {
          ...templatePayload.message.content,
          text: templatePayload.message.content.text.innerHTML,
        },
      }),
    {
      onSuccess: ({
        data: { message },
      }: AxiosResponse<SendMessageResponseTO>) => {
        if (message.state === MessageState.FAILED) {
          setErrorMessage(t('pageInbox.messages.editor.error_SERVER_ERROR'));
          return;
        }

        closeModal('template-modal');
        notifySuccess(t('componentTemplate.messageHasBeenSent'));

        // TODO for pipeline: base on templateName, update status of lead
      },
      onError: () => {
        setErrorMessage(t('pageInbox.messages.editor.error_SERVER_ERROR'));
      },
    },
  );

  const sendTemplate = useCallback(
    ({
      payload,
      availableMessagingChannels,
    }: {
      payload: SendTemplatePayload;
      availableMessagingChannels: MessagingChannel[];
    }) => {
      const validationError = validateMessageContent(
        payload.message.content.text,
      );
      if (validationError) {
        setErrorMessage(
          t(normalizeKey(`pageInbox.messages.editor.error_${validationError}`)),
        );
        return;
      }

      const channel = getMessageChannelForSending(
        payload.message.channel,
        availableMessagingChannels,
      );

      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setErrorMessage(null);
      sendTemplateHandler({
        ...payload,
        message: {
          ...payload.message,
          channel,
        },
      });
    },
    [],
  );

  return { isSending, errorMessage, sendTemplate };
};

export default useSendTemplate;
