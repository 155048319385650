import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import Button from 'components/button/Button';
import LeadActionsPopover from 'components/domain/lead/actions/LeadActionsPopover';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import LeadCircle from 'components/domain/lead/status/LeadCircle';
import {
  getLeadCheckInDate,
  getLeadCheckOutDate,
  getLeadDetailLocalizedIntValue,
  getLeadLocalizedNightsCount,
} from '../../../utils/lead/leadUtils';
import { formatRangeShortWithCurrentYearCheck } from '../../../utils/dateTimeUtils';
import InboxContext from '../InboxContext';
import useFormatCurrency from '../../../hooks/useFormatCurrency';
import {
  LeadBody,
  LeadContainer,
  LeadDates,
  LeadDetailChannelValue,
  LeadDetailLabel,
  LeadDetailValue,
  LeadHeader,
  LeadHeaderFirstRow,
  LeadHeaderStatusContainer,
  LeadPropertyName,
} from './InboxLead.styles';
import InboxActiveLeadButtons from './InboxActiveLeadButtons';

const InboxActiveLead = () => {
  const { activeLead, propertiesMap } = useContext(InboxContext);
  const { updateLeadModal } = useOpenLeadModal();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  if (!activeLead) {
    return null;
  }

  const {
    assignee,
    channel,
    order,
    property: { uid: propertyUid },
    uid: leadUid,
    adultCount,
    childrenCount,
    infantCount,
    petCount,
  } = activeLead;

  const checkInDate = getLeadCheckInDate(activeLead);
  const checkOutDate = getLeadCheckOutDate(activeLead);
  const formattedDates = formatRangeShortWithCurrentYearCheck(
    checkInDate,
    checkOutDate,
    t,
  );
  const nightsCount = getLeadLocalizedNightsCount(activeLead, t);
  // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const propertyName = propertiesMap[propertyUid]?.name;

  const { bestProfile: assigneeName } = assignee || {};
  const { bestProfile: channelName, channel: channelType } = channel || {};
  const { currency, totalAmount } = order || {};

  const checkInDay = t('common.dateShort', { date: checkInDate });
  const checkInTime = t('common.timeShort', { date: checkInDate });
  const checkOutDay = t('common.dateShort', { date: checkOutDate });
  const checkOutTime = t('common.timeShort', { date: checkOutDate });

  const guestDetails = [
    getLeadDetailLocalizedIntValue(adultCount, 'adult', t),
    getLeadDetailLocalizedIntValue(childrenCount, 'child', t),
    getLeadDetailLocalizedIntValue(infantCount, 'infant', t),
    getLeadDetailLocalizedIntValue(petCount, 'pet', t),
  ];

  const totalAmountFormatted =
    currency && typeof totalAmount !== undefined // zero can be a valid value
      ? formatCurrency({ currency, value: totalAmount })
      : '-';

  const viewLead = () => {
    updateLeadModal({ jobCreationNotAllowed: true, leadUid });
  };

  const messagingActionCallback = () => {
    // TODO select template - to be implemented within PMP-5281
  };
  const updateLeadCallback = () => {
    // updateActiveLead(updatedLead, true); TODO to be implemented within PMP-5281
  };

  return (
    <LeadContainer data-testid={`active-lead-${leadUid}`}>
      <LeadHeader>
        <LeadHeaderFirstRow>
          <div>
            <LeadPropertyName>{propertyName}</LeadPropertyName>
            <LeadDates>
              <span>{formattedDates}</span> <span>({nightsCount})</span>
            </LeadDates>
          </div>
          <div>
            <Button data-testid="lead-view-button" onClick={viewLead}>
              {t('common.view')}
            </Button>
          </div>
        </LeadHeaderFirstRow>
        <LeadHeaderStatusContainer>
          <LeadActionsPopover
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            lead={undefined} // temporarily disable popover in the inbox until fully tested on the pipeline page
            messagingActionCallback={messagingActionCallback}
            updateLeadCallback={updateLeadCallback}
          >
            <LeadCircle lead={activeLead} />
          </LeadActionsPopover>
        </LeadHeaderStatusContainer>
      </LeadHeader>
      <LeadBody>
        <Row>
          <Col xs={6}>
            <LeadDetailLabel $id="assignee">
              {t('componentLead.details.assignee')}
            </LeadDetailLabel>
            <LeadDetailValue $id="assignee">
              {assigneeName || t('componentLead.details.unassigned')}
            </LeadDetailValue>
          </Col>
          <Col xs={6}>
            <LeadDetailLabel $id="channel">
              {t('componentLead.details.channel')}
            </LeadDetailLabel>
            <LeadDetailChannelValue>
              {/*
               // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
              <LeadChannelIcon channel={channelType} />
              <span>{channelName}</span>
            </LeadDetailChannelValue>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <LeadDetailLabel $id="checkIn">
              {t('componentLead.details.checkIn')}
            </LeadDetailLabel>
            <LeadDetailValue $id="checkIn">
              <div>{checkInDay}</div>
              <div>{checkInTime}</div>
            </LeadDetailValue>
          </Col>
          <Col xs={6}>
            <LeadDetailLabel $id="checkOut">
              {t('componentLead.details.checkOut')}
            </LeadDetailLabel>
            <LeadDetailValue $id="checkOut">
              <div>{checkOutDay}</div>
              <div>{checkOutTime}</div>
            </LeadDetailValue>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <LeadDetailLabel $id="guests">
              {t('componentLead.details.guests')}
            </LeadDetailLabel>
            <LeadDetailValue $id="guests">
              {guestDetails
                .filter((details) => !!details)
                .map((details) => (
                  <div key={details}>{details}</div>
                ))}
            </LeadDetailValue>
          </Col>
          <Col xs={6}>
            <LeadDetailLabel $id="totalAmount">
              {t('common.total')}
            </LeadDetailLabel>
            <LeadDetailValue $id="totalAmount">
              {totalAmountFormatted}
            </LeadDetailValue>
          </Col>
        </Row>
      </LeadBody>
      <InboxActiveLeadButtons />
    </LeadContainer>
  );
};

export default InboxActiveLead;
