import { Glyphicon, MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Photo } from 'models/Photos';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import {
  PhotoActionsDropdownContainer,
  PhotoActionsDropdownStyled,
} from '../PhotoGrid.styles';
import usePhotoActions from './usePhotoActions';

const PhotoActions = ({ photo }: { photo: Photo }) => {
  const { handleMovePhotoToTop, handleDeletePhoto, handleEditPhoto } =
    usePhotoActions(photo);
  const { t } = useTranslation();

  const shouldShowMoveToTop = photo.displayOrder !== 0;

  return (
    <PhotoActionsDropdownContainer>
      <PropertyEditActionWrapper tooltipId="property-photo-actions-dropdown-tooltip">
        <PhotoActionsDropdownStyled
          id="photo-actions-dropdown"
          title={<Glyphicon glyph="option-horizontal" />}
          noCaret
          bsStyle="default"
          pullRight
          data-testid="photo-actions-dropdown"
        >
          <MenuItem
            onClick={handleEditPhoto}
            data-testid="photo-actions-dropdown-edit"
          >
            {t('common.edit')}
          </MenuItem>
          {shouldShowMoveToTop && (
            <MenuItem
              onClick={handleMovePhotoToTop}
              data-testid="photo-actions-dropdown-move-to-top"
            >
              {t('pageProperty.photos.moveToTop')}
            </MenuItem>
          )}
          <MenuItem
            onClick={handleDeletePhoto}
            data-testid="photo-actions-dropdown-delete"
          >
            {t('common.delete')}
          </MenuItem>
        </PhotoActionsDropdownStyled>
      </PropertyEditActionWrapper>
    </PhotoActionsDropdownContainer>
  );
};

export default PhotoActions;
