import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getPaginationParams } from 'components/table/utils/pagination';
import { getSortParam } from 'components/table/utils/sorting';
import {
  GetPricingRulesResponseTO,
  PricingRule,
} from 'models/pricing/PricingRule';
import API from 'services/API';

export const PRICING_RULES_BASE_QUERY_KEY = 'pricing-rules';

export const PRICING_RULES_SORTING: {
  [K in keyof PricingRule]?: string;
} = {
  type: 'TYPE',
  name: 'NAME',
};

type QueryReturnType = ExpectedQueryData<PricingRule>;

export const pricingRulesQuery = ({
  propertyUid,
  pageIndex,
  pageSize,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    PRICING_RULES_BASE_QUERY_KEY,
    'list',
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<QueryReturnType> => {
    const sort = getSortParam(sorting, PRICING_RULES_SORTING);

    const response = await API.get<GetPricingRulesResponseTO>(
      '/api/internal/pricing-rules',
      {
        params: {
          propertyUid,
          ...getPaginationParams({ pageIndex, pageSize }),
          ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
        },
      },
    );

    const { pricingRules, _metadata } = response.data;

    return {
      _metadata,
      data: pricingRules,
    };
  },
  enabled: true,
});
