import { ModalBody, Row } from 'react-bootstrap';
import FormWithProvider from 'components/forms/form/Form';
import { Property } from 'models/Properties';
import { PricingRule } from 'models/pricing/PricingRule';
import {
  getFormDefaultValues,
  priceRulesAddEditFormSchema,
} from './PriceRulesAddEditModal.schema';
import { FieldsetStyled } from './PriceRulesAddEditModal.styles';
import PriceRulesAddEditModalAdjustPriceField from './PriceRulesAddEditModalAdjustPriceField';
import PriceRulesAddEditModalAppliesToField from './PriceRulesAddEditModalAppliesToField';
import PriceRulesAddEditModalCompatibleChannels from './PriceRulesAddEditModalCompatibleChannels';
import PriceRulesAddEditModalFooter from './PriceRulesAddEditModalFooter';
import PriceRulesAddEditModalNameField from './PriceRulesAddEditModalNameField';
import PriceRulesAddEditModalThreshold from './PriceRulesAddEditModalThreshold';
import PriceRulesAddEditModalTypeField from './PriceRulesAddEditModalTypeField';
import usePriceRulesAddEditModalFormSubmit from './usePriceRulesAddEditModalFormSubmit';

interface PriceRulesAddEditModalProps {
  modalId: string;
  pricingRule?: PricingRule;
  property: Property;
}

const PriceRulesAddEditModal = ({
  modalId,
  pricingRule,
  property,
}: PriceRulesAddEditModalProps) => {
  const { isUpdating, onSubmit } = usePriceRulesAddEditModalFormSubmit({
    modalId,
    originalPricingRule: pricingRule,
  });

  return (
    <FormWithProvider
      defaultValues={getFormDefaultValues({ pricingRule, property })}
      disabled={isUpdating}
      onSubmit={onSubmit}
      schema={priceRulesAddEditFormSchema()}
      noValidate
    >
      <FieldsetStyled disabled={isUpdating}>
        <ModalBody>
          <Row className="d-flex flex-column">
            <PriceRulesAddEditModalNameField />
            <PriceRulesAddEditModalTypeField />
            <PriceRulesAddEditModalCompatibleChannels />
            <PriceRulesAddEditModalAdjustPriceField />
            <PriceRulesAddEditModalThreshold />
            <PriceRulesAddEditModalAppliesToField />
          </Row>
        </ModalBody>
        <PriceRulesAddEditModalFooter modalId={modalId} />
      </FieldsetStyled>
    </FormWithProvider>
  );
};

PriceRulesAddEditModal.defaultProps = {
  pricingRule: undefined,
};

export default PriceRulesAddEditModal;
