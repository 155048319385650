import styled from 'styled-components';
import { Container as SwitchContainer } from 'components/switch/Switch.styles';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .column-header-Status {
    width: 55px;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  ${SwitchContainer} {
    --size: 32px;
    height: var(--size);
    width: var(--size);
  }
`;
