import { useTranslation } from 'react-i18next';
import useBulkUpdatePropertyAmenities from 'components/domain/property/amenities/useBulkUpdatePropertyAmenities';
import useBulkUpdatePropertyRules from 'components/domain/property/rules/useBulkUpdatePropertyRules';
import useBulkUpdatePropertyPriceSettings from 'components/domain/propertyPricing/useBulkUpdatePropertyPriceSettings';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { BaseErrorResponse } from 'models/_base';
import { PropertyAmenitiesBulkUpdatePayload } from 'models/Amenity';
import { AppEventType } from 'models/Events';
import { PropertyRulesBulkUpdatePayload } from 'models/Rule';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import {
  PropertySettingsTab,
  PropertySettingsTabType,
} from '../PropertySettingsTabs.constants';
import usePropertyMainSettingsUpdatePayload from '../mainSettings/usePropertyMainSettingsUpdatePayload';
import {
  PropertySettingsMainTabFormDirtyFields,
  PropertySettingsMainTabFormValues,
} from '../mainSettings/PropertySettingsMainTab.schema';
import useCategorizedAmenitiesUpdatePayload from '../amenities/sections/categorizedAmenities/useCategorizedAmenitiesUpdatePayload';
import {
  CategorizedAmenitiesFormDirtyFields,
  CategorizedAmenitiesFormValues,
} from '../amenities/sections/categorizedAmenities/CategorizedAmenities.schema';
import usePropertyPriceSettingsUpdatePayload from '../pricing/sections/priceSettings/usePropertyPriceSettingsUpdatePayload';
import {
  PropertySettingsPricingTabFormDirtyFields,
  PropertySettingsPricingTabFormValues,
} from '../pricing/PropertySettingsPricingTab.schema';
import useBulkUpdatePropertyMainSettings from './useBulkUpdatePropertyMainSettings';
import { PropertySettingsBulkSaveModalParams } from './PropertySettingsBulkSaveModal.types';
import { PropertySettingsBulkSaveFormValues } from './PropertySettingsBulkSaveModal.schema';

const usePropertySettingsBulkSaveFormSubmit = ({
  dirtyFields,
  formValues,
  modalId,
  propertyUid,
  tabName,
}: {
  dirtyFields: PropertySettingsBulkSaveModalParams['dirtyFields'];
  formValues: PropertySettingsBulkSaveModalParams['formValues'];
  modalId: string;
  propertyUid: string;
  tabName: PropertySettingsTabType;
}) => {
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const {
    isLoading: isUpdatingAmenities,
    mutateAsync: bulkUpdatePropertyAmenities,
  } = useBulkUpdatePropertyAmenities();
  const { isLoading: isUpdatingRules, mutateAsync: bulkUpdatePropertyRules } =
    useBulkUpdatePropertyRules();
  const { isUpdating: isUpdatingMainSettings, bulkUpdatePropertyMainSettings } =
    useBulkUpdatePropertyMainSettings();
  const {
    isLoading: isUpdatingPriceSettings,
    mutateAsync: bulkUpdatePriceSettings,
  } = useBulkUpdatePropertyPriceSettings();
  const { notifyError } = useNotify();
  const getAmenitiesUpdatePayload = useCategorizedAmenitiesUpdatePayload();
  const getMainSettingsUpdatePayload = usePropertyMainSettingsUpdatePayload();
  const getPropertyPriceSettingsUpdatePayload =
    usePropertyPriceSettingsUpdatePayload();
  const { t } = useTranslation();

  const handleUpdateError = ({
    apiErrorCode,
    apiErrorMessage,
  }: {
    apiErrorCode?: string;
    apiErrorMessage: string;
  }) => {
    notifyError(
      getErrorMessage({
        apiErrorCode,
        apiErrorMessage,
        baseKey: 'pageProperty.apiErrorCodes.',
        t,
      }),
    );
  };

  const handleUpdateResponse = ({ response }: BaseErrorResponse) => {
    const apiErrorCode = response?.data?.apiErrorCode;
    const apiErrorMessage = response?.data?.apiErrorMessage;

    if (apiErrorCode || apiErrorMessage) {
      handleUpdateError({
        apiErrorCode,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        apiErrorMessage,
      });
      return;
    }

    publish(AppEventType.PROPERTY_SETTINGS_UPDATED, {
      propertyUid,
    });

    closeModal(modalId);
  };

  if (tabName === PropertySettingsTab.mainSettings) {
    const onSubmit = async (
      bulkSaveFormValues: PropertySettingsBulkSaveFormValues,
    ) => {
      const { updateAllProperties, propertiesUids } =
        extractPropertiesMultiSelectFields('appliesTo', bulkSaveFormValues);

      const otherPropertiesUids = updateAllProperties
        ? undefined
        : // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          propertiesUids.filter((uid) => uid !== propertyUid);

      const updateData = getMainSettingsUpdatePayload({
        dirtyFields: dirtyFields as PropertySettingsMainTabFormDirtyFields,
        formValues: formValues as PropertySettingsMainTabFormValues,
      });

      await bulkUpdatePropertyMainSettings({
        applyToAllProperties: updateAllProperties,
        mainPropertyUid: propertyUid,
        otherPropertiesUids,
        updateData,
      })
        .then(handleUpdateResponse)
        .catch(({ response: { data } }) => handleUpdateError({ ...data }));
    };

    return { onSubmit, isUpdating: isUpdatingMainSettings };
  }

  if (tabName === PropertySettingsTab.amenities) {
    const onSubmit = async (
      bulkSaveFormValues: PropertySettingsBulkSaveFormValues,
    ) => {
      const { updateAllProperties, propertiesUids } =
        extractPropertiesMultiSelectFields('appliesTo', bulkSaveFormValues);

      const { amenities, rules } = getAmenitiesUpdatePayload({
        dirtyFields: dirtyFields as CategorizedAmenitiesFormDirtyFields,
        formValues: formValues as CategorizedAmenitiesFormValues,
      });

      const amenitiesPayload: PropertyAmenitiesBulkUpdatePayload = {
        amenities,
        applyToAll: updateAllProperties,
        propertyUids: updateAllProperties ? undefined : propertiesUids,
      };

      const rulesPayload: PropertyRulesBulkUpdatePayload = {
        rules,
        applyToAll: updateAllProperties,
        propertyUids: updateAllProperties ? undefined : propertiesUids,
      };

      await Promise.all(
        [
          amenities.length
            ? bulkUpdatePropertyAmenities({ payload: amenitiesPayload })
            : null,
          rules.length
            ? bulkUpdatePropertyRules({ payload: rulesPayload })
            : null,
        ].filter(Boolean),
      )
        .then((responses) => {
          const response = responses[0];

          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          return handleUpdateResponse(response);
        })
        .catch(({ response: { data } }) => handleUpdateError({ ...data }));
    };

    return {
      onSubmit,
      isUpdating: isUpdatingAmenities || isUpdatingRules,
    };
  }

  if (tabName === PropertySettingsTab.pricing) {
    const onSubmit = async (
      bulkSaveFormValues: PropertySettingsBulkSaveFormValues,
    ) => {
      const { updateAllProperties, propertiesUids } =
        extractPropertiesMultiSelectFields('appliesTo', bulkSaveFormValues);

      const otherPropertiesUids = updateAllProperties
        ? undefined
        : // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          propertiesUids.filter((uid) => uid !== propertyUid);

      const pricingSettings = getPropertyPriceSettingsUpdatePayload({
        dirtyFields: dirtyFields as PropertySettingsPricingTabFormDirtyFields,
        formValues: formValues as PropertySettingsPricingTabFormValues,
      });

      await bulkUpdatePriceSettings({
        applyToAllProperties: updateAllProperties,
        mainPropertyUid: propertyUid,
        otherPropertiesUids,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        updateData: { pricingSettings },
      })
        .then(handleUpdateResponse)
        .catch(({ response: { data } }) => handleUpdateError({ ...data }));
    };

    return { onSubmit, isUpdating: isUpdatingPriceSettings };
  }

  // TODO implement other required tabs

  throw new Error(`Not implemented tab: ${tabName}`);
};

export default usePropertySettingsBulkSaveFormSubmit;
