import { useState } from 'react';
import API from '../../../../../../services/API';
import useAppMutation from '../../../../../../hooks/useAppMutation';
import useNotify from '../../../../../../hooks/useNotify';
import { toISODateString } from '../../../../../../utils/dateTimeUtils';
import { GuestMisconductBody } from '../../../../../../models/channelactions/GuestMisconductBody';
import { useReportingApiErrorTranslation } from './useReportApiErrorTranslate';

export const useBookingDotComActionInvocation = <TVariables, TData>(
  mutationFn: (variables: TVariables) => Promise<TData>,
) => {
  const { notifyError } = useNotify();
  const { translateError } = useReportingApiErrorTranslation();
  const [reported, setReported] = useState(false);
  const { mutate, isLoading } = useAppMutation(mutationFn, {
    onError: (_err: any) => {
      notifyError(translateError(_err?.response?.data));
    },
    onSuccess: () => {
      setReported(true);
    },
  });
  return { isLoading, mutate, reported };
};

export const useReportInvalidCreditCard = (leadUid: string) => {
  const {
    isLoading,
    mutate: reportReservationAction,
    reported,
  } = useBookingDotComActionInvocation<void, void>(() =>
    API.post(
      `/api/internal/leads/channel-actions/bookingdotcom/${leadUid}/report-invalid-creditcard`,
    ),
  );
  return {
    isLoading,
    reportInvalidCreditCardMutation: reportReservationAction,
    reported,
  };
};

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
export const useReportCancellationDueToInvalidCreditCard = (leadUid) => {
  const {
    isLoading,
    mutate: reportCancellationDueToInvalidCardMutation,
    reported,
  } = useBookingDotComActionInvocation<void, any>(() =>
    API.post<any>(
      `/api/internal/leads/channel-actions/bookingdotcom/${leadUid}/report-cancellation-due-to-invalid-cc`,
    ),
  );
  return {
    isLoading,
    reportCancellationDueToInvalidCardMutation,
    reported,
  };
};

export interface ChangeStayDateParams {
  leadUid: string;
  checkIn: Date;
  checkOut: Date;
  price: number;
}

export const useReportChangeStayDates = () => {
  const {
    isLoading,
    mutate: changeStayDatesMutation,
    reported,
  } = useBookingDotComActionInvocation((args: ChangeStayDateParams) =>
    API.post<any>(
      `/api/internal/leads/channel-actions/bookingdotcom/${args.leadUid}/report-change-stay-dates`,
      {
        checkIn: toISODateString(args.checkIn),
        checkOut: toISODateString(args.checkOut),
        price: args.price,
      },
    ),
  );
  return {
    isLoading,
    changeStayDatesMutation,
    reported,
  };
};

export interface GuestMisconductMutationArgs {
  leadUid: string;
  data: GuestMisconductBody;
}

export const useReportGuestMisConduct = () => {
  const {
    isLoading,
    mutate: guestMisconductMutation,
    reported,
  } = useBookingDotComActionInvocation((args: GuestMisconductMutationArgs) =>
    API.post<any>(
      `/api/internal/leads/channel-actions/bookingdotcom/${args.leadUid}/report-guest-misconduct`,
      args.data,
    ),
  );
  return {
    isLoading,
    guestMisconductMutation,
    reported,
  };
};

export interface GuestNoShowArgs {
  leadUid: String;
  waiveFees: boolean;
}

export const useReportGuestNoShow = () => {
  const {
    isLoading,
    mutate: guestNoShowMutation,
    reported,
  } = useBookingDotComActionInvocation((args: GuestNoShowArgs) =>
    API.post<any>(
      `/api/internal/leads/channel-actions/bookingdotcom/${args.leadUid}/report-guest-no-show?waiveFees=${args.waiveFees}`,
    ),
  );
  return {
    isLoading,
    guestNoShowMutation,
    reported,
  };
};
