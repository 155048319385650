import { ImageCircle } from 'components/image/ImageCircle.styles';
import { PropertyBase } from 'models/Properties';
import theme from 'styles/theme';
import usePropertyEditPath from '../../property/usePropertyEditPath';

interface PropertyPictureProps {
  property: Pick<PropertyBase, 'businessType' | 'mainPicture' | 'name' | 'uid'>;
}

const PropertyPicture = ({ property }: PropertyPictureProps) => {
  const getPropertyEditPath = usePropertyEditPath();

  return (
    <a
      aria-label={property.name}
      data-testid="property-list-item-picture"
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      href={getPropertyEditPath(property)}
    >
      <ImageCircle $ringColor={theme.colors.gray300}>
        <div>
          <img src={property.mainPicture?.tinyThumbnail} alt={property.name} />
        </div>
      </ImageCircle>
    </a>
  );
};

export default PropertyPicture;
