import { Row } from '@tanstack/react-table';
import { PricingRule } from 'models/pricing/PricingRule';
import { useTranslation } from 'react-i18next';

interface ConditionCellProps {
  getValue: () => number;
  row: Row<PricingRule>;
}

const ConditionCell = ({ getValue, row }: ConditionCellProps) => {
  const { t } = useTranslation();
  const count = getValue();
  const { type: context } = row.original;

  return t(`pageProperty.pricing.priceRules.condition`, {
    context,
    count,
  });
};

export default ConditionCell;
