import { useFormContext } from 'react-hook-form';
import useAppModal from 'hooks/useAppModal';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import SetMinStayByWeekDayModal from './SetMinStayByWeekDayModal';

const setMinStayByWeekDayModalId = 'set-min-stay-by-week-day-modal';

const useOpenSetMinStayByWeekDayModal = () => {
  const { openModal, closeModal } = useAppModal();
  const { getValues, setValue } =
    useFormContext<PropertySettingsMainTabFormValues>();

  const handleOnCancel = () => closeModal(setMinStayByWeekDayModalId);
  const handleOnSubmit = (
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    dayOfWeekMinimumStay: PropertySettingsMainTabFormValues['bookingSettings']['dayOfWeekMinimumStay'],
  ) => {
    setValue('bookingSettings.dayOfWeekMinimumStay', dayOfWeekMinimumStay, {
      shouldDirty: true,
    });
    closeModal(setMinStayByWeekDayModalId);
  };

  const openSetMinStayByWeekDayModal = () => {
    openModal({
      customContent: (
        <SetMinStayByWeekDayModal
          defaultValues={getValues('bookingSettings.dayOfWeekMinimumStay')}
          onCancel={handleOnCancel}
          onSubmit={handleOnSubmit}
        />
      ),
      id: setMinStayByWeekDayModalId,
      size: 'medium',
    });
  };

  return {
    openSetMinStayByWeekDayModal,
  };
};

export default useOpenSetMinStayByWeekDayModal;
