import Button from 'components/button/Button';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import useIsMobileScreen from 'hooks/useIsMobileScreen';
import {
  PhotoEditModalContainer,
  PhotoEditModalFooter,
} from './PhotoEditModal.styles';

const PhotoEditModalLoader = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobileScreen();

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{t('pageProperty.photos.editModal.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <PhotoEditModalContainer>
          <LoadingPlaceholder linesCount={isMobile ? 25 : 43} />
          <LoadingPlaceholder linesCount={5} />
          <LoadingPlaceholder linesCount={2} />
        </PhotoEditModalContainer>
      </Modal.Body>

      <PhotoEditModalFooter>
        <Button bsStyle="danger" onClick={() => {}}>
          {t('common.delete')}
        </Button>
        <Button bsStyle="primary" type="submit">
          {t('common.save')}
        </Button>
      </PhotoEditModalFooter>
    </div>
  );
};

export default PhotoEditModalLoader;
