import styled from 'styled-components';

export const PropertySettingsDescriptionsTabStyled = styled.div`
  fieldset,
  .placeholder-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    textarea {
      resize: vertical;
    }

    &.placeholder-form .form-field-container {
      padding-bottom: 10px;
    }

    .tooltip-container {
      margin-left: 5px;
    }
  }

  .channel-filter-loader {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    div:first-child {
      font-weight: bold;
      margin-right: 16px;
    }

    span {
      flex: 1;
    }
  }
`;
