import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BookingDotComActions } from './BookingDotComActions';
import {
  ActionsTitle,
  LeadModalActionsTabColumn,
} from './LeadModalActionsTab.styles';

export const LeadModalActionsTab = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <LeadModalActionsTabColumn md={12}>
          <ActionsTitle>
            {t('componentLead.modal.actionsTab.reservationActions')}
          </ActionsTitle>
          <p>{t('componentLead.modal.actionsTab.description')}</p>
        </LeadModalActionsTabColumn>
      </Row>
      <BookingDotComActions />
    </>
  );
};
