import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { PropertyExpectationType } from 'models/PropertyExpectations';

export const propertyExpectationAddEditFormSchema = () => ({
  // appliesTo: Yup.string().required(),
  // appliesToProperties: Yup.array()
  //   .of(
  //     Yup.object({
  //       isFixed: Yup.boolean().optional(),
  //       label: Yup.string(),
  //       value: Yup.string(),
  //     }),
  //   )
  //   .label('properties')
  //   .when('appliesTo', {
  //     is: PROPERTIES_SELECTION.ALL_PROPERTIES,
  //     then: (schema) => schema.optional().nullable(true),
  //     otherwise: (schema) => schema.min(1).required(),
  //   }),
  propertyExpectation: Yup.object({
    expectationDescription: Yup.string().max(100).required(),
    expectationType: Yup.mixed<PropertyExpectationType>()
      .oneOf(Object.values(PropertyExpectationType))
      .required(),
    objectUid: Yup.string().required(),
    uid: Yup.string().nullable().optional(),
  }),
});

export type PropertyExpectationAddEditFormValues = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof propertyExpectationAddEditFormSchema>>
>;
