import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useLocalStorage from 'hooks/useLocalStorage';
import { isDeepEqual } from 'utils/objectUtils';
import InboxContext from '../InboxContext';
import {
  getFormDefaultValues,
  InboxFilterSchema,
  InboxFilterSchemaType,
} from './InboxFilter.schema';

const useInboxFilterFormApplyStoredValues = () => {
  const { threadFilter, inboxFilterLocalStorageKey } = useContext(InboxContext);
  const { getValue: getStoredFilterFormValues } =
    useLocalStorage<InboxFilterSchemaType>(inboxFilterLocalStorageKey);
  const { setValue } = useFormContext();

  useEffect(() => {
    const storedFormValues = getStoredFilterFormValues();
    if (!storedFormValues || !threadFilter) {
      return;
    }

    const defaultValues = getFormDefaultValues();
    const storedValues = InboxFilterSchema.cast(storedFormValues);

    Object.keys(storedValues)
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      .filter((key) => !isDeepEqual(storedValues[key], defaultValues[key]))
      .forEach((key) => {
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        setValue(key, storedValues[key], {
          shouldDirty: true,
        });
      });
  }, []);
};

export default useInboxFilterFormApplyStoredValues;
