import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Lead } from 'models/Leads';
import { Col } from 'react-bootstrap';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { isLeadImported } from 'utils/lead/leadUtils';
import Button from 'components/button/Button';
import { OrderViewTableHeader } from '../OrderView.styles';
import {
  OrderViewPriceDetailsListWrapper,
  PriceDetailsListSubOrderTotal,
  PriceDetailsListTotal,
} from './OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItemTotal from './OrderViewPriceDetailsListItemTotal';
import OrderViewTotalSubtitle from './OrderViewTotalSubtitle';
import OrderViewPriceDetailsListItemCancel from './OrderViewPriceDetailsListItemCancel';
import SecurityDeposit from './items/SecurityDeposit';

const GroupOrderViewPriceDetailsInner = ({
  order,
  subOrders,
  lead,
  showTitle,
  showSecurityDepositAsTotal,
  showAmountPaidRow,
  showBalanceRow,
  showLinkToSubBooking,
  showCancelSubOrder,
  onClickLinkToSubBooking,
}: {
  order: HasOrderItems;
  subOrders: HasOrderItems[];
  lead: Lead;
  showTitle?: boolean;
  showSecurityDepositAsTotal?: boolean;
  showAmountPaidRow?: boolean;
  showBalanceRow?: boolean;
  showLinkToSubBooking?: boolean;
  showCancelSubOrder?: boolean;
  onClickLinkToSubBooking?: (leadUid: string) => void;
}) => {
  const { t } = useTranslation();

  const { totalAmount, currency } = order;
  const {
    order: {
      balance,
      balanceDueLocalDate,
      includeSecurityDepositInTotal,
      securityDeposit,
    } = {
      balance: 0,
      balanceDueLocalDate: null,
      includeSecurityDepositInTotal: false,
      securityDeposit: 0,
    },
    property: {
      pricing: { fullPaymentTiming } = { fullPaymentTiming: 0 },
    } = {},
  } = lead || {};
  const isImported = isLeadImported(lead);

  const getSecurityDepositLineItem = () => {
    if (showSecurityDepositAsTotal) {
      return (
        <OrderViewPriceDetailsListItemTotal
          key={`securityDeposit-${order.uid}`}
          currency={currency}
          data-testid="price-details-list-item-total-subOrder"
          label={t('componentOrder.securityDeposit')}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          amount={securityDeposit}
        />
      );
    }

    return (
      <SecurityDeposit
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        includeSecurityDepositInTotal={includeSecurityDepositInTotal}
        allowEdit={false}
        order={order}
      />
    );
  };

  return (
    <div>
      <OrderViewTableHeader>
        {showTitle && (
          <Col xs={6}>
            <h4 data-testid="price-details-title">
              {t('pageOrder.priceDetails.sectionTitle')}
            </h4>
          </Col>
        )}
      </OrderViewTableHeader>

      <OrderViewPriceDetailsListWrapper
        $isLoading={false}
        data-testid="price-details-wrapper"
        className="price-details-wrapper"
      >
        <PriceDetailsListSubOrderTotal
          className="price-details-list-total price-details-list-total-subOrder"
          data-testid="price-details-list-subOrder"
        >
          {subOrders
            .filter((s) => !s.cancelled)
            .map((subOrder) => (
              <OrderViewPriceDetailsListItemTotal
                key={subOrder.uid}
                currency={subOrder.currency}
                data-testid="price-details-list-item-total-subOrder"
                // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                label={subOrder.propertyName}
                amount={subOrder.totalAmount}
                // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                subtitle={
                  showLinkToSubBooking && (
                    <Button
                      type="button"
                      className="btn btn-link"
                      data-testid="sub-booking-button"
                      onClick={() =>
                        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                        onClickLinkToSubBooking?.(subOrder.leadUid)
                      }
                    >
                      {t('componentLead.modal.viewDetails')}
                    </Button>
                  )
                }
              />
            ))}
          {subOrders
            .filter((s) => s.cancelled && showCancelSubOrder)
            .map((subOrder) => (
              <OrderViewPriceDetailsListItemCancel
                key={subOrder.uid}
                data-testid="price-details-list-item-total-subOrder"
                // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                label={subOrder.propertyName}
              />
            ))}

          {!isImported &&
            includeSecurityDepositInTotal &&
            !!securityDeposit &&
            getSecurityDepositLineItem()}
        </PriceDetailsListSubOrderTotal>

        <PriceDetailsListTotal
          className="price-details-list-total"
          data-testid="price-details-list-total"
        >
          <OrderViewPriceDetailsListItemTotal
            currency={currency}
            data-testid="price-details-list-item-total-total"
            label={
              isImported
                ? t('componentOrder.payout')
                : t('componentOrder.total')
            }
            subtitle={<OrderViewTotalSubtitle lead={lead} />}
            amount={totalAmount}
          />

          {/*
           // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {showAmountPaidRow && totalAmount - balance > 0 && !isImported && (
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-amountPaid"
              label={t('componentOrder.amountPaid')}
              // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              amount={totalAmount - balance}
            />
          )}

          {/*
           // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {showBalanceRow && !isImported && balance > 0 && (
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-balance"
              label={t('componentOrder.balanceDue')}
              // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              amount={balance}
              // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              subtitle={
                balanceDueLocalDate &&
                t('componentOrder.balanceDueDate', {
                  date: format(parseISO(balanceDueLocalDate), 'MMM dd, yyyy'),
                  days: fullPaymentTiming,
                })
              }
            />
          )}
        </PriceDetailsListTotal>

        {!isImported &&
          !includeSecurityDepositInTotal &&
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          securityDeposit > 0 && (
            <PriceDetailsListSubOrderTotal
              className="price-details-list-total price-details-list-total-subOrder"
              data-testid="price-details-list-subOrder"
            >
              {getSecurityDepositLineItem()}
            </PriceDetailsListSubOrderTotal>
          )}
      </OrderViewPriceDetailsListWrapper>
    </div>
  );
};

GroupOrderViewPriceDetailsInner.defaultProps = {
  showTitle: true,
  showSecurityDepositAsTotal: false,
  showAmountPaidRow: true,
  showBalanceRow: true,
  showLinkToSubBooking: false,
  showCancelSubOrder: true,
  onClickLinkToSubBooking: () => {},
};

export default GroupOrderViewPriceDetailsInner;
