import PriceTag from 'components/priceTag/PriceTag';
import { Lead } from 'models/Leads';
import { Transaction, TransactionStatus } from 'models/Transactions';

const TransactionAmountRenderer = ({
  lead,
  transaction,
}: {
  lead: Lead;
  transaction: Pick<Transaction, 'amount' | 'status'>;
}) => {
  const { amount, status } = transaction;

  return (
    <PriceTag
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      currency={lead?.order?.currency}
      currencyFormattingOptions={{
        round: false,
        removeDecimals: amount % Math.floor(amount) === 0,
      }}
      data-testid="amount"
      hasError={status === TransactionStatus.FAILURE}
      value={amount}
    />
  );
};

export default TransactionAmountRenderer;
