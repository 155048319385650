import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';

const PropertyCleaningFeeTaxField = ({
  label,
  name = 'propertyPricingFeesTaxes.cleaningFeeTax',
}: {
  label?: ReactNode;
  name?: string;
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={label ?? t('pageProperty.pricing.priceSettings.cleaningFeeTax')}
      name={name}
      colSmInput={7}
      colSmLabel={5}
      rightAddons={['%']}
      type="number"
      min={0}
      max={100}
      rightTooltip={t(
        'pageProperty.pricing.priceSettings.cleaningFeeTaxTooltip',
      )}
    />
  );
};

PropertyCleaningFeeTaxField.defaultProps = {
  label: undefined,
  name: undefined,
};

export default PropertyCleaningFeeTaxField;
