import { AirbnbAlterationRequestProps } from './UpdateAirbnbAlterationModal.types';
import AirbnbAlterationRequestModalContextProvider from './AirbnbAlterationRequestModalContextProvider';
import AirbnbAlterationRequestModalFormWrapper from './AirbnbAlterationRequestModalFormWrapper';

const AirbnbAlterationRequestModal = ({
  availableProperties,
  modalId,
  lead,
}: AirbnbAlterationRequestProps) => {
  return (
    <AirbnbAlterationRequestModalContextProvider
      availableProperties={availableProperties}
      modalId={modalId}
      defaultLeadUid={lead?.uid}
    >
      <AirbnbAlterationRequestModalFormWrapper />
    </AirbnbAlterationRequestModalContextProvider>
  );
};

export default AirbnbAlterationRequestModal;
