import { useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { debounce } from 'utils/funcs';
import { isSafari } from 'react-device-detect';
import CalendarContext from '../CalendarContext';
import { stackedCalendarStyleConstants } from '../Calendar.constants';
import CalendarScrollPositionService from '../CalendarScrollPositionService';
import { CalendarDaysLoadDirection } from './StackedCalendarNavigation.types';
import useStackedCalendarLoadMoreDays from './useStackedCalendarLoadMoreDays';

const scrollPositionPercentageThreshold = 0.1;

function getLoadDirection(
  currentScrollPosition: number,
  displayWidth: number,
  totalWidth: number,
): CalendarDaysLoadDirection | null {
  if (currentScrollPosition / totalWidth < scrollPositionPercentageThreshold) {
    return CalendarDaysLoadDirection.PAST;
  }

  if (
    (currentScrollPosition + displayWidth) / totalWidth >
    1 - scrollPositionPercentageThreshold
  ) {
    return CalendarDaysLoadDirection.FUTURE;
  }

  return null;
}

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const useStackedCalendarNavigationScroll = (scrollRef) => {
  const currentScrollPositionRef = useRef<number>(0);
  const scrollingInProgressRef = useRef<boolean>(false);
  const { bodyWidth, mode } = useContext(CalendarContext);
  const loadMoreDays = useStackedCalendarLoadMoreDays();
  const cellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];
  const scrollingPositionContainer =
    CalendarScrollPositionService.getScrollingContainer();

  useLayoutEffect(() => {
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const scrollListener = (e) => {
      const currentScrollPosition = (e.target as HTMLDivElement).scrollLeft;
      const displayWidth = (e.target as HTMLDivElement).offsetWidth;
      const totalWidth = (e.target as HTMLDivElement).scrollWidth;
      const daysLoadDirection = getLoadDirection(
        currentScrollPosition,
        displayWidth,
        totalWidth,
      );

      if (scrollingPositionContainer) {
        debounce(
          () => {
            scrollingPositionContainer.calendarScrollingPosition = {
              scrollLeft: currentScrollPosition,
            };
            CalendarScrollPositionService.notify();
          },
          isSafari ? 500 : 200,
        )();
      }

      if (daysLoadDirection) {
        loadMoreDays(
          daysLoadDirection,
          scrollRef.current?.firstChild,
          scrollingInProgressRef,
        );
      } else {
        scrollingInProgressRef.current = false;
      }

      currentScrollPositionRef.current = currentScrollPosition;
    };

    if (scrollRef.current) {
      const scrollableElement = scrollRef.current.firstChild as HTMLDivElement;
      scrollableElement.addEventListener('scroll', scrollListener);
    }

    return () => {
      scrollRef.current?.firstChild?.removeEventListener(
        'scroll',
        scrollListener,
      );
    };
  }, [scrollRef.current, loadMoreDays]);

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const scrollBy = (scrollByValue) => {
    if (scrollRef.current && !scrollingInProgressRef.current) {
      scrollingInProgressRef.current = true;
      const scrollableElement = scrollRef.current.firstChild as HTMLDivElement;
      scrollableElement.scrollLeft += scrollByValue;
    }
  };

  return useMemo(
    () => ({
      scrollLeft: () => {
        scrollBy(cellWidth - bodyWidth);
      },
      scrollRight: () => {
        scrollBy(bodyWidth - cellWidth);
      },
      currentScrollPositionRef,
    }),
    [bodyWidth, cellWidth, scrollRef.current, currentScrollPositionRef.current],
  );
};

export default useStackedCalendarNavigationScroll;
