import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import TextField from 'components/forms/textField/TextField';
import Button from 'components/button/Button';
import { Col, Row } from 'react-bootstrap';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { ReferFormWrap } from './Refer.styles';

const ReferForm = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation();
  const { referLink } = useWatch();
  const { copyToClipBoard } = useCopyToClipboard();

  const handleCopyLink = () => {
    copyToClipBoard(referLink, t('pageRefer.linkSuccessfullyCopied'));
  };

  return (
    <Row>
      <Col md={5} sm={12} xs={12} lg={5}>
        <ReferFormWrap>
          <TextField disabled name="referLink" />
          <Button
            className="btn-primary"
            type="button"
            onClick={handleCopyLink}
            disabled={isLoading}
            data-testid="copy-link"
          >
            {t('pageRefer.copyTheLink')}
          </Button>
        </ReferFormWrap>
      </Col>
    </Row>
  );
};

export default ReferForm;
