import BookIconSvg from 'assets/images/icons/book.svg?react';
import CalendarIconSvg from 'assets/images/icons/calendar.svg?react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/button/Button';
import { PropertyBusinessType } from 'models/Properties';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import { calendarModeLocalStorageKey } from 'pages/stackedCalendar/Calendar.constants';
import usePropertyGuidebookLink from '../usePropertyGuidebookLink';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { HeaderContainer } from './PropertySettingsPricingTabHeader.styles';

const PropertySettingsPricingTabHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uid: propertyUid } = useParams();
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const guidebookLink = usePropertyGuidebookLink(propertyUid);
  const { property } = usePropertySettingsInitData();

  if (property?.businessType === PropertyBusinessType.HOTEL) {
    const goToAddUnitType = () => {
      navigate(`/property/${propertyUid}/add/unit-type`);
    };

    return (
      <HeaderContainer>
        <Button
          $colorScheme="green"
          onClick={goToAddUnitType}
          data-testid="add-unit-type-button"
        >
          {t('common.pageTitles.property/:uid/add/unit-type', {
            nsSeparator: false,
          })}
        </Button>
      </HeaderContainer>
    );
  }

  const goToCalendar = () => {
    localStorage.setItem(
      calendarModeLocalStorageKey,
      StackedCalendarMode.PRICING,
    );

    navigate(`/calendar?propertyUid=${propertyUid}`);
  };

  return (
    <HeaderContainer>
      <Button bsStyle="link" onClick={goToCalendar}>
        <CalendarIconSvg />
        {t('pageProperty.mainSettings.header.calendar')}
      </Button>

      {!!guidebookLink && (
        <Button bsStyle="link" href={guidebookLink} target="_blank">
          <BookIconSvg />
          {t('pageProperty.mainSettings.header.guidebook')}
        </Button>
      )}
    </HeaderContainer>
  );
};

export default PropertySettingsPricingTabHeader;
