export enum LanguageCode {
  DE = 'de',
  EN = 'en',
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NL = 'nl',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RU = 'ru',
  TR = 'tr',
  VI = 'vi',
  ZH = 'zh',
}

export enum LanguageCodeUnderscored {
  DE_DE = 'de_DE',
  EN_US = 'en_US',
  ES_ES = 'es_ES',
  FR_FR = 'fr_FR',
  IT_IT = 'it_IT',
  JA_JP = 'ja_JP',
  KO_KR = 'ko_KR',
  NL_NL = 'nl_NL',
  NO_NO = 'no_NO',
  PL_PL = 'pl_PL',
  PT_PT = 'pt_PT',
  RU_RU = 'ru_RU',
  TR_TR = 'tr_TR',
  VI_VN = 'vi_VN',
  ZH_CN = 'zh_CN',
}
