import { useCallback } from 'react';
import useFeatures from 'hooks/useFeatures';
import { Property, PropertyBase } from 'models/Properties';
import { getPropertyAddUnitTypePath } from 'utils/property/propertyUtils';

const usePropertyAddUnitTypePath = () => {
  const { allowPropertyEditV3Beta } = useFeatures();

  return useCallback(
    (property: Partial<Property> | PropertyBase) => {
      if (allowPropertyEditV3Beta) {
        return `#/property/${property.uid}/add/unit-type`;
      }

      return getPropertyAddUnitTypePath(property as PropertyBase);
    },
    [allowPropertyEditV3Beta],
  );
};

export default usePropertyAddUnitTypePath;
