import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Discount, DiscountCodeStatus } from 'models/Discounts';
import FormattedDateCell from 'components/table/cells/common/FormattedDateCell';
import YesOrNoCell from 'components/table/cells/common/YesOrNo';
import useClientFilteredTable from 'components/table/hooks/useClientFilteredTable';
import { sortByColumns } from 'components/table/utils/sorting';
import { DiscountsTableStyled } from './DiscountsTable.styles';
import ActionsCell from './cells/ActionsCell';
import AmountPercentageCell from './cells/AmountPercentageCell';
import ValidDatesCell from './cells/ValidDatesCell';

interface DiscountsTableProps {
  data?: Discount[];
  status: DiscountCodeStatus;
}

const DiscountsTable = ({ data, status }: DiscountsTableProps) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Discount>();
  const columns = [
    columnHelper.accessor('name', {
      header: t('common.name'),
      sortDescFirst: true,
    }),
    columnHelper.accessor('amount', {
      header: `${t('common.amount')} / ${t('common.percent')}`,
      cell: AmountPercentageCell,
      sortingFn: sortByColumns(['type', 'percent', 'amount', 'name']),
    }),
    columnHelper.accessor('status', {
      header: t('common.status'),
      enableSorting: false,
    }),
    columnHelper.accessor('expirationDate', {
      header: t('pageDiscountCodes.pageDiscountCodes.expiresOn'),
      cell: FormattedDateCell,
      sortDescFirst: true,
      sortingFn: sortByColumns(['expirationDate', 'name']),
    }),
    columnHelper.accessor('validFromDate', {
      header: t('pageDiscountCodes.pageDiscountCodes.validDates'),
      cell: ValidDatesCell,
      sortDescFirst: true,
      sortingFn: sortByColumns(['validFromDate', 'validToDate', 'name']),
    }),
    columnHelper.accessor('property', {
      header: t('common.property'),
      sortDescFirst: true,
      sortingFn: sortByColumns(['property', 'name']),
    }),
    columnHelper.accessor('singleUse', {
      header: t('pageDiscountCodes.pageDiscountCodes.singleUse'),
      cell: YesOrNoCell,
      sortingFn: sortByColumns(['singleUse', 'name']),
    }),
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
      meta: {
        isHidden: status === DiscountCodeStatus.DELETED,
      },
    }),
  ];

  const { tableInstance } = useClientFilteredTable({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    data,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    columns,
  });

  return (
    <DiscountsTableStyled
      data-testid="discounts-table"
      caption=""
      tableInstance={tableInstance}
      striped
    />
  );
};

DiscountsTable.defaultProps = {
  data: undefined,
};

export default DiscountsTable;
