import { CountryCode } from './Country';

export function getCountryFlagEmoji(
  countryCode: CountryCode | string | undefined,
) {
  const codePoints = (countryCode ?? '')
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
