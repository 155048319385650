import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BulkSaveButton from 'components/button/BulkSaveButton';
import { propertySettingsBulkSavableFields } from './Common.constants';
import PropertyEditActionWrapper from './PropertyEditActionWrapper';

const FormFooterBulkSaveButton = () => {
  const { isSubmitting, dirtyFields } = useFormState();
  const { t } = useTranslation();

  const isAnyBulkSavableFieldDirty = Object.entries(dirtyFields).some(
    ([sectionName, sectionDirtyFields]) =>
      Object.keys(sectionDirtyFields).some((fieldName) =>
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertySettingsBulkSavableFields[sectionName]?.includes(fieldName),
      ),
  );

  return (
    <PropertyEditActionWrapper tooltipId="bulk-save-button-tooltip">
      <BulkSaveButton
        data-testid="bulk-save-button"
        id="bulk-save-button"
        type="submit"
        disabled={isSubmitting || !isAnyBulkSavableFieldDirty}
      >
        {t('pageProperty.bulkSave.buttonLabel')}
      </BulkSaveButton>
    </PropertyEditActionWrapper>
  );
};

export default FormFooterBulkSaveButton;
