import { useState } from 'react';
import DisplayableValue from './DisplayableValue';

interface PercentageFieldProps {
  isEditable: boolean;
  value: number;
  onItemUpdated: Function;
}

const PercentageField = ({
  isEditable,
  value,
  onItemUpdated,
}: PercentageFieldProps) => {
  const [editMode, setEditMode] = useState(false);

  if (!isEditable) {
    return <span>{`${value}%`}</span>;
  }

  const handleDisplayClick = () => {
    if (isEditable) {
      setEditMode(true);
    }
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onSave = (newValue) => onItemUpdated(newValue);

  return editMode ? (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <input
      type="number"
      defaultValue={value}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          // @ts-ignore
          onSave(e.target.value);
          setEditMode(false);
        }
      }}
      onBlur={(e) => {
        onSave(e.target.value);
        setEditMode(false);
      }}
    />
  ) : (
    <DisplayableValue
      value={value}
      type="percentage"
      onClick={handleDisplayClick}
    />
  );
};

export default PercentageField;
