import { ALL_CATEGORIZED_AMENITIES_ITEMS } from '../amenities/sections/categorizedAmenities/CategorizedAmenities.constants';

export const propertySettingsBulkSavableFields = {
  airbnbSettings: [
    'bookingType',
    'cancellationPolicy',
    'checkInTimeStartFlexible',
    'checkInTimeEnd',
    'nonRefundableRateDiscount',
    'nonRefundableRateEnabled',
  ],
  amenities: ALL_CATEGORIZED_AMENITIES_ITEMS,
  bookingDotComSettings: ['bookingType', 'cancellationPolicyCode'],
  bookingSettings: [
    'bookingLeadTime',
    'bookingType',
    'bookingWindow',
    'checkInTime',
    'checkoutTime',
    'dayOfWeekMinimumStay',
    'daysOfTheWeekToCheckInOn',
    'fullPaymentTiming',
    'maximumStay',
    'minimumStay',
    'minimumWeekendStay',
    'percentageUponReservation',
    'turnoverDays',
  ],
  capacityDetails: ['extraGuestFee'],
  priceSettings: [
    'baseDailyRate',
    'cleaningFee',
    'cleaningFeeTax',
    'currency',
    'isTaxLongTermStayExemption',
    'securityDepositAmount',
    'taxLongTermStayExemptionDays',
    'taxRate',
    'useMinimumPriceRule',
    'weekEndRatePercentAdjustment',
  ],
  vrboSettings: ['bookingType', 'cancellationPolicy'],
  serviceProviders: ['providers', 'notes'],
};
