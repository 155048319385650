import StarIcon from 'assets/images/icons/star.svg?react';
import ProgressBar from './ProgressBar';
import { StarProgressBarContainer } from './ProgressBar.styles';

interface StarProgressBarProps {
  rating: number;
  now: number;
}

const StarProgressBar = ({ now, rating }: StarProgressBarProps) => {
  return (
    <StarProgressBarContainer
      data-testid="star-progress-bar"
      data-rating={rating}
    >
      <span className="star-progress-rating">{rating}</span>
      <StarIcon fill="#000" />
      <ProgressBar now={now} color="#FFC454" />
    </StarProgressBarContainer>
  );
};

export default StarProgressBar;
