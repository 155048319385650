import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Lead,
  LeadAvailablePipelineAction,
  LeadPipelineAction,
} from '../../../../models/Leads';
import useAppMutation from '../../../../hooks/useAppMutation';
import useNotify from '../../../../hooks/useNotify';
import API from '../../../../services/API';
import { LeadActionFormData, LeadActionResponseTO } from './LeadActions.types';

interface ExecuteLeadActionPayload {
  action: LeadAvailablePipelineAction;
  lead: Lead;
  updateLeadCallback: () => void;
  formData?: LeadActionFormData;
  customErrorMessage?: string;
}

function getApiEndpointBase(leadUid: string) {
  return `/api/v3/leads/${leadUid}/`;
}

function getInternalApiEndpointBase(leadUid: string) {
  return `/api/internal/leads/${leadUid}/`;
}

const internalActionsApiEndpointBase = '/api/internal/leads-actions-facade';
function getInternalActionsApiEndpointBase(leadUid: string) {
  return `${internalActionsApiEndpointBase}/${leadUid}/`;
}

const actionToEndpointUrl = {
  [LeadAvailablePipelineAction.ACCEPT_PENDING]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}accept-booking-request`,
  [LeadAvailablePipelineAction.AIRBNB_PRE_APPROVE]: ({ uid: leadUid }: Lead) =>
    `${getInternalActionsApiEndpointBase(leadUid)}pre-approve`,
  [LeadAvailablePipelineAction.ASSIGN_TO_ME]: ({ uid: leadUid }: Lead) =>
    `${getInternalApiEndpointBase(leadUid)}assign-to-me`,
  [LeadAvailablePipelineAction.CANCEL_ALTERATION_REQUEST]: (lead: Lead) =>
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    `/api/internal/airbnb/alterations/${lead.airbnbAlteration.uid}/CANCEL`,
  [LeadAvailablePipelineAction.CANCEL_BOOKING]: ({ uid: leadUid }: Lead) =>
    `${getInternalActionsApiEndpointBase(leadUid)}cancel`,
  [LeadAvailablePipelineAction.CLOSE_LEAD]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}close`,
  [LeadAvailablePipelineAction.DECLINE_PENDING]: ({ uid: leadUid }: Lead) =>
    `${getInternalActionsApiEndpointBase(leadUid)}decline-booking-request`,
  [LeadAvailablePipelineAction.EXTEND_HOLD]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}extend-hold`,
  [LeadAvailablePipelineAction.IGNORE_LEAD]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}ignore`,
  [LeadAvailablePipelineAction.MARK_AS_BOOKED]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}mark-as-booked`,
  [LeadAvailablePipelineAction.PLACE_ON_HOLD]: (_lead: Lead) =>
    internalActionsApiEndpointBase,
  [LeadAvailablePipelineAction.REBOOK]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}rebook`,
  [LeadAvailablePipelineAction.REOPEN]: ({ uid: leadUid }: Lead) =>
    `${getApiEndpointBase(leadUid)}reopen`,
  [LeadAvailablePipelineAction.REVOKE_HOLD]: ({ uid: leadUid }: Lead) =>
    `${getInternalActionsApiEndpointBase(leadUid)}revoke-hold`,
  [LeadAvailablePipelineAction.SEND_QUOTE]: (_lead: Lead) =>
    internalActionsApiEndpointBase,
  [LeadAvailablePipelineAction.SEND_CHECK_IN_INSTRUCTIONS]: (
    _leadUid: string,
  ) => internalActionsApiEndpointBase,
};

const availableActionToRequestParams = {
  [LeadAvailablePipelineAction.SEND_QUOTE]: {
    action: LeadPipelineAction.SEND_QUOTE,
  },
  [LeadAvailablePipelineAction.PLACE_ON_HOLD]: {
    action: LeadPipelineAction.PLACE_ON_HOLD,
  },
  [LeadAvailablePipelineAction.SEND_CHECK_IN_INSTRUCTIONS]: {
    action: LeadPipelineAction.SEND_CHECK_IN_INSTRUCTIONS,
  },
};

function getActionRequestData(
  action: LeadAvailablePipelineAction,
  formData: LeadActionFormData,
) {
  // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const requestParams = availableActionToRequestParams[action];

  if (formData) {
    return {
      ...(requestParams || {}),
      ...formData,
    };
  }

  return requestParams;
}

const useExecuteLeadPipelineAction = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: executeAction, isLoading: isExecutingAction } =
    useAppMutation(
      ['leadActionHandler'],
      ({ action, lead, formData }: ExecuteLeadActionPayload) => {
        // @ts-expect-error TS2551 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const endpointUrlProvider = actionToEndpointUrl[action];

        if (!endpointUrlProvider) {
          throw new Error(`Not implemented lead action: ${action}`);
        }

        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const data = getActionRequestData(action, formData);
        const url = endpointUrlProvider(lead);

        return API.post(url, data);
      },
      {
        onSuccess: (
          _response: AxiosResponse<LeadActionResponseTO>,
          { updateLeadCallback }: ExecuteLeadActionPayload,
        ) => {
          updateLeadCallback();
        },
        onError: (_err, { customErrorMessage }) => {
          notifyError(
            customErrorMessage ||
              t('componentLead.actionsInfo.executeActionError'),
          );
        },
      },
    );

  return {
    executeAction,
    isExecutingAction,
  };
};

export default useExecuteLeadPipelineAction;
