import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import GenericFormField from '../genericFormField/GenericFormField';
import { AsyncTypeaheadStyled } from './AsyncTypeAheadField.styles';
import {
  AsyncSearchFoundOption,
  AsyncTypeAheadFieldProps,
} from './AsyncTypeAheadField.types';

const AsyncTypeAheadField: React.FC<AsyncTypeAheadFieldProps> = ({
  align,
  allowNew,
  className,
  name,
  id = name,
  labelKey,
  placeholder,
  instanceRef: externalInstanceRef,
  renderMenuItemChildren,
  searchHandler,
  onChange: onChangeProp,
  onKeyDown,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<AsyncSearchFoundOption[]>([]);
  const { t } = useTranslation();

  const filterBy = useCallback(() => true, []);

  const handleSearch = useCallback(
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    async (query) => {
      setIsLoading(true);

      await searchHandler(query).then((foundOptions) => {
        setOptions(foundOptions);
        setIsLoading(false);
      });
    },
    [searchHandler],
  );

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const fieldRenderer = ({ field: { ref, onChange, onBlur } }) => {
    return (
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      <AsyncTypeaheadStyled
        align={align}
        allowNew={allowNew}
        className={className}
        clearButton
        id={id}
        filterBy={filterBy}
        isLoading={isLoading}
        labelKey={labelKey}
        minLength={3}
        instanceRef={ref}
        ref={(typeaheadInstance) => {
          if (externalInstanceRef) {
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            externalInstanceRef.current = typeaheadInstance;
          }
          return typeaheadInstance;
        }}
        onBlur={onBlur}
        onChange={(option) => {
          onChangeProp?.(option as AsyncSearchFoundOption[]);
          onChange(option);
        }}
        onKeyDown={onKeyDown}
        onSearch={handleSearch}
        options={options}
        placeholder={placeholder}
        renderMenuItemChildren={renderMenuItemChildren}
        emptyLabel={t('form.typeAhead.noResults')}
        promptText={t('form.typeAhead.typeToSearch')}
        searchText={t('form.typeAhead.searching')}
        newSelectionPrefix={t('form.typeAhead.searchFor')}
        inputProps={{
          // @ts-ignore - needs to be ignored due to Typescript limitation https://github.com/microsoft/TypeScript/issues/28960
          'data-testid': id,
        }}
      />
    );
  };

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...props} />
  );
};

export default AsyncTypeAheadField;
