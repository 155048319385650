import { useContext } from 'react';
import LeadModalContext from '../../LeadModalContext';

export const useLeadActionInitialValues = () => {
  const { lead } = useContext(LeadModalContext);
  return {
    leadUid: lead?.uid,
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    price: lead.order?.totalAmount,
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    currency: lead.order?.currency,
    checkIn: lead?.checkInDate,
    checkOut: lead?.checkOutDate,
  };
};
