import { useEffect, useRef } from 'react';
import Button from 'components/button/Button';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import TextField from 'components/forms/textField/TextField';
import { DiscountCodeType } from 'models/Discounts';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppUser from 'hooks/useAppUser';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import { Property } from 'models/Properties';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const AddForm = ({ properties }) => {
  const { t } = useTranslation();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { watch } = useFormContext();
  const { agency } = useAppUser();

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const type = watch('type');
  const validFromDate = watch('validFromDate');

  return (
    <>
      <Row>
        <Col xs={12} sm={8}>
          <TextField
            inputRef={nameInputRef}
            required
            name="name"
            maxLength={15}
            label={t('common.name')}
            help={t('pageDiscountCodes.pageAddDiscount.helpName')}
            // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            onKeyPress={(e) => {
              if (!/[a-zA-Z0-9]/.test(e.key)) e.preventDefault();
            }}
          />
          <PropertySelectField
            colSmInput={10}
            containerClassName="property-container"
            name="propertyUid"
            emptyValueLabel={t('common.all')}
            pictureAsLabel={false}
            properties={properties as unknown as Property[]}
            showUnits={false}
            enableHotels={false}
            label={t('common.property')}
          />

          <TextField
            required
            name="type"
            label={t('common.type')}
            componentClass="select"
          >
            <option value="AMOUNT">{t('common.amount')}</option>
            <option value="PERCENT">{t('common.percent')}</option>
          </TextField>
          <TextField
            required
            leftAddon={
              type === DiscountCodeType.PERCENT ? '%' : agency?.currencySymbol
            }
            label={t('common.amount')}
            name="amount"
            type="number"
            max={type === DiscountCodeType.PERCENT ? 100 : 10000}
            min={0}
          />
          <DateTimePickerField
            required
            label={t('pageDiscountCodes.pageAddDiscount.expirationDate')}
            name="expirationDate"
            minDate={new Date()}
          />
        </Col>
      </Row>

      <p className="valid-only-text">
        {t('pageDiscountCodes.pageAddDiscount.validOnlyInBetween')}:
      </p>
      <Row>
        <Col xs={6} sm={4}>
          <DateTimePickerField
            required
            label={t('common.start')}
            name="validFromDate"
            colSmLabel={4}
            colSmInput={8}
            minDate={new Date()}
          />
        </Col>
        <Col xs={6} sm={4}>
          <DateTimePickerField
            required
            label={t('common.end')}
            name="validToDate"
            colSmLabel={4}
            colSmInput={8}
            minDate={validFromDate}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Col xs={12} sm={10} smOffset={2} className="col-align">
            <CheckboxField name="singleUse" className="help-block">
              {t('pageDiscountCodes.pageAddDiscount.onlyUsedOnce')}
            </CheckboxField>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={8}>
          <hr />
          <div className="buttons pull-right">
            <Button bsStyle="primary" type="submit">
              {t('common.save')}
            </Button>
            <Button onClick={() => navigate('/discount-codes')}>
              {t('common.cancel')}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AddForm;
