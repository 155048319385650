import styled from 'styled-components';

export const RatingCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  & .tooltip-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
