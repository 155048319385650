import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import { AirbnbGuestReview } from 'models/Reviews';
import useNotify from 'hooks/useNotify';
import useAirbnbGuestReviewMutations, {
  RESPOND_AIRBNB_GUEST_REVIEW_MUTATION_KEY,
} from 'components/domain/review/useAirbnbGuestReviewMutations';
import { AIRBNB_REVIEWS_BASE_QUERY_KEY } from '../GuestReviews.utils';
import ViewAirbnbGuestReviewModal, {
  VIEW_RESPOND_GUEST_REVIEW_MODAL_ID,
} from './ViewAirbnbGuestReviewModal';

const useViewAirbnbGuestReviewModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useNotify();
  const { openModal, closeModal } = useAppModal();
  const { respondAirbnbGuestReview } = useAirbnbGuestReviewMutations();

  const refetchTableData = () => {
    queryClient.invalidateQueries({
      queryKey: [AIRBNB_REVIEWS_BASE_QUERY_KEY],
    });
  };

  const openViewRespondModal = (review: AirbnbGuestReview) => {
    const handleRespondSubmit = (response: string) => {
      respondAirbnbGuestReview({
        reviewUid: review.uid,
        response,
      })
        .then(() => {
          closeModal(VIEW_RESPOND_GUEST_REVIEW_MODAL_ID);
          notifySuccess(
            t(
              'pageProperty.reviews.airbnbGuest.guestReviews.modal.respondSuccess',
            ),
          );
        })
        .catch(() => {
          notifyError(
            t(
              'pageProperty.reviews.airbnbGuest.guestReviews.modal.respondError',
            ),
          );
        })
        .finally(() => {
          refetchTableData();
        });
    };

    openModal({
      id: VIEW_RESPOND_GUEST_REVIEW_MODAL_ID,
      customContent: (
        <ViewAirbnbGuestReviewModal
          review={review}
          mutationKey={[RESPOND_AIRBNB_GUEST_REVIEW_MUTATION_KEY]}
          onSubmit={handleRespondSubmit}
        />
      ),
    });
  };

  return {
    openViewRespondModal,
  };
};

export default useViewAirbnbGuestReviewModal;
