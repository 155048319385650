import Badge from 'components/badge/Badge';
import { DropdownButton } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const imageHeight = '150px';

const photoGridContainerCommonStyles = css`
  display: grid;

  // given Bootstrap's container, it will generate 4 columns at desktop, 3 columns where it fits...
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  ${mediaQuery.xs} {
    // ...and 2 columns on mobile
    grid-template-columns: repeat(2, 1fr);
  }

  gap: 16px;
  padding: 16px;
`;

export const PhotoGridContainer = styled.div<{ $isDisabled?: boolean }>`
  ${photoGridContainerCommonStyles}

  .dragging {
    opacity: 0.5;
    cursor: grabbing;
  }

  .drag-over {
    & img {
      border: 2px dashed ${theme.colors.hostfullyYellow};
      border-radius: 8px;
    }
  }

  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'unset')};
  filter: ${({ $isDisabled }) => ($isDisabled ? 'grayscale(80%)' : 'none')};
  transition: opacity 0.3s ease-in-out;
`;

export const PhotoCardContainer = styled.div<{
  $isCover?: boolean;
  $isDisabled?: boolean;
  $isSelectMode?: boolean;
  $isSelected?: boolean;
}>`
  cursor: ${({ $isDisabled, $isSelectMode }) => {
    if ($isDisabled) return 'not-allowed';
    if ($isSelectMode) return 'pointer';
    return 'grab';
  }};

  .photo-card-content {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  .photo-card-content:hover {
    outline: ${({ $isSelectMode, $isSelected }) => {
      if (!$isSelectMode) return '2px solid #3a84d8';
      if ($isSelected) return '2px solid #3a84d8';
      return 'none';
    }};
  }

  &:active {
    cursor: ${({ $isSelectMode }) => ($isSelectMode ? 'unset' : 'grabbing')};
  }

  opacity: ${({ $isSelectMode, $isSelected }) =>
    $isSelectMode && !$isSelected ? 0.5 : 1};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      .photo-card-content {
        outline: 2px solid #3a84d8;
      }
    `}
`;

export const PhotoImage = styled.img`
  width: 100%;
  height: ${imageHeight};
  object-fit: cover;
`;

export const PhotoCoverBadge = styled(Badge)`
  position: absolute;
  top: 8px;
  left: 8px;

  background-color: ${theme.colors.hostfullyYellow};
  color: #212529;

  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;

export const PhotoGridLoaderSkeleton = styled.div`
  ${photoGridContainerCommonStyles}

  font-size: ${imageHeight};
`;

export const PhotoActionsDropdownContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  width: 28px;
  height: 28px;
`;

export const PhotoActionsDropdownStyled = styled(DropdownButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;

  width: 28px;
  height: 28px;

  border-radius: 50%;

  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;

export const PhotoDescriptionContainer = styled.div<{ $isDisabled: boolean }>`
  margin: 0px;
  padding-top: 8px;

  textarea {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 13px;
    line-height: 20px;
    padding: 4px;

    height: 48px;
    color: inherit;
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
    border-radius: 4px;

    resize: none;

    outline: none;

    overflow: hidden;

    &:hover {
      border: 1px solid ${theme.colors.gray600};
    }

    &:focus {
      border: 2px solid #3a84d8;
      overflow: auto;
    }

    &::placeholder {
      color: ${theme.colors.gray600};
    }
  }
`;

export const PhotoSelectedIndicator = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  width: 28px;
  height: 28px;

  background-color: ${theme.colors.light};
  border-radius: 50%;

  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #3a84d8;
  }
`;

export const PhotoUploadOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;
