import { useTranslation } from 'react-i18next';
import AreaSizeField from 'components/forms/domain/property/areaSizeField/AreaSizeField';

const PropertySizeField = () => {
  const { t } = useTranslation();

  return (
    <AreaSizeField
      areaUnitTypeFieldName="propertyDetails.propertySizeUnit"
      label={t('pageProperty.mainSettings.propertyDetails.propertySize')}
      name="propertyDetails.propertySize"
      step="any"
      min={0}
      colSmInput={7}
      colSmLabel={5}
      rightTooltip={t(
        'pageProperty.mainSettings.propertyDetails.propertySizeTooltip',
      )}
      required
    />
  );
};

export default PropertySizeField;
