import { useState } from 'react';
import Button from 'components/button/Button';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import useFetchEntityTags from 'components/domain/tag/useFetchEntityTags';
import useFetchPopularTags from 'components/domain/tag/useFetchPopularTags';
import FormWithProvider from 'components/forms/form/Form';
import { Channel } from 'models/Channels';
import { Photo } from 'models/Photos';
import { TagType } from 'models/Tags';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import useDeletePhoto from 'pages/property/photos/hooks/useDeletePhoto';
import useInvalidatePropertyPhotos from 'pages/property/photos/hooks/useInvalidatePropertyPhotos';
import useFetchTagSuggestions from 'components/domain/tag/useFetchTagSuggestions';
import usePhotoMutation from '../usePhotoMutation';
import useAirbnbRooms from './useAirbnbRooms';
import PhotoEditModalLoader from './PhotoEditModalLoader';
import PhotoEditModalFields from './PhotoEditModalFields';
import { PhotoEditModalFooter, PhotoImage } from './PhotoEditModal.styles';
import {
  PhotoEditFormValuesType,
  photoEditModalSchemaResolver,
} from './PhotoEditModal.schema';
import { PhotoEditModalId } from './PhotoEditModal.constants';
import useUpdatePhotoTags from './useUpdatePhotoTags';

const PhotoEditModal = ({ photo }: { photo: Photo }) => {
  const [isUpdatingPhotoAndTags, setIsUpdatingPhotoAndTags] = useState(false);
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();

  const { channelSettings, isFetching: isFetchingChannelSettings } =
    useFetchPropertyChannelSettings(photo?.propertyUid);

  const isAirbnbActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.airbnb,
  )?.isActive;

  const { tags, isFetching: isFetchingTags } = useFetchEntityTags(
    photo?.uid,
    TagType.PHOTO,
    true,
  );
  const { data: popularTags, isFetching: isFetchingPopularTags } =
    useFetchPopularTags({
      type: TagType.PHOTO,
    });
  const { tags: availableTags, isFetching: isFetchingAvailableTags } =
    useFetchTagSuggestions(TagType.PHOTO, !!photo?.uid);

  const { rooms = [], isFetching: isFetchingRooms } = useAirbnbRooms({
    propertyUid: photo?.propertyUid,
    enabled: isAirbnbActive,
  });

  const { updatePhoto } = usePhotoMutation();
  const { handleTagsUpdate } = useUpdatePhotoTags(photo.uid);
  const { openDeletePhotoModal } = useDeletePhoto();

  const isFetching =
    isFetchingChannelSettings ||
    isFetchingTags ||
    isFetchingRooms ||
    isFetchingPopularTags ||
    isFetchingAvailableTags;

  const defaultValues = {
    tags,
    popularTags: popularTags?.map(({ tag }) => ({
      name: tag,
      isPopular: true,
    })),
    availableTags,
    mapToRoom: photo?.airbnbRoomId != null,
    airbnbRoomId: photo?.airbnbRoomId,
    caption: photo?.description,
  };

  const airbnbRoomOptions = [
    {
      label: t('pageProperty.photos.editModal.selectRoom'),
      value: null,
    },
    ...rooms.map((room) => ({
      label: room.name,
      value: room.airbnbRoomId,
    })),
  ];

  const handleSubmit = async (values: PhotoEditFormValuesType) => {
    setIsUpdatingPhotoAndTags(true);
    await updatePhoto({
      updatedPhoto: {
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        airbnbRoomId: values.mapToRoom ? values.airbnbRoomId : null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        description: values.caption,
      },
      photoUid: photo?.uid,
    });

    const currentTagNames = tags?.map((tag) => tag.name) || [];
    const newTagNames = values.tags?.map((tag) => tag.name) || [];

    await handleTagsUpdate(currentTagNames, newTagNames);

    setIsUpdatingPhotoAndTags(false);
    invalidatePropertyPhotos();
    closeModal(PhotoEditModalId);
  };

  const handleDelete = () => {
    openDeletePhotoModal({
      photoUid: photo.uid,
      onDelete: () => {
        closeModal(PhotoEditModalId);
      },
    });
  };

  if (isFetching) {
    return <PhotoEditModalLoader />;
  }

  return (
    <FormWithProvider
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      resolver={photoEditModalSchemaResolver}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('pageProperty.photos.editModal.title')}</Modal.Title>
      </Modal.Header>

      <fieldset disabled={isUpdatingPhotoAndTags}>
        <Modal.Body>
          <PhotoImage src={photo.originalImageUrl} />

          <PhotoEditModalFields
            isAirbnbActive={isAirbnbActive}
            airbnbRoomOptions={airbnbRoomOptions}
          />
        </Modal.Body>

        <PhotoEditModalFooter>
          <Button
            bsStyle="danger"
            data-testid="edit-modal-delete-photo"
            onClick={handleDelete}
          >
            {t('common.delete')}
          </Button>
          <Button bsStyle="primary" type="submit">
            {t('common.save')}
          </Button>
        </PhotoEditModalFooter>
      </fieldset>
    </FormWithProvider>
  );
};

export default PhotoEditModal;
