import { useContext, useEffect } from 'react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import useAppUser from 'hooks/useAppUser';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { Trans, useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { LeadModeType } from 'models/DashboardLeadModes';
import { dashboardHideProcessLeadsLocalStorageKey } from 'utils/constants/localStorageKeys';
import useAnalyticsInit from 'pages/analytics/useAnalyticsInit';
import AnalyticsList from 'pages/analytics/cards/AnalyticsList';
import AnalyticsFiltersWrapper from 'pages/analytics/filters/AnalyticsFiltersWrapper';
import PageHeader from 'components/pageHeader/PageHeader';
import ChannelPieChart from 'pages/analytics/charts/ChannelPieChart';
import RevParPieChart from 'pages/analytics/charts/RevParPieChart';
import { PresetDateRange } from 'utils/dateTimeUtils';
import { format } from 'date-fns';
import AnalyticsContext from 'pages/analytics/AnalyticsContext';
import { DateType } from 'pages/analytics/types/Analytics.types';
import InfoIcon from 'components/icons/InfoIcon';
import LeadsList from '../leadsList/LeadsList';
import DashboardContext from '../DashboardContext';
import {
  TabsWrapper,
  ChartsWrapper,
  DashboardAnalytics,
  HideCheckboxWrapper,
  InfoIconWrapper,
} from './Content.styles';

const Content = () => {
  const { t } = useTranslation();
  const { agency, isEmployeeAssociate } = useAppUser();
  const { setLeadModeType, leadModeType, isNewTab } =
    useContext(DashboardContext);
  const { filter } = useContext(AnalyticsContext);
  const { hideProcessedLeads } = useWatch();
  const { isLoading: isAnalyticsLoading } = useAnalyticsInit();

  useEffect(() => {
    if (hideProcessedLeads) {
      localStorage.setItem(dashboardHideProcessLeadsLocalStorageKey, 'true');
    } else {
      localStorage.removeItem(dashboardHideProcessLeadsLocalStorageKey);
    }
  }, [hideProcessedLeads]);

  return (
    <>
      <PageHeader
        title={
          <>
            <span className="green">{agency?.name}</span>{' '}
            {t('common.dashboard')}
          </>
        }
      />
      {!isEmployeeAssociate && (
        <DashboardAnalytics data-testid="dashboard-analytics-cards-container">
          <Col xs={12} className="dashboard-analytics-header">
            <span>
              {t('pageDashboard.metricsShownForThisMonthPeriod', {
                date: filter && format(filter.range.startDate, 'MMMM yyyy'),
              })}{' '}
              <Trans
                i18nKey="pageDashboard.forDetailsGoToAnalytics"
                defaults="For full details on your business metrics, go to <link1>Analytics</link1>."
                components={{
                  link1: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                    <a
                      data-testid="page-subtitle-guest-portal-link"
                      href="#/analytics"
                    />
                  ),
                }}
              />
            </span>
            <AnalyticsFiltersWrapper
              // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              isLoading={isAnalyticsLoading}
              showOnlyCurrencyFilter
              forcePresetDateRange={PresetDateRange.THIS_MONTH}
              forceDateType={DateType.OCCUPANCY}
            />
          </Col>
          <AnalyticsList />
        </DashboardAnalytics>
      )}

      <Row>
        <Col xs={12} md={8}>
          {/*
           // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          <TabsWrapper $isAnalyticsHidden={isEmployeeAssociate}>
            <Tabs
              activeKey={leadModeType}
              id="dashboard-tabs"
              onSelect={(tabName) => setLeadModeType(tabName)}
            >
              <Tab eventKey={LeadModeType.NEW} title={t('pageDashboard.new')} />
              <Tab
                eventKey={LeadModeType.CHECKIN}
                title={t('pageDashboard.checkins')}
              />
              <Tab
                eventKey={LeadModeType.CHECKOUT}
                title={t('pageDashboard.checkouts')}
              />

              <HideCheckboxWrapper>
                {isNewTab && (
                  <>
                    <CheckboxField
                      data-testid="dashboard-hide-processed-leads-checkbox"
                      name="hideProcessedLeads"
                    >
                      {t('pageDashboard.hideProcessedLeads')}
                    </CheckboxField>

                    <InfoIconWrapper>
                      <InfoIcon
                        id="property-channel-widget-info"
                        tooltipPlacement="bottom"
                        tooltipText={t(
                          'pageDashboard.hideProcessedLeadsTooltip',
                        )}
                      />
                    </InfoIconWrapper>
                  </>
                )}
              </HideCheckboxWrapper>
            </Tabs>

            <LeadsList />
          </TabsWrapper>
        </Col>
        {!isEmployeeAssociate && (
          <Col xsHidden md={4}>
            <ChartsWrapper>
              <ChannelPieChart />
              <RevParPieChart />
            </ChartsWrapper>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Content;
