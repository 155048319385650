import { useContext, useMemo } from 'react';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import {
  getLeadCheckInDate,
  getLeadCheckOutDate,
  isLeadAMainBooking,
  isLeadASubBooking,
} from 'utils/lead/leadUtils';
import { addDays, format, isSameDay } from 'date-fns';
import { Lead } from 'models/Leads';
import LeadTooltip from 'components/domain/lead/LeadTooltip';
import { isCheckinVisible, isCheckoutVisible } from '../Calendar.utils';
import { LeadViewWrapper } from './LeadView.style';
import LeadViewInner from './LeadViewInner';

interface Props {
  lead: Lead;
  date: Date;
}

const LeadView = ({ lead, date }: Props) => {
  const { daysLeadsMap } = useContext(CalendarContext);
  const { uid } = lead;

  const isMainBooking = isLeadAMainBooking(lead);
  const isSubBooking = isLeadASubBooking(lead);

  if (isMainBooking) {
    return null;
  }

  const isOverlapping = useMemo(() => {
    const checkIn = getLeadCheckInDate(lead);
    const checkOut = getLeadCheckOutDate(lead);

    let isOverlappingLead = false;

    // loop for every day between checkIn and checkOut:
    for (let day = checkIn; day <= checkOut; day = addDays(day, 1)) {
      const leads = daysLeadsMap.current[format(day, 'yyyy-MM-dd')];
      if (leads && leads.length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        leads.forEach((l) => {
          const isCurrentLeadClicked = l.uid === uid;

          if (!isCurrentLeadClicked) {
            const otherLead = l;
            const { checkIn: otherLeadCheckIn, checkOut: otherLeadCheckOut } =
              otherLead;

            if (
              isSameDay(otherLeadCheckIn, checkOut) ||
              isSameDay(otherLeadCheckOut, checkIn)
            ) {
              isOverlappingLead = false;
            } else {
              isOverlappingLead = true;
            }
          }
        });

        break;
      }
    }

    return isOverlappingLead;
  }, [lead, daysLeadsMap.current]);

  return (
    <LeadViewWrapper
      data-lead-uid={uid}
      data-main-booking-uid={isSubBooking ? lead.groupUid : null}
      $date={date}
      $lead={lead}
      $isCheckinVisible={isCheckinVisible(date, lead)}
      $isCheckoutVisible={isCheckoutVisible(date, lead)}
    >
      <LeadTooltip lead={lead} isOverlapping={isOverlapping} />
      <LeadViewInner date={date} lead={lead} />
    </LeadViewWrapper>
  );
};

export default LeadView;
