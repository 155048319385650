import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import { GetPropertiesCountResponseTO } from 'models/PropertiesCount';
import API from 'services/API';

export const PROPERTIES_COUNT_BASE_QUERY_KEY = 'property-count';

type ReactQueryOptions = Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>;

type UsePropertiesCountArgs = {
  topLevelOnly?: boolean;
} & ReactQueryOptions;

export const usePropertiesCount = ({
  topLevelOnly,
  ...options
}: UsePropertiesCountArgs = {}) => {
  const { agencyUid } = useAppUser();

  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PROPERTIES_COUNT_BASE_QUERY_KEY, agencyUid, topLevelOnly],
    queryFn: async () => {
      const response = await API.get<GetPropertiesCountResponseTO>(
        '/api/internal/properties/count',
        { params: { topLevelOnly } },
      );
      return response.data.propertiesCount;
    },
    enabled: !!agencyUid,
    staleTime: 30000,
    ...(options ?? {}),
  });
};
