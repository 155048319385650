import { useCallback } from 'react';
import useAppUserPermissions from '../hooks/useAppUserPermissions';
import { restrictedRoutes } from './router.constants';

const useHasRouteAccess = () => {
  const permissions = useAppUserPermissions();
  return useCallback(
    (route: string) => {
      if (route.startsWith('#')) {
        route = route.substring(1);
      }

      const restrictedRoute = restrictedRoutes[route];
      if (!restrictedRoute) {
        return true;
      }

      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const hasPermission = (permission) => permissions.includes(permission);
      return restrictedRoute.permissions.every(hasPermission);
    },
    [permissions],
  );
};

export default useHasRouteAccess;
