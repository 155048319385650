import { useContext } from 'react';
import useFetchLead from 'components/domain/lead/useFetchLead';
import { Template, TemplateEntityType } from 'models/Templates';
import { Lead } from 'models/Leads';
import useLeadActionsFacade from 'components/domain/lead/actions/useLeadActionsFacade';
import TemplateModalContext from './TemplateModalContext';
import useFetchTemplate from './useFetchTemplate';
import {
  LeadPipelineTemplateAction,
  TemplateName,
} from './TemplateModal.types';
import useFetchThreadUid from './useFetchThreadUid';

const leadFields = `uid,
  address,
  address2,
  adultCount,
  agreementSigned,
  arrivalLocalDateTime,
  assignee{bestProfile, uid},
  availablePipelineActions,
  bookedDateTimeString,
  cellPhoneNumber,
  channel{bestProfile, channel},
  checkInLocalDateTime,
  checkOutLocalDateTime,
  childrenCount,
  city,
  countdown{color, countdownMinutes, countdownPercentage, visualCountdownValue},
  countryCode,
  departureLocalDateTime,
  email,
  firstName,
  hasCompletedPreArrivalForm,
  infantCount,
  lastName,
  notes,
  order{uid, currency, totalAmount, force100PercentPaymentAtReservation, securityDeposit, paymentLinkSecret, paymentLinkUrl, balance},
  property{uid, name, mainPicture {
    largeThumbnail
  }},
  petCount,
  phoneNumber,
  preferredLocale,
  property{uid},
  reviewByHost{cleanlinessRating, communicationRating, notes, overallRating, respectRulesRating, uid},
  secondaryEmail,
  source,
  state,
  status,
  wereCheckInInstructionsSent,
  zipCode,
  willHaveParty,
  willHaveVisit,
  passportID,
  passportCountryCode,
  customData{uid, name, type, value{text, longText}},
  guest{identificationDocumentUrl, registrationToken, uid},
  extraNotes,
  flightNumber,
  reasonForTrip,
  rentalAgreementURL,
  creationMetadata,
  externalBookingID,
  pinCode{pinCode, pinCodeUrl, uid}
  wasSecurityDepositReceived,
  wasSecurityDepositReturned,
  extraGuests{email, firstName, lastName, age},
  fieldsPermissionsMetadata{fieldName,metadata {editable}},
  assignee{bestProfile, uid, picture},
  leadType,
  referrer,
  autoRefundFail,
  autoRefundOk,
  paidInFull,
  creator{type},
  createdDateTimeString,
  unitUid
`;

const useLeadTemplateModalData = ({
  templateName,
  leadPipelineTemplateAction,
  leadUid,
  jobUid,
}: {
  templateName: TemplateName;
  leadPipelineTemplateAction: LeadPipelineTemplateAction;
  leadUid?: string;
  jobUid?: string;
}): {
  isLoading: boolean;
  lead: Lead;
  threadUid: string;
  fetchedTemplate: Template;
  recipients: string[];
  sendCopyToAgency: boolean;
} => {
  const { setLead } = useContext(TemplateModalContext);
  const { isFetching: isFetchingLead, lead } = useFetchLead({
    leadUid,
    fields: leadFields,
  });
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const { threadUid, isFetchingThreadUid } = useFetchThreadUid(leadUid);
  const { isLoadingTemplate, fetchedTemplate } = useFetchTemplate(
    {
      name: templateName,
    },
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    leadUid || jobUid,
    leadUid ? TemplateEntityType.LEAD : TemplateEntityType.JOB,
  );

  const {
    fetchedLeadFacadeTemplate,
    isLoadingLeadFacadeTemplate,
    recipients,
    copyAgency,
  } = useLeadActionsFacade({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    leadUid,
    leadPipelineTemplateAction,
  });

  if (lead) {
    setLead(lead);
  }

  return {
    isLoading:
      (isFetchingLead && !!leadUid) ||
      isLoadingTemplate ||
      isLoadingLeadFacadeTemplate ||
      (isFetchingThreadUid && !!leadUid),
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    lead,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    threadUid,
    fetchedTemplate: fetchedTemplate || fetchedLeadFacadeTemplate,
    recipients: recipients || [lead?.email] || ['Service Provider email'],
    sendCopyToAgency: copyAgency || false,
  };
};

export default useLeadTemplateModalData;
