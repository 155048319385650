import LoadingSectionPlaceholder from 'pages/property/LoadingSectionPlaceholder';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import { useTranslation } from 'react-i18next';

const PriceSettingsLoader = () => {
  const { t } = useTranslation();

  return (
    <CollapseSectionStyled
      defaultExpanded
      label={t('pageProperty.pricing.priceSettings.title')}
      variant="card"
    >
      <LoadingSectionPlaceholder columns={2} lines={3} linesCount={2} />
    </CollapseSectionStyled>
  );
};

export default PriceSettingsLoader;
