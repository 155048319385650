import useFetchDescriptions from 'components/domain/property/useFetchDescriptions';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { Channel } from 'models/Channels';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import ChannelFilter from '../common/channelFilter/ChannelFilter';
import useEnabledChannels from '../common/channelFilter/useEnabledChannels';
import FormFooter from '../common/FormFooter';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { propertySettingsDescriptionsTabSchema } from './PropertySettingsDescriptionsTab.schema';
import { PropertySettingsDescriptionsTabStyled } from './PropertySettingsDescriptionsTab.styles';
import PropertySettingsDescriptionsTabFormFields from './PropertySettingsDescriptionsTabFormFields';
import PropertySettingsDescriptionsTabLoader from './PropertySettingsDescriptionsTabLoader';
import useUpdateDescription from './useUpdateDescription';
import { SUPPORTED_CHANNELS } from './PropertySettingsDescriptionsTab.constants';

const PropertySettingsDescriptionsTab = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const [searchParams] = useSearchParams();
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();

  const currentLanguage =
    searchParams.get('language') || LanguageCodeUnderscored.EN_US;

  const { handleUpdateDescriptionSubmit } = useUpdateDescription({
    currentLanguage,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyUid,
  });

  const { data: descriptions, isLoading: isLoadingDescriptions } =
    useFetchDescriptions({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUid,
    });

  const {
    channels,
    activeChannels,
    activeChannelsAtPropertyLevel,
    toggleChannel,
    isFetching: isFetchingEnabledChannels,
  } = useEnabledChannels({
    defaultEnabledChannels: [Channel.orbirental],
    supportedChannels: SUPPORTED_CHANNELS,
  });

  const isLoading =
    isLoadingInitData || isLoadingDescriptions || isFetchingEnabledChannels;

  if (isLoading) {
    return <PropertySettingsDescriptionsTabLoader />;
  }

  const currentDescription = descriptions?.find(
    ({ locale }) => locale === currentLanguage,
  );

  return (
    <PropertySettingsDescriptionsTabStyled data-testid="descriptions-tab">
      <PropertyEditPermissionInfo />

      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.descriptions.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        noDivider
      />

      <ChannelFilter
        channels={channels}
        label={t(
          'pageProperty.descriptions.showSupportedDescriptionsPerChannel',
        )}
        toggleChannel={toggleChannel}
      />

      <FormWithProvider
        defaultValues={currentDescription}
        resolver={propertySettingsDescriptionsTabSchema({
          activeChannelsAtPropertyLevel,
          t,
        })}
        onSubmit={handleUpdateDescriptionSubmit}
        dirtyChecker
        noValidate
        // Re render the form when the language changes
        key={currentLanguage}
      >
        <PropertySettingsDescriptionsTabFormFields
          activeChannels={activeChannels}
          activeChannelsAtPropertyLevel={activeChannelsAtPropertyLevel}
        />

        <FormFooter
          noBulkSave
          saveButtonLabel={t('pageProperty.descriptions.saveButton')}
        />
      </FormWithProvider>
    </PropertySettingsDescriptionsTabStyled>
  );
};

export default PropertySettingsDescriptionsTab;
