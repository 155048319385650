import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';

const useDeleteOrderAdjustment = ({ orderUid }: { orderUid: string }) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: deleteOrderAdjustment, isLoading } = useAppMutation(
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    ({ uid }: { successCallback: (response) => void; uid: string }) =>
      API.delete(`/api/internal/orders/${orderUid}/adjustment-items/${uid}`),
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.(response.data);
      },
      onError: () => {
        notifyError(
          t('componentOrderAdjustment.error.couldNotDeleteOrderAdjustment'),
        );
      },
    },
  );

  return {
    deleteOrderAdjustment,
    isLoading,
  };
};

export default useDeleteOrderAdjustment;
