import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Photo } from 'models/Photos';
import { PHOTOS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPhotos';
import { useSelectModeContext } from '../../context/SelectModeContext';
import useDeletePhoto from '../../hooks/useDeletePhoto';
import useInvalidatePropertyPhotos from '../../hooks/useInvalidatePropertyPhotos';

const useDeleteSelectedPhotos = () => {
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { selectedPhotos } = useSelectModeContext();
  const { deletePhoto } = useDeletePhoto();
  const { openConfirmModal } = useAppModal();
  const queryClient = useQueryClient();
  const { uid: propertyUid } = useParams();
  const queryKey = [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid];
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();

  const deletePhotos = async () => {
    try {
      await Promise.all(
        Array.from(selectedPhotos).map((photoUid) =>
          deletePhoto({ photoUid }).then(() => {
            queryClient.setQueryData(
              queryKey,
              (oldData: Photo[] | undefined) => {
                if (!oldData) return [];
                return oldData.filter((photo) => photo.uid !== photoUid);
              },
            );
          }),
        ),
      );
    } catch (error) {
      notifyError(t('pageProperty.photos.deleteSelectedModal.error'));
    } finally {
      selectedPhotos.clear();
      invalidatePropertyPhotos();
    }
  };

  const handleDelete = () => {
    openConfirmModal({
      title: t('pageProperty.photos.deleteSelectedModal.title', {
        count: selectedPhotos.size,
      }),
      body: t('pageProperty.photos.deleteSelectedModal.description', {
        count: selectedPhotos.size,
      }),
      onConfirm: () => {
        deletePhotos();
      },
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
    });
  };

  return { handleDelete };
};

export default useDeleteSelectedPhotos;
