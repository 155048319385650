import { useMemo } from 'react';
import { PropertyUnitType } from 'models/Properties';
import { PropertiesBodyListStyled } from 'pages/agencyProperties/body/PropertiesBody.styles';
import PropertyListItem from 'pages/agencyProperties/item/PropertyListItem';
import { useWatch } from 'react-hook-form';
import { isUidString } from 'utils/stringUtils';

const UnitTypeList = ({
  unitTypes = [],
}: {
  unitTypes: PropertyUnitType[];
}) => {
  const searchQuery = useWatch({
    name: 'unitTypeSearch',
    defaultValue: '',
  });

  const filteredUnitTypes = useMemo(() => {
    const query = searchQuery?.toLowerCase() || '';
    const isUid = isUidString(query);

    return unitTypes.filter((unitType) => {
      if (isUid) {
        return unitType.uid === query;
      }
      return unitType.name.toLowerCase().includes(query);
    });
  }, [unitTypes, searchQuery]);

  return (
    <PropertiesBodyListStyled id="unit-types-list">
      {filteredUnitTypes.map((unitType) => (
        <PropertyListItem key={unitType.uid} property={unitType} />
      ))}
    </PropertiesBodyListStyled>
  );
};

export default UnitTypeList;
