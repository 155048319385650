import { useContext } from 'react';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';
import useFetchLead from 'components/domain/lead/useFetchLead';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import useFetchAllProperties from '../../property/useFetchAllProperties';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';
import { AirbnbAlterationResponse } from './AirbnbAlteration.types';

const leadFields = `uid,
  adultCount,
  channel{bestProfile, channel},
  checkInLocalDateTime,
  checkOutLocalDateTime,
  childrenCount,
  countdown{color, countdownMinutes, countdownPercentage, visualCountdownValue},
  email,
  firstName,
  lastName,
  infantCount,
  order{uid, currency, totalAmount, force100PercentPaymentAtReservation, securityDeposit, paymentLinkSecret, paymentLinkUrl, balance, balanceDueLocalDate, includeSecurityDepositInTotal},
  petCount,
  property{uid, businessType, pricing {fullPaymentTiming}},
  source,
  status,
  wereCheckInInstructionsSent,
`;

const useAirbnbAlterationModalData = () => {
  const {
    availableProperties,
    setLead,
    currentLeadUid,
    setAvailableProperties,
  } = useContext(AirbnbAlterationRequestModalContext);
  const {
    allProperties: fetchedAvailableProperties,
    isFetching: isFetchingAvailableProperties,
  } = useFetchAllProperties({
    enabled: !availableProperties,
    conditions: propertyCalendarConditionGraphQLQuery,
    fields: propertyCalendarGraphQLQuery,
  });
  const { isFetching: isFetchingLead, lead } = useFetchLead({
    leadUid: currentLeadUid,
    fields: leadFields,
  });

  const { data: airbnbAlterationData, isLoading: isAirbnbAlterationLoading } =
    useAppQuery(
      ['airbnb-alteration', currentLeadUid],
      async () => {
        const response = await API.get<AirbnbAlterationResponse>(
          `api/internal/airbnb/alterations?leadUid=${currentLeadUid}&status=PENDING`,
        );

        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        return (response.data?.[0] as AirbnbAlterationResponse) || null;
      },
      {
        enabled: !!currentLeadUid,
      },
    );

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  setLead(lead);

  if (fetchedAvailableProperties) {
    setAvailableProperties(fetchedAvailableProperties);
  }

  return {
    isLoading:
      isFetchingAvailableProperties ||
      isFetchingLead ||
      isAirbnbAlterationLoading,
    lead,
    airbnbAlterationData,
  };
};

export default useAirbnbAlterationModalData;
