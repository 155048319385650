const comparator = new Intl.Collator('en-US', { numeric: true });

export const compare = (valueA: unknown, valueB: unknown) => {
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return comparator.compare(valueA, valueB);
  }

  // @ts-expect-error TS18046 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  if (valueA > valueB) {
    return 1;
  }

  // @ts-expect-error TS18046 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  if (valueA < valueB) {
    return -1;
  }

  return 0;
};
