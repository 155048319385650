import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import FormWithProvider from 'components/forms/form/Form';
import AlertMessage from 'pages/login/loginDesktop/AlertMessage';
import Button from 'components/button/Button';
import TextField from 'components/forms/textField/TextField';
import useForgotPasswordSubmit from '../useForgotPasswordSubmit';

const ForgotPasswordDesktop = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { submitResetPassword } = useForgotPasswordSubmit({
    onSuccess: () => navigate('/login?msg=2'),
    onError: () => navigate('/login?msg=auth'),
  });

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleSubmitForgotPassword = (values) => {
    submitResetPassword(values.email);
  };

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmitForgotPassword}
      schema={{}}
    >
      <Row>
        <Col xs={6} xsOffset={3}>
          <AlertMessage />
          <h2 data-testid="page-title">{t('pageForgotPassword.title')}</h2>
          <TextField
            name="email"
            type="email"
            required
            placeholder={t('pageForgotPassword.emailAddress')}
            colSmInput={12}
          />
          <Button type="submit" block bsSize="large">
            {t('common.submit')}
          </Button>
        </Col>
      </Row>
    </FormWithProvider>
  );
};

export default ForgotPasswordDesktop;
