import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import TextField from 'components/forms/textField/TextField';
import { useFormState, useWatch } from 'react-hook-form';
import InfoIcon from 'components/icons/InfoIcon';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { Lead } from 'models/Leads';
import { Transaction } from 'models/Transactions';
import { getTransactionCreatedDate } from 'utils/tests/transaction/transactionUtils';
import {
  TransactionsListItemAmountColumn,
  TransactionsListItemColumn,
  TransactionsListItemDetailsColumn,
} from '../OrderViewTransactionsListItem.styles';
import OrderViewTransactionsListItemType from '../OrderViewTransactionsListItemType';
import OrderViewTransactionsListItemDetails from '../OrderViewTransactionsListItemDetails';
import TransactionAmountRenderer from '../TransactionAmountRenderer';
import {
  IssueRefundAmountRow,
  IssueRefundTransactionDetailsSection,
  IssueRefundTransactionDetailsWrapper,
  SecurityDepositInfoIconContainer,
} from './IssueRefundModalFormElements.styles';
import { IssueRefundFormValues } from './IssueRefundModal.types';

const IssueRefundModalFormElements = ({
  lead,
  onCancel,
  transaction,
}: {
  lead: Lead;
  onCancel: () => void;
  transaction: Transaction;
}) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const { isSubmitting } = useFormState();
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();
  const { canReturnSecurityDeposit } = useWatch<IssueRefundFormValues>();

  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    order: { currency },
  } = lead;

  return (
    <>
      <ModalBody>
        <div data-testid="issue-refund-info">
          {t('pageOrder.transactions.actions.issueRefundInfo')}
        </div>
        <IssueRefundTransactionDetailsSection>
          <IssueRefundTransactionDetailsWrapper data-testid="transaction-details">
            <TransactionsListItemColumn data-testid="creation-date" xs={3}>
              {t('common.dateMedium', {
                date: getTransactionCreatedDate(transaction),
              })}
            </TransactionsListItemColumn>
            <TransactionsListItemColumn xs={2}>
              <OrderViewTransactionsListItemType transaction={transaction} />
            </TransactionsListItemColumn>
            <TransactionsListItemDetailsColumn data-testid="details" xs={6}>
              <OrderViewTransactionsListItemDetails transaction={transaction} />
            </TransactionsListItemDetailsColumn>
            <TransactionsListItemAmountColumn xs={1}>
              <TransactionAmountRenderer
                lead={lead}
                transaction={transaction}
              />
            </TransactionsListItemAmountColumn>
          </IssueRefundTransactionDetailsWrapper>
        </IssueRefundTransactionDetailsSection>
        <IssueRefundAmountRow>
          <TextField
            label={t('pageOrder.transactions.actions.refundAmount')}
            name="amount"
            colSmInput={7}
            colSmLabel={5}
            labelOnTop={isXS}
            leftAddon={getCurrencySymbol(currency)}
            required
          />
        </IssueRefundAmountRow>
        {canReturnSecurityDeposit && (
          <Row data-testid="mark-sd-as-returned-option">
            <Col smOffset={5} sm={7}>
              <CheckboxField name="markSecurityDepositAsReturned">
                {t(
                  'pageOrder.transactions.actions.markSecurityDepositAsReturned',
                )}
                <SecurityDepositInfoIconContainer>
                  <InfoIcon
                    id="mark-sd-as-returned-info"
                    tooltipPlacement="bottom"
                    tooltipText={t(
                      'pageOrder.transactions.actions.markSecurityDepositAsReturnedInfo',
                    )}
                  />
                </SecurityDepositInfoIconContainer>
              </CheckboxField>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={isSubmitting} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" disabled={isSubmitting} type="submit">
          {t('pageOrder.transactions.actions.confirmRefund')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default IssueRefundModalFormElements;
