import { FC } from 'react';
import TextField from '../textField/TextField';
import { SelectFieldProps } from './SelectField.types';

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function renderOptions(options) {
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return options?.map(({ disabled, id, label, value }) => (
    <option disabled={disabled} id={id} key={value} value={value}>
      {label}
    </option>
  ));
}

const SelectField: FC<SelectFieldProps> = ({
  options,
  optionGroups,
  ...rest
}) => {
  if (!options && !optionGroups) {
    throw new Error('Either options or optionGroups must be specified');
  }

  return (
    <TextField componentClass="select" {...rest}>
      {renderOptions(options)}
      {optionGroups?.map(({ groupLabel, options: groupOptions }) => (
        <optgroup key={groupLabel} label={groupLabel}>
          {renderOptions(groupOptions)}
        </optgroup>
      ))}
    </TextField>
  );
};

export default SelectField;
