import { useQueryClient } from '@tanstack/react-query';
import { PHOTOS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPhotos';
import { Photo } from 'models/Photos';
import { useParams } from 'react-router-dom';

const usePropertyPhotosData = () => {
  const { uid: propertyUid } = useParams();
  const queryClient = useQueryClient();
  const queryKey = [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid];

  const getPhotos = () => queryClient.getQueryData<Photo[]>(queryKey) ?? [];

  return { getPhotos };
};

export default usePropertyPhotosData;
