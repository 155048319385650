import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import {
  OrderViewPriceDetailsListWrapper,
  PriceDetailsListTotal,
} from 'pages/orderView/priceDetails/OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItemTotal from 'pages/orderView/priceDetails/OrderViewPriceDetailsListItemTotal';
import { Alert, Col, Glyphicon, Row } from 'react-bootstrap';
import Tooltip from 'components/tooltip/Tooltip';
import Loader from 'components/loader/Loader';
import useAirbnbAlterationModalQuoteData from './useAirbnbAlterationModalQuoteData';
import useAirbnbAlterationModalData from './useAirbnbAlterationModalData';
import { AirbnbAlterationTitleRequestOrder } from './AirbnbAlterationRequestOrder.styles';
import useAirbnbAlterationModalUpdateQuoteData from './useAirbnbAlterationModalUpdateQuoteData';

const AirbnbAlterationRequestOrder = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { airbnbAlterationData } = useAirbnbAlterationModalData();
  const { quote, isQuoteLoading } = useAirbnbAlterationModalQuoteData();
  const { updatedQuote, isUpdatedQuoteLoading, apiErrorMessage } =
    useAirbnbAlterationModalUpdateQuoteData();

  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    order: { currency },
  } = lead;

  if (!quote) {
    return null;
  }

  const originalTotal = quote?.expectedPayout;
  const newTotal =
    updatedQuote?.expectedPayout ||
    airbnbAlterationData?.payoutAmount ||
    originalTotal;

  return (
    <>
      <Row>
        <Col xs={12}>
          <AirbnbAlterationTitleRequestOrder data-testid="host-payout-details">
            {t('componentLead.alterationRequest.hostPayoutDetails')}
            <Tooltip
              text={t(
                'componentLead.alterationRequest.hostPayoutDetailsTooltip',
              )}
              id="order-tooltip"
            >
              <Glyphicon glyph="info-sign" />
            </Tooltip>
          </AirbnbAlterationTitleRequestOrder>
        </Col>
      </Row>

      {(isQuoteLoading || isUpdatedQuoteLoading) && <Loader />}

      {!apiErrorMessage && !isQuoteLoading && !isUpdatedQuoteLoading && (
        <OrderViewPriceDetailsListWrapper
          $isLoading={false}
          data-testid="price-details-wrapper"
          className="price-details-wrapper"
        >
          <PriceDetailsListTotal
            className="price-details-list-total"
            data-testid="price-details-list-total"
          >
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-total"
              label={t('componentLead.alterationRequest.originalTotal')}
              amount={originalTotal}
            />
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-total"
              label={t('componentLead.alterationRequest.newTotal')}
              amount={newTotal}
            />
            <OrderViewPriceDetailsListItemTotal
              currency={currency}
              data-testid="price-details-list-item-total-total"
              label={t('componentLead.alterationRequest.priceDifference')}
              amount={newTotal - originalTotal}
            />
          </PriceDetailsListTotal>
        </OrderViewPriceDetailsListWrapper>
      )}
      {apiErrorMessage && !isQuoteLoading && !isUpdatedQuoteLoading && (
        <Alert bsStyle="danger">{apiErrorMessage}</Alert>
      )}
    </>
  );
};

export default AirbnbAlterationRequestOrder;
