import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetPropertyByUidResponseTO, PropertyByUid } from 'models/Properties';
import API from 'services/API';

export const PROPERTY_BY_UID_BASE_QUERY_KEY = 'property-by-uid';

type ReactQueryOptions = Omit<
  UseQueryOptions<PropertyByUid>,
  'queryKey' | 'queryFn'
>;

type UseFetchPropertyByUidArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchPropertyByUid = ({
  propertyUid,
  enabled,
  ...options
}: UseFetchPropertyByUidArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PROPERTY_BY_UID_BASE_QUERY_KEY],
    queryFn: async () => {
      const response = await API.get<GetPropertyByUidResponseTO>(
        `/v3/properties/${propertyUid}`,
      );
      return response.data.property;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchPropertyByUid;
