/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect } from 'react';
import { isEqual, set } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import TextField from 'components/forms/textField/TextField';
import {
  isLeadAMainBooking,
  isLeadBooked,
  isLeadFieldDisabled,
} from 'utils/lead/leadUtils';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import LeadModalContext from '../../LeadModalContext';
import GuestInformation from './GuestInformation';
import {
  Container,
  RowSpacer,
  SideInfo,
} from './LeadModalStayDetailsTab.styles';
import PinCodeForm from './PinCodeForm';

const LeadModalStayDetailsTab = () => {
  const { lead, availableProperties } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext();

  const checkInDate = watch('checkInDate');
  const checkOutDate = watch('checkOutDate');
  const arrivalDate = watch('arrivalDate');
  const departureDate = watch('departureDate');
  const propertyUid = watch('propertyUid');
  const leadUid = watch('uid');
  const updateMode = !!leadUid;

  useEffect(() => {
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    if (checkInDate && !isEqual(checkInDate, defaultValues.checkInDate)) {
      const newDate = set(checkInDate, {
        hours: arrivalDate.getHours(),
        minutes: arrivalDate.getMinutes(),
      });

      setValue('arrivalDate', newDate);
    }

    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    if (checkOutDate && !isEqual(checkOutDate, defaultValues.checkOutDate)) {
      const newDate = set(checkOutDate, {
        hours: departureDate.getHours(),
        minutes: departureDate.getMinutes(),
      });

      setValue('departureDate', newDate);
    }
  }, [checkInDate, checkOutDate, setValue]);

  useEffect(() => {
    if (!propertyUid || updateMode) {
      return;
    }

    const selectedProperty = availableProperties?.find(
      (p) => p.uid === propertyUid,
    );

    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    if (selectedProperty?.availability?.checkInTimeStart > -1) {
      const newDate = set(arrivalDate, {
        hours: selectedProperty?.availability?.checkInTimeStart,
        minutes: 0,
      });

      setValue('arrivalDate', newDate);
    }

    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    if (selectedProperty?.availability?.checkOutTime > -1) {
      const newDate = set(departureDate, {
        hours: selectedProperty?.availability?.checkOutTime,
        minutes: 0,
      });

      setValue('departureDate', newDate);
    }
  }, [propertyUid, availableProperties, setValue, updateMode]);

  return (
    <Container>
      <Row>
        <Col xs={8} md={7}>
          <div>
            {/*
             // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
            {!isLeadAMainBooking(lead) && (
              <>
                <div>
                  <DateTimePickerField
                    colSmInput={5}
                    colSmLabel={3}
                    label={t('componentLead.modal.stayDetailsTab.checkIn')}
                    name="arrivalDate"
                    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                    disabled={isLeadFieldDisabled(lead, 'arrivalLocalDateTime')}
                  />
                  <DateTimePickerField
                    colSmInput={4}
                    name="arrivalDate"
                    timeOnly
                    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                    disabled={isLeadFieldDisabled(lead, 'arrivalLocalDateTime')}
                  />
                </div>

                <RowSpacer />

                <DateTimePickerField
                  colSmInput={5}
                  colSmLabel={3}
                  label={t('componentLead.modal.stayDetailsTab.checkOut')}
                  name="departureDate"
                  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                  disabled={isLeadFieldDisabled(lead, 'departureLocalDateTime')}
                />
                <DateTimePickerField
                  colSmInput={4}
                  name="departureDate"
                  timeOnly
                  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                  disabled={isLeadFieldDisabled(lead, 'departureLocalDateTime')}
                />

                <RowSpacer />

                {/*
                 // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                {isLeadBooked(lead) && <PinCodeForm />}

                <RowSpacer />
              </>
            )}

            <TextField
              name="extraNotes"
              label={t('componentLead.modal.stayDetailsTab.extraNotes')}
              placeholder={t(
                'componentLead.modal.stayDetailsTab.extraNotesPlaceholder',
              )}
              componentClass="textarea"
              colSmInput={9}
              colSmLabel={3}
              rows={6}
            />

            <hr />

            <TextField
              label={t('componentLead.modal.stayDetailsTab.flightNumber')}
              name="flightNumber"
              colSmInput={9}
              colSmLabel={3}
            />
            <RowSpacer />
            <TextField
              label={t('componentLead.modal.stayDetailsTab.reasonForTrip')}
              name="reasonForTrip"
              colSmInput={9}
              colSmLabel={3}
            />
          </div>
        </Col>
        <Col xs={4} md={4} mdOffset={1}>
          <SideInfo>
            {/*
             // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
            {!isLeadAMainBooking(lead) && (
              <>
                <Row>
                  <Col xs={12}>
                    <p>
                      {t(
                        'componentLead.modal.stayDetailsTab.expectedCheckInOutProperty',
                      )}
                    </p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            <GuestInformation />
          </SideInfo>
        </Col>
      </Row>
    </Container>
  );
};

export default LeadModalStayDetailsTab;
