import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import {
  AirbnbAlterationQuote,
  AirbnbAlterationResponse,
} from './modal/AirbnbAlteration.types';

const useAirbnbAlterationActions = () => {
  const { notifySuccess, notifyError } = useNotify();
  const { t } = useTranslation();

  const handleError = ({
    apiErrorCode,
    apiErrorMessage,
    defaultMessageKey,
  }: {
    apiErrorCode?: string;
    apiErrorMessage?: string;
    defaultMessageKey: any;
  }) => {
    if (apiErrorCode || apiErrorMessage) {
      notifyError(
        getErrorMessage({
          apiErrorCode,
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          apiErrorMessage,
          baseKey: 'componentLead.alterationRequest.errors.',
          t,
        }),
      );
      return;
    }

    notifyError(t(defaultMessageKey));
  };

  const { mutateAsync: declineAlterationRequest, isLoading: isDeclining } =
    useAppMutation(
      ({
        airbnbAlteration,
      }: {
        airbnbAlteration: AirbnbAlterationResponse;
      }) => {
        return API.post(
          `/api/internal/airbnb/alterations/${airbnbAlteration.uid}/DECLINE`,
        );
      },
      {
        onError: ({ response }) => {
          const apiErrorCode = response?.data?.apiErrorCode;
          const apiErrorMessage = response?.data?.apiErrorMessage;

          handleError({
            apiErrorCode,
            apiErrorMessage,
            defaultMessageKey:
              'componentLead.alterationRequest.action.message.bookingWasNotSuccessfullyUpdated',
          });
        },
        onSuccess: () => {
          notifySuccess(
            t(
              `componentLead.alterationRequest.action.message.bookingWasSuccessfullyDeclined`,
            ),
          );
        },
      },
    );

  const { mutateAsync: acceptAlterationRequest, isLoading: isAccepting } =
    useAppMutation(
      ({
        airbnbAlteration,
      }: {
        airbnbAlteration: AirbnbAlterationResponse;
      }) => {
        return API.post(
          `/api/internal/airbnb/alterations/${airbnbAlteration.uid}/ACCEPT`,
        );
      },
      {
        onError: ({ response }) => {
          const apiErrorCode = response?.data?.apiErrorCode;
          const apiErrorMessage = response?.data?.apiErrorMessage;

          handleError({
            apiErrorCode,
            apiErrorMessage,
            defaultMessageKey: `componentLead.alterationRequest.action.message.bookingWasNotSuccessfullyUpdated`,
          });
        },
        onSuccess: () => {
          notifySuccess(
            t(
              `componentLead.alterationRequest.action.message.bookingWasSuccessfullyAccepted`,
            ),
          );
        },
      },
    );

  const { mutateAsync: cancelAlterationRequest, isLoading: isCanceling } =
    useAppMutation(
      ({
        airbnbAlteration,
      }: {
        airbnbAlteration: AirbnbAlterationResponse;
      }) => {
        return API.post(
          `/api/internal/airbnb/alterations/${airbnbAlteration.uid}/CANCEL`,
        );
      },
      {
        onError: ({ response }) => {
          const apiErrorCode = response?.data?.apiErrorCode;
          const apiErrorMessage = response?.data?.apiErrorMessage;

          handleError({
            apiErrorCode,
            apiErrorMessage,
            defaultMessageKey:
              'componentLead.alterationRequest.action.message.bookingWasNotSuccessfullyCanceled',
          });
        },
        onSuccess: () => {
          notifySuccess(
            t(
              `componentLead.alterationRequest.action.message.bookingWasSuccessfullyCanceled`,
            ),
          );
        },
      },
    );

  const { mutateAsync: sendAlterationRequest, isLoading: isSending } =
    useAppMutation(
      ({
        airbnbAlterationQuote,
      }: {
        airbnbAlterationQuote: AirbnbAlterationQuote;
      }) => {
        return API.post(
          `/api/internal/airbnb/alterations`,
          airbnbAlterationQuote,
        );
      },
      {
        onError: ({ response }) => {
          const apiErrorCode = response?.data?.apiErrorCode;
          const apiErrorMessage = response?.data?.apiErrorMessage;

          handleError({
            apiErrorCode,
            apiErrorMessage,
            defaultMessageKey: `componentLead.alterationRequest.action.message.bookingWasNotSuccessfullySent`,
          });
        },
        onSuccess: () => {
          notifySuccess(
            t(
              `componentLead.alterationRequest.action.message.bookingWasSuccessfullySent`,
            ),
          );
        },
      },
    );

  return {
    acceptAlterationRequest,
    isAccepting,
    declineAlterationRequest,
    isDeclining,
    sendAlterationRequest,
    isSending,
    cancelAlterationRequest,
    isCanceling,
  };
};

export default useAirbnbAlterationActions;
