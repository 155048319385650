import {
  DragEvent,
  DragEventHandler,
  MutableRefObject,
  useContext,
  useRef,
} from 'react';
import {
  addDays,
  differenceInCalendarDays,
  format,
  startOfDay,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useAppEvent from 'hooks/useAppEvent';
import API from 'services/API';
import { AppEventType } from 'models/Events';
import { LeadInternalResponse, LeadStatus } from 'models/Leads';
import useNotify from 'hooks/useNotify';
import { DraggableLead } from 'pages/calendar/common/body/selection/CalendaBodySelection.types';
import CalendarBodySelectionService from 'pages/calendar/common/body/selection/CalendarBodySelectionService';
import useAppModal from 'hooks/useAppModal';
import theme from 'styles/theme';
import CalendarContext from '../CalendarContext';
import {
  MOVE_BOOK_WARNING_DISMISSED,
  stackedCalendarStyleConstants,
} from '../Calendar.constants';
import WarningDragAndDropModalBody from '../WarningDragAndDropModalBody';

export const DragAndDropLeadsUnitCleanUpData = {
  requiresCleanUp: false,
};

const useDragAndDropLeadsUnit = () => {
  const draggingLead = useRef<DraggableLead>(null);
  const { publish } = useAppEvent();
  const { propertiesMap, leadsMap, mode } = useContext(CalendarContext);
  const { notifyError } = useNotify();
  const { openConfirmModal } = useAppModal();
  const { t } = useTranslation();

  const { mutateAsync: patchLead } = useAppMutation(
    ['patch-lead'],
    async ({
      leadUid,
      payload,
    }: {
      leadUid: string;
      payload: Partial<LeadInternalResponse['lead']>;
    }) => {
      const response = await API.patch<LeadInternalResponse>(
        `/api/internal/leads/${leadUid}`,
        payload,
      );

      return response.data.lead;
    },
  );

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const activateDropZone = (e) => {
    e.preventDefault();
    // hide the LeadTooltip
    document.querySelector(`#tooltip`)?.setAttribute('style', 'display: none');
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    const { leadUid, color } = JSON.parse(e.dataTransfer.getData('leadUid'));
    const leadPropertyUid = leadsMap[leadUid].property.uid;
    const { propertyUid: finalPropertyUid, dayDate: finalDayDate } =
      (e.target as HTMLElement).dataset || {};
    const { unitTypeUid: finalUnitTypeUid } =
      // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertiesMap[finalPropertyUid] || {};

    if (
      finalUnitTypeUid &&
      leadPropertyUid &&
      leadPropertyUid === finalUnitTypeUid
    ) {
      const isLeadBlocked = leadsMap[leadUid].status === LeadStatus.BLOCKED;
      // we should find the day cell that is the start of the lead
      const dayCell = document.querySelector(
        `[data-day-date="${
          isLeadBlocked
            ? finalDayDate
            : // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              startOfDay(leadsMap[leadUid].checkInDate).getTime()
        }"][data-property-uid="${finalPropertyUid}"]`,
      );

      const leadView = document.querySelector(
        `[data-lead-uid="${leadUid}"][draggable="true"]`,
      );

      const allIndividuallyDayCellLeads = document.querySelectorAll(
        `[data-lead-uid="${leadUid}"]`,
      );

      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const clonedLeadView = leadView.cloneNode(true) as HTMLDivElement;

      // hide the original leadView and all dayCells lead colored part:
      allIndividuallyDayCellLeads.forEach((lead) =>
        lead.setAttribute('style', `display: none`),
      );
      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      leadView.setAttribute('style', `display: none`);
      clonedLeadView.style.background = color;
      clonedLeadView.style.borderRadius = '5px';
      clonedLeadView.style.opacity = '0.5';

      // unique class to identify the cloned leadView and remove it later:
      clonedLeadView.classList.add('cloned-lead-view');
      DragAndDropLeadsUnitCleanUpData.requiresCleanUp = true;

      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      dayCell.appendChild(clonedLeadView);

      const amountOfDays = differenceInCalendarDays(
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        leadsMap[leadUid].checkOutDate,
        leadsMap[leadUid].checkInDate,
      );

      const checkInLocalDate = new Date(Number(finalDayDate));
      const checkOutLocalDate = addDays(checkInLocalDate, amountOfDays);

      const cancelDrop = () => {
        clonedLeadView.remove();
        allIndividuallyDayCellLeads.forEach((lead) =>
          lead.setAttribute('style', `display: block`),
        );
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        leadView.setAttribute('style', `display: block`);
      };

      const updateLead = () => {
        patchLead({
          leadUid,
          payload: {
            ...(isLeadBlocked
              ? {
                  checkInLocalDate: format(checkInLocalDate, 'yyyy-MM-dd'),
                  checkOutLocalDate: format(checkOutLocalDate, 'yyyy-MM-dd'),
                }
              : {}),
            unitUid: finalPropertyUid,
          },
        })
          .then((lead) => {
            publish(AppEventType.LEAD_UPDATED, { lead });
          })
          .catch(() => {
            notifyError(
              t('pageCalendar.errorMessages.unitNotAvailableForSelectedDates'),
            );

            cancelDrop();
          });
      };

      const warningDismissed =
        localStorage.getItem(MOVE_BOOK_WARNING_DISMISSED) === 'true';

      if (!warningDismissed) {
        openConfirmModal({
          title: t('pageCalendar.dragAndDrop.warningTitle'),
          body: <WarningDragAndDropModalBody />,
          onConfirm: () => updateLead(),
          onHide: () => {
            window.localStorage.removeItem(MOVE_BOOK_WARNING_DISMISSED);
            cancelDrop();
          },
          onCancel: () => {
            window.localStorage.removeItem(MOVE_BOOK_WARNING_DISMISSED);
            cancelDrop();
          },
          confirmLabel: t('common.yes'),
          cancelLabel: t('common.no'),
        });
      } else {
        updateLead();
      }
    }
  };

  const handleDragEnterRow = (
    e: DragEvent<HTMLDivElement>,
    propertyUid: string,
    lastElementHighlighted: MutableRefObject<HTMLDivElement>,
  ) => {
    if (!draggingLead.current) {
      // @ts-expect-error TS2540 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      draggingLead.current =
        CalendarBodySelectionService.getSelectionContainer().dragging;
    }

    const {
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      leadPropertyUid,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      leadStatus,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkIn,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkOut,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      widthPixels,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      borderColor,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      leadUid,
    } = draggingLead.current;

    const target = e.nativeEvent.target as HTMLDivElement;

    const enteredUnitType =
      // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertiesMap[target.dataset.propertyUid]?.unitTypeUid;
    if (
      !target.dataset.dayDate ||
      leadPropertyUid !== enteredUnitType ||
      !leadPropertyUid ||
      !enteredUnitType
    ) {
      return;
    }

    const width = stackedCalendarStyleConstants.daysCellWidth[mode];

    if (leadStatus !== LeadStatus.BLOCKED) {
      const dayCellStart = document.querySelector(
        `[data-day-date='${startOfDay(
          checkIn,
        ).getTime()}'][data-property-uid='${propertyUid}']`,
      ) as HTMLDivElement;

      if (
        lastElementHighlighted.current &&
        lastElementHighlighted.current.dataset.propertyUid !== propertyUid
      ) {
        lastElementHighlighted.current.classList.remove('drop-highlight');
      }

      if (dayCellStart) {
        lastElementHighlighted.current = dayCellStart;
        dayCellStart.setAttribute(
          'style',
          `--drop-highlight-width: ${widthPixels}px !important; 
                          --drop-highlight-left: ${width * 0.63}px !important;
                          --drop-highlight-border-color: ${
                            borderColor || theme.colors.hostfullyBlue
                          } !important;
                            `,
        );
        dayCellStart.classList.add('drop-highlight');
      }
    } else {
      const currentDayCell = target;
      const amountOfNights = differenceInCalendarDays(checkOut, checkIn);

      const newCheckOutDate = addDays(
        new Date(Number(currentDayCell.dataset.dayDate)),
        amountOfNights,
      );

      const checkOutDateElement = document.querySelector(
        `[data-day-date='${newCheckOutDate.getTime()}'][data-property-uid='${propertyUid}']`,
      );

      if (
        checkOutDateElement?.querySelector('[data-lead-uid]') &&
        !checkOutDateElement?.querySelector(`[data-lead-uid="${leadUid}"]`) &&
        !checkOutDateElement?.querySelector('[width]')
      ) {
        currentDayCell.classList.remove('drop-highlight');
        return;
      }

      if (lastElementHighlighted.current) {
        lastElementHighlighted.current.classList.remove('drop-highlight');
      }

      currentDayCell.setAttribute(
        'style',
        `--drop-highlight-width: ${widthPixels}px !important; 
                        --drop-highlight-left: ${width * 0.63}px !important;
                        --drop-highlight-border-color: ${
                          borderColor || theme.colors.hostfullyBlue
                        } !important;
                          `,
      );
      currentDayCell.classList.add('drop-highlight');
      lastElementHighlighted.current = currentDayCell;
    }
  };

  const handleDropRow = (
    lastElementHighlighted: MutableRefObject<HTMLDivElement>,
  ) => {
    lastElementHighlighted.current?.classList?.remove('drop-highlight');
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    lastElementHighlighted.current = null;
    // @ts-expect-error TS2540 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    draggingLead.current = null;
  };

  return [
    {
      onDragOver: activateDropZone,
      onDragEnter: activateDropZone,
      onDrop: handleDrop,
    },
    {
      onDragEnter: handleDragEnterRow,
      onDrop: handleDropRow,
    },
  ] as const;
};

export default useDragAndDropLeadsUnit;
