import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useFetchDescriptions, {
  DESCRIPTIONS_BASE_QUERY_KEY,
} from 'components/domain/property/useFetchDescriptions';
import useUpdateDescriptionMutation from 'components/domain/property/useUpdateDescriptionMutation';
import useNotify from 'hooks/useNotify';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { Channel } from 'models/Channels';
import { Property, PropertyBusinessType } from 'models/Properties';
import useEnabledChannels from 'pages/property/common/channelFilter/useEnabledChannels';
import { SUPPORTED_CHANNELS } from '../PropertySettingsDescriptionsTab.constants';
import { transformDescriptionBasedOnTranslation } from '../PropertySettingsDescriptionsTab.schema';
import useBulkTranslateDescriptions from './useBulkTranslateDescriptions';

interface UseApplyBulkTranslationArgs {
  property: Pick<Property, 'uid' | 'businessType'>;
}

const isFieldEmpty = (value: string) => !value || value.trim() === '';

const useApplyBulkTranslation = ({ property }: UseApplyBulkTranslationArgs) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notifySuccess } = useNotify();
  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const { data: descriptions } = useFetchDescriptions({ propertyUid, isHotel });
  const { mutateAsync: bulkTranslate } = useBulkTranslateDescriptions();
  const { mutateAsync: updateDescription } = useUpdateDescriptionMutation();
  const { activeChannelsAtPropertyLevel } = useEnabledChannels({
    defaultEnabledChannels: [Channel.orbirental],
    supportedChannels: SUPPORTED_CHANNELS,
  });

  const applyBulkTranslation = async (languages: LanguageCodeUnderscored[]) => {
    if (!descriptions) return;

    const englishDescription = descriptions.find(
      ({ locale }) => locale === LanguageCodeUnderscored.EN_US,
    );

    const descriptionFieldsToTranslate = Object.entries(
      englishDescription ?? {},
    ).reduce((result, [key, value]) => {
      if (isFieldEmpty(value) || key === 'locale') return result;

      return { ...result, [key]: value };
    }, {});

    const { translations } = await bulkTranslate({
      descriptions: descriptionFieldsToTranslate,
      propertyUid,
      sourceLanguage: 'en',
      targetLanguages: languages.filter(
        (language) => language !== LanguageCodeUnderscored.EN_US,
      ),
    });

    const updatedDescriptionsPromises = Object.entries(translations).map(
      async ([languageCode, translation]) => {
        const originalDescription = descriptions.find(
          ({ locale }) => locale === languageCode,
        );

        const updatedDescription = transformDescriptionBasedOnTranslation({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          originalDescription,
          translation,
          activeChannelsAtPropertyLevel,
          t,
        });

        await updateDescription({
          ...updatedDescription,
          locale: originalDescription?.locale ?? languageCode,
          propertyUid,
        });
      },
    );

    await Promise.all(updatedDescriptionsPromises);

    await queryClient.invalidateQueries([DESCRIPTIONS_BASE_QUERY_KEY]);

    notifySuccess(
      t('pageProperty.descriptions.manageLanguageModal.autoTranslationSuccess'),
    );
  };

  return applyBulkTranslation;
};

export default useApplyBulkTranslation;
