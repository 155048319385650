import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AsyncTypeAheadFieldInstance } from 'components/forms/typeAheadField/AsyncTypeAheadField.types';
import useAppUser from 'hooks/useAppUser';
import useLocalStorage from 'hooks/useLocalStorage';
import { Lead } from '../../models/Leads';
import { Thread } from '../../models/inbox/Threads';
import {
  AvailableTemplates,
  ThreadFilter,
  ThreadNewMessagesData,
} from './Inbox.types';
import InboxContext from './InboxContext';
import {
  defaultThreadFilter,
  getInboxFilterLocalStorageKey,
} from './filter/InboxFilter.constants';
import { getUpdatedThreadFilter } from './filter/InboxFilter.utils';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const InboxContextProvider = ({ children }) => {
  const { userUid, isEmployee } = useAppUser();
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const inboxFilterLocalStorageKey = getInboxFilterLocalStorageKey(userUid);
  const { getValue: getStoredThreadFilter } = useLocalStorage<ThreadFilter>(
    inboxFilterLocalStorageKey,
  );
  const storedThreadFilter = getStoredThreadFilter();
  const leadSearchFieldRef = useRef<AsyncTypeAheadFieldInstance>();
  const [activeThread, setActiveThread] = useState<Thread>();
  const [activeMessageThreadUid, setActiveMessageThreadUid] =
    useState<string>();
  const [activeThreadLeadUid, setActiveThreadLeadUid] = useState<string>();
  const [activeLead, setActiveLead] = useState<Lead>();
  const [availableTemplates, setAvailableTemplates] =
    useState<AvailableTemplates>();
  const [employees, setEmployees] = useState([]);
  const [leadChannels, setLeadChannels] = useState([]);
  const [properties, setProperties] = useState([]);
  const [propertiesMap, setPropertiesMap] = useState({});
  const [threadFilter, setThreadFilter] = useState<ThreadFilter>(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    storedThreadFilter
      ? getUpdatedThreadFilter(storedThreadFilter)
      : defaultThreadFilter,
  );
  const [
    threadAvailableMessagingChannelsMap,
    setThreadAvailableMessagingChannelsMap,
  ] = useState({});
  const [
    threadParticipantBestProfilesMap,
    setThreadParticipantBestProfilesMap,
  ] = useState({});
  const [threadsWithNewMessages, setThreadsWithNewMessages] =
    useState<ThreadNewMessagesData[]>();
  const [searchParams] = useSearchParams();
  const leadUidParam = searchParams.get('leadUid');
  const threadUidParam = searchParams.get('threadUid');
  const [initialSearchParams, setInitialSearchParams] = useState({
    leadUidParam,
    threadUidParam,
  });

  const contextValue = useMemo(() => {
    return {
      activeThread,
      activeThreadLeadUid,
      activeMessageThreadUid,
      activeLead,
      availableTemplates,
      employees,
      inboxFilterLocalStorageKey,
      leadChannels,
      leadSearchFieldRef,
      properties,
      propertiesMap,
      threadFilter,
      threadAvailableMessagingChannelsMap,
      threadParticipantBestProfilesMap,
      threadsWithNewMessages,
      setAvailableTemplates,
      setEmployees,
      setLeadChannels,
      setProperties,
      setPropertiesMap,
      setThreadsWithNewMessages,
      setActiveMessageThreadUid,
      getInitialSearchParams: () => {
        const params = // the params should be only used once for the initial search
          (initialSearchParams && { ...initialSearchParams }) || {};
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        setInitialSearchParams(null);
        return params;
      },
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateActiveThreadData: (activeThreadData) => {
        const {
          activeThread: updatedActiveThread,
          activeThreadLeadUid: updatedActiveThreadLeadUid,
        } = activeThreadData;
        setActiveThread(updatedActiveThread);
        setActiveThreadLeadUid(updatedActiveThreadLeadUid);

        if (!isEmployee) {
          // This is called when updating the active thread, since guest do not have any active lead, we should update the message thread uid
          setActiveMessageThreadUid(updatedActiveThread?.uid);
        }
      },
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateActiveMessageThreadUid: (_activeMessageThreadUid) => {
        setActiveMessageThreadUid(_activeMessageThreadUid);
      },
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateActiveLead: (activeLeadToSet, onlyIfSame) => {
        setActiveLead((currentActiveLead) =>
          !onlyIfSame || currentActiveLead?.uid === activeLeadToSet?.uid
            ? activeLeadToSet
            : currentActiveLead,
        );
      },
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateThreadFilter: (updatedThreadFilter) => {
        setThreadFilter((currentFilter) => ({
          ...currentFilter,
          ...updatedThreadFilter,
        }));
      },
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateThreadParticipantBestProfilesMap: (bestProfiles) => {
        setThreadParticipantBestProfilesMap((currentMap) => ({
          ...currentMap,
          ...bestProfiles,
        }));
      },
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateThreadAvailableMessagingChannelsMap: (messagingChannels) => {
        setThreadAvailableMessagingChannelsMap((currentMap) => ({
          ...currentMap,
          ...messagingChannels,
        }));
      },
    };
  }, [
    activeThread,
    activeMessageThreadUid,
    activeLead,
    availableTemplates,
    employees,
    inboxFilterLocalStorageKey,
    leadChannels,
    leadSearchFieldRef,
    properties,
    propertiesMap,
    threadFilter,
    threadAvailableMessagingChannelsMap,
    threadParticipantBestProfilesMap,
    threadsWithNewMessages,
  ]);

  return (
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <InboxContext.Provider value={contextValue}>
      {children}
    </InboxContext.Provider>
  );
};

export default InboxContextProvider;
