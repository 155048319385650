import { BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePropertiesCreationAllowed from 'components/domain/property/usePropertiesCreationAllowed';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import { PropertyBusinessType, PropertyUnitType } from 'models/Properties';
import NotFound from 'pages/notFound/NotFound';
import { PropertyPageBreadcrumb } from 'pages/property/common/Common.styles';
import AddPropertyLoadingPlaceholder from '../common/AddPropertyLoadingPlaceholder';
import PropertySettingsTabHotelBreadcrumbItem from '../../PropertySettingsTabHotelBreadcrumbItem';
import usePropertySettingsInitData from '../../usePropertySettingsInitData';
import AddUnitTypeFormElements from './AddUnitTypeFormElements';
import useAddUnitTypeSubmit from './useAddUnitTypeSubmit';
import {
  AddUnitTypeFormValues,
  addUnitTypeSchema,
  getDefaultFormValues,
} from './AddUnitType.schema';

const AddUnitType = () => {
  const { isLoading, property: parentProperty } = usePropertySettingsInitData();
  const propertiesCreationAllowed = usePropertiesCreationAllowed();
  const handleSubmit = useAddUnitTypeSubmit();
  const { t } = useTranslation();

  if (isLoading) {
    return <AddPropertyLoadingPlaceholder />;
  }

  const { businessType, uid } = parentProperty || {};

  if (
    !propertiesCreationAllowed ||
    businessType !== PropertyBusinessType.HOTEL
  ) {
    return <NotFound />;
  }

  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    pricing: { currency },
    uid: hotelUid,
  } = parentProperty;

  const onSubmit = async (formValues: AddUnitTypeFormValues) => {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    await handleSubmit({ formValues, hotelUid });
  };

  const pageTitle = t('common.pageTitles.property/:uid/add/unit-type', {
    nsSeparator: false,
  });

  const property = {
    businessType: PropertyBusinessType.UNIT_TYPE,
    hotelUid: uid,
  } as PropertyUnitType;

  return (
    <div className="container">
      <PropertyPageBreadcrumb>
        <BreadcrumbItem href="#/properties">
          {t('common.pageTitles.properties')}
        </BreadcrumbItem>
        <PropertySettingsTabHotelBreadcrumbItem property={property} />
        <BreadcrumbItem active>{pageTitle}</BreadcrumbItem>
      </PropertyPageBreadcrumb>
      <PageHeader
        title={pageTitle}
        subtitle={t('pageProperty.add.unitType.subtitle')}
        noDivider
      />
      <FormWithProvider
        defaultValues={getDefaultFormValues()}
        resolver={addUnitTypeSchema(currency, t)}
        onSubmit={onSubmit}
        noValidate
      >
        <AddUnitTypeFormElements />
      </FormWithProvider>
    </div>
  );
};

export default AddUnitType;
