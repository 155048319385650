import { useContext, useEffect } from 'react';
import usePrevious from 'hooks/usePrevious';
import { useWatch } from 'react-hook-form';
import { areArraysEqual } from 'utils/arrayUtils';
import InboxContext from '../InboxContext';
import { getUpdatedThreadFilter } from './InboxFilter.utils';

const ThreadFilterFormWatcher = () => {
  const { updateThreadFilter } = useContext(InboxContext);
  const formValues = useWatch();
  const { lead } = formValues;
  const previousLead = usePrevious(lead);

  useEffect(() => {
    // prevents running the search on initial render and makes sure that the search is run on every further user interaction, including changing back to initial value
    // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const leadUids = lead?.map(({ uid }) => uid) || [];
    // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const previousLeadUids = previousLead?.map(({ uid }) => uid) || [];

    if (!areArraysEqual(leadUids, previousLeadUids)) {
      const updatedThreadFilter = getUpdatedThreadFilter(formValues);
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateThreadFilter(updatedThreadFilter);
    }
  }, [lead]);

  return null;
};

export default ThreadFilterFormWatcher;
