import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { RoomType } from 'models/PropertyRooms';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import usePropertySettingsInitData from '../../../usePropertySettingsInitData';
import RoomsAndBedTypesModal from './RoomsAndBedTypesModal';
import { CapacityDetailsRoomsFormValues } from './PropertyCapacityDetails.types';

const modalId = 'rooms-and-bed-types-modal';

const useRoomsAndBedTypesModal = () => {
  const { openModal } = useAppModal();
  const { setValue, watch } =
    useFormContext<PropertySettingsMainTabFormValues>();
  const {
    property: { uid: propertyUid },
  } = usePropertySettingsInitData();
  const { t } = useTranslation();

  const applyCallback = (roomsFormValues: CapacityDetailsRoomsFormValues) => {
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const bedroomCount = roomsFormValues.filter(
      ({ roomType }) => roomType === RoomType.BEDROOM,
    ).length;

    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const bedCount = roomsFormValues.reduce(
      (acc, { beds }) =>
        acc + beds.reduce((roomBeds, { count }) => roomBeds + count, 0),
      0,
    );

    setValue('capacityDetails.bedCount', bedCount);
    setValue('capacityDetails.bedroomCount', bedroomCount);
    setValue('capacityDetails.rooms', roomsFormValues, { shouldDirty: true });
  };

  const openRoomsAndBedTypesModal = () => {
    const initialFormValues = watch('capacityDetails.rooms');

    openModal({
      id: modalId,
      customContent: (
        <RoomsAndBedTypesModal
          modalId={modalId}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          params={{ applyCallback, initialFormValues, propertyUid }}
        />
      ),
      title: t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.title',
      ),
    });
  };

  return { openRoomsAndBedTypesModal };
};

export default useRoomsAndBedTypesModal;
