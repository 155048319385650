import useAppModal from 'hooks/useAppModal';
import { AdjustmentItem } from 'models/order/AdjustmentItem';
import OrderAdjustmentModal from './actions/OrderAdjustmentModal';

const createOrderAdjustmentModalId = 'create-order-adjustment-modal';
const updateOrderAdjustmentModalId = 'update-order-adjustment-modal';

export interface AddOrderAdjustmentModalParams {
  orderUid: string;
  leadUid: string;
  currency: string;
}

export interface UpdateOrderAdjustmentModalParams {
  orderUid: string;
  leadUid: string;
  currency: string;
  adjustmentItem: AdjustmentItem;
}

const useOpenOrderAdjustmentModal = () => {
  const { openModal } = useAppModal();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const openOrderAdjustmentModal = (params) => {
    const id = params.adjustmentItem
      ? updateOrderAdjustmentModalId
      : createOrderAdjustmentModalId;

    openModal({
      customContent: <OrderAdjustmentModal modalId={id} {...params} />,
      size: 'medium',
      id,
    });
  };

  return {
    createOrderAdjustmentModal: (params: AddOrderAdjustmentModalParams) => {
      openOrderAdjustmentModal(params);
    },
    updateOrderAdjustmentModal: (params: UpdateOrderAdjustmentModalParams) => {
      openOrderAdjustmentModal(params);
    },
  };
};

export default useOpenOrderAdjustmentModal;
