import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import { Currency } from 'models/Currency';

const PropertyCleaningFeeField = ({
  currency,
  label,
  name = 'propertyPricingFeesTaxes.cleaningFee',
}: {
  currency: Currency;
  label?: ReactNode;
  name?: string;
}) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const { t } = useTranslation();

  return (
    <TextField
      label={label ?? t('pageProperty.pricing.priceSettings.cleaningFee')}
      name={name}
      colSmInput={7}
      colSmLabel={5}
      leftAddon={getCurrencySymbol(currency)}
      rightTooltip={t('pageProperty.pricing.priceSettings.cleaningFeeTooltip')}
      type="number"
      min={0}
    />
  );
};

PropertyCleaningFeeField.defaultProps = {
  label: undefined,
  name: undefined,
};

export default PropertyCleaningFeeField;
