import { ProgressBar as BSProgressBar } from 'react-bootstrap';
import styled from 'styled-components';

export const ProgressBarStyled = styled(BSProgressBar)<{
  $color?: string;
  $height?: number;
}>`
  margin-bottom: 0px;

  ${({ $height }) => $height && `height: ${$height}px;`}

  & > .progress-bar {
    ${({ $color }) => $color && `background-color: ${$color};`}
  }
`;

export const StarProgressBarContainer = styled.div`
  display: flex;
  align-items: center;

  & > .star-progress-rating {
    margin-right: 5px;
    font-size: 15px;
  }

  & > ${ProgressBarStyled} {
    flex: 1;
    margin-left: 10px;
  }

  & > svg {
    height: 16px;
    width: 16px;
  }
`;
