import useCalendarMode from 'pages/stackedCalendar/useCalendarMode';
import { classNames } from 'utils/classNameUtils';
import { CalendarDayCellSelectionType } from '../../../calendar/common/body/selection/CalendaBodySelection.types';
import useCalendarDayCellSelection from '../../../calendar/common/body/selection/useCalendarDayCellSelection';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const DayCellSelection = ({ dayDate, propertyUid }) => {
  const { isPricingMode } = useCalendarMode();
  const { selectionType } = useCalendarDayCellSelection({
    dayDate,
    propertyUid,
  });

  if (isPricingMode && selectionType === CalendarDayCellSelectionType.PRICING) {
    return (
      <div
        className="day-cell-selection-pricing-container"
        data-testid="day-cell-pricing-selected"
      />
    );
  }

  if (selectionType) {
    return (
      <div
        className={classNames({
          'day-cell-selection-segment-container': true,
          [`selection-${selectionType?.toLowerCase()}`]: !!selectionType,
        })}
      >
        <div
          className="day-cell-selection-segment"
          data-testid={`stacked-calendar-selection-${selectionType}`}
        />
      </div>
    );
  }

  return null;
};

export default DayCellSelection;
