import { useTranslation } from 'react-i18next';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { useFormContext } from 'react-hook-form';
import { Lead } from 'models/Leads';
import { showCleaningFeeRow } from 'utils/lead/leadUtils';
import { QuoteFeeType } from 'models/Quote';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Fees = ({
  order,
  lead,
  allowEdit,
  priceDetailsItems,
}: {
  order: HasOrderItems;
  lead: Lead;
  allowEdit: boolean;
  priceDetailsItems: PriceDetailsItem[];
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const { fees, currency } = order;

  return (
    <PriceDetailsList data-testid="price-details-list-fees">
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.fees')}
        priceDetailsItems={[
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          showCleaningFeeRow({
            lead,
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            cleaningFee: fees?.cleaningFee,
            allowEdit,
          }) && {
            label: t('componentOrder.cleaning'),
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            amount: fees.cleaningFee.netPrice,
            currency,
            isEditable: allowEdit,
            onItemUpdated(val) {
              setValue('quote.fees.cleaningFee.netPrice', val, {
                shouldDirty: true,
              });
            },
          },
          ...priceDetailsItems,
          {
            label: t('componentOrder.totalFees'),
            amount:
              // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              fees.otherFees
                .filter(({ removed, enabled }) => !removed && enabled)
                .filter(({ type }) => type !== QuoteFeeType.TAX)
                .reduce((acc, fee) => acc + fee.netPrice, 0) +
              // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              fees.cleaningFee.netPrice,
            isTotalAmount: true,
            currency,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default Fees;
