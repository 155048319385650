import { createColumnHelper } from '@tanstack/react-table';
import { HouseRule } from 'models/HouseRules';
import { useTranslation } from 'react-i18next';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import { useParams } from 'react-router-dom';
import CommonTable from 'components/table/CommonTable';
import ActionsCell from './cells/ActionsCell';
import { houseRulesQuery } from './HouseRules.utils';

const HouseRulesTable = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<HouseRule>();

  const columns = [
    columnHelper.accessor('description', {
      header: t('pageProperty.feesTaxesAndPolicies.houseRules.description'),
    }),
    // columnHelper.display({
    //   id: 'appliesTo',
    //   header: t('pageProperty.common.cells.appliesTo'),
    //   cell: AppliesToCell,
    // }),
    columnHelper.display({
      id: 'actions',
      header: t('pageProperty.feesTaxesAndPolicies.houseRules.actions'),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getQuery: (options) => houseRulesQuery({ propertyUid, ...options }),
    pageSize: 10,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoading}
      data-testid="house-rules-table"
      emptyState={t('pageProperty.feesTaxesAndPolicies.houseRules.emptyTable')}
    />
  );
};

export default HouseRulesTable;
