import Button from 'components/button/Button';
import Collapse from 'components/collapse/Collapse';
import styled from 'styled-components';

export const AirbnbGuestReviewsCollapseStyled = styled(Collapse)`
  margin: 16px 0px;
`;

export const AirbnbGuestReviewsCollapseLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const ContainerTitle = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 16px;
  }
`;

export const AutomatedReviewsContainer = styled(ContainerTitle)`
  p {
    margin-bottom: 4px;
  }

  #link-my-templates-reviews {
    font-weight: 600;
  }

  a {
    text-decoration: underline;
  }
`;

export const AirbnbGuestReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TemplatesTriggerLinkButton = styled(Button).attrs({
  bsStyle: 'link',
})`
  padding: 0;
  text-decoration: underline;
`;
