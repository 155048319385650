import { Channel } from 'models/Channels';

export const SUPPORTED_CHANNELS = [
  Channel.orbirental,
  Channel.airbnb,
  Channel.homeaway,
  Channel.booking_com,
  Channel.google,
  Channel.hvmi,
];
