import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CurrencySelectField from 'components/forms/currencySelectField/CurrencySelectField';
import TextField from 'components/forms/textField/TextField';
import {
  CollapseSectionStyled,
  FormSectionColumn,
} from 'pages/property/common/Common.styles';
import PropertyCleaningFeeField from 'pages/property/common/pricing/PropertyCleaningFeeField';
import PropertyCleaningFeeTaxField from 'pages/property/common/pricing/PropertyCleaningFeeTaxField';
import PropertyNightlyBasePriceField from '../../common/pricing/PropertyNightlyBasePriceField';
import PropertySecurityDepositField from '../../common/pricing/PropertySecurityDepositField';
import { AddSinglePropertyFormValues } from './AddSingleProperty.schema';

const AddSinglePropertyPricingFeesTaxesSection = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyPricingFeesTaxes: { currency },
  } = useWatch<AddSinglePropertyFormValues>();

  return (
    <CollapseSectionStyled
      data-testid="property-pricing-fees-taxes"
      defaultExpanded
      label={t('pageProperty.add.single.pricingFeesTaxes')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <CurrencySelectField
              label={t('pageProperty.pricing.priceSettings.currency')}
              name="propertyPricingFeesTaxes.currency"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.pricing.priceSettings.currencyTooltip',
              )}
              required
            />
            <PropertyNightlyBasePriceField currency={currency} />
            <TextField
              label={t('pageProperty.pricing.priceSettings.taxRate')}
              name="propertyPricingFeesTaxes.taxRate"
              colSmInput={7}
              colSmLabel={5}
              rightAddons={['%']}
              rightTooltip={t(
                'pageProperty.pricing.priceSettings.taxRateTooltip',
              )}
              type="number"
              min={0}
              max={100}
            />
          </FormSectionColumn>
          <FormSectionColumn>
            <PropertySecurityDepositField
              currency={currency}
              name="propertyPricingFeesTaxes.securityDeposit"
            />
            <PropertyCleaningFeeField currency={currency} />
            <PropertyCleaningFeeTaxField />
          </FormSectionColumn>
        </Row>
      </fieldset>
    </CollapseSectionStyled>
  );
};

export default AddSinglePropertyPricingFeesTaxesSection;
