import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import {
  TranslateDescriptionsPayload,
  TranslateDescriptionsResponse,
} from 'models/Descriptions';
import API from 'services/API';

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    TranslateDescriptionsResponse,
    unknown,
    TranslateDescriptionsPayload
  >,
  'mutationFn' | 'mutationKey'
>;

const bulkTranslateDescriptions = async (
  payload: TranslateDescriptionsPayload,
) => {
  const response = await API.post<TranslateDescriptionsResponse>(
    '/api/internal/ai/messaging/bulk-translate',
    payload,
  );

  return response.data;
};

const useBulkTranslateDescriptions = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: bulkTranslateDescriptions,
    ...options,
  });
};

export default useBulkTranslateDescriptions;
