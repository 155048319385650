import { useState } from 'react';
import { MenuItem, SplitButton } from 'react-bootstrap';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  RichTextEditorButton,
  RichTextEditorButtonSwitcher,
} from '../RichTextEditor.types';
import Button from '../../button/Button';
import { clearEditor } from '../RichTextEditor.utils';
import { htmlMessageWrapperId } from '../RichTextEditor.constants';

const ControlsPluginButtons = ({
  buttons,
  buttonsSwitcher,
}: {
  buttons?: RichTextEditorButton[];
  buttonsSwitcher?: RichTextEditorButtonSwitcher;
}) => {
  if (buttonsSwitcher && buttonsSwitcher.items.length !== buttons?.length) {
    throw new Error(
      'Illegal argument: buttons switcher items and buttons numbers must be equal',
    );
  }

  const [editor] = useLexicalComposerContext();
  const [activeButtonId, setActiveButtonId] = useState<string>();

  const clearEditorContents = () => {
    clearEditor(editor);
  };

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const renderButtonsSwitcher = buttons?.length > 1 && !!buttonsSwitcher;

  const activeButton = renderButtonsSwitcher
    ? // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      buttons.find(
        ({ id }) => id === (activeButtonId || buttonsSwitcher?.initialButtonId),
      )
    : buttons?.length && buttons[0];

  if (!activeButton) {
    return null;
  }

  const { id, disabled, label, onClickHandler } = activeButton;

  const onClick = () => {
    const htmlContent =
      // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      editor.getRootElement().firstElementChild?.id === htmlMessageWrapperId
        ? // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          editor.getRootElement().firstElementChild
        : editor.getRootElement();

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    onClickHandler(htmlContent, clearEditorContents);
  };

  if (renderButtonsSwitcher) {
    return (
      <SplitButton
        title={label}
        id={id}
        data-testid={id}
        key={id}
        disabled={disabled}
        onClick={onClick}
      >
        {buttonsSwitcher.items.map(({ buttonId, label: itemLabel }) => (
          <MenuItem
            key={`switcher-${buttonId}`}
            onClick={() => {
              setActiveButtonId(buttonId);
            }}
          >
            {itemLabel}
          </MenuItem>
        ))}
      </SplitButton>
    );
  }

  return (
    <Button id={id} data-testid={id} disabled={disabled} onClick={onClick}>
      {label}
    </Button>
  );
};

ControlsPluginButtons.defaultProps = {
  buttons: undefined,
  buttonsSwitcher: undefined,
};

export default ControlsPluginButtons;
