import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { CancellationPolicy } from 'models/CancellationPolicy';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import useNotify from 'hooks/useNotify';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { Property } from 'models/Properties';
import { CANCELLATION_POLICY_BASE_QUERY_KEY } from '../useCancellationPolicyData';

const useDeleteModal = ({
  cancellationPolicy,
}: {
  cancellationPolicy: CancellationPolicy;
}) => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { openConfirmModal } = useAppModal();
  const { notifyError } = useNotify();

  const mutationKey = ['cancellation-police-delete', cancellationPolicy?.uid];

  const { mutateAsync: deleteCancellationPolicy } = useAppMutation(
    mutationKey,
    async ({
      applyToAll,
      propertyUids,
    }: {
      applyToAll: boolean;
      propertyUids: Property['uid'][];
    }) => {
      await API.delete('/api/internal/cancellation-policies/bulk-delete', {
        data: {
          locales: [LanguageCodeUnderscored.EN_US],
          applyToAll,
          propertyUids,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([
          CANCELLATION_POLICY_BASE_QUERY_KEY,
          propertyUid,
        ]);

        // closeModal(bulkDeleteModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleDelete = async () => {
    // const { updateAllProperties, propertiesUids } =
    //   extractPropertiesMultiSelectFields('appliesTo', values);

    await deleteCancellationPolicy({
      // applyToAll: updateAllProperties,
      // propertyUids: updateAllProperties ? [] : propertiesUids,
      applyToAll: false,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUids: [propertyUid],
    });
  };

  // const defaultValues = {
  //   appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
  //   // Fill this when backend return shared properties data
  //   appliesToProperties: [],
  // };

  const openDeleteModal = () => {
    // openModal({
    //   id: bulkDeleteModalId,
    //   customContent: (
    //     <BulkDeleteModal
    //       title={t(
    //         'pageProperty.feesTaxesAndPolicies.cancellationPolicy.deleteModal.title',
    //       )}
    //       description={t(
    //         'pageProperty.feesTaxesAndPolicies.cancellationPolicy.deleteModal.description',
    //       )}
    //       onSubmit={handleDelete}
    //       defaultValues={defaultValues}
    //       mutationKey={mutationKey}
    //       propertyMultiSelectProps={{
    //         filterPropertyBy,
    //       }}
    //     />
    //   ),
    // });

    openConfirmModal({
      body: t(
        'pageProperty.feesTaxesAndPolicies.cancellationPolicy.deleteModal.description',
      ),
      title: t(
        'pageProperty.feesTaxesAndPolicies.cancellationPolicy.deleteModal.title',
      ),
      onConfirmAsync: handleDelete,
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
    });
  };

  return {
    openDeleteModal,
  };
};

export default useDeleteModal;
