import styled from 'styled-components';

export const PriceRulesChannelsPerTypeStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  svg {
    --size: 24px;
    height: var(--size);
    width: var(--size);
  }
`;
