import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import TextField from 'components/forms/textField/TextField';
import { Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RadioAgencyLabel, RadioPropertyLabel } from './DefinitionRadioLabels';
import {
  DefinitionRadioOptions,
  EditRentalConditionsFormSchemaType,
} from './EditRentalConditionsModal.schema';

const EditModalBody = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<EditRentalConditionsFormSchemaType>();

  const definition = watch('definition');

  return (
    <>
      <Row>
        <RadioGroupField
          name="definition"
          options={[
            {
              value: DefinitionRadioOptions.AGENCY,
              label: <RadioAgencyLabel />,
            },
            {
              value: DefinitionRadioOptions.PROPERTY,
              label: <RadioPropertyLabel />,
            },
          ]}
        />
      </Row>

      <Row>
        {definition === DefinitionRadioOptions.PROPERTY && (
          <TextField
            name="rentalConditions"
            label={t(
              'pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.text',
            )}
            componentClass="textarea"
            required
            colSmLabel={2}
            colSmInput={10}
            rows={6}
            maxCharacters={1000}
            // maxLength is the same as v2, for backwards compatibility reasons
            maxLength={64000}
          />
        )}
      </Row>

      {/* <AppliesToRow>
        <Col xs={2}>
          <AppliesToLabel>
            {t(
              'pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.appliesTo',
            )}
          </AppliesToLabel>
        </Col>
        <Col xs={10}>
          <PropertyMultiSelectFilterField name="appliesTo" />
        </Col>
      </AppliesToRow> */}
    </>
  );
};

export default EditModalBody;
