import ZonedDateTime from 'ts-time/ZonedDateTime';
import { BaseResponse } from './_base';

export interface GetAgencyResponseTO extends BaseResponse {
  agency: AgencyTO;
}

export enum DiscountCodeStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
  REDEEMED = 'REDEEMED',
}

export enum DiscountCodeType {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
}

export interface Discount {
  uid: string;
  name: string;
  status: DiscountCodeStatus;
  amount: number;
  percent: number;
  type: string;
  property: string;
  propertyCurrency: string;
  expirationDate: Date;
  validFromDate: Date;
  validToDate: Date;
  singleUse: boolean;
}

export interface GetDiscountsResponseTO extends BaseResponse {
  discountCodes: Discount[];
}

export interface AgencyTO {
  uid: string;
  name: string;
  agencyEmailAddress: string;
  phoneNumber: string;
  website: string;
  address1: string;
  city: string;
  zipCode: string;
  state: string;
  currency: string;
  countryCode: string;
  defaultCheckInTime: number;
  defaultCheckOutTime: number;
}

export type AddDiscountTO = {
  uid?: string;
  name: string;
  amount: number;
  percent: number;
  type: DiscountCodeType;
  propertyUid?: string;
  expirationDate?: ZonedDateTime;
  validFromDate?: ZonedDateTime;
  validToDate?: ZonedDateTime;
  singleUse: boolean;
};
