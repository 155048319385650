import { GetCalendarPropertiesResponse } from 'pages/stackedCalendar/Calendar.types';
import useAppUser from 'hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';

export const ALL_PROPERTIES_BASE_QUERY_KEY = 'allProperties';

const useFetchAllProperties = ({
  enabled,
  conditions,
  fields,
  operationName,
}: {
  enabled: boolean;
  conditions: string;
  fields: string;
  operationName?: string;
}) => {
  const { agencyUid, isOwner } = useAppUser();

  const { data: allProperties, isFetching } = useAppQuery(
    [
      ALL_PROPERTIES_BASE_QUERY_KEY,
      operationName,
      agencyUid,
      conditions,
      fields,
    ],
    async () => {
      const conditionsWithAgencyUid = isOwner
        ? conditions
        : `agencyUid:"${agencyUid}",${conditions}`;
      const graphqlQuery = {
        operationName: operationName ?? '',
        query: `{properties(${conditionsWithAgencyUid}){
          ${fields}
        }}`,
        variables: {},
      };

      const propertiesResponse = await API.post<GetCalendarPropertiesResponse>(
        '/v3/graphql',
        graphqlQuery,
      );
      return propertiesResponse?.data?.data?.properties;
    },
    {
      enabled: enabled && !!conditions && !!fields,
      cacheTime: 30000,
      staleTime: 24 * 60 * 60 * 1000, // 24 hours
      // Will refetch the query if the data is stale (either after staleTime has
      // passed or if the query got invalidated).
      refetchOnMount: true,
    },
  );

  return { allProperties, isFetching };
};

export default useFetchAllProperties;
