import { useTranslation } from 'react-i18next';
import { AutomatedReviewsContainer } from './AirbnbGuestReviews.styles';
import TemplatesTriggerLink from './TemplatesTriggerLink';

const AutomatedReviews = () => {
  const { t } = useTranslation();

  return (
    <AutomatedReviewsContainer>
      <h3>{t('pageProperty.reviews.airbnbGuest.automatedReviews.title')}</h3>
      <p>
        <span>
          {t('pageProperty.reviews.airbnbGuest.automatedReviews.description')}
        </span>{' '}
        <a
          href="https://help.hostfully.com/en/articles/5466960-automate-reviews-for-airbnb-guests"
          target="_blank"
          rel="noreferrer"
        >
          {t(
            'pageProperty.reviews.airbnbGuest.automatedReviews.seeDocumentation',
          )}
        </a>
      </p>
      <TemplatesTriggerLink />
    </AutomatedReviewsContainer>
  );
};

export default AutomatedReviews;
