import { useQueryClient } from '@tanstack/react-query';
import { Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import useFetchOverrideAirbnbPricingRules, {
  OVERRIDE_AIRBNB_PRICING_RULES_BASE_QUERY_KEY,
} from 'components/domain/propertyPricing/useFetchOverrideAirbnbPricingRules';
import useOverrideAirbnbPricingRules from 'components/domain/propertyPricing/useOverrideAirbnbPricingRules';
import Switch from 'components/switch/Switch';
import Tooltip from 'components/tooltip/Tooltip';
import TooltipContentWithLearnMore from 'pages/property/common/tooltip/TooltipContentWithLearnMore';
import { PriceRulesOverrideAirbnbWrapperStyled } from './PriceRulesOverrideAirbnb.styles';

const PriceRulesOverrideAirbnb = () => {
  const { t } = useTranslation();
  const { uid: propertyUid = '' } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useFetchOverrideAirbnbPricingRules({
    propertyUid,
  });
  const { mutateAsync: overrideAirbnbPricingRules, isLoading: isUpdating } =
    useOverrideAirbnbPricingRules({
      onSettled: () =>
        queryClient.invalidateQueries([
          OVERRIDE_AIRBNB_PRICING_RULES_BASE_QUERY_KEY,
        ]),
    });

  const handleOverrideChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isInputChecked = event.target.checked;

    overrideAirbnbPricingRules({
      propertyUid,
      shouldOverrideAirbnbPricingRules: isInputChecked,
    });
  };

  return (
    <PriceRulesOverrideAirbnbWrapperStyled data-testid="override-airbnb">
      <Switch
        aria-labelledby="override-airbnb-label"
        checked={data?.shouldOverrideAirbnbPricingRules}
        className="small"
        data-testid="override-airbnb-switch"
        disabled={isLoading || isUpdating}
        id="overrideAirbnbSwitch"
        onChange={handleOverrideChange}
      />
      <AirbnbIcon />
      {/* Disables label for to toggling the switch by clicking on the label */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label id="override-airbnb-label">
        {t('pageProperty.pricing.priceRules.overrideAirbnbLabel')}
      </label>
      <Tooltip
        id="override-airbnb-tooltip"
        text={
          <TooltipContentWithLearnMore
            labelTooltipText={t(
              'pageProperty.pricing.priceRules.overrideAirbnbTooltip',
            )}
            learnMoreUrl="https://help.hostfully.com/en/articles/9414601-send-special-offers-or-give-discounts-promotions-to-airbnb-guests"
          />
        }
      >
        <Glyphicon
          glyph="info-sign"
          id="override-airbnb-tooltip-icon"
          data-testid="override-airbnb-tooltip-icon"
        />
      </Tooltip>
    </PriceRulesOverrideAirbnbWrapperStyled>
  );
};

export default PriceRulesOverrideAirbnb;
