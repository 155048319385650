import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Overlay, Popover as PopoverBootstrap } from 'react-bootstrap';
import useAppEvent from '../../hooks/useAppEvent';
import { AppEventType, PopoverToggleEventPayload } from '../../models/Events';

interface PopoverProps {
  children: ReactElement;
  className?: string;
  containerSelector?: string;
  content: string | ReactNode;
  id: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  title: string | ReactNode;
}

const PopoverStickyOnHover = ({
  children,
  className,
  containerSelector,
  content,
  id,
  placement,
  title,
  ...rest
}: PopoverProps) => {
  const { subscribe, unsubscribe } = useAppEvent();
  const [show, setShow] = useState<boolean>(false);
  const childRef = useRef<ReactNode>();
  let timeoutHandler: NodeJS.Timeout;

  const onMouseEnter = () => {
    setShow(true);
    clearTimeout(timeoutHandler);
  };
  const onMouseLeave = () => {
    timeoutHandler = setTimeout(() => {
      setShow(false);
    }, 200);
  };

  useEffect(() => {
    const toggleListener = ({
      detail: { popoverId },
    }: CustomEvent<PopoverToggleEventPayload>) => {
      if (popoverId === id) {
        setShow((current) => !current);
      }
    };

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    subscribe(AppEventType.POPOVER_TOGGLE, toggleListener);

    return () => {
      clearTimeout(timeoutHandler);
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      unsubscribe(AppEventType.POPOVER_TOGGLE, toggleListener);
    };
  }, []);

  const childElement = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onMouseEnter,
      onMouseLeave,
      ref: (node: ReactNode) => {
        childRef.current = node;
      },
    }),
  )[0];
  const container =
    containerSelector && document.querySelector(containerSelector);

  return (
    <div>
      {childElement}
      <Overlay
        animation={false}
        container={container}
        placement={placement}
        rootClose
        show={show}
        target={() => childRef.current}
        shouldUpdatePosition
      >
        <PopoverBootstrap
          className={className}
          // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          data-testid={rest['data-testid'] || id}
          id={id}
          title={title}
          onMouseEnter={onMouseEnter}
          onMouseLeave={() => {
            setShow(false);
          }}
        >
          {content}
        </PopoverBootstrap>
      </Overlay>
    </div>
  );
};

PopoverStickyOnHover.defaultProps = {
  className: undefined,
  containerSelector: undefined,
  placement: 'right',
};

export default PopoverStickyOnHover;
