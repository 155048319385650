import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { PriceRulesAddEditFormValues } from './PriceRulesAddEditModal.schema';

const PriceRulesAddEditModalThreshold = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    priceRule: { type },
  } = useWatch<PriceRulesAddEditFormValues>();

  if (
    type === PropertyPricingType.EARLY_BIRD_DISCOUNT ||
    type === PropertyPricingType.LAST_MINUTE_DISCOUNT
  ) {
    const length = type === PropertyPricingType.EARLY_BIRD_DISCOUNT ? 37 : 28;
    const options = Array.from({ length }, (_, index) => ({
      label: `${index}`,
      value: index,
    }));

    return (
      <SelectField
        label={t(
          'pageProperty.pricing.priceRules.modal.form.priceModifierThreshold',
          { context: type },
        )}
        name="priceRule.priceModifierThreshold"
        options={options}
        colSmInput={8}
        colSmLabel={4}
        rightAddons={[
          t(
            'pageProperty.pricing.priceRules.modal.form.priceModifierThresholdRightAddon',
            { context: type },
          ),
        ]}
        required
      />
    );
  }

  return (
    <TextField
      label={t(
        'pageProperty.pricing.priceRules.modal.form.priceModifierThreshold',
        { context: type },
      )}
      name="priceRule.priceModifierThreshold"
      colSmInput={8}
      colSmLabel={4}
      step="any"
      type="number"
      min={0}
      rightAddons={[
        t(
          'pageProperty.pricing.priceRules.modal.form.priceModifierThresholdRightAddon',
          { context: type },
        ),
      ]}
      required
    />
  );
};

export default PriceRulesAddEditModalThreshold;
