import { useTranslation } from 'react-i18next';
import { AirbnbCategoryStatistic, AirbnbRatingCategory } from 'models/Reviews';
import {
  CategoryScore,
  CategoryScoresContainer,
} from './OverallAirbnbRatings.styles';

const definedOrderCategories = [
  AirbnbRatingCategory.CLEANLINESS,
  AirbnbRatingCategory.ACCURACY,
  AirbnbRatingCategory.CHECKIN,
  AirbnbRatingCategory.COMMUNICATION,
  AirbnbRatingCategory.LOCATION,
  AirbnbRatingCategory.VALUE,
];

const CategoryScores = ({
  categoryStatistics,
}: {
  categoryStatistics: AirbnbCategoryStatistic[];
}) => {
  const { t } = useTranslation();

  const sortedCategories = categoryStatistics.toSorted((a, b) => {
    return (
      definedOrderCategories.indexOf(a.category) -
      definedOrderCategories.indexOf(b.category)
    );
  });

  return (
    <CategoryScoresContainer data-testid="airbnb-rating-categories">
      {sortedCategories.length > 0
        ? sortedCategories.map(({ category, rating }) => (
            <CategoryScore
              data-testid={`category-score-${category}`}
              key={category}
            >
              <span className="title">
                {t(`componentReviews.airbnb.categories.${category}`)}
              </span>
              <span className="score">{rating}</span>
            </CategoryScore>
          ))
        : definedOrderCategories.map((category) => (
            <CategoryScore
              key={category}
              data-testid={`category-score-${category}`}
            >
              <span className="title">
                {t(`componentReviews.airbnb.categories.${category}`)}
              </span>
              <span className="score">-</span>
            </CategoryScore>
          ))}
    </CategoryScoresContainer>
  );
};

export default CategoryScores;
