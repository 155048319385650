import PermissionsAwareFieldset from '../../../common/PermissionsAwareFieldset';
import { CustomAmenitiesSection } from './CustomAmenities.styles';
import CustomAmenitiesHeader from './CustomAmenitiesHeader';
import CustomAmenitiesTable from './CustomAmenitiesTable';

const CustomAmenities = () => {
  return (
    <PermissionsAwareFieldset>
      <CustomAmenitiesSection data-testid="custom-amenities-section">
        <CustomAmenitiesHeader />
        <CustomAmenitiesTable />
      </CustomAmenitiesSection>
    </PermissionsAwareFieldset>
  );
};

export default CustomAmenities;
