import { useTranslation } from 'react-i18next';
import { Transaction, TransactionStatus } from 'models/Transactions';
import {
  TransactionManualBadge,
  TransactionTypeBadge,
} from './OrderViewTransactionsListItemType.styles';

const OrderViewTransactionsListItemType = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { t } = useTranslation();
  const { manual, type, status } = transaction;

  return (
    <>
      <TransactionTypeBadge
        data-testid="transaction-type-badge"
        $type={type}
        $status={status}
      >
        {status === TransactionStatus.UNCAPTURED
          ? t(`pageOrder.transactions.status.${status}`).toUpperCase()
          : t(`pageOrder.transactions.type.${type}`).toUpperCase()}
      </TransactionTypeBadge>
      {manual && (
        <TransactionManualBadge data-testid="transaction-manual-badge">
          {t('pageOrder.transactions.manual').toUpperCase()}
        </TransactionManualBadge>
      )}
    </>
  );
};

export default OrderViewTransactionsListItemType;
