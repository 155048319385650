import { IntercomProvider } from 'react-use-intercom';
import useAppConfig from '../../hooks/useAppConfig';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const IntercomProviderWrapper = ({ children }) => {
  const { intercomAppId } = useAppConfig();

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return <IntercomProvider appId={intercomAppId}>{children}</IntercomProvider>;
};

export default IntercomProviderWrapper;
