import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { PricingRule } from 'models/pricing/PricingRule';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import useDeleteModal from '../modals/useDeleteModal';
import usePriceRulesAddEditModal from '../modals/usePriceRulesAddEditModal';

const ActionsCell = ({ row }: { row: Row<PricingRule> }) => {
  const { t } = useTranslation();
  const { original: pricingRule } = row;
  const { openEditPriceRulesModal } = usePriceRulesAddEditModal();
  const { openBulkDeleteModal } = useDeleteModal({ pricingRule });

  return (
    <>
      <PropertyEditActionWrapper tooltipId="row-edit-pricing-rule-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-edit-pricing-rule-button"
          onClick={() => openEditPriceRulesModal({ pricingRule })}
        >
          {t('common.edit')}
        </Button>
      </PropertyEditActionWrapper>
      <PropertyEditActionWrapper tooltipId="row-delete-pricing-rule-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-delete-pricing-rule-button"
          onClick={openBulkDeleteModal}
        >
          {t('common.delete')}
        </Button>
      </PropertyEditActionWrapper>
    </>
  );
};

export default ActionsCell;
