import Switch from 'components/switch/Switch';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { TableHeader } from './ServiceProvidersTable.styles';

const ServiceProviderTableHeader = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const providers = useWatch({
    name: 'serviceProviders.providers',
  });

  const isAllSelected = providers?.every(
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    (provider) => provider?.isActive === true,
  );

  const onToggleAll = (value: boolean) => {
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const updatedProviders = providers?.map((provider) => ({
      ...provider,
      isActive: value,
    }));

    setValue('serviceProviders.providers', updatedProviders, {
      shouldDirty: true,
    });
  };

  return (
    <TableHeader>
      <span>{t('pageProperty.serviceProviders.toggleAll')}</span>

      <Switch
        checked={isAllSelected}
        onChange={(event) => onToggleAll(event.target.checked)}
        className="small"
        data-testid="toggle-all-service-providers"
      />
    </TableHeader>
  );
};

export default ServiceProviderTableHeader;
