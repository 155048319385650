import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { LanguageCode } from './internationalization.types';
import { tryToFormatDateTime } from './dateTimeInternationalization';

function readLocaleFiles() {
  const locales = import.meta.glob('./resources/**/*.json', {
    eager: true,
    import: 'default',
  });
  const resources = {};
  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const group = matched[1];
      const languageCode = matched[2];
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      if (!resources[languageCode]) {
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        resources[languageCode] = { translation: {} };
      }
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      resources[languageCode].translation[group] = locales[key];
    }
  });

  return resources;
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: readLocaleFiles(),
    fallbackLng: LanguageCode.EN,
    interpolation: {
      escapeValue: false,
      format: (value, format, lang) => {
        return tryToFormatDateTime(value, format, lang) || value;
      },
    },
  });

export { i18n };
