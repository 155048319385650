import { useContext } from 'react';
import CalendarContext from '../CalendarContext';
import { getPropertySubUnitUids } from '../../../utils/property/propertyUtils';
import useCalendarBodyUpdateEvents from '../../calendar/common/body/useCalendarBodyUpdateEvents';

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function getPropertiesUidsToRefresh(property) {
  return [
    property.uid,
    ...getPropertySubUnitUids(property),
    ...(property.masterUnitUid ? [property.masterUnitUid] : []),
  ];
}

const useStackedCalendarBodyUpdateEvents = () => {
  const { propertiesMap } = useContext(CalendarContext);

  useCalendarBodyUpdateEvents((propertyUid) =>
    getPropertiesUidsToRefresh(propertiesMap[propertyUid]),
  );
};

export default useStackedCalendarBodyUpdateEvents;
