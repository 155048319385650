import { Modal } from 'react-bootstrap';
import { Container } from 'components/modal/blockModal/BlockModal.styles';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import RichTextEditor from 'components/editor/RichTextEditor';
import {
  AgencyMessagingRecipientType,
  MessagingChannel,
} from 'models/inbox/Messages';
import useExecuteLeadPipelineAction from 'components/domain/lead/actions/useExecuteLeadPipelineAction';
import { LeadSource } from 'models/Leads';
import Loader from '../../../loader/Loader';
import useAvailableMessagingChannels from '../useAvailableMessagingChannels';
import templateFormSchema from './TemplateModal.schema';
import {
  LeadPipelineTemplateActions,
  TemplateModalParams,
} from './TemplateModal.types';
import useLeadTemplateModalData from './useLeadTemplateModalData';
import useTemplateModalFormInitialValue from './useTemplateModalFormInitialValue';
import TemplateModalRichEditorFooter from './footer/TemplateModalRichEditorFooter';
import { TemplateModalBody } from './TemplateModal.styles';
import useSendTemplate, { SendTemplatePayload } from './useSendTemplate';
import useSendAgencyMessage, {
  SendAgencyMessagePayload,
} from './useSendAgencyTemplate';
import TemplateModalLeadWrapper from './TemplateModalLeadWrapper';

const TemplateModalFormWrapper = ({
  params,
}: {
  params: TemplateModalParams;
}) => {
  const {
    leadUid,
    jobUid,
    templateName,
    leadPipelineTemplateAction,
    updateLeadCallback,
  } = params;

  const { t } = useTranslation();
  const {
    isLoading,
    lead,
    fetchedTemplate,
    threadUid,
    recipients,
    sendCopyToAgency,
  } = useLeadTemplateModalData({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    templateName,
    leadUid,
    jobUid,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    leadPipelineTemplateAction,
  });
  const {
    channels,
    defaultMessagingChannel,
    isLoadingMessagingChannels,
    fetchedAvailableMessagingChannels,
  } = useAvailableMessagingChannels({
    threadUid,
    lead,
  });
  const { executeAction, isExecutingAction } = useExecuteLeadPipelineAction();

  const prepareFormInitialValue = useTemplateModalFormInitialValue();
  const {
    errorMessage: errorTemplate,
    isSending: isTemplateSending,
    sendTemplate,
  } = useSendTemplate();
  const {
    errorMessage,
    isSending: isMessageSending,
    sendAgencyMessage,
  } = useSendAgencyMessage();

  if (isLoading) {
    return <Loader />;
  }

  const defaultValues = prepareFormInitialValue({
    lead,
    template: fetchedTemplate,
    to: recipients,
    sendCopyToAgency,
  });

  // Only use this map when leadPipelineTemplateAction is present
  const messagingChannelsByLeadSourceMap = {
    [LeadSource.DIRECT_HOMEAWAY]: [MessagingChannel.VRBO],
    [LeadSource.DIRECT_BOOKINGDOTCOM]: [MessagingChannel.BOOKING_COM],
    [LeadSource.DIRECT_AIRBNB]: [MessagingChannel.AIRBNB],
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleSubmit = (formValues) => {
    if (jobUid) {
      sendAgencyMessage({
        content: {
          text: formValues.content,
          subject: formValues.subject,
        },
        channel: formValues.channel,
        settings: {
          shouldCopyAgency: formValues.sendCopyToAgency,
        },
        recipient: {
          type: AgencyMessagingRecipientType.JOB,
          uid: jobUid,
        },
      } as SendAgencyMessagePayload);
    } else {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      if (LeadPipelineTemplateActions.includes(leadPipelineTemplateAction)) {
        executeAction({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          action: leadPipelineTemplateAction,
          lead,
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          updateLeadCallback,
          formData: {
            leadUid,
            subject: formValues.subject,
            content: formValues.content.innerHTML,
            recipients: [formValues.to],
            copyAgency: formValues.sendCopyToAgency,
          },
        });
        return;
      }

      sendTemplate({
        payload: {
          templateName,
          message: {
            threadUid,
            content: {
              text: formValues.content,
              subject: formValues.subject,
            },
            channel: formValues.channel,
            settings: {
              shouldCopyAgency: formValues.sendCopyToAgency,
            },
          },
        } as SendTemplatePayload,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        availableMessagingChannels: fetchedAvailableMessagingChannels,
      });
    }
  };

  const availableChannelsForTemplateModal = () => {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    if (LeadPipelineTemplateActions.includes(leadPipelineTemplateAction)) {
      return (
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        messagingChannelsByLeadSourceMap[lead?.source] || [
          MessagingChannel.EMAIL,
        ]
      );
    }

    return channels;
  };
  const controlsSettings = {
    attachmentsAvailable: false,
    attachmentIconDisabled: false,
    isLoading: false,
    clearEditorButton: false,
  };

  return (
    <Container
      id="create-update-job-form"
      schema={templateFormSchema}
      horizontal
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      <Modal.Header closeButton>
        <Modal.Title data-testid="template-modal-title">
          {/*
           // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {templateName
            ? `${t('componentTemplate.send')} ${templateName}`
            : // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              t(`componentTemplate.send_${leadPipelineTemplateAction}`)}
        </Modal.Title>
      </Modal.Header>

      <TemplateModalBody>
        <TemplateModalLeadWrapper lead={lead} isLoading={isLoading} />

        {!lead && (
          <TextField
            name="to"
            disabled
            label={t('componentTemplate.to')}
            componentClass="input"
          />
        )}

        <TextField
          name="subject"
          label={t('componentTemplate.subject')}
          componentClass="input"
          rightTooltip={t('componentTemplate.forEmailOnly')}
        />

        <RichTextEditor
          customFooter={
            <TemplateModalRichEditorFooter
              // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              errorMessage={errorMessage || errorTemplate}
              channels={availableChannelsForTemplateModal()}
              defaultMessagingChannel={defaultMessagingChannel}
              isSending={
                isTemplateSending ||
                isMessageSending ||
                isLoadingMessagingChannels ||
                isExecutingAction
              }
            />
          }
          id="template-modal-message-editor"
          contents={defaultValues.content}
          placeholderText={t(
            'pageInbox.messages.editor.typeMessagePlaceholder',
          )}
          controlsSettings={controlsSettings}
        />
      </TemplateModalBody>
    </Container>
  );
};

export default TemplateModalFormWrapper;
