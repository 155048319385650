import { ReactNode } from 'react';
import {
  Col,
  ControlLabel,
  Glyphicon,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';
import { Controller, get, useFormContext } from 'react-hook-form';
import ErrorMessage from '../_errorMessage/ErrorMessage';
import Tooltip from '../../tooltip/Tooltip';
import {
  FormGroupContainer,
  InputGroupTooltipWrapper,
} from './GenericFormField.styles';
import { GenericFormFieldProps } from './GenericFormField.types';

function getInfoTooltipElement(name: string, tooltipText: string | ReactNode) {
  const iconId = `${name}-tooltip-icon`;

  return (
    <Tooltip text={tooltipText} id={`${name}-tooltip`}>
      <Glyphicon glyph="info-sign" id={iconId} data-testid={iconId} />
    </Tooltip>
  );
}

const GenericFormField: React.FC<GenericFormFieldProps> = ({
  name,
  groupName,
  fieldRenderer,
  label,
  help,
  leftAddon,
  rightAddons,
  rightTooltip,
  colSmInput,
  colSmLabel,
  colSmOffsetInput,
  colXsInput,
  colXsLabel,
  colXsOffsetInput,
  containerClassName,
  labelOnTop,
  required,
  inline,
  secondaryField,
  disabled,
  isValid: isValidProp,
}) => {
  const {
    formState: { errors, dirtyFields },
    control,
  } = useFormContext();

  const hasErrors = !!get(errors, name);
  const isValid =
    isValidProp !== undefined
      ? isValidProp
      : !!get(dirtyFields, name) && !get(errors, name);

  const validationState = () => {
    if (hasErrors) {
      return 'error';
    }
    if (isValid) {
      return 'success';
    }
    return undefined;
  };

  const renderInput = () => {
    const renderInputGroup = () => {
      const inputGroupBase = (
        <InputGroup>
          {leftAddon && <InputGroup.Addon>{leftAddon}</InputGroup.Addon>}
          <Controller control={control} name={name} render={fieldRenderer} />
          {!!rightAddons?.length &&
            rightAddons.map((rightAddon, index) => (
              // Using index as a key here should be fine considering the array
              // order and size will not change between renders.
              // eslint-disable-next-line react/no-array-index-key
              <InputGroup.Addon key={index}>{rightAddon}</InputGroup.Addon>
            ))}
        </InputGroup>
      );
      return rightTooltip ? (
        <InputGroupTooltipWrapper>
          {inputGroupBase}
          {getInfoTooltipElement(name, rightTooltip)}
        </InputGroupTooltipWrapper>
      ) : (
        inputGroupBase
      );
    };

    return (
      <>
        {/*
         // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        {leftAddon || rightAddons?.length > 0 || rightTooltip ? (
          renderInputGroup()
        ) : (
          <Controller control={control} name={name} render={fieldRenderer} />
        )}
        {help && <HelpBlock>{help}</HelpBlock>}
        {errors && get(errors, name) && (
          /* @ts-ignore */
          <ErrorMessage error={get(errors, name).message} />
        )}
      </>
    );
  };

  if (inline) {
    return (
      <FormGroupContainer
        $disabled={disabled}
        $customClassName={containerClassName}
        $name={groupName || name}
        playsInline
        validationState={validationState()}
      >
        {renderInput()}
      </FormGroupContainer>
    );
  }

  const fieldSizeDivider = secondaryField ? 2 : 1;
  const fieldXsSize =
    (colXsInput || (labelOnTop || !label ? 12 : 8)) / fieldSizeDivider;
  const fieldSmSize =
    (colSmInput || (labelOnTop || !label ? 12 : 10)) / fieldSizeDivider;

  return (
    <FormGroupContainer
      $disabled={disabled}
      $customClassName={containerClassName}
      $name={groupName || name}
      validationState={validationState()}
    >
      {label &&
        (labelOnTop ? (
          <Col
            componentClass={ControlLabel}
            className="label-on-top"
            data-testid={`${groupName || name}-field-label`}
            required={required}
            xs={12}
          >
            {label}
          </Col>
        ) : (
          <Col
            componentClass={ControlLabel}
            data-testid={`${groupName || name}-field-label`}
            xs={colXsLabel || 4}
            sm={colSmLabel || 2}
            required={required}
          >
            {label}
          </Col>
        ))}

      <Col
        xs={fieldXsSize}
        xsOffset={colXsOffsetInput}
        sm={fieldSmSize}
        smOffset={colSmOffsetInput}
      >
        {renderInput()}
      </Col>
      {!!secondaryField && (
        <Col
          xs={fieldXsSize}
          xsOffset={colXsOffsetInput}
          sm={fieldSmSize}
          smOffset={colSmOffsetInput}
        >
          {secondaryField}
        </Col>
      )}
    </FormGroupContainer>
  );
};

GenericFormField.defaultProps = {
  label: '',
  help: '',
  leftAddon: '',
  rightAddons: [],
  rightTooltip: '',
  colSmInput: 10,
  colSmOffsetInput: 0,
  colSmLabel: 2,
  labelOnTop: false,
  disabled: false,
};

export default GenericFormField;
