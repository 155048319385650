import { FeeAmountTypeEnum, FeeTypeEnum } from 'models/PropertyFees';

import { useWatch } from 'react-hook-form';

const useFormData = () => {
  const type = useWatch({ name: 'type' });
  const feeType = useWatch({ name: 'fee.type' });
  const taxType = useWatch({ name: 'tax.type' });
  const scopeField = useWatch({ name: 'scope' });
  const isOptional = useWatch({ name: 'optional' });
  const taxConditionType = useWatch({ name: 'tax.conditions.type' });

  const isTypeFee = type === FeeTypeEnum.CUSTOM;
  const isTypeTax = type === FeeTypeEnum.TAX;
  const isFeeTypePercentage = feeType === FeeAmountTypeEnum.TAX;
  const isTaxTypePercentage = taxType === FeeAmountTypeEnum.TAX;
  const isAmountPercentage = isFeeTypePercentage || isTaxTypePercentage;
  const isFeeTypeAmount = feeType === FeeAmountTypeEnum.AMOUNT;
  const isTaxTypeAmount = taxType === FeeAmountTypeEnum.AMOUNT;

  const scopeValue = isAmountPercentage ? scopeField?.tax : scopeField?.amount;

  return {
    type,
    isTypeFee,
    isTypeTax,
    isAmountPercentage,
    scopeValue,
    taxConditionType,
    isFeeTypeAmount,
    isTaxTypeAmount,
    isFeeTypePercentage,
    isTaxTypePercentage,
    isOptional,
  };
};

export default useFormData;
