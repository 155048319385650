import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { BOOKING_DOT_COM_AVAILABLE_BOOKING_TYPES } from '../bookingSettings/BookingSettings.constants';

const BookingTypeField = () => {
  const { t } = useTranslation();
  const bookingTypeOptions = BOOKING_DOT_COM_AVAILABLE_BOOKING_TYPES.map(
    (value) => ({
      label: t(`common.bookingType.${value}`),
      value,
    }),
  );

  return (
    <SelectField
      label={
        <PropertyFormFieldLabel name="bookingDotComSettings.bookingType">
          {t('pageProperty.mainSettings.bookingDotComSettings.bookingType')}
        </PropertyFormFieldLabel>
      }
      name="bookingDotComSettings.bookingType"
      options={bookingTypeOptions}
      colSmInput={7}
      colSmLabel={5}
      required
    />
  );
};

export default BookingTypeField;
