import { ReactNode } from 'react';
import ReactSelect, {
  components,
  OptionProps,
  Props as ReactSelectProps,
} from 'react-select';
import GenericFormField from '../genericFormField/GenericFormField';
import { GenericFormFieldBaseProps } from '../genericFormField/GenericFormField.types';
import { CustomSelectFieldContainerStyled } from './CustomSelectField.styles';

interface OptionBase {
  label: string | ReactNode;
  value: string;
}

type ReactCustomSelectProps = ReactSelectProps<OptionBase>;

interface CustomSelectFieldProps
  extends Omit<GenericFormFieldBaseProps, 'fieldRenderer' | 'placeholder'> {
  formatOptionLabel?: ReactCustomSelectProps['formatOptionLabel'];
  isSearchable?: boolean;
  name: string;
  optionRenderer?: (props: OptionProps<OptionBase>) => ReactNode;
  options: ReactCustomSelectProps['options'];
  placeholder?: ReactCustomSelectProps['placeholder'];
}

const { GroupHeading, Option } = components;

function getCustomComponents(
  optionRenderer: CustomSelectFieldProps['optionRenderer'],
): ReactCustomSelectProps['components'] {
  const defaultOverrides: ReactCustomSelectProps['components'] = {
    GroupHeading: (props) => (
      <GroupHeading data-testid="select-group-heading" {...props} />
    ),
  };

  if (!optionRenderer) {
    return defaultOverrides;
  }

  return {
    ...defaultOverrides,
    Option: (props) => <Option {...props}>{optionRenderer(props)}</Option>,
  };
}

/**
 * Tries to find the option label given the `value` and all the `options`.
 * It also works for option groups (i.e `{ label: string, options: OptionBase[] }`).
 */
function findOptionLabel(
  options: CustomSelectFieldProps['options'],
  value: string,
) {
  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return options.reduce((label, currentOption) => {
    if (label !== undefined) {
      return label;
    }

    if ('value' in currentOption && value === currentOption.value) {
      return currentOption.label;
    }

    if ('options' in currentOption) {
      const foundOption = currentOption.options.find(
        (option) => option.value === value,
      );

      return foundOption?.label ?? label;
    }

    return label;
  }, undefined);
}

const CustomSelectField = ({
  disabled,
  formatOptionLabel,
  isSearchable,
  name,
  optionRenderer,
  options,
  placeholder,
  ...rest
}: CustomSelectFieldProps) => {
  const fieldRenderer = ({
    // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    field: { onChange, value: currentValue },
    // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    formState: { disabled: isFormDisabled },
  }) => (
    <CustomSelectFieldContainerStyled>
      <ReactSelect
        options={options}
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        onChange={({ value }: OptionBase) => {
          onChange(value); // this 'hack' is required to prevent setting the entire option as value
        }}
        // value needs to be provided as an option object in order to have selected value set properly
        value={
          currentValue && {
            label: findOptionLabel(options, currentValue),
            value: currentValue,
          }
        }
        className="custom-select"
        classNamePrefix="select"
        components={getCustomComponents(optionRenderer)}
        isDisabled={disabled || isFormDisabled}
        isSearchable={isSearchable}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
      />
    </CustomSelectFieldContainerStyled>
  );

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...rest} />
  );
};

CustomSelectField.defaultProps = {
  formatOptionLabel: undefined,
  isSearchable: true,
  optionRenderer: undefined,
  placeholder: undefined,
};

export default CustomSelectField;
