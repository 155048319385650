// @ts-nocheck

import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Token } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Tooltip from 'components/tooltip/Tooltip';
import { useFormContext, useWatch } from 'react-hook-form';
import GenericFormField from '../genericFormField/GenericFormField';
import { TypeAheadFieldProps } from './TypeAheadField.types';
import {
  TypeAheadSearchOption,
  TypeaheadStyled,
} from './TypeAheadField.styles';
import tagsSchema from './typeAhead.schemas';

const TypeAheadField: FC<TypeAheadFieldProps> = ({
  allowNew,
  className,
  name,
  id = name,
  labelKey,
  multiple,
  newSelectionPrefix,
  options,
  placeholder,
  placeholderAlwaysVisible,
  renderMenuItemChildren,
  selected,
  saveOnEnter,
  tagValidationType,
  maxTagsDisplayed,
  maxTagLength,
  renderMenu,
  inputProps,
  open,
  ...props
}) => {
  const { t } = useTranslation();
  const fieldRef = useRef<HTMLInputElement>(null);
  const previousSelectedValues = useWatch({ name });
  const { setValue } = useFormContext();

  const fieldRenderer = ({ field: { ref, onChange, onBlur } }) => {
    if (placeholderAlwaysVisible && fieldRef.current) {
      fieldRef.current.getInput().placeholder = placeholder;
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      if (event.key === 'Enter' && saveOnEnter && inputValue) {
        const readyToSetValue =
          tagsSchema.isValidSync({ [tagValidationType]: inputValue }) ||
          !tagValidationType;

        if (!readyToSetValue) return;

        setValue(name, [
          ...(previousSelectedValues || []),
          { name: inputValue },
        ]);

        fieldRef?.current?.hideMenu();
        fieldRef?.current?.clear();
      }
    };

    return (
      <TypeaheadStyled
        allowNew={allowNew}
        className={className}
        id={id}
        labelKey={labelKey}
        instanceRef={ref}
        multiple={multiple}
        minLength={1}
        newSelectionPrefix={
          newSelectionPrefix && (
            <TypeAheadSearchOption>{newSelectionPrefix}</TypeAheadSearchOption>
          )
        }
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        renderMenuItemChildren={renderMenuItemChildren}
        selected={selected}
        emptyLabel={t('form.typeAhead.noResults')}
        promptText={t('form.typeAhead.typeToSearch')}
        ref={fieldRef}
        searchText={t('form.typeAhead.searching')}
        inputProps={{
          // @ts-expect-error - needs to be ignored due to Typescript limitation https://github.com/microsoft/TypeScript/issues/28960
          'data-testid': id,
          ...inputProps,
        }}
        renderToken={(option, p, idx) => {
          const { name: tagName } = option;
          const hasTooltip = tagName.length > maxTagLength;
          const tag = hasTooltip
            ? `${tagName.slice(0, maxTagLength)}...`
            : tagName;

          if (idx >= maxTagsDisplayed) {
            return null;
          }

          if (hasTooltip) {
            return (
              <Tooltip key={idx} text={tagName}>
                <Token onRemove={p.onRemove} option={option}>
                  {tag}
                </Token>
              </Tooltip>
            );
          }

          return (
            <Token key={idx} onRemove={p.onRemove} option={option}>
              {tag}
            </Token>
          );
        }}
        renderMenu={renderMenu}
        open={open}
      />
    );
  };

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...props} />
  );
};

export default TypeAheadField;
