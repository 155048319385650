import { DeepPartial } from 'react-hook-form';
import { Namespace, TFunction } from 'react-i18next';
import { Description, DescriptionTranslation } from 'models/Descriptions';
import * as Yup from 'yup';
import { Channel } from 'models/Channels';
import { isRequiredAtPropertyLevel } from './PropertySettingsDescriptionsTabFormFields.utils';
import { DescriptionFields } from './PropertySettingsDescriptionsTab.types';

export const AIRBNB_MAX_CHARS = 65_535;
export const DESCRIPTION_FIELD_MAX_CHARS = 49_150;
export const SHORT_SUMMARY_MAX_CHARS = 250;
export const SHORT_SUMMARY_MAX_CHARS_IF_VRBO_ENABLED = 20;
export const SUMMARY_MAX_CHARS = 500;
export const NAME_MAX_CHARS = 50;

const isValidForAirbnb = ({
  access,
  interaction,
  neighbourhood,
  notes,
  shortSummary,
  space,
  transit,
}: Description) => {
  const totalCharacters = [
    access,
    interaction,
    neighbourhood,
    notes,
    shortSummary,
    space,
    transit,
  ]
    .filter((field) => field != null)
    .reduce((acc, field) => acc + field.length, 0);

  return totalCharacters <= AIRBNB_MAX_CHARS;
};

export const propertySettingsDescriptionsTabSchema = ({
  activeChannelsAtPropertyLevel,
  t,
}: {
  activeChannelsAtPropertyLevel: Channel[];
  t: TFunction<Namespace<'en'>>;
}) => {
  const isVrboActiveAtPropertyLevel = activeChannelsAtPropertyLevel.includes(
    Channel.homeaway,
  );
  const isAirbnbActiveAtPropertyLevel = activeChannelsAtPropertyLevel.includes(
    Channel.airbnb,
  );

  const isRequired = (fieldName: DescriptionFields) => {
    return isRequiredAtPropertyLevel(activeChannelsAtPropertyLevel, fieldName);
  };

  const airbnbValidationObject = {
    name: 'combinedCharacterCount',
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    test: (_, testContext) => {
      return isValidForAirbnb(testContext.parent);
    },
    message: t('pageProperty.descriptions.airbnbCombinedCharacterCountError'),
  };

  return Yup.object({
    access: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
    houseManual: Yup.string()
      .optional()
      .nullable()
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
    interaction: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
    locale: Yup.string(),
    name: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isRequired('name'),
        then: (schema) => schema.required(),
      })
      .transform((value) =>
        value ? value.substring(0, NAME_MAX_CHARS) : value,
      ),
    neighbourhood: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
    notes: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
    shortSummary: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isVrboActiveAtPropertyLevel,
        then: Yup.string()
          .required()
          .min(
            SHORT_SUMMARY_MAX_CHARS_IF_VRBO_ENABLED,
            t('pageProperty.descriptions.vrboShortSummaryMinLength'),
          ),
      })
      .when([], {
        is: () => isRequired('shortSummary'),
        then: (schema) => schema.required(),
      })
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, SHORT_SUMMARY_MAX_CHARS) : value,
      ),
    space: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
    summary: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isRequired('summary'),
        then: (schema) => schema.required(),
      })
      .transform((value) =>
        value ? value.substring(0, SUMMARY_MAX_CHARS) : value,
      ),
    transit: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      })
      .transform((value) =>
        value ? value.substring(0, DESCRIPTION_FIELD_MAX_CHARS) : value,
      ),
  });
};

/**
 * This will cast a translation to a description, following a few rules:
 * - Prefer original description fields over translation fields, if they're not
 *   empty;
 * - If Vrbo is enabled and shortSummary has less than 20 characters, it'll
 *   automatically add `.` (periods) until the string meets the requirement;
 * - If Airbnb is enabled and the sum of lengths of all translated fields
 *   surpasses the AIRBNB_MAX_CHARS, then it'll evenly distribute the remaining
 *   length among the fields that were empty in the original description;
 *
 * This is all to avoid the most we can having translations that makes a
 * description form invalid, so that the user has less things to adjust (or even
 * none).
 */
export const transformDescriptionBasedOnTranslation = ({
  originalDescription: _originalDescription,
  translation,
  activeChannelsAtPropertyLevel,
  t,
}: {
  originalDescription: Partial<Description>;
  translation: DescriptionTranslation;
  activeChannelsAtPropertyLevel: Channel[];
  t: TFunction<Namespace<'en'>>;
}): Description => {
  const isVrboActiveAtPropertyLevel = activeChannelsAtPropertyLevel.includes(
    Channel.homeaway,
  );
  const isAirbnbActiveAtPropertyLevel = activeChannelsAtPropertyLevel.includes(
    Channel.airbnb,
  );
  const schema = propertySettingsDescriptionsTabSchema({
    activeChannelsAtPropertyLevel,
    t,
  });

  const isFieldEmpty = (field: string) => !field || field.trim() === '';

  // Casts the translation object to the schema to ensure that all fields are
  // present and of the correct type, besides capping the length of the fields.
  const castedTranslation = schema.cast(translation);
  const originalDescription = schema.cast(_originalDescription);

  // Creates a description object based on the original description but
  // overriding each empty field with its translation.
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const updatedDescription: Description = Object.entries(
    originalDescription,
  ).reduce(
    (result, [key, value]) => {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      if (isFieldEmpty(value) && !isFieldEmpty(castedTranslation[key])) {
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        return { ...result, [key]: castedTranslation[key] };
      }
      return result;
    },
    { ...originalDescription },
  );

  // If original shortSummary was empty and Vrbo is active, caps the translated
  // shortSummary to 20 chars.
  if (
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    isFieldEmpty(originalDescription.shortSummary) &&
    isVrboActiveAtPropertyLevel &&
    updatedDescription.shortSummary.length < 20
  ) {
    updatedDescription.shortSummary = updatedDescription.shortSummary.padEnd(
      20,
      '.',
    );
  }

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  if (isAirbnbActiveAtPropertyLevel && !isValidForAirbnb(castedTranslation)) {
    const shortSummaryLength = updatedDescription.shortSummary.length;
    const airbnbRelatedFields: DescriptionFields[] = [
      'space',
      'access',
      'interaction',
      'neighbourhood',
      'transit',
      'notes',
    ];

    const translatedAirbnbFields = airbnbRelatedFields.filter(
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (key) => !isFieldEmpty(castedTranslation[key]),
    );

    const emptyFields = translatedAirbnbFields.filter((field) =>
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      isFieldEmpty(originalDescription[field]),
    );
    const nonEmptyFields = translatedAirbnbFields.filter(
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (field) => !isFieldEmpty(originalDescription[field]),
    );

    const nonEmptyFieldsLength = nonEmptyFields.reduce(
      // @ts-expect-error TS2533 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (acc, field) => acc + originalDescription[field].length,
      0,
    );

    const remainingCharacters =
      AIRBNB_MAX_CHARS - shortSummaryLength - nonEmptyFieldsLength;
    const fieldCap = Math.floor(remainingCharacters / emptyFields.length);

    emptyFields.forEach((field) => {
      updatedDescription[field] = updatedDescription[field].substring(
        0,
        fieldCap,
      );
    });
  }

  return updatedDescription;
};

export type PropertySettingsDescriptionsTabFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsDescriptionsTabSchema>>
>;

export const getFormDefaultValues = (
  description: Partial<Description>,
): PropertySettingsDescriptionsTabFormValues => {
  return {
    access: description?.access ?? '',
    houseManual: description?.houseManual ?? '',
    interaction: description?.interaction ?? '',
    locale: description?.locale ?? '',
    name: description?.name ?? '',
    neighbourhood: description?.neighbourhood ?? '',
    notes: description?.notes ?? '',
    shortSummary: description?.shortSummary ?? '',
    space: description?.space ?? '',
    summary: description?.summary ?? '',
    transit: description?.transit ?? '',
  };
};
