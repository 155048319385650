import { PropertyBusinessType } from 'models/Properties';
import useFetchPhotos from 'components/domain/property/useFetchPhotos';
import PermissionsAwareFieldset from '../common/PermissionsAwareFieldset';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import PhotoGrid from './components/photoGrid/PhotoGrid';
import PhotoGridLoader from './components/photoGrid/PhotoGridLoader';
import PropertyVideoTour from './components/propertyVideoTour/PropertyVideoTour';
import { SelectModeContextProvider } from './context/SelectModeContext';
import PropertySettingsPhotosTabHeader from './components/header/PropertySettingsPhotosTabHeader';

const PropertySettingsPhotosTab = () => {
  const { isLoading: isLoadingInitData, property } =
    usePropertySettingsInitData();
  const { data: photos, isLoading: isLoadingPhotos } = useFetchPhotos({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyUid: property.uid,
    isHotel: property.businessType === PropertyBusinessType.HOTEL,
    select: (data) => data.toSorted((a, b) => a.displayOrder - b.displayOrder),
  });

  return (
    <SelectModeContextProvider>
      <PropertyEditPermissionInfo />

      <PermissionsAwareFieldset>
        <PropertySettingsPhotosTabHeader
          isLoading={isLoadingInitData || isLoadingPhotos}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          photos={photos}
        />

        {/*
         // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        {isLoadingPhotos ? <PhotoGridLoader /> : <PhotoGrid photos={photos} />}

        <PropertyVideoTour />
      </PermissionsAwareFieldset>
    </SelectModeContextProvider>
  );
};

export default PropertySettingsPhotosTab;
