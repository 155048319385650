import PageHeader from 'components/pageHeader/PageHeader';
import Loader from 'components/loader/Loader';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'pages/stackedCalendar/InfiniteScroll';
import PageTitle from './PageTitle';
import useAirbnbNotifications from './useAirbnbNotifications';
import AirbnbNotificationItem from './AirbnbNotificationItem';
import AirbnbNotificationsPlaceholder from './AirbnbNotificationsPlaceholder';

const NOTIFICATIONS_PAGE_SIZE = 20;

const AirbnbNotifications = () => {
  const { t } = useTranslation();
  const { data, isLoading, fetchNextPage, hasNextPage } =
    useAirbnbNotifications({
      pageSize: NOTIFICATIONS_PAGE_SIZE,
    });

  const notifications =
    data?.pages?.flatMap((page) => page.notifications) ?? [];
  const isInitialFetching = isLoading && !notifications?.length;
  const emptyNotifications = notifications?.length === 0;

  if (isInitialFetching) {
    return <AirbnbNotificationsPlaceholder />;
  }

  return (
    <div className="container">
      <PageHeader title={<PageTitle />} />

      {emptyNotifications ? (
        <div
          className="text-center"
          data-testid="airbnb-notification-not-found"
        >
          {t('pageAirbnbNotifications.notFound')}
        </div>
      ) : (
        <InfiniteScroll
          load={fetchNextPage}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          hasMore={hasNextPage}
          loader={<Loader />}
        >
          {notifications?.map((notification) => (
            <AirbnbNotificationItem
              notification={notification}
              key={notification?.uid}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default AirbnbNotifications;
