import { AppConfig } from '../models/Config';
import useAppSelector from './useAppSelector';

const fallbackConfig = {
  customSettings: {},
  featureFlags: [],
  intercomAppId: 'p5dki4sl',
  showIntercom: false,
  showVersionNumber: false,
  hasAiEnabled: false,
};

const useAppConfig = (noFallback?: boolean): AppConfig => {
  const appConfig = useAppSelector((state) => state.config.appConfig);

  if (noFallback) {
    return appConfig;
  }

  return appConfig || fallbackConfig;
};

export default useAppConfig;
