import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetAssignmentSettingsResponseTO } from 'models/AssignmentSettings';
import API from 'services/API';

export const ASSIGNMENT_SETTINGS_BASE_QUERY_KEY = 'assignment-settings';

type ReactQueryOptions<T> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;

type UseFetchAssignmentSettingsArgs<T> = {
  propertyUid: string;
} & ReactQueryOptions<T>;

const useFetchAssignmentSettings = <T = GetAssignmentSettingsResponseTO>({
  propertyUid,
  enabled,
  ...options
}: UseFetchAssignmentSettingsArgs<T>) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [ASSIGNMENT_SETTINGS_BASE_QUERY_KEY, propertyUid],
    queryFn: async () => {
      const response = await API.get<GetAssignmentSettingsResponseTO>(
        `/api/internal/properties/${propertyUid}/assignment-settings`,
      );

      return response.data as T;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchAssignmentSettings;
