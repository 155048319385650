import { useContext, useState } from 'react';
import { Col, Modal, ModalTitle, Row } from 'react-bootstrap';
import Button from 'components/button/Button';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import BodyModalNoConnection from '../UI/BodyModalNoConnection';
import FooterModalNoConnection from '../UI/FooterModalNoConnection';
import BodyModalLoader from '../UI/BodyModalLoader';
import BodyModalConnection from '../UI/BodyModalConnection';
import useImportBookingDotComPropertiesModalInit from '../useImportBookingDotComPropertiesModalInit';
import useBookingDotComOperationsImportPropertyMutation from '../useBookingDotComOperationsImportPropertyMutation';
import useImportBookingDotComPropertiesConfirmationModal from '../useImportBookingDotComPropertiesConfirmationModal';
import { ImportBCPropertiesModalStyled } from './ImportBookingDotComPropertiesModal.styles';
import { ImportType } from './ImportBookingDotComPropertiesModal.utils';
import ImportBookingDotComPropertiesContext from './ImportBookingDotComPropertiesContext';

const ImportBookingDotComPropertiesModalInner = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const { importableProperties } = useContext(
    ImportBookingDotComPropertiesContext,
  );
  const { contracts, isLoading } = useImportBookingDotComPropertiesModalInit();
  const { openBookingDotComPropertiesConfirmationModal } =
    useImportBookingDotComPropertiesConfirmationModal();

  const { importProperties, isLoading: isImporting } =
    useBookingDotComOperationsImportPropertyMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const defaultValues = {
    importType: ImportType.ALL_PROPERTIES,
    propertyIds: [],
  };

  const schema = {
    legalEntityId: Yup.number().required(),
    importType: Yup.string().required(),
    propertyIds: Yup.array().of(Yup.string()).required(),
  };

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const submitHandler = ({ importType, propertyIds }) => {
    setErrorMessage(null);

    if (
      propertyIds.length === 0 &&
      importType === ImportType.SELECTED_PROPERTY
    ) {
      setErrorMessage(
        t(
          'pageProperties.importBookingDotComPropertiesModal.pleaseSelectAProperty',
        ),
      );
      return;
    }

    const successCallback = () => {
      onClose();
      openBookingDotComPropertiesConfirmationModal();
    };

    if (importType === ImportType.SELECTED_PROPERTY) {
      importProperties({
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        properties: propertyIds.map((propertyId) => {
          return {
            propertyId,
            toggleOnChannel: false,
            importReservations: false,
          };
        }),
        successCallback,
      });
    } else if (importType === ImportType.ALL_PROPERTIES) {
      importProperties({
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        properties: importableProperties.map(({ bookingDotComId }) => {
          return {
            propertyId: bookingDotComId,
            toggleOnChannel: false,
            importReservations: false,
          };
        }),
        successCallback,
      });
    }
  };

  return (
    <ImportBCPropertiesModalStyled data-testid="import-bc-properties-modal">
      <FormWithProvider
        defaultValues={defaultValues}
        onSubmit={submitHandler}
        schema={schema}
      >
        <Modal.Header
          data-testid="import-bc-properties-modal-header"
          closeButton
        >
          <ModalTitle>
            {t('pageProperties.importBookingDotComPropertiesModal.title')}
          </ModalTitle>
        </Modal.Header>

        <Modal.Body>
          {isLoading && <BodyModalLoader />}
          {!isLoading && contracts?.length === 0 && <BodyModalNoConnection />}
          {/*
           // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {!isLoading && contracts?.length > 0 && <BodyModalConnection />}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={4}>
              {errorMessage && (
                <p className="text-danger text-left">{errorMessage}</p>
              )}
            </Col>
            <Col xs={8}>
              <Button data-testid="modal-cancel-button" onClick={onClose}>
                {t('common.cancel')}
              </Button>

              {!isLoading && contracts?.length === 0 && (
                <FooterModalNoConnection onClose={onClose} />
              )}

              {/*
               // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
              {!isLoading && contracts?.length > 0 && (
                <Button
                  data-testid="modal-import-button"
                  bsStyle="primary"
                  type="submit"
                  disabled={!importableProperties?.length || isImporting}
                >
                  {t(
                    'pageProperties.importBookingDotComPropertiesModal.submit',
                  )}
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Footer>
      </FormWithProvider>
    </ImportBCPropertiesModalStyled>
  );
};

export default ImportBookingDotComPropertiesModalInner;
