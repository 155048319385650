import { useTranslation } from 'react-i18next';
import AirbnbImage from 'assets/images/channels/airbnblogo-colour.jpg';

const PageTitle = () => {
  const { t } = useTranslation();

  return (
    <>
      <span>
        <a style={{ color: 'inherit' }} href="#/channels">
          {t('common.channels')}
        </a>
        {' > '}
      </span>
      <a href="#/channels/airbnb/sync">
        <img
          style={{ marginLeft: '15px', marginRight: '15px', width: '120px' }}
          src={AirbnbImage}
          alt="airbnb-orbirental"
        />
      </a>
      {' > '}
      {t('pageAirbnbNotifications.title')}
    </>
  );
};

export default PageTitle;
