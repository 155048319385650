import { useTranslation } from 'react-i18next';
import StarIcon from 'assets/images/icons/star.svg?react';
import WarningIcon from 'assets/images/icons/warning.svg?react';
import {
  AverageRatingReviewsContainer,
  RecentTripIssuesContainer,
} from './OverallAirbnbRatings.styles';

const AverageRatingReviews = ({
  reviewsAmount,
  averageRating,
  reviewUidsWithIssues,
}: {
  reviewsAmount: number;
  averageRating: number;
  reviewUidsWithIssues: string[];
}) => {
  const { t } = useTranslation();

  const hasRecentTripIssues = reviewUidsWithIssues?.length > 0;

  return (
    <AverageRatingReviewsContainer data-testid="average-rating-reviews">
      <StarIcon />
      <span data-testid="airbnb-average-rating">
        {Number(averageRating || 0).toFixed(2)}
      </span>
      <div className="separator" />
      <span data-testid="airbnb-reviews-amount">
        {t(
          'pageProperty.reviews.airbnbGuest.overallAirbnbRating.amountReviews.reviews',
          { count: reviewsAmount || 0 },
        )}
      </span>
      {hasRecentTripIssues && (
        <>
          <div className="separator" />
          <RecentTripIssuesContainer data-testid="recent-trip-issues-container">
            <WarningIcon />
            {t(
              'pageProperty.reviews.airbnbGuest.overallAirbnbRating.recentTripIssues',
            )}
          </RecentTripIssuesContainer>
        </>
      )}
    </AverageRatingReviewsContainer>
  );
};

export default AverageRatingReviews;
