import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import defaultImage from 'assets/images/property/littlehouse.png';

import { Lead } from '../../../../models/Leads';
import {
  getLeadCheckInDate,
  getLeadCheckOutDate,
} from '../../../../utils/lead/leadUtils';
import { PropertyPicture, LeadModalButton } from './Resolutions.styles';

const BookingSummary = ({ lead }: { lead: Lead }) => {
  const navigate = useNavigate();

  return (
    <>
      <Col sm={3}>
        <PropertyPicture
          alt=""
          // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          src={lead.property.mainPicture?.largeThumbnail || defaultImage}
        />
      </Col>
      <Col sm={9}>
        <div>
          <LeadModalButton
            bsStyle="link"
            onClick={() => navigate(`/pipeline?leaduid=${lead.uid}`)}
          >
            {lead.firstName} {lead.lastName}
          </LeadModalButton>
        </div>
        <div>
          {format(getLeadCheckInDate(lead), 'MMM dd')}
          {' - '}
          {format(getLeadCheckOutDate(lead), 'MMM dd, yyyy')}
        </div>
        <div>{lead.property.name}</div>
      </Col>
    </>
  );
};

export default BookingSummary;
