import {
  isSameDay,
  isSameMonth,
  isSameWeek,
  nextSaturday,
  endOfMonth,
  isBefore,
  differenceInHours,
  startOfDay,
  endOfDay,
  addDays,
  subDays,
  isSaturday,
  isAfter,
} from 'date-fns';
import { Lead } from 'models/Leads';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import { CalendarDay } from './body/CalendarBody.types';
import { CalendarFilter } from './Calendar.types';

export const isCheckinVisible = (date: Date, lead: Lead): boolean => {
  return isSameDay(getLeadCheckInDate(lead), date);
};

export const isCheckoutVisible = (date: Date, lead: Lead): boolean => {
  return (
    isSameWeek(getLeadCheckOutDate(lead), date) &&
    isSameMonth(getLeadCheckOutDate(lead), date)
  );
};

export const getTotalDaysOnRow = (date: Date, lead: Lead): number => {
  if (isSaturday(date)) {
    return (
      1 -
      (isCheckinVisible(date, lead) ? 1 : 0) -
      (isCheckoutVisible(date, lead) ? 1 : 0)
    ); // If date is on Saturday, return one
  }

  let end = nextSaturday(date); // Next saturday

  // If month finish before saturday
  if (!isSameMonth(end, date)) {
    end = endOfMonth(date);
  }

  // If checkout is before end of saturday
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  if (isBefore(lead.checkOutDate, end)) {
    end = getLeadCheckOutDate(lead);
  }

  let bodyCount = Math.round(
    differenceInHours(endOfDay(end), startOfDay(date)) / 24,
  );

  if (bodyCount < 0) bodyCount = 0;

  return (
    bodyCount -
    (isCheckinVisible(date, lead) ? 1 : 0) -
    (isCheckoutVisible(date, lead) ? 1 : 0)
  );
};

export function getLimitStartDate(
  date: Date,
  calendarDays: CalendarDay[],
  mode: StackedCalendarMode,
): Date | null {
  let start = null;
  let continueLoop = true;
  if (!calendarDays) return null;

  for (let m = date; continueLoop; m = subDays(m, 1)) {
    const calendarDay = calendarDays.filter((d) => isSameDay(d.date, m))[0];

    if (!calendarDay) {
      continueLoop = false;
      break;
    }

    if (calendarDay.isLoading) {
      continueLoop = false;
      break;
    }
    if (mode !== StackedCalendarMode.PRICING && calendarDay.leads?.length) {
      const leadsCheckoutOnDate = calendarDay.leads.filter((l) =>
        isSameDay(getLeadCheckOutDate(l), m),
      );

      if (leadsCheckoutOnDate.length > 0) {
        continueLoop = false;
        start = m;
        break;
      }
    }

    start = m;
  }

  return start;
}

export function getUnloadedDatesForLeads(calendarDays: CalendarDay[]) {
  if (!calendarDays || calendarDays.length === 0) {
    return {
      start: null,
      end: null,
    };
  }

  const datesWithoutLeads = calendarDays
    .filter((day) => !day.leads?.length)
    .map((day) => day.date);

  // Reorder dates
  datesWithoutLeads.sort((a, b) => {
    if (isBefore(a, b)) return -1;
    if (isAfter(a, b)) return 1;
    return 0;
  });

  return {
    start: datesWithoutLeads[0],
    end: datesWithoutLeads[datesWithoutLeads.length - 1],
  };
}

export function getUnloadedDatesForJobs(calendarDays: CalendarDay[]) {
  if (!calendarDays || calendarDays.length === 0) {
    return {
      start: null,
      end: null,
    };
  }

  const datesWithoutJobs = calendarDays
    .filter((day) => !day.leads?.length)
    .map((day) => day.date);

  // Reorder dates
  datesWithoutJobs.sort((a, b) => {
    if (isBefore(a, b)) return -1;
    if (isAfter(a, b)) return 1;
    return 0;
  });

  return {
    start: datesWithoutJobs[0],
    end: datesWithoutJobs[datesWithoutJobs.length - 1],
  };
}

export function canUsePropertyCalendarCache(
  calendarDays: CalendarDay[],
  calendarFilter: CalendarFilter,
) {
  if (!calendarDays) return false;

  const dates = calendarDays
    .map((d) => d.date)
    .sort((a, b) => {
      if (isBefore(a, b)) return -1;
      if (isAfter(a, b)) return 1;
      return 0;
    });

  // is calendarFilter inside dates
  if (!dates.includes(calendarFilter.start)) return false;
  if (!dates.includes(calendarFilter.end)) return false;

  return !calendarDays.find((d) => d.isLoading);
}

export function getLimitEndDate(
  date: Date,
  calendarDays: CalendarDay[],
  mode: StackedCalendarMode,
): Date | null {
  let start = null;
  let continueLoop = true;
  if (!calendarDays) return null;

  for (let m = date; continueLoop; m = addDays(m, 1)) {
    const calendarDay = calendarDays.filter((d) => isSameDay(d.date, m))[0];

    if (!calendarDay) {
      continueLoop = false;
      break;
    }

    if (calendarDay.isLoading) {
      continueLoop = false;
      break;
    }
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    if (mode !== StackedCalendarMode.PRICING && calendarDay.leads?.length > 0) {
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const leadsCheckInOnDate = calendarDay.leads.filter((l) =>
        isSameDay(getLeadCheckInDate(l), m),
      );

      if (leadsCheckInOnDate?.length > 0) {
        continueLoop = false;
        start = m;
        break;
      }
    }

    start = m;
  }

  return start;
}

export function getLeadsOverlappingElement(leadViewElement: HTMLDivElement) {
  const bodyDayCell = leadViewElement.closest(
    'div.body-day-cell',
  ) as HTMLDivElement;

  if (!bodyDayCell) {
    return null;
  }

  const bodyDayCellLeads = bodyDayCell.querySelectorAll<HTMLDivElement>(
    'div[data-lead-uid]:not([width])',
  );

  if (
    bodyDayCellLeads.length === 2 &&
    ((bodyDayCellLeads[0].classList.contains('check-in') &&
      bodyDayCellLeads[1].classList.contains('check-out')) ||
      (bodyDayCellLeads[1].classList.contains('check-in') &&
        bodyDayCellLeads[0].classList.contains('check-out')))
  ) {
    return null;
  }

  return bodyDayCellLeads;
}
