import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import Switch from 'components/switch/Switch';
import useToggleTurno from '../hooks/useToggleTurno';
import {
  Container,
  Step,
  StepWrapper,
  TurnoConnectButton,
  Divider,
} from './ConnectSteps.styles';

const ConnectSteps = () => {
  const { t } = useTranslation();
  const { user, agency } = useAppUser();
  const { turnOnTurno, turnOffTurno, isLoading, isActive } = useToggleTurno();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onSwitchChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) turnOnTurno();
    if (!isChecked) turnOffTurno();
  };

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const turnoConnectLink = `https://app.turno.com/auth/register/hostfully?name=${user.firstName}&last-name=${user.lastName}&email=${agency.email}&phone=${agency.phone}&agency-id=${agency.uid}`;

  return (
    <Container>
      <h3>{t('pageTurnoManagement.stepsToConnect')}</h3>
      <StepWrapper>
        <Step>
          <p>{t('pageTurnoManagement.step1')}</p>
          <span>{t('pageTurnoManagement.toggleTheIntegration')}</span>

          <Switch
            checked={isActive}
            onChange={onSwitchChange}
            disabled={isLoading}
          />
        </Step>

        <Divider />

        <Step>
          <p>{t('pageTurnoManagement.step2')}</p>
          <span>{t('pageTurnoManagement.clickToTurnoAccount')}</span>
          <TurnoConnectButton href={turnoConnectLink} target="_blank">
            {t('pageTurnoManagement.goToTurno')}
          </TurnoConnectButton>
        </Step>
      </StepWrapper>
    </Container>
  );
};

export default ConnectSteps;
