import { ReactNode, useEffect } from 'react';
import {
  FormFooterStickyContent,
  FormFooterStickyStyled,
} from './FormFooterSticky.styles';

const FormFooterSticky = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    const scrollListener = () => {
      const staticFooter = document.getElementById('form-footer');
      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const staticFooterRect = staticFooter.getBoundingClientRect();
      const stickyFooter = document.getElementById('form-footer-sticky');

      const isStaticFooterVisible =
        staticFooterRect.top >= 0 &&
        staticFooterRect.bottom <= window.innerHeight;

      if (isStaticFooterVisible) {
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        stickyFooter.classList.add('d-none');
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        staticFooter.classList.remove('invisible');
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      } else if (stickyFooter.classList.contains('d-none')) {
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        stickyFooter.classList.remove('d-none');
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        staticFooter.classList.add('invisible');
      }
    };

    const scrollableElement =
      document.getElementById('app-page-content') || document;

    scrollableElement.addEventListener('scroll', scrollListener);

    scrollListener(); // initial call to hide the sticky footer in case the entire static one is already visible

    return () => {
      scrollableElement.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <FormFooterStickyStyled
      data-testid="form-footer-sticky"
      id="form-footer-sticky"
    >
      <FormFooterStickyContent>{children}</FormFooterStickyContent>
    </FormFooterStickyStyled>
  );
};

export default FormFooterSticky;
