import useAppUser from 'hooks/useAppUser';
import { useParams } from 'react-router-dom';
import { ServiceProvider } from 'models/ServiceProvider';
import useFetchServiceProviders from './sections/useServiceProvidersQuery';
import useAgencyServicesQuery from './sections/useAgencyServicesQuery';

export type ServiceProviderTableItem = ServiceProvider & {
  servicesTitles: string;
  isActive: boolean;
};

const useServiceProvidersList = () => {
  const { agencyUid } = useAppUser();
  const { uid: propertyUid } = useParams();

  const {
    isLoading: isAgencyServicesLoading,
    data: { services: agencyServices = [] } = {},
  } = useAgencyServicesQuery({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    agencyUid,
  });

  const {
    data: { serviceProviders: agencyServiceProviders = [] } = {},
    isLoading: isAgencyServiceProvidersLoading,
  } = useFetchServiceProviders({
    agencyUid,
  });

  const {
    data: { serviceProviders: propertyServiceProviders = [] } = {},
    isLoading: isPropertyServiceProvidersLoading,
  } = useFetchServiceProviders({
    propertyUid,
  });

  const isLoading =
    isAgencyServiceProvidersLoading ||
    isPropertyServiceProvidersLoading ||
    isAgencyServicesLoading;

  const getServicesTitles = (provider: ServiceProvider) => {
    const services = agencyServices?.filter(
      (service) => service.serviceProviderUid === provider.uid,
    );

    return services?.map((s) => s.title).join(', ');
  };

  const serviceProviders: ServiceProviderTableItem[] =
    agencyServiceProviders?.map((provider) => ({
      ...provider,
      isActive: propertyServiceProviders?.some((p) => p.uid === provider.uid),
      servicesTitles: getServicesTitles(provider),
    })) ?? [];

  return {
    isLoading,
    serviceProviders,
  };
};

export default useServiceProvidersList;
