import { normalizeKey } from 'utils/localeUtils';
import { TFunction } from 'i18next';
import { PricingProperty } from 'components/domain/propertyPricing/modal/PropertyPricingModal.types';
import { trimAndLimitCharactersNumber } from 'utils/stringUtils';
import { AnalyticsBasicProperty } from 'pages/analytics/types/Analytics.types';
import {
  Property,
  PropertyBusinessType,
  PropertyBase,
} from '../../models/Properties';
import { SelectFieldOption } from '../../components/forms/selectField/SelectField.types';

export function getPropertySubUnitUids(property: Property): string[] {
  return property.subUnits?.map(({ uid }) => uid) || [];
}

export const TAB_HTML_TAG = String.fromCharCode(160);

export function getPropertyByUid(
  properties: Property[] | PropertyBase[] | undefined,
  uid: string,
) {
  let property = null;

  properties?.forEach((p) => {
    if (p.uid === uid) property = p;

    if (p.subUnits?.find((subUnit) => subUnit.uid === uid)) {
      property = p.subUnits?.find((subUnit) => subUnit.uid === uid);
    }

    if (p.unitTypes?.find((unitType) => unitType.uid === uid)) {
      property = p.unitTypes?.find((unitType) => unitType.uid === uid);
    }

    if (
      p.unitTypes?.find((unitType) =>
        unitType.units?.find((unit) => unit.uid === uid),
      )
    ) {
      property = p.unitTypes?.find((unitType) =>
        unitType.units?.find((unit) => unit.uid === uid),
      );
    }
  });

  return property;
}

export function getPropertyCurrency(
  properties:
    | Property[]
    | PropertyBase[]
    | AnalyticsBasicProperty[]
    | undefined,
  propertyUid: string,
): string {
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return properties.find(
    (property) =>
      property.uid === propertyUid ||
      property.unitTypes?.find((unitType) => unitType.uid === propertyUid) ||
      property.subUnits?.find((subUnit) => subUnit.uid === propertyUid),
  )?.pricing.currency;
}

export function isInactiveMasterWithActiveSubUnits({
  property,
}: {
  property: Property;
}): boolean {
  const isMaster =
    property.businessType === PropertyBusinessType.STANDALONE_PROPERTY &&
    property.subUnits?.length > 0;
  const isAnySubUnitActive = property.subUnits?.some((su) => su.isActive);

  return isMaster && !property.isActive && isAnySubUnitActive;
}

export function isPropertyDropdownDisabled({
  property,
  enableHotels = false,
}: {
  property: Property;
  enableHotels?: boolean;
}): boolean {
  const isHotel = property.businessType === PropertyBusinessType.HOTEL;

  return (
    (!enableHotels && isHotel) ||
    isInactiveMasterWithActiveSubUnits({ property })
  );
}

export interface GetPropertyOptionsFilterProps {
  enableUnitTypes?: boolean;
  enableHotels?: boolean;
  showUnits?: boolean;
  showInactiveProperties?: boolean;
}

export function getPropertyOptions({
  property,
  enableUnitTypes = false,
  enableHotels = false,
  showUnits = true,
  showInactiveProperties = true,
}: GetPropertyOptionsFilterProps & {
  property: Property;
}): SelectFieldOption[] {
  const options: SelectFieldOption[] = [];

  // Should not display at all if showInactiveProperties is false and the
  // property is inactive. Unless the property is an inactive master property
  // but has some subunits active.
  if (
    !showInactiveProperties &&
    !property.isActive &&
    !isInactiveMasterWithActiveSubUnits({ property })
  ) {
    return options;
  }

  options.push({
    disabled: isPropertyDropdownDisabled({ property, enableHotels }),
    value: property.uid,
    label: property.name,
  });

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  if (property.unitTypes?.length > 0) {
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    property.unitTypes.forEach((ut) => {
      if (!showInactiveProperties && !ut.isActive) {
        return;
      }

      options.push({
        disabled: !enableUnitTypes,
        value: ut.uid,
        label: `${TAB_HTML_TAG} ${trimAndLimitCharactersNumber({
          text: ut.name,
          limit: 45,
        })}`,
      });

      if (showUnits) {
        const sortedUnits = [...(ut.units || [])];
        sortedUnits?.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        sortedUnits.forEach((u) => {
          if (!showInactiveProperties && !u.isActive) {
            return;
          }

          options.push({
            value: u.uid,
            label: `${TAB_HTML_TAG} ${TAB_HTML_TAG} ${trimAndLimitCharactersNumber(
              {
                text: u.name,
                limit: 45,
              },
            )}`,
          });
        });
      }
    });
  } else if (property.subUnits?.length > 0) {
    property.subUnits.forEach((su) => {
      if (!showInactiveProperties && !su.isActive) {
        return;
      }

      options.push({
        value: su.uid,
        label: `${TAB_HTML_TAG} ${trimAndLimitCharactersNumber({
          text: su.name,
          limit: 45,
        })}`,
      });
    });
  }

  return options;
}

export function getPropertyDetailLocalizedIntValue(
  value: number,
  baseLocaleKey: string,
  t: TFunction,
) {
  if (!value || value < 0) {
    return '';
  }

  if (value === 1) {
    return t(normalizeKey(`componentProperty.details.${baseLocaleKey}Single`));
  }

  return t(normalizeKey(`componentProperty.details.${baseLocaleKey}Number`), {
    number: value,
  });
}

export function getPropertyListLocalizedValue(
  properties: Property[] | PropertyBase[] | PricingProperty[],
  localeKey: string,
  t: TFunction,
) {
  return t(normalizeKey(`${localeKey}`), {
    propertiesList: properties.map((p) => p.name).join(', '),
  });
}

export function getPropertyEditPath(
  property: Partial<Property> | PropertyBase,
) {
  const { uid, businessType } = property;

  switch (businessType) {
    case PropertyBusinessType.HOTEL:
      return `#/hotel?uid=${uid}`;
    case PropertyBusinessType.UNIT_TYPE:
      return `#/unit-type?uid=${uid}`;
    default:
      return `#/property?uid=${uid}`;
  }
}

export function getPropertyAddUnitTypePath(
  property: Pick<PropertyBase, 'uid'>,
) {
  return `#/l/unit_type_add.jsp?uid=${property.uid}`;
}

/**
 * This should be used everywhere where we need to get the propertyUid from the visibleProperties array, and to check businessType
 * @param uid propertyUid from visibleProperties array
 */
export function getPropertyUidFromStringType(uid: string) {
  const isUnitType = uid.includes('[UNIT_TYPE]');
  const isUnit = uid.includes('[UNIT]');
  const isSubUnit = uid.includes('[SUB_UNIT]');
  const isHotel = uid.includes('[HOTEL]');
  const isStandaloneProperty = uid.includes('[STANDALONE_PROPERTY]');

  return {
    isUnitType,
    isUnit,
    isSubUnit,
    isHotel,
    isStandaloneProperty,
    propertyUid: uid.split('[')[0],
  };
}

export function getFullAddress(property: Pick<PropertyBase, 'address'>) {
  const { address } = property;

  return [address?.address, address?.address2, address?.city, address?.state]
    .map((text) => text?.trim())
    .filter(Boolean)
    .join(', ');
}
