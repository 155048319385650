import { Glyphicon } from 'react-bootstrap';
import Button from 'components/button/Button';
import {
  UploadFieldFileListItemStyled,
  UploadFieldFileListStyled,
} from './UploadFieldFileList.styles';
import LinkListItem from './UploadFieldFileListItem/LinkListItem';
import { UploadFieldListItemProps } from './UploadField.types';

interface UploadFieldFileListProps<T = File> {
  className?: string;
  files: T[];
  isDisabled: boolean;
  isLoading?: boolean;
  getName: (arg: T) => string;
  getUrl: (arg: T) => string;
  onDelete: (arg: T) => void;
  ListItemComponent?: React.ComponentType<UploadFieldListItemProps<T>>;
}

const UploadFieldFileList = <T,>({
  files,
  className,
  isDisabled,
  isLoading,
  getName,
  getUrl,
  onDelete,
  ListItemComponent = LinkListItem,
}: UploadFieldFileListProps<T>) => {
  return (
    <UploadFieldFileListStyled data-testid="file-list" className={className}>
      {files.map((file, index) => (
        <UploadFieldFileListItemStyled
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${getName(file)}`}
          $isDisabled={isDisabled}
          data-testid="file-list-item"
        >
          <ListItemComponent
            file={file}
            isDisabled={isDisabled}
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            isLoading={isLoading}
            getName={getName}
            getUrl={getUrl}
          />

          <Button
            data-testid="delete-file-button"
            bsStyle="link"
            onClick={() => onDelete(file)}
            disabled={isDisabled}
          >
            <Glyphicon glyph="trash" className="small" />
          </Button>
        </UploadFieldFileListItemStyled>
      ))}
    </UploadFieldFileListStyled>
  );
};

UploadFieldFileList.defaultProps = {
  className: '',
  isLoading: undefined,
  ListItemComponent: undefined,
};

export default UploadFieldFileList;
