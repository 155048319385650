import { ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import HomeIcon from 'components/icons/HomeIcon';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { RECURRING_EXPENSE_PERIOD } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';
import { OwnerAdjustmentsPathParams } from '../OwnerAdjustments.types';
import useOwnerAdjustmentModal from '../modal/hooks/useOwnerAdjustmentModal';
import { ADJUSTMENT_TYPES } from '../modal/OwnerAdjustmentModal.types';
import {
  OwnerAdjustmentsCreateNewButton,
  OwnerAdjustmentsHeaderWrapper,
  OwnerAdjustmentsHeaderForm,
  OwnerAdjustmentsPropertySelect,
} from './OwnerAdjustmentsHeader.styles';

const OwnerAdjustmentsHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { propertyUid } = useParams<OwnerAdjustmentsPathParams>();
  const { agency } = useAppUser();
  const { allProperties, isFetching } = useFetchAllProperties({
    enabled: true,
    conditions: propertyCalendarConditionGraphQLQuery,
    fields: propertyCalendarGraphQLQuery,
  });
  const { openOwnerAdjustmentModal } = useOwnerAdjustmentModal();
  const selectedPropertyId = propertyUid ?? allProperties?.[0].uid;
  const selectedProperty = allProperties?.find(
    ({ uid }) => uid === selectedPropertyId,
  );

  const handlePropertyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    navigate(`/owner-adjustments/${event.target.value}`, { replace: true });
  };

  return (
    <OwnerAdjustmentsHeaderWrapper title={t('pageOwnerAdjustments.title')}>
      <OwnerAdjustmentsHeaderForm
        horizontal
        defaultValues={{}}
        schema={{}}
        onSubmit={() => {}}
      >
        {isFetching ? (
          <LoadingPlaceholder linesCount={2.5} />
        ) : (
          <OwnerAdjustmentsPropertySelect
            name="propertyUid"
            onChange={handlePropertyChange}
            value={selectedPropertyId}
            // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            properties={allProperties}
            leftAddon={<HomeIcon />}
          />
        )}
      </OwnerAdjustmentsHeaderForm>
      <OwnerAdjustmentsCreateNewButton
        data-testid="createNewOwnerAdjustment"
        onClick={() =>
          openOwnerAdjustmentModal({
            defaultAdjustment: {
              adjustmentType: ADJUSTMENT_TYPES.ONE_TIME,
              recurringExpensePeriod: RECURRING_EXPENSE_PERIOD.WEEKLY,
              amount: 0,
              taxationRate: 0,
              notes: '',
              currency: selectedProperty?.pricing?.currency ?? agency?.currency,
            },
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            propertyUid: selectedPropertyId,
          })
        }
        bsStyle="primary"
      >
        {t('pageOwnerAdjustments.createNew')}
      </OwnerAdjustmentsCreateNewButton>
    </OwnerAdjustmentsHeaderWrapper>
  );
};

export default OwnerAdjustmentsHeader;
