import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import useAppUser from 'hooks/useAppUser';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import useCreateMultiUnitProperty from './useCreateMultiUnitProperty';
import {
  AddMultiUnitPropertyFormValues,
  MultiUnitPropertyCreationPayload,
} from './AddMultiUnitProperty.schema';

const useAddMultiUnitPropertySubmit = () => {
  const { agencyUid } = useAppUser();
  const { createMultiUnitProperty } = useCreateMultiUnitProperty();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { t } = useTranslation();

  return async (formValues: AddMultiUnitPropertyFormValues) => {
    const {
      propertyAddress: {
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        addressLine1,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        addressLine2,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        city,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        countryCode,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        state,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        zipCode,
      },
      propertyDetails: {
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        internalThumbnailUrl,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyName,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyType,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        currency,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        securityDeposit,
      },
    } = formValues;

    const payload: MultiUnitPropertyCreationPayload = {
      address: {
        address: addressLine1,
        address2: addressLine2,
        city,
        countryCode,
        state,
        zipCode,
      },
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      agencyUid,
      mainPhotoUrl: internalThumbnailUrl,
      name: propertyName,
      pricing: {
        currency,
        securityDeposit,
      },
      type: propertyType,
    };

    await createMultiUnitProperty(payload)
      .then(({ data, response }) => {
        const apiErrorCode = response?.data?.apiErrorCode;
        const apiErrorMessage = response?.data?.apiErrorMessage;

        if (apiErrorCode || apiErrorMessage) {
          notifyError(
            getErrorMessage({
              apiErrorCode,
              // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
          return;
        }

        const propertyUid = data?.hotel?.uid;

        if (propertyUid) {
          invalidateFetchAllProperties();

          notifySuccess(t('pageProperty.add.multiUnit.success'));

          navigate(`/property/${propertyUid}`);
        }
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };
};

export default useAddMultiUnitPropertySubmit;
