import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { propertyExpectationsQuery } from 'components/domain/property/expectation/propertyExpectationQuery';
import CommonTable from 'components/table/CommonTable';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import { PropertyExpectation } from 'models/PropertyExpectations';
import NameCell from './cells/NameCell';
import ActionsCell from './cells/ActionsCell';

const PropertyExpectationsTable = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<PropertyExpectation>();

  const columns = [
    columnHelper.accessor('expectationType', {
      header: t('common.name'),
      cell: NameCell,
      sortDescFirst: true,
    }),
    columnHelper.accessor('expectationDescription', {
      header: t('common.description'),
      sortDescFirst: true,
    }),
    // columnHelper.display({
    //   id: 'appliesTo',
    //   header: t('pageProperty.common.cells.appliesTo'),
    //   cell: AppliesToCell,
    // }),
    // actions column
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    getQuery: (options) =>
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyExpectationsQuery({ propertyUid, ...options }),
    pageSize: 6,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoading}
      emptyState={t(
        'pageProperty.feesTaxesAndPolicies.propertyExpectations.emptyTable',
      )}
      data-testid="property-expectations-table"
    />
  );
};

export default PropertyExpectationsTable;
