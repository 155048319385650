import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetPropertyOwnerResponseTO } from 'models/PropertyOwner';
import API from 'services/API';

export const PROPERTY_OWNERS_BASE_QUERY_KEY = 'propertyOwners';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetPropertyOwnerResponseTO>,
  'queryKey' | 'queryFn'
>;

type usePropertyOwnersArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const usePropertyOwners = ({
  propertyUid,
  enabled = true,
  ...options
}: usePropertyOwnersArgs) => {
  return useAppQuery<GetPropertyOwnerResponseTO>({
    queryKey: [PROPERTY_OWNERS_BASE_QUERY_KEY, 'detail', propertyUid],
    queryFn: async () => {
      const response = await API.get<GetPropertyOwnerResponseTO>(
        `/api/v3.2/property-ownership/${propertyUid}`,
      );

      return response.data;
    },
    enabled,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default usePropertyOwners;
