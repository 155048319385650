import useFeatures from 'hooks/useFeatures';
import FormWithProvider from 'components/forms/form/Form';
import Loader from 'components/loader/Loader';
import { useTranslation } from 'react-i18next';
import PropertySettingsBetaAccessInfo from '../PropertySettingsBetaAccessInfo';
import FormFooter from '../common/FormFooter';
import PermissionsAwareFieldset from '../common/PermissionsAwareFieldset';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { PropertySettingsServiceProvidersTabStyled } from './PropertySettingsServiceProvidersTab.styles';
import ServiceProvidersHeader from './sections/header/ServiceProvidersHeader';
import ServiceProvidersNotes from './sections/notes/ServiceProvidersNotes';
import ServiceProvidersTable from './sections/table/ServiceProvidersTable';
import useServiceProvidersList from './useServiceProvidersList';
import useServiceProviderNotes from './useServiceProviderNotes';
import useServiceProvidersFormSubmit from './useServiceProvidersFormSubmit';

const PropertySettingsServiceProvidersTab = () => {
  const { t } = useTranslation();
  const { serviceProviders, isLoading: isServiceProvidersLoading } =
    useServiceProvidersList();
  const { allowPropertyEditV3Beta } = useFeatures();
  const { notes, isFetching: isNotesFetching } = useServiceProviderNotes();
  const { property, isLoading: isPropertyLoading } =
    usePropertySettingsInitData();
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onSubmit = useServiceProvidersFormSubmit({ property });

  const isLoading =
    isServiceProvidersLoading || isNotesFetching || isPropertyLoading;
  const hasServiceProviders = serviceProviders?.length > 0;

  if (allowPropertyEditV3Beta) {
    return <PropertySettingsBetaAccessInfo />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PropertySettingsServiceProvidersTabStyled>
      <PropertyEditPermissionInfo />
      <ServiceProvidersHeader serviceProviders={serviceProviders} />

      <FormWithProvider
        defaultValues={{
          serviceProviders: {
            providers: serviceProviders,
            notes,
          },
        }}
        dirtyChecker
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        onSubmit={onSubmit}
        key={property?.uid}
      >
        <PermissionsAwareFieldset>
          {hasServiceProviders && (
            <ServiceProvidersTable serviceProviders={serviceProviders} />
          )}

          <ServiceProvidersNotes />

          <FormFooter
            saveButtonLabel={t('pageProperty.serviceProviders.saveButton')}
          />
        </PermissionsAwareFieldset>
      </FormWithProvider>
    </PropertySettingsServiceProvidersTabStyled>
  );
};

export default PropertySettingsServiceProvidersTab;
