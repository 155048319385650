import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetPricingPlanResponseTO } from 'models/PricingPlan';
import API from 'services/API';

export const PRICING_PLAN_BASE_QUERY_KEY = 'pricing-plan';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetPricingPlanResponseTO>,
  'queryKey' | 'queryFn'
>;

const useFetchPricingPlan = ({
  enabled,
  ...options
}: ReactQueryOptions = {}) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PRICING_PLAN_BASE_QUERY_KEY],
    queryFn: async () => {
      const response = await API.get<GetPricingPlanResponseTO>(
        '/api/internal/pricing-plan',
      );
      return response.data;
    },
    staleTime: 30000,
    keepPreviousData: true,
    enabled: enabled !== undefined ? enabled : true,
    ...(options ?? {}),
  });
};

export default useFetchPricingPlan;
