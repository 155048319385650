import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import { Col, Row } from 'react-bootstrap';
import {
  CollapseSectionStyled,
  DescriptionStyled,
} from './ServiceProvidersNotes.styles';

const ServiceProvidersNotes = () => {
  const { t } = useTranslation();

  return (
    <CollapseSectionStyled
      defaultExpanded
      label={t('pageProperty.serviceProviders.notes.title')}
      variant="card"
    >
      <Row>
        <Col sm={12}>
          <DescriptionStyled>
            {t('pageProperty.serviceProviders.notes.description')}
          </DescriptionStyled>
        </Col>

        <TextField
          name="serviceProviders.notes"
          label={t('pageProperty.serviceProviders.notes.label')}
          componentClass="textarea"
          colSmInput={8}
          colSmLabel={4}
          rows={5}
        />
      </Row>
    </CollapseSectionStyled>
  );
};

export default ServiceProvidersNotes;
