import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { HouseRule } from 'models/HouseRules';
import { Property } from 'models/Properties';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import API from 'services/API';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { useTranslation } from 'react-i18next';
import { ObjectUidFetchType } from 'models/_base';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { HOUSE_RULES_BASE_QUERY_KEY } from '../HouseRules.utils';
import HouseRulesModal, {
  houseRuleModalId,
  HouseRulesFormSchemaType,
} from './HouseRulesModal';

const BULK_UPDATE_HOUSE_RULES_BASE_MUTATION_KEY = 'bulk-update-house-rules';

interface BulkCreateUpdateHouseRulesPayload {
  houseRule: Pick<HouseRule, 'description' | 'locale'> & {
    uid: HouseRule['uid'];
  };
  objectUidFetchType: 'ALL' | 'SPECIFIC';
  objectUids: Property['uid'][];
}

const useHouseRulesModal = (houseRule?: HouseRule) => {
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutationKey = [BULK_UPDATE_HOUSE_RULES_BASE_MUTATION_KEY];

  const { mutate: bulkCreateHouseRule } = useAppMutation(
    mutationKey,
    (payload: BulkCreateUpdateHouseRulesPayload) => {
      return API.post('/api/internal/house-rules/bulk-save', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([HOUSE_RULES_BASE_QUERY_KEY]);
        closeModal(houseRuleModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const { mutate: bulkUpdateHouseRules } = useAppMutation(
    mutationKey,
    (payload: BulkCreateUpdateHouseRulesPayload) => {
      return API.put('/api/internal/house-rules/bulk-update', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([HOUSE_RULES_BASE_QUERY_KEY]);
        closeModal(houseRuleModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleSubmit = (
    values: HouseRulesFormSchemaType,
    type: 'EDIT' | 'CREATE',
  ) => {
    // const applyToAllProperties =
    //   values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    // const appliesToPropertiesFiltered =
    //   values.appliesToProperties?.filter(isNotSelectAll);

    const payload: BulkCreateUpdateHouseRulesPayload = {
      houseRule: {
        description: values.description,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        uid: houseRule?.uid,
        locale: houseRule?.locale || LanguageCodeUnderscored.EN_US,
      },
      // objectUidFetchType: applyToAllProperties
      //   ? ObjectUidFetchType.ALL
      //   : ObjectUidFetchType.SPECIFIC,
      // objectUids: applyToAllProperties
      //   ? []
      //   : appliesToPropertiesFiltered.map((item) => item.value),
      objectUidFetchType: ObjectUidFetchType.SPECIFIC,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      objectUids: [property.uid],
    };

    if (type === 'CREATE') {
      bulkCreateHouseRule(payload);
    } else {
      bulkUpdateHouseRules(payload);
    }
  };

  const openBulkUpdateModal = () => {
    openModal({
      id: houseRuleModalId,
      customContent: (
        <HouseRulesModal
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          property={property}
          houseRule={houseRule}
          onSubmit={(values) => handleSubmit(values, 'EDIT')}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  const openBulkCreateModal = () => {
    openModal({
      id: houseRuleModalId,
      customContent: (
        <HouseRulesModal
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          property={property}
          onSubmit={(values) => handleSubmit(values, 'CREATE')}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  return { openBulkUpdateModal, openBulkCreateModal };
};

export default useHouseRulesModal;
