import { useContext, useEffect, useState } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { PropertyBusinessType } from 'models/Properties';
import {
  addVisibleProperties,
  removeVisibleProperties,
} from 'store/slices/stackedCalendar';
import ArrowRight from '../../../components/icons/ArrowRight';
import ArrowDown from '../../../components/icons/ArrowDown';
import CalendarContext from '../CalendarContext';
import useStackedCalendarVisibleProperties from '../useStackedCalendarVisibleProperties';
import { PropertyExpandArrowWrapper } from './PropertiesList.styles';

const PropertyListItemExpandIcon = ({ uid }: { uid: string }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { propertiesMap, totalPropertiesNumber } = useContext(CalendarContext);
  const visibleProperties = useStackedCalendarVisibleProperties();
  const dispatch = useAppDispatch();

  const { subUnits, unitTypes, businessType, units } = propertiesMap[uid];
  const subUnitUids = subUnits?.map(
    ({ uid: subUnitUid }) => `${subUnitUid}[SUB_UNIT]`,
  );

  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const isUnitType = businessType === PropertyBusinessType.UNIT_TYPE;

  useEffect(() => {
    if (totalPropertiesNumber > 25 && isUnitType) {
      setExpanded(false);
    }
  }, [totalPropertiesNumber]);

  const toggleExpand = () => {
    const propertiesToChange = [];

    if (isHotel && expanded) {
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const unitsUids = unitTypes?.reduce((acc, ut) => {
        return [...acc, ...(ut.units?.map((u) => `${u.uid}[UNIT]`) || [])];
      }, []);
      // @ts-expect-error TS2461 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertiesToChange.push(...unitsUids);
    }

    if (isHotel) {
      const unitTypeUids = unitTypes?.map((ut) => `${ut.uid}[UNIT_TYPE]`);
      // @ts-expect-error TS2461 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertiesToChange.push(...unitTypeUids);
    } else if (isUnitType) {
      const unitsUids = units?.map((u) => `${u.uid}[UNIT]`);
      propertiesToChange.push(...unitsUids);
    } else {
      propertiesToChange.push(...subUnitUids);
    }

    if (expanded) {
      dispatch(
        removeVisibleProperties({
          properties: propertiesToChange,
        }),
      );
    } else {
      dispatch(
        addVisibleProperties({
          properties: propertiesToChange,
          index: visibleProperties.indexOf(`${uid}[${businessType}]`) + 1,
        }),
      );
    }

    setExpanded((currentExpanded) => !currentExpanded);
  };

  const arrow = expanded ? <ArrowDown /> : <ArrowRight />;

  return (
    <PropertyExpandArrowWrapper onClick={toggleExpand}>
      {arrow}
    </PropertyExpandArrowWrapper>
  );
};

export default PropertyListItemExpandIcon;
