import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import TextField from 'components/forms/textField/TextField';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import { Currency } from 'models/Currency';
import TooltipContentWithLearnMore from 'pages/property/common/tooltip/TooltipContentWithLearnMore';
import {
  CollapseSectionStyled,
  FormSectionColumn,
} from '../../../common/Common.styles';
import PropertyFormFieldLabel from '../../../common/PropertyFormFieldLabel';
import BathroomsField from './BathroomsField';
import BedroomsField from './BedroomsField';
import NumberOfBedsField from './NumberOfBedsField';
import { OpenSetRoomsModalContainer } from './PropertyCapacityDetails.styles';
import useRoomsAndBedTypesModal from './useRoomsAndBedTypesModal';

const PropertyCapacityDetails = ({ currency }: { currency: Currency }) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const { openRoomsAndBedTypesModal } = useRoomsAndBedTypesModal();
  const { t } = useTranslation();

  return (
    <CollapseSectionStyled
      data-testid="property-capacity-details"
      defaultExpanded
      label={t('pageProperty.mainSettings.capacityDetails.sectionTitle')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <BedroomsField />
            <OpenSetRoomsModalContainer xs={8} sm={7} xsOffset={4} smOffset={5}>
              <Button
                bsStyle="link"
                data-testid="open-set-rooms-modal"
                onClick={openRoomsAndBedTypesModal}
              >
                {t(
                  'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypes',
                )}
              </Button>
            </OpenSetRoomsModalContainer>
            <NumberOfBedsField />
            <BathroomsField />
          </FormSectionColumn>
          <FormSectionColumn>
            <TextField
              label={t('pageProperty.mainSettings.capacityDetails.baseGuests')}
              name="capacityDetails.baseGuests"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={
                <TooltipContentWithLearnMore
                  labelTooltipText={t(
                    'pageProperty.mainSettings.capacityDetails.baseGuestsTooltip',
                  )}
                  learnMoreUrl="https://help.hostfully.com/en/articles/8202150-quick-guide-to-your-property-settings"
                />
              }
              min={1}
              type="number"
              required
            />
            <TextField
              label={t('pageProperty.mainSettings.capacityDetails.maxGuests')}
              name="capacityDetails.maxGuests"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.capacityDetails.maxGuestsTooltip',
              )}
              min={1}
              type="number"
              required
            />
            <TextField
              label={
                <PropertyFormFieldLabel name="capacityDetails.extraGuestFee">
                  {t('pageProperty.mainSettings.capacityDetails.extraGuestFee')}
                </PropertyFormFieldLabel>
              }
              help={t(
                'pageProperty.mainSettings.capacityDetails.extraGuestFeeHelp',
              )}
              name="capacityDetails.extraGuestFee"
              colSmInput={7}
              colSmLabel={5}
              leftAddon={getCurrencySymbol(currency)}
              rightTooltip={t(
                'pageProperty.mainSettings.capacityDetails.extraGuestFeeTooltip',
              )}
              min={0}
              type="number"
            />
          </FormSectionColumn>
        </Row>
      </fieldset>
    </CollapseSectionStyled>
  );
};

export default PropertyCapacityDetails;
