import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import PageHeader from 'components/pageHeader/PageHeader';
import PageTitle from './PageTitle';

const AirbnbNotificationsPlaceholder = () => {
  return (
    <div className="container">
      <PageHeader title={<PageTitle />} />

      <LoadingPlaceholder linesCount={4} margin="4px 0" />
      <LoadingPlaceholder linesCount={4} margin="4px 0" />
      <LoadingPlaceholder linesCount={4} margin="4px 0" />
      <LoadingPlaceholder linesCount={4} margin="4px 0" />
      <LoadingPlaceholder linesCount={4} margin="4px 0" />
    </div>
  );
};

export default AirbnbNotificationsPlaceholder;
