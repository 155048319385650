import { useTranslation } from 'react-i18next';
import Loader from '../../../loader/Loader';
import { CreateLeadModalParams, LeadModalParams } from './LeadModal.types';
import leadFormSchema from './LeadModal.schema';
import LeadModalHeader from './LeadModalHeader';
import LeadModalBody from './LeadModalBody';
import LeadModalFooter from './LeadModalFooter';
import { LeadModalForm } from './LeadModal.styles';
import useLeadModalData from './useLeadModalData';
import useLeadModalFormInitialValue from './useLeadModalFormInitialValue';
import useLeadModalFormSubmit from './useLeadModalFormSubmit';
import useLeadForRebooking from './useLeadForRebooking';

const LeadModalFormWrapper = ({ params }: { params: LeadModalParams }) => {
  const createParams = params as CreateLeadModalParams;
  const { leadForRebookingUid } = createParams;
  const {
    isLoading,
    lead,
    leadTags,
    pinCode,
    reviewByHost,
    defaultPropertyUid,
    mainBookingAssigneeUid,
  } = useLeadModalData();
  const { lead: leadForRebooking, isFetching: isFetchingLeadForRebooking } =
    useLeadForRebooking(leadForRebookingUid);
  const prepareFormInitialValue = useLeadModalFormInitialValue();
  const submitHandler = useLeadModalFormSubmit();
  const { t } = useTranslation();

  if (isLoading || isFetchingLeadForRebooking) {
    return <Loader />;
  }

  return (
    <LeadModalForm
      defaultValues={prepareFormInitialValue({
        lead,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        quote: null,
        leadForRebooking,
        leadTags,
        params: createParams,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        pinCode,
        reviewByHost,
        defaultPropertyUid,
        mainBookingAssigneeUid,
      })}
      onSubmit={submitHandler}
      schema={leadFormSchema(t)}
    >
      <LeadModalHeader params={params} />
      <LeadModalBody />
      <LeadModalFooter />
    </LeadModalForm>
  );
};

export default LeadModalFormWrapper;
