import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  removeActiveSidePanel,
  setActiveSidePanel,
} from 'store/slices/sidePanel';

interface UseSidePanelArgs {
  sidePanelId: string;
}

const useSidePanel = ({ sidePanelId }: UseSidePanelArgs) => {
  const activeSidePanels = useAppSelector(
    (state) => state.sidePanel.activeSidePanels,
  );

  const dispatch = useAppDispatch();
  const closeSidePanel = () => dispatch(removeActiveSidePanel({ sidePanelId }));
  const openSidePanel = () => dispatch(setActiveSidePanel({ sidePanelId }));
  const isSidePanelOpen = sidePanelId in activeSidePanels;
  const toggleSidePanel = () =>
    isSidePanelOpen ? closeSidePanel() : openSidePanel();
  const triggerClassName = `${sidePanelId}-trigger`;

  return {
    isSidePanelOpen,
    closeSidePanel,
    openSidePanel,
    toggleSidePanel,
    triggerClassName,
  };
};

export default useSidePanel;
