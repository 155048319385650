import styled, { css } from 'styled-components';
import { Glyphicon } from 'react-bootstrap';
import theme from 'styles/theme';

export const Container = styled.header`
  padding: 0 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #e7e7e7;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;

  a {
    color: ${theme.colors.fontDark} !important;
  }

  .navbar-brand {
    padding: 0;
  }
`;

export const Brand = styled.img`
  height: 50px;
`;

export const HamburgerMenuButton = styled(Glyphicon).attrs({
  glyph: 'menu-hamburger',
})`
  font-size: 20px;
  color: #3f4254;
  cursor: pointer;
`;

export const AccountContainer = styled.div`
  padding: 20px 32px;
  border-bottom: 1px solid #c9c9c9;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profile-avatar {
    list-style: none;
  }

  & > span {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    display: block;
  }
`;

export const CloseButton = styled(Glyphicon).attrs({
  glyph: 'remove',
})`
  font-size: 20px;
  color: #3f4254;
  cursor: pointer;
`;

interface MenuItemProps {
  active?: boolean;
}

export const MenuItem = styled.div.attrs({
  className: 'menu-item',
})<MenuItemProps>`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  height: 50px;

  ${({ active }) =>
    active &&
    css`
      box-shadow: inset 0 -2px 0 0 #d85b35;
    `}

  &:hover {
    background-color: ${theme.colors.bgHover};
  }

  border-bottom: 1px solid #e7e7e7;

  &:first-child {
    border-top: 1px solid #e7e7e7;
  }
`;

export const DropdownTrigger = styled(MenuItem)<{ isOpen: boolean }>`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #3f4254 transparent;
    transition: all 300ms ease-in-out;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      // box-shadow: inset 0 -4px 0 0 #d85b35;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    `}

  &:hover {
    background-color: #fff;
  }
`;

export const DropdownMenu = styled.div<{
  isOpen: boolean;
  itemsAmount: number;
}>`
  // show dropdown menu with slide animation when isOpen is true and hide it when isOpen is false and change the height of the dropdown menu to 0
  overflow: hidden;
  max-height: ${({ isOpen, itemsAmount }) =>
    isOpen ? `${itemsAmount * 50}px` : '0'};
  transition: max-height 300ms ease-in-out;

  .menu-item {
    background-color: #f1f1f1;
    border-bottom: 1px solid #d1d1d1;
  }
`;
