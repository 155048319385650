import useAppQuery from 'hooks/useAppQuery';
import { GetAvailableAmenitiesResponse } from 'models/Amenity';
import { Channel } from 'models/Channels';
import { PropertyBusinessType } from 'models/Properties';
import API from 'services/API';
import { channelToAmenityChannel } from 'utils/amenity/amenityUtils';
import usePropertySettingsInitData from '../usePropertySettingsInitData';

export const AVAILABLE_AMENITIES_QUERY_KEY = 'fetch-all-available-amenities';

const useFetchAvailableAmenities = ({
  activeChannels,
}: {
  activeChannels: Channel[];
}) => {
  const { property } = usePropertySettingsInitData();
  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const activeAmenitiesChannels = activeChannels.map(channelToAmenityChannel);

  const {
    data: availableAmenities,
    error,
    isLoading,
  } = useAppQuery(
    [AVAILABLE_AMENITIES_QUERY_KEY, propertyUid, activeAmenitiesChannels],
    async () => {
      const response = await API.get<GetAvailableAmenitiesResponse>(
        '/api/internal/available-amenities',
        {
          params: {
            [isHotel ? 'hotelUid' : 'propertyUid']: propertyUid,
            channels: activeAmenitiesChannels.join(','),
          },
        },
      );

      const { amenities } = response.data;

      return amenities;
    },
    {
      enabled: !!property && !!activeAmenitiesChannels.length,
    },
  );

  return { availableAmenities, error, isLoading };
};

export default useFetchAvailableAmenities;
