import { format } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import useDirectBookingReviewMutations, {
  CREATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY,
  CreateDirectBookingReviewPayload,
  UPDATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY,
} from 'components/domain/review/useDirectBookingReviewMutations';
import { DirectBookingReview } from 'models/Reviews';
import { PROPERTIES_REVIEWS_BASE_QUERY_KEY } from '../DirectBookingReviews.utils';
import DirectBookingReviewModal, {
  addReviewModalId,
  editReviewModalId,
  DirectBookingReviewFormSchemaType,
} from './DirectBookingReviewModal';

const useOpenDirectBookingReviewModal = () => {
  const { uid: propertyUid } = useParams();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { createDirectBookingReview, updateDirectBookingReview } =
    useDirectBookingReviewMutations();

  const refetchTableData = () => {
    queryClient.invalidateQueries({
      queryKey: [PROPERTIES_REVIEWS_BASE_QUERY_KEY],
    });
  };

  const handleCreateSubmit = (review: DirectBookingReviewFormSchemaType) => {
    const payload: CreateDirectBookingReviewPayload = {
      ...review,
      date: format(review.date, 'yyyy-MM-dd'),
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUid,
    };

    createDirectBookingReview({ directBookingReview: payload })
      .then(() => {
        closeModal(addReviewModalId);
      })
      .catch(() => {
        notifyError(t('pageProperty.reviews.directBooking.createError'));
      })
      .finally(() => {
        refetchTableData();
      });
  };

  const handleUpdateSubmit = (review: DirectBookingReviewFormSchemaType) => {
    const directBookingReview: Partial<DirectBookingReview> = {
      ...review,
      date: format(review.date, 'yyyy-MM-dd'),
    };

    updateDirectBookingReview({ directBookingReview })
      .then(() => {
        closeModal(editReviewModalId);
      })
      .catch(() => {
        notifyError(t('pageProperty.reviews.directBooking.updateError'));
      })
      .finally(() => {
        refetchTableData();
      });
  };

  const openCreateModal = () => {
    openModal({
      id: addReviewModalId,
      customContent: (
        <DirectBookingReviewModal
          mutationKey={[CREATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY]}
          onSubmit={handleCreateSubmit}
        />
      ),
    });
  };

  const openEditModal = (review: DirectBookingReview) => {
    openModal({
      id: editReviewModalId,
      customContent: (
        <DirectBookingReviewModal
          mutationKey={[UPDATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY]}
          onSubmit={handleUpdateSubmit}
          review={review}
        />
      ),
    });
  };

  return { openCreateModal, openEditModal };
};

export default useOpenDirectBookingReviewModal;
