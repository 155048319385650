import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PhotoGridLoaderSkeleton } from './PhotoGrid.styles';

const PhotoGridLoader = () => {
  return (
    <PhotoGridLoaderSkeleton data-testid="photo-grid-loader">
      <LoadingPlaceholder />
      <LoadingPlaceholder />
      <LoadingPlaceholder />
      <LoadingPlaceholder />
    </PhotoGridLoaderSkeleton>
  );
};

export default PhotoGridLoader;
