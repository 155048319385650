import { BaseSyntheticEvent } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import useUpdatePropertyAmenities from 'components/domain/property/amenities/useUpdatePropertyAmenities';
import { PROPERTY_RULES_QUERY_KEY } from 'components/domain/property/rules/useFetchPropertyRules';
import useUpdatePropertyRules from 'components/domain/property/rules/useUpdatePropertyRules';
import { FormContextParts } from 'components/forms/form/Form.types';
import useNotify from 'hooks/useNotify';
import { PropertyAmenity } from 'models/Amenity';
import { GetPropertyRulesResponse } from 'models/Rule';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import usePropertySettingsBulkSaveModal from '../../../bulkSave/usePropertySettingsBulkSaveModal';
import usePropertySettingsInitData from '../../../usePropertySettingsInitData';
import { PROPERTY_AMENITIES_QUERY_KEY } from '../../usePropertyAmenities';
import {
  CategorizedAmenitiesFormValues,
  getFormDefaultValues,
} from './CategorizedAmenities.schema';
import useCategorizedAmenitiesUpdatePayload from './useCategorizedAmenitiesUpdatePayload';

export async function handlePropertyAmenitiesUpdateSuccess({
  notifySuccess,
  propertyUid,
  queryClient,
  reset,
  t,
}: {
  propertyUid: string;
  queryClient: QueryClient;
  reset: (values: CategorizedAmenitiesFormValues) => void;
  notifySuccess: (message: string) => void;
  t: TFunction;
}) {
  const amenitiesQueryKey = [PROPERTY_AMENITIES_QUERY_KEY, propertyUid];
  const rulesQueryKey = [PROPERTY_RULES_QUERY_KEY, 'list', propertyUid];
  await queryClient
    .invalidateQueries({
      predicate: (query) => {
        return (
          query.queryKey.includes(PROPERTY_AMENITIES_QUERY_KEY) ||
          query.queryKey.includes(PROPERTY_RULES_QUERY_KEY)
        );
      },
    })
    .then(() => {
      const amenitiesQueryData =
        queryClient.getQueryData<PropertyAmenity[]>(amenitiesQueryKey);
      const rulesQueryData =
        queryClient.getQueryData<GetPropertyRulesResponse>(rulesQueryKey);

      const updatedFormValues = getFormDefaultValues(
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        amenitiesQueryData,
        rulesQueryData?.propertyRules,
      );

      reset(updatedFormValues);

      notifySuccess(t('pageProperty.amenities.updateSuccess'));
    });
}

const useCategorizedAmenitiesFormSubmit = () => {
  const getUpdatePayload = useCategorizedAmenitiesUpdatePayload();
  const { notifyError, notifySuccess } = useNotify();
  const { property } = usePropertySettingsInitData();
  const { openPropertySettingsBulkSaveModal } =
    usePropertySettingsBulkSaveModal();
  const {
    isLoading: isUpdatingAmenities,
    mutateAsync: updatePropertyAmenities,
  } = useUpdatePropertyAmenities();
  const { isLoading: isUpdatingRules, mutateAsync: updatePropertyRules } =
    useUpdatePropertyRules();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onSubmit = async (
    formValues: CategorizedAmenitiesFormValues,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    { nativeEvent }: BaseSyntheticEvent<SubmitEvent> | undefined,
    {
      formState: { dirtyFields },
      reset,
    }: FormContextParts<CategorizedAmenitiesFormValues>,
  ) => {
    if (!Object.keys(dirtyFields.amenities || {}).length) {
      return;
    }

    const isBulkSave = nativeEvent.submitter.id === 'bulk-save-button';

    if (isBulkSave) {
      openPropertySettingsBulkSaveModal({ dirtyFields, formValues });
      return;
    }

    const { amenities: amenitiesPayload, rules: rulesPayload } =
      getUpdatePayload({ dirtyFields, formValues });

    await Promise.all([
      amenitiesPayload.length
        ? // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          updatePropertyAmenities({ payload: amenitiesPayload, property })
        : Promise.resolve({ data: null }),
      rulesPayload.length
        ? // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          updatePropertyRules({ payload: rulesPayload, property })
        : Promise.resolve({ data: null }),
    ])
      .then(async (responses) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const response of responses) {
          // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          const apiErrorCode = response?.data?.apiErrorCode;
          // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          const apiErrorMessage = response?.data?.apiErrorMessage;

          if (apiErrorCode || apiErrorMessage) {
            notifyError(
              getErrorMessage({
                apiErrorCode,
                apiErrorMessage,
                baseKey: 'pageProperty.apiErrorCodes.',
                t,
              }),
            );

            return;
          }
        }

        await handlePropertyAmenitiesUpdateSuccess({
          notifySuccess,
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          propertyUid: property.uid,
          queryClient,
          reset,
          t,
        });
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };

  return {
    isUpdating: isUpdatingAmenities || isUpdatingRules,
    onSubmit,
  };
};

export default useCategorizedAmenitiesFormSubmit;
