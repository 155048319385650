import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const AirbnbAlterationRequestModalActionsContainer = styled(Col).attrs({
  className: 'lead-modal-footer-actions-container',
  xs: 12,
})`
  justify-content: end;
  display: flex;
`;

export const AirbnbAlterationRequestModalFooterErrorMessage = styled.p.attrs({
  className: 'text-danger',
  'data-testid': 'airbnb-alteration-modal-footer-error-message',
})``;

export const AirbnbAlterationRequestModalFooterMessagesContainer = styled(
  Col,
).attrs({
  md: 6,
})`
  text-align: left;
`;

export const AirbnbAlterationRequestModalFooterSubmitButtonsContainer = styled(
  Col,
).attrs({
  md: 6,
})`
  text-align: right;
`;
