import styled from 'styled-components';
import { Container as SwitchContainer } from 'components/switch/Switch.styles';

export const StatusCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${SwitchContainer} {
    height: 16px;
    width: 32px;
  }

  .toggle-switch.small {
    margin-top: -7px;
  }
`;
