import useProfileAvatar from 'hooks/useProfileAvatar';
import useAppUser from 'hooks/useAppUser';
import { UserType } from 'models/Users';
import { Container } from './ProfileAvatar.styles';

const paths = {
  [UserType.Employee]: '/account',
  [UserType.Guest]: '/guest/account',
  [UserType.ServiceProviderUser]: '/service-provider/account',
  [UserType.Owner]: '/owner/account',
  [UserType.Agent]: '/',
};

interface Props {
  onClick?: () => void;
}

const ProfileAvatar = ({ onClick }: Props) => {
  const { user } = useAppUser();
  const avatar = useProfileAvatar({
    pictureUrl: user?.pictureUrl,
    firstName: user?.firstName,
    lastName: user?.lastName,
  });

  const getHref = () => {
    const type = user?.type;
    if (!type) {
      return '/';
    }

    return paths[type];
  };

  return (
    // @ts-expect-error TS2722 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <Container href={getHref()} onClick={() => onClick()}>
      {avatar}
    </Container>
  );
};

ProfileAvatar.defaultProps = {
  onClick: () => {},
};

export default ProfileAvatar;
