import { Lead } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import { LEAD_STATUS_WITHOUT_PAYMENT_STATUS } from 'utils/lead/leadUtils.constant';
import { format, isBefore, parseISO } from 'date-fns';
import { isLeadImported } from 'utils/lead/leadUtils';
import {
  DangerIcon,
  OkIcon,
  TotalSubtitleWrapper,
  WarningIcon,
} from './OrderViewPriceDetailsInner.styles';

const OrderViewTotalSubtitle = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const isImported = isLeadImported(lead);

  const balance = lead?.order?.balance ?? 0;
  const balanceDueLocalDate = lead?.order?.balanceDueLocalDate ?? null;

  if (
    isImported ||
    !balanceDueLocalDate ||
    LEAD_STATUS_WITHOUT_PAYMENT_STATUS.includes(lead?.status)
  ) {
    return null;
  }

  const balanceDueDate = parseISO(balanceDueLocalDate);

  if (balance === 0) {
    return (
      <TotalSubtitleWrapper>
        <span data-testid="total-subtitle">
          {t('componentOrder.reservationFullyPaid')}
        </span>
        <OkIcon />
      </TotalSubtitleWrapper>
    );
  }

  if (isBefore(balanceDueDate, new Date()) && balance > 0) {
    return (
      <TotalSubtitleWrapper>
        <span data-testid="total-subtitle">
          {t('componentOrder.reservationWithBalanceOverdue', {
            date: format(balanceDueDate, 'MMM dd, yyyy'),
          })}
        </span>
        <DangerIcon />
      </TotalSubtitleWrapper>
    );
  }

  return (
    <TotalSubtitleWrapper>
      <span data-testid="total-subtitle">
        {t('componentOrder.reservationWithBalanceDue', {
          date: format(balanceDueDate, 'MMM dd, yyyy'),
        })}
      </span>
      <WarningIcon />
    </TotalSubtitleWrapper>
  );
};

export default OrderViewTotalSubtitle;
