import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CurrencySelectField from 'components/forms/currencySelectField/CurrencySelectField';
import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import TextField from 'components/forms/textField/TextField';
import { FormSectionColumn } from 'pages/property/common/Common.styles';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import PropertyCleaningFeeField from 'pages/property/common/pricing/PropertyCleaningFeeField';
import PropertyCleaningFeeTaxField from 'pages/property/common/pricing/PropertyCleaningFeeTaxField';
import PropertyNightlyBasePriceField from 'pages/property/common/pricing/PropertyNightlyBasePriceField';
import PropertySecurityDepositField from 'pages/property/common/pricing/PropertySecurityDepositField';
import { PropertySettingsPricingTabFormValues } from '../../PropertySettingsPricingTab.schema';
import PriceSettingsTaxRateField from './PriceSettingsTaxRateField';

const PriceSettingsFormElements = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    priceSettings: { currency },
  } = useWatch<PropertySettingsPricingTabFormValues>();

  const minimumPriceRuleOptions = [
    { label: t('common.disabled'), value: 'false' },
    { label: t('common.enabled'), value: 'true' },
  ];

  return (
    <fieldset>
      <Row>
        <FormSectionColumn>
          <CurrencySelectField
            label={
              <PropertyFormFieldLabel name="priceSettings.currency">
                {t('pageProperty.pricing.priceSettings.currency')}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.currency"
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.pricing.priceSettings.currencyTooltip',
            )}
            required
          />
          <PropertyNightlyBasePriceField
            currency={currency}
            label={
              <PropertyFormFieldLabel name="priceSettings.baseDailyRate">
                {t('pageProperty.pricing.priceSettings.nightlyBasePrice')}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.baseDailyRate"
          />
          <TextField
            label={
              <PropertyFormFieldLabel name="priceSettings.weekEndRatePercentAdjustment">
                {t(
                  'pageProperty.pricing.priceSettings.weekEndRatePercentAdjustment',
                )}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.weekEndRatePercentAdjustment"
            colSmInput={7}
            colSmLabel={5}
            rightAddons={['%']}
            type="number"
            min={0}
            max={100}
          />
          <RadioGroupField
            label={
              <PropertyFormFieldLabel name="priceSettings.useMinimumPriceRule">
                {t('pageProperty.pricing.priceSettings.useMinimumPriceRule')}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.useMinimumPriceRule"
            options={minimumPriceRuleOptions}
            colSmInput={7}
            colSmLabel={5}
            horizontal
            rightTooltip={t(
              'pageProperty.pricing.priceSettings.useMinimumPriceRuleTooltip',
            )}
          />
        </FormSectionColumn>
        <FormSectionColumn>
          <PriceSettingsTaxRateField />

          <PropertySecurityDepositField
            currency={currency}
            label={
              <PropertyFormFieldLabel name="priceSettings.securityDepositAmount">
                {t('pageProperty.pricing.priceSettings.securityDeposit')}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.securityDepositAmount"
          />
          <PropertyCleaningFeeField
            currency={currency}
            label={
              <PropertyFormFieldLabel name="priceSettings.cleaningFee">
                {t('pageProperty.pricing.priceSettings.cleaningFee')}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.cleaningFee"
          />
          <PropertyCleaningFeeTaxField
            label={
              <PropertyFormFieldLabel name="priceSettings.cleaningFeeTax">
                {t('pageProperty.pricing.priceSettings.cleaningFeeTax')}
              </PropertyFormFieldLabel>
            }
            name="priceSettings.cleaningFeeTax"
          />
        </FormSectionColumn>
      </Row>
    </fieldset>
  );
};

export default PriceSettingsFormElements;
