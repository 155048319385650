import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import useAppUser from 'hooks/useAppUser';

export const RESPOND_AIRBNB_GUEST_REVIEW_MUTATION_KEY =
  'respond-airbnb-guest-review';

const useAirbnbGuestReviewMutations = () => {
  const { userUid } = useAppUser();

  const {
    mutateAsync: respondAirbnbGuestReview,
    isLoading: isRespondingAirbnbGuestReview,
  } = useAppMutation(
    [RESPOND_AIRBNB_GUEST_REVIEW_MUTATION_KEY],
    ({ reviewUid, response }: { reviewUid: string; response: string }) => {
      return API.post(
        `/api/internal/guest-review/airbnb/${reviewUid}/respond`,
        {
          author: userUid,
          content: response,
        },
      );
    },
  );

  return {
    respondAirbnbGuestReview,
    isRespondingAirbnbGuestReview,
  };
};

export default useAirbnbGuestReviewMutations;
