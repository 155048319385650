import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetPopularTagsResponse, PopularTag, TagType } from 'models/Tags';
import API from 'services/API';

export const POPULAR_TAGS_BASE_QUERY_KEY = 'popular-tags';

type ReactQueryOptions = Omit<
  UseQueryOptions<PopularTag[]>,
  'queryKey' | 'queryFn'
>;

type UseFetchPopularTagsArgs = {
  count?: number;
  type: TagType;
} & ReactQueryOptions;

const useFetchPopularTags = ({
  count = 10,
  type,
  enabled = true,
  ...options
}: UseFetchPopularTagsArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [POPULAR_TAGS_BASE_QUERY_KEY, 'get-all', type, count],
    queryFn: async () => {
      const response = await API.get<GetPopularTagsResponse>(
        '/api/internal/popular-tags',
        {
          params: {
            count,
            type,
          },
        },
      );

      return response.data.tags;
    },
    staleTime: 30000,
    keepPreviousData: true,
    enabled,
    ...(options ?? {}),
  });
};

export default useFetchPopularTags;
