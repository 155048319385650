import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import { ContainerTitle } from '../AirbnbGuestReviews.styles';

export const OverallAirbnbRatingsContainer = styled(ContainerTitle)``;

export const AverageRatingReviewsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    height: 20px;
    width: 20px;
  }

  span {
    font-weight: 600;
  }

  .separator {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #000;
  }
`;

export const RatingsAndCategoriesContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  align-items: center;

  ${mediaQuery.xs} {
    flex-direction: column;
  }
`;

export const CategoryScoresContainer = styled.div`
  width: 100%;
  flex: 0.6;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 80px);

  grid-gap: 10px;
`;

export const CategoryScore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  & > .title {
    font-weight: 600;
  }

  & > .score {
    font-weight: 600;
    font-size: 24px;
    line-height: 1em;
  }

  // apply right border to first and second columns:
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(5) {
    border-right: 1px solid #e5e5e5;
  }
`;

export const StarRatingsBreakdownContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  width: 100%;
  flex: 0.4;
`;

export const RecentTripIssuesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;

  svg {
    height: 18px;
    width: 18px;
  }
`;
