export interface AirbnbAlterationResponse {
  createdDateTime: string;
  endDate: string;
  nights?: number;
  numberOfAdults?: number;
  numberOfChildren?: number;
  numberOfInfants?: number;
  numberOfPets?: number;
  price?: number;
  property: {
    imageUrl?: string;
    name: string;
    uid: string;
  };
  startDate: string;
  status: AirbnbAlterationStatus;
  type: AirbnbAlterationType;
  uid: string;
  payoutAmount: number;
  totalRentalPrice: number;
}

export interface LeadAirbnbAlteration {
  uid: string;
  startDate: Date;
  endDate: Date;
  type: AirbnbAlterationType;
  price: number;
  nights: number;
}

export interface AirbnbAlterationQuote {
  leadUid: string;
  propertyUid?: string;
  startDate?: string;
  endDate?: string;
  numberOfAdults?: number;
  numberOfChildren?: number;
  numberOfInfants?: number;
  numberOfPets?: number;
  nights?: number;
  price?: number;
}

export enum AirbnbAlterationType {
  HOST = 'HOST',
  GUEST = 'GUEST',
}

export enum AirbnbAlterationStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}
