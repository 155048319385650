import styled from 'styled-components';
import { AirbnbAlterationTitle } from './AirbnbAlterationRequestModal.styles';

export const AirbnbAlterationTitleRequestOrder = styled(AirbnbAlterationTitle)`
  margin-top: 18px;
  text-align: left;
  display: flex;

  span.tooltip-container {
    margin-left: 8px;
  }
`;
