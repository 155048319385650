import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetCancellationPoliciesResponseTO } from 'models/bookingDotCom/CancellationPolicies';
import API from 'services/API';

export const CANCELLATION_POLICIES_BASE_QUERY_KEY =
  'booking-dot-com-cancellation-policies';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetCancellationPoliciesResponseTO>,
  'queryKey' | 'queryFn'
>;

type UseFetchBookingDotComCancellationPoliciesArgs = ReactQueryOptions;

const useFetchBookingDotComCancellationPolicies = ({
  enabled,
  ...options
}: UseFetchBookingDotComCancellationPoliciesArgs = {}) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [CANCELLATION_POLICIES_BASE_QUERY_KEY, 'list'],
    queryFn: async () => {
      const response = await API.get<GetCancellationPoliciesResponseTO>(
        '/api/internal/channels/bookingdotcom/cancellation-policies/',
      );

      return response.data;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchBookingDotComCancellationPolicies;
