import { useContext } from 'react';
import InboxContext from '../InboxContext';
import useAppUser from '../../../hooks/useAppUser';
import { LeadStatus } from '../../../models/Leads';

const filterPropertiesMapper = {
  leadStatuses: (leadStatuses?: LeadStatus[]) => {
    if (leadStatuses?.length) {
      return {
        leadStatus: leadStatuses.join(','),
      };
    }
    return {};
  },
  leadUid: () => {
    return {}; // leadUid should not be sent as filter param
  },
};

const useInboxThreadFilterParams = () => {
  const { agencyUid, isEmployee } = useAppUser();
  const { threadFilter } = useContext(InboxContext);
  const { leadUid, sortBy } = threadFilter;

  if (leadUid) {
    return { leadUid, params: {}, sortBy };
  }

  const params = isEmployee ? { agencyUid } : {};

  Object.entries(threadFilter).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const propertyMapper = filterPropertiesMapper[key];

    if (propertyMapper) {
      Object.assign(params, propertyMapper(value));
    } else if (value) {
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      params[key] = value;
    }
  });

  return { params, sortBy };
};

export default useInboxThreadFilterParams;
