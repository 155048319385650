import { useContext, useMemo } from 'react';
import { format, isWeekend } from 'date-fns';
import { Job } from 'models/Jobs';
import { PropertyBusinessType } from 'models/Properties';
import CalendarContext from '../../CalendarContext';
import { CalendarLead, DayCellData } from '../../Calendar.types';
import useStackedCalendarPropertyDayData from '../../useStackedCalendarPropertyDayData';

function getLoadingCellData(dayDate: Date, isHotel: boolean) {
  return {
    dayDate,
    isGrayedOut: isHotel,
    isLoading: true,
    isWeekend: isWeekend(dayDate),
    leads: null,
    jobs: null,
  };
}

const useDayCellData = ({
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  dayDate,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyUid,
}): { dayData: DayCellData; leads?: CalendarLead[]; jobs?: Job[] } => {
  const { leadsMap, jobsMap, propertiesMap } = useContext(CalendarContext);
  const daysMapKey = format(dayDate, 'yyyy-MM-dd');
  const existingDaysData = useStackedCalendarPropertyDayData({
    daysMapKey,
    propertyUid,
  });

  const isHotel =
    propertiesMap[propertyUid]?.businessType === PropertyBusinessType.HOTEL;
  const dayData = existingDaysData || getLoadingCellData(dayDate, isHotel);

  return useMemo(() => {
    const leadsUid = dayData?.leads;
    const jobsUid = dayData?.jobs;
    const leads = leadsUid?.map((leadUid: string) => leadsMap[leadUid]);
    const jobs = jobsUid?.map((jobUid: string) => jobsMap[jobUid]);

    return {
      leads,
      dayData,
      jobs,
    };
  }, [dayData]);
};

export default useDayCellData;
