import { Col } from 'react-bootstrap';
import { DisplayFieldRowStyled } from './DisplayField.styles';

interface DisplayFieldProps {
  colSmInput?: number;
  colSmLabel?: number;
  colXsInput?: number;
  colXsLabel?: number;
  id: string;
  label: React.ReactNode;
  value: React.ReactNode;
}

const DisplayField = ({
  colSmInput,
  colSmLabel,
  colXsInput,
  colXsLabel,
  id,
  label,
  value,
}: DisplayFieldProps) => {
  return (
    <DisplayFieldRowStyled data-testid={`${id}-field-container`}>
      <Col xs={colXsLabel ?? 3} sm={colSmLabel ?? 3}>
        <label htmlFor={id}>{label}</label>
      </Col>
      <Col xs={colXsInput ?? 9} sm={colSmInput ?? 9}>
        <div id={id}>{value}</div>
      </Col>
    </DisplayFieldRowStyled>
  );
};

DisplayField.defaultProps = {
  colSmInput: undefined,
  colSmLabel: undefined,
  colXsInput: undefined,
  colXsLabel: undefined,
};

export default DisplayField;
