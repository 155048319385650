import { useQueryClient } from '@tanstack/react-query';
import { DESCRIPTIONS_BASE_QUERY_KEY } from 'components/domain/property/useFetchDescriptions';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { Description } from 'models/Descriptions';
import { Property } from 'models/Properties';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';

const useUpdateLanguages = () => {
  const queryClient = useQueryClient();
  const { setErrorConfirmModal } = useAppModal();
  const { t } = useTranslation();

  const { mutateAsync: updateLanguages, isLoading } = useAppMutation(
    ['updateManagedLanguages'],
    async ({
      unselectedLocales,
      isHotel,
      propertyUid,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handleClose,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newSelectedLocales,
    }: {
      unselectedLocales: string[];
      newSelectedLocales: string[];
      propertyUid: Property['uid'];
      isHotel: boolean;
      handleClose: () => void;
    }) => {
      const deletePromises = unselectedLocales.map((locale) => {
        const objectUidParamName = isHotel ? 'hotelUid' : 'propertyUid';

        return API.delete(
          `/v3/descriptions?${objectUidParamName}=${propertyUid}&locale=${locale}`,
        );
      });

      await Promise.all(deletePromises);
    },
    {
      onSuccess: (_, params) => {
        const previousDescriptions = queryClient.getQueryData<Description[]>([
          DESCRIPTIONS_BASE_QUERY_KEY,
          'list',
          params.propertyUid,
        ]);

        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const filteredDescriptions = previousDescriptions.filter(
          (desc) => !params.unselectedLocales.includes(desc.locale),
        );

        const newDescriptions = params.newSelectedLocales.map((locale) => {
          const description: Description = {
            locale,
            access: '',
            houseManual: '',
            interaction: '',
            name: '',
            neighbourhood: '',
            notes: '',
            shortSummary: '',
            space: '',
            summary: '',
            transit: '',
          };

          return description;
        });

        queryClient.setQueryData(
          [DESCRIPTIONS_BASE_QUERY_KEY, 'list', params.propertyUid],
          [...filteredDescriptions, ...newDescriptions],
        );

        params.handleClose();
      },
      onError: (error: any) => {
        setErrorConfirmModal(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  return {
    updateLanguages,
    isLoading,
  };
};

export default useUpdateLanguages;
