import { useContext } from 'react';
import { Button, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LeadModalContext from '../../LeadModalContext';
import useAppModal from '../../../../../../hooks/useAppModal';
import {
  LeadModalActionsTabColumn,
  TypeTd,
} from './LeadModalActionsTab.styles';
import {
  useReportCancellationDueToInvalidCreditCard,
  useReportInvalidCreditCard,
} from './bookingDotComActionHooks';
import { useLeadActionInitialValues } from './useLeadActionInitialValues';
import {
  GUEST_MISCONDUCT_MODAL_ID,
  GuestMisconductModal,
} from './guestmisconduct/GuestMisconductModal';
import {
  GUEST_NO_SHOW_MODAL_ID,
  GuestNoShowModal,
} from './guestNoShow/GuestNoShowModal';
import {
  CHANGE_STAY_DATES_MODAL_ID,
  ChangeStayDatesModal,
} from './changeStayDates/ChangeStayDatesModal';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const ReportButton = ({ enabled, onClick, ...props }) => {
  return (
    <Button
      bsStyle="link"
      style={{ paddingLeft: 0 }}
      disabled={!enabled}
      onClick={onClick}
      {...props}
    >
      Report
    </Button>
  );
};

export const BookingDotComActions = () => {
  const { lead, availableBookingDotComActions, modalId } =
    useContext(LeadModalContext);
  const { openModal, closeModal, openConfirmModal } = useAppModal();

  const { reportInvalidCreditCardMutation } =
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    useReportInvalidCreditCard(lead.uid);

  const { reportCancellationDueToInvalidCardMutation } =
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    useReportCancellationDueToInvalidCreditCard(lead.uid);

  const { t } = useTranslation();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  function reportButton(id, actionEnabled: boolean, onClick) {
    return (
      <ReportButton
        enabled={actionEnabled}
        onClick={onClick}
        data-testid={id}
      />
    );
  }

  const handleGuestNoShow = () => {
    closeModal(modalId);
    openModal({
      id: GUEST_NO_SHOW_MODAL_ID,
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      customContent: <GuestNoShowModal leadUid={lead.uid} />,
      size: 'medium',
    });
  };

  const handleInvalidCreditCard = () => {
    openConfirmModal({
      title: t('componentLead.modal.actionsTab.confirmAreYouSure'),
      body: t(
        'componentLead.modal.actionsTab.confirmInvalidCreditCardDescription',
      ),
      onConfirm: reportInvalidCreditCardMutation,
    });
  };

  const handleCancellationDueToInvalidCard = () => {
    openConfirmModal({
      title: t('componentLead.modal.actionsTab.confirmAreYouSure'),
      body: t(
        'componentLead.modal.actionsTab.confirmCancelDueToInvalidCreditCardDescription',
      ),
      onConfirm: reportCancellationDueToInvalidCardMutation,
    });
  };
  const leadActionInitialValues = useLeadActionInitialValues();
  const handleChangeStayDates = () => {
    closeModal(modalId);
    openModal({
      id: CHANGE_STAY_DATES_MODAL_ID,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      customContent: <ChangeStayDatesModal {...leadActionInitialValues} />,
      size: 'medium',
    });
  };

  const handleGuestMisconduct = () => {
    closeModal(modalId);
    openModal({
      id: GUEST_MISCONDUCT_MODAL_ID,
      customContent: <GuestMisconductModal {...leadActionInitialValues} />,
      size: 'medium',
    });
  };

  return (
    <Row>
      <LeadModalActionsTabColumn md={12}>
        <Table responsive data-testid="channel-actions-table">
          <thead>
            <tr>
              <TypeTd>{t('componentLead.modal.actionsTab.type')}</TypeTd>
              <th>{t('componentLead.modal.actionsTab.title')}</th>
              <th>{t('componentLead.modal.actionsTab.tableDescription')}</th>
              <th>{t('componentLead.modal.actionsTab.action')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('componentLead.modal.actionsTab.typeGuest')}</td>
              <td>{t('componentLead.modal.actionsTab.titleGuestNoShow')}</td>
              <td>
                {t('componentLead.modal.actionsTab.descriptionGuestNoShow')}
              </td>
              <td>
                {reportButton(
                  'report-guest-no-show',
                  availableBookingDotComActions?.cancelDueToGuestNoShow,
                  handleGuestNoShow,
                )}
              </td>
            </tr>
            <tr>
              <td>{t('componentLead.modal.actionsTab.typeGuest')}</td>
              <td>
                {t('componentLead.modal.actionsTab.titleGuestMisconduct')}
              </td>
              <td>
                {t('componentLead.modal.actionsTab.descriptionGuestMisconduct')}
              </td>
              <td>
                {reportButton(
                  'report-guest-misconduct',
                  availableBookingDotComActions?.guestMisconduct,
                  handleGuestMisconduct,
                )}
              </td>
            </tr>
            <tr>
              <td>{t('componentLead.modal.actionsTab.typeGuest')}</td>
              <td>
                {t('componentLead.modal.actionsTab.titleChangeStayDates')}
              </td>
              <td>
                {t('componentLead.modal.actionsTab.descriptionChangeStayDates')}
              </td>
              <td>
                {reportButton(
                  'report-change-stay-dates',
                  availableBookingDotComActions?.changeStayDates,
                  handleChangeStayDates,
                )}
              </td>
            </tr>
            <tr>
              <td>{t('componentLead.modal.actionsTab.creditCard')}</td>
              <td>
                {t('componentLead.modal.actionsTab.titleInvalidCreditCard')}
              </td>
              <td>
                {t(
                  'componentLead.modal.actionsTab.descriptionInvalidCreditCard',
                )}
              </td>
              <td>
                {reportButton(
                  'report-invalid-creditcard',
                  availableBookingDotComActions?.invalidCreditCard,
                  handleInvalidCreditCard,
                )}
              </td>
            </tr>
            <tr>
              <td>{t('componentLead.modal.actionsTab.creditCard')}</td>
              <td>
                {t(
                  'componentLead.modal.actionsTab.titleCancelDueToInvalidCreditCard',
                )}
              </td>
              <td>
                {t(
                  'componentLead.modal.actionsTab.descriptionCancelDueToInvalidCreditCard',
                )}
              </td>
              <td>
                {reportButton(
                  'report-cancel-due-to-incalidcc',
                  availableBookingDotComActions?.cancelDueToInvalidCard,
                  handleCancellationDueToInvalidCard,
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </LeadModalActionsTabColumn>
    </Row>
  );
};
