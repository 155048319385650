import { useTranslation } from 'react-i18next';
import {
  OrderViewPriceDetailsListItemTotalTitle,
  OrderViewPriceDetailsListItemTotalWrapper,
} from './OrderViewPriceDetailsListItemTotal.styles';

const OrderViewPriceDetailsListItemCancel = ({
  label,
  ...props
}: {
  label: string;
}) => {
  const { t } = useTranslation();

  return (
    <OrderViewPriceDetailsListItemTotalWrapper
      {...props}
      className="price-details-list-item-cancel-total-wrapper"
    >
      <OrderViewPriceDetailsListItemTotalTitle>
        <span data-testid="price-details-list-item-total-label">{label}</span>
      </OrderViewPriceDetailsListItemTotalTitle>
      <div
        data-testid="price-details-list-item-total-value"
        className="text-right"
      >
        {t('form.leadStatuses.CANCELLED')}
      </div>
    </OrderViewPriceDetailsListItemTotalWrapper>
  );
};

export default OrderViewPriceDetailsListItemCancel;
