import { BaseResponse } from './_base';

export enum TagType {
  LEAD = 'LEAD',
  PROPERTY = 'PROPERTY',
  HOTEL = 'HOTEL',
  GUEST = 'GUEST',
  USER = 'USER',
  PHOTO = 'PHOTO',
}

export interface Tag {
  name: string;
}

export interface PopularTag {
  tag: string;
}

export interface GetTagsSuggestionsResponseTO extends BaseResponse {
  tags: Tag[];
}

export interface GetEntityTagsResponseTO extends BaseResponse {
  tags: Tag[];
}

export interface GetPopularTagsResponse extends BaseResponse {
  tags: PopularTag[];
}
