import { useQueryClient } from '@tanstack/react-query';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { serviceProviderEditModalId } from '../../PropertySettingsServiceProvidersTab.constants';
import { SERVICE_PROVIDERS_BASE_QUERY_KEY } from '../useServiceProvidersQuery';

export type ServiceProviderBulkEditPayload = {
  updateContext: {
    applyToAll: boolean;
    propertyUids: string[];
  };
  maintenanceNotes: {
    notes: string;
  };
  serviceProviderPropertyStatuses: {
    serviceProviderUid: string;
    enabled: boolean;
  }[];
};

const useServiceProviderMutation = () => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotify();
  const { closeModal } = useAppModal();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();
  const queryClient = useQueryClient();

  const { mutateAsync: updateServiceProvider, isLoading: isUpdating } =
    useAppMutation(
      async (payload: ServiceProviderBulkEditPayload) => {
        const response = await API.post(
          '/api/internal/service-providers/bulk-update',
          payload,
        );
        return response.data;
      },
      {
        onSuccess: () => {
          notifySuccess(t('pageProperty.serviceProviders.editModal.success'));

          queryClient.invalidateQueries({
            queryKey: [SERVICE_PROVIDERS_BASE_QUERY_KEY],
          });
          invalidateFetchAllProperties();
          closeModal(serviceProviderEditModalId);
        },
        onError: (error: any) => {
          notifyError(
            getErrorMessage({
              apiErrorCode: error.response.data?.apiErrorCode,
              apiErrorMessage: error.response.data?.apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
        },
      },
    );

  return { isUpdating, updateServiceProvider };
};

export default useServiceProviderMutation;
