import * as Yup from 'yup';
import { PropertyAmenity } from 'models/Amenity';
import { DeepPartial, FieldNamesMarkedBoolean } from 'react-hook-form';
import { PropertyRule } from 'models/Rule';
import { ALL_CATEGORIZED_AMENITIES_ITEMS } from './CategorizedAmenities.constants';

export const categorizedAmenitiesSchema = () =>
  Yup.object({
    amenities: Yup.object(
      ALL_CATEGORIZED_AMENITIES_ITEMS.reduce(
        (acc, type) => ({
          ...acc,
          [type]: Yup.object({
            description: Yup.string().nullable(),
            value: Yup.boolean().required(),
          }),
        }),
        {},
      ),
    ),
  });

export type CategorizedAmenitiesFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof categorizedAmenitiesSchema>>
>;

export type CategorizedAmenitiesFormDirtyFields = Partial<
  Readonly<FieldNamesMarkedBoolean<CategorizedAmenitiesFormValues>>
>;

export function getFormDefaultValues(
  propertyAmenities: PropertyAmenity[],
  propertyRules: PropertyRule[],
): CategorizedAmenitiesFormValues {
  const baseValues = ALL_CATEGORIZED_AMENITIES_ITEMS.reduce(
    (acc, amenity) => ({
      ...acc,
      [amenity]: {
        description: null,
        value: false,
      },
    }),
    {},
  );

  const allItems =
    propertyAmenities || propertyRules
      ? [...(propertyAmenities ?? []), ...(propertyRules ?? [])]
      : undefined;

  return {
    amenities: allItems?.reduce(
      (acc, { description, ...item }) => ({
        ...acc,
        ['amenity' in item ? item.amenity : item.rule]: {
          description,
          value: true,
        },
      }),
      baseValues,
    ),
  };
}
