/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import useInvalidatePropertyPhotos from 'pages/property/photos/hooks/useInvalidatePropertyPhotos';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import usePhotoMutation from '../modals/usePhotoMutation';
import { PhotoDescriptionContainer } from '../PhotoGrid.styles';
import PropertyEditActionWrapper from '../../../../common/PropertyEditActionWrapper';

interface PhotoDescriptionProps {
  description?: string;
  photoUid: string;
}

const PhotoDescription = ({
  description: initialDescription,
  photoUid,
}: PhotoDescriptionProps) => {
  const [descriptionValue, setDescriptionValue] = useState(initialDescription);
  const [isDirty, setIsDirty] = useState(false);
  const { t } = useTranslation();
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();
  const { updatePhoto } = usePhotoMutation();
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  useOnClickOutside(descriptionRef, async () => {
    if (isDirty) {
      await updatePhoto({
        updatedPhoto: { description: descriptionValue },
        photoUid,
      });
      setIsDirty(false);
      invalidatePropertyPhotos();
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (value.length > 250) {
      return;
    }

    setDescriptionValue(value);
    if (value !== initialDescription) {
      setIsDirty(true);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      await updatePhoto({
        updatedPhoto: { description: descriptionValue },
        photoUid,
      });
      setIsDirty(false);
      invalidatePropertyPhotos();
    }
  };

  return (
    <PhotoDescriptionContainer
      data-testid="photo-description"
      $isDisabled={!propertiesUpdatingAllowed}
    >
      <PropertyEditActionWrapper tooltipId="property-photo-description-edit-tooltip">
        <textarea
          name="description"
          id="description"
          value={descriptionValue || ''}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('pageProperty.photos.clickToAddCaption')}
          ref={descriptionRef}
        />
      </PropertyEditActionWrapper>
    </PhotoDescriptionContainer>
  );
};

PhotoDescription.defaultProps = {
  description: '',
};

export default PhotoDescription;
