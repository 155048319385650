import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { PropertyInternalFee } from 'models/PropertyFees';
import API from 'services/API';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { useTranslation } from 'react-i18next';
import { PROPERTIES_FEES_BASE_QUERY_KEY } from '../PropertyFeesAndTaxes.utils';
import {
  ADD_PROPERTY_FEE_MODAL_ID,
  EDIT_PROPERTY_FEE_MODAL_ID,
} from './PropertyFeeModal';

export const UPDATE_PROPERTIES_FEES_BASE_QUERY_KEY = 'updatePropertyFees';
export const ADD_PROPERTIES_FEES_BASE_QUERY_KEY = 'createPropertyFees';

interface BulkCreatePropertyFeesPayload
  extends Omit<
    PropertyInternalFee,
    | 'metadata'
    | 'uid'
    | 'propertyUid'
    | 'optional'
    | 'longTermStayExemption'
    | 'attestation'
  > {
  allProperties: boolean;
  propertiesUids: string[];
}

interface BulkUpdatePropertyFeesPayload extends BulkCreatePropertyFeesPayload {
  uid: PropertyInternalFee['uid'];
}

const usePropertyFeeModalQueries = () => {
  const queryClient = useQueryClient();
  const { closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutateAsync: createFee, isLoading: isCreatingFee } = useAppMutation(
    [ADD_PROPERTIES_FEES_BASE_QUERY_KEY],
    (payload: BulkCreatePropertyFeesPayload) => {
      return API.post('/api/internal/fees', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PROPERTIES_FEES_BASE_QUERY_KEY]);
        closeModal(ADD_PROPERTY_FEE_MODAL_ID);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const { mutateAsync: updateFee, isLoading: isUpdatingFee } = useAppMutation(
    [UPDATE_PROPERTIES_FEES_BASE_QUERY_KEY],
    (payload: BulkUpdatePropertyFeesPayload) => {
      const { uid, ...values } = payload;

      return API.patch(`/api/internal/fees/${uid}`, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PROPERTIES_FEES_BASE_QUERY_KEY]);
        closeModal(EDIT_PROPERTY_FEE_MODAL_ID);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  return {
    createFee,
    isCreatingFee,
    updateFee,
    isUpdatingFee,
  };
};

export default usePropertyFeeModalQueries;
