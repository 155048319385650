import { AmenityType, PropertyAmenitiesUpdatePayload } from 'models/Amenity';
import { PropertyRulesUpdatePayload, RuleType } from 'models/Rule';
import {
  CategorizedAmenitiesFormDirtyFields,
  CategorizedAmenitiesFormValues,
} from './CategorizedAmenities.schema';

type CategorizedPayload = {
  amenities: PropertyAmenitiesUpdatePayload;
  rules: PropertyRulesUpdatePayload;
};

const useCategorizedAmenitiesUpdatePayload = () => {
  return ({
    dirtyFields,
    formValues: { amenities },
  }: {
    dirtyFields: CategorizedAmenitiesFormDirtyFields;
    formValues: CategorizedAmenitiesFormValues;
  }): CategorizedPayload => {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return Object.keys(dirtyFields.amenities).reduce(
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (result, type) => {
        const common = {
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          description: amenities[type].description ?? null,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          state: amenities[type].value ? 'ON' : 'OFF',
        };

        if (type in AmenityType) {
          return {
            ...result,
            amenities: [...result.amenities, { ...common, amenityType: type }],
          };
        }

        if (type in RuleType) {
          return {
            ...result,
            rules: [...result.rules, { ...common, rule: type }],
          };
        }

        return result;
      },
      { rules: [], amenities: [] },
    );
  };
};

export default useCategorizedAmenitiesUpdatePayload;
