import useAppUser from 'hooks/useAppUser';
import { useTranslation } from 'react-i18next';
import { Container } from './Footer.styles';

const OwnerFooter = () => {
  const { agency } = useAppUser();
  const { t } = useTranslation();

  const renderInformation = (information: string) => {
    if (!information) return null;
    return ` | ${information}`;
  };

  return (
    <Container>
      <div>
        {/*
         // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        {t('common.layout.managedBy')} <strong>{agency.name}</strong>
        {/*
         // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        {renderInformation(agency.phone)}
        {/*
         // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        {renderInformation(agency.email)}
      </div>
    </Container>
  );
};

export default OwnerFooter;
