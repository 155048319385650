import { useContext } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import {
  enableQuery,
  getPercentageKeyMetrics,
  getTimezoneDateString,
  prepareAllPropertyUids,
} from 'utils/analytics/analyticsUtils';
import { differenceInCalendarDays, subDays } from 'date-fns';
import useAppUser from '../../hooks/useAppUser';
import AnalyticsContext from './AnalyticsContext';
import {
  AnalyticsKeyMetric,
  AnalyticsKeyMetricsPercentage,
  AnalyticsKeyMetricsResponse,
} from './types/AnalyticsKeyMetrics.types';
import useKeyMetricsData from './useKeyMetricsData';

const usePercentageKeyMetricsData = () => {
  const { filter, allProperties } = useContext(AnalyticsContext);
  const { agencyUid, agency } = useAppUser();
  const { keyMetrics, isKeyMetricsLoading, keyMetricsError } =
    useKeyMetricsData();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const getPreviousRange = (range) => {
    const { startDate, endDate } = range;
    const diff = differenceInCalendarDays(endDate, startDate);
    return {
      startDate: subDays(startDate.getTime(), diff),
      endDate: subDays(endDate.getTime(), diff),
    };
  };

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const {
    data: percentageKeyMetrics,
    isLoading: isPercentageKeyMetricsLoading,
  }: {
    data: AnalyticsKeyMetricsPercentage[];
    isLoading: boolean;
  } = useAppQuery(
    [
      'getPercentageKeyMetrics',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.channel,
      filter?.range,
      agencyUid,
    ],
    async () => {
      const { dateType, currency, propertyUid, channel, range } = filter;
      const { startDate, endDate } = getPreviousRange(range);
      const response = await API.post<AnalyticsKeyMetricsResponse>(
        `/api/internal/analytics/key-metrics`,
        {
          agencyUid,
          dateType,
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          from: getTimezoneDateString(startDate, agency?.timeZone),
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          to: getTimezoneDateString(endDate, agency?.timeZone),
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metrics: [
            AnalyticsKeyMetric.RENTAL_REVENUE,
            AnalyticsKeyMetric.NIGHTS_BOOKED,
            AnalyticsKeyMetric.OCCUPANCY_RATE,
            AnalyticsKeyMetric.AVERAGE_DAILY_RATE,
            AnalyticsKeyMetric.REV_PAR,
          ],
        },
      );

      return response.data.metricsValues.map((metricsValue) => {
        return {
          ...metricsValue,
          percentage: getPercentageKeyMetrics({
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            currentKeyMetrics: keyMetrics.find(
              (metric) => metric.metric === metricsValue.metric,
            ),
            previousKeyMetrics: metricsValue,
          }),
        };
      });
    },
    {
      enabled:
        enableQuery({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          agencyUid,
          filter,
          allProperties,
        }) &&
        !isKeyMetricsLoading &&
        !keyMetricsError,
    },
  );

  return {
    percentageKeyMetrics,
    isPercentageKeyMetricsLoading,
  };
};

export default usePercentageKeyMetricsData;
