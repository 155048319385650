import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import useAnalyticsData from '../useAnalyticsData';
import { getBarChartHeight } from '../Analytics.utils';
import AnalyticsContext from '../AnalyticsContext';
import { ChartFilter, ChartType } from './Charts.types';
import CustomLineChart from './types/lineCharts/CustomLineChart';
import CustomBarChart from './types/barCharts/CustomBarChart';

const ChartsWrapper = () => {
  const {
    isFinancialPerformanceLoading,
    financialPerformanceData,
    financialPerformanceError,
    isInitialFinancialPerformanceLoading,
    isTeamLoading,
    teamData,
    teamError,
    isInitialTeamLoading,
    isPropertyLoading,
    propertyError,
    propertyData,
    isInitialPropertyLoading,
  } = useAnalyticsData();
  const { metric, type } = useWatch();
  const { filter } = useContext(AnalyticsContext);

  if (type === ChartType.FINANCIAL_PERFORMANCE) {
    return (
      <CustomLineChart
        xAxisKey="from"
        dataKey="value"
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        data={financialPerformanceData}
        error={financialPerformanceError}
        isLoading={isFinancialPerformanceLoading}
        chartFilter={
          {
            metric,
            type,
          } as ChartFilter
        }
        isInitialLoading={isInitialFinancialPerformanceLoading}
        timeSpan={filter?.timeSpan}
      />
    );
  }

  if (type === ChartType.PROPERTY_PERFORMANCE) {
    return (
      <CustomBarChart
        fillColor="rgba(49, 180, 141, 0.7)"
        height={getBarChartHeight(propertyData?.length)}
        isLoading={isPropertyLoading}
        chartFilter={
          {
            metric,
            type,
          } as ChartFilter
        }
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        data={propertyData}
        error={propertyError}
        xAxisKey="value"
        yAxisKey="propertyName"
        isInitialLoading={isInitialPropertyLoading}
      />
    );
  }

  if (type === ChartType.TEAM_PERFORMANCE) {
    return (
      <CustomBarChart
        fillColor="#8A5BDA"
        height={getBarChartHeight(teamData?.length)}
        isLoading={isTeamLoading}
        chartFilter={
          {
            metric,
            type,
          } as ChartFilter
        }
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        data={teamData}
        error={teamError}
        xAxisKey="value"
        yAxisKey="teamMember"
        isInitialLoading={isInitialTeamLoading}
      />
    );
  }

  return null;
};

export default ChartsWrapper;
