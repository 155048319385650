import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipContentWithLearnMoreWrapper } from './TooltipContentWithLearnMore.styles';

interface TooltipContentWithLearnMoreProps {
  labelTooltipText: ReactNode;
  learnMoreUrl: string;
}

const TooltipContentWithLearnMore = ({
  labelTooltipText,
  learnMoreUrl,
}: TooltipContentWithLearnMoreProps) => {
  const { t } = useTranslation();

  return (
    <TooltipContentWithLearnMoreWrapper>
      {labelTooltipText}
      <br />
      <a href={learnMoreUrl} target="_blank" rel="noreferrer">
        {t('pageProperty.common.learnMore')}
      </a>
    </TooltipContentWithLearnMoreWrapper>
  );
};

export default TooltipContentWithLearnMore;
