import useFetchAvailableRules from 'components/domain/property/rules/useFetchAvailableRules';
import useFetchPropertyRules from 'components/domain/property/rules/useFetchPropertyRules';
import { Channel } from 'models/Channels';
import { PropertyBusinessType } from 'models/Properties';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';

const useRules = ({ activeChannels }: { activeChannels: Channel[] }) => {
  const { property } = usePropertySettingsInitData();
  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;

  const { data: propertyRules, isLoading: isLoadingPropertyRules } =
    useFetchPropertyRules({
      isHotel,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUid,
    });
  const { data: availableRules, isLoading: isLoadingAvailableRules } =
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    useFetchAvailableRules({ activeChannels, isHotel, propertyUid });

  return {
    availableRules: availableRules?.rules ?? [],
    propertyRules: propertyRules?.propertyRules ?? [],
    isLoadingRules: isLoadingAvailableRules || isLoadingPropertyRules,
  };
};

export default useRules;
