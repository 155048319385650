import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider } from 'components/forms/form/Form';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { REQUIRE_OPEN_TEXT_FIELD_CHOICES } from 'models/AirbnbFlows';
import { PropertyByUid } from 'models/Properties';
import useAirbnbDeactivationOptions from '../airbnb/useAirbnbDeactivationOptions';
import DeactivationModalBody from './DeactivationModalBody';

interface DeactivationModalProps {
  isUpdating: boolean;
  modalId: string;
  name: string;
  propertyUid: string;
  onSubmit: (property: PropertyByUid) => Promise<void>;
}

const deactivationModalSchema = (isChoiceRequired: boolean) =>
  Yup.object({
    choice: isChoiceRequired
      ? Yup.string().required()
      : Yup.string().optional().nullable(),
    deactivationReason: Yup.string().when(['choice'], {
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      is: (choice) => REQUIRE_OPEN_TEXT_FIELD_CHOICES.includes(choice),
      then: (schema) => schema.required(),
    }),
  });

const DeactivationModal = ({
  isUpdating,
  name,
  propertyUid,
  modalId,
  onSubmit,
}: DeactivationModalProps) => {
  const { t } = useTranslation();
  const { isActiveOnAirbnb, isLoadingAirbnbOptions, choices, property } =
    useAirbnbDeactivationOptions({ propertyUid });
  const isDisabled = isUpdating;

  if (isLoadingAirbnbOptions) {
    return <LoadingPlaceholder />;
  }

  return (
    <div data-testid="deactivation-modal">
      <Modal.Header data-testid="modal-header" closeButton>
        <Modal.Title>
          {t('pageProperties.actions.common.areYouSure')}
        </Modal.Title>
      </Modal.Header>

      <FormProvider
        defaultValues={{}}
        resolver={deactivationModalSchema(isActiveOnAirbnb)}
      >
        <fieldset disabled={isDisabled}>
          <DeactivationModalBody
            choices={choices}
            isActiveOnAirbnb={isActiveOnAirbnb}
            isDisabled={isDisabled}
            modalId={modalId}
            name={name}
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            property={property}
            onSubmit={onSubmit}
          />
        </fieldset>
      </FormProvider>
    </div>
  );
};

export default DeactivationModal;
