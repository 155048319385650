import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { LeadV3Source } from 'models/LeadsV3';
import { LeadSource, LeadStatus } from 'models/Leads';

const airbnbAlterationRequestFormSchema = (t: TFunction) => ({
  adultCount: Yup.number(),
  childrenCount: Yup.number(),
  infantCount: Yup.number(),
  petCount: Yup.number(),
  checkInDate: Yup.date()
    .required()
    .typeError(t('form.validation.invalidDate')),
  checkOutDate: Yup.date()
    .required()
    .typeError(t('form.validation.invalidDate'))
    .min(
      Yup.ref('checkInDate'),
      t('componentLead.modal.form.validation.checkOutDateInvalid'),
    ),
  propertyUid: Yup.string(),
  source: Yup.mixed<LeadV3Source | LeadSource>().oneOf([
    ...Object.values(LeadV3Source),
    ...Object.values(LeadSource),
  ]),
  status: Yup.mixed<LeadStatus>().oneOf(Object.values(LeadStatus)),
  uid: Yup.string(),
  airbnbAlteration: Yup.object().shape({
    startDate: Yup.date()
      .required()
      .typeError(t('form.validation.invalidDate')),
    endDate: Yup.date().required().typeError(t('form.validation.invalidDate')),
    numberOfAdults: Yup.number(),
    numberOfChildren: Yup.number(),
    numberOfInfants: Yup.number(),
    numberOfPets: Yup.number(),
    price: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      )
      .notRequired(),
  }),
});

export default airbnbAlterationRequestFormSchema;
