import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import { isLeadAMainBooking, isLeadASubBooking } from 'utils/lead/leadUtils';
import GroupBookingIcon from 'components/icons/GroupBookingIcon.svg?react';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { LeadMultiReservationLabelWrapper } from './LeadMultiReservationLabel.styles';

const LeadMultiReservationLabel = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();

  const isMainBooking = isLeadAMainBooking(lead);
  const isSubBooking = isLeadASubBooking(lead);
  const { isXS } = useScreenBreakpoints();

  if (isMainBooking || isSubBooking) {
    return (
      <LeadMultiReservationLabelWrapper
        data-testid="group-booking-label"
        outline
      >
        <GroupBookingIcon />
        {!isXS && t('pageOrder.groupBooking')}
      </LeadMultiReservationLabelWrapper>
    );
  }

  return null;
};

export default LeadMultiReservationLabel;
