import useAppQuery from 'hooks/useAppQuery';
import { Channel } from 'models/Channels';
import API from 'services/API';
import { UseQueryOptions } from '@tanstack/react-query';
import { GetAvailableRulesResponse } from 'models/Rule';
import { channelToAmenityChannel } from 'utils/amenity/amenityUtils';

export const AVAILABLE_RULES_QUERY_KEY = 'available-rules';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetAvailableRulesResponse>,
  'queryKey' | 'queryFn'
>;

type UseFetchAvailableRulesArgs = {
  activeChannels: Channel[];
  isHotel: boolean;
  propertyUid: string;
} & ReactQueryOptions;

const useFetchAvailableRules = ({
  activeChannels,
  isHotel,
  propertyUid,
  enabled = true,
  ...options
}: UseFetchAvailableRulesArgs) => {
  const activeAmenitiesChannels = activeChannels.map(channelToAmenityChannel);

  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [
      AVAILABLE_RULES_QUERY_KEY,
      'list',
      propertyUid,
      activeAmenitiesChannels,
    ],
    queryFn: async () => {
      const response = await API.get<GetAvailableRulesResponse>(
        '/api/internal/property-rules/available-property-rules',
        {
          params: {
            [isHotel ? 'hotelUid' : 'propertyUid']: propertyUid,
            channels: activeAmenitiesChannels.join(','),
          },
        },
      );

      return response.data;
    },
    enabled: enabled && !!propertyUid,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchAvailableRules;
