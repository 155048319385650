import { useContext } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Loader } from 'react-bootstrap-typeahead';
import {
  canAddNewLineItem,
  canForceFullPayment,
  isLeadImported,
  isLeadEditable,
  isLeadAMainBooking,
  cancelledStatus,
} from 'utils/lead/leadUtils';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { Trans, useTranslation } from 'react-i18next';
import { Lead, LeadSource } from 'models/Leads';
import { useFormContext, useWatch } from 'react-hook-form';
import { LeadQuoteFeeOverride, FeeAmountType } from 'models/Quote';
import OrderViewPriceDetailsInner from 'pages/orderView/priceDetails/OrderViewPriceDetailsInner';
import GroupOrderViewPriceDetailsInner from 'pages/orderView/priceDetails/GroupOrderViewPriceDetailsInner';
import { prepareOrderItems } from 'utils/quote/quoteUtils';
import LeadModalContext from '../../../LeadModalContext';
import useWatchQuoteData from '../../../useWatchQuoteData';
import { LeadModalDetailsOrderWrapper } from './LeadModalDetailsOrder.styles';
import OrderPropertyFees from './OrderPropertyFees';

const LeadModalDetailsOrder = () => {
  const { lead, quoteAdjustments, groupBookingLeads, setCurrentLeadUid } =
    useContext(LeadModalContext);
  const { t } = useTranslation();
  const { isNewQuoteLoading, groupBooking } = useWatchQuoteData();
  const { setValue, getValues } = useFormContext();

  const {
    uid: leadUid,
    quote,
    checkInDate,
    checkOutDate,
    adultCount,
    childrenCount,
    propertyUid,
  } = useWatch();

  if (!quote && !groupBooking) {
    return <Loader />;
  }

  const updateMode = !!leadUid;

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const isEditable = isLeadEditable(lead);
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const isImported = isLeadImported(lead);
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const isMainBooking = isLeadAMainBooking(lead);

  const addNewLineItem = () => {
    const otherFeesKey = 'quote.fees.otherFees';
    const otherFees = getValues(otherFeesKey);

    // @ts-expect-error TS2352 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    otherFees.push({
      name: `New item ${
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        otherFees.filter(({ enabled, removed }) => enabled && !removed).length
      }`,
      amountType: FeeAmountType.AMOUNT,
      netPrice: 0,
      taxationRate: 0,
      enabled: true,
      removed: false,
      amount: 0,
      feeId: null,
      feeUid: null,
    } as LeadQuoteFeeOverride);

    setValue(otherFeesKey, otherFees, {
      shouldDirty: true,
    });
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const isSubOrderCancelled = (subQuote) => {
    const status = groupBookingLeads?.subLeads.find(
      (l) => l.uid === subQuote.leadUid,
    )?.status;

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return cancelledStatus.includes(status);
  };
  return (
    <LeadModalDetailsOrderWrapper data-testid="lead-order">
      <div>
        {/*
         // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        {updateMode && canForceFullPayment(lead) && (
          <Col xs={7} data-testid="force-payment">
            <CheckboxField name="quote.force100PercentPaymentAtReservation">
              {t('componentOrder.forcePaymentAtReservation')}
            </CheckboxField>
          </Col>
        )}

        <Col
          xsOffset={!updateMode || (lead && !canForceFullPayment(lead)) ? 7 : 0}
          xs={5}
        >
          {/*
           // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {isEditable && canAddNewLineItem(lead) && (
            <div className="text-right">
              <Button
                onClick={addNewLineItem}
                data-testid="add-new-item-button"
                className="btn btn-link"
              >
                <span
                  style={{ top: 0, fontSize: 'x-small' }}
                  className="glyphicon glyphicon-plus"
                />{' '}
                {t('componentOrder.addLineItem')}
              </Button>
            </div>
          )}
        </Col>
      </div>

      <br />

      <Col xs={12}>
        {isMainBooking && (
          <GroupOrderViewPriceDetailsInner
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            order={prepareOrderItems(groupBooking?.aggregatedQuote, lead)}
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            subOrders={groupBooking?.subQuotes.map((q) => ({
              // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              ...prepareOrderItems(q, lead),
              propertyName:
                groupBookingLeads?.subLeads.find((l) => l.uid === q.leadUid)
                  ?.unit?.name ||
                groupBookingLeads?.subLeads.find((l) => l.uid === q.leadUid)
                  ?.property?.name,
              leadUid: q.leadUid,
              cancelled: isSubOrderCancelled(q),
            }))}
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            lead={lead}
            showAmountPaidRow={false}
            showBalanceRow={false}
            showTitle={false}
            showLinkToSubBooking
            onClickLinkToSubBooking={(uid) => {
              setCurrentLeadUid(uid);
            }}
          />
        )}

        {!isMainBooking && (
          <OrderViewPriceDetailsInner
            isLoading={isNewQuoteLoading}
            showTitle={false}
            showAmountPaidRow={false}
            showBalanceRow={false}
            handleOrderAdjustment={false}
            lead={
              lead
                ? {
                    ...lead,
                    checkInDate: checkInDate || lead?.checkInDate,
                    checkOutDate: checkOutDate || lead?.checkOutDate,
                  }
                : ({
                    property: { uid: propertyUid },
                    checkInDate,
                    checkOutDate,
                    adultCount,
                    childrenCount,
                  } as Lead)
            }
            allowEdit={isEditable}
            order={quote}
          />
        )}

        {!isImported &&
          !isMainBooking &&
          quote &&
          (!updateMode ||
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            lead.source === LeadSource.ORBIRENTAL_FORM ||
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            lead.source === LeadSource.ORBIRENTAL_WIDGET ||
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            lead.source === LeadSource.ORBIRENTAL_API) && (
            <OrderPropertyFees isEditable={isEditable} order={quote} />
          )}

        {quoteAdjustments?.find((a) => !a.isPaid) && (
          <div
            className="alert alert-warning"
            data-testid="order-adjustments-warning"
            role="alert"
          >
            <Trans
              i18nKey="componentLead.modal.yourOrderHasChanged"
              components={{
                strongWrapper: <strong />,
              }}
            />
          </div>
        )}
      </Col>
    </LeadModalDetailsOrderWrapper>
  );
};

export default LeadModalDetailsOrder;
