import useAppModal from 'hooks/useAppModal';
import { AvailableAmenity } from 'models/Amenity';
import { AvailableRule } from 'models/Rule';
import usePropertyAmenities from 'pages/property/amenities/usePropertyAmenities';
import { useFormContext } from 'react-hook-form';
import AmenityDescriptionModal, {
  amenityDescriptionModalId,
} from './AmenityDescriptionModal';

const useEditAmenityDescriptionModal = (
  amenity: AvailableAmenity | AvailableRule,
) => {
  const { setValue, getValues } = useFormContext();
  const { propertyAmenities } = usePropertyAmenities();
  const { openModal, closeModal } = useAppModal();
  const type = 'amenityType' in amenity ? amenity.amenityType : amenity.rule;

  const amenityDescriptionName = `amenities.${type}.description`;

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const updateDescription = ({ description }) => {
    setValue(amenityDescriptionName, description, { shouldDirty: true });
    closeModal(amenityDescriptionModalId);
  };

  const openEditDescriptionModal = () => {
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const defaultAmenity = propertyAmenities[type];
    const defaultDescription = defaultAmenity?.description;

    const updatedDescription = getValues(amenityDescriptionName);

    openModal({
      id: amenityDescriptionModalId,
      customContent: (
        <AmenityDescriptionModal
          amenity={amenity}
          updateDescription={updateDescription}
          defaultDescription={updatedDescription || defaultDescription}
        />
      ),
    });
  };

  return {
    openEditDescriptionModal,
  };
};

export default useEditAmenityDescriptionModal;
