import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { resetAll } from './store.constants';

import auth from './slices/auth';
import user from './slices/user';
import userPermissions from './slices/userPermissions';
import lead from './slices/lead';
import inbox from './slices/inbox';
import login from './slices/login';
import modal from './slices/modal';
import sidePanel from './slices/sidePanel';
import config from './slices/config';
import legacyPage from './slices/legacyPage';
import stackedCalendar from './slices/stackedCalendar';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  auth,
  config,
  inbox,
  lead,
  legacyPage,
  login,
  modal,
  sidePanel,
  stackedCalendar,
  user,
  userPermissions,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware);
  },
});

/**
 * Resets all store slices to its initial state (if the slice implemented an extra reducer to reset).
 */
export const resetAllStoreSlices = () => {
  store.dispatch(resetAll());
};

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
