import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import FormFooter from '../../common/FormFooter';
import PropertyCapacityDetails from '../../mainSettings/sections/capacityDetails/PropertyCapacityDetails';
import PropertyAddress from '../../mainSettings/sections/propertyAddress/PropertyAddress';
import PropertyDetails from '../../mainSettings/sections/propertyDetails/PropertyDetails';
import AddSinglePropertyPricingFeesTaxesSection from './AddSinglePropertyPricingFeesTaxesSection';
import { AddSinglePropertyFormValues } from './AddSingleProperty.schema';

const AddSinglePropertyFormElements = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyPricingFeesTaxes: { currency },
  } = useWatch<AddSinglePropertyFormValues>();

  return (
    <>
      <PropertyDetails />
      <PropertyAddress />
      <PropertyCapacityDetails currency={currency} />
      <AddSinglePropertyPricingFeesTaxesSection />
      <FormFooter saveButtonLabel={t('pageProperty.add.single.saveProperty')} />
    </>
  );
};

export default AddSinglePropertyFormElements;
