import { format } from 'date-fns';
import useFormatCurrency from 'hooks/useFormatCurrency';
import useFormatNumber from 'hooks/useFormatNumber';
import { Property } from 'models/Properties';
import { getPropertiesFilterConditions } from 'pages/analytics/Analytics.utils';
import { pieChartsContants } from 'pages/analytics/charts/Chart.constants';
import {
  ChartFilter,
  ChartType,
  FinancialPerformanceMetric,
  PieChartMetric,
  PropertyPerformanceMetric,
  TeamPerformanceMetric,
} from 'pages/analytics/charts/Charts.types';
import {
  AnalyticsBasicProperty,
  ValueType,
} from 'pages/analytics/types/Analytics.types';
import { AnalyticsChannelMetricValue } from 'pages/analytics/types/AnalyticsChannel.types';
import { AnalyticsKeyMetricsValue } from 'pages/analytics/types/AnalyticsKeyMetrics.types';
import {
  AnalyticsPropertyMetricValue,
  MetricValue,
} from 'pages/analytics/types/AnalyticsProperty.types';
import { getDateFormatBasedOnTimeSpan, TimeSpan } from 'utils/dateTimeUtils';
import {
  getPropertyByUid,
  getPropertyCurrency,
} from 'utils/property/propertyUtils';
import { trimAndLimitCharactersNumber } from 'utils/stringUtils';
import { toZoneDateTimeBasedOnTimezone } from 'utils/timezones';

export function getCurrencyOptions(allProperties: AnalyticsBasicProperty[]) {
  return allProperties.reduce((acc, prop) => {
    if (
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      !acc.find((currency) => currency.value === prop.pricing.currency) &&
      prop.pricing.currency
    ) {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      acc.push({
        label: prop.pricing.currency,
        value: prop.pricing.currency,
      });
    }
    return acc;
  }, []);
}

export function prepareAllPropertyUids({
  allProperties,
  currency,
  propertyUid,
}: {
  allProperties: AnalyticsBasicProperty[];
  currency: string;
  propertyUid?: string;
}) {
  return !propertyUid
    ? allProperties
        .filter((property) => property.pricing.currency === currency)
        // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        .reduce((acc, property) => {
          if (property?.subUnits?.length > 0) {
            return [
              ...acc,
              ...property.subUnits
                .map((subUnit) => subUnit.uid)
                .concat(property.uid),
            ];
          }

          if (property?.unitTypes?.length > 0) {
            return [
              ...acc,
              ...property.unitTypes.map((unitType) => unitType.uid),
            ];
          }

          return [...acc, property.uid];
        }, [])
    : getPropertiesFilterConditions(propertyUid, allProperties);
}

export function getTimezoneDateString(date: Date, timeZone: string): string {
  return toZoneDateTimeBasedOnTimezone(
    `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}`,
    timeZone,
  ).toString();
}

export function reducePropertyMetricValues({
  metricValues,
  nameKey,
  exceedString,
  averageExceedValue, // If true, the last item will be the average of the rest. if not, the last item will be the sum of the rest
}: {
  metricValues: MetricValue[];
  nameKey: string;
  exceedString: string;
  averageExceedValue: boolean;
}) {
  const lastItemIndex = pieChartsContants.MAXIMUM_PIE_CHART_ITEMS - 1;

  const firstMetricValues = metricValues.slice(0, lastItemIndex);
  const othersMetricValues = metricValues.slice(
    lastItemIndex,
    metricValues.length,
  );

  const accumulator = firstMetricValues.slice() as any;

  if (othersMetricValues.length > 0) {
    if (averageExceedValue) {
      const average =
        othersMetricValues.reduce(
          (acc, metricValue) => acc + metricValue.value,
          0,
        ) / othersMetricValues.length;

      accumulator.push({
        ...othersMetricValues[0],
        [nameKey]: exceedString,
        value: average,
      });
    } else {
      accumulator.push({
        ...othersMetricValues[0],
        [nameKey]: exceedString,
        value: othersMetricValues.reduce(
          (acc, metricValue) => acc + metricValue.value,
          0,
        ),
      });
    }
  }

  return accumulator;
}

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
export function sortingMetricsValue(a, b) {
  // sort by value
  if (a.value > b.value) {
    return -1;
  }
  if (a.value < b.value) {
    return 1;
  }

  return 0;
}

/*
1 - Sort by value
2 - Filter out values that are 0
3 - Add propertyName field
4 - Reduce to 5 items
*/
export function prepareChannelPieChartsData({
  metricValues,
  exceedString,
  nameKey,
}: {
  metricValues: AnalyticsChannelMetricValue[];
  exceedString: string;
  nameKey: 'channel';
}) {
  const preparedMetricValues = metricValues
    .filter((metricValue) => metricValue.value > 0)
    .sort(sortingMetricsValue);

  return reducePropertyMetricValues({
    metricValues: preparedMetricValues,
    nameKey,
    exceedString,
    averageExceedValue: false,
  });
}

/*
1 - Sort by value
2 - Filter out values that are 0
3 - Add propertyName field
4 - Reduce to 5 items
*/
export function preparePropertyPieChartsData({
  metricValues,
  allProperties,
  exceedString,
  nameKey,
}: {
  metricValues: AnalyticsPropertyMetricValue[];
  allProperties: AnalyticsBasicProperty[];
  exceedString: string;
  nameKey: 'propertyName';
}) {
  const preparedMetricValues = metricValues
    .filter((metricValue) => metricValue.value > 0)
    .sort(sortingMetricsValue)
    .map((metricValue) => ({
      ...metricValue,
      propertyName: getPropertyByUid(
        allProperties as Property[],
        metricValue.propertyUid,
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      )?.name,
    }));

  return reducePropertyMetricValues({
    metricValues: preparedMetricValues,
    nameKey,
    exceedString,
    averageExceedValue: true,
  });
}

export function getXValueTypeByMetric({
  chartFilter,
}: {
  chartFilter: ChartFilter;
}): ValueType {
  switch (chartFilter.metric) {
    case FinancialPerformanceMetric.RENTAL_REVENUE:
    case FinancialPerformanceMetric.FEES:
    case FinancialPerformanceMetric.TAXES:
    case FinancialPerformanceMetric.TOTAL_REVENUE:
    case PropertyPerformanceMetric.RENTAL_REVENUE:
    case PropertyPerformanceMetric.AVERAGE_DAILY_RATE:
    case PropertyPerformanceMetric.REV_PAR:
    case TeamPerformanceMetric.TOTAL_REVENUE:
    case PieChartMetric.REV_PAR:
    case PieChartMetric.CHANNEL:
      if (chartFilter.type === ChartType.FINANCIAL_PERFORMANCE) {
        return ValueType.DATE;
      }
      return ValueType.CURRENCY;
    case PropertyPerformanceMetric.NIGHTS_BOOKED:
    case TeamPerformanceMetric.LEADS_PROCESSED:
    case TeamPerformanceMetric.LEADS_CONVERTED:
    case TeamPerformanceMetric.LEADS_ASSIGNED:
      return ValueType.NUMBER;
    case PropertyPerformanceMetric.OCCUPANCY_RATE:
      return ValueType.PERCENTAGE;

    default:
      return ValueType.NUMBER;
  }
}

export function getYValueTypeByMetric({
  chartFilter,
}: {
  chartFilter: ChartFilter;
}): ValueType {
  switch (chartFilter.metric) {
    case FinancialPerformanceMetric.RENTAL_REVENUE:
    case FinancialPerformanceMetric.FEES:
    case FinancialPerformanceMetric.TAXES:
    case FinancialPerformanceMetric.TOTAL_REVENUE:
      if (chartFilter.type === ChartType.TEAM_PERFORMANCE) {
        return ValueType.STRING;
      }

      return ValueType.CURRENCY;
    default:
      return ValueType.STRING;
  }
}

export function enableQuery({
  agencyUid,
  filter,
  allProperties,
}: {
  agencyUid: string;
  filter: any;
  allProperties: AnalyticsBasicProperty[];
}): boolean {
  return (
    !!agencyUid &&
    !!filter &&
    (!filter?.propertyUid ||
      (filter.propertyUid &&
        getPropertyCurrency(allProperties, filter.propertyUid) ===
          filter.currency))
  );
}

export function getFormatter({
  chartFilter,
  value,
  currency,
  formatCurrency,
  formatNumber,
  axis,
  timeSpan,
}: {
  chartFilter: ChartFilter;
  value: number | string;
  currency?: string;
  formatCurrency?: ReturnType<typeof useFormatCurrency>;
  formatNumber?: ReturnType<typeof useFormatNumber>;
  axis?: 'X' | 'Y';
  timeSpan?: TimeSpan;
}): string {
  const valueType =
    axis === 'X'
      ? getXValueTypeByMetric({ chartFilter })
      : getYValueTypeByMetric({ chartFilter });

  if (valueType === ValueType.DATE) {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return format(new Date(value), getDateFormatBasedOnTimeSpan(timeSpan));
  }

  if (valueType === ValueType.PERCENTAGE && formatNumber) {
    return `${formatNumber({ value: (value as number) * 100 })}%`;
  }

  if (valueType === ValueType.CURRENCY && formatCurrency) {
    return formatCurrency({
      value: value as number,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      currency,
      options: {
        round: true,
        notation: 'compact',
        removeDecimals: true,
      },
    });
  }

  if (valueType === ValueType.NUMBER && formatNumber) {
    return formatNumber({
      value: value as number,
    });
  }

  if (valueType === ValueType.STRING) {
    return trimAndLimitCharactersNumber({ text: value as string, limit: 15 });
  }

  return `${value}`;
}

export function getPercentageKeyMetrics({
  currentKeyMetrics,
  previousKeyMetrics,
}: {
  currentKeyMetrics: AnalyticsKeyMetricsValue;
  previousKeyMetrics: AnalyticsKeyMetricsValue;
}): number | null {
  if (previousKeyMetrics.value === null || previousKeyMetrics.value === 0)
    return null;

  const percentage =
    ((currentKeyMetrics.value - previousKeyMetrics.value) /
      previousKeyMetrics.value) *
    100;

  return !Number.isFinite(percentage) ? 100 : parseFloat(percentage.toFixed(1));
}
