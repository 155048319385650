import { AmenityChannel } from 'models/Amenity';
import { Channel } from 'models/Channels';

const amenityChannelEnumIdToSupportedChannel = {
  [Channel.orbirental]: AmenityChannel.HOSTFULLY,
  [Channel.airbnb]: AmenityChannel.AIRBNB,
  [Channel.homeaway]: AmenityChannel.VRBO,
  [Channel.booking_com]: AmenityChannel.BOOKINGDOTCOM,
  [Channel.google]: AmenityChannel.GOOGLE,
  [Channel.hvmi]: AmenityChannel.HVMI,
} as const;

export const channelToAmenityChannel = (channel: Channel) => {
  // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return amenityChannelEnumIdToSupportedChannel[channel];
};
