import { Trans, useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import { channelToIconMapping } from 'components/domain/lead/LeadChannelIcon';
import { formatDateMediumWithCurrentYearCheck } from 'utils/dateTimeUtils';
import {
  getLeadBookedDate,
  getLeadCreatedDate,
  getColorByLeadSource,
} from 'utils/lead/leadUtils';
import LeadMultiReservationLabel from '../LeadMultiReservationLabel';
import {
  LeadChannelNameWrapper,
  LeadCreationDetailsContainer,
  LeadReferrerWrapper,
} from './LeadListItemCreationDetails.styles';

const LeadListItemCreationDetails = ({ lead }: { lead: Lead }) => {
  const { channel, referrer, source } = lead;
  const bookedDate = getLeadBookedDate(lead);
  const createdDate = getLeadCreatedDate(lead);

  const { t } = useTranslation();

  if (!bookedDate && !createdDate) {
    return null;
  }

  const date = formatDateMediumWithCurrentYearCheck({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    date: bookedDate || createdDate,
    t,
  });
  const localeKeyPrefix = bookedDate ? 'booking' : 'creation';

  if (!channel) {
    return (
      <LeadCreationDetailsContainer>
        <Trans
          i18nKey={`pagePipeline.${localeKeyPrefix}DetailsBasic`}
          values={{ date }}
          components={{
            bold: <b />,
          }}
        />
      </LeadCreationDetailsContainer>
    );
  }

  const channelColor = getColorByLeadSource(source);

  if (referrer) {
    return (
      <LeadCreationDetailsContainer>
        <Trans
          i18nKey={`pagePipeline.${localeKeyPrefix}DetailsReferrer`}
          values={{
            channel: channel.bestProfile,
            date,
            referrer,
          }}
          components={{
            bold: <b />,
            // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            channelIcon: channelToIconMapping[channel?.channel] || <span />,
            channelName: (
              <LeadChannelNameWrapper $channelColor={channelColor} />
            ),
            referrer: <LeadReferrerWrapper />,
          }}
        />
      </LeadCreationDetailsContainer>
    );
  }

  return (
    <LeadCreationDetailsContainer>
      <Trans
        i18nKey={`pagePipeline.${localeKeyPrefix}DetailsDefault`}
        values={{
          channel: channel.bestProfile,
          date,
        }}
        components={{
          bold: <b />,
          // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          channelIcon: channelToIconMapping[channel?.channel] || <span />,
          channelName: <LeadChannelNameWrapper $channelColor={channelColor} />,
        }}
      />
      <LeadMultiReservationLabel lead={lead} />
    </LeadCreationDetailsContainer>
  );
};

export default LeadListItemCreationDetails;
