import { ComponentPropsWithoutRef, ReactNode, useEffect, useRef } from 'react';
import { SidePanelContainerStyled, SidePanelOverlay } from './SidePanel.styles';
import useSidePanel from './useSidePanel';
import { SidePanelDirection } from './SidePanel.types';

interface SidePanelProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  direction?: SidePanelDirection;
  sidePanelId: string;
}

const SidePanel = ({
  children,
  direction,
  sidePanelId,
  ...props
}: SidePanelProps) => {
  const { isSidePanelOpen, closeSidePanel } = useSidePanel({ sidePanelId });
  const sidePanelRef = useRef<HTMLDivElement>(null);

  // Handles click outside
  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const target = event.target as Node;
      const trigger = document.querySelector(`.${sidePanelId}-trigger`);

      if (
        isSidePanelOpen &&
        trigger !== target &&
        // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        !trigger.contains(target) &&
        sidePanelRef.current &&
        !sidePanelRef.current.contains(target)
      ) {
        closeSidePanel();
      }
    };

    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [isSidePanelOpen, sidePanelId, sidePanelRef]);

  return (
    <>
      <SidePanelContainerStyled
        data-testid="side-panel"
        $direction={direction ?? SidePanelDirection.LEFT_TO_RIGHT}
        $isOpen={isSidePanelOpen}
        ref={sidePanelRef}
        {...props}
      >
        {isSidePanelOpen ? children : null}
      </SidePanelContainerStyled>
      {isSidePanelOpen && <SidePanelOverlay />}
    </>
  );
};

SidePanel.defaultProps = {
  direction: undefined,
};

export default SidePanel;
