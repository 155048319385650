import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import Tooltip from 'components/tooltip/Tooltip';
import { Col, FormControl, Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { IsOptionalFieldRow } from './PropertyFeeModal.styles';

const IsOptionalField = () => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const onChangeHandler = (e: React.FormEvent<FormControl>) => {
    if ((e.target as HTMLInputElement).checked) {
      setValue('appliesToHostfully', 'YES');
    }
  };

  return (
    <IsOptionalFieldRow>
      <Col xs={6} xsOffset={4}>
        <CheckboxField name="optional" onChangeHandler={onChangeHandler}>
          {t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.feeIsOptional',
          )}
        </CheckboxField>
      </Col>

      <Col xs={2}>
        <Tooltip
          id="fee-is-optional-tooltip"
          text={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.feeOptionalTooltip',
          )}
        >
          <Glyphicon glyph="info-sign" />
        </Tooltip>
      </Col>
    </IsOptionalFieldRow>
  );
};

export default IsOptionalField;
