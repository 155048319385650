import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetPhotosResponse, Photo } from 'models/Photos';
import API from 'services/API';

export const PHOTOS_BASE_QUERY_KEY = 'photos';

type ReactQueryOptions = Omit<UseQueryOptions<Photo[]>, 'queryKey' | 'queryFn'>;

type UseFetchPhotosArgs = {
  propertyUid: string;
  isHotel?: boolean;
} & ReactQueryOptions;

const useFetchPhotos = ({
  propertyUid,
  isHotel,
  enabled = true,
  ...options
}: UseFetchPhotosArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid],
    queryFn: async () => {
      const response = await API.get<GetPhotosResponse>('/api/v3.2/photos', {
        params: {
          [isHotel ? 'hotelUid' : 'propertyUid']: propertyUid,
        },
      });

      return response.data.photos;
    },
    staleTime: 30000,
    keepPreviousData: true,
    enabled: enabled && !!propertyUid,
    ...(options ?? {}),
  });
};

export default useFetchPhotos;
