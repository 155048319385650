import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import FormWithProvider from 'components/forms/form/Form';
import useSaveAssignmentSettingsMutation from 'components/domain/property/useSaveAssignmentSettingsMutation';
import { ASSIGNMENT_SETTINGS_BASE_QUERY_KEY } from 'components/domain/property/useFetchAssignmentSettings';
import BulkSavePropertiesModalTitle from 'components/domain/property/modal/BulkSavePropertiesModalTitle';
import useBulkSaveModal from 'components/domain/property/modal/useBulkSaveModal';
import { AssigneeWithEmployee } from 'components/domain/property/useFetchAssignmentSettingsWithEmployees';
import useNotify from 'hooks/useNotify';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import PropertyPermissionsModal from './modal/PropertyPermissionsModal';
import { PropertyNameTitle } from './modal/PropertyPermissionsModal.styles';
import { FormValues } from './modal/PropertyPermissionModal.types';
import useExtractAssignmentSettings from './modal/useExtractAssignmentSettings';

const modalId = 'propertyItemPermissionsModal';

const usePropertyItemPermissionsModal = (
  property: Pick<PropertyBase, 'uid' | 'name'>,
) => {
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useNotify();
  const { openModal, closeModal } = useAppModal();
  const { openBulkSaveModal } = useBulkSaveModal();
  const queryClient = useQueryClient();
  const { name, uid: propertyUid } = property;
  const { extractAssignmentSettings } =
    useExtractAssignmentSettings(propertyUid);
  const { mutateAsync: saveAssignmentSettings } =
    useSaveAssignmentSettingsMutation({
      onError: () => {
        notifyError(t('pageProperties.actions.permissionsModal.onSubmitError'));
      },
      onSuccess: () => {
        closeModal(modalId);
        notifySuccess(
          t('pageProperties.actions.permissionsModal.onSubmitSuccess'),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          ASSIGNMENT_SETTINGS_BASE_QUERY_KEY,
          propertyUid,
        ]);
      },
    });

  const handleSubmit = (values: FormValues) => {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return saveAssignmentSettings({
      ...extractAssignmentSettings(values),
      propertyUid,
    });
  };

  const handleBulkSaveClick = (
    values: FormValues,
    enabledAssignees: AssigneeWithEmployee[],
  ) => {
    const assignmentSettings = extractAssignmentSettings(values);
    const autoAssignUid = assignmentSettings.automaticAssignment.employeeUid;
    const autoAssignEmployee = enabledAssignees.find(
      (a) => a.employeeUid === autoAssignUid,
    )?.employee;

    const autoAssignLabel = autoAssignEmployee?.bestProfile
      ? t('pageProperties.permissions.autoAssignLeadsTo')
      : t('pageProperties.permissions.autoAssignLeads');

    const autoAssignValue = autoAssignEmployee?.bestProfile ?? t('common.off');

    closeModal(modalId);
    openBulkSaveModal({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyBulkUpdateRequest: { assignmentSettings },
      fieldsDescriptions: {
        [t('pageProperties.permissions.whoCanManageThisProperty')]:
          enabledAssignees.map((a) => a.employee.bestProfile).join(', '),
        [autoAssignLabel]: autoAssignValue,
      },
      title: (
        <BulkSavePropertiesModalTitle>
          <Trans
            i18nKey="pageProperties.permissions.whoCanManage"
            values={{ name }}
            components={{
              span: <PropertyNameTitle />,
            }}
          />
        </BulkSavePropertiesModalTitle>
      ),
      onError: () => {
        notifyError(
          t('pageProperties.actions.permissionsModal.onSubmitBulkSaveError'),
        );
      },
      onSuccess: () => {
        notifySuccess(
          t('pageProperties.actions.permissionsModal.onSubmitBulkSaveSuccess'),
        );

        // invalidate all assignment settings queries, because the bulk request
        // could be making changes for every property
        queryClient.invalidateQueries([ASSIGNMENT_SETTINGS_BASE_QUERY_KEY]);
      },
      preSelectedProperties: [property],
      graphQlFilters: { topLevelOnly: false },
      filterPropertyBy: ({ businessType }) => {
        const isHotel = businessType === PropertyBusinessType.HOTEL;
        const isSubUnit = businessType === PropertyBusinessType.SUB_UNIT;

        return !isHotel && !isSubUnit;
      },
    });
  };

  const openPropertyItemPermissionsModal = () => {
    openModal({
      title: (
        <Trans
          i18nKey="pageProperties.permissions.whoCanManage"
          values={{ name }}
          components={{
            span: <PropertyNameTitle />,
          }}
        />
      ),
      id: modalId,
      customContent: (
        <FormWithProvider schema={{}} onSubmit={handleSubmit}>
          <PropertyPermissionsModal
            property={property}
            onClose={() => closeModal(modalId)}
            onBulkSaveClick={handleBulkSaveClick}
          />
        </FormWithProvider>
      ),
    });
  };

  return { openPropertyItemPermissionsModal };
};

export default usePropertyItemPermissionsModal;
