import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { BOOKING_TYPES } from 'models/BookingType';
import { BOOKING_WINDOWS } from 'models/BookingWindow';
import { PropertyBusinessType } from 'models/Properties';
import { FormSectionColumn } from 'pages/property/common/Common.styles';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import TooltipContentWithLearnMore from 'pages/property/common/tooltip/TooltipContentWithLearnMore';
import { range } from 'utils/arrayUtils';
import { PropertyDetailsCollapseStyled } from '../propertyDetails/PropertyDetails.styles';
import usePropertySettingsInitData from '../../../usePropertySettingsInitData';
import { OpenSetMinStayContainer } from './BookingSettings.styles';
import OnlyCheckInOnField from './OnlyCheckInOnField';
import useOpenSetMinStayByWeekDayModal from './useOpenSetMinStayByWeekDayModal';
import CollectBalanceAtField from './CollectBalanceAtField';

const BookingSettings = () => {
  const { t } = useTranslation();
  const { openSetMinStayByWeekDayModal } = useOpenSetMinStayByWeekDayModal();
  const { property } = usePropertySettingsInitData();

  if (property?.businessType === PropertyBusinessType.HOTEL) {
    return null;
  }

  const bookingTypeOptions = Object.values(BOOKING_TYPES).map((value) => ({
    label: t(`pageProperty.mainSettings.bookingTypeOptions.${value}`),
    value,
  }));

  const bookingWindowOptions = Object.values(BOOKING_WINDOWS).map((value) => ({
    label: t(
      `pageProperty.mainSettings.bookingSettings.bookingWindowOptions.${value}`,
    ),
    value,
  }));

  const turnoverDaysOptions = range(0, 8).map((value) => ({
    label: t('common.daysOptions.day', { count: value }),
    value,
  }));

  const bookingLeadTimeOptions = [...range(-15, 25), 48, 72, 168].map(
    (value) => ({
      label: t('pageProperty.mainSettings.bookingSettings.hourOption', {
        count: value,
      }),
      value,
    }),
  );

  const checkInTimeOptions = range(0, 24).map((value) => ({
    label: t('pageProperty.mainSettings.bookingSettings.hourOption', {
      count: value,
    }),
    value,
  }));

  const checkoutTimeOptions = range(0, 24).map((value) => ({
    label: t('pageProperty.mainSettings.bookingSettings.hourOption', {
      count: value,
    }),
    value,
  }));

  return (
    <PropertyDetailsCollapseStyled
      data-testid="booking-settings"
      defaultExpanded
      label={t('pageProperty.mainSettings.bookingSettings.title')}
      variant="card"
    >
      <Row>
        <FormSectionColumn>
          <SelectField
            label={
              <PropertyFormFieldLabel name="bookingSettings.bookingType">
                {t('pageProperty.mainSettings.bookingSettings.bookingType')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.bookingType"
            options={bookingTypeOptions}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={
              <TooltipContentWithLearnMore
                labelTooltipText={t(
                  'pageProperty.mainSettings.bookingSettings.bookingTypeTooltip',
                )}
                learnMoreUrl="https://help.hostfully.com/en/articles/2989533-select-the-booking-setting-under-the-property-s-main-settings"
              />
            }
            required
          />
          <SelectField
            label={
              <PropertyFormFieldLabel name="bookingSettings.bookingWindow">
                {t('pageProperty.mainSettings.bookingSettings.bookingWindow')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.bookingWindow"
            options={bookingWindowOptions}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.bookingWindowTooltip',
            )}
            required
          />
          <SelectField
            label={
              <PropertyFormFieldLabel name="bookingSettings.turnoverDays">
                {t('pageProperty.mainSettings.bookingSettings.turnoverDays')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.turnoverDays"
            options={turnoverDaysOptions}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.turnoverDaysTooltip',
            )}
          />
          <SelectField
            label={
              <PropertyFormFieldLabel name="bookingSettings.bookingLeadTime">
                {t('pageProperty.mainSettings.bookingSettings.bookingLeadTime')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.bookingLeadTime"
            options={bookingLeadTimeOptions}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.bookingLeadTimeTooltip',
            )}
            required
          />
          <OnlyCheckInOnField />
          <SelectField
            label={
              <PropertyFormFieldLabel name="bookingSettings.checkInTime">
                {t('pageProperty.mainSettings.bookingSettings.checkInTime')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.checkInTime"
            options={checkInTimeOptions}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.checkInTimeTooltip',
            )}
            required
          />
          <SelectField
            label={
              <PropertyFormFieldLabel name="bookingSettings.checkoutTime">
                {t('pageProperty.mainSettings.bookingSettings.checkoutTime')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.checkoutTime"
            options={checkoutTimeOptions}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.checkInTimeTooltip',
            )}
            required
          />
        </FormSectionColumn>
        <FormSectionColumn>
          <TextField
            label={
              <PropertyFormFieldLabel name="bookingSettings.minimumStay">
                {t('pageProperty.mainSettings.bookingSettings.minimumStay')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.minimumStay"
            colSmInput={7}
            colSmLabel={5}
            rightAddons={[
              t('pageProperty.mainSettings.bookingSettings.nights'),
            ]}
            type="number"
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.minimumStayTooltip',
            )}
            required
          />
          <OpenSetMinStayContainer sm={7} smOffset={5}>
            <Button
              bsStyle="link"
              data-testid="open-set-min-stay-modal"
              onClick={openSetMinStayByWeekDayModal}
            >
              {t(
                'pageProperty.mainSettings.bookingSettings.setMinStayByDayOfWeek',
              )}
            </Button>
          </OpenSetMinStayContainer>
          <TextField
            label={
              <PropertyFormFieldLabel name="bookingSettings.maximumStay">
                {t('pageProperty.mainSettings.bookingSettings.maximumStay')}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.maximumStay"
            colSmInput={7}
            colSmLabel={5}
            rightAddons={[
              t('pageProperty.mainSettings.bookingSettings.nights'),
            ]}
            type="number"
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.maximumStayTooltip',
            )}
          />
          <TextField
            label={
              <PropertyFormFieldLabel name="bookingSettings.minimumWeekendStay">
                {t(
                  'pageProperty.mainSettings.bookingSettings.minimumWeekendStay',
                )}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.minimumWeekendStay"
            colSmInput={7}
            colSmLabel={5}
            rightAddons={[
              t('pageProperty.mainSettings.bookingSettings.nights'),
            ]}
            type="number"
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.minimumWeekendStayTooltip',
            )}
          />
          <TextField
            label={
              <PropertyFormFieldLabel name="bookingSettings.percentageUponReservation">
                {t(
                  'pageProperty.mainSettings.bookingSettings.percentageUponReservation',
                )}
              </PropertyFormFieldLabel>
            }
            name="bookingSettings.percentageUponReservation"
            colSmInput={7}
            colSmLabel={5}
            type="number"
            min={0}
            max={100}
            rightTooltip={t(
              'pageProperty.mainSettings.bookingSettings.doesNotPushToAirbnbTooltip',
            )}
          />
          <CollectBalanceAtField />
        </FormSectionColumn>
      </Row>
    </PropertyDetailsCollapseStyled>
  );
};

export default BookingSettings;
