import Button from 'components/button/Button';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { Property } from 'models/Properties';
import { Col, Modal, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import { serviceProviderEditModalId } from '../../PropertySettingsServiceProvidersTab.constants';
import { ServiceProvidersFormValues } from '../../PropertySettingsServiceProvidersTab.type';
import {
  ServiceProviderBulkEditSchema,
  serviceProviderBulkEditSchema,
} from './ServiceProviderBulkEditModal.schema';
import { FooterContainer } from './ServiceProviderBulkEditModal.styles';
import useServiceProviderMutation from './useServiceProviderMutation';

const ServiceProviderBulkEditModal = ({
  formValues,
  dirtyFields,
  property,
}: {
  formValues: ServiceProvidersFormValues;
  dirtyFields: any;
  property: Property;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { updateServiceProvider, isUpdating } = useServiceProviderMutation();

  const notesIsUpdated = dirtyFields?.serviceProviders?.notes;

  const getUpdatedNotesText = () => {
    return formValues.serviceProviders.notes.length > 45
      ? `${formValues.serviceProviders.notes.slice(0, 45)}...`
      : formValues.serviceProviders.notes;
  };

  const allProvidersAreUpdated =
    dirtyFields.serviceProviders?.providers === true;

  const providersList = allProvidersAreUpdated
    ? formValues.serviceProviders?.providers ?? []
    : dirtyFields.serviceProviders?.providers ?? [];

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const updatedProviders = providersList.map((_, index) => {
    const provider = formValues.serviceProviders.providers[index];

    const providerName = `${provider.firstName} ${provider.lastName}`;

    return {
      label: `${provider.servicesTitles || ''} (${providerName}, ${t(
        `pageProperty.serviceProviders.type.${provider.type}`,
      )})`,
      isActive: Boolean(provider.isActive),
    };
  });

  const handleClose = () => {
    closeModal(serviceProviderEditModalId);
  };

  const onSubmit = (
    values: ServiceProviderBulkEditSchema,
    serviceProvidersFormValues: ServiceProvidersFormValues,
  ) => {
    const { updateAllProperties, propertiesUids } =
      extractPropertiesMultiSelectFields('appliesTo', values);

    const payload = {
      updateContext: {
        applyToAll: updateAllProperties,
        propertyUids: updateAllProperties ? [] : propertiesUids,
      },
      maintenanceNotes: {
        notes: serviceProvidersFormValues.serviceProviders.notes,
      },
      serviceProviderPropertyStatuses:
        serviceProvidersFormValues.serviceProviders.providers.map((sp) => ({
          serviceProviderUid: sp.uid,
          enabled: sp.isActive,
        })),
    };

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    updateServiceProvider(payload);
  };

  return (
    <FormWithProvider
      horizontal
      resolver={serviceProviderBulkEditSchema}
      onSubmit={(values) =>
        onSubmit(values as ServiceProviderBulkEditSchema, formValues)
      }
      defaultValues={{
        appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
        appliesToProperties: [
          {
            label: property.name,
            value: property.uid,
            isFixed: true,
          },
        ],
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pageProperty.serviceProviders.editModal.bulkSave')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <p>
              {t('pageProperty.serviceProviders.editModal.followingFields')}
            </p>

            <ul>
              {/*
               // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
              {updatedProviders?.map((updatedProvider) => (
                <li key={updatedProvider.label}>
                  {updatedProvider.label}:{' '}
                  <strong>
                    {updatedProvider.isActive
                      ? t('common.on')
                      : t('common.off')}
                  </strong>
                </li>
              ))}

              {notesIsUpdated && (
                <li>
                  <Trans
                    i18nKey="pageProperty.serviceProviders.editModal.updatedNotes"
                    components={{
                      bold: <strong />,
                    }}
                    values={{
                      notes: getUpdatedNotesText(),
                    }}
                  />
                </li>
              )}
            </ul>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {t('pageProperty.serviceProviders.editModal.willBeAppliedTo')}
          </Col>

          <Col sm={12}>
            <PropertyMultiSelectFilterField
              name="appliesTo"
              enableUnitTypes={false}
              enableHotels={false}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <FooterContainer>
          <Button bsStyle="default" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button bsStyle="primary" type="submit" disabled={isUpdating}>
            {t('common.save')}
          </Button>
        </FooterContainer>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default ServiceProviderBulkEditModal;
