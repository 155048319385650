import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { PropertyPriceSettingsData } from './PriceSettings.types';

export const PRICE_SETTINGS_BASE_QUERY_KEY = 'price-settings';

type ReactQueryOptions = Omit<
  UseQueryOptions<PropertyPriceSettingsData | null>,
  'queryKey' | 'queryFn'
>;

type UseFetchPriceSettingsDataArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchPriceSettingsData = ({
  propertyUid,
  enabled = true,
  ...options
}: UseFetchPriceSettingsDataArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PRICE_SETTINGS_BASE_QUERY_KEY, 'detail', propertyUid],
    queryFn: async () => {
      const response = await API.get<PropertyPriceSettingsData | null>(
        `/api/internal/properties/${propertyUid}/pricing-settings`,
      );

      return response.data;
    },
    enabled: enabled && !!propertyUid,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchPriceSettingsData;
