import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import {
  PropertyPriceSettingsUpdatePayload,
  PropertyPriceSettingsUpdateResponse,
} from 'pages/property/pricing/sections/priceSettings/PriceSettings.types';
import API from 'services/API';

type UpdatePropertyPriceSettingsArgs = PropertyPriceSettingsUpdatePayload & {
  propertyUid: string;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    PropertyPriceSettingsUpdateResponse,
    unknown,
    UpdatePropertyPriceSettingsArgs
  >,
  'mutationFn' | 'mutationKey'
>;

const updatePropertyPriceSettings = ({
  propertyUid,
  ...payload
}: UpdatePropertyPriceSettingsArgs) => {
  return API.put<PropertyPriceSettingsUpdateResponse, BaseErrorResponse>(
    `/api/internal/properties/${propertyUid}/pricing-settings`,
    payload,
  );
};

const useUpdatePropertyPriceSettings = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: updatePropertyPriceSettings,
    ...options,
  });
};

export default useUpdatePropertyPriceSettings;
