import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { PriceRulesAddEditFormValues } from './PriceRulesAddEditModal.schema';

const PriceRulesAddEditModalAdjustPriceField = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    priceRule: { type },
  } = useWatch<PriceRulesAddEditFormValues>();
  const increaseOrDiscount =
    type === PropertyPricingType.SHORT_STAY_PREMIUM ? 'INCREASE' : 'DISCOUNT';

  return (
    <TextField
      label={t('pageProperty.pricing.priceRules.modal.form.priceModifier', {
        context: increaseOrDiscount,
      })}
      name="priceRule.priceModifier"
      colSmInput={8}
      colSmLabel={4}
      step="any"
      type="number"
      rightAddons={['%']}
      required
    />
  );
};

export default PriceRulesAddEditModalAdjustPriceField;
