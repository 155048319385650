import { useParams } from 'react-router-dom';
import { queryClient } from 'App';
import { OrderInitData } from 'models/Orders';
import { Lead } from 'models/Leads';
import { getOrderViewLeadQueryKey } from './useOrderViewInit';

const useOrderViewFetchedData = () => {
  const { uid: orderUid } = useParams();

  const initData = queryClient.getQueryData<OrderInitData>([
    'orderViewInitData',
    orderUid,
  ]);

  const lead = queryClient.getQueryData<Lead>(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getOrderViewLeadQueryKey(initData?.leadUid),
  );

  return { initData, lead, orderUid };
};

export default useOrderViewFetchedData;
