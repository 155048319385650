import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import AmenitiesMultiSelectField from 'components/forms/domain/property/AmenitiesMultiSelectField';
import BedroomsRangeField from 'components/forms/domain/property/BedroomsRangeField';
import GuestsRangeField from 'components/forms/domain/property/GuestsRangeField';
import LocationSelectField from 'components/forms/domain/property/LocationSelectField';
import StatusSelectField from 'components/forms/domain/property/StatusSelectField';
import TagsMultiSelectField from 'components/forms/domain/property/TagsMultiSelectField';
import TypeSelectField from 'components/forms/domain/property/TypeSelectField';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import {
  FilterFormActions,
  FilterFormClearButton,
  FilterFormSubmitButton,
} from 'components/filters/FilterFormButtons';
import SelectField from 'components/forms/selectField/SelectField';
import RangeField from 'components/forms/rangeField/RangeField';
import Button from 'components/button/Button';
import { Col, Row } from 'react-bootstrap';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { isAfter } from 'date-fns';
import useAppUser from 'hooks/useAppUser';
import useOpenEmailModal from 'components/modal/emailModal/useOpenEmailModal';
import { EmailModalOrigin } from 'models/SendEmail';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  getCalendarFilterLocalStorageKey,
  stackedCalendarPropertiesConstants,
} from '../Calendar.constants';
import CalendarContext from '../CalendarContext';
import { defaultValues } from './StackedCalendarFilters.schema';

const StackedCalendarFiltersForm = ({
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  filterOptions,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  employees,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  scrollOnClear,
}) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { agency, userUid } = useAppUser();
  const { reset, watch, setValue } = useFormContext();
  const { setFilter } = useContext(CalendarContext);
  const { clearLocalStorage } = useLocalStorage(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getCalendarFilterLocalStorageKey(userUid),
  );
  const { sendEmailModal } = useOpenEmailModal(
    EmailModalOrigin.CALENDAR_FILTER,
  );

  const watcher = watch();
  const isAvailableFromAfterAvailableTo =
    watcher?.availableFrom &&
    watcher?.availableTo &&
    isAfter(watcher?.availableFrom, watcher?.availableTo);

  if (
    (watcher?.availableFrom && !watcher?.availableTo) ||
    isAvailableFromAfterAvailableTo
  ) {
    setValue('availableTo', watcher?.availableFrom, { shouldDirty: true });
  }

  if (watcher.availableFrom && watcher.status !== 'active') {
    setValue('status', 'active');
  }

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const appendCurrencySymbol = (value) => {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const { currency, currencySymbol } = agency;

    if (currencySymbol) {
      return `${currencySymbol || ''}${value}`;
    }

    if (currency) {
      return `${formatCurrency({ currency, value })}`;
    }

    return value;
  };

  const handleClear = () => {
    const newDefaultValues = {
      ...defaultValues(filterOptions),
      offset: 0,
      limit: stackedCalendarPropertiesConstants.defaultLimit,
    };
    reset(defaultValues(filterOptions));
    // @ts-ignore
    setFilter(newDefaultValues);
    clearLocalStorage();
    scrollOnClear(new Date());
  };

  return (
    <>
      <Row>
        <Col md={4} sm={6}>
          <BedroomsRangeField name="bedrooms" filterOptions={filterOptions} />
        </Col>
        <Col md={4} sm={6}>
          <StatusSelectField name="status" />
        </Col>
        <Col md={4} sm={6}>
          <AmenitiesMultiSelectField
            name="amenities"
            filterOptions={filterOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6}>
          <GuestsRangeField name="guests" filterOptions={filterOptions} />
        </Col>
        <Col md={4} sm={6}>
          <TypeSelectField name="type" filterOptions={filterOptions} />
        </Col>
        <Col md={4} sm={6}>
          <TagsMultiSelectField name="tags" filterOptions={filterOptions} />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6}>
          <RangeField
            colSmInput={8}
            colSmLabel={4}
            label={t('pageCalendar.stacked.dailyRate')}
            name="dailyRate"
            min={filterOptions?.minBaseDailyRate}
            max={filterOptions?.maxBaseDailyRate}
            formatMin={appendCurrencySymbol}
            formatMax={appendCurrencySymbol}
          />
        </Col>
        <Col md={4} sm={6}>
          <LocationSelectField name="location" filterOptions={filterOptions} />
        </Col>
        <Col md={4} sm={6}>
          <DateTimePickerField
            colSmInput={8}
            colSmLabel={4}
            label={t('pageCalendar.stacked.availableFrom')}
            name="availableFrom"
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6} mdOffset={4}>
          <SelectField
            colSmInput={8}
            colSmLabel={4}
            label={t('pageCalendar.stacked.agent')}
            name="agentUid"
            options={[
              { label: t('pageCalendar.stacked.all'), value: '' },
              // eslint-disable-next-line no-unsafe-optional-chaining
              // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              ...employees.map((employee) => ({
                label: employee.bestProfile,
                value: employee.uid,
              })),
            ]}
          />
        </Col>
        <Col md={4} sm={6}>
          <DateTimePickerField
            colSmInput={8}
            colSmLabel={4}
            label={t('pageCalendar.stacked.availableTo')}
            name="availableTo"
          />
        </Col>
      </Row>
      <FilterFormActions>
        <Button
          data-testid="email-property-list-filter"
          type="button"
          bsStyle="link"
          onClick={sendEmailModal}
        >
          {t('pageCalendar.stacked.emailPropertyList')}
        </Button>
        <FilterFormClearButton onClick={handleClear} />
        <FilterFormSubmitButton />
      </FilterFormActions>
    </>
  );
};

export default StackedCalendarFiltersForm;
