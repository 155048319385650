import useAvailableCountries from 'components/domain/country/useAvailableCountries';
import { CountryCode } from 'models/Country';
import { SelectFieldProps } from '../selectField/SelectField.types';
import LoadingPlaceholder from '../../loader/LoadingPlaceholder';
import SelectField from '../selectField/SelectField';

export interface CountrySelectFieldProps
  extends Omit<SelectFieldProps, 'options' | 'optionGroups'> {
  excludeCountries?: CountryCode[];
}

const CountrySelectField = ({
  excludeCountries,
  ...props
}: CountrySelectFieldProps) => {
  const { countries, isLoading } = useAvailableCountries();

  if (isLoading) {
    return <LoadingPlaceholder linesCount={2} width="100%" />;
  }

  const options = countries
    .filter(({ code }) => !excludeCountries?.includes(code) ?? true)
    .map(({ code, flag, localizedName }) => ({
      value: code,
      label: `${flag} ${localizedName}`,
    }));

  return <SelectField options={options} {...props} />;
};

CountrySelectField.defaultProps = {
  excludeCountries: undefined,
};

export default CountrySelectField;
