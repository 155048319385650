import { useContext } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import Loader from 'components/loader/Loader';
import { AppEventType } from 'models/Events';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from '../../../../hooks/useAppModal';
import Button from '../../../button/Button';
import useAirbnbAlterationActions from '../useAirbnbAlterationActions';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';
import { AirbnbAlterationType } from './AirbnbAlteration.types';
import useAirbnbAlterationModalData from './useAirbnbAlterationModalData';
import {
  AirbnbAlterationRequestModalActionsContainer,
  AirbnbAlterationRequestModalFooterMessagesContainer,
  AirbnbAlterationRequestModalFooterSubmitButtonsContainer,
} from './AirbnbAlterationRequestModalFooter.styles';
import useAirbnbAlterationModalUpdateQuoteData from './useAirbnbAlterationModalUpdateQuoteData';

const AirbnbAlterationRequestModalFooter = () => {
  const { modalId, lead } = useContext(AirbnbAlterationRequestModalContext);
  const { airbnbAlterationData, isLoading } = useAirbnbAlterationModalData();

  const {
    acceptAlterationRequest,
    declineAlterationRequest,
    cancelAlterationRequest,
    isAccepting,
    isDeclining,
    isSending,
    isCanceling,
  } = useAirbnbAlterationActions();
  const { isDirty, apiErrorMessage } =
    useAirbnbAlterationModalUpdateQuoteData();
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { isEmployeeAssociate } = useAppUser();
  const { t } = useTranslation();
  const cancelHandler = () => {
    closeModal(modalId);
  };

  const declineAction = async () => {
    await declineAlterationRequest({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      airbnbAlteration: airbnbAlterationData,
    });
    closeModal(modalId);
    publish(AppEventType.LEAD_UPDATED, { lead: { uid: lead?.uid } });
  };

  const acceptAction = async () => {
    await acceptAlterationRequest({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      airbnbAlteration: airbnbAlterationData,
    });
    closeModal(modalId);
    publish(AppEventType.LEAD_UPDATED, { lead: { uid: lead?.uid } });
  };

  const cancelAction = async () => {
    await cancelAlterationRequest({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      airbnbAlteration: airbnbAlterationData,
    });
    closeModal(modalId);
    publish(AppEventType.LEAD_UPDATED, { lead: { uid: lead?.uid } });
  };

  if (isLoading) {
    return (
      <AirbnbAlterationRequestModalActionsContainer>
        <Loader />
      </AirbnbAlterationRequestModalActionsContainer>
    );
  }

  return (
    <Modal.Footer data-testid="airbnb-alteration-request-modal-footer">
      <Row>
        <AirbnbAlterationRequestModalFooterMessagesContainer />
        <AirbnbAlterationRequestModalFooterSubmitButtonsContainer>
          {airbnbAlterationData?.type === AirbnbAlterationType.GUEST ? (
            <>
              <Button
                bsStyle="danger"
                disabled={isAccepting || isDeclining}
                onClick={declineAction}
                data-testid="airbnb-alteration-decline-action-button"
              >
                {t(`componentLead.alterationRequest.action.decline`)}
              </Button>
              <Button
                bsStyle="success"
                disabled={isAccepting || isDeclining}
                onClick={acceptAction}
                data-testid="airbnb-alteration-accept-action-button"
              >
                {t(`componentLead.alterationRequest.action.approve`)}
              </Button>
            </>
          ) : (
            <>
              <Button
                disabled={isSending}
                onClick={cancelHandler}
                data-testid="airbnb-alteration-cancel-request-button"
              >
                {t('common.cancel')}
              </Button>
              {airbnbAlterationData ? (
                <Button
                  bsStyle="danger"
                  data-testid="airbnb-alteration-cancel-action-button"
                  disabled={isEmployeeAssociate || isCanceling}
                  type="button"
                  onClick={cancelAction}
                >
                  {isCanceling
                    ? t('common.canceling')
                    : t(
                        `componentLead.alterationRequest.cancelAlterationRequest`,
                      )}
                </Button>
              ) : (
                <Button
                  bsStyle="primary"
                  data-testid="airbnb-alteration-update-action-button"
                  disabled={
                    isEmployeeAssociate ||
                    isSending ||
                    !isDirty ||
                    !!apiErrorMessage
                  }
                  id="submit"
                  type="submit"
                >
                  {isSending
                    ? t('common.sending')
                    : t(
                        `componentLead.alterationRequest.sendAlterationRequest`,
                      )}
                </Button>
              )}
            </>
          )}
        </AirbnbAlterationRequestModalFooterSubmitButtonsContainer>
      </Row>
    </Modal.Footer>
  );
};

export default AirbnbAlterationRequestModalFooter;
