/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAppQuery from 'hooks/useAppQuery';

import { Alert } from 'react-bootstrap';
import Loader from 'components/loader/Loader';
import Button from 'components/button/Button';
import PageHeader from 'components/pageHeader/PageHeader';

import API from 'services/API';
import { GetAllAgentsResponseTO } from 'models/Agents';
import AgentImage from 'assets/images/agent.png';

import { Container } from './ThirdPartyAgency.styles';

const ThirdPartyAgency = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: thirdPartyAgencies, isLoading } = useAppQuery(
    ['thirdPartyAgencies'],
    async () => {
      const response = await API.get<GetAllAgentsResponseTO>('/v3/agents');
      return response.data.agents;
    },
    { enabled: true },
  );

  if (isLoading) {
    return <Loader fullPage />;
  }

  return (
    <Container>
      <PageHeader
        title={t('pageThirdPartyAgency.title')}
        subtitle={
          <>
            <p>{t('pageThirdPartyAgency.subtitle')}</p>
            <span>
              {`${t('pageThirdPartyAgency.subtitleTip')} `}
              <a
                href="https://help.hostfully.com/en/articles/4352823-setting-up-third-party-agencies-for-vrbo"
                target="_blank"
                rel="noreferrer"
              >
                {t('pageThirdPartyAgency.subtitleLink')}
              </a>
            </span>
          </>
        }
        buttons={
          <Button
            bsSize="xsmall"
            data-testid="add-third-party-agency"
            bsStyle="primary"
            onClick={() => navigate('/third-party-agency/add')}
          >
            {t('pageThirdPartyAgency.button')}
          </Button>
        }
      />
      {/*
       // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      {thirdPartyAgencies.map((agency) => (
        <div
          key={agency.uid}
          data-testid="agency"
          className="agency-card-container"
          onClick={() => navigate(`/third-party-agency/${agency.uid}`)}
        >
          <div className="image">
            <img src={agency.pictureName || AgentImage} alt="Agency" />
          </div>
          <div className="info">
            <h2 className="link" data-testid="name">
              {agency.firstName || agency.email.split('@')[0]}
            </h2>
            <p data-testid="email">{agency.email}</p>
          </div>
        </div>
      ))}
      {/*
       // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      {thirdPartyAgencies.length === 0 && !isLoading && (
        <Alert bsStyle="warning">
          {t('pageThirdPartyAgency.noThirdPartyAgency')}
        </Alert>
      )}
    </Container>
  );
};

export default ThirdPartyAgency;
