import useFeatures from 'hooks/useFeatures';
import PageHeader from 'components/pageHeader/PageHeader';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropertySettingsBetaAccessInfo from '../PropertySettingsBetaAccessInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { OwnerDescription } from './PropertySettingsOwnersTab.styles';

const PropertySettingsOwnersTab = () => {
  const { allowPropertyEditV3Beta } = useFeatures();
  const { property, isLoading } = usePropertySettingsInitData();

  if (allowPropertyEditV3Beta) {
    return <PropertySettingsBetaAccessInfo />;
  }

  return (
    <section>
      <PageHeader
        className="owners-title"
        title={
          <Trans
            i18nKey="pageProperty.owners.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        noDivider
        isLoading={isLoading}
      />

      <OwnerDescription data-testid="owners-description">
        <Trans
          i18nKey="pageProperty.owners.description"
          components={{
            1: (
              <Link
                to="https://help.hostfully.com/en/articles/1674802-use-the-owner-portal"
                target="_blank"
              />
            ),
          }}
        />
      </OwnerDescription>
    </section>
  );
};

export default PropertySettingsOwnersTab;
