import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import PriceRulesChannelsPerType from '../PriceRulesChannelsPerType';

interface ChannelCellProps {
  getValue: () => PropertyPricingType;
}

const ChannelCell = ({ getValue }: ChannelCellProps) => {
  const type = getValue();

  return <PriceRulesChannelsPerType type={type} />;
};

export default ChannelCell;
