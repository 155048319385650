import { useContext } from 'react';
import { FeeAmountType, FeeScopeType } from 'models/Quote';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { normalizeKey } from 'utils/localeUtils';
import { useFormContext } from 'react-hook-form';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import LeadModalContext from '../../../LeadModalContext';

interface OrderPropertyFeesProps {
  isEditable: boolean;
  order: HasOrderItems;
}

const propertyFeeSuffix = {
  [FeeScopeType.PER_STAY]: 'perStay',
  [FeeScopeType.PER_NIGHT]: 'perNight',
  [FeeScopeType.PER_GUEST]: 'perGuest',
  [FeeScopeType.PER_GUEST_PER_NIGHT]: 'perGuestperNight',
  [FeeScopeType.PERCENTAGE_OF_RENT]: 'percentageOfRent',
  [FeeScopeType.PERCENTAGE_OF_TOTAL]: 'percentageOfTotal',
  [FeeScopeType.PER_PET]: 'perPet',
  [FeeScopeType.PER_PET_PER_NIGHT]: 'perPetPerNight',
};

const OrderPropertyFees = ({ isEditable, order }: OrderPropertyFeesProps) => {
  const { propertyFees } = useContext(LeadModalContext);
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();

  const propertyFeesToUse = propertyFees;
  const formName = 'quote.fees.otherFees';

  return (
    <div className="form-group" data-testid="order-property-fees">
      {/*
       // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      {propertyFeesToUse.map((propertyFee, i) => {
        if (propertyFee.feeId !== null) {
          const value =
            propertyFee.amountType === FeeAmountType.TAX
              ? `${propertyFee.name} - ${propertyFee.amount}%`
              : `${propertyFee.name} - ${formatCurrency({
                  value: propertyFee.amount,
                  currency: order?.currency,
                })}`;

          return (
            <div key={`propertyFee_${propertyFee.name}`}>
              <CheckboxField
                disabled={!isEditable}
                checked={propertyFee.enabled}
                name={`fees[${i}].enabled`}
                onChange={(e) => {
                  const otherFees = getValues(formName);

                  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                  otherFees.map((_fee) => {
                    if (
                      _fee.feeId === propertyFee.feeId &&
                      _fee.name === propertyFee.name
                    ) {
                      _fee.enabled = (e.target as HTMLInputElement).checked;
                    }
                    return _fee;
                  });

                  setValue(formName, otherFees, {
                    shouldDirty: true,
                  });
                }}
              >
                {t(
                  normalizeKey(
                    `componentOrder.scope_${
                      propertyFeeSuffix[propertyFee.scope]
                    }`,
                  ),
                  {
                    amount: value,
                  },
                )}
              </CheckboxField>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default OrderPropertyFees;
