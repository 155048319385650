import styled, { css } from 'styled-components';
import { SidePanelDirection } from './SidePanel.types';

export const SidePanelContainerStyled = styled.div<{
  $isOpen: boolean;
  $direction: SidePanelDirection;
}>`
  position: fixed;
  top: 0;

  ${({ $direction }) => {
    if ($direction === SidePanelDirection.RIGHT_TO_LEFT) {
      return css`
        right: 0;
      `;
    }
    return css`
      left: 0;
    `;
  }}
  width: 85vw;
  max-width: 400px;
  height: 100vh;
  height: 100svh;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
  z-index: 1200;
  overflow-y: auto;
  opacity: 0;

  // slide from the right to the left
  /* transform: translateX(100%); */
  transform: ${({ $direction }) =>
    $direction === SidePanelDirection.RIGHT_TO_LEFT
      ? 'translateX(100%)'
      : 'translateX(-100%)'};
  transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;

  ${({ $isOpen, $direction }) =>
    $isOpen &&
    css`
      /* transform: translateX(0); */
      transform: ${$direction === SidePanelDirection.RIGHT_TO_LEFT
        ? 'translateX(0)'
        : 'translateX(0)'};
      opacity: 1;
    `}
`;

export const SidePanelOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1100;
`;
