import { ComponentPropsWithoutRef } from 'react';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';

interface PermissionsAwareFieldsetProps
  extends ComponentPropsWithoutRef<'fieldset'> {}

const PermissionsAwareFieldset = ({
  children,
  ...props
}: PermissionsAwareFieldsetProps) => {
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  return (
    <fieldset disabled={!propertiesUpdatingAllowed} {...props}>
      {children}
    </fieldset>
  );
};

export default PermissionsAwareFieldset;
