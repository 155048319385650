import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { LeadBookingCancellationOrigin } from 'models/Leads';
import FormWithProvider from 'components/forms/form/Form';
import TextField from '../../../../forms/textField/TextField';
import SelectField from '../../../../forms/selectField/SelectField';
import Button from '../../../../button/Button';

const cancelFormSchema = () => ({
  cancellationOrigin: Yup.mixed<LeadBookingCancellationOrigin>()
    .oneOf(Object.values(LeadBookingCancellationOrigin))
    .required(),
  reason: Yup.string().required(),
});

const cancelFormDefaultValues = {
  cancellationOrigin: LeadBookingCancellationOrigin.GUEST,
};

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const CancelVRBOLeadModalContent = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  const cancelOriginOptions = Object.values(LeadBookingCancellationOrigin).map(
    (origin: LeadBookingCancellationOrigin) => ({
      label: t(`componentLead.actionsInfo.vrbo.cancellationOrigin.${origin}`),
      value: origin,
    }),
  );

  return (
    <FormWithProvider
      defaultValues={cancelFormDefaultValues}
      onSubmit={onSubmit}
      schema={cancelFormSchema()}
    >
      <ModalBody>
        <Row>
          <Col>
            <SelectField
              label={t('componentLead.actionsInfo.vrbo.origin')}
              name="cancellationOrigin"
              options={cancelOriginOptions}
              colSmInput={8}
              colSmLabel={4}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t('componentLead.actionsInfo.vrbo.reason')}
              name="reason"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              required
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button bsStyle="danger" type="submit">
          {t('componentLead.actionsInfo.vrbo.cancelConfirm')}
        </Button>
      </ModalFooter>
    </FormWithProvider>
  );
};

export default CancelVRBOLeadModalContent;
