import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getSortParam } from 'components/table/utils/sorting';
import {
  DirectBookingReview,
  GetDirectBookingReviewsResponse,
} from 'models/Reviews';
import API from 'services/API';

export const PROPERTIES_REVIEWS_BASE_QUERY_KEY = 'property-reviews';

export const PROPERTIES_REVIEWS_SORTING: {
  [K in keyof DirectBookingReview]?: string;
} = {
  rating: 'SORT_BY_RATING',
  date: 'SORT_BY_DATE',
  author: 'SORT_BY_AUTHOR',
};

const getDirectBookingReviewsQuery = ({
  propertyUid,
  pageIndex,
  pageSize,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => {
  return {
    queryKey: [
      PROPERTIES_REVIEWS_BASE_QUERY_KEY,
      'list',
      propertyUid,
      { pageIndex, pageSize, sorting },
    ],
    queryFn: async (): Promise<ExpectedQueryData<DirectBookingReview>> => {
      const sort = getSortParam(sorting, PROPERTIES_REVIEWS_SORTING);

      const response = await API.get<GetDirectBookingReviewsResponse>(
        '/api/v3/reviews',
        {
          params: {
            source: 'HOSTFULLY',
            propertyUid,
            _limit: pageSize,
            ...(pageIndex > 0
              ? { _cursor: btoa(`{"offset":${pageIndex * pageSize}}`) }
              : {}),
            ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
          },
        },
      );

      const { reviews, _metadata } = response.data;

      return {
        _metadata,
        data: reviews,
      };
    },
    enabled: true,
  };
};

export default getDirectBookingReviewsQuery;
