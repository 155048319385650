import { LanguageCode } from './internationalization.types';

export function getLanguageCode(localeCode: string): LanguageCode {
  return (localeCode?.split(/[_|-]/)[0] as LanguageCode) || null;
}

export function getLanguageName(language: string, languageCode: LanguageCode) {
  const languageName = new Intl.DisplayNames([language], {
    type: 'language',
  }).of(languageCode);

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return languageName.charAt(0).toUpperCase() + languageName.slice(1);
}
