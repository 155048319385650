import { BaseResponse } from './_base';

export enum FeatureFlag {
  ALLOW_PROPERTY_EDIT_V3_BETA = 'ALLOW_PROPERTY_EDIT_V3_BETA',
  ALLOW_PROPERTIES_WEBAPP = 'ALLOW_PROPERTIES_WEBAPP',
  ALLOW_ORDER_VIEW_WEBAPP = 'ALLOW_ORDER_VIEW_WEBAPP',
}

export interface AppConfig {
  customSettings: AppCustomSettings;
  featureFlags: FeatureFlag[];
  intercomAppId?: string;
  showIntercom: boolean;
  showVersionNumber: boolean;
  hasAiEnabled: boolean;
}

export interface AppCustomSettings {
  customCssUrl?: string;
  customFooterContent?: string;
  customHeaderContent?: string;
  faviconUrl?: string;
  logoutRedirectUrl?: string;
  logoUrl?: string;
}

export type AppPublicCustomisations = Omit<
  AppCustomSettings,
  'logoutRedirectUrl'
>;

export interface GetAppConfigResponseTO extends BaseResponse {
  webAppConfig: AppConfig;
}

export interface GetAppPublicCustomisationsResponseTO {
  customSettings: AppPublicCustomisations;
  showVersionNumber: boolean;
}
