export enum RentalConditionObjectType {
  AGENCY = 'AGENCY',
  HOTEL = 'HOTEL',
  PROPERTY = 'PROPERTY',
}
export interface RentalConditions {
  objectUid: string;
  objectType: RentalConditionObjectType;
  inheritFromAgency: boolean;
  locale: string;
  rentalConditions?: string;
  rentalConditionsUrl?: string;
}
