import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { LeadV3Source } from 'models/LeadsV3';
import { LeadSource, LeadStatus } from 'models/Leads';

const leadFormSchema = (t: TFunction) => ({
  adultCount: Yup.number(),
  childrenCount: Yup.number(),
  checkInDate: Yup.date()
    .required()
    .typeError(t('form.validation.invalidDate')),
  checkOutDate: Yup.date()
    .required()
    .typeError(t('form.validation.invalidDate'))
    .min(
      Yup.ref('checkInDate'),
      t('componentLead.modal.form.validation.checkOutDateInvalid'),
    ),
  email: Yup.string()
    .email(t('form.validation.invalidEmail'))
    .typeError(t('form.validation.invalidEmail'))
    .when('source', {
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      is: (src) => src !== LeadSource.DIRECT_AIRBNB,
      then: Yup.string().required(t('form.validation.requiredEmail')),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  firstName: Yup.string(),
  lastName: Yup.string(),
  propertyUid: Yup.string(),
  source: Yup.mixed<LeadV3Source | LeadSource>().oneOf([
    ...Object.values(LeadV3Source),
    ...Object.values(LeadSource),
  ]),
  status: Yup.mixed<LeadStatus>().oneOf(Object.values(LeadStatus)),
  uid: Yup.string(),
  // TODO add other form values
});

export default leadFormSchema;
