import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import AirbnbWhiteIcon from 'assets/images/icons/channels/airbnb-white.svg?react';
import BookingIcon from 'assets/images/icons/channels/booking.svg?react';
import BookingWhiteIcon from 'assets/images/icons/channels/booking-white.svg?react';
import GoogleIcon from 'assets/images/icons/channels/google.svg?react';
import GoogleWhiteIcon from 'assets/images/icons/channels/google-white.svg?react';
import HoliduIcon from 'assets/images/icons/channels/holidu.svg?react';
import HoliduWhiteIcon from 'assets/images/icons/channels/holidu-white.svg?react';
import HomeToGoIcon from 'assets/images/icons/channels/hometogo.svg?react';
import HomeToGoWhiteIcon from 'assets/images/icons/channels/hometogo-white.svg?react';
import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg?react';
import HostfullyWhiteIcon from 'assets/images/icons/channels/hostfully-white.svg?react';
import HvmiIcon from 'assets/images/icons/channels/hvmi.svg?react';
import HvmiWhiteIcon from 'assets/images/icons/channels/hvmi-white.svg?react';
import TripAdvisorIcon from 'assets/images/icons/channels/tripadvisor.svg?react';
import TripAdvisorWhiteIcon from 'assets/images/icons/channels/tripadvisor-white.svg?react';
import VrboIcon from 'assets/images/icons/channels/vrbo.svg?react';
import VrboWhiteIcon from 'assets/images/icons/channels/vrbo-white.svg?react';
import RedAwningIcon from 'assets/images/icons/channels/RedAwning.svg?react';
import RedAwningWhiteIcon from 'assets/images/icons/channels/RedAwning-white.svg?react';
import { LeadChannel, LeadSource, LeadStatus } from '../../../models/Leads';
import { LeadChannelIconWrapper } from './LeadChannelIcon.styles';

export const channelToIconMapping = {
  [LeadChannel.AIRBNB]: <AirbnbIcon data-testid="channel-icon-airbnb" />,
  [LeadChannel.BOOKING_COM]: (
    <BookingIcon data-testid="channel-icon-bookingcom" />
  ),
  [LeadChannel.GOOGLE]: <GoogleIcon data-testid="channel-icon-google" />,
  [LeadChannel.HOLIDU]: <HoliduIcon data-testid="channel-icon-holidu" />,
  [LeadChannel.HOMETOGO]: <HomeToGoIcon data-testid="channel-icon-hometogo" />,
  [LeadChannel.HOSTFULLY]: (
    <HostfullyIcon data-testid="channel-icon-hostfully" />
  ),
  [LeadChannel.HVMI]: <HvmiIcon data-testid="channel-icon-hvmi" />,
  [LeadChannel.TRIP_ADVISOR]: (
    <TripAdvisorIcon data-testid="channel-icon-trip-advisor" />
  ),
  [LeadChannel.VRBO]: <VrboIcon data-testid="channel-icon-vrbo" />,
  [LeadChannel.REDAWNING]: (
    <RedAwningIcon data-testid="channel-icon-red-awning" />
  ),
};

const channelToWhiteIconMapping = {
  [LeadChannel.AIRBNB]: <AirbnbWhiteIcon width="20px" height="20px" />,
  [LeadChannel.BOOKING_COM]: <BookingWhiteIcon width="20px" height="20px" />,
  [LeadChannel.GOOGLE]: <GoogleWhiteIcon width="20px" height="20px" />,
  [LeadChannel.HOLIDU]: <HoliduWhiteIcon width="20px" height="20px" />,
  [LeadChannel.HOMETOGO]: <HomeToGoWhiteIcon width="20px" height="20px" />,
  [LeadChannel.HOSTFULLY]: <HostfullyWhiteIcon width="20px" height="20px" />,
  [LeadChannel.HVMI]: <HvmiWhiteIcon width="14px" height="20px" />,
  [LeadChannel.TRIP_ADVISOR]: (
    <TripAdvisorWhiteIcon width="20px" height="20px" />
  ),
  [LeadChannel.VRBO]: <VrboWhiteIcon width="20px" height="20px" />,
  [LeadChannel.REDAWNING]: <RedAwningWhiteIcon width="20px" height="20px" />,
};

const LeadChannelIcon = ({
  source,
  status,
  channel,
  whiteVersion,
}: {
  source?: LeadSource;
  status?: LeadStatus;
  channel: LeadChannel;
  whiteVersion?: boolean;
}) => {
  const icon = whiteVersion
    ? // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      channelToWhiteIconMapping[channel]
    : // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      channelToIconMapping[channel];

  if (!icon) {
    return null;
  }

  const forceFullOpacity = channel === LeadChannel.REDAWNING;

  return (
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <LeadChannelIconWrapper
      data-testid="lead-channel-icon"
      $leadStatus={status}
      $leadSource={source}
      fullOpacity={forceFullOpacity}
    >
      {icon}
    </LeadChannelIconWrapper>
  );
};

LeadChannelIcon.defaultProps = {
  status: LeadStatus.BOOKED_BY_AGENT,
  source: LeadSource.ORBIRENTAL_FORM,
  whiteVersion: false,
};

export default LeadChannelIcon;
