import styled from 'styled-components';
import {
  Nav as NavBS,
  Navbar as NavbarBS,
  NavItem as NavItemBS,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Button from 'components/button/Button';
import theme from 'styles/theme';

export const Container = styled.header`
  a {
    color: ${theme.colors.fontDark} !important;
  }

  .hidden-dropdown-close-element {
    display: none;
  }
`;

export const Nav = styled(NavBS)`
  display: flex;
  align-items: center;
  height: 64px;
`;

export const Navbar = styled(NavbarBS)`
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 2px;

  font-weight: 300;

  .dropdown-menu > li > a {
    font-weight: 300 !important;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;

    &::before,
    &::after {
      content: none;
      display: none;
    }
  }

  .navbar-brand {
    height: 50px;
    padding: 4px;
  }

  .dropdown {
    height: 64px;

    &:hover,
    &.active {
      ::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 4px;
        background-color: #d85b35;
        z-index: 1;
      }
    }

    a {
      background-color: #fff !important;
      height: 100%;
      display: flex !important;
      align-items: center;

      .caret {
        margin-left: 5px;
      }
    }
  }
`;

export const NavItemLink = styled(NavItemBS).attrs(({ href }) => ({
  // Navigation must be always performed with NavLink element from react-router in order to have the form's dirty checker working correctly.
  // That element also requires "to" attribute to properly render a clickable link.
  componentClass: NavLink,
  to: href,
}))``;

export const NavItem = styled(NavItemLink)<{ hasBadge?: boolean }>`
  height: 100%;
  display: flex !important;
  align-items: center;
  position: relative;

  font-weight: 300;

  &.active {
    background-color: #fff;

    a {
      background-color: inherit !important;
    }
  }

  &.active,
  &:hover {
    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 4px;
      background-color: #d85b35;
      z-index: 1;
    }
  }

  & > a {
    display: flex !important;
    align-items: center;
  }
`;

export const AddLeadButton = styled(Button)`
  i {
    margin-right: 5px;
    color: green;
  }

  font-weight: 300;

  margin-right: 24px;
`;
