import { useCallback } from 'react';

const leftShadowClassName = 'left-shadow';
const rightShadowClassName = 'right-shadow';

function toggleShadow(scrollableElement: HTMLElement) {
  const isScrolledToLeft = scrollableElement.scrollLeft === 0;
  const isScrolledToRight =
    scrollableElement.scrollLeft ===
    scrollableElement.scrollWidth - scrollableElement.clientWidth;

  if (isScrolledToLeft) {
    // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    scrollableElement.parentElement.classList.remove(leftShadowClassName);
  } else {
    // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    scrollableElement.parentElement.classList.add(leftShadowClassName);
  }

  if (isScrolledToRight) {
    // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    scrollableElement.parentElement.classList.remove(rightShadowClassName);
  } else {
    // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    scrollableElement.parentElement.classList.add(rightShadowClassName);
  }
}

const useWithScrollableShadow = (enabled: boolean = true) => {
  return useCallback(
    (scrollableWrapper: HTMLElement) => {
      if (!scrollableWrapper) {
        return;
      }

      scrollableWrapper.classList.add('with-scrollable-shadow');

      if (!enabled) {
        // remove previously applied shadows when e.g. resizing the window
        scrollableWrapper.classList.remove(
          leftShadowClassName,
          rightShadowClassName,
        );

        return;
      }

      const scrollListener = (event: Event) => {
        toggleShadow(event.target as HTMLElement);
      };

      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      scrollableWrapper.firstChild.addEventListener('scroll', scrollListener);

      // apply shadow on mount if needed
      toggleShadow(scrollableWrapper.firstChild as HTMLElement);
    },
    [enabled],
  );
};

export default useWithScrollableShadow;
