import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { DESCRIPTIONS_BASE_QUERY_KEY } from 'components/domain/property/useFetchDescriptions';
import useUpdateDescriptionMutation from 'components/domain/property/useUpdateDescriptionMutation';
import { FormContextParts } from 'components/forms/form/Form.types';
import { Property } from 'models/Properties';
import { TFunction } from 'i18next';
import { Description } from 'models/Descriptions';
import { PropertySettingsDescriptionsTabFormValues } from './PropertySettingsDescriptionsTab.schema';

const handleUpdateSuccess = async ({
  descriptionFields,
  notifySuccess,
  queryClient,
  propertyUid,
  currentLanguage,
  reset,
  t,
}: {
  descriptionFields: PropertySettingsDescriptionsTabFormValues;
  propertyUid: string;
  queryClient: QueryClient;
  currentLanguage: string;
  reset: (values: PropertySettingsDescriptionsTabFormValues) => void;
  notifySuccess: (message: string) => void;
  t: TFunction;
}) => {
  const query = [DESCRIPTIONS_BASE_QUERY_KEY, 'list', propertyUid];

  const cachedDescriptions = queryClient.getQueryData<Description[]>(query);

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const updatedDescriptions = cachedDescriptions.map((description) => {
    if (description.locale === currentLanguage) {
      return descriptionFields;
    }

    return description;
  });

  queryClient.setQueryData(query, updatedDescriptions);

  const updatedFormValues = updatedDescriptions.find(
    ({ locale }) => locale === currentLanguage,
  );

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  reset(updatedFormValues);

  notifySuccess(t('pageProperty.descriptions.success'));
};

const useUpdateDescription = ({
  currentLanguage,
  propertyUid,
}: {
  currentLanguage: string;
  propertyUid: Property['uid'];
}) => {
  const { notifyError, notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { mutateAsync: doUpdateDescription } = useUpdateDescriptionMutation();
  const queryClient = useQueryClient();

  const { mutateAsync: updateDescription, isLoading: isUpdating } =
    useAppMutation(
      ({
        descriptionFields,
      }: {
        descriptionFields: PropertySettingsDescriptionsTabFormValues;
        reset: (values: PropertySettingsDescriptionsTabFormValues) => void;
      }) => {
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        return doUpdateDescription({
          propertyUid,
          locale: currentLanguage,
          ...descriptionFields,
        });
      },
      {
        onSuccess: (_, { reset, descriptionFields }) => {
          handleUpdateSuccess({
            descriptionFields,
            notifySuccess,
            queryClient,
            propertyUid,
            currentLanguage,
            reset,
            t,
          });
        },
        onError: (error: any) => {
          notifyError(
            getErrorMessage({
              apiErrorCode: error.response.data?.apiErrorCode,
              apiErrorMessage: error.response.data?.apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
        },
      },
    );

  const handleUpdateDescriptionSubmit = async (
    values: PropertySettingsDescriptionsTabFormValues,
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    _,
    { reset }: FormContextParts<PropertySettingsDescriptionsTabFormValues>,
  ) => {
    await updateDescription({
      descriptionFields: values,
      reset,
    });
  };

  return {
    handleUpdateDescriptionSubmit,
    isUpdating,
  };
};

export default useUpdateDescription;
