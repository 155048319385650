import useFeatures from 'hooks/useFeatures';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import { Trans } from 'react-i18next';
import PropertySettingsBetaAccessInfo from '../PropertySettingsBetaAccessInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import UnitTypeList from './list/UnitTypeList';
import UnitTypeSearch from './list/UnitTypeSearch';

const PropertySettingsUnitTypesTab = () => {
  const { property } = usePropertySettingsInitData();
  const { allowPropertyEditV3Beta } = useFeatures();

  if (allowPropertyEditV3Beta) {
    return <PropertySettingsBetaAccessInfo />;
  }

  return (
    <FormWithProvider onSubmit={() => {}}>
      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.unitTypes.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        buttons={<UnitTypeSearch />}
        noDivider
      />
      <UnitTypeList unitTypes={property?.unitTypes ?? []} />
    </FormWithProvider>
  );
};

export default PropertySettingsUnitTypesTab;
