import { useMemo, useState } from 'react';
import { Lead } from '../../../../models/Leads';
import AdjustmentModalContext from './AdjustmentModalContext';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const AdjustmentModalContextProvider = ({ children, modalId }) => {
  const [lead, setLead] = useState<Lead>();
  const [isARefund, setIsARefund] = useState<boolean>();

  const contextValue = useMemo(
    () => ({
      lead,
      modalId,
      setLead,
      isARefund,
      setIsARefund,
    }),
    [lead, isARefund, modalId],
  );

  return (
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <AdjustmentModalContext.Provider value={contextValue}>
      {children}
    </AdjustmentModalContext.Provider>
  );
};

export default AdjustmentModalContextProvider;
