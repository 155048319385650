/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import useProfileAvatar from 'hooks/useProfileAvatar';
import TextField from 'components/forms/textField/TextField';
import Button from 'components/button/Button';
import PhoneNumberField from 'components/forms/phoneNumberField/PhoneNumberField';
import useAppModal from 'hooks/useAppModal';
import PasswordModal from 'components/domain/user/passwordModal/PasswordModal';
import {
  Actions,
  ErrorMessage,
  FormFieldsContainer,
  LabelText,
  ProfilePicture,
  ProfileUpload,
  RoleStaticValue,
} from './Account.styles';

const EmployeeAccountForm = ({
  isLoading,
  isError,
  refetch,
}: {
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { openModal } = useAppModal();
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const { pictureUrl, firstName, lastName, role, picture, phoneNumber } =
    useWatch();
  const avatar = useProfileAvatar({
    pictureUrl: picture ? URL.createObjectURL(picture[0]) : pictureUrl,
    firstName,
    lastName,
  });

  const handleClickUploadPicture = () => {
    inputFileRef.current?.click();
  };

  const handleOpenChangePasswordModal = () => {
    const modalId = 'change-password-modal';
    openModal({
      title: t('pageOwnerAccount.changePassword'),
      customContent: <PasswordModal modalId={modalId} />,
      id: modalId,
    });
  };

  const shouldAllowCancel =
    picture ||
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    firstName !== defaultValues.firstName ||
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    lastName !== defaultValues.lastName ||
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    phoneNumber !== defaultValues.phoneNumber;

  return (
    <FormFieldsContainer>
      <TextField
        colSmInput={9}
        colSmLabel={3}
        name="firstName"
        label={t('pageAccount.firstName')}
      />

      <TextField
        colSmInput={9}
        colSmLabel={3}
        name="lastName"
        label={t('pageAccount.lastName')}
      />

      <TextField
        colSmInput={9}
        colSmLabel={3}
        name="email"
        label={t('pageAccount.email')}
        disabled
      />

      <div className="form-group">
        <LabelText>{t('pageAccount.password')}</LabelText>
        <div className="col-xs-10 col-sm-9">
          <Button
            className="btn-danger"
            id="password"
            onClick={handleOpenChangePasswordModal}
            disabled={isLoading}
          >
            {t('pageAccount.changePassword')}
          </Button>
        </div>
      </div>

      <div className="form-group">
        <LabelText>{t('pageAccount.profilePicture')}</LabelText>
        <ProfileUpload>
          <ProfilePicture>{avatar}</ProfilePicture>
          <Button
            id="upload-picture"
            disabled={isLoading}
            onClick={handleClickUploadPicture}
          >
            <Glyphicon glyph="upload" />
            {t('pageAccount.upload')}
          </Button>
          <input
            id="upload-profile-picture"
            type="file"
            name="picture"
            accept="image/*"
            onChange={(e) => {
              setValue('picture', e.target.files);
            }}
            ref={inputFileRef}
          />
        </ProfileUpload>
      </div>

      <div className="form-group">
        <LabelText>{t('pageAccount.role')}</LabelText>
        <RoleStaticValue>
          <strong>{role}</strong>
        </RoleStaticValue>
      </div>

      <PhoneNumberField
        colSmInput={9}
        colSmLabel={3}
        name="phoneNumber"
        label={t('pageAccount.phoneNumber')}
      />

      {isError && (
        <ErrorMessage>{t('pageAccount.errorWhileSubmitting')}</ErrorMessage>
      )}

      <Actions>
        <Button
          className="btn-default"
          data-testid="cancel-account-edit"
          onClick={refetch}
          disabled={isLoading || !shouldAllowCancel}
        >
          {t('pageAccount.cancel')}
        </Button>
        <Button className="btn-primary" type="submit" disabled={isLoading}>
          {t('pageAccount.submit')}
        </Button>
      </Actions>
    </FormFieldsContainer>
  );
};

export default EmployeeAccountForm;
