import { useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import { Col, Row } from 'react-bootstrap';
import FormWithProvider from 'components/forms/form/Form';
import useAnalyticsInit from './useAnalyticsInit';
import AnalyticsFiltersWrapper from './filters/AnalyticsFiltersWrapper';
import { ChartsAndPiesWrapper } from './Analytics.styles';
import AnalyticsList from './cards/AnalyticsList';
import ChartsFilters from './charts/filters/ChartsFilters';
import ChartsWrapper from './charts/ChartsWrapper';
import ChannelPieChart from './charts/ChannelPieChart';
import RevParPieChart from './charts/RevParPieChart';
import ChartsFilterFormWatcher from './charts/ChartsFilterFormWatcher';

const AnalyticsWrapper = () => {
  const { t } = useTranslation();
  const { isLoading, chartFormSchema, chartFormDefaultValues } =
    useAnalyticsInit();
  return (
    <FormWithProvider
      defaultValues={chartFormDefaultValues}
      onSubmit={() => {}}
      schema={chartFormSchema()}
    >
      <PageHeader
        title={t('pageAnalytics.analyticsHeader')}
        // help={
        //   !isLoading
        //     ? t('pageAnalytics.lastUpdateDate', {
        //         // TODO Shouldn’t this use localised format like Pp which could be added to src/i18n/dateTimeInternationalization.ts#tryToFormatDateTime
        //         formattedDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        //       })
        //     : ''
        // }
      />
      {/*
       // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <AnalyticsFiltersWrapper isLoading={isLoading} />
      <Row>
        <AnalyticsList />
      </Row>
      <ChartsAndPiesWrapper>
        <Col data-testid="charts-container" md={7} xs={12}>
          <ChartsFilters />
          <ChartsFilterFormWatcher />
          <ChartsWrapper />
        </Col>
        <Col mdOffset={1} md={4} xs={12}>
          <ChannelPieChart />
          <RevParPieChart />
        </Col>
      </ChartsAndPiesWrapper>
    </FormWithProvider>
  );
};

export default AnalyticsWrapper;
