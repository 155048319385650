import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import PermissionsAwareFieldset from 'pages/property/common/PermissionsAwareFieldset';
import { useTranslation } from 'react-i18next';
import { AirbnbGuestReview } from 'models/Reviews';
import { shouldAllowRespond } from '../GuestReviews.utils';
import useViewAirbnbGuestReviewModal from '../modals/useViewAirbnbGuestReviewModal';

const ActionsCell = ({ row }: { row: Row<AirbnbGuestReview> }) => {
  const { t } = useTranslation();
  const { openViewRespondModal } = useViewAirbnbGuestReviewModal();

  const handleClick = () => {
    openViewRespondModal(row.original);
  };

  const allowRespond = shouldAllowRespond(row.original);

  return (
    <PermissionsAwareFieldset>
      <PropertyEditActionWrapper tooltipId="guest-reviews-actions">
        {allowRespond ? (
          <Button
            bsStyle="link"
            data-testid="row-view-and-respond-guest-review-button"
            onClick={handleClick}
          >
            {t(
              'pageProperty.reviews.airbnbGuest.guestReviews.table.viewAndRespond',
            )}
          </Button>
        ) : (
          <Button
            bsStyle="link"
            data-testid="row-view-details-guest-review-button"
            onClick={handleClick}
          >
            {t(
              'pageProperty.reviews.airbnbGuest.guestReviews.table.viewDetails',
            )}
          </Button>
        )}
      </PropertyEditActionWrapper>
    </PermissionsAwareFieldset>
  );
};

export default ActionsCell;
