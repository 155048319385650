import { BaseResponse } from './_base';

export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  UNCAPTURED = 'UNCAPTURED',
}
export enum TransactionType {
  SALE = 'SALE',
  REFUND = 'REFUND',
  SECURITY_DEPOSIT_CHARGE = 'SECURITY_DEPOSIT_CHARGE',
}

export interface Transaction {
  amount: number;
  autoRefundDate?: Date;
  autoRefundUtcDateTime?: string;
  createdDate: Date;
  createdUtcDateTime: string;
  errorMessage?: string;
  manual?: boolean;
  notes?: string;
  refundable?: boolean;
  refundableAmount?: number;
  status: TransactionStatus;
  transactionId?: string;
  type: TransactionType;
  uid: string;
  // add other fields when required
}

export interface ManualTransactionCreationPayload {
  orderUid: string;
  status: TransactionStatus;
  type: TransactionType;
  amount?: number;
  created?: string;
  fullPayment?: boolean;
  notes?: String;
}

export interface CreateTransactionResponseTO extends BaseResponse {
  transaction: Transaction;
}

export interface CancelScheduledAutoRefundResponseTO extends BaseResponse {
  transaction: Transaction;
}

export interface CreateRefundResponseTO extends BaseResponse {
  transaction: Transaction;
}

export interface RefundCreationPayload {
  amount: number;
  markSecurityDepositAsReturned?: boolean;
}

export interface CreateSecurityDepositChargeResponseTO extends BaseResponse {
  transaction: Transaction;
}

export interface SecurityDepositChargeCreationPayload {
  amount: number;
  orderUid: string;
}
