import { useContext, useLayoutEffect, useRef, useState } from 'react';
import InboxContext from '../InboxContext';
import useThreadParticipantBestProfile from '../threads/useThreadParticipantBestProfile';
import { useFetchInboxThreadRelatedObjects } from '../threadRelatedObjects/useFetchInboxThreadRelatedObjects';
import { ThreadRelatedPropertyBody } from '../threadRelatedObjects/ThreadRelatedPropertyBody';
import InboxLeadsContext from './InboxLeadsContext';
import useInboxActiveLead from './useInboxActiveLead';
import { LeadGuestName, LeadSelectorContainer } from './InboxLead.styles';
import InboxLeadSelector from './InboxLeadSelector';
import InboxActiveLead from './InboxActiveLead';
import InboxLeadLoader from './InboxLeadLoader';
import InboxActiveLeadLoader from './InboxActiveLeadLoader';

const InboxLeadBody = () => {
  const { isLoading, isLoadingLead } = useInboxActiveLead();
  const { activeThread } = useContext(InboxContext);
  const { threadRelatedLeadsMap, isThreadRelatedPropertyExists } =
    useContext(InboxLeadsContext);
  const { isLoading: isLoadingThreadObjects, property: threadRelatedProperty } =
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    useFetchInboxThreadRelatedObjects(activeThread?.uid);
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const bestProfile = useThreadParticipantBestProfile(activeThread?.uid);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerRef = useRef(null);

  const { uid: threadUid } = activeThread || {};
  const leads = !!threadUid && threadRelatedLeadsMap[threadUid];
  const hasLeads = !!(leads && leads.length);
  const hasThreadRelatedProperty = isThreadRelatedPropertyExists(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    activeThread?.uid,
  );
  useLayoutEffect(() => {
    if (containerRef?.current) {
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [hasLeads, hasThreadRelatedProperty]);

  if (isLoading) {
    return <InboxLeadLoader />;
  }
  const canDrawThreadRelatedProperty =
    !isLoadingLead &&
    !hasLeads &&
    !isLoadingThreadObjects &&
    !!threadRelatedProperty;
  return (
    <>
      {(hasLeads || hasThreadRelatedProperty) && (
        <LeadSelectorContainer ref={containerRef} $width={containerWidth}>
          <LeadGuestName>{bestProfile?.name}</LeadGuestName>
          <InboxLeadSelector />
        </LeadSelectorContainer>
      )}
      {isLoadingLead ? <InboxActiveLeadLoader /> : <InboxActiveLead />}
      {canDrawThreadRelatedProperty && (
        <ThreadRelatedPropertyBody model={threadRelatedProperty} />
      )}
    </>
  );
};

export default InboxLeadBody;
