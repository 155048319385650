import { useContext } from 'react';
import { isAfter, isBefore, isSameDay, isWeekend, parse } from 'date-fns';
import useAppDispatch from 'hooks/useAppDispatch';
import { Job } from 'models/Jobs';
import { updatePropertiesEntries } from 'store/slices/stackedCalendar';
import { Lead, LeadStatus } from '../../models/Leads';
import { PropertyBusinessType } from '../../models/Properties';
import {
  getLeadCheckInDate,
  getLeadCheckOutDate,
  isFromICS,
} from '../../utils/lead/leadUtils';
import { getJobStartDate } from '../../utils/job/jobUtils';
import CalendarContext from './CalendarContext';
import { CalendarDayCellsData } from './Calendar.types';
import { propertiesBusinessType } from './Calendar.constants';

const getLeadUids = (leads: Lead[], propertyUid: string, dayDate: Date) =>
  leads
    .filter((lead) => {
      if (lead.unitUid && lead.unitUid !== propertyUid) {
        return false;
      }

      if (!lead.unitUid && lead.property.uid !== propertyUid) {
        return false;
      }

      const leadCheckInDate = getLeadCheckInDate(lead);
      const leadCheckOutDate = getLeadCheckOutDate(lead);

      // it should be between checkInDate and checkOutDate (inclusive)
      return (
        isSameDay(dayDate, leadCheckInDate) ||
        isSameDay(dayDate, leadCheckOutDate) ||
        (isAfter(dayDate, leadCheckInDate) &&
          isBefore(dayDate, leadCheckOutDate))
      );
    })
    // Reorder leads PMPK-4673
    .sort((a, b) => {
      if (a.status === LeadStatus.BLOCKED) return -1; // blocked leads first, so they are displayed behind bookings
      if (isFromICS(a.source)) return 1;
      if (isFromICS(b.source)) return -1;
      return 0;
    })
    .map(({ uid }) => uid);

const getJobsUids = (jobs: Job[], propertyUid: string, dayDate: Date) =>
  jobs
    .filter((job) => {
      if (job.unitUid && job.unitUid !== propertyUid) {
        return false;
      }

      if (!job.unitUid && job.propertyUid !== propertyUid) {
        return false;
      }

      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      return isSameDay(getJobStartDate(job), dayDate);
    })
    .map(({ uid }) => uid);

const useGetCalendarDataProcess = () => {
  const dispatch = useAppDispatch();
  const { jobsMap, leadsMap } = useContext(CalendarContext);

  return ([properties, leads, jobs]: CalendarDayCellsData) => {
    const datesMap = {};
    const referenceDate = new Date();
    const getDayDate = (dateStr: string) => {
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      if (!datesMap[dateStr]) {
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        datesMap[dateStr] = parse(dateStr, 'yyyy-MM-dd', referenceDate);
      }
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      return datesMap[dateStr];
    };

    Object.assign(
      jobsMap,
      jobs.reduce((acc, job) => ({ ...acc, [job.uid]: job }), {}),
    );

    Object.assign(
      leadsMap,
      leads.reduce((acc, lead) => ({ ...acc, [lead.uid]: lead }), {}),
    );

    const updatedEntries = {};

    // those are properties but not units:
    properties.forEach((property) => {
      const { entries, propertyUid } = property;

      const newDayEntries = entries.reduce((acc, entry) => {
        const dayDate = getDayDate(entry.date);
        const isGrayedOut =
          propertiesBusinessType[propertyUid]?.type ===
          PropertyBusinessType.HOTEL;

        const leadsUids = getLeadUids(leads, propertyUid, dayDate);
        const jobsUids = getJobsUids(jobs, propertyUid, dayDate);

        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        acc[entry.date] = {
          dayDate,
          entry,
          isEmpty: false,
          isGrayedOut,
          isLoading: false,
          isWeekend: isWeekend(dayDate),
          leads: leadsUids,
          jobs: jobsUids,
        };
        return acc;
      }, {});

      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const existingDayEntries = updatedEntries[propertyUid];
      if (existingDayEntries) {
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        updatedEntries[propertyUid] = {
          ...existingDayEntries,
          ...newDayEntries,
        };
      } else {
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        updatedEntries[propertyUid] = newDayEntries;
      }
    });

    const unitPropertyUids = Object.keys(propertiesBusinessType).filter(
      (key) => propertiesBusinessType[key]?.type === PropertyBusinessType.UNIT,
    );

    // the same as above but for units:
    unitPropertyUids.forEach((unitPropertyUid) => {
      Object.keys(datesMap).forEach((key) => {
        // units doesn't have entries, so we need to get them from the unit type:
        const unitTypeUid = propertiesBusinessType[unitPropertyUid].parent;
        // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const unitTypeEntries = updatedEntries[unitTypeUid];
        const unitTypeDayEntry = unitTypeEntries?.[key];

        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const dayDate = datesMap[key];
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const existingDayEntries = updatedEntries[unitPropertyUid];

        const leadsUids = getLeadUids(leads, unitPropertyUid, dayDate);
        const jobsUids = getJobsUids(jobs, unitPropertyUid, dayDate);

        if (existingDayEntries) {
          // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          updatedEntries[unitPropertyUid] = {
            ...existingDayEntries,
            [key]: {
              entry: unitTypeDayEntry?.entry,
              dayDate,
              isEmpty: true,
              isLoading: false,
              leads: leadsUids,
              jobs: jobsUids,
              isWeekend: isWeekend(dayDate),
            },
          };
        } else {
          // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          updatedEntries[unitPropertyUid] = {
            [key]: {
              entry: unitTypeDayEntry?.entry,
              dayDate,
              isEmpty: true,
              isLoading: false,
              leads: leadsUids,
              jobs: jobsUids,
              isWeekend: isWeekend(dayDate),
            },
          };
        }
      });
    });

    dispatch(updatePropertiesEntries(updatedEntries));
  };
};

export default useGetCalendarDataProcess;
