import { useTranslation } from 'react-i18next';
import { Photo } from 'models/Photos';
import CheckIcon from 'assets/images/icons/check.svg?react';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import { useSelectModeContext } from 'pages/property/photos/context/SelectModeContext';
import Loader from 'components/loader/Loader';
import {
  PhotoCardContainer,
  PhotoCoverBadge,
  PhotoImage,
  PhotoSelectedIndicator,
  PhotoUploadOverlay,
} from '../PhotoGrid.styles';
import PhotoActions from './PhotoActions';
import PhotoDescription from './PhotoDescription';
import usePhotoActions from './usePhotoActions';

interface PhotoCardProps {
  photo: Photo;
  isCover: boolean;
}

const PhotoCard = ({ photo, isCover }: PhotoCardProps) => {
  const { isSelectMode, toggleSelectPhoto, getPhotoSelectionStatus } =
    useSelectModeContext();
  const { handleEditPhoto } = usePhotoActions(photo);
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();
  const { t } = useTranslation();

  const handlePhotoClick = () => {
    if (isSelectMode) {
      toggleSelectPhoto(photo.uid);
    } else {
      handleEditPhoto();
    }
  };

  const isSelected = getPhotoSelectionStatus(photo.uid);
  const isUploading = photo.uid?.startsWith('temp-') ?? false;

  return (
    <PhotoCardContainer
      data-testid={`photo-card-${photo.uid}`}
      $isCover={isCover}
      $isDisabled={!propertiesUpdatingAllowed}
      $isSelectMode={isSelectMode}
      $isSelected={isSelected}
    >
      <div className="photo-card-content">
        <PhotoImage
          src={
            photo.mediumThumbnailScaleImageUrl ||
            photo.largeThumbnailScaleImageUrl
          }
          alt={photo.description}
          onClick={propertiesUpdatingAllowed ? handlePhotoClick : undefined}
        />
        {!isSelectMode && !isUploading && <PhotoActions photo={photo} />}
        {isSelected && (
          <PhotoSelectedIndicator data-testid="photo-card-selected-indicator">
            <CheckIcon />
          </PhotoSelectedIndicator>
        )}
        {isCover && (
          <PhotoCoverBadge data-testid="photo-card-cover-badge">
            {t('pageProperty.photos.cover')}
          </PhotoCoverBadge>
        )}
        {isUploading && (
          <PhotoUploadOverlay>
            <Loader inline />
          </PhotoUploadOverlay>
        )}
      </div>

      <PhotoDescription
        key={`${photo.description}-${photo.uid}`}
        description={photo.description}
        photoUid={photo.uid}
      />
    </PhotoCardContainer>
  );
};

export default PhotoCard;
