import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import TextField from 'components/forms/textField/TextField';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { PropertySettingsPricingTabFormValues } from '../../PropertySettingsPricingTab.schema';
import { TaxFieldColStyled, TaxFieldRowStyled } from './PriceSettings.styles';

const PriceSettingsTaxRateField = () => {
  const { t } = useTranslation();
  const isTaxLongTermStayExemption =
    useWatch<PropertySettingsPricingTabFormValues>({
      name: 'priceSettings.isTaxLongTermStayExemption',
    });

  return (
    <>
      <TextField
        label={
          <PropertyFormFieldLabel name="priceSettings.taxRate">
            {t('pageProperty.pricing.priceSettings.taxRate')}
          </PropertyFormFieldLabel>
        }
        name="priceSettings.taxRate"
        colSmInput={7}
        colSmLabel={5}
        rightAddons={['%']}
        type="number"
        min={0}
        max={100}
        rightTooltip={t('pageProperty.pricing.priceSettings.taxRateTooltip')}
      />

      <TaxFieldRowStyled>
        <TaxFieldColStyled xsOffset={4} smOffset={5}>
          <CheckboxField name="priceSettings.isTaxLongTermStayExemption">
            {t('pageProperty.pricing.priceSettings.isTaxLongTermStayExemption')}
          </CheckboxField>
          {isTaxLongTermStayExemption && (
            <TextField
              label={
                <PropertyFormFieldLabel name="priceSettings.taxLongTermStayExemptionDays">
                  {t(
                    'pageProperty.pricing.priceSettings.taxLongTermStayExemptionDays',
                  )}
                  :
                </PropertyFormFieldLabel>
              }
              name="priceSettings.taxLongTermStayExemptionDays"
              colSmInput={12}
              colSmLabel={12}
              labelOnTop
              min={0}
              rightAddons={[t('common.nights')]}
              rightTooltip={t(
                'pageProperty.pricing.priceSettings.taxLongTermStayExemptionDaysTooltip',
              )}
              type="number"
            />
          )}
        </TaxFieldColStyled>
      </TaxFieldRowStyled>
    </>
  );
};

export default PriceSettingsTaxRateField;
