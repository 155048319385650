import { useContext, useEffect, useRef } from 'react';
import { createThreadRelatedLeadFromLead } from 'utils/lead/leadUtils';
import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import {
  GetThreadParticipantBestProfileResponseTO,
  GetThreadRelatedLeadsResponseTO,
  ThreadParticipantType,
  ThreadRelatedLead,
} from 'models/inbox/Threads';
import { Lead } from 'models/Leads';
import useSearchURLParams, { URLParamsName } from 'hooks/useSearchURLParams';
import useAppEvent from 'hooks/useAppEvent';
import { useQueryClient } from '@tanstack/react-query';
import { AppEventType } from 'models/Events';
import { getThreadInterlocutorUid } from '../threads/useInboxThreads';
import InboxContext from '../InboxContext';
import InboxLeadsContext from './InboxLeadsContext';
import useInboxLeadMissingProperties from './useInboxLeadMissingProperties';

const useInboxActiveLead = () => {
  const wasActiveLeadUpdated = useRef(false);
  const { subscribe, unsubscribe } = useAppEvent();
  const queryClient = useQueryClient();
  const { agencyUid, userType } = useAppUser();
  const {
    activeThread,
    activeThreadLeadUid,
    updateActiveLead,
    activeLead,
    updateThreadParticipantBestProfilesMap,
    updateActiveMessageThreadUid,
  } = useContext(InboxContext);
  const {
    leadsMap,
    selectedLeadUid,
    threadRelatedLeadsMap,
    setSelectedLeadUid,
    updateLeadsMap,
    updateThreadRelatedLeadsMap,
  } = useContext(InboxLeadsContext);
  const fetchMissingProperties = useInboxLeadMissingProperties();
  const { setParameter, deleteParameter } = useSearchURLParams();
  const { uid: activeThreadUid } = activeThread || {};

  const threadRelatedLeads = activeThreadUid
    ? threadRelatedLeadsMap[activeThreadUid]
    : null;

  const {
    data: fetchedThreadRelatedLeads,
    isInitialLoading: isThreadRelatedLeadsLoading,
  } = useAppQuery(
    ['threadRelatedLeads', activeThreadUid],
    async (): Promise<ThreadRelatedLead[]> => {
      const threadLeadsResponse =
        await API.get<GetThreadRelatedLeadsResponseTO>(
          '/v3/thread-related-leads',
          {
            params: {
              limit: 20,
              threadUid: activeThreadUid,
            },
          },
        );
      return threadLeadsResponse.data.threadRelatedLeads;
    },
    {
      enabled: !!activeThreadUid && !threadRelatedLeads,
    },
  );

  const { data: fetchedLead, isInitialLoading: isActiveLeadLoading } =
    useAppQuery(
      ['activeLead', selectedLeadUid],
      async (): Promise<Lead> => {
        const leadsResponse = await API.post('/v3/graphql', {
          // for all the available fields check com.orbirental.api.v3.TOs.graphql.LeadGraphQLApiTO
          query: `{leads (agencyUid:"${agencyUid}",uids:["${selectedLeadUid}"]){
            uid,
            adultCount,
            agreementSigned,
            assignee{bestProfile, uid},
            availablePipelineActions,
            channel{bestProfile, channel},
            checkInLocalDateTime,
            checkOutLocalDateTime,
            childrenCount,
            countdown{color, countdownMinutes, countdownPercentage, visualCountdownValue},
            email,
            hasCompletedPreArrivalForm,
            infantCount,
            order{currency, totalAmount},
            petCount,
            property{uid},
            source,
            status,
            wereCheckInInstructionsSent
          }}`,
          variables: {},
        });

        wasActiveLeadUpdated.current = false;

        return leadsResponse?.data?.data?.leads?.length
          ? leadsResponse.data.data.leads[0]
          : null;
      },
      {
        enabled:
          (!!selectedLeadUid &&
            !!threadRelatedLeads &&
            !leadsMap[selectedLeadUid]) ||
          wasActiveLeadUpdated.current,
      },
    );

  useEffect(() => {
    const matchedLeadUid =
      activeThreadLeadUid ||
      activeThread?.participants?.find(
        (p) => p.participantType === ThreadParticipantType.LEAD,
      )?.participantUid ||
      (threadRelatedLeads?.length && threadRelatedLeads[0].leadUid);

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setSelectedLeadUid(matchedLeadUid);
  }, [threadRelatedLeads]);

  useEffect(() => {
    if (selectedLeadUid) {
      updateActiveLead(leadsMap[selectedLeadUid]);
      setParameter(URLParamsName.LEAD_UID, selectedLeadUid);
    } else {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateActiveLead(null);
      deleteParameter(URLParamsName.LEAD_UID);
    }
  }, [selectedLeadUid]);

  useEffect(() => {
    if (activeLead) {
      if (isThreadRelatedLeadsLoading || isActiveLeadLoading) return;

      // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const leadRelatedThread = threadRelatedLeadsMap[activeThreadUid]?.find(
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        (a) => a.leadUid === activeLead.uid,
      );

      if (leadRelatedThread) {
        updateActiveMessageThreadUid(leadRelatedThread.threadUid);
      }
      // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    } else if (threadRelatedLeadsMap[activeThreadUid]?.length === 0) {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      updateActiveMessageThreadUid(activeThreadUid);
    }
  }, [
    activeLead,
    activeThreadUid,
    threadRelatedLeadsMap,
    isThreadRelatedLeadsLoading,
    isActiveLeadLoading,
  ]);

  useEffect(() => {
    if (fetchedThreadRelatedLeads) {
      updateThreadRelatedLeadsMap({
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        threadUid: activeThreadUid,
        leads: fetchedThreadRelatedLeads,
      });

      fetchMissingProperties(fetchedThreadRelatedLeads);
    }
  }, [fetchedThreadRelatedLeads]);

  useEffect(() => {
    if (fetchedLead) {
      updateLeadsMap(fetchedLead);

      // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const threadLeads = threadRelatedLeadsMap[activeThreadUid];
      if (threadLeads) {
        const { uid } = fetchedLead;
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        const threadLead = threadLeads.find(({ leadUid }) => leadUid === uid);

        if (!threadLead) {
          updateThreadRelatedLeadsMap({
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            threadUid: activeThreadUid,
            leads: [
              ...threadLeads,
              // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              createThreadRelatedLeadFromLead(fetchedLead, activeThreadUid),
            ],
          });
        }
      } else {
        updateThreadRelatedLeadsMap({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          threadUid: activeThreadUid,
          leads: [
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            createThreadRelatedLeadFromLead(fetchedLead, activeThreadUid),
          ],
        });
      }

      updateActiveLead(fetchedLead);
    }
  }, [fetchedLead]);

  useEffect(() => {
    const refetchLead = async () => {
      wasActiveLeadUpdated.current = true;

      const interlocutorUid = getThreadInterlocutorUid({
        userType,
        thread: activeThread,
      });

      const response = await API.get<GetThreadParticipantBestProfileResponseTO>(
        `/v3/thread-participant-best-profile/${interlocutorUid}`,
      );

      updateThreadParticipantBestProfilesMap({
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        [activeThread.uid]: response.data.bestProfile,
      });

      queryClient.invalidateQueries(['activeLead', selectedLeadUid]);
    };

    subscribe(AppEventType.LEAD_UPDATED, refetchLead);

    return () => {
      unsubscribe(AppEventType.LEAD_UPDATED, refetchLead);
    };
  }, [selectedLeadUid, activeThread]);

  return {
    isLoading: !!activeThreadUid && isThreadRelatedLeadsLoading,
    isLoadingLead: !!activeThreadUid && isActiveLeadLoading,
  };
};

export default useInboxActiveLead;
