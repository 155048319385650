import Collapse from 'components/collapse/Collapse';
import styled from 'styled-components';

export const CollapseSectionStyled = styled(Collapse)`
  .collapsible-body {
    padding: 16px 20px;
  }

  textarea {
    resize: vertical;
  }
`;

export const DescriptionStyled = styled.p`
  margin-bottom: 10px;
`;
