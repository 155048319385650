import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { Col, Row } from 'react-bootstrap';
import { trimAndLimitCharactersNumber } from 'utils/stringUtils';
import {
  LeadStatusRelevant,
  ThreadFilterFormLeadStatusesMap,
} from 'pages/inbox/filter/InboxFilter.constants';
import { prepareLeadChannelGroups } from 'pages/inbox/filter/ThreadFilterForm';
import RangePickerField from 'components/forms/rangePickerField/RangePickerField';
import {
  FilterFormActions,
  FilterFormClearButton,
  FilterFormSubmitButton,
} from 'components/filters/FilterFormButtons';
import { useFormContext, useWatch } from 'react-hook-form';
import { LeadSortingStrategy } from 'models/Leads';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import PipelineContext from '../PipelineContext';
import usePipelineForm from '../usePipelineForm';
import { convertPipelineFilterFormValues } from './PipelineFilters.utils';
import usePipelineFiltersFormStorage from './usePipelineFiltersFormStorage';
import usePipelineFiltersFormApplyStoredValues from './usePipelineFiltersFormApplyStoredValues';

const availableSortingStrategies: (
  | LeadSortingStrategy.CHECK_IN_DATE_ASC
  | LeadSortingStrategy.CREATION_DATE_DESC
  | LeadSortingStrategy.UPDATED_AT_DESC
)[] = [
  LeadSortingStrategy.CHECK_IN_DATE_ASC,
  LeadSortingStrategy.CREATION_DATE_DESC,
  LeadSortingStrategy.UPDATED_AT_DESC,
];

const PipelineFiltersForm = () => {
  const { t } = useTranslation();
  const { properties, employees, leadChannels, leadSearchFieldRef, setFilter } =
    useContext(PipelineContext);
  const { setValue, reset } = useFormContext();
  const { getDefaultValues } = usePipelineForm();
  const { lead } = useWatch();
  const leadSearchActive = !!lead?.length;
  const { clearStoredFilterFormValues } = usePipelineFiltersFormStorage();
  usePipelineFiltersFormApplyStoredValues();

  const onRangeUpdate = (range: Date[]) => {
    setValue(
      'range',
      {
        checkInFrom: range[0],
        checkInTo: range[1],
      },
      { shouldDirty: true },
    );
  };

  const allOption = { label: t('common.all'), value: '' };
  const employeesOptions = [
    allOption,
    ...employees.map(({ bestProfile, uid }) => ({
      label: trimAndLimitCharactersNumber({ text: bestProfile, limit: 45 }),
      value: uid,
    })),
  ];

  const sortByOptions = availableSortingStrategies.map((value) => ({
    label: t(`form.sortBy_${value}`),
    value,
  }));
  const leadStatusOptions = [
    allOption,
    {
      label: t(`form.leadStatuses.${LeadStatusRelevant}`),
      value: LeadStatusRelevant,
    },
    ...Object.keys(ThreadFilterFormLeadStatusesMap)
      .filter((key) => key !== LeadStatusRelevant)
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      .map((key: keyof typeof ThreadFilterFormLeadStatusesMap) => ({
        label: t(`form.leadStatuses.${key}`),
        value: key,
      })),
  ];

  const handleClear = () => {
    const newDefaultValues = getDefaultValues();
    reset(newDefaultValues);
    setFilter(convertPipelineFilterFormValues(newDefaultValues));
    clearStoredFilterFormValues();
    leadSearchFieldRef.current?.clear();
  };

  return (
    <>
      <Row>
        <Col md={4} sm={6}>
          <SelectField
            colSmLabel={4}
            colSmInput={8}
            disabled={leadSearchActive}
            label={t('form.status')}
            name="leadStatus"
            options={leadStatusOptions}
          />
        </Col>
        <Col md={4} sm={6}>
          <SelectField
            colSmLabel={4}
            colSmInput={8}
            disabled={leadSearchActive}
            label={t('form.sortBy')}
            name="sortStrategy"
            options={sortByOptions}
          />
        </Col>
        <Col md={4} sm={6}>
          <PropertySelectField
            colSmLabel={4}
            colSmInput={8}
            containerClassName="property-container"
            name="propertyUid"
            emptyValueLabel={t('common.all')}
            pictureAsLabel={false}
            properties={properties}
            disabled={leadSearchActive}
            label={t('form.property')}
            showUnits={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6}>
          <SelectField
            colSmLabel={4}
            colSmInput={8}
            disabled={leadSearchActive}
            label={t('form.leadChannel')}
            name="channel"
            options={[allOption]}
            optionGroups={prepareLeadChannelGroups(leadChannels, t)}
          />
        </Col>
        <Col md={4} sm={6}>
          <SelectField
            colSmLabel={4}
            colSmInput={8}
            disabled={leadSearchActive}
            label={t('form.agent')}
            name="userUid"
            options={employeesOptions}
          />
        </Col>
        <Col md={4} sm={6}>
          <RangePickerField
            label={t('pagePipeline.filters.dates')}
            colSmLabel={4}
            colSmInput={8}
            onChange={onRangeUpdate}
            startDateFieldName="range.checkInFrom"
            endDateFieldName="range.checkInTo"
            disabled={leadSearchActive}
            name="range"
          />
        </Col>
      </Row>
      <FilterFormActions>
        <FilterFormClearButton onClick={handleClear} />
        <FilterFormSubmitButton disabled={leadSearchActive} />
      </FilterFormActions>
    </>
  );
};

export default PipelineFiltersForm;
