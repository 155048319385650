import { useContext } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAppEvent from 'hooks/useAppEvent';
import { AppEventType } from 'models/Events';
import useAppModal from '../../../../hooks/useAppModal';
import Button from '../../../button/Button';
import useDeleteLead from '../useDeleteLead';
import useNotify from '../../../../hooks/useNotify';
import { LeadSource, LeadStatus } from '../../../../models/Leads';
import LeadModalContext from './LeadModalContext';
import LeadModalFooterDeleteButton from './footer/LeadModalFooterDeleteButton';
import LeadModalFooterMessages from './footer/LeadModalFooterMessages';
import useLeadModalPropertyAvailability from './footer/useLeadModalPropertyAvailability';
import LeadModalFooterSubmitButtons from './footer/LeadModalFooterSubmitButtons';
import {
  LeadModalFooterDeleteButtonContainer,
  LeadModalFooterMessagesContainer,
  LeadModalFooterSubmitButtonsContainer,
} from './footer/LeadModalFooterMessages.styles';
import LeadModalFooterActionButtons from './LeadModalFooterActionButtons';

const LeadModalFooter = () => {
  const { apiError, lead, modalId } = useContext(LeadModalContext);
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { isSubmitting } = useFormState();
  const { notifySuccess } = useNotify();

  const { t } = useTranslation();
  const leadUid = lead?.uid;
  const leadTypeMessageKey =
    lead?.status === LeadStatus.BLOCKED ? 'Block' : 'Lead';

  const {
    deleteLead,
    errorMessage: deleteErrorMessage,
    isDeleting,
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  } = useDeleteLead(leadUid, () => {
    closeModal(modalId);
    notifySuccess(
      t(`componentLead.modal.deleteSuccessMessage${leadTypeMessageKey}`),
    );
  });

  const {
    isUnavailable: isPropertyUnavailable,
    errorMessage: availabilityErrorMessage,
    overlappingLeads: availabilityOverlappingLeads,
  } = useLeadModalPropertyAvailability();

  const cancelHandler = () => {
    closeModal(modalId);
  };

  const decisionHandler = () => {
    publish(AppEventType.LEAD_UPDATED, { lead });
    closeModal(modalId);
  };

  const showOnlyActionsButtons =
    lead?.status === LeadStatus.PENDING ||
    (lead?.status === LeadStatus.NEW &&
      lead?.source === LeadSource.DIRECT_AIRBNB);

  return (
    <Modal.Footer data-testid="lead-modal-footer">
      <Row>
        {showOnlyActionsButtons ? (
          <LeadModalFooterActionButtons
            updateLeadCallback={decisionHandler}
            overlappingLeads={availabilityOverlappingLeads}
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            errorMessage={
              deleteErrorMessage || apiError || availabilityErrorMessage
            }
          />
        ) : (
          <>
            <LeadModalFooterDeleteButtonContainer>
              <LeadModalFooterDeleteButton
                deleteLead={deleteLead}
                isDeleting={isDeleting}
              />
            </LeadModalFooterDeleteButtonContainer>
            <LeadModalFooterMessagesContainer>
              <LeadModalFooterMessages
                overlappingLeads={availabilityOverlappingLeads}
                errorMessage={
                  deleteErrorMessage || apiError || availabilityErrorMessage
                }
              />
            </LeadModalFooterMessagesContainer>
            <LeadModalFooterSubmitButtonsContainer>
              <Button
                disabled={isSubmitting || isDeleting}
                onClick={cancelHandler}
                data-testid="lead-modal-footer-cancel-button"
              >
                {t('common.cancel')}
              </Button>
              <LeadModalFooterSubmitButtons
                // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                isPropertyUnavailable={isPropertyUnavailable}
              />
            </LeadModalFooterSubmitButtonsContainer>
          </>
        )}
      </Row>
    </Modal.Footer>
  );
};

export default LeadModalFooter;
