import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import CurrencySelectField from 'components/forms/currencySelectField/CurrencySelectField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { PropertyBusinessType } from 'models/Properties';
import { MultiUnitPropertyType } from 'models/PropertyType';
import {
  CollapseSectionStyled,
  FormSectionColumn,
} from 'pages/property/common/Common.styles';
import PropertyDetailsUploadField from '../../mainSettings/sections/propertyDetails/PropertyDetailsUploadField';
import PropertySecurityDepositField from '../../common/pricing/PropertySecurityDepositField';
import { AddMultiUnitPropertyFormValues } from './AddMultiUnitProperty.schema';

const AddMultiUnitPropertyDetailsSection = () => {
  const { t } = useTranslation();
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } =
    useFetchPropertyTypes();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyDetails: { currency },
  } = useWatch<AddMultiUnitPropertyFormValues>();

  if (isLoadingPropertyTypes) {
    return null;
  }

  const context = PropertyBusinessType.HOTEL;

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const propertyTypeOptions = propertyTypes
    .filter(({ type }) =>
      Object.values(MultiUnitPropertyType).includes(
        type as MultiUnitPropertyType,
      ),
    )
    .map(({ label, type }) => ({
      label,
      value: type,
    }))
    .toSorted(({ label: label1 }, { label: label2 }) =>
      label1.localeCompare(label2),
    );

  return (
    <CollapseSectionStyled
      data-testid="property-details"
      defaultExpanded
      label={t('pageProperty.mainSettings.propertyDetails.sectionName')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <SelectField
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertyType',
                { context },
              )}
              name="propertyDetails.propertyType"
              options={propertyTypeOptions}
              colSmInput={7}
              colSmLabel={5}
              required
            />
            <TextField
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertyName',
                { context },
              )}
              name="propertyDetails.propertyName"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.propertyNameTooltip',
              )}
              maxLength={49}
              required
            />
            <PropertyDetailsUploadField />
          </FormSectionColumn>
          <FormSectionColumn>
            <CurrencySelectField
              label={t('pageProperty.pricing.priceSettings.currency')}
              name="propertyDetails.currency"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.pricing.priceSettings.currencyTooltip',
              )}
              required
            />
            <PropertySecurityDepositField
              currency={currency}
              name="propertyDetails.securityDeposit"
            />
          </FormSectionColumn>
        </Row>
      </fieldset>
    </CollapseSectionStyled>
  );
};

export default AddMultiUnitPropertyDetailsSection;
