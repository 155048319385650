import { getCountryFlagEmoji } from 'models/Country.utils';

const i18nLanguagesListObject = {
  de_DE: 'Deutsch',
  en_US: 'English',
  es_ES: 'Español',
  fr_FR: 'French',
  it_IT: 'Italiano',
  nl_NL: 'Nederlands',
  no_NO: 'Norsk',
  pl_PL: 'Polski',
  pt_PT: 'Português',
  ko_KR: 'Korean',
  ja_JP: 'Japanese',
  tr_TR: 'Turkish',
  vi_VN: 'Vietnamese',
};

const i18nLanguageToLocaleCode = {
  de: 'de_DE',
  en: 'en_US',
  es: 'es_ES',
  fr: 'fr_FR',
  it: 'it_IT',
  nl: 'nl_NL',
  no: 'no_NO',
  pl: 'pl_PL',
  pt: 'pt_PT',
  tr: 'tr_TR',
  ja: 'ja_JP',
  ko: 'ko_KR',
  vi: 'vi_VN',
};

interface languagesListArrayType {
  value: string;
  label: string;
}

const i18nLanguagesListArray: languagesListArrayType[] = Object.keys(
  i18nLanguagesListObject,
).map((countryCode) => ({
  value: countryCode,
  // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  label: i18nLanguagesListObject[countryCode],
}));

const languagesListWithFlag: languagesListArrayType[] =
  i18nLanguagesListArray.map(({ label: prevLabel, value }) => {
    const countryCode = value.substring(3, 5);

    return {
      value,
      label: `${getCountryFlagEmoji(countryCode)} ${prevLabel}`,
    };
  });

export {
  i18nLanguagesListArray,
  i18nLanguageToLocaleCode,
  languagesListWithFlag,
};
