import { PropertySettingsMainTabFormValues } from './PropertySettingsMainTab.schema';

const checkInMethodFieldValueConverter = (
  // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  value: PropertySettingsMainTabFormValues['bookingDotComSettings']['alternativeCheckInMethod'],
  dirtyObject: Record<
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    keyof PropertySettingsMainTabFormValues['bookingDotComSettings']['alternativeCheckInMethod'],
    boolean
  >,
) => {
  return Object.entries(dirtyObject)
    .filter(([_, isDirty]) => isDirty)
    .reduce((acc, [key]) => {
      return {
        ...acc,
        [key]: value[key],
      };
    }, {});
};

export const commonFieldValueConverter = {
  bookingDotComSettings: {
    alternativeCheckInMethod: checkInMethodFieldValueConverter,
    primaryCheckInMethod: checkInMethodFieldValueConverter,
  },
};
