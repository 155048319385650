import FormWithProvider from 'components/forms/form/Form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import MFACodeForm from 'components/domain/user/mfaCodeForm/MFACodeForm';
import { MFAType } from 'components/domain/user/mfaCodeForm/MFA.types';
import loginSchema from '../Login.schema';
import LoginForm from '../LoginForm';
import LoginPageTitle from '../LoginPageTitle';
import useLoginSubmit from '../useLoginSubmit';
import useLoginFormInitialValue from '../useLoginFormInitialValue';
import useLoginRedirectionParams from '../useLoginRedirectionParams';
import Loader from '../../../components/loader/Loader';
import useResendMFACode from '../useResendMFACode';

const LoginMobile = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { handleSubmitLogin, clearErrors, errorMessage } = useLoginSubmit();
  const { isLoading, initialValue } = useLoginFormInitialValue();
  const { resendCode } = useResendMFACode();
  useLoginRedirectionParams();

  const showResetPasswordSuccess = searchParams.get('resetPassword') === 'true';
  const showMFA = !!searchParams.get('mfaCode');
  const mfaType = searchParams.get('mfaType') as MFAType;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmitLogin}
      schema={loginSchema(showMFA)}
      defaultValues={initialValue}
    >
      <LoginPageTitle />
      {showMFA ? (
        <MFACodeForm
          type={mfaType}
          resendCode={resendCode}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          codeSentTo={searchParams.get('mfaCodeSentTo')}
          clearErrors={clearErrors}
          errorMessage={errorMessage}
        />
      ) : (
        <>
          {showResetPasswordSuccess && (
            <p>{t('pageLogin.mobileResetSuccess')}</p>
          )}
          {/*
           // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          <LoginForm clearErrors={clearErrors} errorMessage={errorMessage} />
          <Link
            to="/forgot-password"
            className="action-mobile"
            data-testid="forgot-password-link"
            target="_blank"
          >
            {t('pageLogin.forgotPassword')}
          </Link>
        </>
      )}
    </FormWithProvider>
  );
};

export default LoginMobile;
