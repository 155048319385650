import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import {
  GetPropertiesFilterOptionsResponseTO,
  PropertiesFilterOptions,
} from 'models/PropertiesFilter';
import API from 'services/API';

export const PROPERTIES_FILTER_OPTIONS_BASE_QUERY_KEY =
  'properties-filter-options';

type ReactQueryOptions = Omit<
  UseQueryOptions<PropertiesFilterOptions>,
  'queryKey' | 'queryFn'
>;

export const useFetchPropertiesFilterOptions = ({
  enabled,
  ...options
}: ReactQueryOptions = {}) => {
  const { agencyUid } = useAppUser();

  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PROPERTIES_FILTER_OPTIONS_BASE_QUERY_KEY, agencyUid],
    queryFn: async () => {
      const response = await API.get<GetPropertiesFilterOptionsResponseTO>(
        `/api/internal/properties/filter-options?agencyUid=${agencyUid}`,
      );
      return response.data;
    },
    enabled: !!agencyUid && enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};
