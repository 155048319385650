import { createContext, useContext, useMemo, useState } from 'react';
import { SelectModeContextProps } from './SelectModeContext.types';

// @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const SelectModeContext = createContext<SelectModeContextProps>(undefined);

export const SelectModeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedPhotos, setSelectedPhotos] = useState<Set<string>>(new Set());
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);

  const toggleSelectMode = () => {
    setIsSelectMode((mode) => !mode);
    setSelectedPhotos(new Set());
  };

  const toggleSelectPhoto = (photoUid: string) => {
    setSelectedPhotos((photos) => {
      const newPhotos = new Set(photos);
      if (photos.has(photoUid)) {
        newPhotos.delete(photoUid);
      } else {
        newPhotos.add(photoUid);
      }
      return newPhotos;
    });
  };

  const getPhotoSelectionStatus = (photoUid: string) => {
    if (!isSelectMode) {
      return false;
    }

    return selectedPhotos.has(photoUid);
  };

  const contextValue = useMemo(
    () => ({
      selectedPhotos,
      isSelectMode,
      toggleSelectMode,
      toggleSelectPhoto,
      getPhotoSelectionStatus,
    }),
    [selectedPhotos, isSelectMode],
  );

  return (
    <SelectModeContext.Provider value={contextValue}>
      {children}
    </SelectModeContext.Provider>
  );
};

export const useSelectModeContext = () => {
  const context = useContext(SelectModeContext);

  if (!context) {
    throw new Error(
      'useSelectModeContext must be used within a SelectModeContextProvider',
    );
  }

  return context;
};
