import useCalendarBodySelection from 'pages/calendar/common/body/selection/useCalendarBodySelection';
import useAppModal from '../../../../hooks/useAppModal';
import PropertyPricingModal from './PropertyPricingModal';
import { PropertyPricingModalParams } from './PropertyPricingModal.types';

const propertyPricingModalId = 'property-pricing-modal';

const usePropertyPricingModal = () => {
  const { resetSelection } = useCalendarBodySelection();
  const { openModal } = useAppModal();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const openPropertyPricingModal = (params) => {
    openModal({
      customContent: (
        <PropertyPricingModal
          modalId={propertyPricingModalId}
          params={params}
        />
      ),
      id: propertyPricingModalId,
      width: 400,
      onHide: () => {
        resetSelection();
      },
    });
  };

  return {
    createPropertyPricingModal: (params: PropertyPricingModalParams) => {
      openPropertyPricingModal(params);
    },
  };
};

export default usePropertyPricingModal;
