import { PayloadAction } from '@reduxjs/toolkit';
import { Property } from 'models/Properties';
import { StackedCalendarState } from '../types';

export default function updateVisiblePropertiesReducer(
  state: StackedCalendarState,
  {
    payload: { appendToExisting, properties },
  }: PayloadAction<{ properties: Property[]; appendToExisting?: boolean }>,
) {
  // logic moved from CalendarContextProvider.tsx
  const totalProperties = properties.reduce(
    (total, property) =>
      total +
      1 +
      (property.subUnits?.length || 0) +
      (property.unitTypes?.length || 0) +
      (property.unitTypes?.reduce(
        (acc, unitType) => acc + (unitType.units?.length || 0),
        0,
      ) || 0),
    0,
  );

  const currentVisibleProperties = state.visibleProperties;

  if (totalProperties > 25) {
    // if there are more than 25 properties, we should not display units and subunits by default:
    state.visibleProperties = [
      ...(appendToExisting ? currentVisibleProperties : []),
      ...properties
        .map(({ uid, unitTypes, subUnits, businessType }) => [
          `${uid}[${businessType}]`,
          ...(subUnits || []).map(
            ({ uid: subUnitUid }) => `${subUnitUid}[SUB_UNIT]`,
          ),
          // @ts-expect-error TS2461 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          ...(unitTypes || []).reduce(
            // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            (unitsAccumulator, { uid: unitTypeUid }) => {
              return [...unitsAccumulator, `${unitTypeUid}[UNIT_TYPE]`];
            },
            [],
          ),
        ])
        .reduce(
          (uidsAccumulator, propertyUids) => [
            ...uidsAccumulator,
            ...propertyUids,
          ],
          [],
        ),
    ];
  } else {
    state.visibleProperties = [
      ...(appendToExisting ? currentVisibleProperties : []),
      ...properties
        .map(({ uid, subUnits, unitTypes, businessType }) => [
          `${uid}[${businessType}]`,
          ...(subUnits || []).map(
            ({ uid: subUnitUid }) => `${subUnitUid}[SUB_UNIT]`,
          ),
          // for each unit type, also add the units uids from the units array within the unit type:
          // @ts-expect-error TS2461 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          ...(unitTypes || []).reduce(
            // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            (unitsAccumulator, { uid: unitTypeUid, units }) => {
              const sortedUnits =
                units?.sort((a, b) => a.name.localeCompare(b.name)) || [];
              return [
                ...unitsAccumulator,
                `${unitTypeUid}[UNIT_TYPE]`,
                ...(sortedUnits || []).map(
                  ({ uid: unitUid }) => `${unitUid}[UNIT]`,
                ),
              ];
            },
            [],
          ),
        ])
        .reduce(
          (uidsAccumulator, propertyUids) => [
            ...uidsAccumulator,
            ...propertyUids,
          ],
          [],
        ),
    ];
  }
}
