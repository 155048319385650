import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import {
  BookingDotComCheckInMethodHow,
  BookingDotComCheckInMethodType,
} from 'models/bookingDotCom/CheckInMethods';
import { CheckInMethodSubOptionsContainer } from '../BookingDotComSettings.styles';
import { PropertySettingsMainTabFormValues } from '../../../PropertySettingsMainTab.schema';
import CheckInMethodTypeField from './CheckInMethodTypeField';
import CheckInMethodHowField from './CheckInMethodHowField';
import CheckInMethodWhenField from './CheckInMethodWhenField';

interface CheckInMethodFieldProps {
  name: 'alternativeCheckInMethod' | 'primaryCheckInMethod';
}

const TYPE_WITH_HOW_FIELD_VISIBLE = [
  BookingDotComCheckInMethodType.INSTRUCTION_CONTACT_US,
  BookingDotComCheckInMethodType.INSTRUCTION_WILL_SEND,
];

const TYPE_WITH_WHEN_FIELD_VISIBLE = [
  BookingDotComCheckInMethodType.INSTRUCTION_WILL_SEND,
];

const TYPE_WITH_IDENTIFIER_FIELD_VISIBLE = [
  BookingDotComCheckInMethodType.INSTRUCTION_CONTACT_US,
];

const HOW_WITH_ADDITIONAL_TEXT_VISIBLE = [BookingDotComCheckInMethodHow.OTHER];

const TYPE_WITH_ADDITIONAL_TEXT_VISIBLE = [
  BookingDotComCheckInMethodType.RECEPTION,
  BookingDotComCheckInMethodType.DOOR_CODE,
  BookingDotComCheckInMethodType.LOCK_BOX,
  BookingDotComCheckInMethodType.SECRET_SPOT,
  BookingDotComCheckInMethodType.SOMEONE_WILL_MEET,
  BookingDotComCheckInMethodType.OTHER,
];

const TYPE_WITH_BRAND_NAME_VISIBLE = [
  BookingDotComCheckInMethodType.DOOR_CODE,
  BookingDotComCheckInMethodType.LOCK_BOX,
];

const CheckInMethodField = ({ name }: CheckInMethodFieldProps) => {
  const { t } = useTranslation();
  const { bookingDotComSettings } =
    useWatch<PropertySettingsMainTabFormValues>();
  const checkInMethod: // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  | PropertySettingsMainTabFormValues['bookingDotComSettings']['alternativeCheckInMethod']
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    | PropertySettingsMainTabFormValues['bookingDotComSettings']['primaryCheckInMethod']
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    | null = bookingDotComSettings[name];

  return (
    <>
      <CheckInMethodTypeField name={name} />
      <CheckInMethodSubOptionsContainer xsOffset={4} smOffset={5}>
        {TYPE_WITH_HOW_FIELD_VISIBLE.includes(checkInMethod?.type) && (
          <CheckInMethodHowField name={name} />
        )}
        {TYPE_WITH_WHEN_FIELD_VISIBLE.includes(checkInMethod?.type) && (
          <CheckInMethodWhenField name={name} />
        )}
        {(TYPE_WITH_ADDITIONAL_TEXT_VISIBLE.includes(checkInMethod?.type) ||
          HOW_WITH_ADDITIONAL_TEXT_VISIBLE.includes(checkInMethod?.how)) && (
          <TextField
            label={`${t(
              `pageProperty.mainSettings.bookingDotComSettings.${name}.text`,
            )}:`}
            name={`bookingDotComSettings.${name}.text`}
            colSmInput={12}
            colSmLabel={12}
            labelOnTop
          />
        )}
        {TYPE_WITH_BRAND_NAME_VISIBLE.includes(checkInMethod?.type) && (
          <TextField
            label={`${t(
              `pageProperty.mainSettings.bookingDotComSettings.${name}.brandName`,
            )}:`}
            name={`bookingDotComSettings.${name}.brandName`}
            colSmInput={12}
            colSmLabel={12}
            labelOnTop
          />
        )}
        {TYPE_WITH_IDENTIFIER_FIELD_VISIBLE.includes(checkInMethod?.type) && (
          <TextField
            label={`${t(
              `pageProperty.mainSettings.bookingDotComSettings.${name}.identifier`,
            )}:`}
            name={`bookingDotComSettings.${name}.identifier`}
            colSmInput={12}
            colSmLabel={12}
            placeholder={t(
              `pageProperty.mainSettings.bookingDotComSettings.${name}.identifierPlaceholder`,
            )}
            labelOnTop
          />
        )}
      </CheckInMethodSubOptionsContainer>
    </>
  );
};

export default CheckInMethodField;
