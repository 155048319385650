import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';

interface InboxState {
  unreadThreads: number;
}

const initialState: InboxState = {
  unreadThreads: 0,
};

const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setUnreadThreads: (
      state,
      action: PayloadAction<{ unreadThreads: number }>,
    ) => {
      state.unreadThreads = action.payload.unreadThreads;
    },
  },
});

export const { setUnreadThreads } = inboxSlice.actions;

export default inboxSlice.reducer;
