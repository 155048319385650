import { DiscountCodeType } from './Discounts';

// QuoteFeeType is used on quote.fees, while QuoteOverrideFeeType quote.overrides.fees
export enum QuoteFeeType {
  FEE = 'FEE',
  TAX = 'TAX',
}

export enum FeeAmountType {
  AMOUNT = 'AMOUNT',
  TAX = 'TAX',
}

export enum FeeScopeType {
  PER_NIGHT = 'PER_NIGHT',
  PER_STAY = 'PER_STAY',
  PER_GUEST_PER_NIGHT = 'PER_GUEST_PER_NIGHT',
  PER_GUEST = 'PER_GUEST',
  PERCENTAGE_OF_RENT = 'PERCENTAGE_OF_RENT',
  PERCENTAGE_OF_TOTAL = 'PERCENTAGE_OF_TOTAL',
  PER_PET = 'PER_PET',
  PER_PET_PER_NIGHT = 'PER_PET_PER_NIGHT',
}

// QuoteFeeType is used on quote.fees, while QuoteOverrideFeeType quote.overrides.fees
export enum QuoteOverrideFeeType {
  CLEANING = 'CLEANING',
  EARLY_ARRIVAL = 'EARLY_ARRIVAL',
  LATE_ARRIVAL = 'LATE_ARRIVAL',
  PET = 'PET',
  TAX = 'TAX',
  CUSTOM = 'CUSTOM',
}
export enum AdjustmentType {
  RENT = 'RENT',
  TAX = 'TAX',
  FEE = 'FEE',
  SERVICE = 'SERVICE',
  RESOLUTION = 'RESOLUTION',
}

export enum AdjustmentTransactionType {
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
}

export interface LeadQuoteFeeOverride {
  feeId?: number;
  feeUid?: string;
  name: string;
  amountType: FeeAmountType;
  amount: number;
  taxationRate?: number;
  scope?: FeeScopeType;
  type?: QuoteOverrideFeeType;
  isOptional?: boolean;
  netPrice: number;
  enabled: boolean;
  removed: boolean;
}

export interface LeadQuoteFeesOverride {
  cleaningFee: {
    netPrice: number;
    taxationRate: number;
  };
  otherFees: LeadQuoteFeeOverride[];
}

export interface LeadQuoteRentOverride {
  rentBaseNetPrice: number;
  rentNetPrice: number;
  extraGuestsBaseNetPrice: number;
  taxationRate: number;
  taxAmount: number;
}

export interface LeadQuoteOverride {
  fees: LeadQuoteFeesOverride;
  rent: LeadQuoteRentOverride;
  force100PercentPaymentAtReservation: boolean;
  totalPrice: number;
  securityDeposit: number;
}

export interface QuoteRentBreakdown {
  nightlyAmount: number;
  nightlyBaseAmount: number;
  nightlyDate: string;
}

export interface Discount {
  discountCode: string;
  discountType: DiscountCodeType;
  amount: number;
  percent: number;
}

export interface QuoteRent {
  rentNetPrice: number;
  rentBaseNetPrice: number;
  rentBreakdowns?: QuoteRentBreakdown[];
  extraGuestsNetPrice: number;
  extraGuestsBaseNetPrice: number;
  rateMultiplier: number;
  rateMultiplierAmount: number;
  listPrice: number;
  discount: Discount;
  netPrice: number;
  taxationRate: number;
  taxAmount: number;
  grossPrice: number;
}

export interface Price {
  netPrice: number;
  taxationRate: number;
  taxAmount: number;
  grossPrice: number;
}

export interface QuoteFee {
  feeId: number;
  feeUid?: string;
  name: string;
  amountType: FeeAmountType;
  isEditable: boolean;
  netPrice: number;
  taxationRate: number;
  taxAmount: number;
  grossPrice: number;
  type: QuoteFeeType;
}

export interface QuoteFees {
  cleaningFee: Price;
  otherFees: QuoteFee[];
}

export interface QuoteService {
  title: string;
  grossPrice: number;
}

export interface QuoteAdjustment {
  id: number;
  isPaid: boolean;
  amount: number;
}

export interface QuoteAdjustmentItem {
  uid: string;
  amount: number;
  note: string;
  adjustmentType: AdjustmentType;
  transactionType: AdjustmentTransactionType;
}

export interface QuoteResponse {
  quote: Quote;
}

export interface Quote {
  propertyName?: string;
  rent: QuoteRent;
  securityDeposit: number;
  includeSecurityDepositInTotal: boolean;
  fees: QuoteFees;
  services: QuoteService[];
  adjustments: QuoteAdjustment[];
  totalAmount: number;
  totalPrice: number;
  currency: string;
  overrides?: QuoteOverrides;
  adjustmentItems: QuoteAdjustmentItem[];
  leadUid?: string;
}

export interface QuoteOverrides {
  rent: {
    rentBaseNetPrice: number;
    extraGuestsBaseNetPrice: number;
    taxationRate: number;
    taxAmount: number;
  };
  securityDeposit: number;
  totalPrice: number;
  force100PercentPaymentAtReservation: boolean;
  fees: {
    cleaningFee?: {
      netPrice: number;
      taxationRate: number;
    };
    otherFees?: {
      feeId?: number;
      feeUid?: string;
      name: string;
      amountType: FeeAmountType;
      amount: number;
      taxationRate: number;
      scope: FeeScopeType;
      type: QuoteOverrideFeeType;
      isOptional: boolean;
      netPrice: number;
      enabled: boolean;
      removed: boolean;
    }[];
  };
}
