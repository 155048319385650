import { Trans } from 'react-i18next';
import { getPropertyEditPath } from '../../utils/property/propertyUtils';
import usePropertySettingsInitData from './usePropertySettingsInitData';
import PropertyLoader from './PropertyLoader';
import usePropertySettingsActiveTab from './usePropertySettingsActiveTab';
import { PropertySettingsTab } from './PropertySettingsTabs.constants';

const activeTabV3ToLegacy = {
  [PropertySettingsTab.feesAndPolicies]: 'fees',
  [PropertySettingsTab.owners]: 'owner',
  [PropertySettingsTab.pricing]: 'pricingPeriods',
  [PropertySettingsTab.serviceProviders]: 'providers',
  [PropertySettingsTab.units]: 'units',
  [PropertySettingsTab.unitTypes]: '',
  [PropertySettingsTab.rentalConditions]: 'rentalConditions',
};

const PropertySettingsBetaAccessInfo = () => {
  const activeTab = usePropertySettingsActiveTab();
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();

  if (isLoadingInitData) {
    return <PropertyLoader />;
  }

  return (
    <div data-testid="beta-access-info">
      <Trans
        i18nKey="pageProperty.common.betaNotAvailableInfo"
        components={{
          link1: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
            <a
              // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              href={`${getPropertyEditPath(property)}#${
                activeTabV3ToLegacy[activeTab]
              }`}
            />
          ),
        }}
      />
    </div>
  );
};

export default PropertySettingsBetaAccessInfo;
