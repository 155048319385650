import { Lead } from 'models/Leads';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import { parse } from 'date-fns';
import { AirbnbAlterationResponse } from './AirbnbAlteration.types';

const useAirbnbAlterationModalFormInitialValue = () => {
  return ({
    lead,
    airbnbAlterationData,
  }: {
    lead: Lead | undefined;
    airbnbAlterationData: AirbnbAlterationResponse | undefined;
  }) => {
    if (lead) {
      const {
        adultCount,
        childrenCount,
        infantCount,
        petCount,
        property: { uid: propertyUid },
        source,
        status,
        uid,
        order,
        email,
        firstName,
        lastName,
      } = lead;
      const checkInDate = getLeadCheckInDate(lead);
      const checkOutDate = getLeadCheckOutDate(lead);

      const {
        startDate,
        endDate,
        numberOfAdults,
        numberOfChildren,
        numberOfInfants,
        numberOfPets,
      } = airbnbAlterationData || {};

      const airbnbAlterationStart = startDate
        ? parse(startDate, 'yyyy-MM-dd', new Date())
        : checkInDate;
      const airbnbAlterationEnd = endDate
        ? parse(endDate, 'yyyy-MM-dd', new Date())
        : checkOutDate;

      return {
        propertyUid,
        checkInDate,
        checkOutDate,
        adultCount,
        childrenCount,
        infantCount,
        petCount,
        source,
        status,
        uid,
        email,
        order,
        firstName,
        lastName,
        airbnbAlteration: {
          startDate: airbnbAlterationStart,
          endDate: airbnbAlterationEnd,
          numberOfAdults: numberOfAdults ?? adultCount,
          numberOfChildren: numberOfChildren ?? childrenCount,
          numberOfInfants: numberOfInfants ?? infantCount,
          numberOfPets: numberOfPets ?? petCount,
          price: null,
          propertyUid,
        },
      };
    }
    return null;
  };
};

export default useAirbnbAlterationModalFormInitialValue;
