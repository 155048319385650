import { LeadModalProps, UpdateLeadModalParams } from './LeadModal.types';
import LeadModalContextProvider from './LeadModalContextProvider';
import LeadModalFormWrapper from './LeadModalFormWrapper';

const LeadModal = ({
  availableProperties,
  modalId,
  params,
}: LeadModalProps) => {
  return (
    <LeadModalContextProvider
      availableProperties={availableProperties}
      modalId={modalId}
      defaultLeadUid={(params as UpdateLeadModalParams)?.leadUid}
    >
      <LeadModalFormWrapper params={params} />
    </LeadModalContextProvider>
  );
};

export default LeadModal;
