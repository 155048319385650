import NotFound from '../notFound/NotFound';
import usePropertySettingsInitData from './usePropertySettingsInitData';
import PropertyLoader from './PropertyLoader';
import PropertySettingsTabs from './PropertySettingsTabs';

const Property = () => {
  const { isLoading, property } = usePropertySettingsInitData();

  if (isLoading) {
    return <PropertyLoader />;
  }

  if (!property || !Object.keys(property).length) {
    return <NotFound />;
  }

  return (
    <div className="container">
      {/*
       // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <PropertySettingsTabs property={property} />
    </div>
  );
};

export default Property;
