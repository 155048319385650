import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Currency } from 'models/Currency';
import TextField from 'components/forms/textField/TextField';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';

const PropertySecurityDepositField = ({
  currency,
  label,
  name,
}: {
  currency: Currency;
  label?: ReactNode;
  name: string;
}) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const { t } = useTranslation();

  return (
    <TextField
      label={label ?? t('pageProperty.pricing.priceSettings.securityDeposit')}
      name={name}
      colSmInput={7}
      colSmLabel={5}
      leftAddon={getCurrencySymbol(currency)}
      rightTooltip={
        <Trans
          i18nKey="pageProperty.pricing.priceSettings.securityDepositTooltip"
          components={{
            link1: (
              <a
                aria-label="Hostfully documentation"
                href="https://help.hostfully.com/en/articles/4787787-collect-the-security-deposit"
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      }
      type="number"
      min={0}
    />
  );
};

PropertySecurityDepositField.defaultProps = {
  label: undefined,
};

export default PropertySecurityDepositField;
