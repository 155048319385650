import useFeatures from 'hooks/useFeatures';
import PropertySettingsBetaAccessInfo from '../PropertySettingsBetaAccessInfo';
import RentalConditions from '../feesAndPolicies/sections/rentalConditions/RentalConditions';

const PropertySettingsRentalConditionsTab = () => {
  const { allowPropertyEditV3Beta } = useFeatures();

  if (allowPropertyEditV3Beta) {
    return <PropertySettingsBetaAccessInfo />;
  }

  return <RentalConditions />;
};

export default PropertySettingsRentalConditionsTab;
