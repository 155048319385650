import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CurrencyWithoutNone } from 'models/Currency';
import OwnerAdjustmentModalFormFields from './OwnerAdjustmentModalFormFields';
import { OwnerAdjustmentModalProps } from './OwnerAdjustmentModal.types';
import { FormStyled } from './OwnerAdjustmentModal.styles';
import OwnerAdjustmentModalFooter from './OwnerAdjustmentModalFooter';
import useOwnerAdjustmentModalForm from './hooks/useOwnerAdjustmentModalForm';
import ownerAdjustmentModalSchema from './OwnerAdjustmentModal.schema';

const OwnerAdjustmentModal = ({
  defaultAdjustment,
  propertyUid,
}: OwnerAdjustmentModalProps) => {
  const { t } = useTranslation();
  const { handleSubmit, isUpdating } =
    useOwnerAdjustmentModalForm(defaultAdjustment);
  const isNew = !defaultAdjustment.uid;
  const defaultCurrency = defaultAdjustment.currency;
  const currency: CurrencyWithoutNone =
    !defaultCurrency || defaultCurrency === 'NONE' ? 'USD' : defaultCurrency;

  return (
    <div data-testid="owner-adjustment-modal">
      <Modal.Header data-testid="owner-adjustment-modal-header" closeButton>
        <Modal.Title>
          {isNew
            ? t('pageOwnerAdjustments.modal.title.create')
            : t('pageOwnerAdjustments.modal.title.edit')}
        </Modal.Title>
      </Modal.Header>

      <FormStyled
        disabled={isUpdating}
        defaultValues={{
          ...defaultAdjustment,
          currency,
        }}
        resolver={ownerAdjustmentModalSchema(t)}
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        onSubmit={(formData) => {
          handleSubmit({ id: defaultAdjustment.uid, propertyUid, ...formData });
        }}
        noValidate
      >
        <fieldset disabled={isUpdating}>
          <Modal.Body>
            <OwnerAdjustmentModalFormFields />
          </Modal.Body>

          <Modal.Footer>
            <OwnerAdjustmentModalFooter />
          </Modal.Footer>
        </fieldset>
      </FormStyled>
    </div>
  );
};

export default OwnerAdjustmentModal;
