import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { GetAirbnbNotificationsResponseTO } from 'models/AirbnbNotification';
import API from 'services/API';

export const AIRBNB_NOTIFICATIONS_BASE_QUERY_KEY = 'airbnb-notifications';

type ReactQueryOptions = Omit<
  UseInfiniteQueryOptions<GetAirbnbNotificationsResponseTO>,
  'queryKey' | 'queryFn'
>;

type useAirbnbNotificationsArgs = {
  pageSize: number;
} & ReactQueryOptions;

const useAirbnbNotifications = ({
  enabled,
  pageSize,
  ...options
}: useAirbnbNotificationsArgs) => {
  // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return useInfiniteQuery({
    queryKey: [AIRBNB_NOTIFICATIONS_BASE_QUERY_KEY, { pageSize }],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await API.get<GetAirbnbNotificationsResponseTO>(
        '/api/internal/airbnb/notifications',
        {
          params: {
            _limit: pageSize,
            _page: pageParam,
          },
        },
      );

      return response.data;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    // eslint-disable-next-line no-underscore-dangle
    getNextPageParam: (lastPage) => lastPage.paging?._nextPage ?? undefined,
    ...(options ?? {}),
  });
};

export default useAirbnbNotifications;
