import { useTranslation } from 'react-i18next';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import {
  PropertyEditPermissionInfoBody,
  PropertyEditPermissionInfoContainer,
} from './PropertyEditPermissionInfo.styles';

const PropertyEditPermissionInfo = () => {
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();
  const { t } = useTranslation();

  if (propertiesUpdatingAllowed) {
    return null;
  }

  return (
    <PropertyEditPermissionInfoContainer
      className="edit-permission-info"
      data-testid="edit-permission-info"
    >
      <PropertyEditPermissionInfoBody>
        {t('common.permissions.noPermissionToEditPage')}
      </PropertyEditPermissionInfoBody>
    </PropertyEditPermissionInfoContainer>
  );
};

export default PropertyEditPermissionInfo;
