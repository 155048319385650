import { ReactNode } from 'react';
import { TableProps } from 'components/table/Table.types';
import useWithScrollableShadow from 'hooks/useWithScrollableShadow';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import {
  CommonTableCaption,
  CommonTableWithScrollableShadowStyled,
  CommonTableWrapperStyled,
} from './CommonTable.styles';
import Table from './Table';

interface CommonTableProps<T> extends TableProps<T> {
  caption?: ReactNode;
}

const CommonTable = <T,>({
  caption,
  isLoading,
  tableInstance,
  ...tableProps
}: CommonTableProps<T>) => {
  const isEmpty = !tableInstance.options.data.length;
  const isNotEmpty = !isEmpty;
  const hasManyPages = tableInstance.getPageCount() > 1;
  const { isXS, isSM } = useScreenBreakpoints();
  const hasPagination = hasManyPages && isNotEmpty;
  const withScrollableShadowRef = useWithScrollableShadow(
    (isXS || isSM) && isNotEmpty,
  );

  const hasActionsColumn = tableInstance
    .getAllColumns()
    .some((column) => column.id === 'actions');
  const rightOffset = hasActionsColumn ? 130 : 0;

  return (
    <CommonTableWithScrollableShadowStyled
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      ref={withScrollableShadowRef}
      $rightOffset={rightOffset}
      $hasPagination={hasPagination}
    >
      {/*
       // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <CommonTableWrapperStyled $isEmpty={isEmpty} $isLoading={isLoading}>
        <Table
          isLoading={isLoading}
          tableInstance={tableInstance}
          {...tableProps}
        >
          {caption && (
            <Table.Caption>
              <CommonTableCaption>{caption}</CommonTableCaption>
            </Table.Caption>
          )}

          {isNotEmpty && <Table.ColGroup />}
          {isNotEmpty && <Table.Header />}
          <Table.Body />

          {hasPagination && (
            <Table.SingleCellFooter>
              <Table.Pagination />
            </Table.SingleCellFooter>
          )}
        </Table>
      </CommonTableWrapperStyled>
    </CommonTableWithScrollableShadowStyled>
  );
};

CommonTable.defaultProps = {
  caption: undefined,
};

export default CommonTable;
