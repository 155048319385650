import { useContext } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { usePropertiesCount } from 'components/domain/propertiesList/usePropertiesCount';
import PropertySearchField from 'components/forms/domain/property/PropertySearchField/PropertySearchField';
import useUpgradePlanModal from 'components/domain/property/modal/upgradePlan/useUpgradePlanModal';
import usePropertiesCreationAllowed from 'components/domain/property/usePropertiesCreationAllowed';
import useAppUser from 'hooks/useAppUser';
import useFeatures from 'hooks/useFeatures';
import useFilterIcon from 'hooks/useFilterIcon';
import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import BookingIcon from 'assets/images/icons/channels/booking.svg?react';
import PropertiesFilter from '../filters/PropertiesFilter';
import PropertiesContext from '../PropertiesContext';
import useImportBookingDotComPropertiesModal from '../actions/importProperties/bookingDotCom/useImportBookingDotComPropertiesModal';
import {
  ActionsWrapper,
  AgencyName,
  ImportMenuItem,
  PageHeaderStyled,
  PropertiesCount,
  PropertiesFilterFormContainer,
} from './PropertiesHeader.styles';

const PropertiesHeader = () => {
  const { t } = useTranslation();
  const { agency } = useAppUser();
  const { allowPropertyEditV3Beta } = useFeatures();
  const canCreateProperties = usePropertiesCreationAllowed();
  const { propertySearchFieldRef } = useContext(PropertiesContext);
  const { openUpgradePlanModal, canAddProperties } = useUpgradePlanModal();
  const { openBookingDotComPropertiesModal } =
    useImportBookingDotComPropertiesModal();
  const { data: propertiesCount, isLoading } = usePropertiesCount();
  const { isFilterOpen, filterIconWithCounterFromForm: FilterIconRenderer } =
    useFilterIcon({
      stateStorageKey: 'properties-filters-expanded',
    });

  const handleAddPropertyDropdownClick: React.MouseEventHandler = (event) => {
    if (!canAddProperties(1)) {
      event.preventDefault();
      openUpgradePlanModal();
    }
  };

  const addSinglePropertyPath = allowPropertyEditV3Beta
    ? '#/property/add/single'
    : '#/property/add';
  const addMultiUnitPropertyPath = allowPropertyEditV3Beta
    ? '#/property/add/multi-unit'
    : '#/hotel/add';

  return (
    <>
      <PageHeaderStyled
        title={
          <>
            {/*
             // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
            <AgencyName>{agency.name}</AgencyName>{' '}
            {!isLoading && (
              <PropertiesCount data-testid="properties-count">
                {t('pageProperties.header.propertiesCount', {
                  propertiesCount,
                })}
              </PropertiesCount>
            )}
          </>
        }
      >
        <ActionsWrapper>
          {canCreateProperties && (
            <DropdownButton
              id="add-property-button"
              data-testid="add-property-button"
              bsStyle="primary"
              title={t('pageProperties.header.addProperty')}
            >
              <MenuItem
                href={addSinglePropertyPath}
                onClick={handleAddPropertyDropdownClick}
              >
                {t('pageProperties.header.singleProperty')}
              </MenuItem>
              <MenuItem
                href={addMultiUnitPropertyPath}
                onClick={handleAddPropertyDropdownClick}
              >
                {t('pageProperties.header.multiUnitProperty')}
              </MenuItem>

              <MenuItem divider />

              <ImportMenuItem href="#/property/airbnb-import">
                <AirbnbIcon />
                {t('pageProperties.header.airbnbImport')}
              </ImportMenuItem>

              <ImportMenuItem onClick={openBookingDotComPropertiesModal}>
                <BookingIcon />
                {t('pageProperties.header.bcImport')}
              </ImportMenuItem>
            </DropdownButton>
          )}
          <PropertySearchField
            align="right"
            containerClassName="property-search-field"
            colSmInput={12}
            name="propertySearch"
            instanceRef={propertySearchFieldRef}
          />
          <FilterIconRenderer />
        </ActionsWrapper>
      </PageHeaderStyled>
      <PropertiesFilterFormContainer isCollapsed={!isFilterOpen}>
        <PropertiesFilter />
      </PropertiesFilterFormContainer>
    </>
  );
};

export default PropertiesHeader;
