import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import PropertyMultiSelectFilterField, {
  PropertyMultiSelectFilterFieldProps,
} from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { AppliesToLabel } from 'pages/property/common/Common.styles';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export const bulkDeleteModalId = 'bulk-delete-modal';

const deleteFormSchema = () => ({
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        isFixed: Yup.boolean().optional(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
});

export type BulkDeleteFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof deleteFormSchema>>
>;

const BulkDeleteModal = ({
  onSubmit,
  mutationKey,
  defaultValues,
  description,
  title,
  propertyMultiSelectProps,
}: {
  onSubmit: (data: BulkDeleteFormSchemaType) => void;
  mutationKey: string[];
  defaultValues: { [key: string]: any };
  title: string;
  description: string;
  propertyMultiSelectProps?: Omit<PropertyMultiSelectFilterFieldProps, 'name'>;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;

  const handleClose = () => {
    closeModal(bulkDeleteModalId);
  };

  return (
    <FormWithProvider
      horizontal
      schema={deleteFormSchema()}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>{description}</Col>

          <Col xs={12}>
            {t('pageProperty.feesTaxesAndPolicies.bulkDelete.warning')}
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <AppliesToLabel>
              {t('pageProperty.common.cells.appliesTo')}
            </AppliesToLabel>
          </Col>

          <Col xs={9}>
            <fieldset disabled={isLoading}>
              <PropertyMultiSelectFilterField
                name="appliesTo"
                {...propertyMultiSelectProps}
              />
            </fieldset>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="danger" type="submit" disabled={isLoading}>
          {t('common.delete')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

BulkDeleteModal.defaultProps = {
  propertyMultiSelectProps: {},
};

export default BulkDeleteModal;
