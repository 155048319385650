import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import {
  PricingRuleBulkCreatePayload,
  PricingRuleBulkCreateResponse,
} from 'models/pricing/PricingRule';
import API from 'services/API';

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    PricingRuleBulkCreateResponse,
    unknown,
    PricingRuleBulkCreatePayload
  >,
  'mutationFn' | 'mutationKey'
>;

const bulkCreatePricingRule = (payload: PricingRuleBulkCreatePayload) => {
  return API.post<PricingRuleBulkCreateResponse, BaseErrorResponse>(
    '/api/internal/pricing-rules',
    payload,
  );
};

const useBulkCreatePricingRule = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: bulkCreatePricingRule,
    ...options,
  });
};

export default useBulkCreatePricingRule;
