import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import API from 'services/API';
import { CancellationPolicyValue } from 'models/CancellationPolicy';
import { useQueryClient } from '@tanstack/react-query';
import { Property } from 'models/Properties';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { CANCELLATION_POLICY_BASE_QUERY_KEY } from '../useCancellationPolicyData';
import CancellationPolicyModal, {
  CancellationPolicyFormSchemaType,
  cancellationPolicyModalId,
} from './CancellationPolicyModal';

const ADD_CANCELLATION_POLICY_BASE_MUTATION_KEY = 'add-cancellation-policy';
const UPDATE_CANCELLATION_POLICY_BASE_MUTATION_KEY =
  'update-cancellation-policy';

const useCancellationPolicyModal = (cancellationPolicyUid?: string) => {
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();
  const queryClient = useQueryClient();

  const { mutate: addCancellationPolicy } = useAppMutation(
    [ADD_CANCELLATION_POLICY_BASE_MUTATION_KEY],
    (payload: {
      cancellationPolicy: {
        values: CancellationPolicyValue[];
      };
      applyToAll: boolean;
      propertyUids: Property['uid'][];
    }) => {
      return API.post(
        '/api/internal/cancellation-policies/bulk-update',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CANCELLATION_POLICY_BASE_QUERY_KEY]);
        closeModal(cancellationPolicyModalId);
      },
    },
  );

  const { mutate: updateCancellationPolicy } = useAppMutation(
    [UPDATE_CANCELLATION_POLICY_BASE_MUTATION_KEY],
    (payload: { values: CancellationPolicyValue[] }) => {
      return API.put(
        `/api/internal/cancellation-policies/${cancellationPolicyUid}`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CANCELLATION_POLICY_BASE_QUERY_KEY]);
        closeModal(cancellationPolicyModalId);
      },
    },
  );

  const handleAddSubmit = (values: CancellationPolicyFormSchemaType) => {
    const payload = {
      cancellationPolicy: {
        values: [
          {
            text: values.text,
            locale: LanguageCodeUnderscored.EN_US,
          },
        ],
      },
      applyToAll: false,
      propertyUids: [property.uid],
    };

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    addCancellationPolicy(payload);
  };

  const handleUpdateSubmit = (values: CancellationPolicyFormSchemaType) => {
    const payload = {
      values: [
        {
          text: values.text,
          locale: LanguageCodeUnderscored.EN_US,
        },
      ],
    };

    updateCancellationPolicy(payload);
  };

  const openAddModal = () => {
    openModal({
      id: cancellationPolicyModalId,
      customContent: (
        <CancellationPolicyModal
          onSubmit={handleAddSubmit}
          mutationKey={[ADD_CANCELLATION_POLICY_BASE_MUTATION_KEY]}
        />
      ),
    });
  };

  const openEditModal = (cancellationPolicyValue: CancellationPolicyValue) => {
    openModal({
      id: cancellationPolicyModalId,
      customContent: (
        <CancellationPolicyModal
          cancellationPolicyValue={cancellationPolicyValue}
          onSubmit={handleUpdateSubmit}
          mutationKey={[UPDATE_CANCELLATION_POLICY_BASE_MUTATION_KEY]}
        />
      ),
    });
  };

  return { openAddModal, openEditModal };
};

export default useCancellationPolicyModal;
