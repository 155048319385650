import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import useAppModal from 'hooks/useAppModal';
import { Trans } from 'react-i18next';
import Button from '../../../../button/Button';
import { requiresRebooking } from '../LeadModal.utils';
import useLeadRebook from '../useLeadRebook';
import LeadModalContext from '../LeadModalContext';
import useUpdateLeadOrBlockModal from '../useUpdateLeadOrBlockModal';
import { updateLeadModalId } from '../LeadModal.constants';
import {
  LeadModalFooterErrorMessage,
  LeadModalFooterOverlappingLink,
} from './LeadModalFooterMessages.styles';

const LeadModalFooterMessages = ({
  errorMessage,
  overlappingLeads,
}: {
  errorMessage: string | undefined;
  overlappingLeads: string[] | undefined;
}) => {
  const { closeModal } = useAppModal();
  const { lead } = useContext(LeadModalContext);
  const { prepareLeadRebookForm } = useLeadRebook();
  const { openModal } = useUpdateLeadOrBlockModal();
  const { checkInDate, checkOutDate, status } = useWatch();

  const redirectToBlockingLead = () => {
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const leadUid = overlappingLeads.find((uid) => uid !== lead?.uid);

    if (leadUid) {
      closeModal(updateLeadModalId);
      openModal(leadUid);
    }
  };

  const formatErrorMessage = () => {
    if (overlappingLeads?.length) {
      return (
        <Trans
          i18nKey="componentLead.modal.form.validation.removeOverlappingLeads"
          components={{
            1: (
              <LeadModalFooterOverlappingLink
                onClick={redirectToBlockingLead}
              />
            ),
          }}
        />
      );
    }

    return errorMessage;
  };

  return (
    <>
      {requiresRebooking({
        checkInDate,
        checkOutDate,
        status,
      }) && (
        <LeadModalFooterErrorMessage>
          {/* prettier breaks locale Trans component interpolation, so it must be excluded from formatting */}
          {/* prettier-ignore */}
          <Trans i18nKey="componentLead.modal.form.validation.requiresRebookingMessage">
            Archived bookings cannot be modified with future check-in or check-out dates.
            Please try <Button bsStyle="link" className="rebook-button" onClick={prepareLeadRebookForm}>re-booking</Button> instead
          </Trans>
        </LeadModalFooterErrorMessage>
      )}
      {!!errorMessage && (
        <LeadModalFooterErrorMessage>
          {formatErrorMessage()}
        </LeadModalFooterErrorMessage>
      )}
    </>
  );
};

export default LeadModalFooterMessages;
