import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'models/Country';
import { Lead, LeadStatus } from 'models/Leads';
import { LeadV3Source } from 'models/LeadsV3';
import { PinCode } from 'models/PinCode';
import { ReviewByHost } from 'models/Reviews';
import { Tag } from 'models/Tags';
import { getCountryCode } from 'utils/languageUtils';
import {
  getLeadArrivalDate,
  getLeadCheckInDate,
  getLeadCheckOutDate,
  getLeadDepartureDate,
} from 'utils/lead/leadUtils';
import { setHours } from 'date-fns';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { CreateLeadModalParams } from './LeadModal.types';
import LeadModalContext from './LeadModalContext';

const useLeadModalFormInitialValue = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { availableProperties } = useContext(LeadModalContext);

  return ({
    lead,
    leadForRebooking,
    leadTags,
    params,
    pinCode,
    reviewByHost,
    defaultPropertyUid,
    mainBookingAssigneeUid,
  }: {
    lead: Lead | undefined;
    leadForRebooking: Lead | undefined;
    leadTags: Tag[] | undefined;
    params: CreateLeadModalParams;
    pinCode: PinCode | undefined;
    reviewByHost: ReviewByHost | undefined;
    defaultPropertyUid: string | undefined;
    quote: HasOrderItems;
    mainBookingAssigneeUid: string | undefined;
  }) => {
    if (lead) {
      const {
        address,
        address2,
        adultCount,
        assignee,
        cellPhoneNumber,
        childrenCount,
        city,
        countryCode,
        creationMetadata,
        customData,
        email,
        externalBookingID,
        extraNotes,
        firstName,
        flightNumber,
        guest,
        infantCount,
        lastName,
        notes,
        petCount,
        phoneNumber,
        preferredLocale,
        property: { uid: propertyUid },
        reasonForTrip,
        rentalAgreementURL,
        reviewByHost: leadReviewByHost,
        secondaryEmail,
        source,
        state,
        status,
        uid,
        zipCode,
        order,
        unitUid,
      } = lead;
      const checkInDate = getLeadCheckInDate(lead);
      const checkOutDate = getLeadCheckOutDate(lead);
      const arrivalDate = getLeadArrivalDate(lead);
      const departureDate = getLeadDepartureDate(lead);

      return {
        address,
        address2,
        adultCount,
        arrivalDate,
        assigneeUid: assignee?.uid || mainBookingAssigneeUid,
        cellPhoneNumber,
        childrenCount,
        checkInDate,
        checkOutDate,
        city,
        countryCode: countryCode || getCountryCode(language) || CountryCode.US,
        departureDate,
        email,
        extraNotes,
        firstName,
        flightNumber,
        infantCount,
        lastName,
        notes,
        phoneNumber,
        petCount,
        pinCode,
        preferredLocale,
        propertyUid,
        reasonForTrip,
        reviewByHost: reviewByHost || leadReviewByHost,
        secondaryEmail,
        source,
        state,
        status,
        tags: leadTags,
        customData,
        uid,
        zipCode,
        guest,
        rentalAgreementURL,
        externalBookingID,
        creationMetadata,
        force100PercentPaymentAtReservation:
          order?.force100PercentPaymentAtReservation,
        unitUid,
        order,
        quote: null,
        currentGroupBookingLeadUid: lead.uid,
      };
    }

    const { dateFrom, dateTo, propertyUid } = params;
    const {
      address,
      address2,
      adultCount,
      cellPhoneNumber,
      childrenCount,
      city,
      countryCode,
      email,
      extraNotes,
      firstName,
      flightNumber,
      infantCount,
      lastName,
      notes,
      petCount,
      phoneNumber,
      preferredLocale,
      property,
      reasonForTrip,
      secondaryEmail,
      state,
      zipCode,
    } = leadForRebooking || {};

    const selectedPropertyUid =
      property?.uid || propertyUid || defaultPropertyUid;
    const selectedProperty = availableProperties?.find(
      (p) => p.uid === selectedPropertyUid,
    );

    const arrivalDate =
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      selectedProperty?.availability?.checkInTimeStart > -1
        ? // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          setHours(dateFrom, selectedProperty?.availability?.checkInTimeStart)
        : dateFrom;
    const departureDate =
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      selectedProperty?.availability?.checkOutTime > -1
        ? // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          setHours(dateTo, selectedProperty?.availability?.checkOutTime)
        : dateTo;

    return {
      adultCount: adultCount || 1,
      arrivalDate,
      checkInDate: dateFrom,
      checkOutDate: dateTo,
      childrenCount: childrenCount || 0,
      countryCode: countryCode || getCountryCode(language) || CountryCode.US,
      departureDate,
      infantCount: infantCount || 0,
      petCount: petCount || 0,
      propertyUid: selectedPropertyUid,
      source: LeadV3Source.HOSTFULLY_UI,
      status: leadForRebooking
        ? LeadStatus.BOOKED_BY_AGENT
        : LeadStatus.ON_HOLD,
      address,
      address2,
      cellPhoneNumber,
      city,
      email,
      extraNotes,
      firstName,
      flightNumber,
      lastName,
      notes,
      phoneNumber,
      preferredLocale,
      reasonForTrip,
      secondaryEmail,
      state,
      zipCode,
    };
  };
};

export default useLeadModalFormInitialValue;
