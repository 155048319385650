import { useContext, useEffect } from 'react';
import { runSyncCheckAction } from 'store/slices/syncCheck/sagas/syncCheckActions';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppUser from '../../../hooks/useAppUser';
import InboxContext from '../InboxContext';
import InboxMessagesContext from './InboxMessagesContext';
import { InboxBackgroundRefreshResponseTO } from './InboxMessages.types';

const messagesCheckInterval = 10000;

function getUpdatedLastCheckTimestamp() {
  return new Date().getTime() - messagesCheckInterval / 2;
}

let lastCheckTimestamp = getUpdatedLastCheckTimestamp();

const useInboxNewMessagesCheck = () => {
  const dispatch = useAppDispatch();
  const { isEmployee, agencyUid, userUid } = useAppUser();
  const { activeMessageThreadUid, setThreadsWithNewMessages } =
    useContext(InboxContext);
  const { addNewMessages, messages: currentMessages } =
    useContext(InboxMessagesContext);

  const { data: newMessages } = useAppQuery(
    ['newMessages'],
    async () => {
      const params = isEmployee ? { agencyUid } : { participantUid: userUid };

      const response = await API.get<InboxBackgroundRefreshResponseTO>(
        '/v3/inbox-background-refresh',
        {
          params: {
            ...params,
            fromTimestamp: lastCheckTimestamp,
          },
        },
      );

      lastCheckTimestamp = getUpdatedLastCheckTimestamp();

      return response.data.newMessages;
    },
    {
      enabled: true,
      refetchInterval: messagesCheckInterval, // this will be run every 10 seconds when the window is focused
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (newMessages) {
      // @ts-expect-error TS7034 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const threadsWithNewMessages = [];
      const inactiveThreadsUidsWithNewMessages = [];
      // @ts-expect-error TS7034 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const messagesToDisplay = [];

      newMessages.forEach(({ message, threadParticipantsReadStatuses }) => {
        const { threadUid, uid: messageUid } = message;
        const messageFromCurrentThread = activeMessageThreadUid === threadUid;

        if (
          // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          !threadsWithNewMessages.find(
            ({ threadUid: tUid }) => tUid === threadUid,
          )
        ) {
          threadsWithNewMessages.push({
            threadUid,
            participantsReadStatuses: threadParticipantsReadStatuses,
          });
        }

        if (!currentMessages.find(({ uid }) => uid === messageUid)) {
          if (messageFromCurrentThread) {
            messagesToDisplay.push(message);
          } else {
            inactiveThreadsUidsWithNewMessages.push(threadUid);
          }
        }
      });

      if (messagesToDisplay.length) {
        // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        addNewMessages(messagesToDisplay);
      }

      if (threadsWithNewMessages.length) {
        // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        setThreadsWithNewMessages(threadsWithNewMessages);
      }

      if (inactiveThreadsUidsWithNewMessages.length) {
        dispatch(runSyncCheckAction({})); // update unread items number in the main nav
      }
    }
  }, [newMessages]);

  return null;
};

export default useInboxNewMessagesCheck;
