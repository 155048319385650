import { useTranslation } from 'react-i18next';
import PhotoIcon from 'assets/images/icons/photo.svg?react';
import PropertyEditActionWrapper from '../../../common/PropertyEditActionWrapper';
import { AddPhotoTileContainer } from './UploadPhoto.styles';
import UploadPhotoWrapper from './UploadPhotoWrapper';

const UploadPhotoTile = () => {
  const { t } = useTranslation();

  return (
    <UploadPhotoWrapper id="upload-photo-tile">
      <PropertyEditActionWrapper tooltipId="upload-property-photo-tile-tooltip">
        <AddPhotoTileContainer data-testid="upload-photo-tile-button">
          <PhotoIcon />
          <span>{t('pageProperty.photos.addPhoto')}</span>
        </AddPhotoTileContainer>
      </PropertyEditActionWrapper>
    </UploadPhotoWrapper>
  );
};

export default UploadPhotoTile;
