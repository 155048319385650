import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import usePropertyOwners from './usePropertyOwners';

const PropertySettingsOwnersTabHeader = () => {
  const { t } = useTranslation();
  const { uid } = useParams();
  const propertyUid = uid as string;
  const { data, isLoading } = usePropertyOwners({
    propertyUid,
  });

  const hasNoOwner = data?.propertyOwnership?.owner === null;
  const disabled = isLoading || hasNoOwner;

  return (
    <Link
      to={`/owner-adjustments/${propertyUid}`}
      data-testid="manage-owner-adjustments-link"
    >
      <Button
        $colorScheme="green"
        data-testid="manage-owner-adjustments-button"
        disabled={disabled}
      >
        {t('pageProperty.owners.manageOwnerAdjustments')}
      </Button>
    </Link>
  );
};

export default PropertySettingsOwnersTabHeader;
