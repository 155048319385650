import { HelpBlock } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from '../../../utils/localeUtils';

interface ValidationError {
  /** key into translation file */
  key: string;

  /** token/params values */
  values?: { [key: string]: unknown };
}

interface ErrorMessageProps {
  error?: ValidationError | string;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation();

  if (error === undefined) {
    return null;
  }
  if (typeof error === 'string') {
    return <HelpBlock>{error}</HelpBlock>;
  }
  const { key, values } = error;

  return <HelpBlock>{t(normalizeKey(key), values)}</HelpBlock>;
};

ErrorMessage.defaultProps = {
  error: undefined,
};

export default ErrorMessage;
