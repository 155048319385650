import styled from 'styled-components';

export const Container = styled.section.attrs({
  className: 'container',
})`
  --refer-font-weight: 300;
  position: relative;
`;

export const Description = styled.p`
  font-weight: var(--refer-font-weight);
`;

export const Details = styled.ol`
  font-weight: var(--refer-font-weight);
`;

export const ReferFormWrap = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 25px;
  padding-left: 25px;

  .form-group {
    flex: 1 0 0;
    margin: 0;

    > div {
      width: 100%;
      padding: 0;
    }
  }

  .form-control[disabled] {
    background-color: inherit;
  }

  button {
    margin-left: 15px;
  }
`;

export const DetailsItem = styled.li``;
