import { useContext, useEffect } from 'react';
import useAvailableMessagingChannels from 'components/domain/template/useAvailableMessagingChannels';
import InboxContext from '../../InboxContext';

const useInboxAvailableMessagingChannels = () => {
  const {
    activeThread,
    activeLead,
    threadAvailableMessagingChannelsMap,
    updateThreadAvailableMessagingChannelsMap,
  } = useContext(InboxContext);
  const { uid: activeThreadUid } = activeThread || {};

  const {
    defaultMessagingChannel,
    channels,
    isLoadingMessagingChannels,
    fetchedAvailableMessagingChannels,
  } = useAvailableMessagingChannels({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    threadUid: activeThreadUid,
    availableMessagingChannelsMap: threadAvailableMessagingChannelsMap,
    lead: activeLead,
  });

  useEffect(() => {
    if (fetchedAvailableMessagingChannels) {
      updateThreadAvailableMessagingChannelsMap({
        // @ts-expect-error TS2464 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        [activeThreadUid]: fetchedAvailableMessagingChannels,
      });
    }
  }, [fetchedAvailableMessagingChannels]);

  return { defaultMessagingChannel, channels, isLoadingMessagingChannels };
};

export default useInboxAvailableMessagingChannels;
