import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Toaster } from 'react-hot-toast';

import GlobalStyles from 'styles/GlobalStyles';
import Router from './router';
import IntercomProviderWrapper from './utils/intercom/IntercomProviderWrapper';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/pages/calendar.css';
import 'i18n/setupInternationalization';
import 'utils/dragDropTouch/dragDropTouch';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      cacheTime: 0,
    },
  },
});

const App = () => {
  return (
    <>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <IntercomProviderWrapper>
            <Router />
          </IntercomProviderWrapper>
        </Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>

      <Toaster
        position="bottom-left"
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      />
    </>
  );
};

export default App;
