import useFeatures from 'hooks/useFeatures';
import { PropertyBusinessType } from 'models/Properties';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import PermissionsAwareFieldset from '../common/PermissionsAwareFieldset';
import PropertySettingsBetaAccessInfo from '../PropertySettingsBetaAccessInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { SectionContainer } from './PropertySettingsFeesAndPoliciesTab.styles';
import CancellationPolicy from './sections/cancellationPolicy/CancellationPolicy';
import PropertyExpectations from './sections/propertyExpectations/PropertyExpectations';
import HouseRules from './sections/houseRules/HouseRules';
import PropertyFeesAndTaxes from './sections/propertyFeesAndTaxes/PropertyFeesAndTaxes';
import RentalConditions from './sections/rentalConditions/RentalConditions';

const PropertySettingsFeesAndPoliciesTab = () => {
  const { allowPropertyEditV3Beta } = useFeatures();
  const { property } = usePropertySettingsInitData();

  if (allowPropertyEditV3Beta) {
    return <PropertySettingsBetaAccessInfo />;
  }

  const showCancellationPolicy =
    property?.businessType !== PropertyBusinessType.UNIT &&
    property?.businessType !== PropertyBusinessType.HOTEL;

  const showRentalConditions =
    property?.businessType !== PropertyBusinessType.HOTEL &&
    property?.businessType !== PropertyBusinessType.UNIT_TYPE;

  return (
    <>
      <PropertyEditPermissionInfo />
      <PermissionsAwareFieldset>
        <SectionContainer>
          <PropertyFeesAndTaxes />
          <HouseRules />
          <PropertyExpectations />
          {showCancellationPolicy && <CancellationPolicy />}
          {showRentalConditions && <RentalConditions />}
        </SectionContainer>
      </PermissionsAwareFieldset>
    </>
  );
};

export default PropertySettingsFeesAndPoliciesTab;
