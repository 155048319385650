/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import TextField from 'components/forms/textField/TextField';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { PropertyBusinessType } from 'models/Properties';
import { FormSectionColumn } from 'pages/property/common/Common.styles';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useIsMobileScreen from 'hooks/useIsMobileScreen';
import TooltipContentWithLearnMore from 'pages/property/common/tooltip/TooltipContentWithLearnMore';
import { useWatch } from 'react-hook-form';
import {
  DateTimePickerFieldCol,
  PropertyMiscInfoCollapseStyled,
} from './PropertyMiscInfo.styles';

const PropertyMiscInfo = () => {
  const propertyUid = useWatch({ name: 'propertyUid' });
  const isMobileScreen = useIsMobileScreen();
  const { property } = usePropertySettingsInitData();
  const { getCopyAddonIcon } = useCopyToClipboard();
  const { t } = useTranslation();

  const isAccountingIdFieldVisible =
    property?.businessType !== PropertyBusinessType.UNIT_TYPE;
  const isRentalLicenseNumberFieldVisible =
    property?.businessType === PropertyBusinessType.STANDALONE_PROPERTY ||
    property?.businessType === PropertyBusinessType.SUB_UNIT;
  const isExternalIdFieldVisible =
    property?.businessType !== PropertyBusinessType.HOTEL;
  const isWifiDetailsVisible =
    property?.businessType !== PropertyBusinessType.HOTEL;

  return (
    <PropertyMiscInfoCollapseStyled
      defaultExpanded
      label={t('pageProperty.mainSettings.miscInfo.title', {
        context: property?.businessType,
      })}
      variant="card"
      data-testid="property-misc-info"
    >
      <Row>
        <FormSectionColumn>
          <TextField
            name="propertyUid"
            label={t('pageProperty.mainSettings.miscInfo.uniqueId')}
            disabled
            rightAddons={[getCopyAddonIcon(propertyUid)]}
            colSmInput={7}
            colSmLabel={5}
          />
          {isExternalIdFieldVisible && (
            <TextField
              name="miscInfo.externalId"
              label={t('pageProperty.mainSettings.miscInfo.externalId')}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.miscInfo.externalIdTooltip',
              )}
            />
          )}
          {isAccountingIdFieldVisible && (
            <TextField
              name="miscInfo.accountingId"
              label={t('pageProperty.mainSettings.miscInfo.accountingId')}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.miscInfo.accountingIdTooltip',
              )}
            />
          )}
          {isRentalLicenseNumberFieldVisible && (
            <>
              <TextField
                name="miscInfo.rentalLicenseNumber"
                label={t(
                  'pageProperty.mainSettings.miscInfo.rentalLicenseNumber',
                )}
                colSmInput={7}
                colSmLabel={5}
                rightTooltip={
                  <TooltipContentWithLearnMore
                    labelTooltipText={t(
                      'pageProperty.mainSettings.miscInfo.rentalLicenseNumberTooltip',
                    )}
                    learnMoreUrl="https://help.hostfully.com/en/articles/8202150-quick-guide-to-your-property-settings"
                  />
                }
              />
              <DateTimePickerFieldCol>
                <DateTimePickerField
                  name="miscInfo.rentalLicenseExpirationDate"
                  label={`${t(
                    'pageProperty.mainSettings.miscInfo.rentalLicenseExpirationDate',
                  )}:`}
                  rightTooltip={t(
                    'pageProperty.mainSettings.miscInfo.rentalLicenseExpirationDateTooltip',
                  )}
                  labelOnTop={!isMobileScreen}
                />
              </DateTimePickerFieldCol>
            </>
          )}
        </FormSectionColumn>

        {isWifiDetailsVisible && (
          <FormSectionColumn>
            <TextField
              name="miscInfo.wifiNetwork"
              label={t('pageProperty.mainSettings.miscInfo.wifiNetwork')}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.miscInfo.valuesOnlyTemplateAirbnbTooltip',
              )}
            />
            <TextField
              name="miscInfo.wifiPassword"
              label={t('pageProperty.mainSettings.miscInfo.wifiPassword')}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.miscInfo.valuesOnlyTemplateAirbnbTooltip',
              )}
            />
          </FormSectionColumn>
        )}
      </Row>
    </PropertyMiscInfoCollapseStyled>
  );
};

export default PropertyMiscInfo;
