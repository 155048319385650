import defaultImage from 'assets/images/property/littlehouse.png';
import { Property } from 'models/Properties';
import { InboxProperty } from 'pages/inbox/Inbox.types';
import {
  LeadsSearchPropertiesGraphQLResponseItem,
  LeadsSearchResponseItem,
} from './LeadSearchField.types';
import useLeadSearchMutation from './useLeadSearchMutation';
import useFetchPropertiesForLeadsSearchMutation from './useFetchPropertiesForLeadsSearchMutation';

const useLeadSearchField = (
  preFetchedProperties?: Property[] | InboxProperty[],
) => {
  const { mutateAsync: searchLeads } = useLeadSearchMutation();
  const { mutateAsync: fetchProperties } =
    useFetchPropertiesForLeadsSearchMutation();

  const retrieveLeadPropertyPicture = (
    lead: LeadsSearchResponseItem,
    properties: LeadsSearchPropertiesGraphQLResponseItem[],
  ) => {
    const retrievedProperty = properties.find(
      (property) => property.uid === lead.propertyUid,
    );

    if (!retrievedProperty?.mainPicture?.tinyThumbnail) {
      return defaultImage;
    }

    return retrievedProperty.mainPicture.tinyThumbnail;
  };

  const flattenAndFilterPreFetchedProperties = (
    properties: Property[] | InboxProperty[],
    propertyUids: string[],
  ) => {
    const result: LeadsSearchPropertiesGraphQLResponseItem[] = [];

    properties.forEach((property) => {
      if (property.unitTypes && property.unitTypes.length) {
        property.unitTypes.forEach((unitType) => {
          result.push({
            uid: unitType.uid,
            // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            mainPicture: unitType.mainPicture,
          });
        });
      }

      if (property.subUnits && property.subUnits.length) {
        property.subUnits.forEach((subUnit) => {
          result.push({
            uid: subUnit.uid,
            // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            mainPicture: subUnit.mainPicture,
          });
        });
      }

      result.push({ ...property });
    });

    return result.filter(
      (property) =>
        !(propertyUids && propertyUids.length) ||
        propertyUids.includes(property.uid),
    );
  };

  const getPreFetchedPropertiesOrFetchFromGraphQL = async (
    propertyUids: string[],
  ) => {
    if (preFetchedProperties && preFetchedProperties.length) {
      return flattenAndFilterPreFetchedProperties(
        preFetchedProperties,
        propertyUids,
      );
    }
    return fetchProperties({ propertyUids });
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const searchHandler = async (query) => {
    const leads = await searchLeads({ query });

    if (!leads) {
      return [];
    }

    const propertyUids = leads.map((lead) => lead.propertyUid);

    const properties = await getPreFetchedPropertiesOrFetchFromGraphQL(
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUids,
    );

    leads.forEach((lead) => {
      const propertyPicture = retrieveLeadPropertyPicture(lead, properties);
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      lead.propertyPicture = propertyPicture;
    });

    return leads;
  };

  return {
    searchHandler,
  };
};

export default useLeadSearchField;
