import {
  PipelineFilter,
  PipelineFilterFormValues,
  PipelineFilterLeadFormValues,
} from '../Pipeline.types';
import {
  Lead,
  LeadChannel,
  LeadGroupedStatus,
  LeadStatus,
} from '../../../models/Leads';

const statusesMapping = {
  [LeadStatus.ON_HOLD]: [LeadStatus.ON_HOLD, LeadStatus.HOLD_EXPIRED],
};

const mapPipelineFilterFormValues = {
  lead: (leads: Lead[]) => ({
    uids: leads?.length ? [leads[0].uid] : '',
  }),
  leadStatus: (leadStatus: LeadStatus | string) => {
    if (!leadStatus) {
      return { groupedStatus: null, statuses: null };
    }

    if (Object.keys(LeadGroupedStatus).includes(leadStatus)) {
      return { groupedStatus: leadStatus, statuses: null };
    }

    return {
      groupedStatus: null,
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      statuses: statusesMapping[leadStatus] || [leadStatus],
    };
  },
  propertyUid: (propertyUid: string) => ({
    propertyUids: propertyUid ? [propertyUid] : [],
  }),
  channel: (channel: LeadChannel) => ({
    channels: channel ? [channel] : [],
  }),
  userUid: (userUidValue: string) => ({
    userUids: userUidValue ? [userUidValue] : [],
  }),
  range: (
    rangeValue:
      | {
          checkInFrom: Date | null;
          checkInTo: Date | null;
        }
      | undefined,
  ) => ({
    checkInFrom: rangeValue?.checkInFrom,
    checkInTo: rangeValue?.checkInTo,
  }),
};

export function convertPipelineFilterFormValues(
  pipelineFilterFormValues:
    | PipelineFilterFormValues
    | PipelineFilterLeadFormValues,
): PipelineFilter {
  // @ts-expect-error TS2740 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return Object.entries(pipelineFilterFormValues).reduce((values, entry) => {
    const key = entry[0];
    const value = entry[1];
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const fieldMapper = mapPipelineFilterFormValues[key];
    return {
      ...values,
      ...(fieldMapper ? fieldMapper(value) : { [key]: value }),
    };
  }, {});
}
