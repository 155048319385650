import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Tooltip from 'components/tooltip/Tooltip';
import { AirbnbGuestReview, AirbnbRatingStats } from 'models/Reviews';
import WarningIcon from 'assets/images/icons/warning.svg?react';
import { AIRBNB_RATING_STATS_QUERY_KEY } from '../../overallAirbnbRatings/useAirbnbRatingStats';
import { RatingCellContainer } from './RatingCell.styles';

const RatingCell = ({ row }: { row: Row<AirbnbGuestReview> }) => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const queryClient = useQueryClient();
  const airbnbRatingStats = queryClient.getQueryData<AirbnbRatingStats>([
    AIRBNB_RATING_STATS_QUERY_KEY,
    propertyUid,
  ]);

  const reviewUidsWithIssues = airbnbRatingStats?.reviewUidsWithIssues || [];
  const isReviewWithIssues = reviewUidsWithIssues.includes(row.original.uid);

  return (
    <RatingCellContainer>
      <span>{row.original.rating}</span>
      {isReviewWithIssues && (
        <Tooltip
          id="tooltip-recent-trip-issue-review"
          text={t(
            'pageProperty.reviews.airbnbGuest.overallAirbnbRating.recentTripIssues',
          )}
        >
          <WarningIcon data-testid="warning-icon-recent-trip-issue-review" />
        </Tooltip>
      )}
    </RatingCellContainer>
  );
};

export default RatingCell;
