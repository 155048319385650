import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAppUser from 'hooks/useAppUser';
import GoogleAnalyticsService from 'services/GoogleAnalytics';
import useFetchPricingPlan from 'components/domain/property/useFetchPricingPlan';

/**
 * Hook for logging custom page view events to Google Analytics every time
 * react-router-dom changes the location (based on `useLocation`).
 */
const useLogCustomPageView = (isEnabled: boolean) => {
  const location = useLocation();
  const { user, agencyUid } = useAppUser();
  const { data: pricingPlan, isLoading: isLoadingPricingPlan } =
    useFetchPricingPlan({ enabled: !!user });

  useEffect(() => {
    if (isEnabled && !isLoadingPricingPlan) {
      GoogleAnalyticsService.publishCustomPageView({
        location: `${location.pathname}${location.search}${location.hash}`,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        userRole: user?.employeeRole,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        subscriptionPan: pricingPlan?.currentPlan.category,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyCount: pricingPlan?.propertyCount,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        userUid: user?.uid,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        agencyUid,
        authentication: !!user?.uid,
      });
    }
  }, [isEnabled, location, user, agencyUid, isLoadingPricingPlan, pricingPlan]);
};

export default useLogCustomPageView;
