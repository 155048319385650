import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const AirbnbAlterationFormLeftColumn = styled(Col)`
  .check-out-date-container {
    margin-bottom: 30px;
  }

  .property-container {
    min-height: 70px;
  }

  .property-container > .control-label {
    padding-top: 0;
  }

  .form-column-inner:first-child {
    padding-right: 0;
  }

  .form-column-inner:last-child {
    padding-left: 0;
  }

  .property-select-label-picture {
    height: 55px;
    width: 65px;
  }
`;

export const AirbnbAlterationFormLeftColumnInner = styled(Col).attrs({
  className: 'form-column-inner',
})<{}>`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
`;

export const AirbnbAlterationFormRightColumn = styled(
  AirbnbAlterationFormLeftColumn,
)``;

export const AirbnbAlterationTitle = styled.p`
  font-weight: 700;
  text-align: center;
  font-size: 16px;
`;
