import useFeatures from 'hooks/useFeatures';
import PropertySettingsBetaAccessInfo from '../PropertySettingsBetaAccessInfo';

const PropertySettingsUnitsTab = () => {
  const { allowPropertyEditV3Beta } = useFeatures();

  if (allowPropertyEditV3Beta) {
    return <PropertySettingsBetaAccessInfo />;
  }

  return <div>TODO units</div>;
};

export default PropertySettingsUnitsTab;
