import { BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAvailableCountries from 'components/domain/country/useAvailableCountries';
import useFetchAgency from 'components/domain/agency/useFetchAgency';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import usePropertiesCreationAllowed from 'components/domain/property/usePropertiesCreationAllowed';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import useAppUser from 'hooks/useAppUser';
import AddPropertyLoadingPlaceholder from '../common/AddPropertyLoadingPlaceholder';
import { PropertyPageBreadcrumb } from '../../common/Common.styles';
import NotFound from '../../../notFound/NotFound';
import {
  addSinglePropertySchema,
  getDefaultFormValues,
} from './AddSingleProperty.schema';
import useAddSinglePropertySubmit from './useAddSinglePropertySubmit';
import AddSinglePropertyFormElements from './AddSinglePropertyFormElements';

const AddSingleProperty = () => {
  const { agencyUid } = useAppUser();
  const { isLoading: isLoadingAvailableCountries } = useAvailableCountries();
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } =
    useFetchPropertyTypes();
  const { data: agency, isLoading: isLoadingAgency } = useFetchAgency({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    agencyUid,
  });
  const { t } = useTranslation();

  const onSubmit = useAddSinglePropertySubmit();

  const propertiesCreationAllowed = usePropertiesCreationAllowed();

  if (
    isLoadingAvailableCountries ||
    isLoadingPropertyTypes ||
    isLoadingAgency
  ) {
    return <AddPropertyLoadingPlaceholder />;
  }

  if (!propertiesCreationAllowed) {
    return <NotFound />;
  }

  const pageTitle = t('common.pageTitles.property/add/single');

  return (
    <div className="container">
      <PropertyPageBreadcrumb>
        <BreadcrumbItem href="#/properties">
          {t('common.pageTitles.properties')}
        </BreadcrumbItem>
        <BreadcrumbItem active>{pageTitle}</BreadcrumbItem>
      </PropertyPageBreadcrumb>
      <PageHeader
        title={pageTitle}
        subtitle={t('pageProperty.add.single.subtitle')}
        noDivider
      />
      <FormWithProvider
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        defaultValues={getDefaultFormValues(propertyTypes, agency)}
        resolver={addSinglePropertySchema(t)}
        onSubmit={onSubmit}
        noValidate
      >
        <AddSinglePropertyFormElements />
      </FormWithProvider>
    </div>
  );
};

export default AddSingleProperty;
