import { CSSProperties } from 'react';
import type {
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  NodeKey,
  Spread,
} from 'lexical';
import { $applyNodeReplacement } from 'lexical';
import { LinkAttributes, LinkNode, SerializedLinkNode } from '@lexical/link';
import {
  convertCssPropertiesToCssText,
  convertStyleToCssProperties,
} from './lexicalNodes.utils';

interface AnchorPayload {
  attributes?: LinkAttributes;
  className?: string;
  style?: CSSProperties;
  key?: NodeKey;
  url: string;
}

export type SerializedAnchorNode = Spread<
  {
    className?: string;
    style?: CSSProperties;
  },
  SerializedLinkNode
>;

export class LinkStyledNode extends LinkNode {
  className: string;

  style: CSSProperties;

  constructor(
    url: string,
    attributes: LinkAttributes = {},
    className?: string,
    style?: CSSProperties,
    key?: NodeKey,
  ) {
    super(url, attributes, key);
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    this.className = className;
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    this.style = style;
  }

  static getType(): string {
    return 'link';
  }

  static clone(node: LinkStyledNode): LinkStyledNode {
    const { __rel: rel, __target: target, __title: title, __url: url } = node;
    return new LinkStyledNode(
      url,
      { rel, target, title },
      node.className,
      node.style,
    );
  }

  static importDOM(): DOMConversionMap | null {
    return {
      a: (_node: Node) => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: convertAnchorElement,
        priority: 0,
      }),
    };
  }

  static importJSON(serializedNode: SerializedAnchorNode): LinkStyledNode {
    const { className, rel, style, target, title, url } = serializedNode;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return $createAnchorNode({
      attributes: { rel, target, title },
      className,
      style,
      url,
    });
  }

  createDOM(config: EditorConfig): HTMLAnchorElement {
    const dom = super.createDOM(config);

    if (this.className) {
      dom.setAttribute('class', this.className);
    }

    if (this.style) {
      dom.setAttribute('style', convertCssPropertiesToCssText(this.style));
    }
    return dom;
  }

  exportJSON(): SerializedAnchorNode {
    return {
      ...super.exportJSON(),
      className: this.className,
      style: this.style,
    };
  }
}

export function $createAnchorNode({
  attributes,
  className,
  key,
  style,
  url,
}: AnchorPayload): LinkStyledNode {
  return $applyNodeReplacement(
    new LinkStyledNode(url, attributes, className, style, key),
  );
}

function convertAnchorElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLAnchorElement) {
    const { className, href, rel, style, target, title } = domNode;

    const node = $createAnchorNode({
      attributes: { rel, target, title },
      className,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      style: convertStyleToCssProperties(style),
      url: href,
    });
    return { node };
  }
  return null;
}
