/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */

// @ts-expect-error TS7034 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
import DragDropTouch from './dragDropTouch.original';

/*
 * This is a list of paths that are allowed to use drag and drop.
 * If the path is not in this list, the drag and drop will not be enabled.
 * Please update this list manually. This extra step helps prevent accidental enabling of drag & drop on mobile
 * and ensures developers consciously decide which paths should support this functionality.
 */
export const DRAG_DROP_ENABLED_PATHS = ['/property'];

// @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const { _shouldHandle } = DragDropTouch.DragDropTouch.prototype;

// @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
DragDropTouch.DragDropTouch.prototype._shouldHandle = function (e) {
  const isAllowedPath = DRAG_DROP_ENABLED_PATHS.some((path) =>
    window.location.hash.includes(path),
  );

  if (!isAllowedPath) {
    return false;
  }

  return _shouldHandle.call(this, e);
};
