import PermissionsAwareFieldset from 'pages/property/common/PermissionsAwareFieldset';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TemplatesTriggerLinkButton } from './AirbnbGuestReviews.styles';

const TemplatesTriggerLink = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirect = () => {
    navigate('/my-templates#reviews');
  };

  return (
    <PermissionsAwareFieldset>
      <PropertyEditActionWrapper tooltipId="link-my-templates-reviews-tooltip">
        <TemplatesTriggerLinkButton
          id="link-my-templates-reviews"
          onClick={handleRedirect}
        >
          {t(
            'pageProperty.reviews.airbnbGuest.automatedReviews.templateTriggersReviewLink',
          )}
        </TemplatesTriggerLinkButton>
      </PropertyEditActionWrapper>
    </PermissionsAwareFieldset>
  );
};

export default TemplatesTriggerLink;
