import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LeadModalForm } from 'components/domain/lead/modal/LeadModal.styles';
import { Modal } from 'react-bootstrap';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import { AppEventType } from 'models/Events';
import { format } from 'date-fns';
import Loader from '../../../loader/Loader';
import useAirbnbAlterationActions from '../useAirbnbAlterationActions';
import useAirbnbAlterationModalData from './useAirbnbAlterationModalData';
import useAirbnbAlterationModalFormInitialValue from './useAirbnbAlterationModalFormInitialValue';
import airbnbAlterationRequestFormSchema from './UpdateAirbnbAlterationModal.schema';
import AirbnbAlterationRequestModalHeader from './AirbnbAlterationRequestModalHeader';
import AirbnbAlterationRequestModalBody from './AirbnbAlterationRequestModalBody';
import AirbnbAlterationRequestModalFooter from './AirbnbAlterationRequestModalFooter';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';

const AirbnbAlterationRequestModalFormWrapper = () => {
  const { modalId } = useContext(AirbnbAlterationRequestModalContext);
  const { isLoading, lead, airbnbAlterationData } =
    useAirbnbAlterationModalData();
  const prepareFormInitialValue = useAirbnbAlterationModalFormInitialValue();
  const { t } = useTranslation();
  const { sendAlterationRequest } = useAirbnbAlterationActions();
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onSubmit = async (values) => {
    const { airbnbAlteration } = values;

    await sendAlterationRequest({
      airbnbAlterationQuote: {
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        leadUid: lead?.uid,
        propertyUid: airbnbAlteration?.propertyUid,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        startDate: airbnbAlteration?.startDate
          ? format(airbnbAlteration.startDate, 'yyyy-MM-dd')
          : null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        endDate: airbnbAlteration?.endDate
          ? format(airbnbAlteration.endDate, 'yyyy-MM-dd')
          : null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        numberOfAdults: parseInt(airbnbAlteration?.numberOfAdults, 10) || null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        numberOfChildren:
          parseInt(airbnbAlteration?.numberOfChildren, 10) || null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        numberOfInfants:
          parseInt(airbnbAlteration?.numberOfInfants, 10) || null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        numberOfPets: parseInt(airbnbAlteration?.numberOfPets, 10) || null,
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        price: parseFloat(airbnbAlteration?.price) || null,
      },
    });
    closeModal(modalId);
    publish(AppEventType.LEAD_UPDATED, { lead: { uid: lead?.uid } });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <LeadModalForm
      defaultValues={prepareFormInitialValue({
        lead,
        airbnbAlterationData,
      })}
      onSubmit={onSubmit}
      schema={airbnbAlterationRequestFormSchema(t)}
    >
      <AirbnbAlterationRequestModalHeader />
      <Modal.Body data-testid="airbnb-alteration-request-modal-body">
        <AirbnbAlterationRequestModalBody />
      </Modal.Body>
      <AirbnbAlterationRequestModalFooter />
    </LeadModalForm>
  );
};

export default AirbnbAlterationRequestModalFormWrapper;
