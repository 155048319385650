import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { UseQueryOptions } from '@tanstack/react-query';
import { GetPropertyRulesResponse } from 'models/Rule';

export const PROPERTY_RULES_QUERY_KEY = 'property-rules';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetPropertyRulesResponse>,
  'queryKey' | 'queryFn'
>;

type UseFetchPropertyRulesArgs = {
  isHotel: boolean;
  propertyUid: string;
} & ReactQueryOptions;

const useFetchPropertyRules = ({
  isHotel,
  propertyUid,
  enabled = true,
  ...options
}: UseFetchPropertyRulesArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PROPERTY_RULES_QUERY_KEY, 'list', propertyUid],
    queryFn: async () => {
      const response = await API.get<GetPropertyRulesResponse>(
        '/api/v3.2/property-rules',
        {
          params: {
            [isHotel ? 'hotelUid' : 'propertyUid']: propertyUid,
          },
        },
      );

      return response.data;
    },
    enabled: enabled && !!propertyUid,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchPropertyRules;
