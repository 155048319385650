import { i18nLanguageToLocaleCode } from './constants/languages';

export function getLanguageCode(localeCode: string | undefined) {
  return localeCode?.split(/[-_]/)[0];
}

export function getCountryCode(localeCode: string | undefined) {
  const parts = localeCode?.split(/[-_]/);

  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return parts?.length > 1 ? parts[1] : null;
}

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
export function getAvailableI18nLanguage(localeCode) {
  return (
    // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    i18nLanguageToLocaleCode[getLanguageCode(localeCode)] ||
    i18nLanguageToLocaleCode.en
  );
}
