import { useCallback } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import {
  AssignmentSettingsAssignee,
  GetAssignmentSettingsResponseTO,
} from 'models/AssignmentSettings';
import { Employee } from 'models/Employees';
import useFetchEmployees from '../employee/useFetchEmployees';
import useFetchAssignmentSettings from './useFetchAssignmentSettings';

export type AssigneeWithEmployee = AssignmentSettingsAssignee & {
  employee: Employee;
};

export type AssignmentSettingsWithEmployee = Omit<
  GetAssignmentSettingsResponseTO,
  'assignees'
> & {
  assignees: AssigneeWithEmployee[];
};

type ReactQueryOptions = Omit<
  UseQueryOptions<AssignmentSettingsWithEmployee>,
  'queryKey' | 'queryFn'
>;

type UseFetchAssignmentSettingsWithEmployeesArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchAssignmentSettingsWithEmployees = ({
  propertyUid,
  ...options
}: UseFetchAssignmentSettingsWithEmployeesArgs) => {
  const { employees, isFetching } = useFetchEmployees(true);
  const enabled = !!employees && !isFetching;
  const select = useCallback(
    (assignmentSettings: GetAssignmentSettingsResponseTO) => {
      return {
        ...assignmentSettings,
        assignees: assignmentSettings.assignees.map((data) => ({
          ...data,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          employee: employees.find(({ uid }) => data.employeeUid === uid),
        })),
      };
    },
    [employees],
  );

  return useFetchAssignmentSettings<AssignmentSettingsWithEmployee>({
    propertyUid,
    enabled,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    select,
    ...options,
  });
};

export default useFetchAssignmentSettingsWithEmployees;
