import API from 'services/API';
import { call, put, takeLeading } from 'redux-saga/effects';
import { convertUserToAppUser } from 'utils/user/userUtils';
import { setAppUser } from '../index';
import { GetUserAction, UserActionType } from './userActions';

function* getUser({ payload }: GetUserAction) {
  const { callback } = payload || {};

  try {
    // @ts-expect-error TS7057 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const response = yield call(API.get, 'v3/me');

    if (response.status === 200 && response.data?.me) {
      const appUser = convertUserToAppUser(response.data.me);
      yield put(setAppUser({ appUser }));

      if (callback) {
        callback(true);
      }
    }
  } catch (error) {
    if (callback) {
      callback(false);
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* getUserSaga() {
  yield takeLeading(UserActionType.GET_USER, getUser);
}
