import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetPropertyRoomsResponseTO } from 'models/PropertyRooms';
import API from 'services/API';

export const PROPERTY_ROOMS_BASE_QUERY_KEY = 'property-rooms';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetPropertyRoomsResponseTO['rooms']>,
  'queryKey' | 'queryFn'
>;

type UseFetchPropertyRoomsArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchPropertyRooms = ({
  propertyUid,
  enabled = true,
  ...options
}: UseFetchPropertyRoomsArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [PROPERTY_ROOMS_BASE_QUERY_KEY, propertyUid],
    queryFn: async () => {
      const response = await API.get<GetPropertyRoomsResponseTO>(
        `/api/v3.1/rooms?propertyUid=${propertyUid}`,
      );

      return response.data.rooms;
    },
    enabled: enabled && !!propertyUid,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchPropertyRooms;
