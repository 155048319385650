import React, { useEffect } from 'react';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import SelectField from 'components/forms/selectField/SelectField';
import Tooltip from 'components/tooltip/Tooltip';
import { Channel } from 'models/Channels';
import { Property } from 'models/Properties';
import { FeeScopeEnum } from 'models/PropertyFees';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import { MappingTypeContainer } from './PropertyFeeModal.styles';
import { BY_LENGTH_OF_STAY_SCOPE_OPTION } from './useFieldsOptions';
import useFormData from './useFormData';

interface MappingTypeFieldProps {
  name: string;
  label: string;
  channel: Channel;
  options: string[];
  icon: React.ReactElement;
  labelInputSizes: {
    colSmLabel: number;
    colSmInput: number;
  };
  propertyUid: Property['uid'];
}

const MappingTypeField: React.FC<MappingTypeFieldProps> = ({
  name,
  label,
  options,
  channel,
  icon,
  labelInputSizes,
  propertyUid,
}) => {
  const { t } = useTranslation();
  const { isTypeTax, scopeValue, taxConditionType, isOptional, isTypeFee } =
    useFormData();
  const { setValue } = useFormContext();
  const { channelSettings, isFetching } =
    useFetchPropertyChannelSettings(propertyUid);

  const isChannelActiveAtProperty = !!channelSettings?.find(
    ({ enumId }) => enumId === channel,
  )?.isActive;

  const isAirbnbMappingType = channel === Channel.airbnb;
  const isVrboMappingType = channel === Channel.homeaway;
  const isPetRelatedScope =
    scopeValue === FeeScopeEnum.PER_PET ||
    scopeValue === FeeScopeEnum.PER_PET_PER_NIGHT;
  const isPetSupportedMappingType = isAirbnbMappingType || isVrboMappingType;
  const isPetScopeNotSupported =
    isPetRelatedScope && !isPetSupportedMappingType;

  const isOptionalFee = isTypeFee && isOptional;

  const isTaxConditionNotSupported =
    isTypeTax &&
    taxConditionType === BY_LENGTH_OF_STAY_SCOPE_OPTION &&
    !isAirbnbMappingType;

  const isMappingTypeUnsupported =
    !isChannelActiveAtProperty ||
    isPetScopeNotSupported ||
    isTaxConditionNotSupported ||
    isOptionalFee;

  const mappingTypeErrorClassName = isMappingTypeUnsupported ? 'has-error' : '';

  const getMappingTypeOptions = () => {
    if (isMappingTypeUnsupported) {
      return [
        {
          value: 'UNSUPPORTED',
          label: t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.notSupported',
          ),
        },
      ];
    }
    return [
      {
        value: '',
        label: t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.pleaseSelect',
        ),
      },
      ...options.map((value) => ({
        value,
        label: t(
          normalizeKey(
            `pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.channelSpecific.${name}Options.${value}`,
          ),
        ),
      })),
    ];
  };

  // Set undefined value when mapping type is unsupported
  useEffect(() => {
    if (isFetching) return;

    if (isMappingTypeUnsupported) {
      setValue(`${name}MappingType`, null);
    }
  }, [
    isMappingTypeUnsupported,
    isFetching,
    isChannelActiveAtProperty,
    scopeValue,
    isPetScopeNotSupported,
    isTaxConditionNotSupported,
    isOptionalFee,
  ]);

  const field = (
    <MappingTypeContainer className={mappingTypeErrorClassName}>
      <SelectField
        name={`${name}MappingType`}
        label={label}
        {...labelInputSizes}
        options={getMappingTypeOptions()}
        disabled={isMappingTypeUnsupported}
      />
      {icon}
    </MappingTypeContainer>
  );

  if (isMappingTypeUnsupported) {
    return (
      <Tooltip
        id={`tooltip-${name}`}
        text={t(
          !isChannelActiveAtProperty
            ? 'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.channelNotAvailableProperty'
            : 'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.channelNotAvailableScope',
        )}
      >
        {field}
      </Tooltip>
    );
  }

  return field;
};

export default MappingTypeField;
