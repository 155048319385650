import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import useOpenAirbnbAlterationRequestModal from 'components/domain/airbnbAlterationRequest/useOpenAirbnbAlterationRequestModal';
import { LeadViewButton } from './LeadListItemViewButton.styles';

const LeadListItemReviewAlterationButton = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { updateAirbnbAlterationRequestModal } =
    useOpenAirbnbAlterationRequestModal();

  const { airbnbAlteration } = lead;

  if (!airbnbAlteration) {
    return null;
  }

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onClick = (e) => {
    e.preventDefault();
    updateAirbnbAlterationRequestModal(lead);
  };

  return (
    <LeadViewButton
      className="lead-review-alteration-button"
      data-testid="lead-review-alteration-button"
      onClick={onClick}
    >
      {t('componentLead.details.reviewAlteration')}
    </LeadViewButton>
  );
};

export default LeadListItemReviewAlterationButton;
