import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import TextField from 'components/forms/textField/TextField';
import { useWatch } from 'react-hook-form';
import { ConditionContainer } from './PropertyFeeModal.styles';
import { BY_LENGTH_OF_STAY_SCOPE_OPTION } from './useFieldsOptions';

interface Option {
  value: string;
  label: string;
}

const TaxConditionField = ({
  labelInputSizes,
  conditionByLengthOfStayOptions,
  conditionTypeOptions,
}: {
  conditionByLengthOfStayOptions: Option[];
  conditionTypeOptions: Option[];
  labelInputSizes: {
    colSmLabel: number;
    colSmInput: number;
  };
}) => {
  const { t } = useTranslation();

  const isByLengthOfStayCondition =
    useWatch({ name: 'tax.conditions.type' }) ===
    BY_LENGTH_OF_STAY_SCOPE_OPTION;

  return (
    <ConditionContainer>
      <RadioGroupField
        name="tax.conditions.type"
        label={t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.condition',
        )}
        required
        {...labelInputSizes}
        options={conditionTypeOptions}
      />

      {isByLengthOfStayCondition && (
        <>
          <Col className="col-md-offset-5">
            <RadioGroupField
              name="tax.conditions.lengthOfStayType"
              horizontal
              options={conditionByLengthOfStayOptions}
              {...labelInputSizes}
            />
          </Col>

          <Col xs={8} className="col-md-offset-4">
            <TextField
              name="tax.conditions.lengthOfStayValue"
              label={t(
                'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.forStaysOver',
              )}
              type="number"
              min={1}
              rightAddons={[
                t(
                  'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.nights',
                ),
              ]}
              required
              {...labelInputSizes}
            />
          </Col>
        </>
      )}
    </ConditionContainer>
  );
};

export default TaxConditionField;
