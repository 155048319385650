import { useEffect } from 'react';
import AnalyticsContextProvider from 'pages/analytics/AnalyticsContextProvider';
import { Container } from 'pages/inbox/Inbox.styles';
import {
  getDashboardAnalyticsFilterLocalStorageKey,
  getDashboardChartsFilterLocalStorageKey,
} from 'pages/analytics/types/Analytics.constants';
import useAppUser from 'hooks/useAppUser';
import useOpenJobModal from 'components/modal/jobModal/useOpenJobModal';
import useOpenBlockModal from 'components/modal/blockModal/useOpenBlockModal';
import DashboardContextProvider from './DashboardContextProvider';
import ContentWrapper from './content/ContentWrapper';
import useUpdateUserActivity from './useUpdateUserActivity';

const Dashboard = () => {
  const { userUid } = useAppUser();
  const { updateUserActivity } = useUpdateUserActivity();
  useOpenJobModal();
  useOpenBlockModal({ listenerCreationNotAllowed: false });

  useEffect(() => {
    updateUserActivity();
  }, []);

  return (
    <DashboardContextProvider>
      <AnalyticsContextProvider
        analyticsFilterLocalStorageKey={getDashboardAnalyticsFilterLocalStorageKey(
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          userUid,
        )}
        chartsFilterLocalStorageKey={getDashboardChartsFilterLocalStorageKey(
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          userUid,
        )}
      >
        <Container>
          <ContentWrapper />
        </Container>
      </AnalyticsContextProvider>
    </DashboardContextProvider>
  );
};

export default Dashboard;
