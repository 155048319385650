import { useContext } from 'react';
import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { HouseRule } from 'models/HouseRules';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { ObjectUidFetchType } from 'models/_base';
import { Property } from 'models/Properties';
import useNotify from 'hooks/useNotify';
import { handlePaginationAfterDelete } from 'utils/pagination';
import TableContext from 'components/table/TableContext';
import { HOUSE_RULES_BASE_QUERY_KEY } from '../HouseRules.utils';

const useDeleteModal = ({ houseRule }: { houseRule: HouseRule }) => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { notifyError } = useNotify();
  const { openConfirmModal } = useAppModal();
  const { tableInstance } = useContext(TableContext);

  const mutationKey = ['house-rule-delete', houseRule?.uid];

  const { mutateAsync: deleteHouseRule } = useAppMutation(
    mutationKey,
    async ({
      uid,
      objectUidFetchType,
      objectUids,
    }: {
      uid: HouseRule['uid'];
      objectUidFetchType: ObjectUidFetchType;
      objectUids: Property['uid'][];
    }) => {
      await API.delete('/api/internal/house-rules/bulk-delete', {
        data: {
          uid,
          objectUidFetchType,
          objectUids,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          HOUSE_RULES_BASE_QUERY_KEY,
          'all',
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);

        // closeModal(bulkDeleteModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleDelete = async () => {
    // const { updateAllProperties, propertiesUids } =
    //   extractPropertiesMultiSelectFields('appliesTo', values);

    // const objectUidFetchType = updateAllProperties
    //   ? ObjectUidFetchType.ALL
    //   : ObjectUidFetchType.SPECIFIC;

    // const objectUids = updateAllProperties ? [] : propertiesUids;

    await deleteHouseRule({
      uid: houseRule?.uid,
      objectUidFetchType: ObjectUidFetchType.SPECIFIC,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      objectUids: [propertyUid],
    });
  };

  // const defaultValues = {
  //   appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
  //   appliesToProperties: houseRule?.metadata?.propertiesThatAppliesTo?.map(
  //     ({ uid, name }) => ({
  //       label: name,
  //       value: uid,
  //     }),
  //   ),
  // };

  const openBulkDeleteModal = () => {
    openConfirmModal({
      body: t(
        'pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.description',
      ),
      title: t(
        'pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.title',
      ),
      onConfirmAsync: handleDelete,
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
    });

    // openModal({
    //   id: bulkDeleteModalId,
    //   customContent: (
    //     <BulkDeleteModal
    //       title={t(
    //         'pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.title',
    //       )}
    //       description={t(
    //         'pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.description',
    //       )}
    //       onSubmit={handleDelete}
    //       defaultValues={defaultValues}
    //       mutationKey={mutationKey}
    //       propertyMultiSelectProps={{
    //         enableHotels: false,
    //         enableUnitTypes: true,
    //       }}
    //     />
    //   ),
    // });
  };

  return {
    openBulkDeleteModal,
  };
};

export default useDeleteModal;
