import styled from 'styled-components';

export const PropertySettingsServiceProvidersTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .edit-permission-info {
    margin-bottom: 0;
  }
`;
