import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { PricingRule } from 'models/pricing/PricingRule';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import PriceRulesAddEditModal from './PriceRulesAddEditModal';

const modalId = 'price-rules-add-edit-modal';

const usePriceRulesAddEditModal = () => {
  const { openModal } = useAppModal();
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();

  const openAddEditModal = ({
    pricingRule,
  }: { pricingRule?: PricingRule } = {}) => {
    openModal({
      customContent: (
        <PriceRulesAddEditModal
          modalId={modalId}
          pricingRule={pricingRule}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          property={property}
        />
      ),
      id: modalId,
      title: pricingRule
        ? t('pageProperty.pricing.priceRules.modal.editTitle')
        : t('pageProperty.pricing.priceRules.modal.addTitle'),
    });
  };

  const openAddPriceRulesModal = () => {
    openAddEditModal();
  };

  const openEditPriceRulesModal = ({
    pricingRule,
  }: {
    pricingRule: PricingRule;
  }) => {
    openAddEditModal({ pricingRule });
  };

  return { openAddPriceRulesModal, openEditPriceRulesModal };
};

export default usePriceRulesAddEditModal;
