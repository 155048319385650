import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetServicesResponseTO } from 'models/Service';
import API from 'services/API';

export const AGENCY_SERVICES_BASE_QUERY_KEY = 'services';

type Props = Omit<
  UseQueryOptions<GetServicesResponseTO>,
  'queryKey' | 'queryFn'
> & {
  agencyUid: string;
};

const useAgencyServicesQuery = ({ agencyUid, ...options }: Props) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [AGENCY_SERVICES_BASE_QUERY_KEY, agencyUid],
    queryFn: async () => {
      const response = await API.get<GetServicesResponseTO>(
        '/api/v3.2/services',
        {
          params: {
            agencyUid,
          },
        },
      );
      return response.data;
    },
    staleTime: 30_000,
    enabled: !!agencyUid,
    ...(options ?? {}),
  });
};

export default useAgencyServicesQuery;
