import { useMemo, useState } from 'react';
import { startOfMonth, endOfMonth, addMonths } from 'date-fns';
import { CalendarFilter } from './Calendar.types';
import { calendarConstants } from './Calendar.constant';
import StandaloneCalendarContext from './CalendarContext';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const StandaloneCalendarContextProvider = ({ children }) => {
  const [calendarFilter, setCalendarFilter] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(
      addMonths(
        new Date(),
        calendarConstants.numberOfMonthsToDisplayOnCalendar - 1,
      ),
    ),
  } as CalendarFilter);

  const contextValue = useMemo(
    () => ({
      calendarFilter,
      setCalendarFilter,
    }),
    [calendarFilter],
  );
  return (
    <StandaloneCalendarContext.Provider value={contextValue}>
      {children}
    </StandaloneCalendarContext.Provider>
  );
};

export default StandaloneCalendarContextProvider;
