import CommonTable from 'components/table/CommonTable';
import useTable from 'components/table/hooks/useTable';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { sortByColumns } from 'components/table/utils/sorting';
import { ServiceProviderTableItem } from '../../useServiceProvidersList';
import { TableContainer } from './ServiceProvidersTable.styles';
import ServiceProviderTableHeader from './ServiceProviderTableHeader';
import StatusCell from './cells/StatusCell';

const ServiceProvidersTable = ({
  serviceProviders,
}: {
  serviceProviders: ServiceProviderTableItem[];
}) => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ServiceProviderTableItem>();

  const columns = [
    columnHelper.accessor('firstName', {
      header: t('pageProperty.serviceProviders.table.name'),
      cell: ({ row }) => {
        return `${row.original.firstName} ${row.original.lastName}`;
      },
      sortingFn: sortByColumns(['firstName', 'lastName']),
    }),
    columnHelper.accessor('type', {
      header: t('pageProperty.serviceProviders.table.type'),
      sortingFn: sortByColumns(['type']),
      cell: ({ row }) => {
        return t(`pageProperty.serviceProviders.type.${row.original.type}`);
      },
    }),
    columnHelper.accessor('servicesTitles', {
      header: t('pageProperty.serviceProviders.table.services'),
      enableSorting: false,
    }),
    columnHelper.display({
      header: t('pageProperty.serviceProviders.table.status'),
      cell: StatusCell,
    }),
  ];

  const tableInstance = useTable({
    data: serviceProviders,
    columns,
    manualPagination: true,
    pageCount: 1,
  });

  return (
    <TableContainer>
      <ServiceProviderTableHeader />

      <CommonTable
        tableInstance={tableInstance}
        data-testid="service-providers-table"
      />
    </TableContainer>
  );
};

export default ServiceProvidersTable;
