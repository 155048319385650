import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';

const PriceRulesAddEditModalNameField = () => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t('pageProperty.pricing.priceRules.modal.form.name')}
      name="priceRule.name"
      colSmInput={8}
      colSmLabel={4}
      placeholder={t(
        'pageProperty.pricing.priceRules.modal.form.namePlaceholder',
      )}
      required
    />
  );
};

export default PriceRulesAddEditModalNameField;
