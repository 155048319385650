import { HTMLAttributes, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UploadIcon from 'components/icons/Upload.svg?react';
import Button from 'components/button/Button';
import GenericFormField from '../../genericFormField/GenericFormField';
import FileField from '../FileField';
import { ButtonWrapper } from './UploadField.styles';

interface UploadFieldProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  help?: string;
  accept?: string;
  children?: ReactNode;
  colSmInput?: number;
  colSmLabel?: number;
}

const UploadField = ({
  name,
  help,
  label,
  accept,
  className,
  children,
  colSmInput,
  colSmLabel,
  ...rest
}: UploadFieldProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const fieldRenderer = () => (
    <ButtonWrapper className="upload-field-button-wrapper">
      <FileField
        name={name}
        aria-hidden="true"
        accept={accept}
        ref={fileInputRef}
        {...rest}
      />
      {children}
      <Button
        className={className}
        data-testid="upload-button"
        // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        onClick={() => fileInputRef.current?.click()}
      >
        <UploadIcon />
        {t('common.upload')}
      </Button>
    </ButtonWrapper>
  );

  return (
    <GenericFormField
      name={name}
      fieldRenderer={fieldRenderer}
      help={help}
      label={label}
      colSmInput={colSmInput}
      colSmLabel={colSmLabel}
    />
  );
};

UploadField.defaultProps = {
  help: '',
  label: '',
  accept: undefined,
  children: null,
  colSmInput: undefined,
  colSmLabel: undefined,
};

export default UploadField;
