import { useCallback, useMemo } from 'react';
import useAppUser from 'hooks/useAppUser';
import useLocalStorage from 'hooks/useLocalStorage';
import { getPipelineFilterLocalStorageKey } from '../Pipeline.constants';
import { PipelineFilterFormValues } from '../Pipeline.types';

const usePipelineFiltersFormStorage = () => {
  const { userUid } = useAppUser();
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const localStorageKey = getPipelineFilterLocalStorageKey(userUid);
  const {
    clearLocalStorage: clearStoredFilterFormValues,
    getValue,
    setValue,
  } = useLocalStorage<PipelineFilterFormValues>(localStorageKey);

  const getStoredFilterFormValues =
    useCallback((): PipelineFilterFormValues => {
      const storedFilterFormValues = getValue();

      if (storedFilterFormValues) {
        const { range, ...rest } = storedFilterFormValues;

        return {
          ...rest,
          range: {
            checkInFrom: range?.checkInFrom ? new Date(range.checkInFrom) : '',
            checkInTo: range?.checkInTo ? new Date(range.checkInTo) : '',
          },
        };
      }

      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      return null;
    }, [getValue]);

  const storeFilterFormValues = useCallback(
    (formValues: PipelineFilterFormValues) => {
      const filterWithoutLead = { ...formValues, lead: undefined };
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setValue(filterWithoutLead);
    },
    [setValue],
  );

  return useMemo(
    () => ({
      clearStoredFilterFormValues,
      getStoredFilterFormValues,
      storeFilterFormValues,
    }),
    [
      clearStoredFilterFormValues,
      getStoredFilterFormValues,
      storeFilterFormValues,
    ],
  );
};

export default usePipelineFiltersFormStorage;
