import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { GetOverrideAirbnbPricingRulesResponseTO } from 'models/pricing/PricingRule';

export const OVERRIDE_AIRBNB_PRICING_RULES_BASE_QUERY_KEY = 'override-airbnb';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetOverrideAirbnbPricingRulesResponseTO | null>,
  'queryKey' | 'queryFn'
>;

type UseFetchOverrideAirbnbPricingRulesArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchOverrideAirbnbPricingRules = ({
  propertyUid,
  enabled = true,
  ...options
}: UseFetchOverrideAirbnbPricingRulesArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [OVERRIDE_AIRBNB_PRICING_RULES_BASE_QUERY_KEY, propertyUid],
    queryFn: async () => {
      const response =
        await API.get<GetOverrideAirbnbPricingRulesResponseTO | null>(
          `/api/internal/pricing-rules/${propertyUid}/override-airbnb-pricing-rules`,
        );

      return response.data;
    },
    enabled: enabled && !!propertyUid,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchOverrideAirbnbPricingRules;
