import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import FormWithProvider from 'components/forms/form/Form';
import {
  CollapseSectionStyled,
  FormFooterStyled,
} from 'pages/property/common/Common.styles';
import { FormFooterStickyStyled } from 'pages/property/common/FormFooterSticky.styles';
import theme from 'styles/theme';

export const FormWithProviderStyled = styled(FormWithProvider)`
  border: 1px solid ${theme.colors.gray300};
  border-radius: 4px;

  ${CollapseSectionStyled} {
    margin: -1px -1px 0 -1px;
  }

  ${FormFooterStyled}, ${FormFooterStickyStyled} {
    padding: 20px;
  }
`;

export const TaxFieldRowStyled = styled(Row)`
  margin: 0;
`;
export const TaxFieldColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: -15px;

  .checkbox {
    margin-left: 15px;
  }

  .form-field-container label {
    padding-top: 0;
  }
`;
