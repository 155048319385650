import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { Description } from 'models/Descriptions';
import API from 'services/API';

type UpdateDescriptionMutationArgs = {
  propertyUid: string;
} & Partial<Description>;

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<unknown, unknown, UpdateDescriptionMutationArgs>,
  'mutationFn' | 'mutationKey'
>;

const updateDescription = async ({
  propertyUid,
  ...description
}: UpdateDescriptionMutationArgs) => {
  return API.put('/v3/descriptions', {
    propertyUid,
    ...description,
  });
};

const useUpdateDescriptionMutation = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: updateDescription,
    ...options,
  });
};

export default useUpdateDescriptionMutation;
