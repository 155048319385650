import styled from 'styled-components';

export const PageTitleDeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > div {
    display: flex;
    align-items: center;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
`;
