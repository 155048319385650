import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';
import { AppConfig } from '../../../models/Config';

interface ConfigState {
  appConfig: AppConfig;
  appConfigRefreshRequested?: boolean;
}

const initialState: ConfigState = {
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  appConfig: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setAppConfig: (
      state,
      { payload: { appConfig } }: PayloadAction<{ appConfig: AppConfig }>,
    ) => {
      state.appConfig = appConfig;
      delete state.appConfigRefreshRequested;
    },
    refreshAppConfig: (state) => {
      state.appConfigRefreshRequested = true;
    },
  },
});

export const { setAppConfig, refreshAppConfig } = configSlice.actions;

export default configSlice.reducer;
