import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { RentalConditions } from 'models/RentalCondition';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  DefinitionRadioOptions,
  EditRentalConditionsFormSchemaType,
  editSchema,
} from './EditRentalConditionsModal.schema';
import { EditModalContainer } from './EditRentalConditionsModal.styles';
import EditRentalConditionsModalFields from './EditRentalConditionsModalFields';

const EditRentalConditionsModal = ({
  rentalConditions,
  onSubmit,
  mutationKey,
  modalId,
}: {
  rentalConditions: RentalConditions;
  onSubmit: (rentalConditions: EditRentalConditionsFormSchemaType) => void;
  mutationKey: string[];
  modalId: string;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();

  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;

  const handleClose = () => {
    closeModal(modalId);
  };

  return (
    <EditModalContainer>
      <FormWithProvider
        horizontal={false}
        schema={editSchema()}
        onSubmit={onSubmit}
        defaultValues={{
          rentalConditions: rentalConditions?.inheritFromAgency
            ? ''
            : rentalConditions.rentalConditions,
          definition: rentalConditions?.inheritFromAgency
            ? DefinitionRadioOptions.AGENCY
            : DefinitionRadioOptions.PROPERTY,
        }}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(
              'pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.title',
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditRentalConditionsModalFields />
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="default" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button bsStyle="primary" type="submit" disabled={isLoading}>
            {t('common.save')}
          </Button>
        </Modal.Footer>
      </FormWithProvider>
    </EditModalContainer>
  );
};

export default EditRentalConditionsModal;
