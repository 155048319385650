import { useMemo, useState } from 'react';
import { Property } from 'models/Properties';
import { Lead } from 'models/Leads';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';

const AirbnbAlterationRequestModalContextProvider = ({
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  availableProperties: initialAvailableProperties,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  children,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  modalId,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  defaultLeadUid,
}) => {
  const currentLeadUid = defaultLeadUid;
  const [availableProperties, setAvailableProperties] = useState<Property[]>(
    initialAvailableProperties,
  );

  const [lead, setLead] = useState<Lead>();

  const contextValue = useMemo(
    () => ({
      modalId,
      currentLeadUid,
      availableProperties,
      setAvailableProperties,
      lead,
      setLead,
    }),
    [modalId, currentLeadUid, availableProperties, lead],
  );

  return (
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <AirbnbAlterationRequestModalContext.Provider value={contextValue}>
      {children}
    </AirbnbAlterationRequestModalContext.Provider>
  );
};

export default AirbnbAlterationRequestModalContextProvider;
