import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetServiceProvidersResponseTO } from 'models/ServiceProvider';
import API from 'services/API';

export const SERVICE_PROVIDERS_BASE_QUERY_KEY = 'service-providers';

type Props = Omit<
  UseQueryOptions<GetServiceProvidersResponseTO>,
  'queryKey' | 'queryFn'
> & {
  agencyUid?: string;
  propertyUid?: string;
};

const useFetchServiceProviders = ({
  agencyUid,
  propertyUid,
  ...options
}: Props = {}) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [SERVICE_PROVIDERS_BASE_QUERY_KEY, agencyUid, propertyUid],
    queryFn: async () => {
      const response = await API.get<GetServiceProvidersResponseTO>(
        '/api/internal/service-providers',
        {
          params: {
            agencyUid,
            objectUid: propertyUid,
          },
        },
      );
      return response.data;
    },
    staleTime: 30_000,
    cacheTime: 5 * 60 * 1000,
    enabled: !!agencyUid || !!propertyUid,
    ...(options ?? {}),
  });
};

export default useFetchServiceProviders;
