import { useMemo, useState } from 'react';
import { LeadModeType } from 'models/DashboardLeadModes';
import DashboardContext from './DashboardContext';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const DashboardContextProvider = ({ children }) => {
  const [leadModeType, setLeadModeType] = useState(LeadModeType.NEW);
  const isNewTab = leadModeType === LeadModeType.NEW;
  const isCheckinTab = leadModeType === LeadModeType.CHECKIN;
  const isCheckoutTab = leadModeType === LeadModeType.CHECKOUT;

  const contextValue = useMemo(
    () => ({
      isNewTab,
      isCheckinTab,
      isCheckoutTab,
      leadModeType,
      setLeadModeType,
    }),
    [leadModeType],
  );

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
