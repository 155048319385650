import styled from 'styled-components';

export const DetailedFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    margin-left: auto;
  }
`;

export const DetailedFeedbackGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
`;

export const RatingCategoryContainer = styled.div`
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
    span {
      font-weight: 600;
    }

    .category-rate {
      font-size: 16px;
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 8px 16px;
  }
`;

export const CategoryTags = styled.div`
  display: flex;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-top: 4px;
  }
`;
