import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  differenceInCalendarMonths,
  differenceInDays,
  differenceInHours,
  isSameMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import CalendarContext from '../CalendarContext';
import { StackedCalendarMode } from '../Calendar.types';
import useStackedCalendarStartDate from '../useStackedCalendarStartDate';
import {
  getFirstDateWithinViewport,
  getLastDateWithinViewport,
} from './StackedCalendarNavigation.utils';
import {
  NavigationMonthsContainer,
  NavigationMonthStyled,
} from './StackedCalendarNavigation.styles';

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function getNextMonthAfterFirst(firstVisibleDate, lastVisibleDate) {
  let nextMonth = lastVisibleDate;
  while (differenceInCalendarMonths(nextMonth, firstVisibleDate) > 1) {
    nextMonth = subMonths(nextMonth, 1);
  }

  return nextMonth;
}

const showFirstMonthHoursThreshold = {
  [StackedCalendarMode.BOOKING]: 96,
  [StackedCalendarMode.PRICING]: 48,
};

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const NavigationMonths = ({ scrollRef }) => {
  const { bodyWidth, mode } = useContext(CalendarContext);
  const { t } = useTranslation();
  const [currentMonth, setCurrentMonth] = useState<Date | null>();
  const currentMonthRef = useRef<Date>();
  const monthRef = useRef<HTMLSpanElement>();
  const startDate = useStackedCalendarStartDate();

  useLayoutEffect(() => {
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const scrollListener = (e) => {
      const scrollPosition = e.target.scrollLeft;

      const firstVisibleDate = getFirstDateWithinViewport({
        mode,
        scrollPosition,
        startDate,
      });

      if (
        !currentMonthRef.current ||
        !isSameMonth(firstVisibleDate, currentMonthRef.current)
      ) {
        currentMonthRef.current = firstVisibleDate;
        setCurrentMonth(firstVisibleDate);
      }

      if (!monthRef.current) {
        return;
      }

      const lastVisibleDate = getLastDateWithinViewport({
        bodyWidth,
        mode,
        scrollPosition,
        startDate,
      });

      const onlyOneMonthVisible = isSameMonth(
        firstVisibleDate,
        lastVisibleDate,
      );
      const hoursDifferenceFromMonthStart = differenceInHours(
        firstVisibleDate,
        startOfMonth(firstVisibleDate),
      );
      const hoursDifferenceTillNextMonth = onlyOneMonthVisible
        ? Number.MAX_VALUE
        : differenceInHours(
            startOfMonth(
              getNextMonthAfterFirst(firstVisibleDate, lastVisibleDate),
            ),
            firstVisibleDate,
          );
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const threshold = showFirstMonthHoursThreshold[mode];
      const hideFirstMonth =
        hoursDifferenceFromMonthStart <= threshold ||
        hoursDifferenceTillNextMonth <= threshold;

      monthRef.current.style.visibility = hideFirstMonth ? 'hidden' : 'visible';
    };

    if (scrollRef.current) {
      scrollRef.current.firstChild.addEventListener('scroll', scrollListener);
    }

    return () => {
      scrollRef.current?.firstChild?.removeEventListener(
        'scroll',
        scrollListener,
      );
    };
  }, [mode, scrollRef.current, startDate]);

  useLayoutEffect(() => {
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const handleFilterEvent = (event) => {
      const { date } = event.detail;
      if (!date) return;

      const monthStart = startOfMonth(date);

      const difference = differenceInDays(date, monthStart);

      if (difference > 4 && difference < 26) {
        setCurrentMonth(date);
      } else {
        setCurrentMonth(null);
      }
    };

    window.addEventListener('scrollToDateEvent', handleFilterEvent);

    return () => {
      window.removeEventListener('scrollToDateEvent', handleFilterEvent);
    };
  }, []);

  return (
    <NavigationMonthsContainer data-testid="stacked-calendar-months-container">
      {!!currentMonth && (
        // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        <NavigationMonthStyled ref={monthRef}>
          {t('common.monthFullYear', { date: currentMonth })}
        </NavigationMonthStyled>
      )}
    </NavigationMonthsContainer>
  );
};

export default NavigationMonths;
