import styled from 'styled-components';

export const HeaderContainer = styled.div`
  align-items: start;
  display: flex;
  gap: 1rem;

  & a,
  & button.btn-link {
    align-items: center;
    display: flex;
    gap: 8px;
    height: fit-content;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const PricingAlertContainer = styled.div`
  &.bd-callout {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
    font-weight: bold;
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  a {
    font-weight: bold;
  }
`;
