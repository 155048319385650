import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import styled from 'styled-components';

export const PropertyVideoTourSection = styled(CollapseSectionStyled)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .form-group {
    margin-bottom: 0px;
  }

  .collapsible-body {
    padding: 20px 0 0 0;
  }

  img {
    width: 34px;
    height: 34px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #ced4da;
  margin-top: 36px;
`;
