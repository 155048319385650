import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import { DailyRateUpdateType } from 'components/domain/propertyPricing/modal/PropertyPricingModal.types';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';
import TextField from '../textField/TextField';
import { DailyRateFieldProps } from './DailyRateField.types';
import { DailyRateFieldWrapper } from './DailyRateField.styles';

const DailyRateField: FC<DailyRateFieldProps> = ({
  id,
  name,
  placeholder,
  currency,
}) => {
  const { setValue, watch } = useFormContext();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const numberValueFieldName = `${name}.dailyRateValue`;
  const typeValueFieldName = `${name}.dailyRateUpdateType`;

  const currentTypeValue = watch(typeValueFieldName);

  const dailyRateTypeOptions = [
    {
      value: DailyRateUpdateType.FIXED_AMOUNT,
      label: t('componentProperty.modal.form.dailyRateType.fixed'),
    },
    {
      value: DailyRateUpdateType.PERCENTAGE_CHANGE,
      label: t('componentProperty.modal.form.dailyRateType.percentageChange'),
    },
  ];

  const currentTypeOption =
    dailyRateTypeOptions.find(({ value }) => {
      return value === currentTypeValue;
    }) || dailyRateTypeOptions[0];

  const onChangeType = (value: {
    value: DailyRateUpdateType;
    label: string;
  }) => {
    setValue(`${typeValueFieldName}`, value.value);
  };

  const isFixedAmount = currentTypeValue === DailyRateUpdateType.FIXED_AMOUNT;

  return (
    <DailyRateFieldWrapper>
      <TextField
        name={numberValueFieldName}
        placeholder={placeholder}
        label={t('componentProperty.modal.form.dailyRate')}
        type="number"
        min={isFixedAmount ? 0 : undefined} // TODO Cedric possibly $10 for USD?
        colSmInput={8}
        colSmLabel={4}
        leftAddon={
          <ReactSelect
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            id={id.toString()}
            isSearchable={false}
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            onChange={onChangeType}
            options={dailyRateTypeOptions}
            value={currentTypeOption}
            classNames={{
              indicatorsContainer: () => 'indicators-container',
              control: () => 'control-container',
              menu: () => 'menu-container',
              singleValue: () => 'single-value-container',
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        }
        rightAddons={[
          isFixedAmount ? formatCurrency({ currency, value: undefined }) : '%',
        ]}
      />
    </DailyRateFieldWrapper>
  );
};

export default DailyRateField;
