import { PropertyBusinessType } from 'models/Properties';
import { getFullAddress } from 'utils/property/propertyUtils';
import { PropertyForPropertyListItem } from '../Properties.types';
import usePropertyEditPath from '../../property/usePropertyEditPath';
import {
  PropertyAddressStyled,
  PropertyCharacteristicsStyled,
  PropertyInfoStyled,
  PropertyNameStyled,
  PropertyNameWrapperStyled,
} from './PropertyListItem.styles';
import PropertyGuestsBedroomsAndBathroomsCount from './PropertyGuestsBedroomsAndBathroomsCount';
import PropertyUnits from './PropertyUnits';
import PropertyLabel from './PropertyLabel';
import PropertyTags from './tags/PropertyTags';
import useUpdatePropertyFormState from './useUpdatePropertyFormState';

interface PropertyInfoProps {
  property: PropertyForPropertyListItem;
}

const PropertyInfo = ({ property }: PropertyInfoProps) => {
  const getPropertyEditPath = usePropertyEditPath();

  const isUnitType = property.businessType === PropertyBusinessType.UNIT_TYPE;
  const isHotel = property.businessType === PropertyBusinessType.HOTEL;

  useUpdatePropertyFormState(property);

  return (
    <PropertyInfoStyled>
      <PropertyNameWrapperStyled>
        {/*
         // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        <PropertyNameStyled href={getPropertyEditPath(property)}>
          {property.name}
        </PropertyNameStyled>
        <PropertyLabel property={property} />
      </PropertyNameWrapperStyled>
      {'address' in property && !isUnitType && (
        <PropertyAddressStyled>
          {getFullAddress(property)}
        </PropertyAddressStyled>
      )}
      <PropertyCharacteristicsStyled>
        {!isHotel && (
          <PropertyGuestsBedroomsAndBathroomsCount property={property} />
        )}
        {(isHotel || isUnitType) && <PropertyUnits property={property} />}
      </PropertyCharacteristicsStyled>
      <PropertyTags propertyUid={property.uid} propertyName={property.name} />
    </PropertyInfoStyled>
  );
};

export default PropertyInfo;
