import { Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import Tooltip from 'components/tooltip/Tooltip';
import { ModalDescription } from './ManageLanguagesModal.styles';

interface ManageLanguagesModalAutoTranslationProps {
  isDisabled: boolean;
}

const ManageLanguagesModalAutoTranslation = ({
  isDisabled,
}: ManageLanguagesModalAutoTranslationProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalDescription data-testid="auto-translation-description">
        {t('pageProperty.descriptions.manageLanguageModal.autoTranslation')}
        <Tooltip
          id="auto-translation-description-icon"
          text={t(
            'pageProperty.descriptions.manageLanguageModal.autoTranslationTooltip',
          )}
        >
          <Glyphicon
            data-testid="auto-translation-description-icon"
            glyph="info-sign"
          />
        </Tooltip>
      </ModalDescription>
      <CheckboxField name="autoTranslation" disabled={isDisabled}>
        {t(
          'pageProperty.descriptions.manageLanguageModal.autoTranslationLabel',
        )}
      </CheckboxField>
    </>
  );
};

export default ManageLanguagesModalAutoTranslation;
