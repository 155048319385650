import { useTranslation } from 'react-i18next';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';

const graphQlFilters = { topLevelOnly: false };

const PriceRulesAddEditModalAppliesToField = () => {
  const { t } = useTranslation();

  return (
    <PropertyMultiSelectFilterField
      label={t('pageProperty.common.cells.appliesTo')}
      name="appliesTo"
      graphQlFilters={graphQlFilters}
      enableHotels={false}
      enableUnitTypes
      colSmInput={8}
      colSmLabel={4}
      filtersColXs={12}
    />
  );
};

export default PriceRulesAddEditModalAppliesToField;
