import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';
import useAddManualTransactionModal from './useAddManualTransactionModal';

const AddManualTransactionButton = () => {
  const { openAddManualTransactionModal } = useAddManualTransactionModal();
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const onClick = () => {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    openAddManualTransactionModal({ lead });
  };

  return (
    <Button
      bsStyle="link"
      data-testid="add-manual-transaction-button"
      onClick={onClick}
    >
      {t('pageOrder.transactions.actions.addManualTransaction.buttonLabel')}
    </Button>
  );
};

export default AddManualTransactionButton;
