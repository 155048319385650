import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';
import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import { OwnerAdjustmentsPathParams } from 'pages/ownerAdjustments/OwnerAdjustments.types';
import { getAdjustmentType } from 'pages/ownerAdjustments/OwnerAdjustments.utils';
import useOwnerAdjustmentModal from 'pages/ownerAdjustments/modal/hooks/useOwnerAdjustmentModal';
import useDeleteOwnerAdjustmentMutation from 'pages/ownerAdjustments/modal/hooks/useDeleteOwnerAdjustmentMutation';

interface ActionsCellProps<T> {
  row: Row<T>;
}

const ActionsCell = <T extends OwnerAdjustment | OwnerAdjustmentSchedule>({
  row,
}: ActionsCellProps<T>) => {
  const { t } = useTranslation();
  const { propertyUid } = useParams<OwnerAdjustmentsPathParams>();
  const { openConfirmModal } = useAppModal();
  const { openOwnerAdjustmentModal } = useOwnerAdjustmentModal();
  const { mutate: deleteOwnerAdjustment, isLoading: isDeleting } =
    useDeleteOwnerAdjustmentMutation();
  const adjustmentType = getAdjustmentType(row.original);

  const handleEditClick = () => {
    openOwnerAdjustmentModal({
      defaultAdjustment: {
        adjustmentType,
        ...row.original,
      },
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUid,
    });
  };

  const handleDeleteClick = () => {
    openConfirmModal({
      onConfirm: () => deleteOwnerAdjustment(row.original),
      body: t('pageOwnerAdjustments.modal.deleteConfirmMessage'),
      title: t('pageOwnerAdjustments.modal.deleteTitle'),
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
    });
  };

  return (
    <>
      <Button
        bsStyle="link"
        data-testid="edit-button"
        disabled={isDeleting}
        onClick={handleEditClick}
      >
        {t('common.edit')}
      </Button>
      <Button
        bsStyle="link"
        data-testid="delete-button"
        disabled={isDeleting}
        onClick={handleDeleteClick}
      >
        {t('common.delete')}
      </Button>
    </>
  );
};

export default ActionsCell;
