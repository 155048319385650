import { useContext } from 'react';
import TableContext from '../TableContext';
import { TableEmptyStateStyled } from './TableEmptyState.styles';

interface TableEmptyStateProps {
  className?: string;
}

const TableEmptyState = ({ className }: TableEmptyStateProps) => {
  const { tableInstance, emptyState } = useContext(TableContext);
  const { length: colSpan } = tableInstance.getHeaderGroups()[0].headers;

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <tr>
      <td colSpan={colSpan}>
        <TableEmptyStateStyled
          className={className}
          data-testid="table-empty-state"
        >
          {emptyState}
        </TableEmptyStateStyled>
      </td>
    </tr>
  );
};

TableEmptyState.defaultProps = {
  className: '',
};

export default TableEmptyState;
