import BookIconSvg from 'assets/images/icons/book.svg?react';
import CalendarIconSvg from 'assets/images/icons/calendar.svg?react';
import StackIconSvg from 'assets/images/icons/stack.svg?react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/button/Button';
import { PropertyBusinessType } from 'models/Properties';
import usePropertyGuidebookLink from '../usePropertyGuidebookLink';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { HeaderContainer } from './PropertySettingsMainTabHeader.styles';

export const AddUnitTypeButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uid: propertyUid } = useParams();

  const goToAddUnitType = () => {
    navigate(`/property/${propertyUid}/add/unit-type`);
  };

  return (
    <Button
      $colorScheme="green"
      onClick={goToAddUnitType}
      data-testid="add-unit-type-button"
    >
      {t('common.pageTitles.property/:uid/add/unit-type', {
        nsSeparator: false,
      })}
    </Button>
  );
};

const PropertySettingsMainTabHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uid: propertyUid } = useParams();
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const guidebookLink = usePropertyGuidebookLink(propertyUid);
  const { property } = usePropertySettingsInitData();

  const isNotUnitType =
    property?.businessType !== PropertyBusinessType.UNIT_TYPE;

  const commonHeader = isNotUnitType && (
    <NavLink
      data-testid="custom-data-link"
      to={`/l/customDataField_edit.jsp?uid=${propertyUid}`}
    >
      <StackIconSvg />
      {t('pageProperty.mainSettings.header.customData')}
    </NavLink>
  );

  if (property?.businessType === PropertyBusinessType.HOTEL) {
    return (
      <HeaderContainer>
        {commonHeader}
        <AddUnitTypeButton />
      </HeaderContainer>
    );
  }

  const goToCalendar = () => {
    navigate(`/calendar?propertyUid=${propertyUid}`);
  };

  return (
    <HeaderContainer>
      {commonHeader}

      <Button bsStyle="link" onClick={goToCalendar}>
        <CalendarIconSvg />
        {t('pageProperty.mainSettings.header.calendar')}
      </Button>

      {!!guidebookLink && (
        <Button bsStyle="link" href={guidebookLink} target="_blank">
          <BookIconSvg />
          {t('pageProperty.mainSettings.header.guidebook')}
        </Button>
      )}
    </HeaderContainer>
  );
};

export default PropertySettingsMainTabHeader;
