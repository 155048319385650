// @ts-nocheck [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
// ignoring the entire file because the error gets deep and updating the styles syntax may cause UI issues
import styled from 'styled-components';
import { buttonAndAnchorBaseStyles } from './LeadListItemButton.styles';

/* @ts-ignore -- START: Ignoring styled-components type issues */
export const LeadViewButton = styled.button<{ $backgroundColor?: string }>`
  ${buttonAndAnchorBaseStyles};
  background-color: ${({ $backgroundColor }) => $backgroundColor || '#3b84d8'};
`;
/* @ts-ignore -- END */
