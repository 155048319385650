import * as Yup from 'yup';
import { DeepPartial, FieldNamesMarkedBoolean } from 'react-hook-form';
import { CURRENCIES_WITHOUT_NONE, Currency } from 'models/Currency';
import { PropertyPriceSettingsData } from './sections/priceSettings/PriceSettings.types';

export const propertySettingsPricingTabSchema = () => {
  return Yup.object({
    priceSettings: Yup.object({
      baseDailyRate: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .positive()
        .when('currency', { is: 'USD', then: Yup.number().min(10) })
        .required(),
      cleaningFee: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      cleaningFeeTax: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
      currency: Yup.mixed<Currency>().oneOf(CURRENCIES_WITHOUT_NONE).required(),
      isTaxLongTermStayExemption: Yup.boolean().optional(),
      securityDepositAmount: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      taxLongTermStayExemptionDays: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      taxRate: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
      useMinimumPriceRule: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .optional()
        .nullable(),
      weekEndRatePercentAdjustment: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
    }),
  });
};

export type PropertySettingsPricingTabFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsPricingTabSchema>>
>;

export type PropertySettingsPricingTabFormDirtyFields = Partial<
  Readonly<FieldNamesMarkedBoolean<PropertySettingsPricingTabFormValues>>
>;

export function getFormDefaultValues({
  priceSettingsData,
}: {
  priceSettingsData: PropertyPriceSettingsData | null;
}): PropertySettingsPricingTabFormValues {
  return {
    priceSettings: {
      baseDailyRate: priceSettingsData?.baseDailyRate ?? 0,
      cleaningFee: priceSettingsData?.cleaningFee ?? 0,
      cleaningFeeTax: priceSettingsData?.cleaningFeeTax ?? 0,
      currency: priceSettingsData?.currency ?? 'USD',
      isTaxLongTermStayExemption:
        priceSettingsData?.isTaxLongTermStayExemption ?? false,
      securityDepositAmount: priceSettingsData?.securityDepositAmount ?? 0,
      taxLongTermStayExemptionDays:
        priceSettingsData?.taxLongTermStayExemptionDays ?? 0,
      taxRate: priceSettingsData?.taxRate ?? 0,
      useMinimumPriceRule: `${priceSettingsData?.useMinimumPriceRule ?? false}`,
      weekEndRatePercentAdjustment:
        (priceSettingsData?.weekEndRatePercentAdjustment ?? 0) * 100,
    },
  };
}
