import { Container as SwitchContainer } from 'components/switch/Switch.styles';
import styled from 'styled-components';

export const PriceRulesOverrideAirbnbWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;

  ${SwitchContainer} {
    --size: 32px;
    height: var(--size);
    width: var(--size);

    .toggle-switch:checked {
      background-color: #3a84d8;
    }
  }

  label {
    font-weight: normal;
    margin: 0;
  }

  svg {
    --size: 24px;
    height: var(--size);
    width: var(--size);
  }
`;
