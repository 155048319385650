import { Namespace, TFunction, useTranslation } from 'react-i18next';
import { isDate } from 'date-fns';
import useAvailableCountries from 'components/domain/country/useAvailableCountries';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { ALL_WEEK_DAYS, DayOfWeek } from 'models/DayOfWeek';
import { getListingHostName } from 'utils/airbnb/listingHostUtils';
import { usStateOptions } from 'utils/constants/states';
import { normalizeKey } from 'utils/localeUtils';
import { ALL_CATEGORIZED_AMENITIES_ITEMS } from '../amenities/sections/categorizedAmenities/CategorizedAmenities.constants';
import { CheckInMethodFormValues } from '../common/Common.schema';
import { PropertySettingsTab } from '../PropertySettingsTabs.constants';
import { CHECK_IN_TIME_END_FLEXIBLE } from '../mainSettings/sections/airbnbSettings/AirbnbSettings.constants';
import {
  PropertySettingsBulkSavableFormValues,
  PropertySettingsBulkSaveModalParams,
} from './PropertySettingsBulkSaveModal.types';

const tabNameToLocaleGroup = {
  [PropertySettingsTab.mainSettings]: 'mainSettings',
  [PropertySettingsTab.pricing]: 'pricing',
};

const checkInMethodFormatter = (
  checkInMethod: 'alternativeCheckInMethod' | 'primaryCheckInMethod',
  fieldValue: CheckInMethodFormValues,
  t: TFunction<Namespace<'en'>>,
) => {
  const commonKey = 'pageProperty.mainSettings.bookingDotComSettings';
  const getLabel = (key: keyof CheckInMethodFormValues) =>
    t(`${commonKey}.${checkInMethod}.${key}`);

  return (
    [
      'type' in fieldValue
        ? [t(normalizeKey(`${commonKey}.checkInMethodType.${fieldValue.type}`))]
        : null,
      'how' in fieldValue
        ? [
            getLabel('how'),
            fieldValue.how
              ? t(
                  normalizeKey(
                    `${commonKey}.checkInMethodHow.${fieldValue.how}`,
                  ),
                )
              : t('common.unset'),
          ]
        : null,
      'when' in fieldValue
        ? [
            getLabel('when'),
            fieldValue.when
              ? t(
                  normalizeKey(
                    `${commonKey}.checkInMethodWhen.${fieldValue.when}`,
                  ),
                )
              : t('common.unset'),
          ]
        : null,
      'text' in fieldValue
        ? [getLabel('text'), fieldValue.text ?? t('common.unset')]
        : null,
      'brandName' in fieldValue
        ? [getLabel('brandName'), fieldValue.brandName ?? t('common.unset')]
        : null,
      'identifier' in fieldValue
        ? [getLabel('identifier'), fieldValue.identifier ?? t('common.unset')]
        : null,
    ]
      .filter(Boolean)
      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      .map((keyAndValue) => keyAndValue.join(': '))
      .join(', ')
  );
};

const fieldLabelFactories = {
  [PropertySettingsTab.amenities]: {
    amenities: ALL_CATEGORIZED_AMENITIES_ITEMS.reduce(
      (acc, amenity) => ({
        ...acc,
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        [amenity]: ({ fieldName, t }) => t(`amenities.types.${fieldName}`),
      }),
      {},
    ),
  },
  [PropertySettingsTab.mainSettings]: {
    airbnbSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      allowBookingRequestAboveMaximumStay: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.allowBookingRequest'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      allowBookingRequestWithinBookingLeadTime: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.allowBookingRequest'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ t }) =>
        t('pageProperty.bulkSave.airbnbSettings.bookingType'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cancellationPolicy: ({ t }) =>
        t('pageProperty.bulkSave.airbnbSettings.cancellationPolicy'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkInTimeStartFlexible: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.checkInTimeStart'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      nonRefundableRateEnabled: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.nonRefundableRate'),
    },
    bookingDotComSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      alternativeCheckInMethod: ({ t }) =>
        t(
          'pageProperty.mainSettings.bookingDotComSettings.alternativeCheckInMethod.type',
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ t }) =>
        t('pageProperty.bulkSave.bookingDotComSettings.bookingType'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cancellationPolicyCode: ({ t }) =>
        t('pageProperty.bulkSave.bookingDotComSettings.cancellationPolicyCode'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      primaryCheckInMethod: ({ t }) =>
        t(
          'pageProperty.mainSettings.bookingDotComSettings.primaryCheckInMethod.type',
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      primaryContactUid: ({ t }) =>
        t('pageProperty.mainSettings.bookingDotComSettings.primaryContact'),
    },
    capacityDetails: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rooms: ({ t }) =>
        t(
          'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.roomsAndBedTypes',
        ),
    },
    propertyDetails: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      internalThumbnailUrl: ({ t }) =>
        t('pageProperty.mainSettings.propertyDetails.internalThumbnail'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertySizeUnit: ({ t }) =>
        t('pageProperty.mainSettings.propertyDetails.propertySize'),
    },
    vrboSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ t }) =>
        t('pageProperty.bulkSave.vrboSettings.bookingType'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cancellationPolicy: ({ t }) =>
        t('pageProperty.bulkSave.vrboSettings.cancellationPolicy'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      showPropertyExactLocation: ({ t }) =>
        t('pageProperty.mainSettings.vrboSettings.showPropertyLocation.label'),
    },
  },
  [PropertySettingsTab.pricing]: {
    priceSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      baseDailyRate: ({ t }) =>
        t('pageProperty.pricing.priceSettings.nightlyBasePrice'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      securityDepositAmount: ({ t }) =>
        t('pageProperty.pricing.priceSettings.securityDeposit'),
    },
  },
};

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const airbnbAllowBookingRequestValueFactory = ({ allFieldValues, t }) =>
  [
    allFieldValues.airbnbSettings.allowBookingRequestAboveMaximumStay &&
      t('pageProperty.mainSettings.airbnbSettings.aboveMaximumStay'),
    allFieldValues.airbnbSettings.allowBookingRequestWithinBookingLeadTime &&
      t('pageProperty.mainSettings.airbnbSettings.withinBookingLeadTime'),
  ]
    .filter(Boolean)
    .join(', ');

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const propertySizeUnitValueFactory = ({ fieldValue, t }) =>
  t(`pageProperty.mainSettings.areaUnitType.${fieldValue}`);

const fieldValueFactories = {
  [PropertySettingsTab.amenities]: {
    amenities: ALL_CATEGORIZED_AMENITIES_ITEMS.reduce(
      (acc, amenity) => ({
        ...acc,
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        [amenity]: ({ fieldValue: { description, value }, t }) =>
          `${t(`common.${value ? 'on' : 'off'}`)}${
            description ? ` (${description})` : ''
          }`,
      }),
      {},
    ),
  },
  [PropertySettingsTab.mainSettings]: {
    airbnbSettings: {
      allowBookingRequestAboveMaximumStay:
        airbnbAllowBookingRequestValueFactory,
      allowBookingRequestWithinBookingLeadTime:
        airbnbAllowBookingRequestValueFactory,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ fieldValue, t }) => t(`common.bookingType.${fieldValue}`),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cancellationPolicy: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.cancellationPolicyOptions.${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkInOption: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.checkInOptionOptions.${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkInTimeEnd: ({ fieldValue, t }) =>
        fieldValue === CHECK_IN_TIME_END_FLEXIBLE
          ? t('pageProperty.mainSettings.airbnbSettings.checkInTimeEndFlexible')
          : fieldValue,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkInTimeStartFlexible: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.checkInTimeStartOptions.${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      guestRequirements: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.guestRequirementsOptions.${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      listingType: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.listingTypeOptions.${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      nonRefundableRateDiscount: ({ fieldValue }) => `${Number(fieldValue)}%`,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      nonRefundableRateEnabled: ({ fieldValue, t }) =>
        fieldValue ? t('common.enabled') : t('common.disabled'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      sendMessageAsUserId: ({ fieldValue, mainSettings, t }) => {
        const listingHost =
          mainSettings?.airbnbSettings?.airbnbListingHosts?.find(
            // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            ({ airbnbUserId }) => fieldValue === `${airbnbUserId}`,
          );

        return listingHost ? getListingHostName(t, listingHost) : '';
      },
    },
    bookingDotComSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      alternativeCheckInMethod: ({ fieldValue, t }) => {
        return checkInMethodFormatter(
          'alternativeCheckInMethod',
          fieldValue,
          t,
        );
      },
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ fieldValue, t }) => t(`common.bookingType.${fieldValue}`),
      cancellationPolicyCode: ({
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        bookingDotComCancellationPolicies,
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        fieldValue,
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        t,
      }) =>
        fieldValue
          ? bookingDotComCancellationPolicies.find(
              // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              ({ code }) => code === Number(fieldValue),
            )?.name || ''
          : t('common.unset'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      primaryCheckInMethod: ({ fieldValue, t }) => {
        return checkInMethodFormatter('primaryCheckInMethod', fieldValue, t);
      },
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      primaryContactUid: ({ employees, fieldValue, t }) =>
        fieldValue
          ? // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            employees?.find(({ uid }) => uid === fieldValue)?.bestProfile
          : t('common.unset'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      roomType: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.bookingDotComSettings.roomTypeOptions.${fieldValue}`,
        ),
    },
    bookingSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingLeadTime: ({ fieldValue, t }) =>
        t('pageProperty.mainSettings.bookingSettings.hourOption', {
          count: fieldValue,
        }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ fieldValue, t }) =>
        t(`pageProperty.mainSettings.bookingTypeOptions.${fieldValue}`),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingWindow: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.bookingSettings.bookingWindowOptions.${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkInTime: ({ fieldValue, t }) =>
        t('pageProperty.mainSettings.bookingSettings.hourOption', {
          count: fieldValue,
        }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      checkoutTime: ({ fieldValue, t }) =>
        t('pageProperty.mainSettings.bookingSettings.hourOption', {
          count: fieldValue,
        }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      dayOfWeekMinimumStay: ({ fieldValue, t }) =>
        Object.entries(fieldValue)
          .toSorted(
            ([day1], [day2]) =>
              ALL_WEEK_DAYS.indexOf(day1 as DayOfWeek) -
              ALL_WEEK_DAYS.indexOf(day2 as DayOfWeek),
          )
          .map(([day, value]) => `${t(`common.dayOfWeek.${day}`)}: ${value}`)
          .join(', '),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      daysOfTheWeekToCheckInOn: ({ fieldValue, t }) => {
        const checkedDays = Object.entries(fieldValue)
          .filter(([_, isChecked]) => isChecked)
          .map(([day]) => t(`common.dayOfWeek.${day}`));
        const checkedCount = checkedDays.length;

        if (checkedCount === ALL_WEEK_DAYS.length) {
          return t(
            'pageProperty.mainSettings.bookingSettings.onlyCheckInOnNoRestrictionCount',
            { count: checkedCount },
          );
        }

        if (checkedCount) {
          return checkedDays.join(', ');
        }

        return t(
          'pageProperty.mainSettings.bookingSettings.onlyCheckInOnNoRestriction',
        );
      },
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      fullPaymentTiming: ({ fieldValue, t }) =>
        t('common.daysOptions.day', {
          count: Number(fieldValue),
        }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      maximumStay: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      minimumStay: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      minimumWeekendStay: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      turnoverDays: ({ fieldValue, t }) =>
        t('common.daysOptions.day', {
          count: Number(fieldValue),
        }),
    },
    capacityDetails: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      extraGuestFee: ({ fieldValue, formatCurrency }) =>
        formatCurrency(fieldValue),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rooms: ({ fieldValue, t }) => {
        const roomsCount = fieldValue.length;
        const bedsCount = fieldValue.reduce(
          // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          (acc: number, { beds }) =>
            acc +
            // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            beds.reduce((roomBeds: number, { count }) => roomBeds + count, 0),
          0,
        );

        return `${t(
          'pageProperty.bulkSave.capacityDetails.roomsAndBedTypes.room',
          { count: roomsCount },
        )}, ${t(
          t('pageProperty.bulkSave.capacityDetails.roomsAndBedTypes.bed', {
            count: bedsCount,
          }),
        )}`;
      },
    },
    miscInfo: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rentalLicenseExpirationDate: ({ fieldValue, t }) =>
        isDate(fieldValue) ? t('common.dateMedium', { date: fieldValue }) : '',
    },
    propertyAddress: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      countryCode: ({ availableCountries, fieldValue }) =>
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        availableCountries.find(({ code }) => code === fieldValue)
          ?.localizedName,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      state: ({ fieldValue }) =>
        usStateOptions.find(({ value }) => value === fieldValue)?.label ??
        fieldValue,
    },
    propertyDetails: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      internalThumbnailUrl: ({ allFieldValues, t }) =>
        allFieldValues.propertyDetails.internalThumbnail?.[0]
          ? allFieldValues.propertyDetails.internalThumbnail?.[0]?.name ??
            `[${t('common.file').toLowerCase()}]`
          : t('common.unset'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertySize: ({ allFieldValues, fieldValue, t }) =>
        `${fieldValue}${propertySizeUnitValueFactory({
          fieldValue: allFieldValues.propertyDetails.propertySizeUnit,
          t,
        })}`,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertySizeUnit: ({ allFieldValues, fieldValue, t }) =>
        `${
          allFieldValues.propertyDetails.propertySize
        }${propertySizeUnitValueFactory({ fieldValue, t })}`,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyType: ({ fieldValue, propertyTypes }) =>
        // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyTypes?.find(({ type }) => type === fieldValue)?.label ||
        fieldValue,
    },
    vrboSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingType: ({ fieldValue, t }) => t(`common.bookingType.${fieldValue}`),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cancellationPolicy: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.vrboSettings.cancellationPolicy.option_${fieldValue}`,
        ),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      showPropertyExactLocation: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.vrboSettings.showPropertyLocation.option_${fieldValue}`,
        ),
    },
  },
  [PropertySettingsTab.pricing]: {
    priceSettings: {
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      baseDailyRate: ({ fieldValue, formatCurrency }) =>
        formatCurrency(fieldValue),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cleaningFee: ({ fieldValue, formatCurrency }) =>
        formatCurrency(fieldValue),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cleaningFeeTax: ({ fieldValue }) => `${Number(fieldValue)}%`,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      isTaxLongTermStayExemption: ({ fieldValue, t }) =>
        fieldValue ? t('common.enabled') : t('common.disabled'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      securityDepositAmount: ({ fieldValue, formatCurrency }) =>
        formatCurrency(fieldValue),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      useMinimumPriceRule: ({ fieldValue, t }) =>
        fieldValue ? t('common.enabled') : t('common.disabled'),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      taxLongTermStayExemptionDays: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      taxRate: ({ fieldValue }) => `${Number(fieldValue)}%`,
      // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      weekEndRatePercentAdjustment: ({ fieldValue }) =>
        `${Number(fieldValue)}%`,
    },
  },
};

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function getFieldLabel({ fieldName, sectionName, tabName, t }) {
  const fieldLabelFactory =
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    fieldLabelFactories[tabName]?.[sectionName]?.[fieldName];

  return (
    fieldLabelFactory?.({ fieldName, sectionName, t }) ??
    t(
      normalizeKey(
        `pageProperty.${tabNameToLocaleGroup[tabName]}.${sectionName}.${fieldName}`,
      ),
    )
  );
}

function getFieldValue({
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  allFieldValues,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  availableCountries,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  bookingDotComCancellationPolicies,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  employees,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  fieldName,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  fieldValue,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  formatCurrency,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  mainSettings,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyTypes,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  sectionName,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  tabName,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  t,
}) {
  const fieldValueFactory =
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    fieldValueFactories[tabName]?.[sectionName]?.[fieldName];

  return (
    fieldValueFactory?.({
      allFieldValues,
      availableCountries,
      bookingDotComCancellationPolicies,
      employees,
      fieldValue,
      formatCurrency,
      mainSettings,
      propertyTypes,
      t,
    }) ??
    (fieldValue || t('common.unset'))
  );
}

const usePropertySettingsBulkSaveModalFieldDescriptions = () => {
  const { countries: availableCountries } = useAvailableCountries();
  const formatCurrencyFn = useFormatCurrency();
  const { t } = useTranslation();

  return ({
    allFieldValues,
    bookingDotComCancellationPolicies,
    employees,
    fields,
    mainSettings,
    property,
    propertyTypes,
    tabName,
  }: {
    allFieldValues: PropertySettingsBulkSavableFormValues;
    bookingDotComCancellationPolicies: PropertySettingsBulkSaveModalParams['bookingDotComCancellationPolicies'];
    employees: PropertySettingsBulkSaveModalParams['employees'];
    fields: PropertySettingsBulkSavableFormValues;
    mainSettings: PropertySettingsBulkSaveModalParams['mainSettings'];
    property: PropertySettingsBulkSaveModalParams['property'];
    propertyTypes: PropertySettingsBulkSaveModalParams['propertyTypes'];
    tabName: PropertySettingsBulkSaveModalParams['tabName'];
  }) => {
    const {
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      pricing: { currency },
    } = property;
    const selectedCurrencyFromPricingTab =
      'priceSettings' in allFieldValues
        ? allFieldValues?.priceSettings?.currency
        : undefined;
    const formatCurrency = (value: number) =>
      formatCurrencyFn({
        currency: selectedCurrencyFromPricingTab ?? currency,
        value,
      });

    return Object.entries(fields).reduce(
      (acc, [sectionName, sectionFields]) => ({
        ...acc,
        ...Object.entries(sectionFields).reduce(
          (sectionAcc, [fieldName, fieldValue]) => ({
            ...sectionAcc,
            [getFieldLabel({ fieldName, sectionName, tabName, t })]:
              getFieldValue({
                allFieldValues,
                availableCountries,
                bookingDotComCancellationPolicies,
                employees,
                fieldName,
                fieldValue,
                formatCurrency,
                mainSettings,
                propertyTypes,
                sectionName,
                tabName,
                t,
              }),
          }),
          {},
        ),
      }),
      {},
    );
  };
};

export default usePropertySettingsBulkSaveModalFieldDescriptions;
