import useAppInit from 'hooks/useAppInit';
import useAppSyncCheck from 'hooks/useAppSyncCheck';
import useIsMobileScreen from 'hooks/useIsMobileScreen';
import Loader from 'components/loader/Loader';
import useAppFavicon from 'hooks/useAppFavicon';
import useAppGlobalEventHandlers from 'hooks/useAppGlobalEventHandlers';
import useUserLanguage from 'i18n/useUserLanguage';
import Footer from './footer/Footer';
import Header from './header/Header';
import { ChildContent, Container, Content } from './PrivateLayout.styles';

interface Props {
  children: React.ReactNode;
  showOnlyContent?: boolean;
}

const PrivateLayout = ({ children, showOnlyContent }: Props) => {
  const { isInitInProgress } = useAppInit();
  const isMobile = useIsMobileScreen();

  useAppSyncCheck({ isInitInProgress });
  useAppFavicon();
  useAppGlobalEventHandlers();
  useUserLanguage();

  if (isInitInProgress) {
    return <Loader fullPage />;
  }

  return (
    <Container>
      {!showOnlyContent && <Header />}
      {/*
       // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <Content $showOnlyContent={showOnlyContent}>
        {/*
         // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        <ChildContent $showOnlyContent={showOnlyContent}>
          {children}
        </ChildContent>
        {!showOnlyContent && !isMobile && <Footer />}
      </Content>
    </Container>
  );
};

PrivateLayout.defaultProps = {
  showOnlyContent: false,
};

export default PrivateLayout;
