import useAppModal from 'hooks/useAppModal';
import {
  OwnerAdjustmentFormData,
  OwnerAdjustmentModalProps,
} from '../OwnerAdjustmentModal.types';
import { ownerAdjustmentsModalId } from '../OwnerAdjustmentModal.constants';
import useSaveOwnerAdjustmentMutation from './useSaveOwnerAdjustmentMutation';
import useDeleteFileMutation from './useDeleteFileMutation';

const useOwnerAdjustmentModalForm = (
  defaultAdjustment?: OwnerAdjustmentModalProps['defaultAdjustment'],
) => {
  const { closeModal } = useAppModal();
  const { mutate: deleteFile } = useDeleteFileMutation();
  const {
    mutate: saveOwnerAdjustment,
    isLoading: isCreatingOneTimeAdjustment,
  } = useSaveOwnerAdjustmentMutation({
    onSuccess: (data, ownerAdjustmentFormData) => {
      // we should delete the initial attachment if the user saved the form with a new attachment
      if (
        defaultAdjustment?.attachedImageURL &&
        defaultAdjustment.attachedImageURL !==
          ownerAdjustmentFormData.attachedImageURL
      ) {
        deleteFile({
          url: defaultAdjustment.attachedImageURL,
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          fileName: defaultAdjustment.attachedImageName,
        });
      }
      closeModal(ownerAdjustmentsModalId);
    },
  });
  const isUpdating = isCreatingOneTimeAdjustment;

  const handleSubmit = (formValues: OwnerAdjustmentFormData) => {
    saveOwnerAdjustment(formValues);
  };

  return {
    handleSubmit,
    isUpdating,
  };
};

export default useOwnerAdjustmentModalForm;
