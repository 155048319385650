import { PropertiesPricingData } from 'components/domain/propertyPricing/modal/PropertyPricingModal.types';
import { isAfter, addDays, format } from 'date-fns';
import { PropertiesDayCellDataMap } from 'pages/stackedCalendar/Calendar.types';

export function preparePropertiesPricingDataForPropertyPricingModal(
  propertyUids: string[],
  propertiesEntries: PropertiesDayCellDataMap,
  minDate: Date,
  maxDate: Date,
): PropertiesPricingData {
  // @ts-expect-error TS7034 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  let minMinimumStay;
  // @ts-expect-error TS7034 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  let maxMinimumStay;

  const propertiesPricingData: PropertiesPricingData = {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    minMinimumStay: null,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    maxMinimumStay: null,
    pricing: {},
  };

  propertyUids.forEach((propertyUid) => {
    const daysMap = propertiesEntries[propertyUid];

    for (let date = minDate; !isAfter(date, maxDate); date = addDays(date, 1)) {
      const daysMapKey = format(date, 'yyyy-MM-dd');
      const dayData = daysMap && daysMap[daysMapKey];
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const dayPrice = parseFloat(dayData.entry.pricing.value);

      const {
        minimumStayLength: dayMinimumStay,
        maximumStayLength: dayMaximumStay,
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      } = dayData.entry.availability;

      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const propertyCurrency = dayData.entry.pricing.currency;

      if (!propertiesPricingData.pricing[propertyCurrency]) {
        propertiesPricingData.pricing[propertyCurrency] = {
          propertyUids: new Set<string>(),
        };
      }

      propertiesPricingData.pricing[propertyCurrency].propertyUids.add(
        propertyUid,
      );

      // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      if (minMinimumStay == null || dayMinimumStay < minMinimumStay) {
        minMinimumStay = dayMinimumStay;
      }

      // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      if (maxMinimumStay == null || dayMaximumStay > maxMinimumStay) {
        maxMinimumStay = dayMaximumStay;
      }

      if (
        !propertiesPricingData.pricing[propertyCurrency].minDailyRate ||
        // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        dayPrice < propertiesPricingData.pricing[propertyCurrency].minDailyRate
      ) {
        propertiesPricingData.pricing[propertyCurrency].minDailyRate =
          Math.round(dayPrice);
      }

      if (
        !propertiesPricingData.pricing[propertyCurrency].maxDailyRate ||
        // @ts-expect-error TS7005 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        dayPrice > propertiesPricingData.pricing[propertyCurrency].maxDailyRate
      ) {
        propertiesPricingData.pricing[propertyCurrency].maxDailyRate =
          Math.round(dayPrice);
      }
    }
  });

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertiesPricingData.minMinimumStay = minMinimumStay;
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertiesPricingData.maxMinimumStay = maxMinimumStay;

  return propertiesPricingData;
}
