import { ProgressBarProps as BSProgressBarProps } from 'react-bootstrap';
import { ProgressBarStyled } from './ProgressBar.styles';

export interface ProgressBarProps extends BSProgressBarProps {
  color?: string;
  height?: number;
}

const ProgressBar = ({ color, height, ...rest }: ProgressBarProps) => {
  // @ts-ignore
  return <ProgressBarStyled $color={color} $height={height} {...rest} />;
};

ProgressBar.defaultProps = {
  color: '',
  height: 10,
};

export default ProgressBar;
