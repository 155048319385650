import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import { AmenityCategory } from 'models/Amenity';
import { Channel } from 'models/Channels';
import ChannelFilter from 'pages/property/common/channelFilter/ChannelFilter';
import useEnabledChannels from 'pages/property/common/channelFilter/useEnabledChannels';
import useAmenitiesDataMap from '../../useAmenitiesDataMap';
import useFetchAvailableAmenities from '../../useFetchAvailableAmenities';
import usePropertyAmenities from '../../usePropertyAmenities';
import CategoryCollapseSection from './CategoryCollapseSection';
import FormFooterActions from './FormFooterActions';
import useCategorizedAmenitiesFormSubmit from './useCategorizedAmenitiesFormSubmit';
import CategorizedAmenitiesLoadingPlaceholder from './CategorizedAmenitiesLoadingPlaceholder';
import PopularAmenitiesSection from './popularAmenitiesSection/PopularAmenitiesSection';
import {
  categorizedAmenitiesSchema,
  getFormDefaultValues,
} from './CategorizedAmenities.schema';
import CategorizedAmenitiesEventListeners from './CategorizedAmenitiesEventListeners';
import RulesSection from './rulesSection/RulesSection';
import useRules from './rulesSection/useRules';

const supportedChannels = [
  Channel.orbirental,
  Channel.airbnb,
  Channel.homeaway,
  Channel.booking_com,
  Channel.google,
  Channel.hvmi,
];

const CategorizedAmenities = () => {
  const { t } = useTranslation();
  const {
    channels,
    activeChannels,
    toggleChannel,
    isFetching: isFetchingEnabledChannels,
  } = useEnabledChannels({
    defaultEnabledChannels: [Channel.orbirental],
    supportedChannels,
  });
  const { availableAmenities, isLoading: isLoadingAvailableAmenities } =
    useFetchAvailableAmenities({
      activeChannels,
    });
  const { propertyAmenities, isLoading: isLoadingPropertyAmenities } =
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    usePropertyAmenities(availableAmenities?.length > 0);
  const { availableRules, propertyRules, isLoadingRules } = useRules({
    activeChannels,
  });
  const { categorizedAmenities, popularAmenities } = useAmenitiesDataMap(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    availableAmenities,
    availableRules,
  );
  const { isUpdating, onSubmit } = useCategorizedAmenitiesFormSubmit();
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  const { INDOOR, OUTDOOR, FAMILY, EXTRAS } = categorizedAmenities || {};

  const isLoading =
    isFetchingEnabledChannels ||
    isLoadingAvailableAmenities ||
    isLoadingPropertyAmenities ||
    isLoadingRules;

  if (isLoading) {
    return <CategorizedAmenitiesLoadingPlaceholder />;
  }

  const defaultValues = getFormDefaultValues(
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    Object.values(propertyAmenities),
    propertyRules,
  );

  return (
    <FormWithProvider
      data-testid="categorized-amenities-form"
      defaultValues={defaultValues}
      disabled={!propertiesUpdatingAllowed}
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      onSubmit={onSubmit}
      resolver={categorizedAmenitiesSchema()}
      dirtyChecker
      noValidate
    >
      <fieldset disabled={isUpdating || !propertiesUpdatingAllowed}>
        <CategorizedAmenitiesEventListeners />

        <ChannelFilter
          channels={channels}
          label={t('pageProperty.amenities.showSupportedAmenitiesChannel')}
          toggleChannel={toggleChannel}
        />

        {/*
         // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
        <PopularAmenitiesSection popularAmenities={popularAmenities} />

        <CategoryCollapseSection
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          category={INDOOR}
          type={AmenityCategory.INDOOR}
        />

        <CategoryCollapseSection
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          category={OUTDOOR}
          type={AmenityCategory.OUTDOOR}
        />

        <CategoryCollapseSection
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          category={FAMILY}
          type={AmenityCategory.FAMILY}
        />

        <CategoryCollapseSection
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          category={EXTRAS}
          type={AmenityCategory.EXTRAS}
        />

        <RulesSection availableRules={availableRules} />

        <FormFooterActions />
      </fieldset>
    </FormWithProvider>
  );
};
export default CategorizedAmenities;
