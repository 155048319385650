import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';
import { TransactionStatus, TransactionType } from 'models/Transactions';

const typeToBgColor = {
  [TransactionType.REFUND]: theme.colors.hostfullyPurple,
  [TransactionType.SALE]: theme.colors.primary,
  [TransactionType.SECURITY_DEPOSIT_CHARGE]: theme.colors.primary,
};

const statusToBgColor = {
  [TransactionStatus.UNCAPTURED]: theme.colors.hostfullyOrange,
};

export const TransactionTypeBadge = styled(Badge)<{
  $type: TransactionType;
  $status: TransactionStatus;
}>`
  background-color: ${({ $type, $status }) =>
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    statusToBgColor[$status] || typeToBgColor[$type]};
  border-radius: 5px;
`;

export const TransactionManualBadge = styled(Badge)`
  background-color: ${theme.colors.hostfullyYellow};
  border-radius: 5px;
  margin-left: 0.5rem;
`;
