import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { isDeepEqual } from 'utils/objectUtils';
import { differenceWith } from 'utils/arrayUtils';
import { BaseOption, ReactMultiSelectProps } from './MultiSelectField.types';
import {
  SELECT_ALL_OPTION_VALUE,
  isNotSelectAll,
  isSelectAll,
} from './MultiSelectField.constants';

interface UseHandleSelectAllOptionArgs {
  name: string;
  options: BaseOption[];
  fixedOptions?: BaseOption[];
}

function useHandleSelectAllOption({
  name,
  options,
  fixedOptions,
}: UseHandleSelectAllOptionArgs) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const currentSelected: BaseOption[] | undefined = useWatch({ name }) ?? [];

  const selectAllOption = {
    label: t('form.multiSelectField.selectAll'),
    value: SELECT_ALL_OPTION_VALUE,
  };

  const activeOptions = options?.filter((opt) => !opt?.disabled);

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleOnChangeWithSelectAll: ReactMultiSelectProps['onChange'] = (
    newValue: BaseOption[],
  ) => {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const addedPills = differenceWith(newValue, currentSelected, isDeepEqual);
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const removedPills = differenceWith(currentSelected, newValue, isDeepEqual);
    const isAddingSelectAll = !!addedPills.find(isSelectAll);
    const isRemovingSelectAll = !!removedPills.find(isSelectAll);
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const currentlyHasSelectAll = !!currentSelected.find(isSelectAll);
    const isIndividuallySelectingEachOption =
      activeOptions.length && isDeepEqual(activeOptions, newValue);

    if (isRemovingSelectAll) {
      if (fixedOptions) {
        setValue(name, fixedOptions, { shouldDirty: true });
        return;
      }
      setValue(name, [], { shouldDirty: true });
    } else if (isAddingSelectAll || isIndividuallySelectingEachOption) {
      setValue(name, [selectAllOption, ...activeOptions], {
        shouldDirty: true,
      });
    } else if (currentlyHasSelectAll && removedPills.length > 0) {
      setValue(name, newValue.filter(isNotSelectAll), { shouldDirty: true });
    } else {
      setValue(name, newValue, { shouldDirty: true });
    }
  };

  return { selectAllOption, handleOnChangeWithSelectAll };
}

export default useHandleSelectAllOption;
