import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import API from 'services/API';
import { Client } from './ExchangeToken.types';

const useExchangeTokenSubmit = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [client, setClient] = useState<Client>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const clearErrors = () => {
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setErrorMessage(null);
  };

  function setError(message?: string) {
    setErrorMessage(message || t('pageExchangeToken.errorMessage'));
  }

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  function processExchangeToken({ redirectUri }) {
    setIsLoading(true);

    API.post('/api/auth/oauth/clients/register', {
      token,
      redirectUri,
    })
      .then(({ data }) => {
        const { credentials } = data;
        setClient(credentials);
      })
      .catch(({ response }) => {
        setError(response.data?.apiErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSubmitExchangeToken = (values: { redirectUri: string }) => {
    const { redirectUri } = values;

    processExchangeToken({ redirectUri });
  };

  return {
    handleSubmitExchangeToken,
    clearErrors,
    errorMessage,
    token,
    client,
    isLoading,
  };
};

export default useExchangeTokenSubmit;
