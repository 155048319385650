import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import { useWatch } from 'react-hook-form';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';

const NonRefundableRateDiscountField = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    airbnbSettings: { nonRefundableRateEnabled },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const isDisabled =
    nonRefundableRateEnabled === false || nonRefundableRateEnabled === 'false';

  return (
    <TextField
      label={t(
        'pageProperty.mainSettings.airbnbSettings.nonRefundableRateDiscount',
      )}
      name="airbnbSettings.nonRefundableRateDiscount"
      rightAddons={['%']}
      colSmInput={12}
      type="number"
      labelOnTop
      disabled={isDisabled}
    />
  );
};

export default NonRefundableRateDiscountField;
