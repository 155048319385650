interface ChangeCellProps {
  getValue: () => number;
}

const ChangeCell = ({ getValue }: ChangeCellProps) => {
  const value = getValue();

  return `${value > 0 ? '+' : ''}${value}%`;
};

export default ChangeCell;
