import { CSSProperties } from 'react';
import { camelCaseToKebabCase, kebabCaseToCamelCase } from 'utils/stringUtils';

export interface SerializedStyledNode {
  style?: CSSProperties;
}

export function convertStyleToCssProperties(
  style: CSSStyleDeclaration,
): CSSProperties | null {
  if (style) {
    const result = {};

    for (let i = 0; i < style.length; i += 1) {
      const styleKey = style[i];
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      result[kebabCaseToCamelCase(styleKey)] = style[styleKey];
    }

    return result;
  }

  return null;
}

export function convertCssPropertiesToCssText(style: CSSProperties): string {
  if (style) {
    return Object.entries(style)
      .map(([key, value]) => `${camelCaseToKebabCase(key)}: ${value};`)
      .join(' ');
  }

  return '';
}
