import useFetchProperty from 'components/domain/property/useFetchProperty';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import { useParams } from 'react-router-dom';

const serviceProviderNotesFields = `
uid,
name,
extraNotes`;

const useServiceProviderNotes = () => {
  const { uid: propertyUid } = useParams();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();
  const { property, isFetching } = useFetchProperty({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyUid,
    enabled: !!propertyUid,
    fields: serviceProviderNotesFields,
    operationName: 'PropertyExtraNotes',
  });

  return {
    notes: property?.extraNotes ?? '',
    isFetching,
    revalidate: invalidateFetchAllProperties,
  };
};

export default useServiceProviderNotes;
