import styled from 'styled-components';

export const HeaderContainer = styled.div`
  align-items: start;
  display: flex;
  gap: 1rem;

  & a,
  & button.btn-link {
    align-items: center;
    border: 1px solid transparent;
    display: flex;
    gap: 8px;
    height: fit-content;
    padding: 6px 0;
  }
`;
