import { useMemo } from 'react';
import StarProgressBar from 'components/progressBar/StarProgressBar';
import { AirbnbStarRating } from 'models/Reviews';
import { StarRatingsBreakdownContainer } from './OverallAirbnbRatings.styles';

interface StarRatingsBreakdownProps {
  totalCount: number;
  ratings: AirbnbStarRating[];
}

const fillRatings = (ratings: AirbnbStarRating[]) => {
  if (!ratings) {
    return [
      { starRating: 5, count: 0 },
      { starRating: 4, count: 0 },
      { starRating: 3, count: 0 },
      { starRating: 2, count: 0 },
      { starRating: 1, count: 0 },
    ];
  }

  const ratingsMap = new Map<number, number>();

  ratings.forEach(({ starRating, count }) => {
    ratingsMap.set(starRating, count);
  });

  for (let i = 1; i <= 5; i += 1) {
    if (!ratingsMap.has(i)) {
      ratingsMap.set(i, 0);
    }
  }

  const ratingsArray = Array.from(ratingsMap.entries())
    .map(([starRating, count]) => ({ starRating, count }))
    .sort((a, b) => b.starRating - a.starRating);

  return ratingsArray;
};

const StarRatingsBreakdown = ({
  ratings,
  totalCount,
}: StarRatingsBreakdownProps) => {
  const filledRatings = useMemo(() => fillRatings(ratings), [ratings]);

  return (
    <StarRatingsBreakdownContainer data-testid="star-ratings-breakdown">
      {filledRatings.map(({ starRating, count }) => {
        return (
          <StarProgressBar
            key={starRating}
            rating={starRating}
            now={(count / totalCount) * 100}
          />
        );
      })}
    </StarRatingsBreakdownContainer>
  );
};

export default StarRatingsBreakdown;
