import { useTranslation } from 'react-i18next';
import {
  AirbnbGuestReviewCategoryTags,
  AirbnbGuestReviewRatingCategory,
} from 'models/Reviews';
import WarningIcon from 'assets/images/icons/warning.svg?react';
import CheckIcon from 'assets/images/icons/check.svg?react';
import { getReviewSentiment, ReviewSentiment } from '../../GuestReviews.utils';
import {
  CategoryTags,
  RatingCategoryContainer,
} from './DetailedFeedback.styles';

interface RatingCategoryProps extends AirbnbGuestReviewRatingCategory {}

const RatingCategory = ({
  category,
  rate,
  reviewCategoryTags,
}: RatingCategoryProps) => {
  const { t } = useTranslation();

  const getTranslatedTag = (tag: AirbnbGuestReviewCategoryTags) =>
    t(`componentReviews.airbnb.reviewCategoryTags.${tag}`);

  const negativeTags = reviewCategoryTags?.filter(
    (tag) => getReviewSentiment(tag) === ReviewSentiment.NEGATIVE,
  );
  const positiveTags = reviewCategoryTags?.filter(
    (tag) => getReviewSentiment(tag) === ReviewSentiment.POSITIVE,
  );
  const neutralTags = reviewCategoryTags?.filter(
    (tag) => getReviewSentiment(tag) === ReviewSentiment.NEUTRAL,
  );

  return (
    <RatingCategoryContainer data-testid={`rating-category-${category}`}>
      <div className="header">
        <span className="category-name">
          {t(`componentReviews.airbnb.categories.${category}`)}
        </span>
        <span className="category-rate">{rate}</span>
      </div>

      {reviewCategoryTags?.length > 0 && (
        <div className="content">
          {negativeTags.length > 0 ? (
            <CategoryTags data-testid={`negative-tags-${category}`}>
              <WarningIcon />
              <div>{negativeTags.map(getTranslatedTag).join(', ')}</div>
            </CategoryTags>
          ) : null}
          {positiveTags.length > 0 ? (
            <CategoryTags data-testid={`positive-tags-${category}`}>
              <CheckIcon />
              <div>{positiveTags.map(getTranslatedTag).join(', ')}</div>
            </CategoryTags>
          ) : null}
          {neutralTags.length > 0 ? (
            <CategoryTags data-testid={`neutral-tags-${category}`}>
              <div>{neutralTags.map(getTranslatedTag).join(', ')}</div>
            </CategoryTags>
          ) : null}
        </div>
      )}
    </RatingCategoryContainer>
  );
};

export default RatingCategory;
