import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import {
  PropertyRulesBulkUpdatePayload,
  PropertyRulesBulkUpdateResponse,
} from 'models/Rule';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';

type BulkUpdatePropertyRulesArgs = {
  payload: PropertyRulesBulkUpdatePayload;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    PropertyRulesBulkUpdateResponse,
    unknown,
    BulkUpdatePropertyRulesArgs
  >,
  'mutationFn' | 'mutationKey'
>;

const bulkUpdatePropertyRules = async ({
  payload,
}: BulkUpdatePropertyRulesArgs) => {
  return API.post<PropertyRulesBulkUpdateResponse, BaseErrorResponse>(
    '/api/internal/property-rules/bulk-update',
    payload,
  );
};

const useBulkUpdatePropertyRules = (
  options: ReactQueryMutationOptions = {},
) => {
  return useAppMutation({
    mutationFn: bulkUpdatePropertyRules,
    ...options,
  });
};

export default useBulkUpdatePropertyRules;
