import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useAppMutation from 'hooks/useAppMutation';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import { PropertyRulesUpdatePayload } from 'models/Rule';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';

type UpdatePropertyRulesArgs = {
  payload: PropertyRulesUpdatePayload;
  property: Pick<PropertyBase, 'businessType' | 'uid'>;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    AxiosResponse<BaseErrorResponse>,
    unknown,
    UpdatePropertyRulesArgs
  >,
  'mutationFn' | 'mutationKey'
>;

const updatePropertyRules = async ({
  payload,
  property: { businessType, uid },
}: UpdatePropertyRulesArgs) => {
  const uidType =
    businessType === PropertyBusinessType.HOTEL ? 'HOTEL' : 'PROPERTY';
  const resourceUid = uid;

  return API.post<BaseErrorResponse>(
    '/api/internal/property-rules/batch-update',
    payload,
    {
      params: {
        uidType,
        resourceUid,
      },
    },
  );
};

const useUpdatePropertyRules = (options: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: updatePropertyRules,
    ...options,
  });
};

export default useUpdatePropertyRules;
