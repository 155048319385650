import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/table/CommonTable';
import useTable from 'components/table/hooks/useTable';
import {
  RentalConditionObjectType,
  RentalConditions,
} from 'models/RentalCondition';
import { useTranslation } from 'react-i18next';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { useParams } from 'react-router-dom';
import ActionsCell from './cells/ActionsCell';
import useRentalConditionsData from './useRentalConditionsData';

const RentalConditionsTable = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { isLoadingRentalConditions, rentalConditionData } =
    useRentalConditionsData();

  const columnHelper = createColumnHelper<RentalConditions>();

  const columns = [
    columnHelper.accessor('rentalConditions', {
      id: 'rentalConditions',
      header: t(
        'pageProperty.feesTaxesAndPolicies.rentalConditions.definition',
      ),
      enableSorting: false,
      cell: (props) => {
        return props.row.original?.inheritFromAgency
          ? t(
              'pageProperty.feesTaxesAndPolicies.rentalConditions.inheritedFromAgency',
            )
          : props.cell.getValue();
      },
    }),
    // columnHelper.display({
    //   id: 'appliesTo',
    //   header: t('pageProperty.feesTaxesAndPolicies.rentalConditions.appliesTo'),
    //   cell: AppliesToCell,
    // }),
    columnHelper.display({
      id: 'actions',
      header: t('pageProperty.feesTaxesAndPolicies.rentalConditions.actions'),
      cell: ActionsCell,
    }),
  ];

  const emptyRentalCondition = {
    objectType: RentalConditionObjectType.PROPERTY,
    objectUid: propertyUid,
    locale: LanguageCodeUnderscored.EN_US,
    inheritFromAgency: false,
    rentalConditionsUrl: '',
    rentalConditions: '',
  };

  const tableInstance = useTable({
    // We always have only one rental condition
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    data: rentalConditionData ? [rentalConditionData] : [emptyRentalCondition],
    columns,
    manualPagination: true,
    pageCount: 1,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoadingRentalConditions}
      data-testid="rental-conditions-table"
    />
  );
};

export default RentalConditionsTable;
