import { useCallback, useState } from 'react';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useAppUser from 'hooks/useAppUser';
import API from 'services/API';
import { Thread, ThreadReadStatus } from 'models/inbox/Threads';
import { UserType } from 'models/Users';
import { runSyncCheckAction } from 'store/slices/syncCheck/sagas/syncCheckActions';
import useAppDispatch from 'hooks/useAppDispatch';
import { getThreadParticipantReadStatus } from './InboxThreads.utils';

interface UpdateReadStatusPayload {
  readStatus: ThreadReadStatus;
}

function isThreadUnread(thread: Thread, userType: UserType) {
  const readStatus = getThreadParticipantReadStatus(thread, userType);
  return readStatus?.readStatus === ThreadReadStatus.UNREAD;
}

const useThreadReadStatus = (thread: Thread) => {
  const dispatch = useAppDispatch();
  const { userType } = useAppUser();
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const [unread, setUnread] = useState<boolean>(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    isThreadUnread(thread, userType),
  );

  const { uid: threadUid } = thread;

  const { mutate: updateReadStatus, isLoading: isMutating } = useAppMutation(
    ['updateReadStatus'],
    ({ readStatus }: UpdateReadStatusPayload) =>
      API.patch(`/v3/threads/${threadUid}`, { status: readStatus }),
    {
      onSuccess: (response, { readStatus }: UpdateReadStatusPayload) => {
        setUnread(readStatus === ThreadReadStatus.UNREAD);
        const threadParticipantReadStatus = getThreadParticipantReadStatus(
          thread,
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          userType,
        );
        if (threadParticipantReadStatus) {
          threadParticipantReadStatus.readStatus = readStatus;
        }
        dispatch(runSyncCheckAction({ force: true }));
      },
      onError: () => {
        notifyError(t('pageInbox.threadsList.readStatusUpdateError'));
      },
    },
  );

  const markAsRead = useCallback(() => {
    if (!isMutating && unread) {
      updateReadStatus({ readStatus: ThreadReadStatus.READ });
    }
  }, [isMutating, threadUid, unread]);

  const markAsUnread = useCallback(() => {
    if (!isMutating && !unread) {
      updateReadStatus({ readStatus: ThreadReadStatus.UNREAD });
    }
  }, [isMutating, threadUid, unread]);

  return {
    unread,
    markAsRead,
    markAsUnread,
  };
};

export default useThreadReadStatus;
