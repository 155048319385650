import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { MessageTemporaryAttachment } from '../../../../models/inbox/Messages';
import API from '../../../../services/API';
import { UploadFilesResponseTO } from '../../../../models/Files';

const acceptedFormats = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'application/zip',
  'text/csv',
];

async function uploadFiles(data: FormData) {
  return API.post('/v3/files', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}

function setUploadError(uploadErrorMessage: string) {
  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return (attachments) =>
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    attachments.map((attachment) => ({
      ...attachment,
      errorMessage: attachment.isUploading
        ? uploadErrorMessage
        : attachment.errorMessage,
      isUploading: false,
    }));
}

const useInboxMessageEditorAttachments = () => {
  const [attachments, setAttachments] = useState<MessageTemporaryAttachment[]>(
    [],
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { t } = useTranslation();

  const deleteAttachments = useCallback((attachmentUids: string[]) => {
    setAttachments((currentAttachments) =>
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      currentAttachments.filter(({ uid }) => !attachmentUids.includes(uid)),
    );
  }, []);

  const uploadAttachments = useCallback((filesToUpload: FileList | null) => {
    if (filesToUpload?.length) {
      const newAttachments: MessageTemporaryAttachment[] = [];

      for (let i = 0; i < filesToUpload.length; i += 1) {
        const file = filesToUpload[i];
        const { name: fileName, type } = file;

        const errorMessage = !acceptedFormats.includes(type)
          ? t('pageInbox.messages.editor.unsupportedAttachmentFormat')
          : undefined;

        newAttachments.push({
          errorMessage,
          file,
          fileName,
          isUploading: !errorMessage,
          uid: `${i}-${fileName}`,
          uploadItemName: errorMessage ? undefined : `inboxUploadFile${i + 1}`,
        });
      }

      setAttachments((currentAttachments) => [
        ...currentAttachments.filter(({ errorMessage }) => !errorMessage), // remove previous attachments with errors
        ...newAttachments,
      ]);

      const attachmentsToUpload = newAttachments.filter(
        ({ errorMessage }) => !errorMessage,
      );

      if (attachmentsToUpload.length) {
        setIsUploading(true);

        const formData = new FormData();
        formData.append('destination', 'TEMPORARY_ATTACHMENT');

        attachmentsToUpload.forEach(({ file, fileName, uploadItemName }) => {
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          formData.append(uploadItemName, file, fileName);
        });

        uploadFiles(formData)
          .then(
            ({
              data: { apiErrorMessage, files },
            }: AxiosResponse<UploadFilesResponseTO>) => {
              if (apiErrorMessage) {
                setAttachments(setUploadError(apiErrorMessage));
              } else {
                const uploadItemNameToFileItem = files.reduce(
                  (accumulator, file) => ({
                    ...accumulator,
                    [file.uploadItemName]: file,
                  }),
                  {},
                );

                setAttachments((currentAttachments) =>
                  currentAttachments.map((attachment) => {
                    const { uploadItemName, uri } = attachment;
                    const fileItem =
                      uploadItemName &&
                      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                      uploadItemNameToFileItem[uploadItemName];

                    // attachments from previous uploads should be left intact
                    if (!fileItem) {
                      return attachment;
                    }

                    const {
                      messageAttachmentType,
                      sizeInBytes,
                      uri: newUri,
                    } = fileItem;

                    return {
                      ...attachment,
                      isUploading: false,
                      sizeInBytes,
                      type: messageAttachmentType,
                      uri: newUri || uri,
                      uploadItemName: undefined,
                    };
                  }),
                );
              }

              setIsUploading(false);
            },
          )
          .catch(() => {
            setAttachments(
              setUploadError(
                t('pageInbox.messages.editor.unableToUploadAttachment'),
              ),
            );
            setIsUploading(false);
          });
      }
    }
  }, []);

  return { attachments, deleteAttachments, isUploading, uploadAttachments };
};

export default useInboxMessageEditorAttachments;
