import { useEffect, useState } from 'react';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import useIntegrationsActivationStatus from 'components/domain/integrations/useIntegrationsActivationStatus';

const useToggleTurno = () => {
  const { notifyError, notifySuccess } = useNotify();
  const { active: defaultActive, isLoading } = useIntegrationsActivationStatus({
    affiliate: 'turnoverbnb',
  });
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const [isActive, setIsActive] = useState<boolean>(defaultActive);

  useEffect(() => {
    if (!isLoading) {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setIsActive(defaultActive);
    }
  }, [isLoading]);

  const { mutate: turnOn, isLoading: isTurnOnLoading } = useAppMutation(
    () => {
      return API.get('integrations/turnoverbnb/handler_activation.jsp');
    },
    {
      onSuccess: () => {
        notifySuccess('Turnover Management integration ON');
      },
      onError: () => {
        setIsActive(false);
        notifyError('error message');
      },
    },
  );

  const { mutate: turnOff, isLoading: isTurnOffLoading } = useAppMutation(
    () => {
      return API.get('integrations/turnoverbnb/handler_activation_off.jsp');
    },
    {
      onSuccess: () => {
        notifySuccess('Turnover Management integration OFF');
      },
      onError: () => {
        setIsActive(true);
        notifyError('error message');
      },
    },
  );

  const turnOnTurno = () => {
    setIsActive(true);
    turnOn();
  };

  const turnOffTurno = () => {
    setIsActive(false);
    turnOff();
  };

  return {
    turnOffTurno,
    turnOnTurno,
    isActive,
    isLoading: isTurnOnLoading || isTurnOffLoading || isLoading,
  };
};

export default useToggleTurno;
