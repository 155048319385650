import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';
import { AppUser } from '../../../models/Users';

interface UserState {
  appUser: AppUser;
}

const initialState: UserState = {
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  appUser: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setAppUser: (
      state,
      { payload: { appUser } }: PayloadAction<{ appUser: AppUser }>,
    ) => {
      state.appUser = appUser;
    },
    cleanUser: (state) => {
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      state.appUser = null;
    },
  },
});

export const { setAppUser, cleanUser } = userSlice.actions;

export default userSlice.reducer;
