import { useContext } from 'react';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import LeadModalContext from '../../LeadModalContext';

const useSecurityDepositQueries = () => {
  const { lead } = useContext(LeadModalContext);

  const { mutateAsync: markSDAsReceived, isLoading: isFetchingSDReceived } =
    useAppMutation(() =>
      API.post(
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        `/api/internal/leads/${lead.uid}/mark-security-deposit-received`,
      ),
    );

  const { mutateAsync: markSDAsReturned, isLoading: isFetchingSDReturned } =
    useAppMutation(() =>
      API.post(
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        `/api/internal/leads/${lead.uid}/mark-security-deposit-returned`,
      ),
    );

  return {
    markSDAsReceived,
    markSDAsReturned,
    isFetchingSDReceived,
    isFetchingSDReturned,
  };
};

export default useSecurityDepositQueries;
