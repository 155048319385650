import { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Container } from 'components/modal/blockModal/BlockModal.styles';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'utils/lead/leadUtils';
import useAppModal from 'hooks/useAppModal';
import useOpenTemplateModal from 'components/domain/template/modal/useOpenTemplateModal';
import { TemplateName } from 'components/domain/template/modal/TemplateModal.types';
import Loader from '../../../loader/Loader';
import { AdjustmentModalParams } from './AdjustmentModal.types';
import {
  AdjustmentModalBody,
  AdjustmentModalHighlight,
  AdjustmentModalTitle,
} from './AdjustmentModal.styles';
import useAdjustmentModalData from './useAdjustmentModalData';
import AdjustmentModalContext from './AdjustmentModalContext';

const AdjustmentModalInner = ({
  params,
}: {
  params: AdjustmentModalParams;
}) => {
  const { t } = useTranslation();

  const { leadUid } = params;
  const { isLoading, lead, usePaypalIntegration } =
    useAdjustmentModalData(leadUid);
  const { isARefund, modalId } = useContext(AdjustmentModalContext);
  const { closeModal } = useAppModal();
  const { createTemplatedModal } = useOpenTemplateModal();

  const openTemplateModal = () => {
    closeModal(modalId);
    createTemplatedModal({
      templateName: TemplateName.BALANCE_DUE,
      leadUid,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container horizontal>
      <Modal.Header closeButton>
        <AdjustmentModalTitle>
          {t('componentAdjustment.theBookingDetailsFor')}{' '}
          <AdjustmentModalHighlight>
            {/*
             // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
            {getFullName(lead)}
          </AdjustmentModalHighlight>{' '}
          {t('componentAdjustment.haveBeenModified')}
        </AdjustmentModalTitle>
      </Modal.Header>

      <AdjustmentModalBody>
        {isARefund && <p>{t('componentAdjustment.noRefundWarning')}</p>}
        {!isARefund && usePaypalIntegration && (
          <Button
            target="_blank"
            bsStyle="warning link"
            onClick={openTemplateModal}
          >
            {t('componentAdjustment.proceedToConfirmAdjustment')}
          </Button>
        )}
        {!isARefund && !usePaypalIntegration && (
          <Button
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            href={`${window.location.origin}/orderadjustment.jsp?o=${lead.order?.uid}`}
            target="_blank"
            onClick={() => closeModal(modalId)}
            bsStyle="warning link"
          >
            {t('componentAdjustment.proceedToConfirmAdjustment')}
          </Button>
        )}
      </AdjustmentModalBody>
    </Container>
  );
};

export default AdjustmentModalInner;
