import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import { useSelectModeContext } from '../../context/SelectModeContext';
import usePhotoMutationStatus from '../../hooks/usePhotoMutationStatus';

const SelectPhotosButton = () => {
  const { t } = useTranslation();
  const { toggleSelectMode, isSelectMode } = useSelectModeContext();
  const { isUpdatingPhotos } = usePhotoMutationStatus();

  return (
    <PropertyEditActionWrapper tooltipId="property-photos-select-button-tooltip">
      <Button
        data-testid="select-photos-button"
        onClick={toggleSelectMode}
        bsStyle={!isSelectMode ? 'primary' : 'default'}
        disabled={isUpdatingPhotos}
      >
        {isSelectMode
          ? t('pageProperty.photos.exitSelectMode')
          : t('pageProperty.photos.selectPhotos')}
      </Button>
    </PropertyEditActionWrapper>
  );
};

export default SelectPhotosButton;
