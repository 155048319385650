import { useTranslation } from 'react-i18next';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { getLeadDetailLocalizedIntValue } from 'utils/lead/leadUtils';
import { DiscountCodeType } from 'models/Discounts';
import { Lead } from 'models/Leads';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { useFormContext } from 'react-hook-form';
import { FeeAmountType } from 'models/Quote';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';

const Rent = ({
  lead,
  order,
  isImported,
  allowEdit,
}: {
  lead: Lead;
  order: HasOrderItems;
  isImported: boolean;
  allowEdit: boolean;
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const { checkInDate, checkOutDate } = lead;

  const {
    currency,
    totalAmount,
    rent: {
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      netPrice,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rateMultiplier,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rateMultiplierAmount,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rentBaseNetPrice,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      extraGuestsBaseNetPrice,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      discount,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rentBreakdowns,
    },
  } = order;

  const showRentBreakdowns =
    rentBreakdowns?.length > 0 &&
    rentBaseNetPrice ===
      rentBreakdowns.reduce(
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        (acc, current) => acc + current.nightlyBaseAmount,
        0,
      );
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const nightsCount = differenceInCalendarDays(checkOutDate, checkInDate);

  const title = isImported
    ? t('componentOrder.total')
    : t('componentOrder.rent');

  return (
    !!order.rent && (
      <PriceDetailsList $hasNoBorder data-testid="price-details-list-rent">
        <OrderViewPriceDetailsListItem
          title={title}
          priceDetailsItems={[
            {
              label: getLeadDetailLocalizedIntValue(nightsCount, 'night', t),
              amount: !isImported ? rentBaseNetPrice : totalAmount,
              isEditable: allowEdit,
              onItemUpdated: (val) => {
                const key = !isImported
                  ? 'quote.rent.rentBaseNetPrice'
                  : 'quote.totalAmount';
                setValue(key, val, {
                  shouldDirty: true,
                });
              },
              breakdowns:
                showRentBreakdowns &&
                // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                rentBreakdowns?.map((b) => ({
                  label: b.nightlyDate,
                  amount: b.nightlyBaseAmount,
                })),
              currency,
            },
            extraGuestsBaseNetPrice > 0 && {
              label: t('componentOrder.extraGuests'),
              amount: extraGuestsBaseNetPrice,
              onItemUpdated: (val) => {
                setValue('quote.rent.extraGuestsBaseNetPrice', val, {
                  shouldDirty: true,
                });
              },
              isEditable: allowEdit,
              currency,
            },
            rateMultiplierAmount > 0 && {
              label: t('componentOrder.rateMultiplierWithPercentage', {
                value: rateMultiplier,
              }),
              amount: rateMultiplierAmount,
              currency,
            },
            discount && {
              label: t('componentOrder.discountCodeWithCode', {
                value: discount.discountCode,
              }),
              amount:
                discount.discountType === DiscountCodeType.PERCENT
                  ? discount.percent * -1
                  : discount.amount,
              amountType:
                discount.discountType === DiscountCodeType.PERCENT
                  ? FeeAmountType.TAX
                  : FeeAmountType.AMOUNT,
              currency,
            },
            {
              label: t('componentOrder.totalRent'),
              amount: netPrice,
              isTotalAmount: true,
              currency,
            },
          ]}
        />
      </PriceDetailsList>
    )
  );
};

export default Rent;
