import { useContext } from 'react';
import { Lead, LeadStatus } from 'models/Leads';
import {
  getLeadCheckInDate,
  getLeadCheckOutDate,
  isFromICS,
  getFullName,
  convertLeadChannelFromLeadSource,
  isLeadASubBooking,
} from 'utils/lead/leadUtils';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import { isSameWeek, isSaturday, isSunday } from 'date-fns';
import { hideFavicon } from '../LeadView.utils';
import { isCheckinVisible, isCheckoutVisible } from '../Calendar.utils';
import useResponsiveStyleCalendar from '../useResponsiveStyleCalendar';
import { LeadSpan, LeadViewBody } from './LeadView.style';

interface Props {
  lead: Lead;
  date: Date;
}

const LeadViewInner = ({ lead, date }: Props) => {
  const { leadsMap } = useContext(CalendarContext);

  const leadCheckout = getLeadCheckOutDate(lead);
  const leadCheckin = getLeadCheckInDate(lead);
  const isCIVisible = isCheckinVisible(date, lead);
  const isCOVisible = isCheckoutVisible(date, lead);
  const calendarStyleConstants = useResponsiveStyleCalendar();

  const hideGuessName =
    (isSaturday(date) &&
      isSaturday(leadCheckin) &&
      isSameWeek(date, leadCheckin)) ||
    (isSunday(date) &&
      isSunday(leadCheckout) &&
      isSameWeek(date, leadCheckout));

  const leadName = isLeadASubBooking(lead)
    ? // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (leadsMap[lead.groupUid] as Lead)
    : (lead as Lead);

  const name = lead.status !== LeadStatus.BLOCKED ? getFullName(leadName) : '';

  return (
    <LeadViewBody
      $lead={lead}
      $isCheckinVisible={isCIVisible}
      $isCheckoutVisible={isCOVisible}
    >
      {!hideFavicon(date, lead) && (
        <LeadChannelIcon
          source={lead.source}
          status={lead.status}
          channel={convertLeadChannelFromLeadSource(lead.source)}
          whiteVersion
        />
      )}
      {!hideGuessName && (
        <LeadSpan
          $calendarStyleConstants={calendarStyleConstants}
          data-testid="guess-name"
          $isFromICS={isFromICS(lead.source)}
        >
          {name}
        </LeadSpan>
      )}
    </LeadViewBody>
  );
};

export default LeadViewInner;
