import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { Property } from 'models/Properties';
import { PricingRule } from 'models/pricing/PricingRule';

export const priceRulesAddEditFormSchema = () => ({
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        isFixed: Yup.boolean().optional(),
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
  appliesToStatus: Yup.mixed().optional().strip(),
  appliesToTags: Yup.mixed().optional().strip(),
  priceRule: Yup.object({
    name: Yup.string().required(),
    priceModifier: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .min(0)
      .max(100)
      .required(),
    priceModifierThreshold: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .min(0)
      .required(),
    type: Yup.mixed<PropertyPricingType>()
      .oneOf(Object.values(PropertyPricingType))
      .required(),
  }),
});

export type PriceRulesAddEditFormValues = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof priceRulesAddEditFormSchema>>
>;

export const getFormDefaultValues = ({
  pricingRule,
  property,
}: {
  pricingRule?: PricingRule;
  property: Property;
}): PriceRulesAddEditFormValues => {
  const appliesTo = pricingRule?.metadata.appliesToAllProperties
    ? PROPERTIES_SELECTION.ALL_PROPERTIES
    : PROPERTIES_SELECTION.SELECTED_PROPERTIES;
  const appliesToProperties = [
    {
      isFixed: true,
      label: property?.name,
      value: property?.uid,
    },
    ...(pricingRule?.metadata.propertiesThatAppliesTo ?? [])
      .filter((p) => p.uid !== property?.uid)
      .map(({ uid, name }) => ({
        label: name,
        value: uid,
        isFixed: false,
      })),
  ];

  return {
    appliesTo,
    appliesToProperties,
    appliesToStatus: undefined,
    appliesToTags: undefined,
    priceRule: {
      name: pricingRule?.name ?? '',
      priceModifier: pricingRule ? Math.abs(pricingRule.priceModifier) : 0,
      priceModifierThreshold: pricingRule?.priceModifierThreshold ?? 0,
      type: pricingRule?.type ?? PropertyPricingType.LOS_DISCOUNT,
    },
  };
};
