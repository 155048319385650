import { Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUidString } from 'utils/stringUtils';
import {
  PropertySearchFieldAsyncTypeahead,
  PropertySearchOption,
} from './PropertySearchField.styles';
import {
  PropertySearchFieldProps,
  PropertySearchFoundOption,
} from './PropertySearchField.types';
import usePropertySearchMutation from './usePropertySearchMutation';

const PropertySearchField = ({ name, ...props }: PropertySearchFieldProps) => {
  const { t } = useTranslation();
  const { mutateAsync: searchProperties } = usePropertySearchMutation();

  const searchHandler = async (query: string) => {
    return searchProperties({ query });
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onKeyDownHandler = (event) => {
    if ('key' in event && event.key === 'Enter') {
      // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const customOption = (event.target as HTMLElement)
        .closest('.form-field-container')
        .querySelector('[role="option"].rbt-menu-custom-option') as HTMLElement;

      // selects the custom option defined by `allowNew` on hitting 'Enter', if there's one
      customOption?.click();
    }
  };

  return (
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <PropertySearchFieldAsyncTypeahead
      allowNew={(options, { text }) => {
        if (isUidString(text) || options.length === 0) {
          return false;
        }
        return true;
      }}
      name={name}
      labelKey={(option: PropertySearchFoundOption) => `${option.name}`}
      leftAddon={<Glyphicon glyph="search" />}
      placeholder={t('form.propertySearch.placeholderPropertyNameOrUid')}
      searchHandler={searchHandler}
      onKeyDown={onKeyDownHandler}
      renderMenuItemChildren={(option: PropertySearchFoundOption) => (
        <PropertySearchOption data-testid={`found-property-${option.uid}`}>
          {option.name}
        </PropertySearchOption>
      )}
      {...props}
    />
  );
};

PropertySearchField.defaultProps = {
  align: undefined,
};

export default PropertySearchField;
