import { min as minDate, max as maxDate } from 'date-fns';
import {
  CalendarSelection,
  CalendarSelectionContainer,
  CalendarSelectionService,
} from './CalendaBodySelection.types';

const selectionContainer: CalendarSelectionContainer = {};
const subscribers: { [id: string]: (_sel: CalendarSelection) => void } = {};

const CalendarBodySelectionService: CalendarSelectionService = {
  getSelectionContainer: () => selectionContainer,
  updateSelectionMinMaxDates: (min: Date, max: Date) => {
    const { selection } = selectionContainer;

    if (!selection) {
      return;
    }

    selection.min = minDate([min, max]);
    selection.max = maxDate([min, max]);
    CalendarBodySelectionService.notify();
  },
  notify: () => {
    Object.values(subscribers).forEach((notifyCallback) => {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      notifyCallback(selectionContainer.selection);
    });
  },
  reset: () => {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    selectionContainer.selection = null;
  },
  subscribe: (notifyCallback) => {
    const subscriptionId = Math.floor(Math.random() * Date.now()).toString(36);
    subscribers[subscriptionId] = notifyCallback;

    return {
      unsubscribe: () => {
        delete subscribers[subscriptionId];
      },
    };
  },
  setDraggingLead: (draggingLead) => {
    selectionContainer.dragging = draggingLead;
  },
  resetDraggingLead: () => {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    selectionContainer.dragging = null;
  },
};

export default CalendarBodySelectionService;
