import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getSortParam } from 'components/table/utils/sorting';
import {
  GetAirbnbGuestReviewsResponse,
  AirbnbGuestReview,
  AirbnbReviewAction,
  AirbnbGuestReviewCategoryTags,
} from 'models/Reviews';
import API from 'services/API';

export const AIRBNB_REVIEWS_BASE_QUERY_KEY = 'airbnb-reviews';

export const airbnbReviewsQuery = ({
  propertyUid,
  pageIndex,
  sorting,
  pageSize,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    AIRBNB_REVIEWS_BASE_QUERY_KEY,
    'list',
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<AirbnbGuestReview>> => {
    const sort = getSortParam(sorting, {
      creationDate: 'SORT_BY_CREATION_DATE',
      rating: 'SORT_BY_RATING',
      guestName: 'SORT_BY_GUEST_NAME',
    });

    const response = await API.get<GetAirbnbGuestReviewsResponse>(
      '/api/internal/guest-review/airbnb',
      {
        params: {
          propertyUid,
          _limit: pageSize,
          ...(pageIndex > 0
            ? { _cursor: btoa(`{"offset":${pageIndex * pageSize}}`) }
            : {}),
          ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
        },
      },
    );

    const { reviews, _metadata } = response.data;

    return {
      _metadata,
      data: reviews,
    };
  },
  enabled: !!propertyUid,
});

export const shouldAllowRespond = (review: AirbnbGuestReview) => {
  return review.actionsAvailable.includes(AirbnbReviewAction.RESPOND);
};

export enum ReviewSentiment {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export const getReviewSentiment = (
  categoryTag: AirbnbGuestReviewCategoryTags,
): ReviewSentiment => {
  if (!categoryTag) return ReviewSentiment.NEUTRAL;
  if (categoryTag.includes('_NEGATIVE_')) return ReviewSentiment.NEGATIVE;
  if (categoryTag.includes('_POSITIVE_')) return ReviewSentiment.POSITIVE;
  return ReviewSentiment.NEUTRAL;
};
