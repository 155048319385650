import { useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import useAppModal from 'hooks/useAppModal';
import { FormDirtyCheckerOptions } from './Form.types';

const FormDirtyChecker = ({
  options: {
    cancelLabel,
    confirmBody,
    confirmLabel,
    confirmColor,
    confirmTitle,
  } = {},
}: {
  options?: FormDirtyCheckerOptions;
}) => {
  const { openConfirmModal } = useAppModal();
  const { dirtyFields } = useFormState();
  const { t } = useTranslation();

  const blocker = useBlocker(
    ({
      currentLocation: { pathname: currentPathname, search: currentSearch },
      nextLocation: { pathname: nextPathname, search: nextSearch },
    }) =>
      (currentPathname !== nextPathname || currentSearch !== nextSearch) &&
      !!Object.keys(dirtyFields).length,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openConfirmModal({
        title: confirmTitle ?? t('form.dirtyCheck.confirmTitle'),
        body: confirmBody ?? (
          <>
            <div>{t('form.dirtyCheck.messageUnsavedChanges')}</div>
            <div>{t('form.dirtyCheck.messageSaveBefore')}</div>
          </>
        ),
        cancelLabel: cancelLabel ?? t('form.dirtyCheck.cancelLabel'),
        confirmColor: confirmColor ?? 'danger',
        confirmLabel: confirmLabel ?? t('form.dirtyCheck.confirmLabel'),
        onConfirm: () => {
          blocker.proceed();
        },
        onCancel: () => {
          blocker.reset();
        },
      });
    }
  }, [blocker]);

  useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (Object.keys(dirtyFields).length) {
        event.preventDefault();

        return `${t('form.dirtyCheck.messageUnsavedChanges')} ${t(
          'form.dirtyCheck.messageSaveBefore',
        )}`;
      }

      return null;
    };

    return () => {
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      window.onbeforeunload = undefined;
    };
  }, [dirtyFields]);

  return null;
};

FormDirtyChecker.defaultProps = {
  options: {},
};

export default FormDirtyChecker;
