import { Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BulkSavePropertiesModalFieldsDescriptions from 'components/domain/property/modal/BulkSavePropertiesModalFieldsDescriptions';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import FormWithProvider from 'components/forms/form/Form';
import { PropertyBusinessType } from 'models/Properties';
import { propertySettingsBulkSavableFields } from '../common/Common.constants';
import { commonFieldValueConverter } from '../mainSettings/PropertyMainSettingsTab.utils';
import {
  PropertySettingsBulkSavableFormDirtyFields,
  PropertySettingsBulkSavableFormValues,
  PropertySettingsBulkSaveModalParams,
} from './PropertySettingsBulkSaveModal.types';
import usePropertySettingsBulkSaveModalFieldDescriptions from './usePropertySettingsBulkSaveModalFieldDescriptions';
import PropertySettingsBulkSaveModalFooter from './PropertySettingsBulkSaveModalFooter';
import {
  CurrentPropertyName,
  PropertySettingsBulkSaveFieldsRow,
} from './PropertySettingsBulkSaveModal.styles';
import usePropertySettingsBulkSaveFormSubmit from './usePropertySettingsBulkSaveFormSubmit';
import { propertySettingsBulkSaveFormSchema } from './PropertySettingsBulkSaveModal.schema';

const fieldValuesConverters = {
  bookingDotComSettings: {
    ...commonFieldValueConverter.bookingDotComSettings,
  },
};

function getFilteredFieldChanges({
  bulkEditable,
  dirtyFields,
  formValues,
}: {
  bulkEditable: boolean;
  dirtyFields: PropertySettingsBulkSavableFormDirtyFields;
  formValues: PropertySettingsBulkSavableFormValues;
}) {
  return Object.entries(dirtyFields).reduce(
    (acc, [sectionName, sectionDirtyFields]) => {
      const filteredFields = Object.entries(sectionDirtyFields)
        .filter(
          ([fieldName]) =>
            // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            !!propertySettingsBulkSavableFields[sectionName]?.includes(
              fieldName,
            ) === bulkEditable,
        )
        .reduce((sectionAcc, [fieldName, isDirtyOrDirtyObject]) => {
          // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          const fieldValue = formValues[sectionName][fieldName];
          const fieldValueConverter =
            // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            fieldValuesConverters[sectionName]?.[fieldName];

          return {
            ...sectionAcc,
            [fieldName]: fieldValueConverter
              ? fieldValueConverter(fieldValue, isDirtyOrDirtyObject)
              : fieldValue,
          };
        }, {});

      if (Object.keys(filteredFields).length) {
        return {
          ...acc,
          [sectionName]: filteredFields,
        };
      }

      return acc;
    },
    {},
  );
}

const PropertySettingsBulkSaveModal = ({
  modalId,
  params: {
    bookingDotComCancellationPolicies,
    dirtyFields,
    employees,
    formValues,
    mainSettings,
    property,
    propertyTypes,
    tabName,
  },
}: {
  modalId: string;
  params: PropertySettingsBulkSaveModalParams;
}) => {
  const { name: propertyName, uid: propertyUid } = property;
  const getFieldDescriptions =
    usePropertySettingsBulkSaveModalFieldDescriptions();
  const { onSubmit, isUpdating } = usePropertySettingsBulkSaveFormSubmit({
    dirtyFields,
    formValues,
    modalId,
    propertyUid,
    tabName,
  });
  const { t } = useTranslation();

  const bulkEditableFieldChanges = getFilteredFieldChanges({
    bulkEditable: true,
    dirtyFields,
    formValues,
  });
  const nonBulkEditableFieldChanges = getFilteredFieldChanges({
    bulkEditable: false,
    dirtyFields,
    formValues,
  });

  const nonBulkEditableFieldDescriptions = getFieldDescriptions({
    allFieldValues: formValues,
    bookingDotComCancellationPolicies,
    employees,
    fields: nonBulkEditableFieldChanges,
    mainSettings,
    property,
    propertyTypes,
    tabName,
  });
  const bulkEditableFieldDescriptions = getFieldDescriptions({
    allFieldValues: formValues,
    bookingDotComCancellationPolicies,
    employees,
    fields: bulkEditableFieldChanges,
    mainSettings,
    property,
    propertyTypes,
    tabName,
  });

  const defaultValues = {
    appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
    appliesToProperties: [
      {
        isFixed: true,
        label: propertyName,
        value: propertyUid,
      },
    ],
  };

  const graphQlFilters = { topLevelOnly: false };

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const filterPropertyBy = ({ businessType }) =>
    businessType === PropertyBusinessType.STANDALONE_PROPERTY ||
    businessType === PropertyBusinessType.SUB_UNIT;

  const hasNonBulkEditableFieldChanges =
    Object.keys(nonBulkEditableFieldChanges).length > 0;

  return (
    <FormWithProvider
      defaultValues={defaultValues}
      disabled={isUpdating}
      onSubmit={onSubmit}
      resolver={propertySettingsBulkSaveFormSchema()}
      noValidate
    >
      <fieldset disabled={isUpdating}>
        <Modal.Body>
          <PropertySettingsBulkSaveFieldsRow>
            {hasNonBulkEditableFieldChanges && (
              <Col data-testid="non-bulk-savable-fields" sm={6}>
                <BulkSavePropertiesModalFieldsDescriptions
                  fieldsDescriptions={nonBulkEditableFieldDescriptions}
                />
                <section data-testid="non-bulk-savable-fields-applies-to">
                  <span data-testid="description">
                    {t('componentProperty.bulkSaveModal.appliedTo')}
                  </span>
                  <CurrentPropertyName data-testid="property-name">
                    {propertyName}
                  </CurrentPropertyName>
                </section>
              </Col>
            )}
            <Col
              data-testid="bulk-savable-fields"
              sm={hasNonBulkEditableFieldChanges ? 6 : 12}
            >
              <BulkSavePropertiesModalFieldsDescriptions
                fieldsDescriptions={bulkEditableFieldDescriptions}
              />
              <section data-testid="bulk-savable-fields-applies-to">
                <span data-testid="description">
                  {t('componentProperty.bulkSaveModal.appliedTo')}
                </span>
                <PropertyMultiSelectFilterField
                  name="appliesTo"
                  graphQlFilters={graphQlFilters}
                  filterPropertyBy={filterPropertyBy}
                  filtersColXs={hasNonBulkEditableFieldChanges ? 12 : 6}
                />
              </section>
            </Col>
          </PropertySettingsBulkSaveFieldsRow>
        </Modal.Body>
        <PropertySettingsBulkSaveModalFooter modalId={modalId} />
      </fieldset>
    </FormWithProvider>
  );
};

export default PropertySettingsBulkSaveModal;
