import axios from 'axios';
import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';

const useReferLink = () => {
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { user, isEmployee } = useAppUser();

  const userEmail = user?.email || '';

  const { data: referLink, isLoading } = useAppQuery(
    ['get-refer-friend-link', userEmail],
    async () => {
      const response = await axios.post<{
        rdata: string;
      }>(
        `https://api.partnerstack.com/v1/partnerships/anonymous/hostfully/${userEmail}`,
      );
      const rdata = JSON.parse(response.data?.rdata);
      return rdata.link;
    },
    {
      enabled: isEmployee && !!userEmail,
      onError: () => notifyError(t('pageAccount.somethingWentWrong')),
    },
  );

  return {
    referLink,
    isLoading,
  };
};

export default useReferLink;
