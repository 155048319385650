import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AirbnbGuestReview, AirbnbRatingCategory } from 'models/Reviews';
import Button from 'components/button/Button';
import {
  DetailedFeedbackContainer,
  DetailedFeedbackGrid,
} from './DetailedFeedback.styles';
import RatingCategory from './RatingCategory';

interface DetailedFeedbackProps {
  review: AirbnbGuestReview;
}

const definedOrderCategories = [
  AirbnbRatingCategory.CHECKIN,
  AirbnbRatingCategory.COMMUNICATION,
  AirbnbRatingCategory.CLEANLINESS,
  AirbnbRatingCategory.LOCATION,
  AirbnbRatingCategory.ACCURACY,
  AirbnbRatingCategory.VALUE,
];

const DetailedFeedback = ({ review }: DetailedFeedbackProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { ratingCategories } = review;

  const sortedCategories = ratingCategories.toSorted((a, b) => {
    return (
      definedOrderCategories.indexOf(a.category) -
      definedOrderCategories.indexOf(b.category)
    );
  });

  const displayedCategories = isExpanded
    ? sortedCategories
    : sortedCategories.slice(0, 2);

  return (
    <DetailedFeedbackContainer>
      <DetailedFeedbackGrid>
        {displayedCategories.map((categoryData) => (
          <RatingCategory key={categoryData.category} {...categoryData} />
        ))}
      </DetailedFeedbackGrid>

      {sortedCategories.length > 2 && (
        <Button bsStyle="link" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded
            ? t('pageProperty.reviews.airbnbGuest.guestReviews.modal.viewLess')
            : t('pageProperty.reviews.airbnbGuest.guestReviews.modal.viewMore')}
        </Button>
      )}
    </DetailedFeedbackContainer>
  );
};

export default DetailedFeedback;
