import { useTranslation } from 'react-i18next';
import { Photo } from 'models/Photos';
import PageHeader from 'components/pageHeader/PageHeader';
import { useSelectModeContext } from '../../context/SelectModeContext';
import PageTitleDeleteButton from '../selectMode/PageTitleDeleteButton';
import HeaderButtons from './HeaderButtons';

const PropertySettingsPhotosTabHeader = ({
  isLoading,
  photos,
}: {
  isLoading: boolean;
  photos: Photo[];
}) => {
  const { t } = useTranslation();
  const { isSelectMode } = useSelectModeContext();

  const pageTitleWhenNotSelectMode = `${t('pageProperty.photos.tabTitle')}${
    photos?.length > 0 ? ` (${photos.length})` : ''
  }`;

  const title = isSelectMode ? (
    <PageTitleDeleteButton />
  ) : (
    pageTitleWhenNotSelectMode
  );

  return (
    <PageHeader
      className="tab-title"
      title={title}
      isLoading={isLoading}
      buttons={<HeaderButtons />}
      subtitle={!isSelectMode ? t('pageProperty.photos.subtitle') : undefined}
      noDivider
    />
  );
};

export default PropertySettingsPhotosTabHeader;
