import { isValid, parseISO } from 'date-fns';
import * as Yup from 'yup';

const StackedCalendarFiltersSchema = Yup.object({
  bedrooms: Yup.array().of(Yup.number()).length(2),
  guests: Yup.array().of(Yup.number()).length(2),
  status: Yup.string(),
  dailyRate: Yup.array().of(Yup.number()).length(2),
  type: Yup.string(),
  agentUid: Yup.string(),
  location: Yup.string(),
  availableFrom: Yup.mixed()
    .oneOf([Yup.date(), Yup.string()])
    .transform((value) => {
      const maybeDate = parseISO(value);

      return isValid(maybeDate) ? maybeDate : '';
    }),
  availableTo: Yup.mixed()
    .oneOf([Yup.date(), Yup.string()])
    .transform((value) => {
      const maybeDate = parseISO(value);

      return isValid(maybeDate) ? maybeDate : '';
    }),
  tags: Yup.array().of(Yup.object()),
  amenities: Yup.array().of(Yup.object()),
});

export type StackedCalendarFiltersSchemaType = Yup.InferType<
  typeof StackedCalendarFiltersSchema
>;

export const defaultValues = (
  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  filterOptions,
): StackedCalendarFiltersSchemaType => ({
  bedrooms: [filterOptions?.minBedrooms, filterOptions?.maxBedrooms],
  guests: [filterOptions?.minGuests, filterOptions?.maxGuests],
  status: 'active',
  dailyRate: [filterOptions?.minBaseDailyRate, filterOptions?.maxBaseDailyRate],
  type: '',
  agentUid: '',
  location: '',
  availableFrom: '',
  availableTo: '',
  tags: [],
  amenities: [],
});

export default StackedCalendarFiltersSchema;
