import { useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import { Container, Description, Details, DetailsItem } from './Refer.styles';
import ReferFormWrapper from './ReferFormWrapper';

const Refer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={t('pageRefer.title')}
        subtitle={t('pageRefer.subtitle')}
      />

      <Description data-testid="description">
        {t('pageRefer.description')}
      </Description>

      <ReferFormWrapper />

      <Details data-testid="details">
        <DetailsItem>{t('pageRefer.details.1')}</DetailsItem>
        <DetailsItem>{t('pageRefer.details.2')}</DetailsItem>
        <DetailsItem>{t('pageRefer.details.3')}</DetailsItem>
      </Details>
    </Container>
  );
};

export default Refer;
