import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppUser from 'hooks/useAppUser';
import useIsPageWithoutFooter from 'hooks/layout/useIsPageWithoutFooter';
import useOpenBlockModal from 'components/modal/blockModal/useOpenBlockModal';
import { setIsLegacyPageLoading } from 'store/slices/legacyPage';
import { LEGACY_PAGES, LEGACY_PAGES_PUBLIC } from './legacyPages.constants';
import LegacyPageLoader from './LegacyPageLoader';

interface LegacyPageProps {
  pageId?: string;
}

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function getIframeSrc({ pageId, pageUrl, search }) {
  const params = search !== '?' ? search : '';
  if (pageUrl) {
    return `/${pageUrl}${params}`;
  }

  const pageDescriptor = LEGACY_PAGES.find(({ pageId: id }) => pageId === id);

  if (!pageDescriptor) {
    throw new Error(`Not implemented legacy page: ${pageId}`);
  }

  const { iframeSrc } = pageDescriptor;
  return `${iframeSrc}${params}`;
}

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
function getReloadParam({ iframeSrc, iframeRef }) {
  const currentSrc =
    iframeRef.current && (iframeRef.current as HTMLIFrameElement).src;
  if (currentSrc && currentSrc.includes(iframeSrc)) {
    return `&_=${Math.random()}`;
  }
  return '';
}

const allowedPagesForBlockModalListener = ['/dashboard', '/pipeline'];

const LegacyPage = ({ pageId }: LegacyPageProps) => {
  const { pageUrl } = useParams();
  const { pathname, search, hash } = useLocation();
  const iframeRef = useRef<HTMLIFrameElement>();
  const dispatch = useAppDispatch();
  const { isGuest } = useAppUser();
  const isPageWithoutFooter = useIsPageWithoutFooter();

  // TODO delete once modals merged
  const isAllowedPageForBlockModalListener =
    allowedPagesForBlockModalListener.some((path) => pathname.startsWith(path));
  useOpenBlockModal({
    listenerCreationNotAllowed: !isAllowedPageForBlockModalListener,
  });

  const iframeSrc = getIframeSrc({ pageId, pageUrl, search });
  const src = `${iframeSrc}${
    iframeSrc.includes('?') ? '&' : '?'
  }pmpRequestOrigin=PMP_WEBAPP${getReloadParam({
    iframeSrc,
    iframeRef,
  })}${hash}`;

  const isPagePublic = LEGACY_PAGES_PUBLIC.find(
    (page) => page.pageId === pageId,
  );
  const heightOffset = (isPageWithoutFooter && 90) || (isGuest && 165) || 150;

  useEffect(() => {
    dispatch(setIsLegacyPageLoading(true));
  }, [src]);

  const handleIframeClick = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      iframe.contentWindow.addEventListener('click', () => {
        const event = new CustomEvent('iframeClick');
        window.dispatchEvent(event);
      });
    }
  };

  useEffect(() => {
    // Double scrollbar fix:
    const pageContent = document.getElementById('app-page-content');
    if (pageContent) pageContent.style.overflowY = 'hidden';

    return () => {
      if (pageContent) pageContent.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const header = iframe.contentDocument?.querySelector(
        '#webapp-header',
      ) as HTMLElement;
      if (header) {
        header.style.display = 'none';
      }
      const footer = iframe.contentDocument?.querySelector(
        '#webapp-footer',
      ) as HTMLElement;
      if (footer) {
        footer.style.display = 'none';
      }
    }
  }, [iframeRef.current]);

  return (
    <>
      <LegacyPageLoader />
      <iframe
        src={src}
        data-testid="legacy-page-frame"
        id="legacy-page-frame"
        title="legacy-page-frame"
        width="100%"
        style={{
          border: 'none',
          minHeight:
            !isPagePublic && !isMobile ? `calc(100vh - ${heightOffset}px)` : '',
          height: isPagePublic || isMobile ? '100%' : '',
        }}
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        ref={iframeRef}
        onLoad={() => {
          handleIframeClick();
          dispatch(setIsLegacyPageLoading(false));
        }}
      />
    </>
  );
};

LegacyPage.defaultProps = {
  pageId: '',
};

export default LegacyPage;
