import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import {
  RentalConditionObjectType,
  RentalConditions,
} from 'models/RentalCondition';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import API from 'services/API';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { useTranslation } from 'react-i18next';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { RENTAL_CONDITIONS_BASE_QUERY_KEY } from '../useRentalConditionsData';
import EditRentalConditionsModal from './EditRentalConditionsModal';
import {
  DefinitionRadioOptions,
  EditRentalConditionsFormSchemaType,
} from './EditRentalConditionsModal.schema';

export const modalId = 'edit-rental-conditions-modal';

export const EDIT_RENTAL_CONDITIONS_BASE_MUTATION_KEY =
  'edit-rental-conditions';

type Props = {
  rentalConditions: RentalConditions;
};

const useEditRentalConditions = ({ rentalConditions }: Props) => {
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const mutationKey = [EDIT_RENTAL_CONDITIONS_BASE_MUTATION_KEY, property.uid];

  const { mutate: editRentalCondition } = useAppMutation(
    mutationKey,
    ({
      rentalConditions: newRentalConditions,
      definition,
    }: EditRentalConditionsFormSchemaType) => {
      const inheritFromAgency = definition === DefinitionRadioOptions.AGENCY;

      return API.put('/api/internal/rental-conditions', {
        objectType: RentalConditionObjectType.PROPERTY,
        objectUid: property.uid,
        data: {
          inheritFromAgency,
          rentalConditionsData: inheritFromAgency
            ? null
            : {
                locale:
                  rentalConditions.locale || LanguageCodeUnderscored.EN_US,
                rentalConditions: newRentalConditions,
              },
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          RENTAL_CONDITIONS_BASE_QUERY_KEY,
          RentalConditionObjectType.PROPERTY,
          property.uid,
        ]);

        closeModal(modalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleSubmit = (values: EditRentalConditionsFormSchemaType) => {
    editRentalCondition(values);
  };

  const openEditModal = () => {
    openModal({
      id: modalId,
      customContent: (
        <EditRentalConditionsModal
          onSubmit={handleSubmit}
          rentalConditions={rentalConditions}
          modalId={modalId}
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          mutationKey={mutationKey}
        />
      ),
    });
  };

  return { openEditModal };
};

export default useEditRentalConditions;
