import { formatISO } from 'date-fns';
import * as Yup from 'yup';
import { queryClient } from 'App';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import FormWithProvider from 'components/forms/form/Form';
import useCreateManualTransaction from 'components/domain/transaction/useCreateManualTransaction';
import useAppModal from 'hooks/useAppModal';
import { TransactionStatus, TransactionType } from 'models/Transactions';
import {
  AddManualTransactionFormValues,
  AddManualTransactionModalParams,
} from './AddManualTransactionModal.types';
import AddManualTransactionModalFormElements from './AddManualTransactionModalFormElements';

const addManualTransactionFormSchema = () => ({
  amount: Yup.number().positive().required(),
  created: Yup.date().required(),
  notes: Yup.string().max(100).optional(),
  type: Yup.mixed<TransactionType>()
    .oneOf([TransactionType.REFUND, TransactionType.SALE])
    .required(),
});

const AddManualTransactionModal = ({
  modalId,
  params: { lead },
}: {
  modalId: string;
  params: AddManualTransactionModalParams;
}) => {
  const { closeModal } = useAppModal();
  const { createManualTransaction } = useCreateManualTransaction();

  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    order: { currency, uid: orderUid },
    uid: leadUid,
  } = lead;

  const onCancel = () => {
    closeModal(modalId);
  };

  const onSubmit = async ({
    amount,
    created,
    notes,
    type,
  }: AddManualTransactionFormValues) => {
    await createManualTransaction({
      transaction: {
        amount,
        orderUid,
        created: formatISO(created, {
          representation: 'date',
        }),
        notes,
        status: TransactionStatus.SUCCESS,
        type,
      },
      successCallback: () => {
        queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]);
        closeModal(modalId);
      },
    });
  };

  const formDefaultValues = {
    created: new Date(),
    type: TransactionType.SALE,
  };

  return (
    <FormWithProvider
      defaultValues={formDefaultValues}
      noValidate
      onSubmit={onSubmit}
      schema={addManualTransactionFormSchema()}
    >
      <AddManualTransactionModalFormElements
        currency={currency}
        onCancel={onCancel}
      />
    </FormWithProvider>
  );
};

export default AddManualTransactionModal;
