import useAppMutation from 'hooks/useAppMutation';
import {
  PropertyAmenitiesBulkUpdatePayload,
  PropertyAmenitiesBulkUpdateResponse,
} from 'models/Amenity';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';

const useBulkUpdatePropertyAmenities = () => {
  return useAppMutation({
    mutationFn: ({
      payload,
    }: {
      payload: PropertyAmenitiesBulkUpdatePayload;
    }) => {
      return API.post<PropertyAmenitiesBulkUpdateResponse, BaseErrorResponse>(
        '/api/internal/amenities/bulk-update',
        payload,
      );
    },
  });
};

export default useBulkUpdatePropertyAmenities;
