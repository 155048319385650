import { Channel } from 'models/Channels';
import { DescriptionFields } from './PropertySettingsDescriptionsTab.types';

export enum FieldVisibility {
  VISIBLE = 'VISIBLE',
  VISIBLE_AND_REQUIRED = 'VISIBLE_AND_REQUIRED',
  NOT_VISIBLE = 'NOT_VISIBLE',
}

/**
 * Maps between a Channel enumId and an object containing each description field
 * and its visibility state (visible, visible and required, or not visible).
 */
export const ChannelToFieldVisibilityMapping: {
  [K in Channel]?: Record<DescriptionFields, FieldVisibility>;
} = {
  [Channel.orbirental]: {
    access: FieldVisibility.VISIBLE,
    houseManual: FieldVisibility.NOT_VISIBLE,
    interaction: FieldVisibility.VISIBLE,
    name: FieldVisibility.VISIBLE_AND_REQUIRED,
    neighbourhood: FieldVisibility.VISIBLE,
    notes: FieldVisibility.VISIBLE,
    shortSummary: FieldVisibility.VISIBLE_AND_REQUIRED,
    space: FieldVisibility.VISIBLE,
    summary: FieldVisibility.VISIBLE_AND_REQUIRED,
    transit: FieldVisibility.VISIBLE,
  },
  [Channel.airbnb]: {
    access: FieldVisibility.VISIBLE,
    houseManual: FieldVisibility.VISIBLE,
    interaction: FieldVisibility.VISIBLE,
    name: FieldVisibility.VISIBLE_AND_REQUIRED,
    neighbourhood: FieldVisibility.VISIBLE,
    notes: FieldVisibility.VISIBLE,
    shortSummary: FieldVisibility.NOT_VISIBLE,
    space: FieldVisibility.VISIBLE,
    summary: FieldVisibility.VISIBLE_AND_REQUIRED,
    transit: FieldVisibility.VISIBLE,
  },
  [Channel.homeaway]: {
    access: FieldVisibility.VISIBLE,
    houseManual: FieldVisibility.NOT_VISIBLE,
    interaction: FieldVisibility.VISIBLE,
    name: FieldVisibility.NOT_VISIBLE,
    neighbourhood: FieldVisibility.VISIBLE,
    notes: FieldVisibility.VISIBLE,
    shortSummary: FieldVisibility.VISIBLE_AND_REQUIRED,
    space: FieldVisibility.VISIBLE,
    summary: FieldVisibility.VISIBLE_AND_REQUIRED,
    transit: FieldVisibility.VISIBLE,
  },
  [Channel.booking_com]: {
    access: FieldVisibility.NOT_VISIBLE,
    houseManual: FieldVisibility.NOT_VISIBLE,
    interaction: FieldVisibility.NOT_VISIBLE,
    name: FieldVisibility.VISIBLE_AND_REQUIRED,
    neighbourhood: FieldVisibility.NOT_VISIBLE,
    notes: FieldVisibility.NOT_VISIBLE,
    shortSummary: FieldVisibility.VISIBLE_AND_REQUIRED,
    space: FieldVisibility.NOT_VISIBLE,
    summary: FieldVisibility.VISIBLE_AND_REQUIRED,
    transit: FieldVisibility.NOT_VISIBLE,
  },
  [Channel.google]: {
    access: FieldVisibility.VISIBLE,
    houseManual: FieldVisibility.NOT_VISIBLE,
    interaction: FieldVisibility.VISIBLE,
    name: FieldVisibility.VISIBLE_AND_REQUIRED,
    neighbourhood: FieldVisibility.VISIBLE,
    notes: FieldVisibility.VISIBLE,
    shortSummary: FieldVisibility.VISIBLE_AND_REQUIRED,
    space: FieldVisibility.VISIBLE,
    summary: FieldVisibility.VISIBLE_AND_REQUIRED,
    transit: FieldVisibility.VISIBLE,
  },
  [Channel.hvmi]: {
    access: FieldVisibility.VISIBLE,
    houseManual: FieldVisibility.NOT_VISIBLE,
    interaction: FieldVisibility.VISIBLE,
    name: FieldVisibility.VISIBLE_AND_REQUIRED,
    neighbourhood: FieldVisibility.VISIBLE,
    notes: FieldVisibility.VISIBLE,
    shortSummary: FieldVisibility.VISIBLE_AND_REQUIRED,
    space: FieldVisibility.VISIBLE,
    summary: FieldVisibility.VISIBLE_AND_REQUIRED,
    transit: FieldVisibility.VISIBLE,
  },
};

export const isRequiredAtPropertyLevel = (
  activeChannelsAtPropertyLevel: Channel[],
  fieldName: DescriptionFields,
) => {
  const channelVisibilityAtPropertyLevel = activeChannelsAtPropertyLevel
    .map((channel) => ChannelToFieldVisibilityMapping[channel])
    .filter(Boolean);

  return channelVisibilityAtPropertyLevel.some((fieldVisibility) => {
    return [FieldVisibility.VISIBLE_AND_REQUIRED].includes(
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      fieldVisibility[fieldName],
    );
  });
};
