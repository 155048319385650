import useTagMutations from 'components/domain/tag/useTagMutations';
import { TagType } from 'models/Tags';
import { areArraysEqual } from 'utils/arrayUtils';

const useUpdatePhotoTags = (photoUid: string) => {
  const { addTagAsync, deleteTagAsync } = useTagMutations();

  const addMultipleTags = (tags: string[]) => {
    const promises = tags.map((tag) =>
      addTagAsync({
        tag,
        tagType: TagType.PHOTO,
        entityUid: photoUid,
      }),
    );
    return Promise.all(promises);
  };

  const deleteMultipleTags = async (tags: string[]) => {
    const promises = tags.map((tag) =>
      deleteTagAsync({
        tag,
        tagType: TagType.PHOTO,
        entityUid: photoUid,
      }),
    );
    return Promise.all(promises);
  };

  const handleTagsUpdate = async (
    currentTagNames: string[],
    newTagNames: string[],
  ) => {
    if (areArraysEqual(currentTagNames, newTagNames)) {
      return;
    }

    const tagsToAdd = newTagNames.filter(
      (tagName) => !currentTagNames.includes(tagName),
    );

    const tagsToDelete = currentTagNames.filter(
      (tagName) => !newTagNames.includes(tagName),
    );

    await Promise.all([
      tagsToAdd.length > 0 ? addMultipleTags(tagsToAdd) : Promise.resolve(),
      tagsToDelete.length > 0
        ? deleteMultipleTags(tagsToDelete)
        : Promise.resolve(),
    ]);
  };

  return { handleTagsUpdate };
};

export default useUpdatePhotoTags;
