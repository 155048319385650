import Collapse from 'components/collapse/Collapse';
import styled from 'styled-components';
import theme from 'styles/theme';

export const OrderViewPriceDetailsListWrapper = styled.div<{
  $isLoading?: boolean;
}>`
  border: 1px solid ${theme.colors.gray300};
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  ${({ $isLoading }) => $isLoading && 'opacity: 0.2;'}
`;

export const PriceDetailsList = styled.div.attrs({
  className: 'order-view-price-details-list-item',
})<{
  $hasNoBorder?: boolean;
}>`
  & > div {
    border-top: ${({ $hasNoBorder }) =>
      $hasNoBorder ?? false
        ? 'none !important'
        : `1px solid ${theme.colors.gray300}`};
  }
`;

export const PriceDetailsListTotal = styled.div`
  background-color: #f3f3f7;
  font-weight: bold;

  > div:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray500};
  }

  > div {
    margin: 0 0.5rem;
  }
`;

export const PriceDetailsListSubOrderTotal = styled(PriceDetailsListTotal)`
  background-color: white;
`;

export const OrderViewPriceDetailsInnerCollapseStyled = styled(Collapse)`
  & {
    margin-bottom: 10px;

    .collapsible-header {
      background-color: transparent;
      border-bottom: 0;
    }

    .collapsible-body {
      padding: 0;
    }

    .price-details-wrapper {
      border: 0;
      margin-bottom: 0;
    }
  }
`;
