import { AxiosError } from 'axios';

interface LegacyMetadata {
  /**
   * @deprecated Metadata fields should be prefixed with an underscore (_), new APIs will follow this pattern and thus
   * fields without this prefix will not work in the future.
   */
  count: number;

  /**
   * @deprecated Metadata fields should be prefixed with an underscore (_), new APIs will follow this pattern and thus
   * fields without this prefix will not work in the future.
   */
  totalCount: number | null;
}

interface Metadata {
  _count: number;
  _totalCount: number | null;
}

export interface BaseResponse {
  _metadata: Metadata | LegacyMetadata;
}

export interface BasePagingResponse {
  _paging: { limit: number; cursor: any };
}

export interface BaseErrorResponse
  extends AxiosError<{
    apiErrorCode?: string;
    apiErrorMessage: string;
    uid: string;
  }> {}

export enum ObjectUidFetchType {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
}
