import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import LinkWithIcon from 'pages/property/common/LinkWithIcon';

export const NonRefundableRateDiscountContainer = styled(Col)`
  padding-right: 0;

  .form-field-container {
    margin-left: -15px;
    margin-top: -25px;
  }
`;

export const ViewAirbnbResolutionsStyled = styled(LinkWithIcon)`
  align-self: flex-end;
  width: fit-content;
  margin-top: 15px;
`;
