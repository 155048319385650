import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import useAppModal from 'hooks/useAppModal';
import { CancellationPolicyValue } from 'models/CancellationPolicy';
import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export const cancellationPolicyModalId = 'cancellation-policy-modal';

const formSchema = () => ({
  // appliesTo: Yup.string().required(),
  // appliesToProperties: Yup.array()
  //   .of(
  //     Yup.object({
  //       label: Yup.string(),
  //       value: Yup.string(),
  //       isFixed: Yup.boolean().optional(),
  //     }),
  //   )
  //   .label('properties')
  //   .when('appliesTo', {
  //     is: PROPERTIES_SELECTION.ALL_PROPERTIES,
  //     then: (schema) => schema.optional().nullable(true),
  //     otherwise: (schema) => schema.min(1).required(),
  //   }),
  text: Yup.string().max(1000).required(),
});

export type CancellationPolicyFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof formSchema>>
>;

// const filterPropertyBy = ({
//   businessType,
// }: {
//   businessType: PropertyBusinessType;
// }) =>
//   businessType !== PropertyBusinessType.HOTEL &&
//   businessType !== PropertyBusinessType.UNIT;

const CancellationPolicyModal = ({
  onSubmit,
  mutationKey,
  cancellationPolicyValue,
}: {
  onSubmit: (data: CancellationPolicyFormSchemaType) => void;
  mutationKey: string[];
  cancellationPolicyValue?: CancellationPolicyValue;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isEditing = !!cancellationPolicyValue;
  const isUpdating = isMutating > 0;

  const handleClose = () => {
    closeModal(cancellationPolicyModalId);
  };

  return (
    <FormWithProvider
      schema={formSchema()}
      onSubmit={onSubmit}
      defaultValues={{
        // appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
        // appliesToProperties: [
        //   {
        //     label: property.name,
        //     value: property.uid,
        //     isFixed: true,
        //   },
        // ],
        text: cancellationPolicyValue?.text,
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {isEditing
            ? t(
                'pageProperty.feesTaxesAndPolicies.cancellationPolicy.modal.editTitle',
              )
            : t(
                'pageProperty.feesTaxesAndPolicies.cancellationPolicy.modal.addTitle',
              )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex flex-column">
          <TextField
            name="text"
            label={t(
              'pageProperty.feesTaxesAndPolicies.cancellationPolicy.modal.text',
            )}
            componentClass="textarea"
            required
            colSmInput={8}
            colSmLabel={4}
            rows={6}
            maxCharacters={1000}
            maxLength={1000}
          />
          {/* <PropertyMultiSelectFilterField
            label={t(
              'pageProperty.feesTaxesAndPolicies.cancellationPolicy.modal.appliesTo',
            )}
            name="appliesTo"
            filterPropertyBy={filterPropertyBy}
            colSmInput={8}
            colSmLabel={4}
            filtersColXs={12}
          /> */}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" type="submit" disabled={isUpdating}>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

CancellationPolicyModal.defaultProps = {
  cancellationPolicyValue: undefined,
};

export default CancellationPolicyModal;
