import { PaginationState } from '@tanstack/react-table';
import { BaseResponse } from 'models/_base';

/**
 * Gets the total amount of items prioritizing the metadata fields that starts
 * with `_` and the largest number between `_count` and `_totalCount`.
 */
export const getTotalCount = (
  metadata: undefined | BaseResponse['_metadata'],
) => {
  if (!metadata) {
    return 0;
  }

  const metadataCount =
    // eslint-disable-next-line no-underscore-dangle
    'count' in metadata ? metadata.count : metadata._count;
  const metadataTotalCount =
    // eslint-disable-next-line no-underscore-dangle
    'totalCount' in metadata ? metadata.totalCount : metadata._totalCount;

  return Math.max(metadataTotalCount ?? 0, metadataCount ?? 0);
};

/**
 * Gets the pagination query params `_limit` and `_offset`, based on the
 * `pageIndex` and `pageSize`.
 */
export const getPaginationParams = ({
  pageIndex,
  pageSize,
}: PaginationState) => {
  return {
    _limit: pageSize,
    ...(pageIndex > 0 ? { _offset: pageSize * pageIndex } : {}),
  };
};
