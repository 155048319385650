import { useSelectModeContext } from '../../context/SelectModeContext';
import usePropertyPhotosData from '../../hooks/usePropertyPhotosData';
import SelectPhotosButton from '../selectMode/SelectPhotosButton';
import UploadPhotoButton from '../upload/UploadPhotoButton';
import { HeaderButtonsContainer } from './HeaderButtons.styles';

const HeaderButtons = () => {
  const { getPhotos } = usePropertyPhotosData();
  const { isSelectMode } = useSelectModeContext();
  const photos = getPhotos();

  const shouldShowSelectPhotosButton = photos.length > 0;
  const shouldShowUploadButton = !isSelectMode;

  return (
    <HeaderButtonsContainer>
      {shouldShowSelectPhotosButton && <SelectPhotosButton />}
      {shouldShowUploadButton && <UploadPhotoButton />}
    </HeaderButtonsContainer>
  );
};

export default HeaderButtons;
