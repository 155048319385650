import { Lead } from 'models/Leads';
import {
  QuoteOverrides,
  Quote,
  LeadQuoteOverride,
  FeeAmountType,
} from 'models/Quote';
import { HasFeeItem, HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { isLeadAMainBooking } from 'utils/lead/leadUtils';

export function prepareOrderItems(quote: Quote, lead: Lead): HasOrderItems {
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  if (!quote) return null;

  // If the lead is a main booking, SD info should be retrieve from the lead and not the quote
  const isMainBooking = isLeadAMainBooking(lead);

  let otherFees: HasFeeItem[] = quote.fees?.otherFees?.map((fee) => {
    return {
      feeId: fee.feeId,
      feeUid: fee.feeUid,
      isEditable: fee.isEditable,
      name: fee.name,
      amountType: fee.amountType,
      taxationRate: fee.taxationRate,
      netPrice: fee.netPrice,
      grossAmount: fee.grossPrice,
      type: fee.type,
      taxAmount: fee.taxAmount,
      removed: false,
      enabled: true,
      isOptional: false,
    } as HasFeeItem;
  });
  // ?.filter((fee) => !fee.removed && fee.enabled);

  quote.overrides?.fees?.otherFees?.forEach((overrideFee) => {
    const fee = otherFees?.find(
      (o) => o.name === overrideFee.name && o.feeUid === overrideFee.feeUid,
    );

    if (!fee) {
      otherFees.push({
        feeId: overrideFee?.feeId,
        feeUid: overrideFee?.feeUid,
        isEditable: false,
        name: overrideFee?.name,
        amountType: overrideFee?.amountType,
        taxationRate: overrideFee?.taxationRate,
        netPrice: overrideFee?.netPrice,
        grossAmount: overrideFee?.amount,
        removed: overrideFee?.removed ?? false,
        enabled: overrideFee?.enabled ?? true,
        isOptional: overrideFee?.isOptional ?? true,
      } as HasFeeItem);
    } else {
      otherFees = otherFees?.map((otherFee) => {
        if (
          otherFee.name === overrideFee.name &&
          otherFee.feeUid === overrideFee.feeUid
        ) {
          return {
            ...otherFee,
            removed: overrideFee?.removed ?? otherFee.removed,
            enabled: overrideFee?.enabled ?? otherFee.enabled,
            name: overrideFee?.name ?? otherFee.name,
            taxationRate: overrideFee?.taxationRate ?? otherFee.taxationRate,
            netPrice: overrideFee?.netPrice ?? otherFee.netPrice,
            grossAmount: overrideFee?.amount ?? otherFee.grossAmount,
          } as HasFeeItem;
        }

        return otherFee;
      });
    }
  });

  return {
    force100PercentPaymentAtReservation:
      quote?.overrides?.force100PercentPaymentAtReservation != null
        ? quote?.overrides?.force100PercentPaymentAtReservation
        : lead?.order?.force100PercentPaymentAtReservation,
    includeSecurityDepositInTotal: isMainBooking
      ? lead?.order?.includeSecurityDepositInTotal
      : quote.includeSecurityDepositInTotal,
    securityDeposit: isMainBooking
      ? lead?.order?.securityDeposit
      : quote.securityDeposit,
    currency: quote.currency,
    totalAmount: quote.totalAmount,
    rent: {
      ...quote.rent,
      extraGuestsBaseNetPrice:
        quote?.overrides?.rent?.extraGuestsBaseNetPrice ||
        quote.rent.extraGuestsBaseNetPrice,
      rentBaseNetPrice:
        quote?.overrides?.rent?.rentBaseNetPrice || quote.rent.rentBaseNetPrice,
      taxAmount: quote?.overrides?.rent?.taxAmount || quote.rent.taxAmount,
      taxationRate:
        quote?.overrides?.rent?.taxationRate || quote.rent.taxationRate,
    },
    services: quote.services,
    adjustmentItems: quote.adjustmentItems,
    propertyName: quote.propertyName,
    fees: {
      cleaningFee: {
        ...quote?.fees?.cleaningFee,
        amountType: FeeAmountType.AMOUNT,
        grossAmount: quote?.fees?.cleaningFee?.netPrice || 0,
      },
      otherFees: [...otherFees],
    },
  };
}

export function convertQuoteOverrideToLeadQuoteOverride(
  overrides: QuoteOverrides,
): LeadQuoteOverride {
  return {
    ...overrides,
    rent: {
      ...overrides.rent,
      rentBaseNetPrice: overrides?.rent?.rentBaseNetPrice,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      rentNetPrice: null,
      extraGuestsBaseNetPrice: overrides?.rent?.extraGuestsBaseNetPrice,
    },
    fees: {
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cleaningFee: overrides?.fees?.cleaningFee
        ? {
            netPrice: overrides?.fees?.cleaningFee?.netPrice,
            taxationRate: overrides?.fees?.cleaningFee?.taxationRate,
          }
        : null,
      otherFees:
        overrides?.fees?.otherFees?.map((fee) => ({
          ...fee,
        })) || [],
    },
  };
}
