import useAppUser from '../../../hooks/useAppUser';
import useScreenBreakpoints from '../../../hooks/useScreenBreakpoints';
import { InboxLeadDisplayMode } from '../lead/InboxLead.types';

const useInboxDisplayLead = (displayMode: InboxLeadDisplayMode): boolean => {
  const { isEmployee } = useAppUser();
  const { isXS, isSM, isMD } = useScreenBreakpoints();

  if (displayMode === InboxLeadDisplayMode.STANDALONE) {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return isEmployee && (isXS || isSM || isMD);
  }

  if (displayMode === InboxLeadDisplayMode.RIGHT_PANEL) {
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return isEmployee && !isXS && !isSM && !isMD;
  }

  throw new Error(`Not implemented lead display mode: ${displayMode}`);
};

export default useInboxDisplayLead;
