import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import {
  enableQuery,
  getTimezoneDateString,
  prepareAllPropertyUids,
} from 'utils/analytics/analyticsUtils';
import useAppUser from '../../hooks/useAppUser';
import AnalyticsContext from './AnalyticsContext';
import { AnalyticsFinancialPerformanceResponse } from './types/AnalyticsFinancialPerformance.types';
import { AnalyticsTeamResponse } from './types/AnalyticsTeam.types';
import {
  ChartType,
  FinancialPerformanceMetric,
  PropertyPerformanceMetric,
  TeamPerformanceMetric,
} from './charts/Charts.types';
import { AnalyticsPropertyResponse } from './types/AnalyticsProperty.types';

const useAnalyticsData = () => {
  const { filter, allProperties } = useContext(AnalyticsContext);
  const { metric, type, offset, limit } = useWatch();
  const { agencyUid, agency } = useAppUser();

  const {
    isFetching: isFinancialPerformanceLoading,
    error: financialPerformanceError,
    data: financialPerformanceData,
  } = useAppQuery(
    [
      'getFinancialPerformanceData',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.channel,
      filter?.range,
      metric,
      agencyUid,
    ],
    async () => {
      const { dateType, currency, propertyUid, channel, range, timeSpan } =
        filter;
      const response = await API.post<AnalyticsFinancialPerformanceResponse>(
        `api/internal/analytics/financial-performance`,
        {
          agencyUid,
          dateType,
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          from: getTimezoneDateString(range.startDate, agency?.timeZone),
          // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          to: getTimezoneDateString(range.endDate, agency?.timeZone),
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metric,
          timeSpan,
        },
      );
      return response.data.metricValues.filter(
        (metricValue) => metricValue.value > 0,
      );
    },
    {
      enabled:
        enableQuery({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          agencyUid,
          filter,
          allProperties,
        }) &&
        type === ChartType.FINANCIAL_PERFORMANCE &&
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        !!FinancialPerformanceMetric[metric],
      keepPreviousData: true,
    },
  );

  const {
    isFetching: isTeamLoading,
    error: teamError,
    data: teamData,
  } = useAppQuery(
    [
      'getTeamPerformanceData',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.range,
      filter?.channel,
      metric,
      agencyUid,
    ],
    async () => {
      const { dateType, currency, propertyUid, range, channel } = filter;
      const response = await API.post<AnalyticsTeamResponse>(
        `api/internal/analytics/team`,
        {
          agencyUid,
          dateType,
          from: range.startDate,
          to: range.endDate,
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metric,
        },
      );
      return response.data.metricValues.filter(
        (metricValue) => metricValue.value > 0,
      );
    },
    {
      enabled:
        enableQuery({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          agencyUid,
          filter,
          allProperties,
        }) &&
        type === ChartType.TEAM_PERFORMANCE &&
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        !!TeamPerformanceMetric[metric],
      keepPreviousData: true,
    },
  );

  const {
    isFetching: isPropertyLoading,
    error: propertyError,
    data: propertyData,
  } = useAppQuery(
    [
      'getPropertyPerformanceData',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.channel,
      filter?.range,
      metric,
      agencyUid,
      limit,
      offset,
    ],
    async () => {
      const { dateType, currency, propertyUid, channel, range } = filter;
      const response = await API.post<AnalyticsPropertyResponse>(
        `api/internal/analytics/property`,
        {
          agencyUid,
          dateType,
          from: range.startDate,
          to: range.endDate,
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metric,
          limit,
          offset,
        },
      );
      return response.data.metricValues
        .filter((metricValue) => metricValue.value > 0)
        .map((metricValue) => ({
          ...metricValue,
          // @ts-expect-error TS2532 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          propertyName: allProperties.find(
            (property) => property.uid === metricValue.propertyUid,
          ).name,
        }));
    },
    {
      enabled:
        enableQuery({
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          agencyUid,
          filter,
          allProperties,
        }) &&
        type === ChartType.PROPERTY_PERFORMANCE &&
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        !!PropertyPerformanceMetric[metric],
      keepPreviousData: true,
    },
  );

  return {
    isFinancialPerformanceLoading,
    financialPerformanceData,
    financialPerformanceError,
    isInitialFinancialPerformanceLoading:
      financialPerformanceData === undefined && !financialPerformanceError,
    isTeamLoading,
    teamData,
    teamError,
    isInitialTeamLoading: teamData === undefined && !teamError,
    isPropertyLoading,
    propertyData,
    propertyError,
    isInitialPropertyLoading: propertyData === undefined && !propertyError,
  };
};

export default useAnalyticsData;
