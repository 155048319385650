import { Button, Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAppModal from '../../../../../../../hooks/useAppModal';
import CheckboxField from '../../../../../../forms/checkboxField/CheckboxField';
import FormWithProvider from '../../../../../../forms/form/Form';
import { useReportGuestNoShow } from '../bookingDotComActionHooks';
import { ModalRow } from '../LeadModalActionsTab.styles';

export const GUEST_NO_SHOW_MODAL_ID = 'guest-no-show-modal';
// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
export const GuestNoShowModal = ({ leadUid }) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const { isLoading, guestNoShowMutation } = useReportGuestNoShow();
  const handleHide = () => {
    closeModal(GUEST_NO_SHOW_MODAL_ID);
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleSubmit = (data) => {
    guestNoShowMutation(
      {
        leadUid,
        waiveFees: data.waiveFees,
      },
      { onSettled: handleHide },
    );
  };

  return (
    <FormWithProvider
      defaultValues={{ waiveFees: false }}
      schema={{}}
      onSubmit={handleSubmit}
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">
          {t('componentLead.modal.actionsTab.guestNoShow.modalTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalRow>
          <Col sm={12}>
            {t('componentLead.modal.actionsTab.guestNoShow.modalDescription')}
          </Col>
        </ModalRow>
        <ModalRow>
          <Col sm={7}>
            <CheckboxField name="waiveFees">
              {t('componentLead.modal.actionsTab.guestNoShow.fieldWaiveFees')}
            </CheckboxField>
          </Col>
        </ModalRow>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide} disabled={isLoading}>
          {t('componentLead.modal.actionsTab.guestNoShow.buttonCancel')}
        </Button>
        <Button
          bsStyle="primary"
          type="submit"
          disabled={isLoading}
          data-testid="confirm-button"
        >
          {t('componentLead.modal.actionsTab.guestNoShow.buttonReport')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};
