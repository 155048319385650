import { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { queryClient } from 'App';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import TableContext from 'components/table/TableContext';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { PropertyInternalFee } from 'models/PropertyFees';
import BulkDeleteModal, {
  BulkDeleteFormSchemaType,
  bulkDeleteModalId,
} from 'pages/property/feesAndPolicies/BulkDeleteModal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { handlePaginationAfterDelete } from 'utils/pagination';
import useNotify from 'hooks/useNotify';
import { Property } from 'models/Properties';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import { PROPERTIES_FEES_BASE_QUERY_KEY } from '../PropertyFeesAndTaxes.utils';

const useDeleteModal = ({
  row,
  property,
}: {
  row: Row<PropertyInternalFee>;
  property: Property;
}) => {
  const { t } = useTranslation();
  const { tableInstance } = useContext(TableContext);
  const { uid: propertyUid } = useParams();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();

  const fee = row.original;
  const mutationKey = ['house-rule-delete', fee.uid];

  const { mutateAsync: deleteFee } = useAppMutation(
    mutationKey,
    async ({
      feeUid,
      allProperties,
      propertyUids,
    }: {
      feeUid: PropertyInternalFee['uid'];
      allProperties: boolean;
      propertyUids: Property['uid'][];
    }) => {
      await API.delete('/api/internal/fees', {
        data: {
          feeUid,
          allProperties,
          propertyUids,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          PROPERTIES_FEES_BASE_QUERY_KEY,
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);

        closeModal(bulkDeleteModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleDelete = async (values: BulkDeleteFormSchemaType) => {
    const { updateAllProperties, propertiesUids } =
      extractPropertiesMultiSelectFields('appliesTo', values);

    const objectUids = updateAllProperties ? [] : propertiesUids;

    await deleteFee({
      feeUid: fee.uid,
      allProperties: updateAllProperties,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUids: objectUids,
    });
  };

  const sharedProperties =
    fee?.metadata?.propertiesThatShareFee?.map(
      ({ left: uid, right: name }) => ({
        label: name,
        value: uid,
      }),
    ) ?? [];

  const defaultValues = {
    appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
    appliesToProperties: [
      {
        label: property?.name,
        value: property?.uid,
      },
      ...sharedProperties,
    ],
  };

  const openBulkDeleteModal = () => {
    openModal({
      id: bulkDeleteModalId,
      customContent: (
        <BulkDeleteModal
          title={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.deleteModal.title',
          )}
          description={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.deleteModal.description',
            {
              name: fee?.name,
            },
          )}
          onSubmit={handleDelete}
          defaultValues={defaultValues}
          mutationKey={mutationKey}
          propertyMultiSelectProps={{
            enableHotels: false,
            enableUnitTypes: true,
          }}
        />
      ),
    });
  };

  return {
    openBulkDeleteModal,
  };
};

export default useDeleteModal;
