import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';

// @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const useLeadListItemActions = (onMutationSuccess) => {
  const { notifyError, notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { openConfirmModal } = useAppModal();

  const { mutate: signLeadMutation, isLoading: isSignLeadLoading } =
    useAppMutation(
      (leadUid: string) => {
        return API.post(`/api/internal/leads/${leadUid}/assign-to-me`);
      },
      {
        onSuccess: () => {
          notifySuccess(t('pageDashboard.signedLead'));
          onMutationSuccess?.();
        },
        onError: () => notifyError(t('pageDashboard.somethingWentWrong')),
      },
    );

  const { mutate: ignoreLeadMutation, isLoading: isIgnoreLeadLoading } =
    useAppMutation(
      (leadUid: string) => {
        return API.post(`/api/v3/leads/${leadUid}/ignore`);
      },
      {
        onSuccess: () => {
          notifySuccess(t('pageDashboard.ignoreLead'));
          onMutationSuccess?.();
        },
        onError: () => notifyError(t('pageDashboard.somethingWentWrong')),
      },
    );

  const ignoreLead = (leadUid: string) => {
    openConfirmModal({
      body: t('pageDashboard.confirmIgnoreLeadMessage'),
      confirmColor: 'danger',
      confirmLabel: t('pageDashboard.confirmIgnoreLeadButton'),
      title: t('pageDashboard.confirmIgnoreLeadTitle'),
      onConfirm: () => {
        ignoreLeadMutation(leadUid);
      },
    });
  };

  return {
    signLead: signLeadMutation,
    ignoreLead,
    isActionsLoading: isSignLeadLoading || isIgnoreLeadLoading,
  };
};

export default useLeadListItemActions;
