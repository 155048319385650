import { useMemo } from 'react';
import useAppUser from 'hooks/useAppUser';
import { Container } from './Analytics.styles';
import AnalyticsContextProvider from './AnalyticsContextProvider';
import AnalyticsWrapper from './AnalyticsWrapper';
import {
  getAnalyticsFilterLocalStorageKey,
  getChartsFilterLocalStorageKey,
} from './types/Analytics.constants';

const Analytics = () => {
  const { userUid } = useAppUser();
  const analyticsWrapper = useMemo(() => <AnalyticsWrapper />, []);

  return (
    <AnalyticsContextProvider
      analyticsFilterLocalStorageKey={getAnalyticsFilterLocalStorageKey(
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        userUid,
      )}
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      chartsFilterLocalStorageKey={getChartsFilterLocalStorageKey(userUid)}
    >
      <Container>{analyticsWrapper}</Container>
    </AnalyticsContextProvider>
  );
};

export default Analytics;
