import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import useAppUser from '../../../hooks/useAppUser';
import {
  GetAllResolutionsResponseTO,
  ResolutionStatus,
} from '../../../models/AirbnbResolutions';

// @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const useFetchAirbnbResolutions = (status: ResolutionStatus = null) => {
  const { agencyUid } = useAppUser();

  const params =
    status && status !== ResolutionStatus.ALL
      ? { agencyUid, status }
      : { agencyUid };
  const { data: resolutions, isLoading: isFetching } = useAppQuery(
    [`airbnb-resolutions`, agencyUid, status],
    async () => {
      const response = await API.get<GetAllResolutionsResponseTO>(
        `/v3/airbnb/resolutions`,
        {
          params,
        },
      );
      return response.data.resolutions;
    },
    { enabled: !!agencyUid },
  );

  return { resolutions, isFetching };
};

export default useFetchAirbnbResolutions;
