import { BasePagingResponse, BaseResponse } from './_base';
import { FeeAmountType, FeeScopeType } from './Quote';

export enum FeeScopeEnum {
  PER_NIGHT = 'PER_NIGHT',
  PER_STAY = 'PER_STAY',
  PER_GUEST_PER_NIGHT = 'PER_GUEST_PER_NIGHT',
  PER_GUEST = 'PER_GUEST',
  PERCENTAGE_OF_RENT = 'PERCENTAGE_OF_RENT',
  PERCENTAGE_OF_TOTAL = 'PERCENTAGE_OF_TOTAL',
  PER_PET = 'PER_PET',
  PER_PET_PER_NIGHT = 'PER_PET_PER_NIGHT',
}

export enum FeeType {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  CLEANING,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  EARLY_ARRIVAL,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  LATE_ARRIVAL,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  PET,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  TAX,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  CUSTOM,
}

export enum FeeTypeEnum {
  CLEANING = 'CLEANING',
  EARLY_ARRIVAL = 'EARLY_ARRIVAL',
  LATE_ARRIVAL = 'LATE_ARRIVAL',
  PET = 'PET',
  TAX = 'TAX',
  CUSTOM = 'CUSTOM',
}

export enum FeeAmountTypeEnum {
  AMOUNT = 'AMOUNT',
  TAX = 'TAX',
}

export enum BookingDotComFeeType {
  RESORT_FEE = 'RESORT_FEE',
  SERVICE_CHARGE_FEE = 'SERVICE_CHARGE_FEE',
  DESTINATION_FEE = 'DESTINATION_FEE',
  ENVIRONMENT_FEE = 'ENVIRONMENT_FEE',
  MUNICIPALITY_FEE = 'MUNICIPALITY_FEE',
  TRANSIT_FEE = 'TRANSIT_FEE',
  HERITAGE_FEE = 'HERITAGE_FEE',
  TOWEL_FEE = 'TOWEL_FEE',
  ELECTRICITY_FEE = 'ELECTRICITY_FEE',
  LINEN_FEE = 'LINEN_FEE',
  GAS_FEE = 'GAS_FEE',
  OIL_FEE = 'OIL_FEE',
  WOOD_FEE = 'WOOD_FEE',
  WATER_FEE = 'WATER_FEE',
  TRANSFER_FEE = 'TRANSFER_FEE',
  LINEN_PACKAGE_FEE = 'LINEN_PACKAGE_FEE',
  HEATING_FEE = 'HEATING_FEE',
  AIR_CONDITIONING_FEE = 'AIR_CONDITIONING_FEE',
  KITCHEN_LINEN_FEE = 'KITCHEN_LINEN_FEE',
  HOUSEKEEPING_FEE = 'HOUSEKEEPING_FEE',
  AIRPORT_SHUTTLE_FEE = 'AIRPORT_SHUTTLE_FEE',
  SHUTTLE_BOAT_FEE = 'SHUTTLE_BOAT_FEE',
  SEA_PLANE_FEE = 'SEA_PLANE_FEE',
  SKI_PASS_FEE = 'SKI_PASS_FEE',
  FINAL_CLEANING_FEE = 'FINAL_CLEANING_FEE',
  WRISTBAND_FEE = 'WRISTBAND_FEE',
  VISA_SUPPORT_FEE = 'VISA_SUPPORT_FEE',
  WATER_PARK_FEE = 'WATER_PARK_FEE',
  CLUB_CARD_FEE = 'CLUB_CARD_FEE',
  CONSERVATION_FEE = 'CONSERVATION_FEE',
  CREDIT_CARD_FEE = 'CREDIT_CARD_FEE',
  GOODS_AND_SERVICES_TAX = 'GOODS_AND_SERVICES_TAX',
  GOVERNMENT_TAX = 'GOVERNMENT_TAX',
  SPA_TAX = 'SPA_TAX',
  HOT_SPRING_TAX = 'HOT_SPRING_TAX',
  RESIDENTIAL_TAX = 'RESIDENTIAL_TAX',
  SAUNA_FITNESS_FACILITIES_TAX = 'SAUNA_FITNESS_FACILITIES_TAX',
  LOCAL_COUNCIL_TAX = 'LOCAL_COUNCIL_TAX',
  PETS_FEE = 'PETS_FEE',
}

export enum AirbnbFeeType {
  PASS_THROUGH_RESORT_FEE = 'PASS_THROUGH_RESORT_FEE',
  PASS_THROUGH_MANAGEMENT_FEE = 'PASS_THROUGH_MANAGEMENT_FEE',
  PASS_THROUGH_COMMUNITY_FEE = 'PASS_THROUGH_COMMUNITY_FEE',
  PASS_THROUGH_LINEN_FEE = 'PASS_THROUGH_LINEN_FEE',
  PASS_THROUGH_ELECTRICITY_FEE = 'PASS_THROUGH_ELECTRICITY_FEE',
  PASS_THROUGH_WATER_FEE = 'PASS_THROUGH_WATER_FEE',
  PASS_THROUGH_HEATING_FEE = 'PASS_THROUGH_HEATING_FEE',
  PASS_THROUGH_AIR_CONDITIONING_FEE = 'PASS_THROUGH_AIR_CONDITIONING_FEE',
  PASS_THROUGH_UTILITY_FEE = 'PASS_THROUGH_UTILITY_FEE',
  PASS_THROUGH_PET_FEE = 'PASS_THROUGH_PET_FEE',
  PASS_THROUGH_CLEANING_FEE = 'PASS_THROUGH_CLEANING_FEE',
  PASS_THROUGH_SHORT_TERM_CLEANING_FEE = 'PASS_THROUGH_SHORT_TERM_CLEANING_FEE',
}

export enum AirbnbFeeUnitType {
  PER_KILOWATT_HOUR = 'PER_KILOWATT_HOUR',
  PER_LITER = 'PER_LITER',
  PER_CUBIC_METER = 'PER_CUBIC_METER',
}

export enum HVMIFeeType {
  ADMINISTRATIVE_FEE = 'ADMINISTRATIVE_FEE',
  BOOKING_FEE = 'BOOKING_FEE',
  CLEANING_FEE = 'CLEANING_FEE',
  DOCK_FEE = 'DOCK_FEE',
  GUEST_REGISTRATION = 'GUEST_REGISTRATION',
  HOA_FEE = 'HOA_FEE',
  HOT_TUB_FEE = 'HOT_TUB_FEE',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  PARKING_FEE = 'PARKING_FEE',
  POOL_FEE = 'POOL_FEE',
  POOL_HEATING_FEE = 'POOL_HEATING_FEE',
  RESORT_FEE = 'RESORT_FEE',
  SECURITY_DEPOSIT_FEE = 'SECURITY_DEPOSIT_FEE',
  SERVICE_FEE = 'SERVICE_FEE',
  UTILITY_FEE = 'UTILITY_FEE',
  WAIVER_DAMAGE_FEE = 'WAIVER_DAMAGE_FEE',
  MISCELLANEOUS_FEE = 'MISCELLANEOUS_FEE',
  PER_EXTRA_PERSON_FEE = 'PER_EXTRA_PERSON_FEE',
  HOTEL_TAX = 'HOTEL_TAX',
  OCCUPANCY_TAX = 'OCCUPANCY_TAX',
  VAT_TAX = 'VAT_TAX',
  PET_FEE = 'PET_FEE',
}

export enum VrboFeeType {
  ADDITIONAL_BED = 'ADDITIONAL_BED',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  ARRIVAL_EARLY = 'ARRIVAL_EARLY',
  ARRIVAL_LATE = 'ARRIVAL_LATE',
  ASSOCIATION_PROPERTY = 'ASSOCIATION_PROPERTY',
  BABY_BED = 'BABY_BED',
  BOOKING_EARLY = 'BOOKING_EARLY',
  BOOKING_LATE = 'BOOKING_LATE',
  CLASS = 'CLASS',
  CLUB = 'CLUB',
  CONCIERGE = 'CONCIERGE',
  DEPARTURE_EARLY = 'DEPARTURE_EARLY',
  DEPARTURE_LATE = 'DEPARTURE_LATE',
  ELECTRICITY = 'ELECTRICITY',
  EQUIPMENT = 'EQUIPMENT',
  FOOD = 'FOOD',
  GARDENING = 'GARDENING',
  GAS = 'GAS',
  HEATING = 'HEATING',
  HIGH_CHAIR = 'HIGH_CHAIR',
  HOT_TUB = 'HOT_TUB',
  INTERNET = 'INTERNET',
  LABOR = 'LABOR',
  LAUNDRY = 'LAUNDRY',
  LINENS = 'LINENS',
  LINENS_BATH = 'LINENS_BATH',
  LINENS_BED = 'LINENS_BED',
  MANAGEMENT = 'MANAGEMENT',
  OIL = 'OIL',
  ON_SITE_PAYMENT_METHOD = 'ON_SITE_PAYMENT_METHOD',
  PARKING = 'PARKING',
  PET = 'PET',
  PHONE = 'PHONE',
  POOL = 'POOL',
  POOL_HEATING = 'POOL_HEATING',
  RENT = 'RENT',
  RESORT = 'RESORT',
  SPA = 'SPA',
  TAX = 'TAX',
  TOILETRIES = 'TOILETRIES',
  TOUR = 'TOUR',
  TRANSPORTATION = 'TRANSPORTATION',
  UTENSILS_CLEANING = 'UTENSILS_CLEANING',
  UTENSILS_FOOD = 'UTENSILS_FOOD',
  VEHICLE = 'VEHICLE',
  WAIVER_DAMAGE = 'WAIVER_DAMAGE',
  WATER = 'WATER',
  WATER_CRAFT = 'WATER_CRAFT',
  WATER_CRAFT_MOORING = 'WATER_CRAFT_MOORING',
  WATER_DRINKING = 'WATER_DRINKING',
  WOOD = 'WOOD',
}

export enum HostfullyFeeType {
  DEFAULT = 'DEFAULT',
}

export enum FeeConditionEnum {
  NONE = 'NONE',
  EXEMPT_LONG_STAY = 'EXEMPT_LONG_STAY',
  EXCLUSIVE_LONG_STAY = 'EXCLUSIVE_LONG_STAY',
}

export interface PropertyFee {
  name: string;
  uid: string;
  propertyUid: string;
  type: FeeType;
  optional: boolean;
  amount: number;
  amountType: FeeAmountType;
  taxationRate: number;
  scope: FeeScopeType;
  longTermStayExemption: number;
  attestation: boolean;
}

export type PropertyInternalFee = Omit<
  PropertyFee,
  'amountType' | 'scope' | 'type'
> & {
  airbnbType: AirbnbFeeType;
  airbnbUnitType: AirbnbFeeUnitType;
  amountType: FeeAmountTypeEnum;
  bookingDotComType: BookingDotComFeeType;
  conditions?: FeeConditionEnum;
  conditionsLOSDays: number;
  hvmiType: HVMIFeeType;
  vrboType: VrboFeeType;
  hostfullyType: HostfullyFeeType;
  scope: FeeScopeEnum;
  type: FeeTypeEnum;
  lengthOfStay?: number;
  rollsIntoRent: boolean;
  metadata: {
    appliesToAllProperties: boolean;
    propertiesThatShareFee: { left: string; right: string }[];
  };
};

export interface GetPropertyFeesResponseTO extends BaseResponse {
  fees: PropertyFee[];
}

export interface GetPropertyFeesInternalResponse
  extends BaseResponse,
    BasePagingResponse {
  fees: PropertyInternalFee[];
}
