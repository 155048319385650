import styled from 'styled-components';
import Button from 'components/button/Button';
import { SidePanelContainerStyled } from 'components/sidePanel/SidePanel.styles';
import mediaQuery, { breakpoints } from 'styles/mediaQuery';

export const MenuButtonStyled = styled(Button)`
  border: none;
  flex-basis: 40px;
  font-size: 20px;
  height: 40px;
  margin-top: 3px;
  padding: 1px 5px;
`;

export const PropertySettingsTabHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 10px;

  .breadcrumb {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.XS}px) {
    justify-content: space-between;
  }

  ${mediaQuery.sm} {
    align-items: start;

    > form {
      flex-basis: calc(100% - 50px);
    }
  }

  ${SidePanelContainerStyled} {
    max-width: 60vw;

    ${mediaQuery.custom(575)} {
      margin-top: 60px;
    }
  }
`;
