import { Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Tooltip';
import TooltipContentWithLearnMore from 'pages/property/common/tooltip/TooltipContentWithLearnMore';
import { DescriptionFields } from '../PropertySettingsDescriptionsTab.types';
import useLabelTooltipText from './useLabelTooltipText';

interface LabelWithTooltipProps {
  fieldName: DescriptionFields;
}

const LEARN_MORE_URLS: { [K in DescriptionFields]: string } = {
  access:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_8e2dbe6e09',
  houseManual:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_368613bbd9',
  interaction:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_a57b94f00b',
  name: 'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_d1d76e7348',
  neighbourhood:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_f068f29a95',
  notes:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_07c18ffcc2',
  shortSummary:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_6f158f0c8f',
  space:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_505e546062',
  summary:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_4458e25642',
  transit:
    'https://help.hostfully.com/en/articles/8017235-update-your-property-s-description#h_3103cf8e9c',
};

const LabelWithTooltip = ({ fieldName }: LabelWithTooltipProps) => {
  const { t } = useTranslation();
  const labelTooltipText = useLabelTooltipText(fieldName);
  const learnMoreUrl = LEARN_MORE_URLS[fieldName];

  return (
    <>
      {t(`pageProperty.descriptions.form.${fieldName}`)}
      <Tooltip
        id={`${fieldName}-tooltip`}
        text={
          <TooltipContentWithLearnMore
            labelTooltipText={labelTooltipText}
            learnMoreUrl={learnMoreUrl}
          />
        }
      >
        <Glyphicon
          glyph="info-sign"
          id={`${fieldName}-tooltip-icon`}
          data-testid={`${fieldName}-tooltip-icon`}
        />
      </Tooltip>
    </>
  );
};

export default LabelWithTooltip;
