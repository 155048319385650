import styled from 'styled-components';
import theme from 'styles/theme';
import { SourceColor } from '../../../../models/Leads';

export const LeadCreationDetailsContainer = styled.div.attrs({
  'data-testid': 'lead-creation-details',
})`
  align-items: center;
  color: ${theme.colors.gray500};
  display: flex;
  white-space: pre;

  & > svg {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
`;

export const LeadChannelNameWrapper = styled.span<{ $channelColor: string }>`
  color: ${({ $channelColor }) => $channelColor};
`;

export const LeadReferrerWrapper = styled.span`
  color: ${SourceColor.DEFAULT};
  white-space: normal;
  word-break: break-all;
`;
