import { ModalBody, ModalFooter } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { AppEventType } from 'models/Events';
import { Lead } from 'models/Leads';
import {
  ManualTransactionCreationPayload,
  TransactionStatus,
  TransactionType,
} from 'models/Transactions';
import Button from '../../../button/Button';
import useCreateManualTransaction from '../../transaction/useCreateManualTransaction';

const MarkAsPaidModal = ({
  lead,
  modalId,
}: {
  lead: Lead;
  modalId: string;
}) => {
  const { closeModal } = useAppModal();
  const { publish } = useAppEvent();
  const { createManualTransaction, isLoading } = useCreateManualTransaction();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    order: { currency, totalAmount, uid: orderUid },
    uid,
  } = lead;

  const onCancel = () => closeModal(modalId);
  const onConfirm = async () => {
    const transaction: ManualTransactionCreationPayload = {
      fullPayment: true,
      orderUid,
      status: TransactionStatus.SUCCESS,
      type: TransactionType.SALE,
    };

    await createManualTransaction({
      successCallback: () => {
        publish(AppEventType.LEAD_UPDATED, { lead: { uid } });
        closeModal(modalId);
      },
      transaction,
    });
  };

  return (
    <>
      <ModalBody>
        <div data-testid="mark-lead-as-paid-message">
          {t('componentLead.actionsInfo.markAsPaidMessage', {
            amount: formatCurrency({ currency, value: totalAmount }),
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button bsStyle="danger" disabled={isLoading} onClick={onCancel}>
          {t('common.no')}
        </Button>
        <Button bsStyle="success" disabled={isLoading} onClick={onConfirm}>
          {t(
            isLoading
              ? 'common.processing'
              : 'componentLead.actionsInfo.markAsPaid',
          )}
        </Button>
      </ModalFooter>
    </>
  );
};

export default MarkAsPaidModal;
