import Button from 'components/button/Button';
import styled from 'styled-components';
import { imageHeight } from '../photoGrid/PhotoGrid.styles';

export const UploadPhotoButtonStyled = styled(Button)`
  input[type='file'] {
    display: none;
  }

  margin-left: 10px;

  span.glyphicon {
    margin-right: 5px;
    font-size: 12px;
  }
`;

export const AddPhotoTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${imageHeight};

  cursor: pointer;

  gap: 10px;

  border: 1px dashed #19172a;
  border-radius: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const UploadPhotoWrapperStyled = styled.div`
  input[type='file'] {
    display: none;
  }
`;
