import { useEffect, useState } from 'react';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import useAppModal from 'hooks/useAppModal';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import Button from '../../../button/Button';
import Loader from '../../../loader/Loader';
import useCheckPropertyAvailability from '../../property/useCheckPropertyAvailability';
import { queryClient } from '../../../../App';

const ReopenLeadModal = ({
  executeActionHandler,
  lead,
  modalId,
}: {
  executeActionHandler: () => void;
  lead: Lead;
  modalId: string;
}) => {
  const { closeModal, openConfirmModal } = useAppModal();
  const [isReFetched, setIsReFetched] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    property: { uid: propertyUid },
    uid,
  } = lead;

  const startDate = getLeadCheckInDate(lead);
  const endDate = getLeadCheckOutDate(lead);

  const { propertyAvailability, isFetching } = useCheckPropertyAvailability({
    enabled: true,
    startDate,
    endDate,
    leadUidsToExclude: [uid],
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyUid,
  });

  const isAvailable = propertyAvailability?.available;
  const onCancel = () => closeModal(modalId);

  useEffect(() => {
    const queryKey = [
      'property-availability-check',
      propertyUid,
      startDate,
      endDate,
      uid,
    ];

    const isNotCurrentlyFetching = !queryClient.isFetching(queryKey);

    if (isNotCurrentlyFetching && !isReFetched) {
      queryClient.invalidateQueries(queryKey).then(() => {
        setIsReFetched(true);
      });
    }
  }, []);

  useEffect(() => {
    if (!isFetching && isReFetched && isAvailable) {
      closeModal(modalId);

      openConfirmModal({
        body: t('componentLead.actionsInfo.confirmReopenLeadMessage'),
        confirmLabel: t(
          'componentLead.actionsInfo.confirmReopenLeadConfirmButtonLabel',
        ),
        onConfirm: executeActionHandler,
        onCancel,
        title: t('componentLead.actionsInfo.confirmReopenLeadTitle'),
      });
    }
  }, [isAvailable, isFetching, isReFetched]);

  if (isFetching || !isReFetched) {
    return (
      <ModalBody>
        <Loader />
      </ModalBody>
    );
  }

  return (
    <>
      <ModalBody>
        <div
          className="text-center"
          data-testid="reopening-not-allowed-message"
        >
          {t('componentLead.actionsInfo.reopeningNotAllowed')}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>{t('common.close')}</Button>
      </ModalFooter>
    </>
  );
};

export default ReopenLeadModal;
