import { useEffect, useState } from 'react';
import API from 'services/API';
import useAppQuery from './useAppQuery';
import useAppUser from './useAppUser';

const usePaypal = (propertyUid: string) => {
  const { isEmployee } = useAppUser();
  const [usePaypalIntegration, setUsePaypalIntegration] =
    useState<boolean>(false);

  const { data: propertyIntegrations } = useAppQuery(
    ['getPropertyIntegrations', propertyUid],
    async () => {
      const response = await API.get(
        `v3/integrations-settings?propertyUid=${propertyUid}`,
      );
      return response.data.integrationSettings;
    },
    { enabled: isEmployee && !!propertyUid },
  );

  useEffect(() => {
    setUsePaypalIntegration(false);

    if (!propertyIntegrations) return;

    if (
      propertyIntegrations.filter(
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        (propertyIntegration) =>
          propertyIntegration.affiliateEnum === 'paypal' &&
          propertyIntegration.enabled,
      ).length > 0
    ) {
      setUsePaypalIntegration(true);
    }
  }, [propertyIntegrations]);

  return usePaypalIntegration;
};

export default usePaypal;
