import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';

const useLeadCountdownDisplayValue = (lead: Lead): string | null => {
  const { t } = useTranslation();

  if (lead?.countdown) {
    const {
      countdown: { countdownMinutes, visualCountdownValue },
    } = lead;

    if (typeof countdownMinutes === 'number') {
      const minutes = Math.max(countdownMinutes, 0);

      if (minutes < 60) {
        return t('componentLead.countdown.lessThanHour');
      }

      const hours = Math.floor(minutes / 60);

      if (hours < 24) {
        return t('componentLead.countdown.hours', { hours });
      }

      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;

      if (remainingHours > 0) {
        return t('componentLead.countdown.daysAndHours', {
          days,
          hours: remainingHours,
        });
      }

      return t('componentLead.countdown.days', { days });
    }

    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    return visualCountdownValue;
  }

  return null;
};

export default useLeadCountdownDisplayValue;
