import styled from 'styled-components';

export const LeadModalDetailsOrderWrapper = styled.div`
  .price-details-list-total {
    .price-details-list-item-total-wrapper {
      padding: 10px 0.5rem;
    }

    .price-details-list-item-cancel-total-wrapper {
      padding: 16px 0.5rem;
    }

    .order-view-price-details-list-item-total-title {
      flex-direction: column;
    }

    .order-view-price-details-list-item-total-subtitle {
      margin-left: 0;
    }
  }

  .price-details-list-total-subOrder {
    .order-view-price-details-list-item-total-title {
      display: flex;
      align-items: center;
      flex-direction: row;

      button {
        margin-left: 15px;
      }
    }
  }
`;
