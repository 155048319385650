import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InboxMessagesContext from '../InboxMessagesContext';
import InboxContext from '../../InboxContext';
import { AvailableTemplate, TemplateType } from '../../../../models/Templates';
import { AvailableTemplates } from '../../Inbox.types';
import { InboxTemplateSelectorFilter } from '../InboxMessages.types';
import {
  InboxMessageTemplatesListTitle,
  InboxMessageTemplatesListWrapper,
  InboxNoMessageTemplatesInfo,
} from './InboxMessageTemplates.styles';
import { templatesDisplayOrder } from './InboxMessageTemplates.constants';
import InboxMessageTemplatesListItem from './InboxMessageTemplatesListItem';

function getFilteredTemplates(
  availableTemplates: AvailableTemplates | undefined,
  templateSelectorFilter: InboxTemplateSelectorFilter,
) {
  if (!availableTemplates) {
    return {};
  }

  const { templateName: filterTemplateName, templateType: filterTemplateType } =
    templateSelectorFilter;

  return Object.entries(availableTemplates)
    .map(([templateType, templates]) => [
      templateType,
      templates.filter(
        ({ localizedName }) =>
          !filterTemplateName ||
          localizedName
            ?.toLowerCase()
            .includes(filterTemplateName.toLowerCase()),
      ),
    ])
    .filter(
      ([templateType, templates]) =>
        templates.length &&
        (!filterTemplateType || templateType === filterTemplateType),
    )
    .reduce(
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (
        accumulator,
        [templateType, templates]: [
          templateType: TemplateType,
          templates: AvailableTemplate[],
        ],
      ) => ({
        ...accumulator,
        [templateType]: templates,
      }),
      {},
    );
}

function splitIntoTwoColumns(
  templates: AvailableTemplate[],
): { column: AvailableTemplate[]; columnId: string }[] {
  if (templates.length < 2) {
    return [{ column: templates, columnId: 'template-col-1' }];
  }

  const breakpointIndex =
    Math.floor(templates.length / 2) + (templates.length % 2);

  return [
    { column: templates.slice(0, breakpointIndex), columnId: 'template-col-1' },
    { column: templates.slice(breakpointIndex), columnId: 'template-col-2' },
  ];
}

const InboxMessageTemplatesList = () => {
  const { availableTemplates } = useContext(InboxContext);
  const { templateSelectorFilter } = useContext(InboxMessagesContext);
  const { t } = useTranslation();

  const filteredTemplates = getFilteredTemplates(
    availableTemplates,
    templateSelectorFilter,
  );

  if (!Object.keys(filteredTemplates).length) {
    return (
      <InboxNoMessageTemplatesInfo>
        {t('pageInbox.messages.templates.noTemplateFound')}
      </InboxNoMessageTemplatesInfo>
    );
  }

  return (
    <>
      {templatesDisplayOrder
        // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        .filter((templateType) => filteredTemplates[templateType])
        .map((templateType) => (
          <React.Fragment key={`group-${templateType}`}>
            <InboxMessageTemplatesListTitle key={`title-${templateType}`}>
              {t(`pageInbox.messages.templates.templateType_${templateType}`)}
            </InboxMessageTemplatesListTitle>
            <Row key={`list-${templateType}`}>
              <InboxMessageTemplatesListWrapper>
                {/*
                 // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                {splitIntoTwoColumns(filteredTemplates[templateType]).map(
                  ({ column, columnId }) => (
                    <Col xs={6} key={columnId}>
                      {column.map((template, index) => (
                        <InboxMessageTemplatesListItem
                          key={
                            template.uid || `template${index}-${template.name}`
                          }
                          template={template}
                        />
                      ))}
                    </Col>
                  ),
                )}
              </InboxMessageTemplatesListWrapper>
            </Row>
          </React.Fragment>
        ))}
    </>
  );
};

export default InboxMessageTemplatesList;
