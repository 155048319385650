import { useTranslation } from 'react-i18next';
import {
  OverallAirbnbRatingsContainer,
  RatingsAndCategoriesContainer,
} from './OverallAirbnbRatings.styles';
import useAirbnbRatingStats from './useAirbnbRatingStats';
import AverageRatingReviews from './AverageRatingReviews';
import CategoryScores from './CategoryScores';
import StarRatingsBreakdown from './StarRatingsBreakdown';

const OverallAirbnbRatings = () => {
  const { t } = useTranslation();
  const { airbnbRatingStats, isLoading } = useAirbnbRatingStats();

  if (isLoading || !airbnbRatingStats) {
    return null;
  }

  return (
    <OverallAirbnbRatingsContainer>
      <h3>{t('pageProperty.reviews.airbnbGuest.overallAirbnbRating.title')}</h3>
      <AverageRatingReviews
        averageRating={airbnbRatingStats.rating}
        reviewsAmount={airbnbRatingStats.count}
        reviewUidsWithIssues={airbnbRatingStats.reviewUidsWithIssues}
      />
      <RatingsAndCategoriesContainer>
        <StarRatingsBreakdown
          ratings={airbnbRatingStats.starRatings}
          totalCount={airbnbRatingStats.count}
        />

        <CategoryScores
          categoryStatistics={airbnbRatingStats.categoryStatistics}
        />
      </RatingsAndCategoriesContainer>
    </OverallAirbnbRatingsContainer>
  );
};

export default OverallAirbnbRatings;
