import { useContext } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

import useAppUser from 'hooks/useAppUser';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';
import {
  AlterationQuoteRequest,
  AlterationQuoteResponse,
} from './UpdateAirbnbAlterationModal.types';

const useAirbnbAlterationModalQuoteData = () => {
  const { agencyUid } = useAppUser();
  const { lead } = useContext(AirbnbAlterationRequestModalContext);

  const { data: quote, isFetching: isQuoteLoading } = useAppQuery(
    [`get-airbnb-alteration-quote-${lead?.uid}`],
    async () => {
      const response = await API.get<AlterationQuoteResponse>(
        'api/internal/airbnb/alterations/quote',
        {
          params: {
            leadUid: lead?.uid,
          } as AlterationQuoteRequest,
        },
      );
      return response.data;
    },
    {
      enabled: !!agencyUid && !!lead?.uid,
    },
  );

  return {
    quote,
    isQuoteLoading,
  };
};

export default useAirbnbAlterationModalQuoteData;
