import ReactDatePicker from 'react-datepicker';
import { useWatch } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControl } from 'react-bootstrap';
import { CalendarIcon } from '../dateTimePickerField/DateTimePickerField.styles';
import GenericFormField from '../genericFormField/GenericFormField';
import { RangePickerFieldWrapper } from './RangePickerField.styles';

interface Props {
  name: string;
  startDateFieldName: string;
  endDateFieldName: string;
  label?: string;
  help?: string;
  disabled?: boolean;
  'data-testid'?: string;
  onChange?: (dates: Date[]) => void;
  [key: string]: any;
}

const RangePickerField = ({
  name,
  startDateFieldName,
  endDateFieldName,
  help,
  label,
  'data-testid': dataTestId,
  onChange,
  disabled,
  ...rest
}: Props) => {
  const startDate = useWatch({
    name: startDateFieldName,
  });
  const endDate = useWatch({
    name: endDateFieldName,
  });

  const fieldRenderer = ({ field: { ref } }: any) => (
    <RangePickerFieldWrapper data-testid={dataTestId}>
      <ReactDatePicker
        customInput={<FormControl data-testid={dataTestId} inputRef={ref} />}
        selected={startDate}
        // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        onChange={onChange}
        startDate={startDate}
        name="rangePicker"
        endDate={endDate}
        selectsRange
        disabled={disabled}
        popperClassName="date-time-picker-popper"
      />
      <CalendarIcon />
    </RangePickerFieldWrapper>
  );

  return (
    <GenericFormField
      colSmInput={12}
      colSmLabel={0}
      name={name}
      fieldRenderer={fieldRenderer}
      help={help}
      label={label}
      disabled={disabled}
      {...rest}
    />
  );
};

RangePickerField.defaultProps = {
  'data-testid': undefined,
  label: '',
  help: '',
  disabled: false,
  onChange: () => {},
};

export default RangePickerField;
