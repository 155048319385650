import { useContext, useEffect, useRef } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';
import {
  setIsLoadingProperties,
  setNextPropertiesOffset,
  updateVisibleProperties,
} from 'store/slices/stackedCalendar';
import { PropertyBusinessType } from 'models/Properties';
import { getUnitType } from 'utils/lead/leadUtils';
import getPropertiesQuery from 'utils/property/getPropertiesQuery';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import useAppUser from '../../../hooks/useAppUser';
import CalendarContext from '../CalendarContext';
import {
  getCalendarFilterLocalStorageKey,
  setPropertiesBusinessType,
  stackedCalendarStyleConstants,
} from '../Calendar.constants';
import useFetchAllProperties from '../../../components/domain/property/useFetchAllProperties';
import { GetCalendarPropertiesResponse } from '../Calendar.types';

const otherUiItemsHeight = 85 + 83 + 100 + 30; // main nav + calendar page title + months container + margin bottom
function getInitialPropertiesLimit() {
  const availablePropertiesHeight = window.innerHeight - otherUiItemsHeight;
  const limit =
    Math.ceil(
      availablePropertiesHeight / stackedCalendarStyleConstants.days.height,
    ) + 2;
  return Math.max(limit, 25); // load at least 25 properties
}

const useCalendarProperties = () => {
  const previousPropertiesLength = useRef<number>(0);
  const { agencyUid, isOwner, userUid } = useAppUser();
  const storedFilter = localStorage.getItem(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getCalendarFilterLocalStorageKey(userUid),
  );
  const {
    filter,
    setTotalPropertiesNumber,
    updatePropertiesMap,
    setAllProperties,
    selectedProperty,
  } = useContext(CalendarContext);
  const dispatch = useAppDispatch();

  const { offset } = filter;
  const isNewSearch = offset === 0;

  const {
    allProperties: fetchedAllProperties,
    isFetching: isAllPropertiesLoading,
  } = useFetchAllProperties({
    enabled: isNewSearch,
    conditions: propertyCalendarConditionGraphQLQuery,
    fields: propertyCalendarGraphQLQuery,
  });

  const { data: fetchedPropertiesData, isLoading: isPropertiesLoading } =
    useAppQuery(
      ['properties-calendar', agencyUid, filter, selectedProperty],
      async () => {
        const limit = isNewSearch ? getInitialPropertiesLimit() : filter.limit;

        const propertyFilter = {
          ...filter,
          limit,
        };

        let queryStr = getPropertiesQuery({
          filters: {
            ...propertyFilter,
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            agencyUid: !isOwner && agencyUid,
          },
        });

        if (selectedProperty) {
          const propertyUidToFetch =
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            getUnitType(fetchedAllProperties, selectedProperty)?.uid ||
            selectedProperty;
          queryStr += `,propertyUids:"${propertyUidToFetch}"`;
          // always fetch the selectedProperty with isActive: true || false:
          queryStr = queryStr.replace(/isActive: (true|false),?/, '');
        }

        const response = await API.post<GetCalendarPropertiesResponse>(
          '/v3/graphql',
          {
            query: `{properties (${queryStr}){
              ${propertyCalendarGraphQLQuery}
            }}`,
            variables: {},
          },
        );

        previousPropertiesLength.current = response.data.data.properties.length;

        return { limit, properties: response.data.data.properties };
      },
      {
        enabled:
          !!agencyUid && !!filter && (!!fetchedAllProperties || !!storedFilter),
      }, // !!fetchedAllProperties prevents day cells from loading before properties list and Cypress tests from randomly failing due to unpredictable GraphQL queries order
    );

  useEffect(() => {
    if (fetchedAllProperties) {
      setAllProperties(fetchedAllProperties);

      const totalPropertiesNumber = fetchedAllProperties.reduce(
        (total, property) =>
          total +
          (property.businessType === PropertyBusinessType.HOTEL ? 0 : 1) +
          (property.subUnits?.length || 0) +
          (property.unitTypes?.reduce(
            (acc, unitType) => acc + (unitType.units?.length || 0),
            0,
          ) || 0),
        0,
      );

      setTotalPropertiesNumber(totalPropertiesNumber);
    }
  }, [fetchedAllProperties]);

  useEffect(() => {
    dispatch(
      setIsLoadingProperties(
        isAllPropertiesLoading || (isPropertiesLoading && isNewSearch),
      ),
    );
  }, [isAllPropertiesLoading, isPropertiesLoading, isNewSearch]);

  useEffect(() => {
    if (fetchedPropertiesData) {
      const { limit, properties } = fetchedPropertiesData;
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      dispatch(setNextPropertiesOffset(limit + offset));
      updatePropertiesMap({ properties });
      setPropertiesBusinessType(properties);
      dispatch(
        updateVisibleProperties({ properties, appendToExisting: !isNewSearch }),
      );
    }
  }, [fetchedPropertiesData]);

  return {
    previousPropertiesLength: previousPropertiesLength.current,
  };
};

export default useCalendarProperties;
