import { useState } from 'react';
import FilterIcon from 'components/filters/FilterIcon';
import FilterIconWithCounterFromForm from 'components/filters/FilterIconWithCounterFromForm';
import useAppUser from './useAppUser';

function getStoredState(storageKey: string) {
  const storedState = localStorage.getItem(storageKey);
  return storedState?.toLowerCase() === 'true';
}

function storeState(storageKey: string, isFilterOpen: boolean) {
  localStorage.setItem(storageKey, `${isFilterOpen}`);
}

const useFilterIcon = (options?: { stateStorageKey?: string }) => {
  const { userUid } = useAppUser();
  const storageKey = options?.stateStorageKey
    ? `${options.stateStorageKey}:${userUid}`
    : undefined;
  const initIsOpen = storageKey ? getStoredState(storageKey) : false;
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(initIsOpen);

  const onClick = () => {
    setIsFilterOpen((current) => {
      const newValue = !current;
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      storeState(storageKey, newValue);
      return newValue;
    });
  };

  return {
    isFilterOpen,
    filterIcon: (filterCounter?: number) => (
      <FilterIcon
        isFilterOpen={isFilterOpen}
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        filterCounter={filterCounter}
        onClick={onClick}
      />
    ),
    filterIconWithCounterFromForm: () => (
      <FilterIconWithCounterFromForm
        isFilterOpen={isFilterOpen}
        onClick={onClick}
      />
    ),
  };
};
export default useFilterIcon;
