import { Row } from '@tanstack/react-table';
import { ServiceProviderTableItem } from 'pages/property/serviceProviders/useServiceProvidersList';
import Switch from 'components/switch/Switch';
import { useFormContext, useWatch } from 'react-hook-form';
import { StatusCellContainer } from './StatusCell.styles';

const StatusCell = ({ row }: { row: Row<ServiceProviderTableItem> }) => {
  const { setValue } = useFormContext();
  const providers: ServiceProviderTableItem[] = useWatch({
    name: 'serviceProviders.providers',
  });

  const provider = providers.find((p) => p.uid === row.original.uid);

  const handleChange = (checked: boolean) => {
    const index = providers.findIndex((p) => p.uid === provider?.uid);

    setValue(`serviceProviders.providers.${index}.isActive`, checked, {
      shouldDirty: true,
    });
  };

  return (
    <StatusCellContainer>
      <Switch
        checked={provider?.isActive}
        onChange={(e) => handleChange(e.target.checked)}
        className="small"
        data-testid={`status-cell-${provider?.uid}`}
      />
    </StatusCellContainer>
  );
};

export default StatusCell;
