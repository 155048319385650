import styled from 'styled-components';
import theme from 'styles/theme';

export const PropertySettingsPricingTabStyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .divider {
    background-color: ${theme.colors.gray300};
    height: 1px;
    width: 100%;
  }
`;
