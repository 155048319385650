import { useTranslation } from 'react-i18next';
import useFetchBookingDotComCancellationPolicies from 'components/domain/bookingDotCom/useFetchBookingDotComCancellationPolicies';
import { formatOptionLabelWithHighlight } from 'components/forms/multiSelectField/formatOptionLabel';
import { CancellationPolicyCategories } from 'models/bookingDotCom/CancellationPolicies';
import FieldPlaceholder from 'pages/property/common/FieldPlaceholder';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { orderBy } from 'utils/arrayUtils';
import CustomSelectField from 'components/forms/customSelectField/CustomSelectField';

const CANCELLATION_POLICY_CATEGORY_ORDER = [
  CancellationPolicyCategories.POLICY_WITH_100_PERCENT_PENALTY,
  CancellationPolicyCategories.POLICY_WITH_50_PERCENT_PENALTY,
  CancellationPolicyCategories.POLICY_WITH_30_PERCENT_PENALTY,
  CancellationPolicyCategories.POLICY_WITH_PENALTY_IN_NIGHTS,
  CancellationPolicyCategories.POLICY_WITH_NO_PENALTY,
  CancellationPolicyCategories.UNDEFINED,
];

const CancellationPolicyField = () => {
  const { t } = useTranslation();

  const {
    data: cancellationPolicies,
    isLoading: isLoadingCancellationPolicies,
  } = useFetchBookingDotComCancellationPolicies();

  if (isLoadingCancellationPolicies) {
    return (
      <FieldPlaceholder
        label={
          <PropertyFormFieldLabel name="bookingDotComSettings.cancellationPolicyCode">
            {t(
              'pageProperty.mainSettings.bookingDotComSettings.cancellationPolicyCode',
            )}
          </PropertyFormFieldLabel>
        }
        name="bookingDotComSettings.cancellationPolicyCode"
        colSmInput={7}
        colSmLabel={5}
        required
      />
    );
  }

  const cancellationPoliciesGroupedByCategories = orderBy(
    cancellationPolicies ?? [],
    [
      {
        field: ({ category }) =>
          CANCELLATION_POLICY_CATEGORY_ORDER.indexOf(category),
        order: 'asc',
      },
      {
        field: 'name',
        order: 'asc',
      },
    ],
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  ).reduce((result, cancellationPolicy) => {
    const currentCategory = result[result.length - 1];

    // if iterating over a new category, then create a new group
    if (
      !currentCategory ||
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      currentCategory.category !== cancellationPolicy.category
    ) {
      return [
        ...result,
        {
          category: cancellationPolicy.category,
          label: t(
            `pageProperty.mainSettings.bookingDotComSettings.cancellationPolicyCategories.${cancellationPolicy.category}`,
          ),
          options: [
            { label: cancellationPolicy.name, value: cancellationPolicy.code },
          ],
        },
      ];
    }

    const { options } = currentCategory;

    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    options.push({
      label: cancellationPolicy.name,
      value: cancellationPolicy.code,
    });

    return result;
  }, []);

  return (
    <CustomSelectField
      label={
        <PropertyFormFieldLabel name="bookingDotComSettings.cancellationPolicyCode">
          {t(
            'pageProperty.mainSettings.bookingDotComSettings.cancellationPolicyCode',
          )}
        </PropertyFormFieldLabel>
      }
      name="bookingDotComSettings.cancellationPolicyCode"
      options={[
        { value: null, label: t('common.select') },
        // @ts-expect-error TS2461 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        ...cancellationPoliciesGroupedByCategories,
      ]}
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      formatOptionLabel={formatOptionLabelWithHighlight}
      containerClassName="cancellation-policy-field"
      colSmInput={7}
      colSmLabel={5}
    />
  );
};

export default CancellationPolicyField;
