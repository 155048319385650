import { useEffect } from 'react';
import { startOfDay } from 'date-fns';
import useAppDispatch from 'hooks/useAppDispatch';
import { setBodyRefreshRequest } from 'store/slices/stackedCalendar';
import { getDaysBetween } from 'utils/dateTimeUtils';
import useCalendarBodyRefreshRequest from '../../useCalendarBodyRefreshRequest';
import useCalendarMode from '../../useCalendarMode';

const highlightedTimeout = 2500;

const useHighlightUpdatedPricing = () => {
  const dispatch = useAppDispatch();
  const bodyRefreshRequest = useCalendarBodyRefreshRequest();
  const { isPricingMode } = useCalendarMode();

  useEffect(() => {
    if (
      isPricingMode &&
      bodyRefreshRequest?.shouldHighlightUpdatedCells &&
      bodyRefreshRequest?.fromDate &&
      bodyRefreshRequest?.toDate &&
      bodyRefreshRequest?.properties?.length
    ) {
      const { fromDate, toDate, properties } = bodyRefreshRequest;
      const days = getDaysBetween(startOfDay(fromDate), startOfDay(toDate));

      const selectors = properties
        .map((propertyUid) =>
          days
            .map(
              (dayDate) =>
                `[data-day-date="${dayDate.getTime()}"][data-property-uid="${propertyUid}"]`,
            )
            .join(','),
        )
        .join(',');

      document.querySelectorAll(selectors).forEach((cell) => {
        cell.classList.add('highlight');

        setTimeout(() => {
          cell.classList.remove('highlight');
        }, highlightedTimeout);
      });

      setTimeout(() => {
        // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        dispatch(setBodyRefreshRequest(null));
      });
    }
  }, [bodyRefreshRequest]);
};

export default useHighlightUpdatedPricing;
