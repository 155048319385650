import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { AvailableAmenity } from 'models/Amenity';
import { AvailableRule } from 'models/Rule';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { AmenityItemStyled } from './CategorizedAmenities.styles';
import AmenityDescriptionIcon from './description/AmenityDescriptionIcon';
import AmenityItemChild from './AmenityItemChild';

const AmenityItem = ({
  amenity,
  isTheLastInItsRow,
  columnPosition,
}: {
  amenity: AvailableAmenity | AvailableRule;
  isTheLastInItsRow: boolean;
  columnPosition: number;
}) => {
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();
  const children = 'children' in amenity ? amenity.children : undefined;
  const type = 'amenityType' in amenity ? amenity.amenityType : amenity.rule;
  const name = `amenities.${type}.value`;
  const { watch, setValue } = useFormContext();
  const amenityValue = watch(name);

  useEffect(() => {
    if (!amenityValue && amenity.supportsDescription) {
      setValue(`${type}.description`, null);
    }
  }, [amenityValue]);

  const amountOfColumns = isXS ? 2 : 3;

  const shouldBeOnNextColumn =
    isTheLastInItsRow &&
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    children?.length >= 1 &&
    columnPosition < amountOfColumns;
  const gridColumn = shouldBeOnNextColumn ? columnPosition + 1 : null;

  return (
    <>
      <AmenityItemStyled
        $gridColumn={gridColumn}
        data-testid={`amenity-type-${type}`}
      >
        <CheckboxField name={name} disabled={!!amenity.disabled}>
          {t(normalizeKey(`amenities.types.${type}`))}
        </CheckboxField>

        {amenity.supportsDescription && !!amenityValue && !amenity.disabled && (
          <AmenityDescriptionIcon amenity={amenity} />
        )}
      </AmenityItemStyled>
      {!('rule' in amenity) &&
        children?.map((child) => {
          return (
            <AmenityItemChild
              key={child.amenityType}
              child={child}
              gridColumn={gridColumn}
              parentAmenityType={amenity.amenityType}
            />
          );
        })}
    </>
  );
};
export default AmenityItem;
