import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { ObjectUidFetchType } from 'models/_base';
import { PropertyExpectation } from 'models/PropertyExpectations';
import API from 'services/API';

interface DeletePropertyExpectationArgs {
  uid: string;
  objectUidFetchType: ObjectUidFetchType;
  objectUids: PropertyExpectation['uid'][];
}

type DeletePropertyExpectationMutationOptions = Omit<
  UseMutationOptions<void, unknown, DeletePropertyExpectationArgs>,
  'mutationFn'
>;

const deletePropertyExpectation = async ({
  uid,
  objectUidFetchType,
  objectUids,
}: DeletePropertyExpectationArgs): Promise<void> => {
  return API.delete(`/api/internal/property-expectations/${uid}`, {
    data: {
      objectUidFetchType,
      objectUids,
    },
  });
};

const useDeletePropertyExpectation = ({
  ...options
}: DeletePropertyExpectationMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: deletePropertyExpectation,
    ...options,
  });
};

export default useDeletePropertyExpectation;
