import { Outlet } from 'react-router-dom';
import Modals from '../components/modal/Modals';
import GoogleAnalyticsHandler from './GoogleAnalyticsHandler';
import PageTitleHandler from './PageTitleHandler';
import WindowMessagesHandler from './WindowMessagesHandler';

const RoutesWrapper = () => {
  return (
    <>
      <PageTitleHandler />
      <WindowMessagesHandler />
      <GoogleAnalyticsHandler />
      <Outlet />
      <Modals />
    </>
  );
};

export default RoutesWrapper;
