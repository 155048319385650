import { useTranslation, Trans } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { CleaningOffer } from '../hooks/useTurnoCleaners';
import { Cleaner, ProfilePicture, ProfileInfo } from './TopRatedCleaner.styles';

const MyCleaner = ({
  cleaningOffer,
  value,
  currency,
  isLoading,
}: {
  cleaningOffer?: CleaningOffer['cleaner'];
  value?: number;
  currency?: string;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const { rating, first_name, image_url } = cleaningOffer;

  if (isLoading) {
    return (
      <Cleaner>
        <div>
          <LoadingPlaceholder circle width="100px" />
        </div>
        <ProfileInfo>
          <LoadingPlaceholder linesCount={1.2} />
          <LoadingPlaceholder linesCount={3} />
        </ProfileInfo>
      </Cleaner>
    );
  }

  return (
    <Cleaner key={first_name}>
      <div>
        <ProfilePicture src={image_url} alt="profile picture" />
      </div>
      <ProfileInfo>
        <h5>{first_name}</h5>

        <div>
          <Rating initialValue={parseInt(rating, 10)} size={18} />{' '}
          <span>{parseInt(rating, 10).toFixed(1)}</span>
        </div>

        {/* <span className="total_reviews">
          {t('pageTurnoManagement.reviews', {
            count: number_of_reviews,
          })}
        </span> */}

        <p>
          <Trans
            i18nKey="pageTurnoManagement.perProject"
            values={{
              amount: `${currency}${value}`,
            }}
          >
            <span>{`${currency}${value}`}</span>
            {t('pageTurnoManagement.perProjectLabel')}
          </Trans>
        </p>
      </ProfileInfo>
    </Cleaner>
  );
};

MyCleaner.defaultProps = {
  cleaningOffer: {
    first_name: '',
    image_url: '',
    rating: '0',
  },
  value: 0,
  currency: '$',
};
export default MyCleaner;
