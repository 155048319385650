import { ReactNode, useState } from 'react';
import { Tooltip as TooltipBootstrap, Overlay } from 'react-bootstrap';

interface TooltipProps {
  children: ReactNode;
  id: string;
  text: string | ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  zIndex?: number;
}

const Tooltip = ({ children, id, text, placement, zIndex }: TooltipProps) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const tooltip = (
    <TooltipBootstrap
      id={id}
      placement={placement}
      style={zIndex ? { zIndex } : {}}
    >
      {text}
    </TooltipBootstrap>
  );

  const handleMouseEnter = (event: any) => {
    setShow(true);
    setTarget(event.target);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <span
      data-testid="tooltip-container"
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {/*
       // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <Overlay target={target} show={show} placement={placement}>
        {tooltip}
      </Overlay>
    </span>
  );
};

Tooltip.defaultProps = {
  placement: 'top',
  zIndex: undefined,
};

export default Tooltip;
