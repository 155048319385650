import { useMemo, useState } from 'react';
import { ResolutionStatus } from '../../../../models/AirbnbResolutions';
import ResolutionsContext from './ResolutionsContext';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const ResolutionsContextProvider = ({ children }) => {
  const [status, setStatus] = useState<ResolutionStatus>(ResolutionStatus.ALL);

  const contextValue = useMemo(
    () => ({
      status,
      setStatus,
    }),
    [status],
  );

  return (
    <ResolutionsContext.Provider value={contextValue}>
      {children}
    </ResolutionsContext.Provider>
  );
};

export default ResolutionsContextProvider;
