import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';
import { ModalData } from '../../../components/modal/Modal.types';

interface ModalState {
  activeModals: {
    [modalId: string]: ModalData;
  };
}

const initialState: ModalState = {
  activeModals: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setActiveModal: (
      state,
      {
        payload: { modalId, modalData },
      }: PayloadAction<{ modalId: string; modalData: ModalData }>,
    ) => {
      state.activeModals[modalId] = modalData;
    },
    removeActiveModal: (
      state,
      { payload: { modalId } }: PayloadAction<{ modalId: string }>,
    ) => {
      delete state.activeModals[modalId];
    },
  },
});

export const { setActiveModal, removeActiveModal } = modalSlice.actions;

export default modalSlice.reducer;
