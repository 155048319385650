import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Tooltip';
import usePhotoMutationStatus from '../../hooks/usePhotoMutationStatus';
import usePropertyPhotosData from '../../hooks/usePropertyPhotosData';
import { UploadPhotoWrapperStyled } from './UploadPhoto.styles';
import {
  ACCEPT_FILE_TYPES_STRING,
  MAX_PHOTOS_LIMIT,
} from './UploadPhoto.utils';
import useUploadPhotos from './useUploadPhotos';

const UploadPhotoWrapper = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleImagesSelect } = useUploadPhotos();
  const { isUpdatingPhotos } = usePhotoMutationStatus();
  const { getPhotos } = usePropertyPhotosData();

  const maxPhotosReached = getPhotos().length >= MAX_PHOTOS_LIMIT;

  const isDisabled = isUpdatingPhotos || maxPhotosReached;

  const wrapper = (
    <fieldset disabled={isDisabled}>
      <UploadPhotoWrapperStyled
        onClick={() => fileInputRef.current?.click()}
        data-testid={`upload-photo-wrapper-${id}`}
      >
        <input
          id={id}
          type="file"
          accept={ACCEPT_FILE_TYPES_STRING}
          multiple
          aria-hidden="true"
          ref={fileInputRef}
          onChange={handleImagesSelect}
        />
        {children}
      </UploadPhotoWrapperStyled>
    </fieldset>
  );

  if (maxPhotosReached) {
    return (
      <Tooltip
        id="max-photos-reached-tooltip"
        text={t('pageProperty.photos.maxPhotosReachedTooltip')}
      >
        {wrapper}
      </Tooltip>
    );
  }

  return wrapper;
};

export default UploadPhotoWrapper;
