import { useTranslation } from 'react-i18next';
import { LanguageCode } from './internationalization.types';
import { getLanguageCode } from './i18n.utils';

const useCurrentLocale = () => {
  const {
    i18n: { language },
  } = useTranslation();

  if (
    language &&
    Object.values(LanguageCode).includes(language as LanguageCode)
  ) {
    return language;
  }

  // Gets first part of the locale, i.e 'en_US' becomes just 'en'
  const languageCode = getLanguageCode(language);

  if (languageCode && Object.values(LanguageCode).includes(languageCode)) {
    return languageCode;
  }

  return LanguageCode.EN;
};

export default useCurrentLocale;
