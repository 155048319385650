import { useSearchParams } from 'react-router-dom';

const messages = {
  '2': (
    <div className="alert alert-success">
      <div className="error_box">
        <strong>Hooray!</strong>&nbsp;&nbsp;&nbsp;
        <span data-testid="status-message">
          Your password has been reset successfully. Please{' '}
          <strong>check your email</strong> to get your temporary password and
          log in below.
        </span>
      </div>
    </div>
  ),

  auth: (
    <div className="alert alert-danger">
      <div className="error_box">
        <strong>Oops!</strong>&nbsp;&nbsp;&nbsp;
        <span data-testid="status-message">Your credentials are wrong.</span>
      </div>
    </div>
  ),

  dsb: (
    <div className="alert alert-danger">
      <div className="error_box">
        <strong>Oops!</strong>&nbsp;&nbsp;&nbsp;
        <span data-testid="status-message">
          This account is currently disabled.
        </span>
      </div>
    </div>
  ),

  cancelled: (
    <div className="alert alert-danger">
      <div className="error_box">
        <strong>Oops!</strong>&nbsp;&nbsp;&nbsp;
        <span data-testid="status-message">
          The agency&apos;s account has been cancelled
        </span>
      </div>
    </div>
  ),

  lstatdp: (
    <div className="alert alert-warning">
      <div className="error_box">
        <strong>Oops!</strong>&nbsp;&nbsp;&nbsp;
        <span data-testid="status-message">This is your last attempt.</span>
      </div>
    </div>
  ),
};

const AlertMessage = () => {
  const [searchParams] = useSearchParams();

  if (!searchParams.get('msg')) {
    return null;
  }

  // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return messages[searchParams.get('msg')] || null;
};

export default AlertMessage;
