import * as Yup from 'yup';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import TextAreaField from 'components/forms/textAreaField/TextAreaField';
import useAppModal from 'hooks/useAppModal';
import { Modal } from 'react-bootstrap';
import { AvailableAmenity } from 'models/Amenity';
import { AvailableRule } from 'models/Rule';
import { Trans, useTranslation } from 'react-i18next';
import {
  AmenityDescriptionModalFooter,
  AmenityTypeName,
} from './AmenityDescription.styles';

const descriptionSchema = () => ({
  description: Yup.string().max(1000),
});

export const amenityDescriptionModalId = 'amenity-description-modal';

const AmenityDescriptionModal = ({
  amenity,
  defaultDescription,
  updateDescription,
}: {
  amenity: AvailableAmenity | AvailableRule;
  defaultDescription: string;
  updateDescription: (values: { description: string }) => void;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const type = 'amenityType' in amenity ? amenity.amenityType : amenity.rule;

  const handleClose = () => {
    closeModal(amenityDescriptionModalId);
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const handleSubmit = (values) => {
    updateDescription(values);
  };

  const defaultValues = {
    description: defaultDescription,
  };

  return (
    <FormWithProvider
      horizontal
      schema={descriptionSchema()}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans
            i18nKey="pageProperty.amenities.amenityWithType"
            values={{
              amenityType: t(`amenities.types.${type}`),
            }}
            components={{ 1: <AmenityTypeName /> }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextAreaField
          name="description"
          label={t('pageProperty.amenities.description')}
          maxCharacters={1000}
          maxLength={1000}
          colSmInput={8}
          colSmLabel={4}
        />
      </Modal.Body>
      <Modal.Footer>
        <AmenityDescriptionModalFooter>
          <Button bsStyle="default" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button bsStyle="primary" type="submit">
            {t('common.apply')}
          </Button>
        </AmenityDescriptionModalFooter>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default AmenityDescriptionModal;
