import Button from 'components/button/Button';
import { Modal } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ViewAirbnbGuestReviewModalFooter = ({
  handleClose,
  isLoading,
  isRespondActionAllowed,
}: {
  handleClose: () => void;
  isLoading: boolean;
  isRespondActionAllowed: boolean;
}) => {
  const { t } = useTranslation();
  const { isDirty } = useFormState();
  return (
    <Modal.Footer>
      {isRespondActionAllowed && (
        <Button onClick={handleClose}>{t('common.cancel')}</Button>
      )}
      <Button
        bsStyle="primary"
        onClick={isRespondActionAllowed ? undefined : handleClose}
        type={isRespondActionAllowed ? 'submit' : 'button'}
        disabled={(!isDirty && isRespondActionAllowed) || isLoading}
      >
        {isRespondActionAllowed
          ? t(
              'pageProperty.reviews.airbnbGuest.guestReviews.modal.submitResponse',
            )
          : t('common.close')}
      </Button>
    </Modal.Footer>
  );
};

export default ViewAirbnbGuestReviewModalFooter;
