import { useTranslation } from 'react-i18next';
import { Col, Glyphicon } from 'react-bootstrap';
import Tooltip from 'components/tooltip/Tooltip';
import {
  OrderViewTableHeader,
  OrderViewTitleWrapper,
} from '../OrderView.styles';
import {
  OrderViewPriceDetailsListWrapper,
  PriceDetailsListTotal,
} from '../priceDetails/OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItemTotal from '../priceDetails/OrderViewPriceDetailsListItemTotal';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import ChannelCommission from './items/ChannelCommission';

const OrderViewChannelCommission = () => {
  const { t } = useTranslation();
  const { lead } = useOrderViewFetchedData();

  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    order: { channelCommissionMetadata, totalAmount, currency },
  } = lead;

  const commissionAmount = channelCommissionMetadata?.commissionAmount;

  if (!commissionAmount) {
    return null;
  }

  return (
    <div>
      <OrderViewTableHeader>
        <Col xs={12}>
          <OrderViewTitleWrapper>
            <h4 data-testid="channel-commission-title">
              {t('pageOrder.channelCommission')}
            </h4>
            <Tooltip
              id="channel-commission-title-tooltip"
              text={t('pageOrder.channelCommissionTooltip')}
            >
              <Glyphicon glyph="info-sign" />
            </Tooltip>
          </OrderViewTitleWrapper>
        </Col>
      </OrderViewTableHeader>

      <OrderViewPriceDetailsListWrapper data-testid="channel-commission-wrapper">
        <ChannelCommission />
        <PriceDetailsListTotal data-testid="channel-commission-total">
          <OrderViewPriceDetailsListItemTotal
            data-testid="channel-commission-item-total-netAmount"
            label={t('pageOrder.netAmount')}
            amount={totalAmount - commissionAmount}
            currency={currency}
          />
        </PriceDetailsListTotal>
      </OrderViewPriceDetailsListWrapper>
    </div>
  );
};

export default OrderViewChannelCommission;
