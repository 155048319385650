import { useTranslation } from 'react-i18next';
import { Lead, LeadSource, LeadStatus } from 'models/Leads';
import { TFunction } from 'i18next';
import { shouldHandleSecurityDeposit } from '../../../../utils/lead/leadUtils';
import useLeadCountdownDisplayValue from '../countdown/useLeadCountdownDisplayValue';

const baseLocaleKey = 'componentLead.actionsInfo.popoverTitle.';

function getTitle({
  countdownDisplayValue,
  lead,
  t,
}: {
  countdownDisplayValue: string | null;
  lead: Lead;
  t: TFunction;
}) {
  const { countdown, paidInFull, source, status, wasSecurityDepositReceived } =
    lead;

  if (status === LeadStatus.NEW) {
    if (source === LeadSource.DIRECT_AIRBNB) {
      return t(`${baseLocaleKey}review`);
    }

    if (source === LeadSource.DIRECT_HOMEAWAY) {
      return t(`${baseLocaleKey}needFollowUp`);
    }

    return t(`${baseLocaleKey}sendQuote`);
  }

  if (status === LeadStatus.QUOTE_SENT) {
    if (source === LeadSource.DIRECT_AIRBNB) {
      return t(`${baseLocaleKey}preApproved`);
    }

    if (source === LeadSource.DIRECT_HOMEAWAY) {
      return t(`${baseLocaleKey}needFollowUp`);
    }

    if (
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      countdown?.countdownPercentage > 0 &&
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      countdown?.countdownPercentage < 1
    ) {
      return t(`${baseLocaleKey}percentBeforeFollowingUp`, {
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        percent: Math.round(countdown.countdownPercentage * 100),
      });
    }

    return t(`${baseLocaleKey}needFollowUp`);
  }

  if (status === LeadStatus.ON_HOLD && countdownDisplayValue) {
    return t(`${baseLocaleKey}leftBeforeHoldExpires`, {
      value: countdownDisplayValue,
    });
  }

  if (status === LeadStatus.PENDING) {
    return t(`${baseLocaleKey}pending`);
  }

  if (status === LeadStatus.HOLD_EXPIRED) {
    return t(`${baseLocaleKey}holdExpired`);
  }

  if (
    status === LeadStatus.BOOKED_BY_AGENT ||
    status === LeadStatus.BOOKED_BY_CUSTOMER ||
    status === LeadStatus.BOOKED_BY_OWNER ||
    status === LeadStatus.BOOKED_EXTERNALLY ||
    status === LeadStatus.PAID_IN_FULL
  ) {
    if (paidInFull || status === LeadStatus.PAID_IN_FULL) {
      if (
        source === LeadSource.DIRECT_AIRBNB ||
        source === LeadSource.DIRECT_BOOKINGDOTCOM ||
        source === LeadSource.DIRECT_HOMEAWAY ||
        wasSecurityDepositReceived ||
        !shouldHandleSecurityDeposit(lead)
      ) {
        return t(`${baseLocaleKey}readyToGo`);
      }

      return t(`${baseLocaleKey}securityDepositNotReceived`);
    }

    if (
      source === LeadSource.DIRECT_AIRBNB ||
      source === LeadSource.DIRECT_BOOKINGDOTCOM
    ) {
      return t(`${baseLocaleKey}balanceNotPaid`);
    }

    if (countdown?.overdue) {
      return t(`${baseLocaleKey}balancePastDue`);
    }

    if (!wasSecurityDepositReceived && shouldHandleSecurityDeposit(lead)) {
      return t(`${baseLocaleKey}securityDepositNotReceived`);
    }

    return t(`${baseLocaleKey}balanceNotPaid`);
  }

  if (status === LeadStatus.STAY) {
    if (countdownDisplayValue) {
      return t(`${baseLocaleKey}checkoutIn`, {
        value: countdownDisplayValue,
      });
    }

    return t(`${baseLocaleKey}stay`);
  }

  if (status === LeadStatus.ARCHIVED) {
    if (!paidInFull) {
      return t(`${baseLocaleKey}balanceNotPaid`);
    }

    if (!wasSecurityDepositReceived && shouldHandleSecurityDeposit(lead)) {
      return t(`${baseLocaleKey}securityDepositNotReceived`);
    }

    return t(`${baseLocaleKey}guestCheckedOut`);
  }

  if (
    status === LeadStatus.CANCELLED ||
    status === LeadStatus.CANCELLED_BY_OWNER ||
    status === LeadStatus.CANCELLED_BY_TRAVELER
  ) {
    return t(`${baseLocaleKey}cancelled`);
  }

  if (status === LeadStatus.DECLINED) {
    return t(`${baseLocaleKey}declined`);
  }

  if (status === LeadStatus.IGNORED) {
    return t(`${baseLocaleKey}ignored`);
  }

  if (status === LeadStatus.CLOSED_HOLD || status === LeadStatus.CLOSED_QUOTE) {
    return t(`${baseLocaleKey}closed`);
  }

  return null;
}

const LeadActionsPopoverTitle = ({ lead }: { lead: Lead }) => {
  const countdownDisplayValue = useLeadCountdownDisplayValue(lead);
  const { t } = useTranslation();

  return (
    <div className="text-center" data-testid="lead-actions-popover-title">
      {getTitle({ countdownDisplayValue, lead, t })}
    </div>
  );
};

export default LeadActionsPopoverTitle;
