import { useContext, useEffect, useMemo, useState } from 'react';
import { Message } from '../../../models/inbox/Messages';
import { Template } from '../../../models/Templates';
import InboxContext from '../InboxContext';
import InboxMessagesContext from './InboxMessagesContext';
import useInboxMessages from './useInboxMessages';
import {
  InboxMessagesMetadata,
  InboxTemplateSelectorFilter,
} from './InboxMessages.types';
import { GetChatSuggestionResponseTO } from './editor/InboxMessageSuggestion.types';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const InboxMessagesContextProvider = ({ children }) => {
  const [isTemplateSelectorActive, setIsTemplateSelectorActive] =
    useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messagesMetadata, setMessagesMetadata] =
    useState<InboxMessagesMetadata>({});
  const [templateSelectorFilter, setTemplateSelectorFilter] =
    useState<InboxTemplateSelectorFilter>({});
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [suggestion, setSuggestion] = useState<GetChatSuggestionResponseTO>();
  const { fetchedMessages, hasMore, isLoading, loadMore } = useInboxMessages();
  const { activeMessageThreadUid } = useContext(InboxContext);

  useEffect(() => {
    setIsTemplateSelectorActive(false);
    setMessages([]);
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setSelectedTemplate(null);
    setTemplateSelectorFilter({});
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setSuggestion(null);
  }, [activeMessageThreadUid]);

  useEffect(() => {
    if (fetchedMessages) {
      setMessages((currentMessages) => [
        ...currentMessages,
        ...fetchedMessages,
      ]);
    }
  }, [fetchedMessages]);

  const addNewMessages = (newMessages: Message[]) => {
    setMessages((currentMessages) => [...newMessages, ...currentMessages]);
  };

  const onMessageSent = (message: Message) => {
    addNewMessages([message]);
    setIsTemplateSelectorActive(false);

    // TODO implement along with leads panel
    // updateLastUpdateDateOnThread(activeThread.uid);
    //
    //         if (_leadCurrentAction === LeadActionsTypeEnum.EVENT_SEND_CHECKIN_INSTRUCTIONS_KEY) {
    //             await leadCommunicationMetadataAPI.sendLeadCommunicationMetadata(activeLead.uid, LeadCommunicationMetadataEnum.CHECK_IN_INSTRUCTIONS_SENT);
    //             await updateActiveLead();
    //         }
    //
    //         if (_leadCurrentAction === LeadActionsTypeEnum.EVENT_SEND_A_QUOTE_KEY) {
    //             await LeadAPI.editLeadStatus(activeLead.uid, 'QUOTE_SENT', LeadTypeEnum.INQUIRY);
    //             await updateActiveLead();
    //         }
    //
    //         if (_leadCurrentAction === LeadActionsTypeEnum.EVENT_PLACE_ON_HOLD_KEY) {
    //             await LeadAPI.editLeadStatus(activeLead.uid, 'ON_HOLD', LeadTypeEnum.INQUIRY);
    //             await updateActiveLead();
    //         }
  };

  const updateMessage = (updatedMessage: Message) => {
    setMessages((currentMessages) =>
      currentMessages.map((message) =>
        message.uid === updatedMessage.uid ? updatedMessage : message,
      ),
    );
  };

  const updateMessagesMetadata = (newMetadata: InboxMessagesMetadata) => {
    setMessagesMetadata((currentMetadata) => ({
      ...currentMetadata,
      ...newMetadata,
    }));
  };

  const toggleTemplateSelector = () => {
    setIsTemplateSelectorActive((currentValue) => !currentValue);
    setTemplateSelectorFilter({});
  };

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const updateTemplateSelectorFilter = (newFilter) => {
    setTemplateSelectorFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
    }));
  };

  const contextValue = useMemo(
    () => ({
      hasMoreMessages: hasMore,
      isLoading,
      isTemplateSelectorActive,
      messages,
      messagesMetadata,
      selectedTemplate,
      templateSelectorFilter,
      suggestion,
      addNewMessages,
      loadMoreMessages: loadMore,
      onMessageSent,
      setSelectedTemplate,
      toggleTemplateSelector,
      updateMessage,
      updateMessagesMetadata,
      updateTemplateSelectorFilter,
      setSuggestion,
    }),
    [
      hasMore,
      isLoading,
      isTemplateSelectorActive,
      messages,
      messagesMetadata,
      selectedTemplate,
      templateSelectorFilter,
      suggestion,
    ],
  );
  return (
    <InboxMessagesContext.Provider value={contextValue}>
      {children}
    </InboxMessagesContext.Provider>
  );
};

export default InboxMessagesContextProvider;
