import { Property, PropertyBusinessType } from 'models/Properties';
import { PipelineFilter } from 'pages/pipeline/Pipeline.types';

// This function add unitTypeUid based on hotelUid by in the filter
export function getPropertiesFilterConditions(
  filter: PipelineFilter,
  properties: Property[],
) {
  const { propertyUids } = filter;

  if (
    propertyUids?.length &&
    properties.find(
      ({ businessType, uid }) =>
        businessType === PropertyBusinessType.HOTEL &&
        propertyUids.includes(uid),
    )
  ) {
    const unitTypesUids = properties
      .filter(
        ({ businessType, uid }) =>
          businessType === PropertyBusinessType.HOTEL &&
          propertyUids.includes(uid),
      )
      .reduce(
        (acc, { unitTypes }) => {
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          return acc.concat(unitTypes.map(({ uid }) => uid));
        },
        [propertyUids],
      );

    return propertyUids?.length
      ? `propertyUids:[${Array.from(unitTypesUids)
          .map((propertyUid) => `"${propertyUid}"`)
          .join(',')}],`
      : '';
  }

  return propertyUids?.length
    ? `propertyUids:[${Array.from(propertyUids)
        .map((propertyUid) => `"${propertyUid}"`)
        .join(',')}],`
    : '';
}
