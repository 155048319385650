import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { PropertyBusinessType } from 'models/Properties';
import { normalizeKey } from 'utils/localeUtils';
import { StackedCalendarMode } from '../Calendar.types';
import CalendarContext from '../CalendarContext';
import { calendarModeLocalStorageKey } from '../Calendar.constants';
import { ModeSwitcherButtonsContainer } from './ModeSwitcher.styles';

const interchangeableCalendarModes = [
  StackedCalendarMode.BOOKING,
  StackedCalendarMode.PRICING,
];

const ModeSwitcher = () => {
  const {
    mode: currentMode,
    setMode,
    propertiesMap,
    selectedProperty,
  } = useContext(CalendarContext);
  const { t } = useTranslation();
  // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const property = propertiesMap[selectedProperty];

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const toggleMode = (mode) => {
    localStorage.setItem(calendarModeLocalStorageKey, mode);
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setMode(StackedCalendarMode[mode]);
  };

  useEffect(() => {
    if (property?.businessType === PropertyBusinessType.UNIT_TYPE) {
      setMode(StackedCalendarMode.PRICING);
    }
    if (property?.businessType === PropertyBusinessType.UNIT) {
      setMode(StackedCalendarMode.BOOKING);
    }
  }, [property?.businessType]);

  return (
    <ModeSwitcherButtonsContainer>
      {interchangeableCalendarModes
        .filter(
          (mode) =>
            !selectedProperty ||
            (property?.businessType === PropertyBusinessType.UNIT_TYPE &&
              mode === StackedCalendarMode.PRICING) ||
            (property?.businessType === PropertyBusinessType.UNIT &&
              mode === StackedCalendarMode.BOOKING) ||
            property?.businessType === PropertyBusinessType.HOTEL ||
            property?.businessType === PropertyBusinessType.SUB_UNIT ||
            property?.businessType === PropertyBusinessType.STANDALONE_PROPERTY,
        )
        .map((mode) => (
          <Button
            bsStyle={mode === currentMode ? 'primary' : 'default'}
            data-testid={`mode-switcher-button-${mode}`}
            key={`mode-switcher-button-${mode}`}
            onClick={() => toggleMode(mode)}
          >
            {t(normalizeKey(`pageCalendar.mode.title_${mode}`))}
          </Button>
        ))}
    </ModeSwitcherButtonsContainer>
  );
};

export default ModeSwitcher;
