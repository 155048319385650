import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useBookingDotComOperationsQuickConnectNotify from '../../useBookingDotComOperationsQuickConnectNotifyMutation';
import { CONNECTION_STEP } from '../../modal/ImportBookingDotComPropertiesModal.utils';
import ImportBookingDotComPropertiesContext from '../../modal/ImportBookingDotComPropertiesContext';
import useBookingDotComOperationsImportImportablePropertiesMutation from '../../useBookingDotComOperationsImportImportablePropertiesMutation';

const RefreshProviderButton = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleQuickConnectFinishNotify } =
    useBookingDotComOperationsQuickConnectNotify();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setCurrentStep,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setImportableProperties,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    isImportablePropertiesLoading,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setIsImportablePropertiesLoading,
  } = useContext(ImportBookingDotComPropertiesContext);
  const { getImportableProperties } =
    useBookingDotComOperationsImportImportablePropertiesMutation();

  const onRefresh = () => {
    setIsLoading(true);
    handleQuickConnectFinishNotify().then(() => {
      setIsImportablePropertiesLoading(true);

      getImportableProperties({
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        successCallback: (importableProperties) => {
          setImportableProperties(importableProperties);
          setIsImportablePropertiesLoading(false);
          setIsLoading(false);

          if (importableProperties?.length > 0) {
            setCurrentStep(CONNECTION_STEP.PROPERTY_SELECTION);
          }
          if (importableProperties?.length === 0) {
            setCurrentStep(CONNECTION_STEP.CONNECT_TO_PROVIDER);
          }
        },
      });
    });
  };

  return (
    <Button
      data-testid="refresh-provider-button"
      disabled={isLoading || isImportablePropertiesLoading}
      onClick={onRefresh}
      className="btn btn-success"
    >
      {t('pageProperties.importBookingDotComPropertiesModal.refreshProvider')}
    </Button>
  );
};

export default RefreshProviderButton;
