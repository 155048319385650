import * as Yup from 'yup';
import FormWithProvider from 'components/forms/form/Form';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import useReferLink from './useReferLink';
import ReferForm from './ReferForm';
import { ReferFormWrap } from './Refer.styles';

const ReferFormWrapper = () => {
  const { isLoading, referLink } = useReferLink();

  if (isLoading) {
    return (
      <ReferFormWrap>
        <LoadingPlaceholder linesCount={2} width="40%" />
      </ReferFormWrap>
    );
  }

  const initialValues = { referLink };

  const accountSchema = () => ({
    referLink: Yup.string().required(),
  });

  return (
    <FormWithProvider
      data-testid="refer-form"
      key={JSON.stringify(initialValues)}
      schema={accountSchema()}
      defaultValues={initialValues}
      onSubmit={() => {}}
    >
      <ReferForm isLoading={isLoading} />
    </FormWithProvider>
  );
};

export default ReferFormWrapper;
