import useFetchPropertyByUid from 'components/domain/property/useFetchPropertyByUid';
import { Choice } from 'models/AirbnbFlows';
import useFetchAirbnbListingDeactivationFlow from './useFetchAirbnbListingDeactivationFlow';

interface UseAirbnbDeactivationOptionsArgs {
  propertyUid: string;
}

export interface ChoiceTree extends Omit<Choice, 'choices'> {
  prev?: Choice;
  choices?: ChoiceTree[];
}

const buildChoiceTree = (choice: Choice, parentChoice?: Choice): ChoiceTree => {
  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const choiceTree: ChoiceTree = { ...choice, prev: parentChoice };

  choiceTree.choices = choice.choices?.map((subChoice) => {
    return buildChoiceTree(subChoice, choiceTree);
  });

  return choiceTree;
};

const useAirbnbDeactivationOptions = ({
  propertyUid,
}: UseAirbnbDeactivationOptionsArgs) => {
  const { data: property, isLoading } = useFetchPropertyByUid({ propertyUid });
  const isActiveOnAirbnb = !!property?.airbnbData?.active;
  const {
    data: listingDeactivationFlow,
    isLoading: isLoadingAirbnbDeactivationFlow,
  } = useFetchAirbnbListingDeactivationFlow({
    propertyUid,
    enabled: isActiveOnAirbnb,
  });

  const choicesTree: ChoiceTree[] = (
    listingDeactivationFlow?.choices ?? []
  ).map((choice) => buildChoiceTree(choice));

  return {
    property,
    choices: choicesTree,
    isActiveOnAirbnb,
    isLoadingAirbnbOptions:
      isLoading || (isActiveOnAirbnb && isLoadingAirbnbDeactivationFlow),
  };
};

export default useAirbnbDeactivationOptions;
