import useAppModal from 'hooks/useAppModal';
import { Photo } from 'models/Photos';
import useDeletePhoto from 'pages/property/photos/hooks/useDeletePhoto';
import PhotoEditModal from '../modals/editPhoto/PhotoEditModal';
import { PhotoEditModalId } from '../modals/editPhoto/PhotoEditModal.constants';
import usePhotoReorder from '../usePhotoReorder';

const usePhotoActions = (photo: Photo) => {
  const { openDeletePhotoModal } = useDeletePhoto();
  const { handleUpdatePhotoOrder } = usePhotoReorder();
  const { openModal } = useAppModal();

  const handleMovePhotoToTop = () => {
    handleUpdatePhotoOrder({ photo, order: 0 });
  };

  const handleEditPhoto = () => {
    openModal({
      id: PhotoEditModalId,
      customContent: <PhotoEditModal photo={photo} />,
      size: 'large',
    });
  };

  const handleDeletePhoto = () => {
    openDeletePhotoModal({
      photoUid: photo.uid,
    });
  };

  return {
    handleMovePhotoToTop,
    handleDeletePhoto,
    handleEditPhoto,
  };
};

export default usePhotoActions;
