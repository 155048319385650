import { useTranslation } from 'react-i18next';
import useFetchBookingDotComCancellationPolicies from 'components/domain/bookingDotCom/useFetchBookingDotComCancellationPolicies';
import useFetchEmployees from 'components/domain/employee/useFetchEmployees';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import useAppModal from 'hooks/useAppModal';
import useFetchPropertyMainSettingsData from '../mainSettings/useFetchPropertyMainSettingsData';
import usePropertySettingsActiveTab from '../usePropertySettingsActiveTab';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import {
  PropertySettingsTab,
  PropertySettingsTabType,
} from '../PropertySettingsTabs.constants';
import {
  PropertySettingsBulkSavableFormDirtyFields,
  PropertySettingsBulkSavableFormValues,
  PropertySettingsBulkSaveModalParams,
} from './PropertySettingsBulkSaveModal.types';
import PropertySettingsBulkSaveModal from './PropertySettingsBulkSaveModal';

const modalId = 'property-settings-bulk-save-modal';

function getDataFetchingParams(activeTab: PropertySettingsTabType) {
  if (activeTab === PropertySettingsTab.amenities) {
    return {
      fetchBookingDotComCancellationPolicies: false,
      fetchEmployees: false,
      fetchMainSettings: false,
      fetchPropertyTypes: false,
    };
  }

  if (activeTab === PropertySettingsTab.mainSettings) {
    return {
      fetchBookingDotComCancellationPolicies: true,
      fetchEmployees: true,
      fetchMainSettings: true,
      fetchPropertyTypes: true,
    };
  }

  if (activeTab === PropertySettingsTab.pricing) {
    return {
      fetchBookingDotComCancellationPolicies: false,
      fetchEmployees: false,
      fetchMainSettings: false,
      fetchPropertyTypes: false,
    };
  }

  throw new Error(`Not implemented tab: ${activeTab}`);
}

const usePropertySettingsBulkSaveModal = () => {
  const { openModal } = useAppModal();
  const activeTab = usePropertySettingsActiveTab();
  const {
    fetchBookingDotComCancellationPolicies,
    fetchEmployees,
    fetchMainSettings,
    fetchPropertyTypes,
  } = getDataFetchingParams(activeTab);
  const { employees } = useFetchEmployees(fetchEmployees);
  const { data: propertyTypes } = useFetchPropertyTypes({
    enabled: fetchPropertyTypes,
  });
  const { data: bookingDotComCancellationPolicies } =
    useFetchBookingDotComCancellationPolicies({
      enabled: fetchBookingDotComCancellationPolicies,
    });
  const { property } = usePropertySettingsInitData();
  const { mainSettingsData } = useFetchPropertyMainSettingsData(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    property?.uid,
    fetchMainSettings,
  );
  const { t } = useTranslation();

  const openPropertySettingsBulkSaveModal = ({
    dirtyFields,
    formValues,
  }: {
    dirtyFields: PropertySettingsBulkSavableFormDirtyFields;
    formValues: PropertySettingsBulkSavableFormValues;
  }) => {
    const params: PropertySettingsBulkSaveModalParams = {
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      bookingDotComCancellationPolicies,
      // original objects managed by RHF cannot be passed to the modal as they would get converted to immutable objects
      // when stored in Redux which would, in turn, cause issues with the form after closing the modal - deep copies must be passed instead.
      dirtyFields: structuredClone(dirtyFields),
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      employees,
      formValues: structuredClone(formValues),
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      mainSettings: mainSettingsData,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      property,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyTypes,
      tabName: activeTab,
    };

    openModal({
      customContent: (
        <PropertySettingsBulkSaveModal modalId={modalId} params={params} />
      ),
      id: modalId,
      title: t('componentProperty.bulkSaveModal.bulkSave'),
    });
  };

  return { openPropertySettingsBulkSaveModal };
};

export default usePropertySettingsBulkSaveModal;
