import { useTranslation } from 'react-i18next';
import { AppEventType } from 'models/Events';
import { Lead } from 'models/Leads';
import useOpenTemplateModal from 'components/domain/template/modal/useOpenTemplateModal';
import {
  LeadPipelineTemplateActions,
  PipelineActionTemplateNameMap,
} from 'components/domain/template/modal/TemplateModal.types';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import LeadCircle from '../../components/domain/lead/status/LeadCircle';
import LeadActionsPopover from '../../components/domain/lead/actions/LeadActionsPopover';
import useLeadActionsPermission from '../../components/domain/lead/actions/useLeadActionsPermission';
import Tooltip from '../../components/tooltip/Tooltip';

const PipelineLeadListItemActiveStatusIndicator = ({
  lead,
}: {
  lead: Lead;
}) => {
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { createTemplatedModal, templateModalId } = useOpenTemplateModal();
  const canExecutePipelineActions = useLeadActionsPermission(lead);
  const { t } = useTranslation();

  const { availablePipelineActions, uid } = lead;

  if (!canExecutePipelineActions && availablePipelineActions?.length) {
    return (
      <Tooltip
        id={`no-actions-permission-tooltip-${uid}`}
        placement="left"
        text={t('componentLead.actionsInfo.noPermission')}
      >
        <LeadCircle lead={lead} />
      </Tooltip>
    );
  }

  const updateLeadCallback = () => {
    publish(AppEventType.LEAD_UPDATED, { lead: { uid } });
  };

  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const messagingActionCallback = ({ action, lead: _lead }) => {
    if (
      PipelineActionTemplateNameMap.get(action) ||
      LeadPipelineTemplateActions.includes(action)
    ) {
      createTemplatedModal({
        templateName: PipelineActionTemplateNameMap.get(action),
        leadUid: lead.uid,
        leadPipelineTemplateAction: action,
        updateLeadCallback: () => {
          closeModal(templateModalId);
          updateLeadCallback();
        },
      });
    } else {
      // eslint-disable-next-line no-alert
      alert(`TODO implement lead messaging action: ${action}`);
    }
  };

  return (
    <LeadActionsPopover
      lead={lead}
      messagingActionCallback={messagingActionCallback}
      updateLeadCallback={updateLeadCallback}
    >
      <LeadCircle lead={lead} />
    </LeadActionsPopover>
  );
};

export default PipelineLeadListItemActiveStatusIndicator;
