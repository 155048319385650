import FormWithProvider from 'components/forms/form/Form';
import Loader from 'components/loader/Loader';
import PageHeader from 'components/pageHeader/PageHeader';
import useAppMutation from 'hooks/useAppMutation';
import useAppQuery from 'hooks/useAppQuery';
import useNotify from 'hooks/useNotify';
import { Agent, GetAgentResponseTO } from 'models/Agents';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import API from 'services/API';
import { convertToBase64 } from 'utils/funcs';
import ManageForm from '../manageForm/ManageForm';
import manageThirdPartyAgencySchema from '../manageForm/ManageForm.schema';
import { Container, Subtitle } from '../ThirdPartyAgencyManage.styles';

const EditThirdPartyAgency = () => {
  const { id } = useParams();
  if (!id) {
    return <Navigate to="/third-party-agency" />;
  }

  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const {
    data: agent,
    isLoading,
    isFetching,
  } = useAppQuery(
    ['agent', id],
    async () => {
      const response = await API.get<GetAgentResponseTO>(`/v3/agents/${id}`);
      return response.data.agent;
    },
    { enabled: !!id },
  );

  const { mutate: submitAgent, isLoading: isMutating } = useAppMutation(
    ['manageAgent'],
    (data: Agent) => API.put(`/v3/agents/`, data),
    {
      onSuccess: () => {
        navigate('/third-party-agency');
      },
      onError: () => {
        notifyError(t('pageThirdPartyAgencyManage.editError'));
      },
    },
  );

  const handleSubmit = async (values: Agent) => {
    const payload = { ...values };

    // @ts-expect-error TS2790 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    delete payload.password;
    // @ts-expect-error TS2790 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    delete payload.email;
    // @ts-expect-error TS2790 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    delete payload.agencyId;

    delete payload.pictureUrl;

    if (values.picture) {
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      payload.picture = await convertToBase64(values.picture[0]);
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      payload.pictureFileName = values.picture[0]?.name || null;
      payload.pictureName = '';
    } else {
      // @ts-expect-error TS2790 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      delete payload.picture;
      // @ts-expect-error TS2790 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      delete payload.pictureFileName;
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      payload.pictureName = agent.pictureName;
    }

    submitAgent(payload);
  };

  if (isLoading || isFetching) {
    return <Loader fullPage />;
  }

  return (
    <>
      {isMutating && <Loader fullPage overlay />}
      <Container>
        <PageHeader
          title={t('pageThirdPartyAgencyManage.titleEdit')}
          subtitle={
            <Subtitle>
              <span>
                {/*
                 // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                <strong>{t('common.account')}:</strong> {agent.email}
              </span>
              <span>
                <strong>
                  {t('pageThirdPartyAgencyManage.thirdPartyUID')}:
                </strong>{' '}
                {/*
                 // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                {agent.uid}
              </span>
            </Subtitle>
          }
        />
        <FormWithProvider
          horizontal
          defaultValues={agent}
          schema={manageThirdPartyAgencySchema(!!agent?.uid)}
          onSubmit={handleSubmit}
        >
          {/*
           // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          <ManageForm agent={agent} />
        </FormWithProvider>
      </Container>
    </>
  );
};

export default EditThirdPartyAgency;
