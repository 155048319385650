import SelectField from 'components/forms/selectField/SelectField';
import { AirbnbFeeType, FeeScopeEnum, VrboFeeType } from 'models/PropertyFees';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import useFormData from './useFormData';

interface AmountOption {
  value: FeeScopeEnum;
  label: string;
}

const ScopeField = ({
  labelInputSizes,
  amountScopeOptions,
  percentageScopeOptions,
}: {
  amountScopeOptions: AmountOption[];
  percentageScopeOptions: AmountOption[];
  labelInputSizes: {
    colSmLabel: number;
    colSmInput: number;
  };
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { isAmountPercentage } = useFormData();

  const handleScopeChange = (e: React.FormEvent<FormControl>) => {
    const scopeValue = (e.target as HTMLSelectElement).value;

    if (
      scopeValue === FeeScopeEnum.PER_PET_PER_NIGHT ||
      scopeValue === FeeScopeEnum.PER_PET
    ) {
      setValue('appliesToHostfully', 'NO');
      setValue('airbnbMappingType', AirbnbFeeType.PASS_THROUGH_PET_FEE);
      setValue('vrboMappingType', VrboFeeType.PET);
    }
  };

  return (
    <SelectField
      required
      name={isAmountPercentage ? 'scope.tax' : 'scope.amount'}
      label={t('pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scope')}
      {...labelInputSizes}
      options={isAmountPercentage ? percentageScopeOptions : amountScopeOptions}
      onChangeHandler={handleScopeChange}
    />
  );
};

export default ScopeField;
