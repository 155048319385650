import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import usePriceRulesAddEditModal from './modals/usePriceRulesAddEditModal';
import PriceRulesTable from './PriceRulesTable';
import PriceRulesOverrideAirbnb from './PriceRulesOverrideAirbnb';

const PriceRules = () => {
  const { t } = useTranslation();
  const { openAddPriceRulesModal } = usePriceRulesAddEditModal();

  return (
    <section data-testid="price-rules-section">
      <SectionHeader
        id="price-rules-header"
        title={t('pageProperty.pricing.priceRules.title')}
        button={{
          label: t('pageProperty.pricing.priceRules.addPriceRule'),
          onClick: openAddPriceRulesModal,
        }}
        tooltip={t('pageProperty.pricing.priceRules.tooltip')}
      />
      <PriceRulesOverrideAirbnb />
      <PriceRulesTable />
    </section>
  );
};

export default PriceRules;
