import { DeepPartial } from 'react-hook-form';
import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { AreaUnitType } from 'models/AreaUnitType';
import { Currency } from 'models/Currency';
import { PropertyBusinessType } from 'models/Properties';
import {
  propertyCapacityDetailsSchema,
  propertyDetailsInternalThumbnailSchema,
  propertyDetailsPropertyNameSchema,
  propertyDetailsPropertySizeSchema,
} from '../../common/Common.schema';
import {
  CreatePropertyMainSettingsPayload,
  PropertyPricingMainSettingsCreationPayload,
} from '../common/AddProperty.types';
import {
  CapacityDetails,
  PropertyDetails,
} from '../../mainSettings/PropertySettingsMainTab.types';

export const addUnitTypeSchema = (
  currency: Currency,
  t: TFunction<Namespace<'en'>>,
) =>
  Yup.object({
    capacityDetails: propertyCapacityDetailsSchema({
      businessType: PropertyBusinessType.UNIT_TYPE,
      t,
    }),
    propertyDetails: Yup.object({
      ...propertyDetailsInternalThumbnailSchema(t),
      ...propertyDetailsPropertySizeSchema(PropertyBusinessType.UNIT_TYPE),
      propertyName: propertyDetailsPropertyNameSchema(),
    }),
    propertyPricingFeesTaxes: Yup.object({
      cleaningFee: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      cleaningFeeTax: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
      nightlyBasePrice: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .positive()
        .when([], { is: () => currency === 'USD', then: Yup.number().min(10) })
        .required(),
      securityDeposit: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      taxRate: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
    }),
  });

export type AddUnitTypeFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof addUnitTypeSchema>>
>;

export interface UnitTypeCreationPayload
  extends CreatePropertyMainSettingsPayload {
  capacityDetails: Partial<CapacityDetails>;
  hotelUid: string;
  pricing: PropertyPricingMainSettingsCreationPayload;
  propertyDetails: Partial<PropertyDetails>;
}

export function getDefaultFormValues(): AddUnitTypeFormValues {
  return {
    capacityDetails: {
      bathroomCount: '1',
      bedCount: 0,
      bedroomCount: 0,
      baseGuests: 1,
      extraGuestFee: 0,
      maxGuests: 1,
    },
    propertyDetails: {
      propertySizeUnit: AreaUnitType.SQUARE_METERS,
    },
    propertyPricingFeesTaxes: {
      cleaningFee: 0,
      cleaningFeeTax: 0,
      nightlyBasePrice: 0,
      securityDeposit: 0,
      taxRate: 0,
    },
  };
}
