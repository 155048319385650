import { ModalFooter } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';

const PriceRulesAddEditModalFooter = ({ modalId }: { modalId: string }) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isDirty, isSubmitting } = useFormState();
  const cancelHandler = () => closeModal(modalId);

  return (
    <ModalFooter>
      <Button
        data-testid="price-rule-cancel-button"
        disabled={isSubmitting}
        onClick={cancelHandler}
      >
        {t('common.cancel')}
      </Button>
      <Button
        bsStyle="primary"
        data-testid="price-rule-submit-button"
        disabled={!isDirty || isSubmitting}
        type="submit"
      >
        {t(isSubmitting ? 'common.saving' : 'common.save')}
      </Button>
    </ModalFooter>
  );
};

export default PriceRulesAddEditModalFooter;
