import { useCallback } from 'react';
import { CheckboxProps as BSCheckboxProps, FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { BSCheckboxStyled } from './CheckboxField.styles';

export interface CheckboxFieldProps extends Omit<BSCheckboxProps, 'as'> {
  name: string;
  indeterminate?: boolean;
  onChangeHandler?: (event: React.FormEvent<FormControl>) => void;
}

/**
 * This is a wrapper around the react-bootstrap Checkbox component.
 * Used for checkboxes in forms used by React Hook Form.
 */
const CheckboxField = ({
  name,
  indeterminate,
  onChangeHandler,
  ...props
}: CheckboxFieldProps) => {
  const { control } = useFormContext();

  const refSetIndeterminate = useCallback(
    (element: HTMLInputElement) => {
      if (element) {
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        element.indeterminate = indeterminate;
      }
    },
    [indeterminate],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <BSCheckboxStyled
          id={name}
          data-testid={name}
          onBlur={onBlur}
          checked={value}
          onChange={(...args) => {
            onChange(...args);
            onChangeHandler?.(...args);
          }}
          inputRef={(element) => {
            refSetIndeterminate(element);
            return ref(element);
          }}
          {...props}
        />
      )}
    />
  );
};

CheckboxField.defaultProps = {
  indeterminate: undefined,
  onChangeHandler: undefined,
};

export default CheckboxField;
