import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import { getFullName } from 'utils/lead/leadUtils';
import { Container, GuestName } from './OrderView.styles';
import useOrderViewInit from './useOrderViewInit';
import OrderViewTransactions from './transactions/OrderViewTransactions';
import OrderViewDetails from './details/OrderViewDetails';
import OrderViewPriceDetails from './priceDetails/OrderViewPriceDetails';
import OrderViewLoader from './loader/OrderViewLoader';
import OrderViewError from './error/OrderViewError';
import OrderViewChannelCommission from './channelCommission/OrderViewChannelCommission';

const OrderView = () => {
  const { uid } = useParams();

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const { isLoading, lead, error } = useOrderViewInit({ orderUid: uid });
  const { t } = useTranslation();

  if (isLoading) {
    return <OrderViewLoader />;
  }

  if (error || (!lead && !isLoading)) {
    return <OrderViewError />;
  }

  return (
    <Container>
      <PageHeader
        title={
          <Trans
            i18nKey="pageOrder.orderFor"
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            values={{ string: getFullName(lead) }}
            components={{ guestNameWrapper: <GuestName /> }}
          />
        }
        buttons={
          <a
            href="#/reports#transactions"
            data-testid="view-all-transactions-btn"
          >
            {t('pageOrder.viewAllTransactions')}
          </a>
        }
      />

      <OrderViewDetails />
      <OrderViewPriceDetails />

      <OrderViewTransactions />

      <OrderViewChannelCommission />
    </Container>
  );
};

export default OrderView;
