import { AirbnbNotification } from 'models/AirbnbNotification';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { NotificationItemContainer } from './AirbnbNotificationItem.styles';

const AirbnbNotificationItem = ({
  notification,
}: {
  notification: AirbnbNotification;
}) => {
  const isActionItem = !!notification.actionUrl;

  return (
    <NotificationItemContainer data-testid="airbnb-notification-item">
      {isActionItem ? (
        <a href={notification.actionUrl} target="_blank" rel="noreferrer">
          <p>{notification.text}</p>
        </a>
      ) : (
        <p>{notification.text}</p>
      )}

      <time>
        {formatDistanceToNow(parseISO(notification.createdAt), {
          addSuffix: true,
        })}
      </time>
    </NotificationItemContainer>
  );
};

export default AirbnbNotificationItem;
