import styled from 'styled-components';
import { DisplayFieldRowStyled } from 'pages/property/common/form/DisplayField.styles';

export const FieldsetStyled = styled.fieldset`
  ${DisplayFieldRowStyled} {
    margin-left: 0;
    margin-right: 0;

    label {
      text-align: right;
    }
  }
`;
