import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';

interface LeadState {
  newLeadsCount: number;
  newLeadsUids: string[];
}

const initialState = {
  newLeadsCount: 0,
} as LeadState;

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setNewLeadsData: (
      state,
      action: PayloadAction<{ newLeadsCount: number; newLeadsUids: string[] }>,
    ) => {
      state.newLeadsCount = action.payload.newLeadsCount;
      state.newLeadsUids = action.payload.newLeadsUids;
    },
  },
});

export const { setNewLeadsData } = leadSlice.actions;

export default leadSlice.reducer;
