import { Trans, useTranslation } from 'react-i18next';
import {
  PRICING_PLAN_CATEGORY,
  PRICING_PLAN_TERM,
  PricingPlan,
} from 'models/PricingPlan';
import { toStartCase } from 'utils/stringUtils';
import UpgradePlanModalTable from './UpgradePlanModalTable';
import UpgradePlanModalPricePerProperty from './UpgradePlanModalPricePerProperty';

interface UpgradePlanModalNextProps {
  currentPlan: PricingPlan;
  nextPlan: PricingPlan;
  propertyCount: number;
}

const UpgradePlanModalNext = ({
  currentPlan,
  nextPlan,
  propertyCount,
}: UpgradePlanModalNextProps) => {
  const { t } = useTranslation();

  if (!nextPlan) {
    return null;
  }

  const nextPlanName = toStartCase(nextPlan.category);
  const isAnnual = nextPlan.term === PRICING_PLAN_TERM.ANNUAL;
  const nextPlanPrice = isAnnual ? nextPlan.price : nextPlan.monthlyPrice;
  const term = isAnnual
    ? t('componentProperty.upgradePlanModal.annual')
    : t('componentProperty.upgradePlanModal.monthly');

  return (
    <section className="next-plan" data-testid="upgrade-plan-modal-next-plan">
      <UpgradePlanModalTable
        caption={
          <>
            <Trans
              i18nKey="componentProperty.upgradePlanModal.upgradeTo"
              values={{ plan: nextPlanName }}
              components={{ strong: <strong /> }}
            />{' '}
            (
            <a
              href="https://www.hostfully.com/pricing/property-management-platform/"
              target="_blank"
              rel="noreferrer"
            >
              {t('componentProperty.upgradePlanModal.seeAllFeatures')}
            </a>
            )
          </>
        }
        data={[
          {
            description: t('componentProperty.upgradePlanModal.newTermPrice', {
              term,
            }),
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            price: nextPlanPrice,
          },
        ]}
      />
      {nextPlan.category === PRICING_PLAN_CATEGORY.PREMIUM &&
        propertyCount >= 20 && (
          <UpgradePlanModalPricePerProperty
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            planPrice={nextPlanPrice}
            propertyCount={propertyCount}
          />
        )}
      {/*
       // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      {currentPlan && nextPlanPrice - currentPlan.price > 0 && (
        <UpgradePlanModalTable
          data={[
            {
              description: t(
                'componentProperty.upgradePlanModal.maximumCharge',
              ),
              // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              price: nextPlanPrice - currentPlan.price,
              highlight: true,
            },
          ]}
          footer={t('componentProperty.upgradePlanModal.priceWillBeProrated')}
        />
      )}
    </section>
  );
};

export default UpgradePlanModalNext;
