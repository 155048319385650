import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';

interface UserPermissionsState {
  permissions: string[];
}

const initialState: UserPermissionsState = {
  permissions: [],
};

const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setAppUserPermissions: (
      state,
      { payload: { permissions } }: PayloadAction<{ permissions: string[] }>,
    ) => {
      state.permissions = permissions;
    },
  },
});

export const { setAppUserPermissions } = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;
