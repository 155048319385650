import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import TagsSelectField from 'components/forms/domain/tag/TagsSelectField';
import { Menu, MenuItem } from 'react-bootstrap-typeahead';
import { PhotoEditModalTagsContainer } from './PhotoEditModal.styles';

const PhotoEditModalTags = () => {
  const { t } = useTranslation();
  const popularTags = useWatch({ name: 'popularTags' }) || [];
  const availableTags = useWatch({ name: 'availableTags' }) || [];

  const options = [...popularTags, ...availableTags];

  return (
    <PhotoEditModalTagsContainer>
      <Col xs={4} sm={2} className="tags-label">
        {t('pageProperty.photos.editModal.tags')}
      </Col>

      <Col xs={8} sm={10} className="tags-input">
        <TagsSelectField
          name="tags"
          options={options}
          colXsInput={10}
          // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          renderMenu={(results, menuProps) => {
            const [customOptions, popularOptions, availableOptions] = (
              results || []
            ).reduce(
              // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              (acc, result) => {
                if (result.customOption) {
                  acc[0].push(result);
                } else if (result.isPopular) {
                  acc[1].push(result);
                } else {
                  acc[2].push(result);
                }
                return acc;
              },
              [[], [], []],
            );

            return (
              <Menu {...menuProps}>
                {/*
                 // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                {availableOptions.slice(0, 10).map((result, index) => (
                  <MenuItem
                    className="typeahead-search-option"
                    key={result.name}
                    option={result}
                    position={index}
                  >
                    {result.name}
                  </MenuItem>
                ))}
                {popularOptions.length > 0 && (
                  <span className="popular-title">
                    {t('pageProperty.photos.editModal.popularTags')}
                  </span>
                )}
                {/*
                 // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                {popularOptions.map((result, index) => (
                  <MenuItem
                    className="typeahead-search-option"
                    key={result.name}
                    option={result}
                    position={availableOptions.length + index}
                  >
                    {result.name}
                  </MenuItem>
                ))}
                {/*
                 // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
                {customOptions.map((result, index) => (
                  <MenuItem
                    className="typeahead-search-option"
                    key={result.name}
                    option={result}
                    position={
                      availableOptions.length + popularOptions.length + index
                    }
                  >
                    {result.name}
                  </MenuItem>
                ))}
              </Menu>
            );
          }}
        />
      </Col>
    </PhotoEditModalTagsContainer>
  );
};

export default PhotoEditModalTags;
