import { Property } from 'models/Properties';
import useAppModal from 'hooks/useAppModal';
import { Lead } from 'models/Leads';
import AirbnbAlterationRequestModal from './modal/AirbnbAlterationRequestModal';

export const modalId = 'update-airbnb-alteration-request-modal';

const useOpenAirbnbAlterationRequestModal = (
  availableProperties?: Property[],
) => {
  const { openModal } = useAppModal();

  const openAirbnbAlterationRequestModal = (lead: Lead) => {
    openModal({
      customContent: (
        <AirbnbAlterationRequestModal
          availableProperties={availableProperties}
          modalId={modalId}
          lead={lead}
        />
      ),
      id: modalId,
      size: 'lg',
    });
  };

  return {
    updateAirbnbAlterationRequestModal: (lead: Lead) => {
      openAirbnbAlterationRequestModal(lead);
    },
  };
};

export default useOpenAirbnbAlterationRequestModal;
