import { useContext } from 'react';
import useStackedCalendarInit from '../useStackedCalendarInit';
import CalendarContext from '../CalendarContext';
import { Container } from './StackedCalendarFilters.styles';
import StackedCalendarFiltersForm from './StackedCalendarFiltersForm';
import useStackedCalendarFiltersFormApplyStoredValues from './useStackedCalendarFiltersFormApplyStoredValues';

const StackedCalendarFilters = () => {
  const { filterOptions, isInitializing, employees } = useStackedCalendarInit();
  const { isFilterOpen } = useContext(CalendarContext);

  useStackedCalendarFiltersFormApplyStoredValues();

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const dispatchScrollEvent = (date) => {
    const event = new CustomEvent('scrollToDateEvent', {
      detail: { date },
    });

    window.dispatchEvent(event);
  };

  if (isInitializing) {
    return null;
  }

  return (
    <Container isCollapsed={!isFilterOpen}>
      <StackedCalendarFiltersForm
        employees={employees}
        filterOptions={filterOptions}
        scrollOnClear={dispatchScrollEvent}
      />
    </Container>
  );
};

export default StackedCalendarFilters;
