import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import { UnitTypeSearchContainer } from './UnitTypeSearch.styles';

const UnitTypeSearch = memo(() => {
  const { t } = useTranslation();

  return (
    <UnitTypeSearchContainer>
      <TextField
        colXsInput={12}
        colSmInput={12}
        name="unitTypeSearch"
        placeholder={t('pageProperty.unitTypes.searchPlaceholder')}
      />
    </UnitTypeSearchContainer>
  );
});

export default UnitTypeSearch;
