import { useMemo, useState } from 'react';

import CalendarBodyContext from './CalendarBodyContext';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const CalendarBodyContextProvider = ({ children }) => {
  const [daysCalendar, setDaysCalendar] = useState({});

  const contextValue = useMemo(
    () => ({
      daysCalendar,
      setDaysCalendar,
    }),
    [daysCalendar],
  );
  return (
    <CalendarBodyContext.Provider value={contextValue}>
      {children}
    </CalendarBodyContext.Provider>
  );
};

export default CalendarBodyContextProvider;
