import { Currency } from 'models/Currency';
import { PropertyDetails } from '../../mainSettings/PropertySettingsMainTab.types';
import { AddSinglePropertyFormValues } from '../single/AddSingleProperty.schema';
import { AddUnitTypeFormValues } from '../unitType/AddUnitType.schema';
import { PropertyPricingMainSettingsCreationPayload } from './AddProperty.types';

export function getPropertyDetailsMainSettingsCreationPayload(
  formValues: AddSinglePropertyFormValues | AddUnitTypeFormValues,
): Partial<PropertyDetails> {
  const { propertyDetails } = formValues;

  return (
    (propertyDetails &&
      Object.entries(propertyDetails)
        .filter(([key]) => key !== 'internalThumbnail')
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {},
        )) ||
    {}
  );
}

export function getPropertyPricingMainSettingsCreationPayload({
  currency,
  formValues,
}: {
  currency?: Currency;
  formValues: AddSinglePropertyFormValues | AddUnitTypeFormValues;
}): PropertyPricingMainSettingsCreationPayload {
  const {
    propertyPricingFeesTaxes: {
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cleaningFee: cleaningFeeAmount,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      cleaningFeeTax,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      nightlyBasePrice: nightlyBasePriceAmount,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      securityDeposit: securityDepositAmount,
      // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      taxRate,
    },
  } = formValues;

  return {
    cleaningFeeAmount,
    cleaningFeeTax,
    currency,
    nightlyBasePriceAmount,
    securityDepositAmount,
    taxRatePercentage: taxRate ? taxRate / 100 : 0,
  };
}
