import { ReactElement } from 'react';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Sizes,
} from 'react-bootstrap';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { removeActiveModal } from '../../store/slices/modal';
import { ModalWrapper } from './ModalRenderer.styles';

interface Props {
  modalId: string;
  body?: ReactElement | string;
  customContent?: ReactElement | string;
  footer?: ReactElement;
  title?: ReactElement | string;
  size?: Sizes;
  width?: number;
}

const ModalRenderer = ({
  modalId,
  body,
  customContent,
  footer,
  size,
  title,
  width,
}: Props) => {
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(
    (state) => state.modal.activeModals[modalId],
  );
  const isOpen = !!activeModal;

  if (!isOpen) {
    return null;
  }

  const onHide = () => {
    activeModal?.onHide?.();
    dispatch(removeActiveModal({ modalId }));
  };

  return (
    <ModalWrapper
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      $width={width}
      show={isOpen}
      onHide={onHide}
      data-testid={modalId}
      animation={false}
      bsSize={size}
    >
      {title && (
        <ModalHeader closeButton>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      {customContent}
      {body && <ModalBody>{body}</ModalBody>}
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </ModalWrapper>
  );
};

ModalRenderer.defaultProps = {
  body: null,
  customContent: null,
  footer: null,
  size: '',
  title: '',
  width: null,
};

export default ModalRenderer;
