import { useTranslation } from 'react-i18next';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import MultiSelectField, {
  MultiSelectFieldProps,
} from 'components/forms/multiSelectField/MultiSelectField';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';
import { Amenities, RuleType } from 'utils/constants/amenities';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';

interface AmenitiesMultiSelectFieldProps
  extends Omit<MultiSelectFieldProps, 'fieldRenderer' | 'options'> {
  filterOptions?: PropertiesFilterOptions;
}

const AmenitiesMultiSelectField = ({
  filterOptions: filterOptionsProp,
  ...rest
}: AmenitiesMultiSelectFieldProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPropertiesFilterOptions({
    enabled: !filterOptionsProp,
  });
  const filterOptions = filterOptionsProp ?? data;

  const amenities = Object.entries(Amenities)
    .concat(Object.entries(RuleType))
    .concat(
      filterOptions?.availableCustomAmenities.map((aca) => {
        return [aca.name, aca.name];
      }) ?? [],
    )
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map((entry) => {
      return {
        label: entry[1],
        value: entry[0],
        isCustom:
          // @ts-expect-error TS2532 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          filterOptions?.availableCustomAmenities.filter(
            (aca) => aca.name === entry[0],
          ).length > 0,
      };
    });

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <MultiSelectField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.amenities')}
      // @ts-expect-error TS2783 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      name="amenities"
      options={amenities}
      {...rest}
    />
  );
};

AmenitiesMultiSelectField.defaultProps = {
  filterOptions: undefined,
};

export default AmenitiesMultiSelectField;
