import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import MultiSelectField from 'components/forms/multiSelectField/MultiSelectField';
import { formatOptionLabelWithHighlight } from 'components/forms/multiSelectField/formatOptionLabel';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import getPropertiesQuery from 'utils/property/getPropertiesQuery';
import { API_MAX_LIMIT } from 'services/API.constants';
import { getPropertyOptions } from 'utils/property/propertyUtils';
import { PropertyMultiSelectFieldProps } from './PropertyMultiSelectField.types';

const PropertyMultiSelectField = ({
  graphQlFilters,
  filterPropertyBy,
  enableUnitTypes = false,
  enableHotels = true,
  showUnits = false,
  showInactiveProperties = true,
  ...props
}: PropertyMultiSelectFieldProps) => {
  const { t } = useTranslation();
  const {
    formState: { defaultValues },
  } = useFormContext();
  // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const fixedOptions = defaultValues[props.name]?.filter((v) => v.isFixed);
  const { allProperties, isFetching } = useFetchAllProperties({
    conditions: getPropertiesQuery({
      filters: { limit: API_MAX_LIMIT, ...(graphQlFilters ?? {}) },
    }),
    fields:
      'uid,name,businessType,numberOfSubUnits,unitTypes{uid,name,units{uid,name}},subUnits{uid,name}',
    operationName: 'MultiSelectFieldProperties',
    enabled: true,
  });

  if (isFetching) {
    return <LoadingPlaceholder />;
  }

  const options = allProperties
    ?.filter(filterPropertyBy ?? (() => true))
    .reduce(
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (acc, property) => [
        ...acc,
        ...getPropertyOptions({
          property,
          enableUnitTypes,
          enableHotels,
          showInactiveProperties,
          showUnits,
        }),
      ],
      [],
    );

  // @ts-expect-error TS2461 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const optionsWithFixed = [...(fixedOptions ?? []), ...(options ?? [])].filter(
    (option, index, self) =>
      index === self.findIndex((o) => o.value === option.value),
  );

  return (
    <MultiSelectField
      placeholder={t(
        'form.propertyMultiSelectField.placeholderSearchPropertyName',
      )}
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      options={fixedOptions?.length > 0 ? optionsWithFixed : options}
      formatOptionLabel={formatOptionLabelWithHighlight}
      {...props}
    />
  );
};

PropertyMultiSelectField.defaultProps = {
  graphQlFilters: undefined,
  filterPropertyBy: undefined,
};

export default PropertyMultiSelectField;
