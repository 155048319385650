import { useMemo } from 'react';
import useCurrentLocale from 'i18n/useCurrentLocale';
import { CountryCode, CountryDescriptor } from 'models/Country';
import { getCountryFlagEmoji } from 'models/Country.utils';
import useFetchAvailableCountries from './useFetchAvailableCountries';

const useAvailableCountries = (): {
  countries: CountryDescriptor[];
  countriesMap: Record<CountryCode, CountryDescriptor>;
  isLoading: boolean;
} => {
  const locale = useCurrentLocale();
  const { data: fetchedCountries, isLoading } = useFetchAvailableCountries();

  const countries = useMemo(() => {
    const comparator = new Intl.Collator(locale, { numeric: true });
    const formatter = new Intl.DisplayNames(locale, { type: 'region' });

    return (
      fetchedCountries?.countries
        ?.map(({ code }) => ({
          code,
          flag: getCountryFlagEmoji(code),
          localizedName: formatter.of(code as unknown as string),
        }))
        .toSorted(
          (
            { localizedName: localizedName1 },
            { localizedName: localizedName2 },
          ) =>
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            comparator.compare(localizedName1, localizedName2),
        ) || []
    );
  }, [fetchedCountries, locale]);

  const countriesMap = useMemo(
    () =>
      countries.reduce(
        (acc, countryDescriptor) => ({
          ...acc,
          [countryDescriptor.code]: countryDescriptor,
        }),
        {} as Record<CountryCode, CountryDescriptor>,
      ),
    [countries],
  );

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return { countries, countriesMap, isLoading };
};

export default useAvailableCountries;
