import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';
import useAppUser from 'hooks/useAppUser';

const PropertySettingsServiceProvidersTabHeader = () => {
  const { isEmployeeManager } = useAppUser();
  const { t } = useTranslation();

  const button = (
    <Button
      $colorScheme="green"
      data-testid="manage-providers-button"
      disabled={!isEmployeeManager}
    >
      {t('pageProperty.serviceProviders.manageProviders')}
    </Button>
  );

  if (isEmployeeManager) {
    return <Link to="/agency-settings#providers">{button}</Link>;
  }

  return (
    <Tooltip
      id="manage-providers-button-tooltip"
      text={t('common.permissions.actionNotAllowedForAccount')}
    >
      {button}
    </Tooltip>
  );
};

export default PropertySettingsServiceProvidersTabHeader;
