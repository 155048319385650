import useUpgradePlanModal from 'components/domain/property/modal/upgradePlan/useUpgradePlanModal';
import useAppModal from 'hooks/useAppModal';
import { addSubUnitsModalId } from './AddSubUnitsModal.constants';
import useAddSubUnitsMutation from './useAddSubUnitsMutation';
import { AddSubUnitsModalSchemaType } from './AddSubUnitsModal.schema';

const useAddSubUnitsModalForm = (propertyUid: string) => {
  const { closeModal } = useAppModal();
  const { canAddProperties, openUpgradePlanModal } = useUpgradePlanModal();
  const { mutate: addSubUnits, isLoading: isAddingSubUnits } =
    useAddSubUnitsMutation({
      onSuccess: () => closeModal(addSubUnitsModalId),
    });

  const handleSubmit = (formValues: AddSubUnitsModalSchemaType) => {
    if (!canAddProperties(formValues.units)) {
      closeModal(addSubUnitsModalId);
      openUpgradePlanModal();
    } else {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      addSubUnits({ propertyUid, ...formValues });
    }
  };

  return {
    handleSubmit,
    isUpdating: isAddingSubUnits,
  };
};

export default useAddSubUnitsModalForm;
