import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import AirbnbAlterationRequestModal from 'components/domain/airbnbAlterationRequest/modal/AirbnbAlterationRequestModal';
import {
  Lead,
  LeadAvailablePipelineAction,
  LeadSource,
} from '../../../../models/Leads';
import useExecuteLeadPipelineAction from './useExecuteLeadPipelineAction';
import DeclineAirbnbLeadModalContent from './airbnb/DeclineAirbnbLeadModalContent';
import CancelAirbnbLeadModalContent from './airbnb/CancelAirbnbLeadModalContent';
import MarkAsPaidModal from './MarkAsPaidModal';
import RecordPaymentModal from './RecordPaymentModal';
import useLeadPipelineActionCustomHandler from './useLeadPipelineActionCustomHandler';
import useLeadPipelineActionEventPublisher from './useLeadPipelineActionEventPublisher';
import ReopenLeadModal from './ReopenLeadModal';
import CancelVRBOLeadModalContent from './vrbo/CancelVRBOLeadModalContent';

interface LeadActionPayload {
  action: LeadAvailablePipelineAction;
  lead: Lead;
  updateLeadCallback: () => void;
}

const actionPropertiesMap = {
  [LeadAvailablePipelineAction.ACCEPT_PENDING]: {},
  [LeadAvailablePipelineAction.AIRBNB_PRE_APPROVE]: {},
  [LeadAvailablePipelineAction.ASSIGN_TO_ME]: {},
  [LeadAvailablePipelineAction.CANCEL_ALTERATION_REQUEST]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmButtonColor: 'danger',
      confirmLabelKey:
        'componentLead.actionsInfo.confirmCancellationAlterationRequestConfirm',
      messageKey:
        'componentLead.actionsInfo.confirmCancellationAlterationRequestMessage',
      titleKey:
        'componentLead.actionsInfo.confirmCancellationAlterationRequestTitle',
    },
  },
  [LeadAvailablePipelineAction.CANCEL_BOOKING]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmButtonColor: 'danger',
      confirmLabelKey:
        'componentLead.actionsInfo.confirmCancelLeadConfirmButtonLabel',
      messageKey: 'componentLead.actionsInfo.confirmCancelLeadMessage',
      titleKey: 'componentLead.actionsInfo.confirmCancelLeadTitle',
    },
    sourceSpecificProps: {
      [LeadSource.DIRECT_AIRBNB]: {
        modal: {
          id: 'airbnb-cancel-lead-modal',
          // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          contentProvider: (props) => (
            <CancelAirbnbLeadModalContent {...props} />
          ),
          errorMessageKey:
            'componentLead.actionsInfo.airbnb.cancellationFailed',
          titleKey: 'componentLead.actionsInfo.airbnb.cancelModalTitle',
        },
      },
      [LeadSource.DIRECT_HOMEAWAY]: {
        modal: {
          id: 'vrbo-cancel-lead-modal',
          // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          contentProvider: (props) => <CancelVRBOLeadModalContent {...props} />,
          errorMessageKey: 'componentLead.actionsInfo.vrbo.cancellationFailed',
          titleKey: 'componentLead.actionsInfo.vrbo.cancelModalTitle',
        },
      },
    },
  },
  [LeadAvailablePipelineAction.CHANGE_BOOKING]: {
    modal: {
      id: 'update-airbnb-alteration-request-modal',
      size: 'lg',
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      contentProvider: (props) => <AirbnbAlterationRequestModal {...props} />,
    },
  },
  [LeadAvailablePipelineAction.CLOSE_LEAD]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmButtonColor: 'danger',
      confirmLabelKey:
        'componentLead.actionsInfo.confirmCloseLeadConfirmButtonLabel',
      messageKey: 'componentLead.actionsInfo.confirmCloseLeadMessage',
      titleKey: 'componentLead.actionsInfo.confirmCloseLeadTitle',
    },
  },
  [LeadAvailablePipelineAction.DECLINE_PENDING]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmButtonColor: 'danger',
      confirmLabelKey:
        'componentLead.actionsInfo.confirmDeclineLeadConfirmButtonLabel',
      messageKey: 'componentLead.actionsInfo.confirmDeclineLeadMessage',
      titleKey: 'componentLead.actionsInfo.confirmDeclineLeadTitle',
    },
    sourceSpecificProps: {
      [LeadSource.DIRECT_AIRBNB]: {
        modal: {
          id: 'airbnb-decline-lead-modal',
          // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          contentProvider: (props) => (
            <DeclineAirbnbLeadModalContent {...props} />
          ),
          titleKey: 'componentLead.actionsInfo.airbnb.declineModalTitle',
        },
      },
    },
  },
  [LeadAvailablePipelineAction.EXTEND_HOLD]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmLabelKey:
        'componentLead.actionsInfo.confirmExtendHoldConfirmButtonLabel',
      messageKey: 'componentLead.actionsInfo.confirmExtendHoldMessage',
      titleKey: 'componentLead.actionsInfo.confirmExtendHoldTitle',
    },
  },
  [LeadAvailablePipelineAction.IGNORE_LEAD]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmButtonColor: 'danger',
      confirmLabelKey:
        'componentLead.actionsInfo.confirmIgnoreLeadConfirmButtonLabel',
      messageKey: 'componentLead.actionsInfo.confirmIgnoreLeadMessage',
      titleKey: 'componentLead.actionsInfo.confirmIgnoreLeadTitle',
    },
  },
  [LeadAvailablePipelineAction.MARK_AS_BOOKED]: {},
  [LeadAvailablePipelineAction.MARK_AS_PAID]: {
    modal: {
      id: 'mark-lead-as-paid-modal',
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      contentProvider: (props) => <MarkAsPaidModal {...props} />,
      titleKey: 'componentLead.actionsInfo.markAsPaid',
    },
  },
  [LeadAvailablePipelineAction.OPEN_INBOX]: {
    customHandler: true,
  },
  [LeadAvailablePipelineAction.REBOOK]: {},
  [LeadAvailablePipelineAction.REBOOK_AS_NEW_LEAD]: {
    publishEvent: true,
  },
  [LeadAvailablePipelineAction.RECORD_PAYMENT]: {
    modal: {
      id: 'record-lead-payment-modal',
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      contentProvider: (props) => <RecordPaymentModal {...props} />,
      titleKey: 'componentLead.actionsInfo.recordPayment.title',
    },
  },
  [LeadAvailablePipelineAction.REOPEN]: {
    modal: {
      id: 'reopen-lead-modal',
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      contentProvider: (props) => <ReopenLeadModal {...props} />,
      titleKey: 'componentLead.actionsInfo.confirmReopenLeadTitle',
    },
  },
  [LeadAvailablePipelineAction.REVIEW]: {
    publishEvent: true,
  },
  [LeadAvailablePipelineAction.REVOKE_HOLD]: {
    confirmRequired: true,
    confirmModalProps: {
      confirmButtonColor: 'danger',
      confirmLabelKey:
        'componentLead.actionsInfo.confirmRevokeHoldConfirmButtonLabel',
      messageKey: 'componentLead.actionsInfo.confirmRevokeHoldMessage',
      titleKey: 'componentLead.actionsInfo.confirmRevokeHoldTitle',
    },
  },
};

const useLeadPipelineAction = () => {
  const { executeAction, isExecutingAction } = useExecuteLeadPipelineAction();
  const customActionHandler = useLeadPipelineActionCustomHandler();
  const eventPublisher = useLeadPipelineActionEventPublisher();
  const { openConfirmModal, openModal, closeModal } = useAppModal();
  const { t } = useTranslation();

  const takeAction = ({
    action,
    lead,
    updateLeadCallback,
  }: LeadActionPayload) => {
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    const actionProperties = actionPropertiesMap[action];

    if (!actionProperties) {
      throw new Error(`Not implemented lead action: ${action}`);
    }

    const {
      confirmRequired,
      confirmModalProps,
      customHandler,
      modal,
      publishEvent,
      sourceSpecificProps,
    } = actionProperties;
    const { source } = lead;

    if (sourceSpecificProps && sourceSpecificProps[source]?.modal) {
      const { contentProvider, errorMessageKey, id, titleKey } =
        sourceSpecificProps[source].modal;
      const customErrorMessage = errorMessageKey && t(errorMessageKey);

      const onCancel = () => closeModal(id);
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const onSubmit = (formData) => {
        executeAction({
          action,
          formData,
          lead,
          updateLeadCallback,
          customErrorMessage,
        });
        closeModal(id);
      };

      openModal({
        id,
        customContent: contentProvider({ lead, onCancel, onSubmit }),
        title: t(titleKey),
      });
    } else if (modal) {
      const { id, contentProvider, titleKey, size = 'sm' } = modal;

      openModal({
        id,
        customContent: contentProvider({
          lead,
          modalId: id,
          executeActionHandler: () => {
            executeAction({ action, lead, updateLeadCallback });
          },
        }),
        size,
        title: t(titleKey),
      });
    } else if (confirmRequired) {
      const { confirmButtonColor, confirmLabelKey, messageKey, titleKey } =
        confirmModalProps;

      openConfirmModal({
        body: t(messageKey),
        confirmColor: confirmButtonColor || 'primary',
        confirmLabel: t(confirmLabelKey),
        title: t(titleKey),
        onConfirm: () => {
          executeAction({ action, lead, updateLeadCallback });
        },
      });
    } else if (customHandler) {
      customActionHandler({ action, lead });
    } else if (publishEvent) {
      eventPublisher({ action, lead });
    } else {
      executeAction({ action, lead, updateLeadCallback });
    }
  };

  return {
    takeAction,
    isExecutingAction,
  };
};

export default useLeadPipelineAction;
