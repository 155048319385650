import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import {
  Message,
  MessageSenderType,
  MessageState,
} from 'models/inbox/Messages';
import { normalizeKey } from 'utils/localeUtils';
import {
  InboxMessageContainer,
  InboxMessageContent,
  InboxMessageContentContainer,
  InboxMessageDate,
  InboxMessageFooterContainer,
  InboxMessageSectionWrapper,
} from './InboxMessages.styles';
import InboxMessageAvatar from './InboxMessageAvatar';
import InboxMessageAttachments from './InboxMessageAttachments';
import InboxMessageFooterError from './InboxMessageFooterError';
import { getInboxMessageContentClassName } from './InboxMessage.utils';

function isMessageFromMe(
  message: Message,
  isEmployee: boolean,
  userUid: string,
) {
  if (isEmployee) {
    return message.senderDetails?.senderType === MessageSenderType.AGENCY;
  }

  return message.messageCreator?.creatorUid === userUid;
}

function getMessageDateLocaleKey(
  message: Message,
  messageFromMe: boolean,
  userUid: string,
) {
  const { channel, senderDetails, messageCreator } = message;
  const displaySenderNameForAgency =
    senderDetails?.senderName &&
    messageFromMe &&
    messageCreator?.creatorUid !== senderDetails?.senderUid &&
    senderDetails?.senderType === MessageSenderType.AGENCY;

  const displaySenderNameForGuest =
    senderDetails?.senderName &&
    !messageFromMe &&
    userUid !== senderDetails?.senderUid &&
    senderDetails?.senderType === MessageSenderType.GUEST;

  if (!senderDetails) {
    return 'pageInbox.messages.dateInfo.dateOnly';
  }

  if (displaySenderNameForAgency || displaySenderNameForGuest) {
    return normalizeKey(`pageInbox.messages.dateInfo.sent_${channel}_by`);
  }

  return normalizeKey(
    `pageInbox.messages.dateInfo.${
      messageFromMe ? 'sent' : 'received'
    }_${channel}`,
  );
}

const InboxMessage = ({ message }: { message: Message }) => {
  const { isEmployee, userUid } = useAppUser();
  const { t } = useTranslation();
  const {
    content,
    created,
    state,
    threadUid,
    uid,
    senderDetails: { senderName } = {},
  } = message;

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const messageFromMe = isMessageFromMe(message, isEmployee, userUid);
  const hasError = state === MessageState.FAILED && messageFromMe;
  const messageContent =
    content?.text?.replace(/href/g, "target='_blank' href") || '';
  const messageDateLocaleKey = getMessageDateLocaleKey(
    message,
    messageFromMe,
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    userUid,
  );

  const className = getInboxMessageContentClassName({ messageContent, uid });

  return (
    <InboxMessageContainer uid={uid}>
      <InboxMessageSectionWrapper $alignRight={messageFromMe}>
        {!messageFromMe && (
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          <InboxMessageAvatar senderName={senderName} threadUid={threadUid} />
        )}
        <InboxMessageContentContainer>
          <InboxMessageContent className={className} $hasError={hasError}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: messageContent }} />
          </InboxMessageContent>
        </InboxMessageContentContainer>
      </InboxMessageSectionWrapper>
      <InboxMessageSectionWrapper $alignRight={messageFromMe}>
        <InboxMessageFooterContainer $alignRight={messageFromMe}>
          {hasError && <InboxMessageFooterError message={message} />}
          {!hasError && (
            <>
              <InboxMessageAttachments message={message} />
              <InboxMessageDate>
                {t(messageDateLocaleKey, {
                  date: new Date(created),
                  senderName,
                })}
              </InboxMessageDate>
            </>
          )}
        </InboxMessageFooterContainer>
      </InboxMessageSectionWrapper>
    </InboxMessageContainer>
  );
};

export default InboxMessage;
