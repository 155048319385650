import { OrderViewPriceDetailsListItemTitleWrapper } from './OrderViewPriceDetailsListItemTitle.styles';

// @ts-expect-error TS7008 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const OrderViewPriceDetailsListItemTitle = ({ title }: { title }) => {
  return (
    <OrderViewPriceDetailsListItemTitleWrapper data-testid="price-details-list-item-main-title">
      {title}
    </OrderViewPriceDetailsListItemTitleWrapper>
  );
};

export default OrderViewPriceDetailsListItemTitle;
