import { useCallback } from 'react';
import useFeatures from 'hooks/useFeatures';
import { Property, PropertyBase } from 'models/Properties';
import { getPropertyEditPath } from 'utils/property/propertyUtils';

const usePropertyEditPath = () => {
  const { allowPropertyEditV3Beta } = useFeatures();

  return useCallback(
    (property: Partial<Property> | PropertyBase) => {
      if (allowPropertyEditV3Beta) {
        return `#/property/${property.uid}`;
      }

      return getPropertyEditPath(property);
    },
    [allowPropertyEditV3Beta],
  );
};

export default usePropertyEditPath;
