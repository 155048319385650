import { useLayoutEffect, useState } from 'react';
import { debounce } from 'utils/funcs';

const mobileWidth = 576;

const useIsMobileScreen = (width = mobileWidth) => {
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.innerWidth < width,
  );

  useLayoutEffect(() => {
    const updateSize = () => {
      setIsMobileScreen(window.innerWidth < width);
    };
    window.addEventListener('resize', debounce(updateSize));
  }, [width]);

  return isMobileScreen;
};

export default useIsMobileScreen;
