import { Currency } from './Currency';
import { BaseResponse } from './_base';

export enum PropertyBusinessType {
  HOTEL = 'HOTEL',
  UNIT_TYPE = 'UNIT_TYPE',
  UNIT = 'UNIT',
  STANDALONE_PROPERTY = 'STANDALONE_PROPERTY',
  SUB_UNIT = 'SUB_UNIT',
}

export const PROPERTIES_SORTING_STRATEGY = {
  SORT_BY_CREATION_DATE_DESC: 'SORT_BY_CREATION_DATE_DESC',
  SORT_BY_NAME: 'SORT_BY_NAME',
} as const;

export type PropertiesSortingStrategy =
  (typeof PROPERTIES_SORTING_STRATEGY)[keyof typeof PROPERTIES_SORTING_STRATEGY];

export const PROPERTY_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export type PropertyStatus =
  (typeof PROPERTY_STATUS)[keyof typeof PROPERTY_STATUS];

export interface PropertyUnit {
  uid: string;
  name: string;
  isActive?: boolean;
}

export interface PropertyUnitType {
  uid: string;
  name: string;
  isActive?: boolean;
  mainPicture: {
    tinyThumbnail: string;
  };
  availability: {
    maxGuests: number;
  };
  businessType: PropertyBusinessType;
  hotelUid: string;
  units: PropertyUnit[];
  bathroomsNumber?: string;
  bedroomsNumber?: number;
  tags?: string[];
}

export interface PropertySubUnit {
  uid: string;
  name: string;
  bathroomsNumber?: string;
  bedroomsNumber?: number;
  isActive?: boolean;
  mainPicture: {
    tinyThumbnail: string;
    largeThumbnail: string;
  };
  availability: {
    maxGuests: number;
  };
  businessType: PropertyBusinessType;
  propertyType: string;
  masterUnitUid: string;
}

export interface PropertyBase {
  availability: {
    maxGuests: number;
    checkInTimeStart?: number;
    checkOutTime?: number;
  };
  zipCode: string;
  country: string;
  city: string;
  weekEndRatePercentAdjustment: number;
  latitude: number;
  description: string;
  type: string;
  isActive: boolean;
  deleteDisabled: false;
  uid: string;
  masterID: number;
  maximumGuests: number;
  cleaningFeeAmount: number;
  defaultCheckoutTime: number;
  countryCode: string;
  securityDepositAmount: number;
  id: number;
  state: string;
  baseGuests: number;
  floor: number;
  availabilityCalendarUrl: string;
  areaSize: number;
  defaultCheckinTime: number;
  bedCount: number;
  minimumStay: number;
  longitude: number;
  airbnbSupportsCity: true;
  address1: string;
  currencySymbol: string;
  bathrooms: string;
  picture: string;
  bedrooms: number;
  acceptInstantBook: false;
  extraGuestFee: number;
  areaSizeUnit: string;
  name: string;
  taxationRate: number;
  baseDailyRate: number;
  masterUnitUid?: string;
  subUnits: PropertySubUnit[];
  unitTypes?: PropertyUnitType[];
  tags: string[];
  units: PropertyUnit[];
  mainPicture?: {
    medium?: string;
    largeThumbnail?: string;
    tinyThumbnail?: string;
    __typename?: string;
  };
  businessType: PropertyBusinessType;
  numberOfSubUnits: number;
  pricing?: {
    currency: Currency | null;
    automatedPricingEnabled?: boolean;
    fullPaymentTiming?: number;
  };
  propertyType: string;
  address?: {
    address?: string;
    address2?: string;
    zipCode?: string;
    city?: string;
    state?: string;
  };
  bathroomsNumber?: string;
  bedroomsNumber?: number;
  webLink?: string;
  isDeleted?: boolean;
  ownerNotes?: string;
  directBookingSiteUrl?: string;
  extraNotes?: string;
}

export type Property = Pick<
  PropertyBase,
  | 'uid'
  | 'name'
  | 'isActive'
  | 'picture'
  | 'businessType'
  | 'propertyType'
  | 'masterUnitUid'
  | 'availability'
  | 'numberOfSubUnits'
  | 'unitTypes'
  | 'subUnits'
  | 'pricing'
  | 'extraNotes'
  | 'units'
> & {
  mainPicture: Pick<
    PropertyBase['mainPicture'],
    // @ts-expect-error TS2344 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    'tinyThumbnail' | 'largeThumbnail'
  >;
};

export interface PropertyAvailability {
  available: boolean;
  startDate: string;
  endDate: string;
  blockingLeadUids: string[];
}

export interface CheckPropertyAvailabilityResponseTO {
  propertyAvailability?: PropertyAvailability;
}

export interface GetPropertiesResponseTO extends BaseResponse {
  data: {
    properties: Property[];
  };
}

export type PropertyByUid = Pick<
  PropertyBase,
  | 'uid'
  | 'name'
  | 'address'
  | 'propertyType'
  | 'businessType'
  | 'isActive'
  | 'webLink'
  | 'bedrooms'
  | 'bathrooms'
  | 'availability'
  | 'pricing'
  | 'ownerNotes'
  | 'units'
> & {
  airbnbData?: {
    activeForAgency: boolean;
    active: boolean;
    airbnbId: string | null;
    instantBookingGuestRequirement: null;
    checkInOptionType: string;
    cancellationPolicy: string;
    eligibleForPassThroughTaxes: boolean;
    allowBookingRequestAsFallback: null;
    nonRefundableRateEnabled: boolean;
    nonRefundableRateDiscount: number;
    flexibleCheckIn: null;
    deactivationData?: {
      reason: string;
      details: string;
    };
  };
  showPropertyExactLocation: boolean;
  integrationsData?: {
    panoramicDataUrl: string;
  };
  // TODO add remaining fields returned by the /v3/properties/{uid} endpoint
};

export interface GetPropertyByUidResponseTO {
  property: PropertyByUid;
}

export interface AppliesToMetadata {
  metadata: {
    appliesToAllProperties: boolean;
    propertiesThatAppliesTo: {
      uid: Property['uid'];
      name: Property['name'];
    }[];
  };
}
