import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import { Channel } from 'models/Channels';
import {
  AirbnbFeeType,
  AirbnbFeeUnitType,
  BookingDotComFeeType,
  FeeConditionEnum,
  FeeScopeEnum,
  FeeTypeEnum,
  HVMIFeeType,
  VrboFeeType,
} from 'models/PropertyFees';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFormData from './useFormData';

export const BY_LENGTH_OF_STAY_SCOPE_OPTION = 'BY_LENGTH_OF_STAY';

const useFieldsOptions = () => {
  const { t } = useTranslation();
  const { channelSettings } = useFetchAgencyChannelSettings();
  const { scopeValue, isFeeTypeAmount, isTypeFee } = useFormData();

  const airbnbMappingType = useWatch({ name: 'airbnbMappingType' });

  const isScopePetRelated =
    scopeValue === FeeScopeEnum.PER_PET_PER_NIGHT ||
    scopeValue === FeeScopeEnum.PER_PET;

  const isAirbnbMappingTypeWithUnit = [
    AirbnbFeeType.PASS_THROUGH_AIR_CONDITIONING_FEE,
    AirbnbFeeType.PASS_THROUGH_ELECTRICITY_FEE,
    AirbnbFeeType.PASS_THROUGH_HEATING_FEE,
    AirbnbFeeType.PASS_THROUGH_UTILITY_FEE,
    AirbnbFeeType.PASS_THROUGH_WATER_FEE,
  ].includes(airbnbMappingType);

  const isAirbnbActive = channelSettings?.some(
    ({ enumId, isActive }) => enumId === Channel.airbnb && isActive,
  );

  const isAirbnbUnitTypeVisible =
    isAirbnbActive &&
    isAirbnbMappingTypeWithUnit &&
    isTypeFee &&
    isFeeTypeAmount;

  const getAirbnbUnitTypeOptions = () => {
    switch (airbnbMappingType) {
      case AirbnbFeeType.PASS_THROUGH_AIR_CONDITIONING_FEE:
        return [
          AirbnbFeeUnitType.PER_KILOWATT_HOUR,
          AirbnbFeeUnitType.PER_LITER,
          AirbnbFeeUnitType.PER_CUBIC_METER,
        ];

      case AirbnbFeeType.PASS_THROUGH_ELECTRICITY_FEE:
        return [AirbnbFeeUnitType.PER_KILOWATT_HOUR];

      case AirbnbFeeType.PASS_THROUGH_HEATING_FEE:
        return [AirbnbFeeUnitType.PER_KILOWATT_HOUR];

      case AirbnbFeeType.PASS_THROUGH_UTILITY_FEE:
        return [
          AirbnbFeeUnitType.PER_KILOWATT_HOUR,
          AirbnbFeeUnitType.PER_LITER,
          AirbnbFeeUnitType.PER_CUBIC_METER,
        ];

      case AirbnbFeeType.PASS_THROUGH_WATER_FEE:
        return [AirbnbFeeUnitType.PER_LITER, AirbnbFeeUnitType.PER_CUBIC_METER];

      default:
        return [];
    }
  };

  const AIRBNB_OPTIONS = isScopePetRelated
    ? [AirbnbFeeType.PASS_THROUGH_PET_FEE]
    : Object.values(AirbnbFeeType);
  const AIRBNB_UNIT_TYPE_OPTIONS = getAirbnbUnitTypeOptions();
  const BOOKING_OPTIONS = Object.values(BookingDotComFeeType);
  const HVMB_OPTIONS = Object.values(HVMIFeeType);
  const VRBO_OPTIONS = isScopePetRelated
    ? [VrboFeeType.PET]
    : Object.values(VrboFeeType);

  const typeOptions = [
    {
      value: FeeTypeEnum.CUSTOM,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.fee',
      ),
    },
    {
      value: FeeTypeEnum.TAX,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.tax',
      ),
    },
  ];

  const conditionTypeOptions = [
    {
      value: FeeConditionEnum.NONE,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.none',
      ),
    },
    {
      value: BY_LENGTH_OF_STAY_SCOPE_OPTION,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.byLengthOfStay',
      ),
    },
  ];

  const conditionByLengthOfStayOptions = [
    {
      value: FeeConditionEnum.EXEMPT_LONG_STAY,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.exempt',
      ),
    },
    {
      value: FeeConditionEnum.EXCLUSIVE_LONG_STAY,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.onlyApplies',
      ),
    },
  ];

  const amountTypeScopeOptions = [
    {
      value: FeeScopeEnum.PER_STAY,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_STAY',
      ),
    },
    {
      value: FeeScopeEnum.PER_NIGHT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_NIGHT',
      ),
    },
    {
      value: FeeScopeEnum.PER_GUEST,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_GUEST',
      ),
    },
    {
      value: FeeScopeEnum.PER_GUEST_PER_NIGHT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_GUEST_PER_NIGHT',
      ),
    },
    {
      value: FeeScopeEnum.PER_PET,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_PET',
      ),
    },
    {
      value: FeeScopeEnum.PER_PET_PER_NIGHT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_PET_PER_NIGHT',
      ),
    },
  ];

  const percentageScopeOptions = [
    {
      value: FeeScopeEnum.PERCENTAGE_OF_RENT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PERCENTAGE_OF_RENT',
      ),
    },
    {
      value: FeeScopeEnum.PERCENTAGE_OF_TOTAL,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PERCENTAGE_OF_TOTAL',
      ),
    },
  ];

  const amountScopeOptions = isAirbnbActive
    ? amountTypeScopeOptions
    : amountTypeScopeOptions;

  return {
    AIRBNB_OPTIONS,
    AIRBNB_UNIT_TYPE_OPTIONS,
    BOOKING_OPTIONS,
    HVMB_OPTIONS,
    VRBO_OPTIONS,
    typeOptions,
    amountScopeOptions,
    percentageScopeOptions,
    conditionTypeOptions,
    conditionByLengthOfStayOptions,
    isAirbnbUnitTypeVisible,
  };
};

export default useFieldsOptions;
