import useFetchAssignmentSettingsWithEmployees from 'components/domain/property/useFetchAssignmentSettingsWithEmployees';
import { PropertyBase } from 'models/Properties';
import {
  PropertyItemPermissionsEmployeeListItemStyled,
  PropertyItemPermissionsEmployeeListStyled,
} from './PropertyItemPermissions.styles';
import PropertyItemPermissionsEmployeeAvatar from './PropertyItemPermissionsEmployeeAvatar';
import PropertyItemPermissionsUsersListPlaceholder from './PropertyItemPermissionsEmployeeListPlaceholder';
import { getSortedAssignees } from './PropertyItemPermissions.utils';
import PropertyItemPermissionsExtraAssignees from './PropertyItemPermissionsExtraAssignees';

interface PropertyItemPermissionsUsersListProps {
  property: Pick<PropertyBase, 'uid'>;
  onClick: () => void;
}

const PropertyItemPermissionsUsersList = ({
  property,
  onClick,
}: PropertyItemPermissionsUsersListProps) => {
  const { data: assignmentSettings, isLoading } =
    useFetchAssignmentSettingsWithEmployees({ propertyUid: property.uid });

  if (isLoading) {
    return <PropertyItemPermissionsUsersListPlaceholder />;
  }

  if (!assignmentSettings?.assignees?.length) {
    return null;
  }

  const autoAssigneeUid = assignmentSettings.automaticAssignment.employeeUid;
  const enabledAssignees = assignmentSettings.assignees.filter(
    ({ enabled }) => enabled,
  );

  const sortedAssignees = getSortedAssignees({
    ...assignmentSettings,
    assignees: enabledAssignees,
  });

  const firstFiveOrLessAssignees = sortedAssignees.slice(0, 5);
  const remainingAssignees = sortedAssignees.slice(5);

  return (
    <PropertyItemPermissionsEmployeeListStyled onClick={onClick}>
      {firstFiveOrLessAssignees.map(({ employee, employeeUid }) => (
        <PropertyItemPermissionsEmployeeListItemStyled
          // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          className={employee.uid === autoAssigneeUid && 'is-auto-assignee'}
          key={employeeUid}
          data-testid="employee-avatar"
        >
          <PropertyItemPermissionsEmployeeAvatar
            employee={employee}
            propertyUid={property.uid}
            isAutoAssignee={employee.uid === autoAssigneeUid}
          />
        </PropertyItemPermissionsEmployeeListItemStyled>
      ))}

      {remainingAssignees?.length > 0 && (
        <PropertyItemPermissionsExtraAssignees
          assignees={remainingAssignees}
          propertyUid={property.uid}
        />
      )}
    </PropertyItemPermissionsEmployeeListStyled>
  );
};

export default PropertyItemPermissionsUsersList;
