import { DeepPartial } from 'react-hook-form';
import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { Agency } from 'models/Agency';
import { AreaUnitType } from 'models/AreaUnitType';
import { CountryCode } from 'models/Country';
import { CURRENCIES_WITHOUT_NONE, Currency } from 'models/Currency';
import { PropertyBusinessType } from 'models/Properties';
import { PropertyType } from 'models/PropertyType';
import {
  basePropertyAddressSchema,
  propertyCapacityDetailsSchema,
  propertyDetailsSchema,
} from '../../common/Common.schema';
import {
  CapacityDetails,
  PropertyAddress,
  PropertyDetails,
} from '../../mainSettings/PropertySettingsMainTab.types';
import {
  CreatePropertyMainSettingsPayload,
  PropertyPricingMainSettingsCreationPayload,
} from '../common/AddProperty.types';

export const addSinglePropertySchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.object({
    capacityDetails: propertyCapacityDetailsSchema({
      businessType: PropertyBusinessType.STANDALONE_PROPERTY,
      t,
    }),
    propertyAddress: basePropertyAddressSchema(
      PropertyBusinessType.STANDALONE_PROPERTY,
    ),
    propertyDetails: propertyDetailsSchema(
      PropertyBusinessType.STANDALONE_PROPERTY,
      t,
    ),
    propertyPricingFeesTaxes: Yup.object({
      cleaningFee: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      cleaningFeeTax: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
      currency: Yup.mixed<Currency>().oneOf(CURRENCIES_WITHOUT_NONE).required(),
      nightlyBasePrice: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .positive()
        .when('currency', { is: 'USD', then: Yup.number().min(10) })
        .required(),
      securityDeposit: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      taxRate: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
    }),
  });

export type AddSinglePropertyFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof addSinglePropertySchema>>
>;

export interface SinglePropertyCreationPayload
  extends CreatePropertyMainSettingsPayload {
  capacityDetails: Partial<CapacityDetails>;
  pricing: PropertyPricingMainSettingsCreationPayload;
  propertyDetails: Partial<PropertyDetails>;
  propertyAddress: Partial<PropertyAddress>;
}

export function getDefaultFormValues(
  propertyTypes: PropertyType[],
  agency: Agency,
): AddSinglePropertyFormValues {
  const defaultCountryCode = agency?.countryCode ?? CountryCode.US;

  return {
    capacityDetails: {
      bathroomCount: '1',
      bedCount: 1,
      bedroomCount: 1,
      baseGuests: 1,
      extraGuestFee: 0,
      maxGuests: 1,
    },
    propertyAddress: {
      countryCode: defaultCountryCode,
      state: defaultCountryCode === CountryCode.US ? 'AL' : '',
    },
    propertyDetails: {
      propertySizeUnit: AreaUnitType.SQUARE_METERS,
      propertyType:
        propertyTypes?.find(({ type }) => type === 'HOUSE')?.type ||
        propertyTypes?.[0]?.type,
    },
    propertyPricingFeesTaxes: {
      cleaningFee: 0,
      cleaningFeeTax: 0,
      currency: agency?.currencyCode ?? 'USD',
      nightlyBasePrice: 0,
      securityDeposit: 0,
      taxRate: 0,
    },
  };
}
