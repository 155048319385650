import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetStatusResponseTO } from 'models/bookingDotCom/Status';
import API from 'services/API';

export const STATUS_BASE_QUERY_KEY = 'booking-dot-com-status';

type ReactQueryOptions = Omit<UseQueryOptions<string>, 'queryKey' | 'queryFn'>;

type UseFetchBookingDotComStatusArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchBookingDotComStatus = ({
  propertyUid,
  enabled,
  ...options
}: UseFetchBookingDotComStatusArgs) => {
  return useAppQuery({
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    queryKey: [STATUS_BASE_QUERY_KEY, 'detail', propertyUid],
    queryFn: async () => {
      const response = await API.get<GetStatusResponseTO>(
        '/api/internal/bookingdotcom-operations/property-status',
        { params: { uid: propertyUid } },
      );

      return response.data.status;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchBookingDotComStatus;
