import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchAirbnbResolutions from '../../../../components/domain/airbnb/useFetchAirbnbResolutions';
import Loader from '../../../../components/loader/Loader';
import useFetchLeads from '../../../../components/domain/lead/useFetchLeads';
import ResolutionsListItem from './ResolutionsListItem';
import {
  BookingColumn,
  DescriptionColumn,
  NoResolutionsInfo,
  ResolutionColumn,
  ResolutionRow,
  ResolutionsListWrapper,
  StatusColumn,
} from './Resolutions.styles';
import ResolutionsContext from './ResolutionsContext';
import { ResolutionData } from './Resolutions.types';

const ResolutionsList = () => {
  const { t } = useTranslation();

  const { status } = useContext(ResolutionsContext);
  const { resolutions, isFetching: isFetchingResolutions } =
    useFetchAirbnbResolutions(status);
  const leadUids = resolutions?.map(({ leadUid }) => leadUid) || [];
  const conditions = leadUids.length
    ? `uids:[${leadUids.map((leadUid) => `"${leadUid}"`).join(',')}]`
    : '';
  const fields = `uid,
          firstName,
          lastName,
          checkInLocalDateTime,
          checkOutLocalDateTime,
          property{uid, name, mainPicture{largeThumbnail}},`;

  const { leads, isFetching: isFetchingLead } = useFetchLeads({
    conditions,
    fields,
  });

  const leadsByUid =
    leads?.reduce((map, lead) => {
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      map[lead.uid] = lead;
      return map;
    }, {}) || {};

  const resolutionsData: ResolutionData[] =
    resolutions?.map((resolution) => ({
      resolution,
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      lead: leadsByUid[resolution.leadUid],
    })) || [];

  if (isFetchingResolutions || isFetchingLead) {
    return <Loader />;
  }

  return (
    <ResolutionsListWrapper>
      <ResolutionRow className="resolutions-header">
        <ResolutionColumn>
          {t('pageAirbnbResolutions.pageAirbnbResolutions.resolution')}
        </ResolutionColumn>
        <BookingColumn>
          {t('pageAirbnbResolutions.pageAirbnbResolutions.booking')}
        </BookingColumn>
        <StatusColumn>
          {t('pageAirbnbResolutions.pageAirbnbResolutions.status')}
        </StatusColumn>
        <DescriptionColumn>
          {t('pageAirbnbResolutions.pageAirbnbResolutions.description')}
        </DescriptionColumn>
      </ResolutionRow>
      {resolutionsData.length === 0 && (
        <NoResolutionsInfo>No items</NoResolutionsInfo>
      )}
      {resolutionsData.map((resolutionData) => (
        <ResolutionsListItem
          key={resolutionData.resolution.id}
          resolutionData={resolutionData}
        />
      ))}
    </ResolutionsListWrapper>
  );
};

export default ResolutionsList;
