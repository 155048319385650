import { BasePagingResponse, BaseResponse } from './_base';

export interface ReviewByHost {
  cleanlinessRating: number;
  communicationRating: number;
  leadUid: string;
  notes: string;
  overallRating: number;
  respectRulesRating: number;
  uid: string;
}

export interface GetReviewByHostResponseTO {
  review: ReviewByHost;
}

export interface DirectBookingReview {
  uid: string;
  author: string;
  title: string;
  content: string;
  rating: number;
  date: string;
  propertyUid: string;
  leadUid: string;
  source: string;
  privateFeedback: string;
  reviewResponse: {
    content: string;
    author: string;
    responseDateTimeUTC: string;
  };
  ratingCategories: {
    category: string;
    rate: number;
    comment: string;
  }[];
  updatedUtcDateTime: string;
}

export interface GetDirectBookingReviewsResponse extends BaseResponse {
  reviews: DirectBookingReview[];
}

export enum AirbnbRatingCategory {
  ACCURACY = 'ACCURACY',
  CHECKIN = 'CHECKIN',
  CLEANLINESS = 'CLEANLINESS',
  COMMUNICATION = 'COMMUNICATION',
  LOCATION = 'LOCATION',
  VALUE = 'VALUE',
}

export interface AirbnbCategoryStatistic {
  category: AirbnbRatingCategory;
  rating: number;
  count: number;
}

export interface AirbnbStarRating {
  starRating: number;
  count: number;
}

export interface AirbnbRatingStats {
  uid: string;
  objectId: string;
  objectType: string;
  rating: number;
  count: number;
  categoryStatistics: AirbnbCategoryStatistic[];
  starRatings: AirbnbStarRating[];
  reviewUidsWithIssues: string[];
}

export interface GetAirbnbRatingStatsResponse extends AirbnbRatingStats {}

export enum AirbnbReviewAction {
  RESPOND = 'RESPOND',
}

export enum AirbnbGuestReviewCategoryTags {
  HOST_REVIEW_GUEST_POSITIVE_NEAT_AND_TIDY = 'HOST_REVIEW_GUEST_POSITIVE_NEAT_AND_TIDY',
  HOST_REVIEW_GUEST_POSITIVE_KEPT_IN_GOOD_CONDITION = 'HOST_REVIEW_GUEST_POSITIVE_KEPT_IN_GOOD_CONDITION',
  HOST_REVIEW_GUEST_POSITIVE_TOOK_CARE_OF_GARBAGE = 'HOST_REVIEW_GUEST_POSITIVE_TOOK_CARE_OF_GARBAGE',
  HOST_REVIEW_GUEST_NEGATIVE_IGNORED_CHECKOUT_DIRECTIONS = 'HOST_REVIEW_GUEST_NEGATIVE_IGNORED_CHECKOUT_DIRECTIONS',
  HOST_REVIEW_GUEST_NEGATIVE_GARBAGE = 'HOST_REVIEW_GUEST_NEGATIVE_GARBAGE',
  HOST_REVIEW_GUEST_NEGATIVE_MESSY_KITCHEN = 'HOST_REVIEW_GUEST_NEGATIVE_MESSY_KITCHEN',
  HOST_REVIEW_GUEST_NEGATIVE_DAMAGE = 'HOST_REVIEW_GUEST_NEGATIVE_DAMAGE',
  HOST_REVIEW_GUEST_NEGATIVE_RUINED_BED_LINENS = 'HOST_REVIEW_GUEST_NEGATIVE_RUINED_BED_LINENS',
  HOST_REVIEW_GUEST_NEGATIVE_ARRIVED_EARLY = 'HOST_REVIEW_GUEST_NEGATIVE_ARRIVED_EARLY',
  HOST_REVIEW_GUEST_NEGATIVE_STAYED_PAST_CHECKOUT = 'HOST_REVIEW_GUEST_NEGATIVE_STAYED_PAST_CHECKOUT',
  HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_GUESTS = 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_GUESTS',
  HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_PET = 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_PET',
  HOST_REVIEW_GUEST_NEGATIVE_DID_NOT_RESPECT_QUIET_HOURS = 'HOST_REVIEW_GUEST_NEGATIVE_DID_NOT_RESPECT_QUIET_HOURS',
  HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_FILMING = 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_FILMING',
  HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_EVENT = 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_EVENT',
  HOST_REVIEW_GUEST_NEGATIVE_SMOKING = 'HOST_REVIEW_GUEST_NEGATIVE_SMOKING',
  HOST_REVIEW_GUEST_POSITIVE_HELPFUL_MESSAGES = 'HOST_REVIEW_GUEST_POSITIVE_HELPFUL_MESSAGES',
  HOST_REVIEW_GUEST_POSITIVE_RESPECTFUL = 'HOST_REVIEW_GUEST_POSITIVE_RESPECTFUL',
  HOST_REVIEW_GUEST_POSITIVE_ALWAYS_RESPONDED = 'HOST_REVIEW_GUEST_POSITIVE_ALWAYS_RESPONDED',
  HOST_REVIEW_GUEST_NEGATIVE_UNHELPFUL_MESSAGES = 'HOST_REVIEW_GUEST_NEGATIVE_UNHELPFUL_MESSAGES',
  HOST_REVIEW_GUEST_NEGATIVE_DISRESPECTFUL = 'HOST_REVIEW_GUEST_NEGATIVE_DISRESPECTFUL',
  HOST_REVIEW_GUEST_NEGATIVE_UNREACHABLE = 'HOST_REVIEW_GUEST_NEGATIVE_UNREACHABLE',
  HOST_REVIEW_GUEST_NEGATIVE_SLOW_RESPONSES = 'HOST_REVIEW_GUEST_NEGATIVE_SLOW_RESPONSES',
  GUEST_REVIEW_HOST_POSITIVE_LOOKED_LIKE_PHOTOS = 'GUEST_REVIEW_HOST_POSITIVE_LOOKED_LIKE_PHOTOS',
  GUEST_REVIEW_HOST_POSITIVE_MATCHED_DESCRIPTION = 'GUEST_REVIEW_HOST_POSITIVE_MATCHED_DESCRIPTION',
  GUEST_REVIEW_HOST_POSITIVE_HAD_LISTED_AMENITIES_AND_SERVICES = 'GUEST_REVIEW_HOST_POSITIVE_HAD_LISTED_AMENITIES_AND_SERVICES',
  GUEST_REVIEW_HOST_NEGATIVE_SMALLER_THAN_EXPECTED = 'GUEST_REVIEW_HOST_NEGATIVE_SMALLER_THAN_EXPECTED',
  GUEST_REVIEW_HOST_NEGATIVE_DID_NOT_MATCH_PHOTOS = 'GUEST_REVIEW_HOST_NEGATIVE_DID_NOT_MATCH_PHOTOS',
  GUEST_REVIEW_HOST_NEGATIVE_NEEDS_MAINTENANCE = 'GUEST_REVIEW_HOST_NEGATIVE_NEEDS_MAINTENANCE',
  GUEST_REVIEW_HOST_NEGATIVE_UNEXPECTED_FEES = 'GUEST_REVIEW_HOST_NEGATIVE_UNEXPECTED_FEES',
  GUEST_REVIEW_HOST_NEGATIVE_EXCESSIVE_RULES = 'GUEST_REVIEW_HOST_NEGATIVE_EXCESSIVE_RULES',
  GUEST_REVIEW_HOST_NEGATIVE_UNEXPECTED_NOISE = 'GUEST_REVIEW_HOST_NEGATIVE_UNEXPECTED_NOISE',
  GUEST_REVIEW_HOST_NEGATIVE_INACCURATE_LOCATION = 'GUEST_REVIEW_HOST_NEGATIVE_INACCURATE_LOCATION',
  GUEST_REVIEW_HOST_NEGATIVE_MISSING_AMENITY = 'GUEST_REVIEW_HOST_NEGATIVE_MISSING_AMENITY',
  GUEST_REVIEW_HOST_POSITIVE_RESPONSIVE_HOST = 'GUEST_REVIEW_HOST_POSITIVE_RESPONSIVE_HOST',
  GUEST_REVIEW_HOST_POSITIVE_CLEAR_INSTRUCTIONS = 'GUEST_REVIEW_HOST_POSITIVE_CLEAR_INSTRUCTIONS',
  GUEST_REVIEW_HOST_POSITIVE_EASY_TO_FIND = 'GUEST_REVIEW_HOST_POSITIVE_EASY_TO_FIND',
  GUEST_REVIEW_HOST_POSITIVE_EASY_TO_GET_INSIDE = 'GUEST_REVIEW_HOST_POSITIVE_EASY_TO_GET_INSIDE',
  GUEST_REVIEW_HOST_POSITIVE_FLEXIBLE_CHECK_IN = 'GUEST_REVIEW_HOST_POSITIVE_FLEXIBLE_CHECK_IN',
  GUEST_REVIEW_HOST_NEGATIVE_HARD_TO_LOCATE = 'GUEST_REVIEW_HOST_NEGATIVE_HARD_TO_LOCATE',
  GUEST_REVIEW_HOST_NEGATIVE_UNCLEAR_INSTRUCTIONS = 'GUEST_REVIEW_HOST_NEGATIVE_UNCLEAR_INSTRUCTIONS',
  GUEST_REVIEW_HOST_NEGATIVE_TROUBLE_WITH_LOCK = 'GUEST_REVIEW_HOST_NEGATIVE_TROUBLE_WITH_LOCK',
  GUEST_REVIEW_HOST_NEGATIVE_UNRESPONSIVE_HOST = 'GUEST_REVIEW_HOST_NEGATIVE_UNRESPONSIVE_HOST',
  GUEST_REVIEW_HOST_NEGATIVE_HAD_TO_WAIT = 'GUEST_REVIEW_HOST_NEGATIVE_HAD_TO_WAIT',
  GUEST_REVIEW_HOST_NEGATIVE_HARD_TO_GET_INSIDE = 'GUEST_REVIEW_HOST_NEGATIVE_HARD_TO_GET_INSIDE',
  GUEST_REVIEW_HOST_POSITIVE_FELT_AT_HOME = 'GUEST_REVIEW_HOST_POSITIVE_FELT_AT_HOME',
  GUEST_REVIEW_HOST_POSITIVE_SPOTLESS_FURNITURE_AND_LINENS = 'GUEST_REVIEW_HOST_POSITIVE_SPOTLESS_FURNITURE_AND_LINENS',
  GUEST_REVIEW_HOST_POSITIVE_FREE_OF_CLUTTER = 'GUEST_REVIEW_HOST_POSITIVE_FREE_OF_CLUTTER',
  GUEST_REVIEW_HOST_POSITIVE_SQUEAKY_CLEAN_BATHROOM = 'GUEST_REVIEW_HOST_POSITIVE_SQUEAKY_CLEAN_BATHROOM',
  GUEST_REVIEW_HOST_POSITIVE_PRISTINE_KITCHEN = 'GUEST_REVIEW_HOST_POSITIVE_PRISTINE_KITCHEN',
  GUEST_REVIEW_HOST_NEGATIVE_DIRTY_OR_DUSTY = 'GUEST_REVIEW_HOST_NEGATIVE_DIRTY_OR_DUSTY',
  GUEST_REVIEW_HOST_NEGATIVE_NOTICEABLE_SMELL = 'GUEST_REVIEW_HOST_NEGATIVE_NOTICEABLE_SMELL',
  GUEST_REVIEW_HOST_NEGATIVE_STAINS = 'GUEST_REVIEW_HOST_NEGATIVE_STAINS',
  GUEST_REVIEW_HOST_NEGATIVE_EXCESSIVE_CLUTTER = 'GUEST_REVIEW_HOST_NEGATIVE_EXCESSIVE_CLUTTER',
  GUEST_REVIEW_HOST_NEGATIVE_MESSY_KITCHEN = 'GUEST_REVIEW_HOST_NEGATIVE_MESSY_KITCHEN',
  GUEST_REVIEW_HOST_NEGATIVE_HAIR_OR_PET_HAIR = 'GUEST_REVIEW_HOST_NEGATIVE_HAIR_OR_PET_HAIR',
  GUEST_REVIEW_HOST_NEGATIVE_DIRTY_BATHROOM = 'GUEST_REVIEW_HOST_NEGATIVE_DIRTY_BATHROOM',
  GUEST_REVIEW_HOST_NEGATIVE_TRASH_LEFT_BEHIND = 'GUEST_REVIEW_HOST_NEGATIVE_TRASH_LEFT_BEHIND',
  GUEST_REVIEW_HOST_NEGATIVE_BROKEN_OR_MISSING_LOCK = 'GUEST_REVIEW_HOST_NEGATIVE_BROKEN_OR_MISSING_LOCK',
  GUEST_REVIEW_HOST_NEGATIVE_UNEXPECTED_GUESTS = 'GUEST_REVIEW_HOST_NEGATIVE_UNEXPECTED_GUESTS',
  GUEST_REVIEW_HOST_NEGATIVE_INCORRECT_BATHROOM = 'GUEST_REVIEW_HOST_NEGATIVE_INCORRECT_BATHROOM',
  GUEST_REVIEW_HOST_POSITIVE_ALWAYS_RESPONSIVE = 'GUEST_REVIEW_HOST_POSITIVE_ALWAYS_RESPONSIVE',
  GUEST_REVIEW_HOST_POSITIVE_LOCAL_RECOMMENDATIONS = 'GUEST_REVIEW_HOST_POSITIVE_LOCAL_RECOMMENDATIONS',
  GUEST_REVIEW_HOST_POSITIVE_PROACTIVE = 'GUEST_REVIEW_HOST_POSITIVE_PROACTIVE',
  GUEST_REVIEW_HOST_POSITIVE_HELPFUL_INSTRUCTIONS = 'GUEST_REVIEW_HOST_POSITIVE_HELPFUL_INSTRUCTIONS',
  GUEST_REVIEW_HOST_POSITIVE_CONSIDERATE = 'GUEST_REVIEW_HOST_POSITIVE_CONSIDERATE',
  GUEST_REVIEW_HOST_NEGATIVE_SLOW_TO_RESPOND = 'GUEST_REVIEW_HOST_NEGATIVE_SLOW_TO_RESPOND',
  GUEST_REVIEW_HOST_NEGATIVE_NOT_HELPFUL = 'GUEST_REVIEW_HOST_NEGATIVE_NOT_HELPFUL',
  GUEST_REVIEW_HOST_NEGATIVE_MISSING_HOUSE_INSTRUCTIONS = 'GUEST_REVIEW_HOST_NEGATIVE_MISSING_HOUSE_INSTRUCTIONS',
  GUEST_REVIEW_HOST_NEGATIVE_UNCLEAR_CHECKOUT_TASKS = 'GUEST_REVIEW_HOST_NEGATIVE_UNCLEAR_CHECKOUT_TASKS',
  GUEST_REVIEW_HOST_NEGATIVE_INCONSIDERATE = 'GUEST_REVIEW_HOST_NEGATIVE_INCONSIDERATE',
  GUEST_REVIEW_HOST_NEGATIVE_EXCESSIVE_CHECKOUT_TASKS = 'GUEST_REVIEW_HOST_NEGATIVE_EXCESSIVE_CHECKOUT_TASKS',
  GUEST_REVIEW_HOST_POSITIVE_PEACEFUL = 'GUEST_REVIEW_HOST_POSITIVE_PEACEFUL',
  GUEST_REVIEW_HOST_POSITIVE_BEAUTIFUL_SURROUNDINGS = 'GUEST_REVIEW_HOST_POSITIVE_BEAUTIFUL_SURROUNDINGS',
  GUEST_REVIEW_HOST_POSITIVE_PRIVATE = 'GUEST_REVIEW_HOST_POSITIVE_PRIVATE',
  GUEST_REVIEW_HOST_POSITIVE_GREAT_RESTAURANTS = 'GUEST_REVIEW_HOST_POSITIVE_GREAT_RESTAURANTS',
  GUEST_REVIEW_HOST_POSITIVE_LOTS_TO_DO = 'GUEST_REVIEW_HOST_POSITIVE_LOTS_TO_DO',
  GUEST_REVIEW_HOST_POSITIVE_WALKABLE = 'GUEST_REVIEW_HOST_POSITIVE_WALKABLE',
  GUEST_REVIEW_HOST_NEGATIVE_NOISY = 'GUEST_REVIEW_HOST_NEGATIVE_NOISY',
  GUEST_REVIEW_HOST_NEGATIVE_NOT_MUCH_TO_DO = 'GUEST_REVIEW_HOST_NEGATIVE_NOT_MUCH_TO_DO',
  GUEST_REVIEW_HOST_NEGATIVE_BLAND_SURROUNDINGS = 'GUEST_REVIEW_HOST_NEGATIVE_BLAND_SURROUNDINGS',
  GUEST_REVIEW_HOST_NEGATIVE_NOT_PRIVATE = 'GUEST_REVIEW_HOST_NEGATIVE_NOT_PRIVATE',
  GUEST_REVIEW_HOST_NEGATIVE_INCONVENIENT_LOCATION = 'GUEST_REVIEW_HOST_NEGATIVE_INCONVENIENT_LOCATION',
  ACCURACY_OTHER = 'ACCURACY_OTHER',
  CHECK_IN_OTHER = 'CHECK_IN_OTHER',
  CLEANLINESS_OTHER = 'CLEANLINESS_OTHER',
  COMMUNICATION_OTHER = 'COMMUNICATION_OTHER',
  LOCATION_OTHER = 'LOCATION_OTHER',
  RESPECT_HOUSE_RULES_OTHER = 'RESPECT_HOUSE_RULES_OTHER',
}

interface AirbnbGuestReviewResponse {
  content: string;
  author: string;
  responseDateTimeUtc: string;
}

export interface AirbnbGuestReviewRatingCategory {
  category: AirbnbRatingCategory;
  rate: number;
  comment: string | null;
  reviewCategoryTags: AirbnbGuestReviewCategoryTags[];
}

export interface AirbnbGuestReview {
  uid: string;
  creationDate: string;
  bookingDateCheckIn: string;
  bookingDateCheckOut: string;
  rating: number;
  leadUid: string;
  guestName: string;
  publicReview: string;
  privateNote: string;

  response: AirbnbGuestReviewResponse | null;
  ratingCategories: AirbnbGuestReviewRatingCategory[];
  actionsAvailable: AirbnbReviewAction[];
}

export interface GetAirbnbGuestReviewsResponse
  extends BaseResponse,
    BasePagingResponse {
  reviews: AirbnbGuestReview[];
}
