import { useTranslation } from 'react-i18next';
import { min as minDate, max as maxDate } from 'date-fns';
import { CheckboxOption } from 'components/forms/checkboxGroupField/CheckboxGroupField';
import { PropertiesDayCellDataMap } from 'pages/stackedCalendar/Calendar.types';
import {
  dayCellToWeekdaysAvailability,
  getChangedCheckboxes,
  mergeCheckboxes,
} from './PropertyPricingModalRestrictions.utils';
import {
  UseRestrictionsCheckboxesReturn,
  WeekDayAvailability,
  WeekDaysAvailability,
} from './PropertyPricingModalRestrictions.types';

const useRestrictionsCheckboxes = (
  allPropertiesEntries: PropertiesDayCellDataMap,
  propertiesUids: string[],
  selectionDates: { start: Date; end: Date },
): UseRestrictionsCheckboxesReturn => {
  const { t } = useTranslation();

  // swaps the selected dates (if needed) based on the earliest start date and
  // latest end date
  const start = minDate([selectionDates.start, selectionDates.end]);
  const end = maxDate([selectionDates.start, selectionDates.end]);

  const checkInCheckoutCheckboxes = Object.entries(allPropertiesEntries)
    .filter(([propertyUid]) => propertiesUids.includes(propertyUid))
    .reduce(
      // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (res, [, dayCellsObj]) => {
        const weekDays = dayCellToWeekdaysAvailability(dayCellsObj, start, end);

        const toCheckboxOptions =
          (checkInOrCheckout: 'checkIn' | 'checkout') =>
          ([weekDay, _weekDays]: [
            keyof WeekDaysAvailability,
            WeekDayAvailability,
          ]): CheckboxOption => {
            const isEmpty = !_weekDays[checkInOrCheckout].length;
            const isAllTrue = _weekDays[checkInOrCheckout].every(
              (b) => b === true,
            );
            const isAllFalse = _weekDays[checkInOrCheckout].every(
              (b) => b === false,
            );

            const label = t(`componentProperty.modal.form.weekdays.${weekDay}`);

            return {
              label,
              name: label,
              ...(!isEmpty && (isAllTrue || isAllFalse)
                ? { value: isAllTrue }
                : { indeterminate: true }),
            };
          };

        return {
          ...res,
          checkIn: Object.entries(weekDays)
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            .map(toCheckboxOptions('checkIn'))
            .map(mergeCheckboxes(res.checkIn)),
          checkout: Object.entries(weekDays)
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            .map(toCheckboxOptions('checkout'))
            .map(mergeCheckboxes(res.checkout)),
        };
      },
      { checkIn: [], checkout: [] },
    );

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return {
    ...checkInCheckoutCheckboxes,
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getChangedCheckboxes: getChangedCheckboxes(checkInCheckoutCheckboxes),
  };
};

export default useRestrictionsCheckboxes;
