import { useContext, useEffect, useState } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

import { useFormContext, useWatch } from 'react-hook-form';
import useAppUser from 'hooks/useAppUser';
import { format } from 'date-fns';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';
import {
  AlterationQuoteRequest,
  AlterationQuoteResponse,
} from './UpdateAirbnbAlterationModal.types';
import useAirbnbAlterationModalData from './useAirbnbAlterationModalData';

const useAirbnbAlterationModalUpdateQuoteData = () => {
  const { airbnbAlterationData } = useAirbnbAlterationModalData();
  const { agencyUid } = useAppUser();
  const { airbnbAlteration } = useWatch();
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);

  const { lead } = useContext(AirbnbAlterationRequestModalContext);
  const {
    formState: { isDirty, dirtyFields },
    setValue,
  } = useFormContext();

  const {
    startDate,
    endDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    numberOfPets,
    price,
    propertyUid,
  } = airbnbAlteration;

  const watchedFields = useWatch({
    name: [
      'airbnbAlteration.startDate',
      'airbnbAlteration.endDate',
      'airbnbAlteration.numberOfAdults',
      'airbnbAlteration.numberOfChildren',
      'airbnbAlteration.numberOfInfants',
      'airbnbAlteration.numberOfPets',
    ],
  });

  useEffect(() => {
    if (!dirtyFields?.airbnbAlteration) return;

    // Check if any field except price is dirty
    const isAnyFieldDirty = Object.keys(dirtyFields.airbnbAlteration).some(
      (field) => field !== 'price',
    );

    if (isAnyFieldDirty) {
      setValue('airbnbAlteration.price', '', { shouldDirty: false });
    }
  }, [watchedFields, dirtyFields, setValue]);

  const { data: updatedQuote, isFetching: isUpdatedQuoteLoading } = useAppQuery(
    [`get-updated-airbnb-alteration-quote`, airbnbAlteration],
    async () => {
      const response = await API.get<AlterationQuoteResponse>(
        'api/internal/airbnb/alterations/quote',
        {
          params: {
            leadUid: lead?.uid,
            numberOfAdults,
            numberOfChildren,
            numberOfInfants,
            numberOfPets,
            price: price || null,
            propertyUid,
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            airbnbQuote: true,
          } as AlterationQuoteRequest,
        },
      );
      return response.data;
    },
    {
      enabled: !!agencyUid && !!lead?.uid && isDirty && !airbnbAlterationData,
      onError: (error: any) => {
        setApiErrorMessage(error.response.data?.apiErrorMessage);
      },
      onSuccess: () => {
        setApiErrorMessage(null);
      },
    },
  );

  return {
    updatedQuote,
    isUpdatedQuoteLoading,
    isDirty,
    apiErrorMessage,
  };
};

export default useAirbnbAlterationModalUpdateQuoteData;
