import { useState } from 'react';
import {
  ColumnDef,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { QueryOptions, useQuery } from '@tanstack/react-query';
import { BaseResponse } from 'models/_base';
import { getTotalCount } from '../utils/pagination';
import useTable from './useTable';

export interface ExpectedQueryData<T> extends BaseResponse {
  data: T[];
}

interface UseServerFilteredTableArgs<
  TData,
  TQueryData extends ExpectedQueryData<TData> = ExpectedQueryData<TData>,
> {
  columns: ColumnDef<TData, any>[];
  getQuery: (arg: {
    pageIndex: number;
    pageSize: number;
    sorting: SortingState;
  }) => QueryOptions<TQueryData>;
  pageSize?: number;
}

const useServerFilteredTable = <
  TData,
  TQueryData extends ExpectedQueryData<TData> = ExpectedQueryData<TData>,
>({
  columns,
  getQuery,
  pageSize: pageSizeParam = 20,
}: UseServerFilteredTableArgs<TData, TQueryData>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPage] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSizeParam,
  });

  const { data, isFetching: isLoading } = useQuery({
    ...getQuery({ pageIndex, pageSize, sorting }),
    keepPreviousData: true,
  });

  // eslint-disable-next-line no-underscore-dangle
  const totalCount = getTotalCount(data?._metadata);

  const tableInstance = useTable({
    data: data?.data ?? [],
    columns,
    manualPagination: true,
    manualSorting: true,
    pageCount: totalCount ? Math.ceil(totalCount / pageSize) : -1,
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
    },
    onPaginationChange: setPage,
    onSortingChange: setSorting,
  });

  const isEmpty = !data?.data?.length;

  return { tableInstance, isLoading, isEmpty, columns, data };
};

export default useServerFilteredTable;
