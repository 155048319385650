import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import useFetchProperty from 'components/domain/property/useFetchProperty';
import useAppUser from 'hooks/useAppUser';
import getPropertiesQuery from 'utils/property/getPropertiesQuery';
import { API_MAX_LIMIT } from 'services/API.constants';
import { PROPERTIES_SORTING_STRATEGY, Property } from '../../models/Properties';

export const PROPERTY_SETTINGS_QUERY_OPERATION_NAME = 'PropertySettings';

const propertiesDropdownListGqlFields = `
  uid,
  name,
  businessType,
  subUnits{
    uid,
    name,
    businessType,
  },
  unitTypes{
    uid,
    hotelUid
    name,
    businessType,
    bathroomsNumber,
    bedroomsNumber,
    mainPicture{tinyThumbnail},
    availability{maxGuests},
    isActive,
    tags,
    units{
      uid,
      name
    }
  },
  units{
    uid,
    name,
  }`;

const propertySettingsGqlFields = `
  uid,
  isActive,
  pricing{
    currency
  }
`;

function findMatchingChildProperty(property: Property, propertyUid: string) {
  if (!property) {
    return null;
  }

  const { subUnits, unitTypes } = property;

  return (
    subUnits?.find(({ uid }) => uid === propertyUid) ||
    unitTypes?.find(({ uid }) => uid === propertyUid)
  );
}

const usePropertySettingsInitData = () => {
  const { isEmployee } = useAppUser();
  const { parentPropertyUid, uid: editedPropertyUid } = useParams();
  const propertyUid = editedPropertyUid || parentPropertyUid;

  const { allProperties, isFetching: isFetchingAllProperties } =
    useFetchAllProperties({
      conditions: getPropertiesQuery({
        filters: {
          offset: 0,
          limit: API_MAX_LIMIT,
          topLevelOnly: true,
          sortBy: PROPERTIES_SORTING_STRATEGY.SORT_BY_NAME,
        },
      }),
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      enabled: isEmployee && !!propertyUid,
      fields: propertiesDropdownListGqlFields,
      operationName: 'PropertySettingsAll',
    });

  const { property: fetchedProperty, isFetching: isFetchingPropertyDetails } =
    useFetchProperty({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUid,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      enabled: isEmployee && !!propertyUid,
      fields: propertySettingsGqlFields,
      operationName: PROPERTY_SETTINGS_QUERY_OPERATION_NAME,
    });

  const matchedProperty = useMemo(
    () =>
      allProperties?.find(
        ({ uid, subUnits, unitTypes }) =>
          uid === propertyUid ||
          subUnits?.find(({ uid: subUnitUid }) => subUnitUid === propertyUid) ||
          unitTypes?.find(
            ({ uid: unitTypeUid }) => unitTypeUid === propertyUid,
          ),
      ),
    [allProperties, propertyUid],
  );

  const propertyFromList =
    matchedProperty?.uid === propertyUid
      ? matchedProperty
      : // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        findMatchingChildProperty(matchedProperty, propertyUid);

  const property = {
    ...propertyFromList,
    ...fetchedProperty,
  };

  return {
    allProperties,
    isLoading: isFetchingAllProperties || isFetchingPropertyDetails,
    property,
  };
};

export default usePropertySettingsInitData;
