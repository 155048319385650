import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import useInvalidatePropertyPhotos from './useInvalidatePropertyPhotos';

export const DELETE_PHOTO_MUTATION_KEY = ['deletePhoto'];

const useDeletePhoto = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();
  const { openConfirmModal } = useAppModal();

  const { mutateAsync: deletePhoto } = useAppMutation(
    DELETE_PHOTO_MUTATION_KEY,
    ({ photoUid }: { photoUid: string }) => {
      return API.delete(`/api/v3.2/photos/${photoUid}`);
    },
  );

  const openDeletePhotoModal = ({
    photoUid,
    onDelete,
  }: {
    photoUid: string;
    onDelete?: () => void;
  }) => {
    openConfirmModal({
      title: t('pageProperty.photos.deleteModal.title'),
      body: t('pageProperty.photos.deleteModal.description'),
      onConfirm: () => {
        deletePhoto({ photoUid })
          .catch(() => {
            notifyError(t('pageProperty.photos.deleteModal.error'));
          })
          .finally(() => {
            invalidatePropertyPhotos();
          });
        onDelete?.();
      },
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return { openDeletePhotoModal, deletePhoto };
};

export default useDeletePhoto;
