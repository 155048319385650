import { useQueryClient } from '@tanstack/react-query';
import useBulkUpdatePropertyExpectations from 'components/domain/property/expectations/useBulkUpdatePropertyExpectations';
import { PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY } from 'components/domain/property/expectation/propertyExpectationQuery';
import useAppModal from 'hooks/useAppModal';
import { ObjectUidFetchType } from 'models/_base';
import { PropertyExpectationAddEditFormValues } from './PropertyExpectationAddEditModal.schema';

const usePropertyExpectationAddEditFormSubmit = ({
  modalId,
  propertyUid,
}: {
  modalId: string;
  propertyUid: string;
}) => {
  const { closeModal } = useAppModal();
  const { mutateAsync: bulkUpdatePropertyExpectations, isLoading: isUpdating } =
    useBulkUpdatePropertyExpectations();
  const queryClient = useQueryClient();

  const onSubmit = async (formValues: PropertyExpectationAddEditFormValues) => {
    const { propertyExpectation } = formValues;
    // const appliesToAll = appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    // const objectUids = appliesToAll
    //   ? undefined
    //   : appliesToProperties.map(({ value }) => value);

    const payload = {
      // objectUidFetchType: appliesToAll
      //   ? ObjectUidFetchType.ALL
      //   : ObjectUidFetchType.SPECIFIC,
      // objectUids,
      objectUids: [propertyUid],
      objectUidFetchType: ObjectUidFetchType.SPECIFIC,
      propertyExpectation,
    };

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    await bulkUpdatePropertyExpectations(payload).then(async () => {
      await queryClient
        .invalidateQueries([
          PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY,
          'list',
          propertyUid,
        ])
        .then(() => {
          closeModal(modalId);
        });
    });
  };

  return { isUpdating, onSubmit };
};

export default usePropertyExpectationAddEditFormSubmit;
