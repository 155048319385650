import { css } from 'styled-components';
import theme from 'styles/theme';
import { selectionLead } from 'pages/calendar/common/body/selection/CalendarBodySelection.constants';
import { getColorSource, getOpacity } from 'utils/lead/leadUtils';

export const DayCellSelectionStyles = css`
  .day-cell-selection-pricing-container {
    background-color: transparent;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 10;
    border: 2px solid ${theme.colors.hostfullyBlue};
  }

  .day-cell-selection-segment-container {
    background-color: #fff;
    height: 100%;
    position: absolute;
    top: 0;

    &.selection-end {
      left: 0;
      width: 17%;

      .day-cell-selection-segment {
        border-radius: 0 5px 5px 0;
        left: 0;
        right: auto;
        width: 100%;
      }
    }

    &.selection-ongoing {
      left: 0;
      width: 100%;

      .day-cell-selection-segment {
        border-radius: 0;
        width: 100%;
      }
    }

    &.selection-start {
      right: 0;
      width: 50%;

      .day-cell-selection-segment {
        border-radius: 5px 0 0 5px;
        left: auto;
        right: 0;
        width: 74%;
      }
    }

    &.selection-start_and_end {
      left: auto;
      right: auto;
      width: calc(100% - 10px);

      .day-cell-selection-segment {
        border-radius: 5px;
        left: auto;
        right: auto;
        width: 100%;
      }
    }

    .day-cell-selection-segment {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      background: ${getColorSource(null, selectionLead)};
      height: 30px;
      opacity: ${getOpacity(selectionLead.status)};
      position: absolute;
      top: 4px;
    }
  }
`;
