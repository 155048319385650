import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { FeeAmountTypeEnum, FeeTypeEnum } from 'models/PropertyFees';
import * as Yup from 'yup';
import { BY_LENGTH_OF_STAY_SCOPE_OPTION } from './useFieldsOptions';

export const propertyFeeSchema = Yup.object().shape({
  uid: Yup.string().nullable().optional(),
  name: Yup.string().required(),
  type: Yup.string().oneOf(Object.values(FeeTypeEnum)).required(),
  fee: Yup.object().when('type', {
    is: FeeTypeEnum.CUSTOM,
    then: Yup.object().shape({
      type: Yup.string().oneOf(Object.values(FeeAmountTypeEnum)).required(),
      value: Yup.number()
        .useNaNAsNull()
        .nullable()
        .required()
        .when(['type'], {
          is: FeeAmountTypeEnum.TAX,
          then: (schema) => schema.min(0).max(100),
          otherwise: (schema) => schema.min(0),
        }),
      taxationRate: Yup.number()
        .min(0)
        .max(100)
        .useNaNAsNull()
        .nullable()
        .required(),
    }),
    otherwise: Yup.object().shape({}),
  }),
  tax: Yup.object().when('type', {
    is: FeeTypeEnum.TAX,
    then: Yup.object().shape({
      value: Yup.number()
        .useNaNAsNull()
        .nullable()
        .required()
        .when(['type'], {
          is: FeeAmountTypeEnum.TAX,
          then: (schema) => schema.min(0).max(100),
          otherwise: (schema) => schema.min(0),
        }),
      conditions: Yup.object().shape({
        type: Yup.string().required(),
        lengthOfStayType: Yup.string().when(['type'], {
          is: BY_LENGTH_OF_STAY_SCOPE_OPTION,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.transform(() => undefined).optional(),
        }),
        lengthOfStayValue: Yup.number().when(['type'], {
          is: BY_LENGTH_OF_STAY_SCOPE_OPTION,
          then: (schema) => schema.min(1).required(),
          otherwise: (schema) => schema.transform(() => undefined).optional(),
        }),
      }),

      type: Yup.string().oneOf(Object.values(FeeAmountTypeEnum)).required(),
    }),
    otherwise: Yup.object().shape({}),
  }),
  scope: Yup.object().when(['type', 'fee', 'tax'], {
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    is: (type, fee, tax) =>
      type === FeeTypeEnum.TAX
        ? tax?.type === FeeAmountTypeEnum.TAX
        : fee?.type === FeeAmountTypeEnum.TAX,
    then: Yup.object().shape({
      tax: Yup.string().required(),
    }),
    otherwise: Yup.object().shape({
      amount: Yup.string().required(),
    }),
  }),
  appliesTo: Yup.string().oneOf(Object.values(PROPERTIES_SELECTION)).required(),
  appliesToProperties: Yup.array()
    .of(Yup.object({ label: Yup.string(), value: Yup.string() }))
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
  appliesToHostfully: Yup.string().oneOf(['YES', 'NO']).required(),
  rollsIntoRent: Yup.string().oneOf(['YES', 'NO']).required(),
  optional: Yup.boolean(),
  airbnbMappingType: Yup.string().nullable(),
  airbnbUnitMappingType: Yup.string().nullable(),
  bookingMappingType: Yup.string().nullable(),
  hvmbMappingType: Yup.string().nullable(),
  vrboMappingType: Yup.string().nullable(),
});

export type PropertyFeeFormSchemaType = Yup.InferType<typeof propertyFeeSchema>;
