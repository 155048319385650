/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useAppModal from 'hooks/useAppModal';
import Tooltip from 'components/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Channel, ChannelSettings } from 'models/Channels';
import PropertyChannelGearIcon from 'assets/images/icons/gear.svg?react';
import PropertyChannelPencilIcon from 'assets/images/icons/pencil.svg?react';
import PropertyChannelExternalLinkIcon from 'assets/images/icons/external-link.svg?react';
import PropertyChannelsWidgetEditLinkModal from './PropertyChannelsWidgetEditLinkModal';
import {
  ChannelProps,
  editExternalLinkModalId,
} from './PropertyChannelsWidget.constants';
import {
  PropertyChannelIconContainer,
  PropertyChannelIconsContainer,
  PropertyChannelNameContainer,
  PropertyChannelsListItem,
} from './PropertyChannelsWidget.styles';

function findActiveChannelSettings(
  channelSettings: ChannelSettings[],
  channel: Channel,
) {
  return channelSettings?.find(
    ({ enumId, isActive }) => enumId === channel && isActive,
  );
}

const linkProps = {
  rel: 'noreferrer',
  target: '_blank',
};

const PropertyChannelsWidgetListItem = ({
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  channel,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyChannelSettings,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyName,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyUid,
}) => {
  const { t } = useTranslation();
  const { openModal } = useAppModal();

  const propertyLevelSettings = findActiveChannelSettings(
    propertyChannelSettings,
    channel,
  );
  const isPropertyLevelActive = !!propertyLevelSettings;
  const isChannelGoogle = channel === Channel.google;

  if (!isPropertyLevelActive) {
    return null;
  }

  // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const { Icon, name, propertyChannelLink } = ChannelProps[channel];

  const propertyChannelExternalLink = propertyChannelSettings.find(
    // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    (ac) => ac.enumId === channel && ac.isActive,
  )?.externalLink;

  const editLinkHandler = () => {
    openModal({
      customContent: (
        <PropertyChannelsWidgetEditLinkModal
          channelName={name}
          propertyName={propertyName}
          propertyChannelExternalLink={propertyChannelExternalLink}
          propertyUid={propertyUid}
        />
      ),
      id: editExternalLinkModalId,
    });
  };

  return (
    <li key={channel}>
      <PropertyChannelsListItem>
        <PropertyChannelIconContainer>
          <Icon />
        </PropertyChannelIconContainer>

        <PropertyChannelNameContainer>{name}</PropertyChannelNameContainer>

        <PropertyChannelIconsContainer>
          {propertyChannelLink && (
            <Tooltip
              id={`manage-channel-${channel}`}
              text={t('componentPropertyChannels.manageThisChannel')}
            >
              <a
                data-testid="manage-channel"
                href={propertyChannelLink}
                {...linkProps}
              >
                <PropertyChannelGearIcon />
              </a>
            </Tooltip>
          )}

          {propertyChannelExternalLink && (
            <Tooltip
              id={`open-link-${channel}`}
              text={t('componentPropertyChannels.shortcutToListing')}
            >
              <a
                href={propertyChannelExternalLink}
                data-testid="open-external-link"
                {...linkProps}
              >
                <PropertyChannelExternalLinkIcon />
              </a>
            </Tooltip>
          )}

          {isChannelGoogle && (
            <Tooltip
              id="set-link-google"
              text={t('componentPropertyChannels.updateListingUrl')}
            >
              <div onClick={editLinkHandler} data-testid="update-link-google">
                <PropertyChannelPencilIcon fill="rgb(58, 132, 216)" />
              </div>
            </Tooltip>
          )}
        </PropertyChannelIconsContainer>
      </PropertyChannelsListItem>
    </li>
  );
};

export default PropertyChannelsWidgetListItem;
