import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButton from 'components/button/ToggleButton';
import { ChannelSettings } from 'models/Channels';
import useFetchPropertyChannelSettings from '../../channelSettings/useFetchPropertyChannelSettings';
import {
  PropertyChannelsList,
  PropertyChannelsListHeader,
  PropertyChannelsListWrapper,
  PropertyChannelsWidgetContainer,
} from './PropertyChannelsWidget.styles';
import { channelsToShow } from './PropertyChannelsWidget.constants';
import PropertyChannelsWidgetListItem from './PropertyChannelsWidgetListItem';

const noChannelsActive = (propertyChannelSettings: ChannelSettings[]) => {
  return !propertyChannelSettings?.some((channel) => channel.isActive);
};

const PropertyChannelsWidget = ({
  propertyName,
  propertyUid,
}: {
  propertyName: string;
  propertyUid: string;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const {
    channelSettings: propertyChannelSettings,
    isFetching: isFetchingPropertyChannelSettings,
  } = useFetchPropertyChannelSettings(propertyUid);
  const { t } = useTranslation();

  if (
    isFetchingPropertyChannelSettings ||
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    noChannelsActive(propertyChannelSettings)
  ) {
    return null;
  }

  const toggleExpand = () => {
    setIsExpanded((current) => !current);
  };

  return (
    <PropertyChannelsWidgetContainer>
      <PropertyChannelsListWrapper $isExpanded={isExpanded}>
        <PropertyChannelsListHeader>
          <ToggleButton onClick={toggleExpand} isExpanded={isExpanded} />
          <p>{t('componentPropertyChannels.quickLinks')}</p>
        </PropertyChannelsListHeader>
        <PropertyChannelsList>
          {channelsToShow.map((channel) => (
            <PropertyChannelsWidgetListItem
              channel={channel}
              key={channel}
              propertyChannelSettings={propertyChannelSettings}
              propertyName={propertyName}
              propertyUid={propertyUid}
            />
          ))}
        </PropertyChannelsList>
      </PropertyChannelsListWrapper>
    </PropertyChannelsWidgetContainer>
  );
};

export default PropertyChannelsWidget;
