import { Glyphicon } from 'react-bootstrap';
import useAppUser from 'hooks/useAppUser';
import { Property } from 'models/Properties';
import usePropertyEditPath from 'pages/property/usePropertyEditPath';

const PropertyListItemEditIcon = ({ property }: { property: Property }) => {
  const { isOwner } = useAppUser();
  const getPropertyEditPath = usePropertyEditPath();

  if (isOwner) return null;

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a
      data-testid="property-edit-icon"
      className="btn btn-link property-edit-icon"
      href={getPropertyEditPath(property)}
    >
      <Glyphicon glyph="pencil" />
    </a>
  );
};

export default PropertyListItemEditIcon;
