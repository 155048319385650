import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { PropertyBusinessType } from 'models/Properties';
import useAppMutation from 'hooks/useAppMutation';
import { useTranslation } from 'react-i18next';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { useQueryClient } from '@tanstack/react-query';
import { PHOTOS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPhotos';
import { Photo } from 'models/Photos';
import useInvalidatePropertyPhotos from '../../hooks/useInvalidatePropertyPhotos';
import usePropertyPhotosData from '../../hooks/usePropertyPhotosData';
import { ACCEPT_FILE_TYPES, MAX_PHOTOS_LIMIT } from './UploadPhoto.utils';

export const UPLOAD_PHOTO_MUTATION_KEY = ['uploadPhoto'];

const useUploadPhotos = () => {
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { invalidatePropertyPhotos } = useInvalidatePropertyPhotos();
  const { getPhotos } = usePropertyPhotosData();
  const queryClient = useQueryClient();

  const isPropertyHotel = property?.businessType === PropertyBusinessType.HOTEL;

  const { mutateAsync: uploadPhotoToProperty } = useAppMutation(
    UPLOAD_PHOTO_MUTATION_KEY,
    (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      return API.post<Photo>(
        '/api/internal/photos/property-photo-create',
        formData,
        {
          params: {
            [isPropertyHotel ? 'hotelUid' : 'propertyUid']: property.uid,
          },
        },
      );
    },
    {
      onMutate: async (file) => {
        await queryClient.cancelQueries({
          queryKey: [PHOTOS_BASE_QUERY_KEY, 'get-all', property.uid],
        });

        const previousPhotos = getPhotos();

        // create an optimistic photo
        const optimisticPhoto: Photo = {
          uid: `temp-${file.name}`,
          description: '',
          displayOrder: previousPhotos.length + 1000,
          // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          propertyUid: property.uid,
          originalImageUrl: URL.createObjectURL(file),
          largeScaleImageUrl: URL.createObjectURL(file),
          mediumScaleImageUrl: URL.createObjectURL(file),
          largeThumbnailScaleImageUrl: URL.createObjectURL(file),
        };

        queryClient.setQueryData(
          [PHOTOS_BASE_QUERY_KEY, 'get-all', property.uid],
          [...previousPhotos, optimisticPhoto],
        );

        return { previousPhotos };
      },
      onSuccess: (response, file) => {
        const photos = getPhotos();

        const [previousPhotos, uploadingPhotos] = photos.reduce(
          (acc, photo) => {
            if (photo.uid.startsWith('temp-')) {
              acc[1].push(photo);
            } else {
              acc[0].push(photo);
            }

            return acc;
          },
          [[], []] as [Photo[], Photo[]],
        );

        const remainingUploadingPhotos = uploadingPhotos.filter(
          (photo) => photo.uid !== `temp-${file.name}`,
        );

        // combine the arrays in the correct order:
        // 1. normal photos
        // 2. the newly completed photo (with response data)
        // 3. remaining uploading photos (in their original order)
        const updatedPhotos = [
          ...previousPhotos.sort((a, b) => a.displayOrder - b.displayOrder),
          { ...response.data },
          ...remainingUploadingPhotos,
        ];

        queryClient.setQueryData(
          [PHOTOS_BASE_QUERY_KEY, 'get-all', property.uid],
          updatedPhotos,
        );
      },
      onError: (_, file, context) => {
        notifyError(
          t('pageProperty.photos.uploadError', { fileName: file.name }),
        );
        queryClient.setQueryData(
          [PHOTOS_BASE_QUERY_KEY, 'get-all', property.uid],
          context?.previousPhotos,
        );
      },
    },
  );

  const handleImagesSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;
    if (!files?.length) {
      return;
    }

    const fileArray = Array.from(files);

    const resetInput = () => {
      event.target.value = '';
    };

    // check if the file type is valid:
    const invalidFile = fileArray.find(
      (file) => !ACCEPT_FILE_TYPES.includes(file.type),
    );
    if (invalidFile) {
      notifyError(t('pageProperty.photos.invalidFileType'));
      resetInput();
      return;
    }

    // check if the amount of existing photos + the amount of new photos is greater than the max amount of photos
    const photos = getPhotos();
    const photosCount = photos.length || 0;

    if (photosCount + fileArray.length > MAX_PHOTOS_LIMIT) {
      notifyError(
        t('pageProperty.photos.maxPhotosReached', {
          count: MAX_PHOTOS_LIMIT - photosCount,
        }),
      );
      resetInput();
      return;
    }

    // scroll to the bottom of the grid to show new photos
    const photoGrid = document.querySelector('[data-testid="photo-grid"]');
    if (photoGrid) {
      setTimeout(() => {
        photoGrid.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);
    }

    await Promise.allSettled(
      fileArray.map((file) => uploadPhotoToProperty(file)),
    );

    resetInput();
    invalidatePropertyPhotos();
  };

  return {
    handleImagesSelect,
  };
};

export default useUploadPhotos;
