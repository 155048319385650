import { Transaction } from 'models/Transactions';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import TransactionAmountRenderer from './TransactionAmountRenderer';

const OrderViewTransactionsListItemAmount = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { lead } = useOrderViewFetchedData();

  // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  return <TransactionAmountRenderer lead={lead} transaction={transaction} />;
};

export default OrderViewTransactionsListItemAmount;
