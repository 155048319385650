import {
  PropertyBase,
  PropertyBusinessType,
  PropertySubUnit,
  PropertyUnitType,
} from 'models/Properties';
import { PropertyForPropertyListItem } from '../Properties.types';
import useSearchFieldFilters, {
  SearchFieldFilters,
} from '../body/useSearchFieldFilters';

const filterBySearchFilter =
  (searchFieldFilters: SearchFieldFilters) =>
  (property: PropertySubUnit | PropertyUnitType) => {
    if (searchFieldFilters.propertyUids) {
      return property.uid === searchFieldFilters.propertyUids[0];
    }

    if (searchFieldFilters.name) {
      return property.name
        .toLocaleLowerCase()
        .includes(searchFieldFilters.name);
    }

    return true;
  };

export const useChildProperties = (
  property: Partial<
    Pick<PropertyBase, 'businessType' | 'subUnits' | 'unitTypes'>
  >,
) => {
  const { searchFieldFilters } = useSearchFieldFilters();

  if ('subUnits' in property && (property.subUnits?.length ?? 0) > 0) {
    return (
      property.subUnits?.filter(filterBySearchFilter(searchFieldFilters)) ?? []
    );
  }
  if (property.businessType === PropertyBusinessType.HOTEL) {
    return (
      property.unitTypes?.filter(filterBySearchFilter(searchFieldFilters)) ?? []
    );
  }
  return [];
};

export const getDefaultFormValues = (property: PropertyForPropertyListItem) => {
  return {
    ...property,
    tags:
      'tags' in property
        ? property.tags?.toSorted().map((t) => ({ name: t }))
        : [],
  };
};
