import { ReactNode } from 'react';
import { PropertyBusinessType } from 'models/Properties';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { propertySettingsBulkSavableFields } from './Common.constants';
import BulkSaveIconWithTooltip from './BulkSaveIconWithTooltip';

const PropertyFormFieldLabel = ({
  children,
  name,
}: {
  children: string | ReactNode;
  name: string;
}) => {
  const { property } = usePropertySettingsInitData();
  const [sectionName, ...restFieldName] = name.split('.');
  // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const isBulkEditableField = propertySettingsBulkSavableFields[
    sectionName
  ]?.includes(restFieldName.join('.'));
  const isStandaloneProperty =
    property?.businessType === PropertyBusinessType.STANDALONE_PROPERTY;
  const isSubUnitProperty =
    property?.businessType === PropertyBusinessType.SUB_UNIT;

  if (isBulkEditableField && (isStandaloneProperty || isSubUnitProperty)) {
    return (
      <>
        {children}
        <BulkSaveIconWithTooltip />
      </>
    );
  }

  return children;
};

export default PropertyFormFieldLabel;
