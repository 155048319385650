import { Namespace, TFunction } from 'react-i18next';
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  differenceInDays,
  endOfMonth,
  endOfQuarter,
  endOfTomorrow,
  endOfWeek,
  endOfYear,
  format,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isThisYear,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';

export enum PresetDateRange {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  LAST_WEEK = 'LAST_WEEK',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  THIS_MONTH = 'THIS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
  LAST_QUARTER = 'LAST_QUARTER',
  THIS_QUARTER = 'THIS_QUARTER',
  NEXT_QUARTER = 'NEXT_QUARTER',
  LAST_YEAR = 'LAST_YEAR',
  THIS_YEAR = 'THIS_YEAR',
  // CUSTOM = 'CUSTOM',
}

export enum TimeSpan {
  MONTH = 'MONTH',
  QUARTERLY = 'QUARTERLY',
  WEEK = 'WEEK',
  FULL_RANGE = 'FULL_RANGE',
  DAY = 'DAY',
}

export function formatRangeShortWithCurrentYearCheck(
  from: Date,
  to: Date,
  t: TFunction,
) {
  const fromFormatted = t('common.dateShort', { date: from });
  const toFormatted = t('common.dateShort', { date: to });

  if (isThisYear(from) && isThisYear(to)) {
    const year = getYear(from);
    const fromStr = fromFormatted.replace(`/${year}`, '');
    const toStr = toFormatted.replace(`/${year}`, '');

    return `${fromStr} - ${toStr}`;
  }

  return `${fromFormatted} - ${toFormatted}`;
}

export function formatRangeMediumWithCurrentYearCheck({
  from,
  to,
  t,
  includeDayOfWeek = false,
}: {
  from: Date;
  to: Date;
  t: TFunction<Namespace<'en'>>;
  includeDayOfWeek?: boolean;
}): {
  fromFormatted: string;
  toFormatted: string;
} {
  const localeKey = `common.dateMedium${
    includeDayOfWeek ? 'WithWeekDay' : ''
  }` as const;

  if (isThisYear(from) && isThisYear(to)) {
    return {
      fromFormatted: t(`${localeKey}WithoutYear`, { date: from }),
      toFormatted: t(`${localeKey}WithoutYear`, { date: to }),
    };
  }

  const fromFormatted = t(localeKey, { date: from });
  const toFormatted = t(localeKey, { date: to });

  return { fromFormatted, toFormatted };
}

export function formatDateMediumWithCurrentYearCheck({
  date,
  t,
  includeDayOfWeek = false,
}: {
  date: Date;
  t: TFunction;
  includeDayOfWeek?: boolean;
}): string {
  const localeKey = `common.dateMedium${includeDayOfWeek ? 'WithWeekDay' : ''}`;
  const dateFormatted = t(localeKey, { date });

  if (isThisYear(date)) {
    const year = getYear(date);
    return dateFormatted.replace(new RegExp(`,*\\s*${year}`), '');
  }

  return dateFormatted;
}

export function isBetween(date: Date, from: Date, to: Date) {
  const isBeforeEqual = true;
  const isAfterEqual = true;

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
}

export function getRangeFromPresetDateRange(
  presetDateRange: PresetDateRange,
): [Date, Date] | null {
  // if (presetDateRange === PresetDateRange.CUSTOM) return null;

  const today = new Date();

  if (presetDateRange === PresetDateRange.TODAY) {
    return [today, today];
  }

  if (presetDateRange === PresetDateRange.TOMORROW) {
    return [startOfTomorrow(), endOfTomorrow()];
  }

  if (presetDateRange === PresetDateRange.LAST_WEEK) {
    return [startOfWeek(subWeeks(today, 1)), endOfWeek(subWeeks(today, 1))];
  }

  if (presetDateRange === PresetDateRange.THIS_WEEK) {
    return [startOfWeek(today), endOfWeek(today)];
  }

  if (presetDateRange === PresetDateRange.NEXT_WEEK) {
    return [startOfWeek(addWeeks(today, 1)), endOfWeek(addWeeks(today, 1))];
  }

  if (presetDateRange === PresetDateRange.LAST_MONTH) {
    return [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))];
  }

  if (presetDateRange === PresetDateRange.THIS_MONTH) {
    return [startOfMonth(today), endOfMonth(today)];
  }

  if (presetDateRange === PresetDateRange.NEXT_MONTH) {
    return [startOfMonth(addMonths(today, 1)), endOfMonth(addMonths(today, 1))];
  }

  if (presetDateRange === PresetDateRange.LAST_QUARTER) {
    return [
      startOfQuarter(subQuarters(startOfMonth(today), 1)),
      endOfQuarter(subQuarters(endOfMonth(today), 1)),
    ];
  }

  if (presetDateRange === PresetDateRange.THIS_QUARTER) {
    return [
      startOfQuarter(startOfQuarter(startOfMonth(today))),
      endOfQuarter(endOfQuarter(endOfMonth(today))),
    ];
  }

  if (presetDateRange === PresetDateRange.NEXT_QUARTER) {
    return [
      startOfQuarter(addQuarters(startOfMonth(today), 1)),
      endOfQuarter(addQuarters(endOfMonth(today), 1)),
    ];
  }

  if (presetDateRange === PresetDateRange.LAST_YEAR) {
    return [startOfYear(subYears(today, 1)), endOfYear(subYears(today, 1))];
  }

  if (presetDateRange === PresetDateRange.THIS_YEAR) {
    return [startOfYear(today), endOfYear(today)];
  }

  return null;
}

export function getTimeSpanFromPresetDateRange(
  presetDateRange: PresetDateRange,
): TimeSpan {
  switch (presetDateRange) {
    case PresetDateRange.TODAY:
    case PresetDateRange.TOMORROW:
    case PresetDateRange.LAST_WEEK:
    case PresetDateRange.THIS_WEEK:
    case PresetDateRange.NEXT_WEEK:
    case PresetDateRange.LAST_MONTH:
    case PresetDateRange.THIS_MONTH:
    case PresetDateRange.NEXT_MONTH:
      return TimeSpan.DAY;
    case PresetDateRange.LAST_QUARTER:
    case PresetDateRange.THIS_QUARTER:
    case PresetDateRange.NEXT_QUARTER:
      return TimeSpan.WEEK;
    case PresetDateRange.LAST_YEAR:
    case PresetDateRange.THIS_YEAR:
      return TimeSpan.MONTH;
    default:
      return TimeSpan.DAY;
  }
}

export function getDateFormatBasedOnTimeSpan(timeSpan: TimeSpan): string {
  switch (timeSpan) {
    case TimeSpan.MONTH:
      return 'MMM yyyy';
    default:
      return 'PP';
  }
}

export function isBeforeToday(date: Date): boolean {
  return isBefore(date, startOfToday());
}

export function getDaysBetween(start: Date, end: Date): Date[] {
  const diffInDays = differenceInDays(end, start);

  return Array.from({ length: diffInDays + 1 }).map((_, index) =>
    addDays(start, index),
  );
}

export function getUTCDate(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export function toISODateString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}
