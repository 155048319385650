import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { AsyncTypeAheadFieldInstance } from 'components/forms/typeAheadField/AsyncTypeAheadField.types';
import { PropertyBase } from 'models/Properties';
import { Employee } from '../../models/Employees';
import { Lead, LeadChannelTO, LeadStatus } from '../../models/Leads';
import {
  Thread,
  ThreadParticipantBestProfile,
  ThreadParticipantReadStatus,
} from '../../models/inbox/Threads';
import { MessagingChannel } from '../../models/inbox/Messages';
import { AvailableTemplate, TemplateType } from '../../models/Templates';

export type InboxProperty = Pick<
  PropertyBase,
  'uid' | 'name' | 'businessType'
> & {
  // @ts-expect-error TS2344 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  pricing: Pick<PropertyBase['pricing'], 'currency'>;
} & {
  subUnits: Array<
    Pick<PropertyBase['subUnits'][number], 'uid' | 'name' | 'businessType'>
  >;
} & {
  unitTypes: Array<
    // @ts-expect-error TS2537 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    Pick<PropertyBase['unitTypes'][number], 'uid' | 'name' | 'businessType'>
  >;
};

export interface GetInboxPropertiesResponse {
  data: {
    properties: InboxProperty[];
  };
}

export interface InboxContextProps {
  activeThread?: Thread;
  activeThreadLeadUid?: string;
  activeMessageThreadUid?: string;
  activeLead?: Lead;
  availableTemplates?: AvailableTemplates;
  employees: Employee[];
  inboxFilterLocalStorageKey: string;
  leadChannels: LeadChannelTO[];
  leadSearchFieldRef: MutableRefObject<AsyncTypeAheadFieldInstance>;
  properties: InboxProperty[];
  propertiesMap: { [uid: string]: InboxProperty };
  threadFilter: ThreadFilter;
  threadAvailableMessagingChannelsMap: {
    [threadUid: string]: MessagingChannel[];
  };
  threadParticipantBestProfilesMap: {
    [threadUid: string]: ThreadParticipantBestProfile;
  };
  threadsWithNewMessages?: ThreadNewMessagesData[];
  setAvailableTemplates: Dispatch<SetStateAction<AvailableTemplates>>;
  setEmployees: Dispatch<SetStateAction<Employee[]>>;
  setLeadChannels: Dispatch<SetStateAction<LeadChannelTO[]>>;
  setProperties: Dispatch<SetStateAction<InboxProperty[]>>;
  setPropertiesMap: Dispatch<SetStateAction<{ [uid: string]: InboxProperty }>>;
  setThreadsWithNewMessages: Dispatch<SetStateAction<ThreadNewMessagesData[]>>;
  getInitialSearchParams: () => {
    leadUidParam?: string;
    threadUidParam?: string;
  };
  updateActiveMessageThreadUid: (_activeMessageThreadUid: string) => void;
  updateActiveThreadData: (_activeThreadData: ActiveThreadData) => void;
  updateActiveLead: (_activeLead: Lead, _onlyIfSame?: boolean) => void;
  updateThreadFilter: (_threadFilter: ThreadFilter) => void;
  updateThreadAvailableMessagingChannelsMap: (_args: {
    [threadUid: string]: MessagingChannel[];
  }) => void;
  updateThreadParticipantBestProfilesMap: (_bestProfiles: {
    [threadUid: string]: ThreadParticipantBestProfile;
  }) => void;
}

export enum ThreadFilterSortOrder {
  SORT_BY_RECENT_UPDATE_DATE = 'SORT_BY_RECENT_UPDATE_DATE',
  SORT_BY_LEAD_CHECK_IN_DATE = 'SORT_BY_LEAD_CHECK_IN_DATE',
}

export interface ThreadFilter {
  sortBy: ThreadFilterSortOrder;
  leadStatuses: LeadStatus[] | string[];
  leadChannel: string;
  agentUid: string;
  propertyUid: string;
  leadUid?: string;
  showUnreadOnly: boolean;
}

export interface ActiveThreadData {
  activeThread?: Thread;
  activeThreadLeadUid?: string;
}

export interface ThreadNewMessagesData {
  participantsReadStatuses: ThreadParticipantReadStatus[];
  threadUid: string;
}

export type AvailableTemplates = {
  [key in TemplateType]: AvailableTemplate[];
};
