import { FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const InputGroupTooltipWrapper = styled.div`
  align-items: center;
  display: flex;

  & > .input-group {
    flex-grow: 1;

    .form-control:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .form-control:last-child,
    .form-control:nth-last-child(1 of :not(.character-counter)) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .character-counter {
      bottom: -20px;
      right: 0;
    }
  }

  & > .tooltip-container {
    margin-left: 15px;
  }
`;

export const FormGroupContainer = styled(FormGroup).attrs(
  ({ $customClassName, $name }: { [_key: string]: string }) => ({
    className: `form-field-container ${$customClassName || ''}`,
    'data-testid': `${$name}-field-container`,
  }),
)<{ $customClassName?: string; $name: string; $disabled?: boolean }>`
  .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
  }

  .label-on-top {
    margin-bottom: 8px;
  }

  label.control-label:not(.label-on-top) {
    text-align: right !important;
  }

  .error-message {
    color: #a94442;
  }

  & label[required] {
    &::after {
      content: '*';
      color: #a94442;
      margin-left: 4px;
      position: absolute;
    }
  }

  &.has-character-counter > div {
    margin-bottom: 10px;

    .help-block {
      // to prevent the error message from overlapping the character counter
      max-width: calc(100% - 12ch);
    }
  }

  ${({ $disabled }) => $disabled && '.glyphicon { color: black; }'}
`;
