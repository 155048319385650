import Button from 'components/button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useAppUser from 'hooks/useAppUser';
import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg';
import { Container } from './Authorization.styles';

const Authorization = () => {
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    user: { email: userEmail },
  } = useAppUser();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const redirectUri = searchParams.get('redirectUri');
  const partnerName = searchParams.get('partnerName');
  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const partnerPicture = decodeURIComponent(searchParams.get('partnerPicture'));

  const handleDecision = (decision: 'ACCEPT' | 'DECLINE') => {
    const redirectionUrl = `${decodeURIComponent(
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      redirectUri,
    )}&userAnswer=${decision}`;

    window.location.href = redirectionUrl;
  };

  return (
    <Container>
      {/*
       // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <img className="partner-picture" src={partnerPicture} alt={partnerName} />
      <h1>{t('pageAuthorization.partnerAccessAccount')}</h1>

      <div className="user-email">
        <img src={HostfullyIcon} alt="Hostfully icon" />
        <span>{userEmail}</span>
      </div>

      <Trans
        i18nKey="pageAuthorization.partnerAccessAccountDescription"
        values={{
          partnerName,
        }}
        parent="h2"
        components={[<b key="b" />]}
      />

      <ul className="secondary-info">
        <li>{t('pageAuthorization.permissionReadPropertyCalendar')}</li>
        <li>{t('pageAuthorization.permissionModifyPricing')}</li>
        <li>{t('pageAuthorization.permissionAccessGuest')}</li>
      </ul>

      <div className="trustable-terms">
        <h3>{t('pageAuthorization.makeSureYouTrust', { partnerName })}</h3>
        <p className="secondary-info">
          {t('pageAuthorization.sensitiveInfoDescription', { partnerName })}
        </p>
      </div>

      <div className="actions">
        <Button bsStyle="link" onClick={() => handleDecision('DECLINE')}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" onClick={() => handleDecision('ACCEPT')}>
          {t('common.allow')}
        </Button>
      </div>
    </Container>
  );
};

export default Authorization;
