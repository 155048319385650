import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const ResolutionSummary = ({ resolution: { title, reason, created } }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="resolution-title">{title}</div>
      <div>{reason}</div>
      <div>
        {t('pageAirbnbResolutions.pageAirbnbResolutions.created', {
          date: parseISO(created),
        })}
      </div>
    </>
  );
};

export default ResolutionSummary;
