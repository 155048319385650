import { useTranslation } from 'react-i18next';
import { DamageType } from '../../../../../../../models/channelactions/GuestMisconductBody';

export const useDamageTypeOptions = () => {
  const { t } = useTranslation();

  const translateDamageType = (key: DamageType): string => {
    switch (key) {
      case DamageType.SMALL_FURNITURE:
        return t(
          `componentLead.modal.actionsTab.guestMisconduct.damageTypes.SMALL_FURNITURE`,
        );
      case DamageType.LARGE_FURNITURE:
        return t(
          `componentLead.modal.actionsTab.guestMisconduct.damageTypes.LARGE_FURNITURE`,
        );
      case DamageType.DOOR_WINDOW_WALL:
        return t(
          `componentLead.modal.actionsTab.guestMisconduct.damageTypes.DOOR_WINDOW_WALL`,
        );
      case DamageType.PROPERTY_DAMAGE_OTHER:
        return t(
          `componentLead.modal.actionsTab.guestMisconduct.damageTypes.PROPERTY_DAMAGE_OTHER`,
        );
      default:
        return key;
    }
  };

  const selectOptions: { value: string; label: string }[] = Object.keys(
    DamageType,
  ).map((key) => {
    return {
      value: key,
      // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      label: translateDamageType(DamageType[key]),
    };
  });
  return selectOptions;
};
