import { useEffect } from 'react';

function getExistingFaviconElement() {
  const links = document.getElementsByTagName('link');
  return Array.from(links).find((link) => link.getAttribute('rel') === 'icon');
}

const useFavicon = (faviconUrl?: string) => {
  useEffect(() => {
    const existingFaviconLink = getExistingFaviconElement();

    if (faviconUrl) {
      const { head } = document;
      const link = existingFaviconLink || document.createElement('link');

      link.rel = 'icon';
      link.href = faviconUrl;

      head.appendChild(link);

      return () => {
        head.removeChild(link);
      };
    }

    if (existingFaviconLink) {
      // @ts-expect-error TS18047 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      existingFaviconLink.parentElement.removeChild(existingFaviconLink);
    }

    return () => {};
  }, [faviconUrl]);
};

export default useFavicon;
