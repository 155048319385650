import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import { UploadPhotoButtonStyled } from './UploadPhoto.styles';
import UploadPhotoWrapper from './UploadPhotoWrapper';

const UploadPhotoButton = () => {
  const { t } = useTranslation();

  return (
    <UploadPhotoWrapper id="upload-photo-button">
      <PropertyEditActionWrapper tooltipId="upload-property-photo-button-tooltip">
        <UploadPhotoButtonStyled
          bsStyle="primary"
          data-testid="upload-photo-button-elem"
        >
          <Glyphicon glyph="plus" />
          {t('common.upload')}
        </UploadPhotoButtonStyled>
      </PropertyEditActionWrapper>
    </UploadPhotoWrapper>
  );
};

export default UploadPhotoButton;
