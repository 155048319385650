import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import FormattedDateCell from 'components/table/cells/common/FormattedDateCell';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import { AirbnbGuestReview } from 'models/Reviews';
import { useTranslation } from 'react-i18next';

import ActionsCell from './cells/ActionsCell';
import GuestNameCell from './cells/GuestNameCell';
import RatingCell from './cells/RatingCell';
import { airbnbReviewsQuery } from './GuestReviews.utils';
import { GuestReviewsTableStyled } from './GuestReviews.styles';

const GuestReviewsTable = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<AirbnbGuestReview>();

  const columns = [
    columnHelper.accessor('creationDate', {
      header: t(
        'pageProperty.reviews.airbnbGuest.guestReviews.table.reviewDate',
      ),
      cell: FormattedDateCell,
      meta: {
        dateFormat: 'PP',
      },
    }),
    columnHelper.accessor('rating', {
      header: t('pageProperty.reviews.airbnbGuest.guestReviews.table.rating'),
      cell: RatingCell,
    }),
    columnHelper.accessor('guestName', {
      header: t(
        'pageProperty.reviews.airbnbGuest.guestReviews.table.guestName',
      ),
      cell: GuestNameCell,
    }),
    columnHelper.accessor('publicReview', {
      header: t('pageProperty.reviews.airbnbGuest.guestReviews.table.review'),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    getQuery: (params) => airbnbReviewsQuery({ ...params, propertyUid }),
    pageSize: 10,
  });

  return (
    <GuestReviewsTableStyled
      tableInstance={tableInstance}
      isLoading={isLoading}
      data-testid="guest-reviews-table"
      emptyState={t(
        'pageProperty.reviews.airbnbGuest.guestReviews.table.emptyTable',
      )}
    />
  );
};

export default GuestReviewsTable;
