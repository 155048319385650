import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import {
  Property,
  PropertyBusinessType,
  PropertyUnitType,
} from 'models/Properties';
import usePropertySettingsInitData from './usePropertySettingsInitData';

const useGetPropertySettingsTabName = () => {
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();

  const getTabName = useCallback(
    (tabName: string): string | null => {
      if (!property) {
        return null;
      }

      if (property.businessType === PropertyBusinessType.HOTEL) {
        return t(normalizeKey(`pageProperty.tabNames.${tabName}`), {
          count: (property as Property)?.unitTypes?.length || 0,
        });
      }

      if (property.businessType === PropertyBusinessType.UNIT_TYPE) {
        return t(normalizeKey(`pageProperty.tabNames.${tabName}`), {
          count: (property as PropertyUnitType)?.units?.length || 0,
        });
      }

      return t(normalizeKey(`pageProperty.tabNames.${tabName}`));
    },
    [property, t],
  );

  return { getTabName };
};

export default useGetPropertySettingsTabName;
