import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  LEGACY_PAGES_PARAM_BASED_PATH_PROVIDERS,
  LEGACY_PAGES_SRC_TO_PATH,
} from './legacyPages.constants';

function getFullRedirectUrl(redirectUrl: string, search: string) {
  // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const pathProvider = LEGACY_PAGES_PARAM_BASED_PATH_PROVIDERS[redirectUrl];

  if (pathProvider) {
    const searchParams = new URLSearchParams(search);
    const page = searchParams.get('page');
    const path = pathProvider(page);
    const filteredParams = Array.from(searchParams)
      .filter(([name]) => name !== 'page')
      .map(([name, value]) => `${name}=${value}`)
      .join('&');
    const params = filteredParams ? `?${filteredParams}` : '';
    return `/${path}${params}`;
  }

  const url =
    // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    LEGACY_PAGES_SRC_TO_PATH[redirectUrl] ||
    `l/${encodeURIComponent(redirectUrl)}`;
  const params = search !== '?' ? search : '';
  return `/${url}${params}`;
}

const LegacyRedirect = () => {
  const { redirectUrl } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const fullRedirectUrl = getFullRedirectUrl(redirectUrl, search);

  useEffect(() => {
    if (window.frameElement) {
      window.parent.postMessage({ redirectUrl: fullRedirectUrl }, '*');
    } else {
      navigate(fullRedirectUrl);
    }
  }, [fullRedirectUrl]);

  return null;
};

export default LegacyRedirect;
