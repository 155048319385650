import { useQueryClient } from '@tanstack/react-query';
import { PHOTOS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPhotos';
import { useParams } from 'react-router-dom';

const useInvalidatePropertyPhotos = () => {
  const queryClient = useQueryClient();
  const { uid: propertyUid } = useParams();

  const invalidatePropertyPhotos = () => {
    queryClient.invalidateQueries({
      queryKey: [PHOTOS_BASE_QUERY_KEY, 'get-all', propertyUid],
    });
  };

  return { invalidatePropertyPhotos };
};

export default useInvalidatePropertyPhotos;
