import { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReset } from 'react-hook-form';
import { TFunction } from 'i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import useUpdatePropertyPriceSettings from 'components/domain/propertyPricing/useUpdatePropertyPriceSettings';
import { FormContextParts } from 'components/forms/form/Form.types';
import useNotify from 'hooks/useNotify';
import usePropertySettingsBulkSaveModal from 'pages/property/bulkSave/usePropertySettingsBulkSaveModal';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import {
  PropertySettingsPricingTabFormValues,
  getFormDefaultValues,
} from '../../PropertySettingsPricingTab.schema';
import { PRICE_SETTINGS_BASE_QUERY_KEY } from './useFetchPriceSettingsData';
import usePropertyPriceSettingsUpdatePayload from './usePropertyPriceSettingsUpdatePayload';

export async function handlePropertyPriceSettingsUpdateSuccess({
  propertyUid,
  queryClient,
  notifySuccess,
  reset,
  t,
}: {
  propertyUid: string;
  queryClient: QueryClient;
  reset: UseFormReset<PropertySettingsPricingTabFormValues>;
  notifySuccess: (message: string) => void;
  t: TFunction;
}) {
  const queryKey = [PRICE_SETTINGS_BASE_QUERY_KEY, 'detail', propertyUid];

  await queryClient.invalidateQueries(queryKey).then(() => {
    const updatedFormValues = getFormDefaultValues({
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      priceSettingsData: queryClient.getQueryData(queryKey),
    });

    reset(updatedFormValues);

    notifySuccess(t('pageProperty.pricing.priceSettings.updateSuccess'));
  });
}

const usePropertyPriceSettingsFormSubmit = (propertyUid: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();
  const { openPropertySettingsBulkSaveModal } =
    usePropertySettingsBulkSaveModal();
  const getUpdatePayload = usePropertyPriceSettingsUpdatePayload();
  const { property } = usePropertySettingsInitData();
  const { mutateAsync: updatePropertyPriceSettings } =
    useUpdatePropertyPriceSettings();

  return async (
    formValues: PropertySettingsPricingTabFormValues,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    { nativeEvent }: BaseSyntheticEvent<SubmitEvent> | undefined,
    {
      formState: { dirtyFields },
      reset,
    }: FormContextParts<PropertySettingsPricingTabFormValues>,
  ) => {
    if (Object.keys(dirtyFields).length === 0) {
      return;
    }

    const isBulkSave = nativeEvent.submitter.id === 'bulk-save-button';

    if (isBulkSave) {
      openPropertySettingsBulkSaveModal({
        dirtyFields,
        formValues,
      });
      return;
    }

    const payload = getUpdatePayload({ dirtyFields, formValues });

    const executeUpdate = async () => {
      return updatePropertyPriceSettings({
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyUid: property?.uid,
        ...payload,
      });
    };

    await executeUpdate()
      .then(async ({ response }) => {
        const apiErrorCode = response?.data?.apiErrorCode;
        const apiErrorMessage = response?.data?.apiErrorMessage;

        if (apiErrorCode || apiErrorMessage) {
          notifyError(
            getErrorMessage({
              apiErrorCode,
              // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
          return;
        }

        await handlePropertyPriceSettingsUpdateSuccess({
          notifySuccess,
          propertyUid,
          queryClient,
          reset,
          t,
        });
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };
};

export default usePropertyPriceSettingsFormSubmit;
