import { useCallback, useContext } from 'react';
import { Lead } from 'models/Leads';
import {
  endOfMonth,
  getDate,
  isFirstDayOfMonth,
  isSameDay,
  isSaturday,
  isSunday,
} from 'date-fns';
import { getDayCellCommonClasses } from 'pages/calendar/common/cells/DayCellCommon.styles';
import useCalendarMode from 'pages/stackedCalendar/useCalendarMode';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import MoonIcon from '../../../components/icons/Moon.svg?react';
import useFormatCurrency from '../../../hooks/useFormatCurrency';
import { isCheckinVisible } from '../Calendar.utils';
import { CalendarDay } from '../body/CalendarBody.types';
import CalendarContext from '../../stackedCalendar/CalendarContext';
import useResponsiveStyleCalendar from '../useResponsiveStyleCalendar';
import useCalendarBodySelection from '../common/body/selection/useCalendarBodySelection';
import {
  DayCellDate,
  DayCellMinStay,
  DayCellPricing,
  DayCellWrapper,
} from './DayCell.style';
import LeadView from './LeadView';
import JobView from './JobView';
import SelectionView from './SelectionView';

interface Props {
  calendarDay: CalendarDay;
}

const DayCell = ({ calendarDay }: Props) => {
  const formatCurrency = useFormatCurrency();
  const calendarStyleConstants = useResponsiveStyleCalendar();

  const { selectedProperty } = useContext(CalendarContext);
  const { isPricingMode, isCombinedMode } = useCalendarMode();
  const { handleMouseEnter } = useCalendarBodySelection();

  const { date: dayDate } = calendarDay;

  const isSaturdayOrEndOfMonth: boolean =
    isSaturday(dayDate) || isSameDay(dayDate, endOfMonth(dayDate));

  const leadsWithCheckin = calendarDay.leads?.filter((lead) => {
    return isSameDay(getLeadCheckInDate(lead), dayDate);
  });

  const leadsWithCheckout = calendarDay.leads?.filter((lead) => {
    return isSameDay(getLeadCheckOutDate(lead), dayDate);
  });

  const hidePriceBcOfLead =
    (calendarDay.leads?.length && !leadsWithCheckout?.length) ||
    leadsWithCheckin?.length;

  const getLeadComponent = (lead: Lead) => {
    const onSunday = isSunday(dayDate);
    const isMonthStart = isFirstDayOfMonth(dayDate);
    const showCheckin = isCheckinVisible(dayDate, lead);

    if (onSunday || showCheckin || isMonthStart) {
      return <LeadView lead={lead} date={dayDate} />;
    }

    return <div style={{ display: 'none' }} />;
  };

  const onSelectionMouseEnter = useCallback(() => {
    handleMouseEnter({
      date: dayDate,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertyUid: selectedProperty,
    });
  }, [dayDate, selectedProperty]);

  if (calendarDay.isLoading) {
    return (
      <DayCellWrapper
        className={getDayCellCommonClasses(dayDate)}
        $calendarStyleConstants={calendarStyleConstants}
        $borderRight={isSaturdayOrEndOfMonth}
      >
        <DayCellDate>{getDate(dayDate)}</DayCellDate>
        <DayCellMinStay $isLoading />
        <DayCellPricing $isLoading />
      </DayCellWrapper>
    );
  }

  const isModeAllowedToShowCellInfo = isPricingMode || isCombinedMode;

  return (
    <DayCellWrapper
      $inRed={
        (!calendarDay.availableForCheckIn || calendarDay.unavailable) &&
        !hidePriceBcOfLead
      }
      $calendarStyleConstants={calendarStyleConstants}
      $borderRight={isSaturdayOrEndOfMonth}
      className={`body-day-cell ${getDayCellCommonClasses(dayDate)}`}
      data-day-date={dayDate.getTime()}
      data-property-uid={selectedProperty}
      onMouseEnter={onSelectionMouseEnter}
    >
      {/*
       // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <SelectionView dayDate={dayDate} propertyUid={selectedProperty} />

      <DayCellDate>{getDate(dayDate)}</DayCellDate>
      {isModeAllowedToShowCellInfo && !hidePriceBcOfLead && (
        <div>
          {calendarDay.minimumStay && (
            <DayCellMinStay>
              <MoonIcon /> <span>{calendarDay.minimumStay}</span>
            </DayCellMinStay>
          )}
          {/*
           // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {calendarDay.price.value && calendarDay.price.currency && (
            <DayCellPricing>
              {formatCurrency({
                // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                currency: calendarDay.price.currency || 'USD',
                // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                value: Number(calendarDay.price.value || 0),
                options: {
                  round: true,
                  removeDecimals: true,
                },
              })}
            </DayCellPricing>
          )}
        </div>
      )}
      {/*
       // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <JobView jobs={calendarDay.jobs} />
      {calendarDay.leads?.map((lead) => getLeadComponent(lead))}
    </DayCellWrapper>
  );
};

export default DayCell;
