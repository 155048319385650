import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { UseRestrictionsCheckboxesReturn } from './PropertyPricingModalRestrictions.types';
import { DailyRateUpdateType } from './PropertyPricingModal.types';

const propertyPricingModalSchema = (
  t: TFunction,
  getChangedCheckboxes: UseRestrictionsCheckboxesReturn['getChangedCheckboxes'],
) => {
  return Yup.object()
    .shape({
      startDate: Yup.date()
        .required()
        .typeError(t('form.validation.invalidDate')),
      endDate: Yup.date()
        .required()
        .typeError(t('form.validation.invalidDate')),
      pricing: Yup.array().of(
        Yup.object().shape({
          dailyRateUpdateType: Yup.mixed<DailyRateUpdateType>()
            .oneOf(Object.values(DailyRateUpdateType))
            .required(),
          dailyRateValue: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .notRequired(),
          currency: Yup.string().notRequired(),
          propertyUids: Yup.mixed<Set<string>>().required(),
        }),
      ),
      minimumStay: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .notRequired(),
    })
    .test(({ pricing, minimumStay, ...formValues }, ctx) => {
      const changedCheckboxes = getChangedCheckboxes(formValues);

      const isAnyCheckInOrCheckoutChanged =
        changedCheckboxes.changedCheckIn?.length ||
        changedCheckboxes.changedCheckout?.length;

      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      const dailyRateUpdates = pricing.filter(
        ({ dailyRateValue }) => !!dailyRateValue,
      );

      if (
        dailyRateUpdates.length ||
        minimumStay ||
        isAnyCheckInOrCheckoutChanged
      ) {
        return true;
      }

      return ctx.createError({
        path: 'invalid',
        message: t('pageCalendar.pricingModal.invalidError'),
      });
    });
};

export default propertyPricingModalSchema;
