import useFetchAgencyIntegrationSettings from 'components/domain/integrationSettings/useFetchAgencyIntegrationSettings';
import MatterportIcon from 'assets/images/icons/matterport.png';
import TruPlaceIcon from 'assets/images/icons/truplace.png';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import FormWithProvider from 'components/forms/form/Form';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import Button from 'components/button/Button';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import useVideoTourURL from './useVideoTourURL';
import {
  PropertyVideoTourSection,
  StyledFooter,
} from './PropertyVideoTour.styles';

export const MATTERPORT_INTEGRATION_NAME = 'matterport';
export const TRUPLACE_INTEGRATION_NAME = 'truplace';

const PropertyVideoTour = () => {
  const { t } = useTranslation();
  const { integrationSettings, isFetching: isFetchingIntegrationSettings } =
    useFetchAgencyIntegrationSettings();
  const {
    updateVideoTourURL,
    isUpdating,
    videoTourURL,
    isLoading: isLoadingVideoTourURL,
  } = useVideoTourURL();

  const isMatterportActive = integrationSettings?.some(
    (integration) =>
      integration.affiliateEnum === MATTERPORT_INTEGRATION_NAME &&
      integration.isEnabled,
  );

  const isTruPlaceActive = integrationSettings?.some(
    (integration) =>
      integration.affiliateEnum === TRUPLACE_INTEGRATION_NAME &&
      integration.isEnabled,
  );

  // TruPlace has priority over Matterport
  const currentIconSrc = isTruPlaceActive ? TruPlaceIcon : MatterportIcon;
  const currentLabel = isTruPlaceActive
    ? t('pageProperty.photos.propertyVideoTour.truPlaceField')
    : t('pageProperty.photos.propertyVideoTour.matterportField');

  const isSomeIntegrationActive = isMatterportActive || isTruPlaceActive;
  const isFetching = isFetchingIntegrationSettings || isLoadingVideoTourURL;

  if (isFetching || !isSomeIntegrationActive) {
    return null;
  }

  return (
    <PropertyVideoTourSection
      data-testid="property-video-tour"
      defaultExpanded
      label={t('pageProperty.photos.propertyVideoTour.title')}
      variant="card"
    >
      <FormWithProvider
        defaultValues={{
          panoramicDataUrl: videoTourURL,
        }}
        onSubmit={({ panoramicDataUrl }) => {
          updateVideoTourURL(panoramicDataUrl);
        }}
        resolver={Yup.object({
          panoramicDataUrl: Yup.string().url(),
        })}
      >
        <Row>
          <TextField
            label={currentLabel}
            name="panoramicDataUrl"
            colSmLabel={3}
            colSmInput={8}
            colXsLabel={4}
            colXsInput={5}
          />
          <Col sm={1} xs={3}>
            <img src={currentIconSrc} alt="" />
          </Col>
        </Row>

        <StyledFooter>
          <PropertyEditActionWrapper tooltipId="save-property-video-tour-button-tooltip">
            <Button bsStyle="primary" type="submit" disabled={isUpdating}>
              {t('common.save')}
            </Button>
          </PropertyEditActionWrapper>
        </StyledFooter>
      </FormWithProvider>
    </PropertyVideoTourSection>
  );
};

export default PropertyVideoTour;
