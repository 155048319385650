import { ModalBody, ModalFooter } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormWithProvider from '../../../../forms/form/Form';
import Button from '../../../../button/Button';
import Loader from '../../../../loader/Loader';
import { LeadBookingCancellationOrigin } from '../../../../../models/Leads';
import useFetchAirbnbCancellationReasons from './useFetchAirbnbCancellationReasons';
import CancelAirbnbLeadFormElements from './CancelAirbnbLeadFormElements';

const cancelFormSchema = () => ({
  airbnbReason: Yup.string().required(),
  cancellationOrigin: Yup.mixed<LeadBookingCancellationOrigin>()
    .oneOf(Object.values(LeadBookingCancellationOrigin))
    .required(),
  messageToAirbnb: Yup.string().required(),
  messageToGuest: Yup.string().required(),
});

const formDefaultValues = {
  cancellationOrigin: LeadBookingCancellationOrigin.HOST,
};

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const CancelAirbnbLeadModalContent = ({ lead, onCancel, onSubmit }) => {
  const { t } = useTranslation();

  const { cancellationReasons, isFetching } = useFetchAirbnbCancellationReasons(
    lead.uid,
  );

  if (isFetching) {
    return <Loader />;
  }

  if (!cancellationReasons?.length) {
    return (
      <>
        <ModalBody>
          <div
            className="text-center"
            data-testid="cancellation-not-allowed-message"
          >
            {t('componentLead.actionsInfo.airbnb.cancellationNotAllowed')}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>{t('common.close')}</Button>
        </ModalFooter>
      </>
    );
  }

  return (
    <FormWithProvider
      defaultValues={formDefaultValues}
      onSubmit={onSubmit}
      schema={cancelFormSchema()}
    >
      <CancelAirbnbLeadFormElements
        cancellationReasons={cancellationReasons}
        onCancel={onCancel}
      />
    </FormWithProvider>
  );
};

export default CancelAirbnbLeadModalContent;
