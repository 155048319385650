import { FormFooterStyled } from './Common.styles';
import FormFooterButtons from './FormFooterButtons';
import FormFooterSticky from './FormFooterSticky';

interface FormFooterProps {
  saveButtonLabel: string;
  noBulkSave?: boolean;
}

const FormFooter = ({ saveButtonLabel, noBulkSave }: FormFooterProps) => {
  const buttons = (
    <FormFooterButtons
      saveButtonLabel={saveButtonLabel}
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      noBulkSave={noBulkSave}
    />
  );

  return (
    <>
      <FormFooterStyled data-testid="form-footer" id="form-footer">
        {buttons}
      </FormFooterStyled>
      <FormFooterSticky>{buttons}</FormFooterSticky>
    </>
  );
};

FormFooter.defaultProps = {
  noBulkSave: false,
};

export default FormFooter;
