import { useTranslation, Trans } from 'react-i18next';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import PercentageField from 'components/domain/lead/modal/tabs/details/order/fields/common/PercentageField';
import { useFormContext } from 'react-hook-form';
import { QuoteFeeType } from 'models/Quote';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Taxes = ({
  order,
  allowEdit,
  priceDetailsItems,
}: {
  order: HasOrderItems;
  allowEdit: boolean;
  priceDetailsItems: PriceDetailsItem[];
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const {
    currency,
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    rent: { taxAmount, taxationRate },
    fees,
  } = order;

  const totalTaxes =
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    fees.otherFees
      .filter(({ removed, enabled }) => !removed && enabled)
      .reduce((acc, fee) => acc + fee.taxAmount, 0) +
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      fees.cleaningFee.taxAmount +
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      fees.otherFees
        .filter(({ removed, enabled }) => !removed && enabled)
        .filter((o) => o.type === QuoteFeeType.TAX)
        .reduce((acc, fee) => acc + fee.netPrice, 0) +
      taxAmount || 0;

  if (
    totalTaxes <= 0 &&
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    !fees.otherFees
      .filter(({ removed, enabled }) => !removed && enabled)
      .find((o) => o.type === QuoteFeeType.TAX)
  ) {
    return null;
  }

  return (
    !!order.rent && (
      <PriceDetailsList data-testid="price-details-list-taxes">
        <OrderViewPriceDetailsListItem
          title={t('componentOrder.taxes')}
          priceDetailsItems={[
            // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            taxAmount > 0 && {
              label: (
                <Trans
                  i18nKey="componentOrder.genericTaxesWithInput"
                  components={{
                    input: (
                      <PercentageField
                        value={taxationRate}
                        isEditable={allowEdit}
                        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                        onItemUpdated={(val) => {
                          // TODO set value to orderItem
                          setValue('quote.rent.taxationRate', val, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    ),
                  }}
                />
              ),
              isEditable: allowEdit,
              amount: taxAmount,
              onItemUpdated: (val) => {
                setValue('quote.rent.taxAmount', val, {
                  shouldDirty: true,
                });
              },
              currency,
            },
            // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            fees.cleaningFee.taxAmount > 0 && {
              label: t('componentOrder.cleaningFeeTaxes', {
                // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                value: fees.cleaningFee.taxationRate,
              }),
              // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              amount: fees.cleaningFee.taxAmount,
              currency,
            },
            ...priceDetailsItems,
            {
              label: t('componentOrder.totalTaxes'),
              amount: totalTaxes,
              isTotalAmount: true,
              currency,
            },
          ]}
        />
      </PriceDetailsList>
    )
  );
};

export default Taxes;
