import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import { useTranslation } from 'react-i18next';
import { sumAll } from 'utils/arrayUtils';

interface PropertyUnitsProps {
  property:
    | {
        businessType: PropertyBusinessType;
        unitTypes?: PropertyBase['unitTypes'];
      }
    | {
        businessType: PropertyBusinessType;
        units?: PropertyBase['units'];
      };
}

const PropertyUnits = ({ property }: PropertyUnitsProps) => {
  const { t } = useTranslation();
  const isHotel = property.businessType === PropertyBusinessType.HOTEL;
  const unitTypes = 'unitTypes' in property ? property.unitTypes : [];
  const units = isHotel
    ? // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      sumAll(unitTypes.flatMap((p) => p.units?.length))
    : 'units' in property && property.units?.length;

  const unitTypesAndUnits = [
    isHotel &&
      t('pageProperties.body.characteristics.unitTypes', {
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        count: unitTypes.length,
      }),
    // @ts-expect-error TS2769 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    t('pageProperties.body.characteristics.units', { count: units ?? 0 }),
  ]
    .filter(Boolean)
    .join(' | ');

  return <span>{unitTypesAndUnits}</span>;
};

export default PropertyUnits;
