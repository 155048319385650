import { useContext } from 'react';
import FormWithProvider from 'components/forms/form/Form';
import {
  PresetDateRange,
  getRangeFromPresetDateRange,
  getTimeSpanFromPresetDateRange,
} from 'utils/dateTimeUtils';
import { DateType } from '../types/Analytics.types';
import useAnalyticsInit from '../useAnalyticsInit';
import AnalyticsFilterLoader from '../AnalyticsFilterLoader';
import AnalyticsContext from '../AnalyticsContext';
import AnalyticsFilterFormWatcher from './AnalyticsFilterFormWatcher';
import AnalyticsFilters from './AnalyticsFilters';

const AnalyticsFiltersWrapper = ({
  isLoading,
  showOnlyCurrencyFilter,
  forcePresetDateRange,
  forceDateType,
}: {
  isLoading: boolean;
  showOnlyCurrencyFilter?: boolean;
  forcePresetDateRange?: PresetDateRange;
  forceDateType?: DateType;
}) => {
  const { defaultCurrency } = useAnalyticsInit();
  const { analyticsFilterLocalStorageKey } = useContext(AnalyticsContext);

  if (!defaultCurrency) return null;

  const storedFilter = JSON.parse(
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    localStorage.getItem(analyticsFilterLocalStorageKey),
  );

  const defaultDateRange = forcePresetDateRange || PresetDateRange.THIS_MONTH;
  const defaultDateType = forceDateType || DateType.OCCUPANCY;

  if (storedFilter?.range) {
    storedFilter.range.startDate = forcePresetDateRange
      ? // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        getRangeFromPresetDateRange(forcePresetDateRange)[0]
      : new Date(storedFilter.range.startDate);
    storedFilter.range.endDate = forcePresetDateRange
      ? // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        getRangeFromPresetDateRange(forcePresetDateRange)[1]
      : new Date(storedFilter.range.endDate);
  }

  if (storedFilter?.dateType) {
    storedFilter.dateType = forceDateType || storedFilter.dateType;
  }

  if (isLoading) {
    return <AnalyticsFilterLoader />;
  }

  return (
    <FormWithProvider
      data-testid="analytics-filter-form"
      horizontal
      defaultValues={
        storedFilter || {
          presetDateRange: defaultDateRange,
          dateType: defaultDateType,
          propertyUid: '',
          channel: '',
          range: {
            // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            startDate: getRangeFromPresetDateRange(defaultDateRange)[0],
            // @ts-expect-error TS2531 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            endDate: getRangeFromPresetDateRange(defaultDateRange)[1],
          },
          currency: defaultCurrency,
          timeSpan: getTimeSpanFromPresetDateRange(defaultDateRange),
        }
      }
      schema={{}}
      onSubmit={() => {}}
    >
      {/*
       // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
      <AnalyticsFilters showOnlyCurrencyFilter={showOnlyCurrencyFilter} />
      <AnalyticsFilterFormWatcher />
    </FormWithProvider>
  );
};

AnalyticsFiltersWrapper.defaultProps = {
  showOnlyCurrencyFilter: false,
  forcePresetDateRange: null,
  forceDateType: null,
};

export default AnalyticsFiltersWrapper;
