import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PRICING_RULES_BASE_QUERY_KEY } from 'components/domain/propertyPricing/pricingRulesQuery';
import useBulkDeletePricingRule from 'components/domain/propertyPricing/useBulkDeletePricingRule';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import TableContext from 'components/table/TableContext';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { PricingRule } from 'models/pricing/PricingRule';
import BulkDeleteModal, {
  BulkDeleteFormSchemaType,
  bulkDeleteModalId,
} from 'pages/property/feesAndPolicies/BulkDeleteModal';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { handlePaginationAfterDelete } from 'utils/pagination';

const useDeleteModal = ({ pricingRule }: { pricingRule: PricingRule }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { tableInstance } = useContext(TableContext);
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const mutationKey = ['pricing-rule-delete', pricingRule.uid];

  const { mutateAsync: deletePricingRule } = useBulkDeletePricingRule({
    mutationKey,
    onSuccess: () => {
      queryClient.invalidateQueries([PRICING_RULES_BASE_QUERY_KEY]);

      handlePaginationAfterDelete(tableInstance);

      closeModal(bulkDeleteModalId);
    },
    onError: (error: any) => {
      notifyError(
        getErrorMessage({
          apiErrorCode: error.response.data?.apiErrorCode,
          apiErrorMessage: error.response.data?.apiErrorMessage,
          baseKey: 'pageProperty.apiErrorCodes.',
          t,
        }),
      );
    },
  });

  const openBulkDeleteModal = () => {
    const handleDelete = async (values: BulkDeleteFormSchemaType) => {
      const { updateAllProperties, propertiesUids } =
        extractPropertiesMultiSelectFields('appliesTo', values);

      const objectUids = updateAllProperties ? undefined : propertiesUids;

      await deletePricingRule({
        uid: pricingRule.uid,
        applyToAll: updateAllProperties,
        propertyUids: objectUids,
      });
    };

    const appliesTo = pricingRule?.metadata.appliesToAllProperties
      ? PROPERTIES_SELECTION.ALL_PROPERTIES
      : PROPERTIES_SELECTION.SELECTED_PROPERTIES;
    const appliesToProperties = [
      {
        isFixed: true,
        label: property?.name,
        value: property?.uid,
      },
      ...(pricingRule?.metadata.propertiesThatAppliesTo ?? [])
        .filter((p) => p.uid !== property?.uid)
        .map(({ uid, name }) => ({
          label: name,
          value: uid,
          isFixed: false,
        })),
    ];

    const defaultValues = { appliesTo, appliesToProperties };

    openModal({
      id: bulkDeleteModalId,
      customContent: (
        <BulkDeleteModal
          title={t('pageProperty.pricing.priceRules.deleteModal.title')}
          description={t(
            'pageProperty.pricing.priceRules.deleteModal.description',
            {
              name: pricingRule.name,
            },
          )}
          onSubmit={handleDelete}
          defaultValues={defaultValues}
          mutationKey={mutationKey}
          propertyMultiSelectProps={{
            enableHotels: false,
            enableUnitTypes: true,
          }}
        />
      ),
    });
  };

  return {
    openBulkDeleteModal,
  };
};

export default useDeleteModal;
