import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { pricingRulesQuery } from 'components/domain/propertyPricing/pricingRulesQuery';
import CommonTable from 'components/table/CommonTable';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import { PricingRule } from 'models/pricing/PricingRule';
import AppliesToCell from 'pages/property/common/cells/AppliesToCell';
import ActionsCell from './cells/ActionsCell';
import ChangeCell from './cells/ChangeCell';
import ChannelCell from './cells/ChannelCell';
import ConditionCell from './cells/ConditionCell';

const PriceRulesTable = () => {
  const { t } = useTranslation();
  const { uid: propertyUid = '' } = useParams();
  const columnHelper = createColumnHelper<PricingRule>();

  const columns = [
    columnHelper.accessor('name', {
      header: t('common.name'),
      sortDescFirst: true,
    }),
    columnHelper.accessor('type', {
      header: t('common.type'),
      cell: ({ getValue }) =>
        t(`pageProperty.pricing.priceRules.typeOptions.${getValue()}`),
      sortDescFirst: true,
    }),
    columnHelper.accessor('type', {
      header: t('common.channels'),
      cell: ChannelCell,
      enableSorting: false,
    }),
    columnHelper.accessor('priceModifier', {
      header: t('pageProperty.pricing.priceRules.change'),
      cell: ChangeCell,
      enableSorting: false,
    }),
    columnHelper.accessor('priceModifierThreshold', {
      header: t('pageProperty.pricing.priceRules.condition'),
      cell: ConditionCell,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'appliesTo',
      header: t('pageProperty.common.cells.appliesTo'),
      cell: AppliesToCell,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    getQuery: (options) => pricingRulesQuery({ propertyUid, ...options }),
    pageSize: 10,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoading}
      emptyState={t('pageProperty.pricing.priceRules.emptyTable')}
      data-testid="price-rules-table"
    />
  );
};

export default PriceRulesTable;
