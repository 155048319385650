import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import CancellationPolicyTable from './CancellationPolicyTable';
import useCancellationPolicyData from './useCancellationPolicyData';
import useCancellationPolicyModal from './modals/useCancellationPolicyModal';

const CancellationPolicy = () => {
  const { t } = useTranslation();
  const { cancellationPolicyData } = useCancellationPolicyData();
  const { openAddModal } = useCancellationPolicyModal();

  const hasCancellationPolicy = !!cancellationPolicyData?.values.length;

  return (
    <section data-testid="property-cancellation-policy-section">
      <SectionHeader
        id="cancellation-policy-header"
        title={t('pageProperty.feesTaxesAndPolicies.cancellationPolicy.title')}
        tooltip={t(
          'pageProperty.feesTaxesAndPolicies.cancellationPolicy.tooltipTitle',
        )}
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        button={
          !hasCancellationPolicy && {
            label: t(
              'pageProperty.feesTaxesAndPolicies.cancellationPolicy.addButton',
            ),
            onClick: openAddModal,
          }
        }
      />

      <CancellationPolicyTable />
    </section>
  );
};

export default CancellationPolicy;
