import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { format, isSameDay } from 'date-fns';
import { getGuestOptions } from 'components/domain/lead/modal/tabs/details/LeadModalDetailsTab';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import AirbnbAlterationRequestModalContext from './AirbnbAlterationRequestModalContext';
import {
  AirbnbAlterationFormLeftColumn,
  AirbnbAlterationFormLeftColumnInner,
  AirbnbAlterationFormRightColumn,
  AirbnbAlterationTitle,
} from './AirbnbAlterationRequestModal.styles';
import AirbnbAlterationRequestOrder from './AirbnbAlterationRequestOrder';
import useAirbnbAlterationModalData from './useAirbnbAlterationModalData';
import { AirbnbAlterationType } from './AirbnbAlteration.types';
import useAirbnbAlterationModalQuoteData from './useAirbnbAlterationModalQuoteData';

const AirbnbAlterationRequestModalBody = () => {
  const { airbnbAlterationData } = useAirbnbAlterationModalData();
  const { quote } = useAirbnbAlterationModalQuoteData();
  const { availableProperties, lead } = useContext(
    AirbnbAlterationRequestModalContext,
  );
  const { t } = useTranslation();
  const {
    petCount,
    childrenCount,
    adultCount,
    infantCount,
    checkInDate,
    checkOutDate,
    airbnbAlteration: {
      numberOfPets,
      numberOfInfants,
      numberOfAdults,
      numberOfChildren,
      startDate,
      endDate,
      price: airbnbAlterationPrice,
    },
  } = useWatch();
  const getCurrencySymbol = useGetCurrencySymbol();

  const initialPrice = quote?.totalRentalPrice;

  if (!lead) {
    return null;
  }

  const maxGuests =
    availableProperties?.find(({ uid }) => uid === lead.property.uid)
      ?.availability?.maxGuests || 10;
  const guestOptions = getGuestOptions(maxGuests);
  const isDisabled =
    airbnbAlterationData?.type === AirbnbAlterationType.GUEST ||
    (airbnbAlterationData &&
      airbnbAlterationData.type === AirbnbAlterationType.HOST);

  return (
    <fieldset disabled={isDisabled}>
      <Row>
        <Col xs={12}>
          <p>
            {!airbnbAlterationData && (
              <span data-testid="alteration-description">
                {t('componentLead.alterationRequest.whatDoYouWantToChange')}
              </span>
            )}

            {airbnbAlterationData?.type === AirbnbAlterationType.GUEST && (
              <span data-testid="alteration-description">
                {t(
                  'componentLead.alterationRequest.pleaseReviewAlterationDetailsByGuest',
                )}
              </span>
            )}

            {airbnbAlterationData?.type === AirbnbAlterationType.HOST &&
              airbnbAlterationData?.createdDateTime && (
                <span data-testid="alteration-description">
                  {t('componentLead.alterationRequest.alterationSubmittedOn', {
                    date: format(
                      new Date(airbnbAlterationData?.createdDateTime),
                      'MMM dd, yyyy',
                    ),
                  })}
                </span>
              )}
            <br />
            <Trans
              i18nKey="componentLead.alterationRequest.alterationDoc"
              defaults="For more information about Airbnb alteration requests, <link1>click here</link1>."
              components={{
                link1: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    target="_blank"
                    data-testid="alteration-request-documentation"
                    href="https://help.hostfully.com/en/articles/1861331-modify-a-quote-or-reservation#h_cff347f175"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </p>
        </Col>
        <AirbnbAlterationFormLeftColumn md={6}>
          <Row>
            <AirbnbAlterationFormLeftColumnInner md={7}>
              <Row>
                <Col xs={8} xsOffset={4}>
                  <AirbnbAlterationTitle>
                    {t('componentLead.alterationRequest.currentValues')}
                  </AirbnbAlterationTitle>
                </Col>
              </Row>
              <PropertySelectField
                colSmInput={8}
                colSmLabel={4}
                containerClassName="property-container"
                name="propertyUid"
                properties={availableProperties}
                disabled
                pictureAsLabel
                showInactiveProperties={false}
                enableHotels={false}
                enableUnitTypes={false}
                shouldShowUnitDefault
              />
              <DateTimePickerField
                name="checkInDate"
                label={t('common.from')}
                colSmInput={8}
                colSmLabel={4}
                required
                disabled
              />
              <DateTimePickerField
                containerClassName="check-out-date-container"
                name="checkOutDate"
                label={t('common.to')}
                colSmInput={8}
                colSmLabel={4}
                required
                disabled
              />
              <SelectField
                label={t('componentLead.modal.form.adults')}
                name="adultCount"
                options={guestOptions}
                colSmInput={8}
                colSmLabel={4}
                disabled
              />
              <SelectField
                label={t('componentLead.modal.form.children')}
                name="childrenCount"
                options={guestOptions}
                colSmInput={8}
                colSmLabel={4}
                disabled
              />
              <SelectField
                label={t('componentLead.modal.form.infants')}
                name="infantCount"
                options={guestOptions}
                colSmInput={8}
                colSmLabel={4}
                disabled
              />
              <TextField
                label={t('componentLead.modal.form.pets')}
                name="petCount"
                type="number"
                min={0}
                max={100}
                colSmInput={8}
                colSmLabel={4}
                disabled
              />
              {!airbnbAlterationData && (
                <TextField
                  label={t('componentLead.modal.form.rentalCost')}
                  name="price"
                  value={initialPrice}
                  type="string"
                  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                  leftAddon={getCurrencySymbol(lead?.order?.currency)}
                  colSmInput={8}
                  colSmLabel={4}
                  disabled
                />
              )}
            </AirbnbAlterationFormLeftColumnInner>
            <AirbnbAlterationFormLeftColumnInner md={5}>
              <Row>
                <Col xs={12}>
                  <AirbnbAlterationTitle>
                    {t('componentLead.alterationRequest.requestedChange')}
                  </AirbnbAlterationTitle>
                </Col>
              </Row>
              <PropertySelectField
                colSmInput={12}
                colSmLabel={0}
                containerClassName="property-container"
                name="airbnbAlteration.propertyUid"
                pictureAsLabel={false}
                properties={availableProperties}
                showInactiveProperties={false}
                enableHotels={false}
                enableUnitTypes={false}
                disabled
                shouldShowUnitDefault
              />
              <DateTimePickerField
                name="airbnbAlteration.startDate"
                colSmInput={12}
                colSmLabel={0}
                required
                minDate={new Date()}
                isValid={!isSameDay(checkInDate, startDate)}
              />
              <DateTimePickerField
                containerClassName="check-out-date-container"
                name="airbnbAlteration.endDate"
                colSmInput={12}
                colSmLabel={0}
                required
                minDate={new Date()}
                isValid={!isSameDay(checkOutDate, endDate)}
              />
              <SelectField
                name="airbnbAlteration.numberOfAdults"
                options={guestOptions}
                colSmInput={12}
                colSmLabel={0}
                isValid={adultCount.toString() !== numberOfAdults.toString()}
              />
              <SelectField
                name="airbnbAlteration.numberOfChildren"
                options={guestOptions}
                colSmInput={12}
                colSmLabel={0}
                isValid={
                  childrenCount.toString() !== numberOfChildren.toString()
                }
              />
              <SelectField
                name="airbnbAlteration.numberOfInfants"
                options={guestOptions}
                colSmInput={12}
                colSmLabel={0}
                isValid={infantCount.toString() !== numberOfInfants.toString()}
              />
              <TextField
                name="airbnbAlteration.numberOfPets"
                type="number"
                isValid={petCount.toString() !== numberOfPets.toString()}
                min={0}
                max={100}
                colSmInput={12}
                colSmLabel={0}
              />
              {!airbnbAlterationData && (
                <TextField
                  name="airbnbAlteration.price"
                  type="number"
                  // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                  leftAddon={getCurrencySymbol(lead?.order?.currency)}
                  isValid={
                    initialPrice !== airbnbAlterationPrice &&
                    airbnbAlterationPrice
                  }
                  colSmInput={12}
                  colSmLabel={0}
                  rightTooltip={t(
                    'componentLead.alterationRequest.rentalCostInputTooltip',
                  )}
                />
              )}
            </AirbnbAlterationFormLeftColumnInner>
          </Row>
        </AirbnbAlterationFormLeftColumn>
        <AirbnbAlterationFormRightColumn md={6}>
          <AirbnbAlterationRequestOrder lead={lead} />
        </AirbnbAlterationFormRightColumn>
      </Row>
    </fieldset>
  );
};

export default AirbnbAlterationRequestModalBody;
