import { useMemo, useRef, useState } from 'react';
import { AsyncTypeAheadFieldInstance } from 'components/forms/typeAheadField/AsyncTypeAheadField.types';
import { Property } from 'models/Properties';
import PipelineContext from './PipelineContext';
import { PipelineFilter } from './Pipeline.types';
import usePipelineInitialFilter from './filter/usePipelineInitialFilter';

// @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
const PipelineContextProvider = ({ children }) => {
  const leadSearchFieldRef = useRef<AsyncTypeAheadFieldInstance>();
  const [properties, setProperties] = useState<Property[]>([]);
  const [employees, setEmployees] = useState([]);
  const [leadChannels, setLeadChannels] = useState([]);

  const initialFilter = usePipelineInitialFilter();
  const [filter, setFilter] = useState<PipelineFilter>(initialFilter);

  const contextValue = useMemo(
    () => ({
      properties,
      filter,
      setFilter,
      setProperties,
      employees,
      setEmployees,
      leadChannels,
      setLeadChannels,
      leadSearchFieldRef,
    }),
    [properties, filter, employees, leadChannels, leadSearchFieldRef],
  );

  return (
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    <PipelineContext.Provider value={contextValue}>
      {children}
    </PipelineContext.Provider>
  );
};

export default PipelineContextProvider;
