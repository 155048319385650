export const Amenities = {
  HAS_TV: 'TV',
  HAS_CABLE_TV: 'Cable TV',
  HAS_SMART_TV: 'Smart TV',
  HAS_AIR_CONDITIONING: 'Air Conditioning',
  HAS_HEATING: 'Heating',
  HAS_KITCHEN: 'Kitchen',
  HAS_KITCHENETTE: 'Kitchenette',
  HAS_INTERNET_WIFI: 'Internet Wifi',
  HAS_PAID_WIFI: 'Paid Wifi',
  HAS_WIFI_SPEED_25: 'Wifi speed (25+ Mbps)',
  HAS_WIFI_SPEED_50: 'Wifi speed (50+ Mbps)',
  HAS_WIFI_SPEED_100: 'Wifi speed (100+ Mbps)',
  HAS_WIFI_SPEED_250: 'Wifi speed (250+ Mbps)',
  HAS_WIFI_SPEED_500: 'Wifi speed (500+ Mbps)',
  HAS_WASHER: 'Washer',
  HAS_DRYER: 'Dryer',
  HAS_SHARED_WASHER: 'Shared Washer',
  HAS_SHARED_DRYER: 'Shared Dryer',
  HAS_POOL: 'Pool',
  HAS_POOL_ALL_YEAR: 'Pool (year round)',
  HAS_POOL_SEASONAL: 'Pool (seasonal)',
  HAS_COMMUNAL_POOL: 'Communal pool',
  HAS_HEATED_POOL: 'Heated pool',
  HAS_INDOOR_POOL: 'Indoor pool',
  HAS_INDOOR_POOL_ALL_YEAR: 'Indoor pool (year round)',
  HAS_INDOOR_POOL_SEASONAL: 'Indoor pool (seasonal)',
  HAS_FENCED_POOL: 'Fenced pool',
  HAS_FENCED_YARD: 'Fenced yard',
  HAS_HOT_TUB: 'Hot Tub',
  HAS_FREE_PARKING: 'Free Parking (On premises)',
  HAS_FREE_STREET_PARKING: 'Free Parking (On street)',
  HAS_PAID_OFF_PREMISES_PARKING: 'Paid Parking (Off premises)',
  HAS_PAID_ON_PREMISES_PARKING: 'Paid Parking (On premises)',
  HAS_EV_CAR_CHARGER: 'EV car charger',
  HAS_INDOOR_FIREPLACE: 'Indoor Fireplace',
  IS_WHEELCHAIR_ACCESSIBLE: 'Wheelchair Accessible',
  HAS_SINGLE_LEVEL: 'Single level home',
  HAS_SMOKE_DETECTOR: 'Smoke Detector',
  HAS_DEADBOLT_LOCK: 'Deadbolt lock',
  HAS_FIRE_EXTINGUISHER: 'Fire Extinguisher',
  HAS_EMERGENCY_EXIT: 'Emergency exit',
  HAS_OUTDOOR_LIGHTING: 'Outdoor lighting',
  HAS_ESSENTIALS: 'Essentials',
  HAS_BALCONY_TERRASSE: 'Balcony Terrasse',
  HAS_BABY_HIGH_CHAIR: 'Baby High Chair',
  HAS_BABY_TRAVEL_BED: 'Baby Travel Bed',
  HAS_CDDVD_PLAYER: 'CD/DVD Player',
  HAS_BOARD_GAMES: 'Board Games',
  HAS_BARBECUE: 'Barbecue',
  HAS_ELEVATOR: 'Elevator',
  HAS_DEHUMIDIFIER: 'Dehumidifier',
  HAS_CEILING_FAN: 'Ceiling Fan',
  HAS_VENTILATION_FAN: 'Ventilation Fan',
  HAS_HAIR_DRYER: 'Hair Dryer',
  HAS_CROCKERY_CUTLERY: 'Crockery and Cutlery',
  HAS_POTS_PANS: 'Pots Pans',
  HAS_OVEN: 'Oven',
  HAS_MICROWAVE_OVEN: 'Microwave Oven',
  HAS_WATER_KETTLE: 'Water Kettle',
  HAS_COFFEE_MAKER: 'Coffee Maker',
  HAS_DISHWASHER: 'Dishwasher',
  HAS_TOASTER: 'Toaster',
  HAS_FRIDGE: 'Fridge',
  HAS_KITCHEN_ISLAND: 'Kitchen island',
  HAS_DINING_TABLE: 'Dining table',
  HAS_ALARM_SYSTEM: 'Alarm System',
  HAS_BASKETBALL_COURT: 'Basketball court',
  HAS_CINEMA_ROOM: 'Cinema room',
  HAS_GATED_PROPERTY: 'Gated property',
  HAS_HELIPAD: 'Helipad',
  HAS_GYM: 'Gym',
  HAS_IPOD_STATION: 'Ipod Station',
  HAS_JACUZZI: 'Jacuzzi',
  HAS_STEAM_ROOM: 'Steam room',
  HAS_LIBRARY: 'Library',
  HAS_MASSAGE_ROOM: 'Massage room',
  HAS_OFFICE: 'Office / Desk',
  HAS_DESK: 'Desk',
  HAS_DESK_CHAIR: 'Desk chair',
  HAS_COMPUTER_MONITOR: 'Computer monitor',
  HAS_PRINTER: 'Printer',
  HAS_POOL_TABLE: 'Pool table',
  HAS_PIANO: 'Piano',
  HAS_SAFE_BOX: 'Safe box',
  HAS_CABINET_LOCKS: 'Cabinet locks',
  HAS_SEA_VIEW: 'Sea view',
  HAS_SMART_HOME: 'Smart home',
  HAS_SOCCER_FIELD: 'Soccer field',
  HAS_TENNIS: 'Tennis',
  HAS_TOILETRIES: 'Toiletries',
  HAS_WINE_CELLAR: 'Wine cellar',
  HAS_WARDROBE: 'Closet / Drawers',
  HAS_IRON: 'Iron',
  HAS_IRONING_FACILITIES: 'Ironing facilities',
  HAS_SHAMPOO: 'Shampoo',
  HAS_BREAKFAST: 'Breakfast',
  HAS_MEAL_DELIVERY: 'Meal delivery',
  HAS_BUZZER: 'Buzzer',
  HAS_DOORMAN: 'Doorman',
  HAS_CARBON_MONOXIDE_DETECTOR: 'Carbon monoxide detector',
  HAS_FIRST_AID_KIT: 'First aid kit',
  HAS_CAT: 'Has cat',
  HAS_DOG: 'Has dog',
  HAS_OTHER_PET: 'Has other pet',
  HAS_24_CHECKIN: '24 checkin',
  HAS_SAFETY_CARD: 'Safety card',
  HAS_HANGERS: 'Hangers',
  IS_LAPTOP_FRIENDLY: 'Laptop Friendly',
  HAS_LOCK_ON_BEDROOM: 'Lock on bedroom',
  HAS_PRIVATE_ENTRANCE: 'Private entrance',
  HAS_BABY_BATH: 'Baby bath',
  HAS_BABY_MONITOR: 'Baby monitor',
  HAS_BABYSITTER_RECOMMENDATIONS: 'Babysitter recommendations',
  HAS_BATHTUB: 'Bathtub',
  HAS_CHANGING_TABLE: 'Changing table',
  HAS_CHILDRENS_BOOKS_AND_TOYS: 'Children books & toys',
  HAS_OUTDOOR_PLAY_AREA: 'Outdoor play area',
  HAS_CHILDRENS_DINNERWARE: 'Children dinnerware',
  HAS_CHILDCARE: 'Childcare',
  HAS_FIREPLACE_GUARDS: 'Fireplace guards',
  HAS_GAME_CONSOLE: 'Game console',
  HAS_OUTLET_COVERS: 'Outlet covers',
  HAS_PACK_N_PLAY_TRAVEL_CRIB: 'Pack n Play travel crib',
  HAS_ROOM_DARKENING_SHADES: 'Room darkening shades',
  HAS_STAIR_GATES: 'Stair gates',
  HAS_TABLE_CORNER_GUARDS: 'Table corner guards',
  HAS_WINDOW_GUARDS: 'Window guards',
  HAS_LAKE_ACCESS: 'Lake access',
  HAS_BEACH_FRONT: 'Beach front',
  HAS_WATER_FRONT: 'Water front',
  HAS_SKI_IN_SKI_OUT: 'Ski-in/Ski-out',
  HAS_STOVE: 'Stove',
  HAS_LINENS: 'Linens Provided',
  HAS_TOWELS: 'Towels Provided',
  HAS_HOT_WATER: 'Hot Water',
  HAS_COOKING_BASICS: 'Cooking Basics',
  HAS_SURVEILLANCE: 'Surveillance',
  HAS_GARDEN: 'Garden',
  HAS_DECK_PATIO: 'Deck/Patio',
  HAS_AIR_FILTER: 'Air Filter',
  HAS_ENHANCED_CLEANING: 'Enhanced Cleaning',
  HAS_CLEANING_WITH_DISINFECTANTS: 'Uses Disinfectants',
  HAS_HIGH_TOUCH_SURFACES_CLEANING_WITH_DISINFECTANTS:
    'Uses Disinfectants for high-touch surfaces',
  HAS_CONTACTLESS_CHECKIN: 'Contactless checkin',
  HAS_IN_PERSON_CHECKIN: 'In person checkin',
  HAS_CONCIERGE: 'Concierge',
  HAS_OCEAN_FRONT: 'Ocean front',
  IS_RESORT: 'Resort',
  IS_RURAL: 'Rural',
  HAS_TOWN: 'Town',
  HAS_WATER_VIEW: 'Water view',
  IS_DOWNTOWN: 'Downtown',
  HAS_GOLF_COURSE_VIEW: 'Golf course view',
  IS_LAKEFRONT: 'Lake front',
  HAS_MOUNTAIN: 'Mountain',
  IS_NEAR_OCEAN: 'Near ocean',
  HAS_RIVER: 'River',
  HAS_VILLAGE: 'Village',
  HAS_BEACH: 'Beach',
  HAS_BEACH_VIEW: 'Beach view',
  IS_GOLF_COURSE_FRONT: 'Golf course front',
  HAS_LAKE: 'Lake',
  HAS_LAKE_VIEW: 'Lake view',
  HAS_MOUNTAIN_VIEW: 'Mountain view',
  HAS_BAKING_SHEET: 'Baking sheet',
  HAS_BARBEQUE_UTENSILS: 'Barbeque utensils',
  HAS_BEACH_ESSENTIALS: 'Beach essentials',
  HAS_BIDET: 'Bidet',
  HAS_BIKES_FOR_RENT: 'Bikes',
  HAS_BLENDER: 'Blender',
  HAS_BOAT_SLIP: 'Boat slip',
  HAS_BODY_SOAP: 'Body soap',
  HAS_BREAD_MAKER: 'Bread maker',
  HAS_CLEANING_PRODUCTS: 'Cleaning products',
  HAS_CLOTHES_DRYING_RACK: 'Drying rack for clothing',
  HAS_COFFEE: 'Coffee',
  HAS_CONDITIONER: 'Conditioner',
  HAS_ETHERNET_CONNECTION: 'Ethernet connection',
  HAS_EXERCISE_EQUIPMENT: 'Exercise equipment',
  HAS_EXTRA_PILLOWS_AND_BLANKETS: 'Extra pillows and blankets',
  HAS_FIRE_PIT: 'Fire pit',
  HAS_FREEZER: 'Freezer',
  HAS_HAMMOCK: 'Hammock',
  HAS_LAUNDROMAT_NEARBY: 'Laundromat nearby',
  HAS_KAYAK: 'Kayak',
  HAS_MINI_FRIDGE: 'Mini fridge',
  HAS_MOSQUITO_NET: 'Mosquito net',
  HAS_OUTDOOR_KITCHEN: 'Outdoor kitchen',
  HAS_OUTDOOR_SEATING: 'Outdoor seating',
  HAS_PING_PONG_TABLE: 'Ping Pong Table',
  HAS_POCKET_WIFI: 'Pocket wifi',
  HAS_PORTABLE_FANS: 'Portable fans',
  HAS_RAIN_SHOWER: 'Rain shower',
  HAS_RECORD_PLAYER: 'Record player',
  HAS_RESORT_ACCESS: 'Resort access',
  HAS_RICE_MAKER: 'Rice maker',
  HAS_SAFE: 'Safe',
  HAS_SHOWER_GEL: 'Shower gel',
  HAS_SOUND_SYSTEM: 'Sound system',
  HAS_TRASH_COMPACTER: 'Trash compactor',
  HAS_WINE_GLASSES: 'Wine glasses',
  INVOICE_PROVIDED: 'Invoices issued on request',
};

export const RuleType = {
  ALLOWS_PETS: 'Allow Pets',
  ALLOWS_CHILDREN: 'Allow Children',
  CHILDREN_NOT_ALLOWED: 'Children Not Allowed',
  INFANT_NOT_ALLOWED: 'Infant Not Allowed',
  ALLOWS_SMOKING: 'Allows Smoking',
  IS_FAMILY_FRIENDLY: 'Family Friendly',
  IS_EVENT_FRIENDLY: 'Event Friendly',
  OTHER: 'Other',
};
