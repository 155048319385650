import { useQueryClient } from '@tanstack/react-query';
import { PRICING_RULES_BASE_QUERY_KEY } from 'components/domain/propertyPricing/pricingRulesQuery';
import useBulkCreatePricingRule from 'components/domain/propertyPricing/useBulkCreatePricingRule';
import useBulkUpdatePricingRule from 'components/domain/propertyPricing/useBulkUpdatePricingRule';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import useAppModal from 'hooks/useAppModal';
import {
  PricingRule,
  PricingRuleBulkCreatePayload,
  PricingRuleBulkUpdatePayload,
} from 'models/pricing/PricingRule';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { PriceRulesAddEditFormValues } from './PriceRulesAddEditModal.schema';

const usePriceRulesAddEditModalFormSubmit = ({
  modalId,
  originalPricingRule,
}: {
  modalId: string;
  originalPricingRule?: PricingRule;
}) => {
  const queryClient = useQueryClient();
  const { closeModal } = useAppModal();
  const {
    mutateAsync: bulkCreatePricingRule,
    isLoading: isCreatingPricingRule,
  } = useBulkCreatePricingRule();
  const {
    mutateAsync: bulkUpdatePricingRule,
    isLoading: isUpdatingPricingRule,
  } = useBulkUpdatePricingRule();

  const isUpdating = isCreatingPricingRule || isUpdatingPricingRule;

  const onSubmit = async (data: PriceRulesAddEditFormValues) => {
    const isNew = !originalPricingRule;
    const { priceRule } = data;
    const { updateAllProperties, propertiesUids } =
      extractPropertiesMultiSelectFields('appliesTo', data);

    const commonPayload:
      | PricingRuleBulkCreatePayload
      | PricingRuleBulkUpdatePayload = {
      ...priceRule,
      priceModifier:
        priceRule.type === PropertyPricingType.SHORT_STAY_PREMIUM
          ? Math.abs(priceRule.priceModifier)
          : -Math.abs(priceRule.priceModifier),
      applyToAllProperties: updateAllProperties,
      // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      applyToPropertiesUids: updateAllProperties ? [] : propertiesUids,
    };

    if (isNew) {
      await bulkCreatePricingRule(commonPayload);
    } else {
      await bulkUpdatePricingRule({
        uid: originalPricingRule.uid,
        ...commonPayload,
      });
    }

    await queryClient.invalidateQueries([PRICING_RULES_BASE_QUERY_KEY]);

    closeModal(modalId);
  };

  return { isUpdating, onSubmit };
};

export default usePriceRulesAddEditModalFormSubmit;
