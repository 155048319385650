import { Lead } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Tooltip';
import { AirbnbAlterationType } from 'components/domain/airbnbAlterationRequest/modal/AirbnbAlteration.types';
import { LeadListTag } from './LeadListTag.styles';

const LeadListItemAlterationRequestedTag = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { airbnbAlteration } = lead;

  if (!airbnbAlteration) {
    return null;
  }

  return (
    <Tooltip
      text={t(
        airbnbAlteration?.type === AirbnbAlterationType.GUEST
          ? 'componentLead.statusIndicator.alterationTooltipGuest'
          : 'componentLead.statusIndicator.alterationTooltipHost',
      )}
      id="lead-alteration-requested-tooltip"
    >
      <LeadListTag
        backgroundColor={
          airbnbAlteration?.type === AirbnbAlterationType.GUEST
            ? '#FF7254'
            : '#ffc454'
        }
        data-testid="lead-alteration-requested-tag"
      >
        {t('componentLead.statusIndicator.alteration')}
      </LeadListTag>
    </Tooltip>
  );
};

export default LeadListItemAlterationRequestedTag;
