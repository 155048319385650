import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';
import { ownerAdjustmentsModalId } from './OwnerAdjustmentModal.constants';
import useOwnerAdjustmentModalForm from './hooks/useOwnerAdjustmentModalForm';
import useDeleteFileMutation from './hooks/useDeleteFileMutation';
import useCurrentSelectedFile from './hooks/useCurrentSelectedFile';

const OwnerAdjustmentModalFooter = () => {
  const { t } = useTranslation();
  const { isUpdating } = useOwnerAdjustmentModalForm();
  const { formState } = useFormContext();
  const { currentFile } = useCurrentSelectedFile();
  const { closeModal } = useAppModal();
  const { isSubmitting, errors } = formState;
  const { mutate: deleteFile } = useDeleteFileMutation();

  const cancelHandler = () => {
    // if an upload was made, delete the file when clicking on cancel
    if (
      currentFile &&
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      currentFile.url !== formState.defaultValues.attachedImageURL
    ) {
      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      deleteFile(currentFile);
    }
    closeModal(ownerAdjustmentsModalId);
  };

  return (
    <>
      <Col sm={12}>
        {errors.invalid && (
          <p className="text-danger text-center">
            {errors.invalid.message?.toString()}
          </p>
        )}
      </Col>
      <Col sm={12}>
        <Button
          data-testid="owner-adjustment-modal-cancel-button"
          disabled={isSubmitting || isUpdating}
          onClick={cancelHandler}
        >
          {t('common.cancel')}
        </Button>
        <Button
          data-testid="owner-adjustment-modal-save-button"
          bsStyle="primary"
          disabled={isSubmitting || isUpdating}
          type="submit"
        >
          {isSubmitting || isUpdating ? t('common.saving') : t(`common.save`)}
        </Button>
      </Col>
    </>
  );
};

export default OwnerAdjustmentModalFooter;
