import useAppConfig from 'hooks/useAppConfig';
import SSLImage from 'assets/images/SSL.png';
import PCIImage from 'assets/images/PCI.png';
import CopyrightNotice from 'components/text/CopyrightNotice';
import {
  LayoutDesktopCustomFooterStyled,
  LayoutDesktopFooterStyled,
  VersionNumber,
} from './LayoutDesktop.styles';

const LayoutDesktopFooter = () => {
  const {
    customSettings: { customFooterContent },
    showVersionNumber,
  } = useAppConfig();

  if (customFooterContent) {
    return (
      <LayoutDesktopCustomFooterStyled>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: customFooterContent }} />
      </LayoutDesktopCustomFooterStyled>
    );
  }

  return (
    <LayoutDesktopFooterStyled>
      <div className="container">
        <div className="certificates">
          <img src={SSLImage} alt="SSL Security" />
          <img src={PCIImage} alt="PCI Compliance" />
        </div>
        <p>
          <CopyrightNotice />
          {/*
           // @ts-expect-error TS7017 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified */}
          {showVersionNumber && !!globalThis.versionNumber && (
            // @ts-expect-error TS7017 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            <VersionNumber>{` - ${globalThis.versionNumber}`}</VersionNumber>
          )}
        </p>
      </div>
    </LayoutDesktopFooterStyled>
  );
};

export default LayoutDesktopFooter;
