import { useQueryClient } from '@tanstack/react-query';
import useFetchPropertyByUid, {
  PROPERTY_BY_UID_BASE_QUERY_KEY,
} from 'components/domain/property/useFetchPropertyByUid';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';

const useVideoTourURL = () => {
  const { notifyError, notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const queryClient = useQueryClient();
  const { data: property, isLoading } = useFetchPropertyByUid({
    // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    propertyUid,
  });

  const videoTourURL = property?.integrationsData?.panoramicDataUrl;

  const { mutate: updateVideoTourURL, isLoading: isUpdating } = useAppMutation(
    (panoramicDataUrl: string) => {
      return API.patch(`/v3/properties/${propertyUid}`, {
        integrationsData: {
          panoramicDataUrl,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [PROPERTY_BY_UID_BASE_QUERY_KEY],
        });

        notifySuccess(t('pageProperty.photos.propertyVideoTour.updateSuccess'));
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  return {
    updateVideoTourURL,
    isUpdating,
    videoTourURL,
    isLoading,
  };
};

export default useVideoTourURL;
