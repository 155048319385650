import { differenceInCalendarDays } from 'date-fns';
import useFetchPropertiesCalendar from '../property/useFetchPropertiesCalendar';

const useCheckLeadMinimumStay = ({
  propertyUid,
  startDate,
  endDate,
  enabled,
}: {
  propertyUid: string;
  startDate: Date;
  endDate: Date;
  enabled: boolean;
}) => {
  const { propertiesCalendar } = useFetchPropertiesCalendar({
    propertiesUids: [propertyUid],
    startDate,
    endDate: startDate,
    enabled,
  });
  const nightsCount = differenceInCalendarDays(endDate, startDate);

  return {
    isMinimumStayNotSatisfied:
      // @ts-expect-error TS2532 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      propertiesCalendar?.find(
        (calendar) => calendar.propertyUid === propertyUid,
      )?.entries[0].availability.minimumStayLength > nightsCount,
  };
};

export default useCheckLeadMinimumStay;
