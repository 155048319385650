import PageHeader from 'components/pageHeader/PageHeader';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { Trans, useTranslation } from 'react-i18next';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { Link } from 'react-router-dom';
import { ServiceProviderTableItem } from '../../useServiceProvidersList';

const ServiceProvidersHeader = ({
  serviceProviders,
}: {
  serviceProviders: ServiceProviderTableItem[];
}) => {
  const { t } = useTranslation();
  const { property, isLoading } = usePropertySettingsInitData();

  const hasServiceProviders = serviceProviders.length > 0;

  if (isLoading) {
    return (
      <div>
        <PageHeader
          className="service-providers-title"
          title={
            <Trans
              i18nKey="pageProperty.serviceProviders.tabTitle"
              values={{ propertyName: property?.name }}
              components={{ 1: <span className="green" /> }}
            />
          }
          noDivider
          isLoading
        />

        <LoadingPlaceholder linesCount={1} />
        <LoadingPlaceholder linesCount={1} />
        <LoadingPlaceholder linesCount={1} />
      </div>
    );
  }

  return (
    <div>
      <PageHeader
        className="service-providers-title"
        title={
          <Trans
            i18nKey="pageProperty.serviceProviders.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        noDivider
      />

      {!hasServiceProviders && (
        <div>
          <p>{t('pageProperty.serviceProviders.notFound.title')}</p>
          <p>{t('pageProperty.serviceProviders.notFound.description')}</p>
        </div>
      )}

      {hasServiceProviders && (
        <div>
          <p>{t('pageProperty.serviceProviders.turnOnOff')}</p>
          <ul>
            <li data-testid="job-creation-link">
              <Trans
                i18nKey="pageProperty.serviceProviders.jobCreation"
                components={{
                  bold: <b />,
                  1: (
                    <Link
                      to="https://help.hostfully.com/en/articles/1662715-managing-turnover-jobs"
                      target="_blank"
                    />
                  ),
                }}
              />
            </li>
            <li data-testid="up-sell-link">
              <Trans
                i18nKey="pageProperty.serviceProviders.upSell"
                components={{
                  bold: <b />,
                  1: (
                    <Link
                      to="https://help.hostfully.com/en/articles/2899851-upselling-items-from-your-direct-website-checkout-flow"
                      target="_blank"
                    />
                  ),
                }}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ServiceProvidersHeader;
