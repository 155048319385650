import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import HvmiIcon from 'assets/images/icons/channels/hvmi.svg?react';
import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg?react';
import VrboIcon from 'assets/images/icons/channels/vrbo.svg?react';
import { Channel } from 'models/Channels';
import { PropertyPricingType } from 'models/pricing/PropertyPricingType';
import { PriceRulesChannelsPerTypeStyled } from './PriceRulesChannelsPerType.styles';

interface PriceRulesChannelsPerTypeProps {
  type: PropertyPricingType;
}

const TypeToChannelMapping: { [K in PropertyPricingType]: Channel[] } = {
  [PropertyPricingType.EARLY_BIRD_DISCOUNT]: [
    Channel.orbirental,
    Channel.airbnb,
    Channel.hvmi,
  ],
  [PropertyPricingType.LAST_MINUTE_DISCOUNT]: [
    Channel.orbirental,
    Channel.airbnb,
    Channel.hvmi,
  ],
  [PropertyPricingType.LOS_DISCOUNT]: [
    Channel.orbirental,
    Channel.airbnb,
    Channel.homeaway,
    Channel.hvmi,
  ],
  [PropertyPricingType.SHORT_STAY_PREMIUM]: [Channel.orbirental],
};

const PriceRulesChannelsPerType = ({
  type,
}: PriceRulesChannelsPerTypeProps) => {
  const mapping = TypeToChannelMapping[type];

  return (
    <PriceRulesChannelsPerTypeStyled>
      {mapping.includes(Channel.orbirental) && (
        <HostfullyIcon data-testid="hostfully-icon" />
      )}
      {mapping.includes(Channel.airbnb) && (
        <AirbnbIcon data-testid="airbnb-icon" />
      )}
      {mapping.includes(Channel.homeaway) && (
        <VrboIcon data-testid="vrbo-icon" />
      )}
      {mapping.includes(Channel.hvmi) && <HvmiIcon data-testid="hvmb-icon" />}
    </PriceRulesChannelsPerTypeStyled>
  );
};

export default PriceRulesChannelsPerType;
