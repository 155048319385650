import { NavLink, useParams } from 'react-router-dom';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import { calendarModeLocalStorageKey } from 'pages/stackedCalendar/Calendar.constants';
import { useTranslation } from 'react-i18next';
import { PricingAlertContainer } from './PropertySettingsPricingTabHeader.styles';

const PropertySettingsPricingManagementAlert = () => {
  const { uid } = useParams();
  const { t } = useTranslation();

  const setCalendarPricingMode = () => {
    localStorage.setItem(
      calendarModeLocalStorageKey,
      StackedCalendarMode.PRICING,
    );
  };

  return (
    <PricingAlertContainer className="bd-callout bd-callout-warning">
      <h4>{t('pageProperty.pricing.pricingManagement.title')}</h4>

      <div>{t('pageProperty.pricing.pricingManagement.description')}</div>

      <NavLink
        to={`/calendar?propertyUid=${uid}`}
        onClick={setCalendarPricingMode}
      >
        {t('pageProperty.pricing.pricingManagement.calendarBtn')}
      </NavLink>
    </PricingAlertContainer>
  );
};

export default PropertySettingsPricingManagementAlert;
