import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'store/store.constants';

interface SidePanelState {
  activeSidePanels: {
    [sidePanelId: string]: unknown;
  };
}

const initialState: SidePanelState = {
  activeSidePanels: {},
};

const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setActiveSidePanel: (
      state,
      { payload: { sidePanelId } }: PayloadAction<{ sidePanelId: string }>,
    ) => {
      state.activeSidePanels[sidePanelId] = {};
    },
    removeActiveSidePanel: (
      state,
      { payload: { sidePanelId } }: PayloadAction<{ sidePanelId: string }>,
    ) => {
      delete state.activeSidePanels[sidePanelId];
    },
  },
});

export const { setActiveSidePanel, removeActiveSidePanel } =
  sidePanelSlice.actions;

export default sidePanelSlice.reducer;
