/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useContext, useState } from 'react';
import TextField from 'components/forms/textField/TextField';
import { useTranslation } from 'react-i18next';
import { Col, Glyphicon, Row } from 'react-bootstrap';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { canSetHomeAwayAsSource } from 'utils/lead/leadUtils';
import { LeadChannel, LeadSource } from 'models/Leads';
import Tooltip from 'components/tooltip/Tooltip';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { CustomDataFieldUsage } from 'models/CustomDataField';
import LeadModalContext from '../../LeadModalContext';
import useInviteGuestData from '../../useInviteGuestData';
import {
  CustomDataColumn,
  CustomDataPageHeader,
  CustomDataWrapper,
  LeadDataFormColumn,
  LeadDataFormRow,
} from './LeadModalDataTab.styles';
import LeadCustomData from './LeadCustomData';
import BookingComData from './BookingComData';

const LeadModalDataTab = () => {
  const { lead, channelSettings } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const { sendInvite } = useInviteGuestData();
  const [isInviteSend, setIsInviteSend] = useState<boolean>(false);
  const { getCopyAddonIcon } = useCopyToClipboard();

  const getOpenLinkAddon = (url: string) => {
    return (
      <Tooltip id="copy-addon-tooltip" text={t('common.openLink')}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Glyphicon glyph="new-window" />
        </a>
      </Tooltip>
    );
  };

  const isConnectedToVRBO = !!channelSettings?.find(
    (channelSetting) =>
      (channelSetting.name === LeadChannel.VRBO ||
        channelSetting.name === 'HomeAway') &&
      channelSetting.isActive,
  );

  const ableToSetHomeAwayAsSource = canSetHomeAwayAsSource(lead);
  const showAirbnbData =
    lead?.source === LeadSource.DIRECT_AIRBNB && lead?.externalBookingID;
  const showVRBOData =
    lead?.source === LeadSource.DIRECT_HOMEAWAY && lead?.externalBookingID;
  const showBookingComData =
    lead?.source === LeadSource.DIRECT_BOOKINGDOTCOM &&
    lead?.externalBookingID &&
    lead?.bookingDotComData;
  const showCustomData =
    lead?.customData?.filter(
      // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      (singleCustomData) =>
        singleCustomData.usage === CustomDataFieldUsage.CUSTOM_VARIABLES,
    ).length > 0;

  return (
    <Row>
      <LeadDataFormColumn md={10}>
        <LeadDataFormRow>
          <TextField
            disabled
            label={t('componentLead.modal.dataTab.uid')}
            name="uid"
            // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            rightAddons={[getCopyAddonIcon(lead?.uid)]}
            colSmInput={5}
            colSmLabel={3}
          />

          {isConnectedToVRBO && ableToSetHomeAwayAsSource && (
            <CheckboxField name="attributeToVrbo">
              {t('componentLead.modal.dataTab.attributeThisBookingToVRBO')}
            </CheckboxField>
          )}
        </LeadDataFormRow>

        {lead?.rentalAgreementURL && (
          <LeadDataFormRow>
            <TextField
              disabled
              label={t('componentLead.modal.dataTab.rentalAgreementURL')}
              name="rentalAgreementURL"
              rightAddons={[
                getOpenLinkAddon(lead?.rentalAgreementURL),
                getCopyAddonIcon(lead?.rentalAgreementURL),
              ]}
              colSmInput={5}
              colSmLabel={3}
            />
          </LeadDataFormRow>
        )}

        <LeadDataFormRow>
          <TextField
            label={t('componentLead.modal.dataTab.guestInvite')}
            name="guestAccessUrl"
            disabled
            colSmInput={5}
            colSmLabel={3}
            rightAddons={
              !!lead?.guest?.registrationToken && [
                getCopyAddonIcon(
                  `${window.location.origin}/app/#/login?code=${lead?.guest?.registrationToken}`,
                ),
              ]
            }
            value={
              !!lead?.guest?.registrationToken &&
              `${window.location.origin}/app/#/login?code=${lead?.guest?.registrationToken}`
            }
          />

          {!!lead?.guest?.uid && (
            <Col xs={4}>
              {!isInviteSend ? (
                <button
                  className="btn btn-xs btn-link"
                  type="button"
                  onClick={() => {
                    sendInvite(lead?.guest?.uid, () => {
                      setIsInviteSend(true);
                    });
                  }}
                >
                  {t('componentLead.modal.dataTab.sendInviteTo')} {lead?.email}
                </button>
              ) : (
                t('componentLead.modal.dataTab.emailSent')
              )}
            </Col>
          )}
        </LeadDataFormRow>

        <TextField
          disabled
          label={t('componentLead.modal.dataTab.createMetadata')}
          name="creationMetadata"
          rightAddons={
            !!lead?.creationMetadata && [
              getCopyAddonIcon(lead?.creationMetadata),
            ]
          }
          colSmInput={5}
          colSmLabel={3}
        />
      </LeadDataFormColumn>

      {(showAirbnbData || showVRBOData) && (
        <CustomDataWrapper data-testid="custom-data-wrapper-airbnb">
          <CustomDataColumn md={12}>
            <CustomDataPageHeader
              title={`${
                showAirbnbData
                  ? t('componentLead.modal.dataTab.customDataAirbnbTitle')
                  : t('componentLead.modal.dataTab.customDataVRBOTitle')
              }`}
            />
          </CustomDataColumn>
          <CustomDataColumn md={10}>
            <LeadDataFormRow>
              <TextField
                disabled
                label={t('componentLead.modal.dataTab.reservationID')}
                name="externalBookingID"
                rightAddons={[
                  getCopyAddonIcon(
                    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
                    lead?.externalBookingID ||
                      lead?.bookingDotComData?.reservationId,
                  ),
                ]}
                colSmInput={5}
                colSmLabel={3}
              />
            </LeadDataFormRow>
          </CustomDataColumn>
        </CustomDataWrapper>
      )}

      {showBookingComData && <BookingComData lead={lead} />}

      {showCustomData && (
        <CustomDataWrapper data-testid="custom-data-wrapper-custom">
          <CustomDataColumn md={12}>
            <CustomDataPageHeader
              title={`${t('componentLead.modal.dataTab.customDataTitle')}`}
              subtitle={
                <span>
                  {`${t('componentLead.modal.dataTab.customDataSubtitle')} `}
                  <a
                    href="#/my-templates#customdata"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('componentLead.modal.dataTab.customDataSubtitleLink')}
                  </a>
                </span>
              }
            />
          </CustomDataColumn>
          <CustomDataColumn md={10}>
            <LeadCustomData />
          </CustomDataColumn>
        </CustomDataWrapper>
      )}
    </Row>
  );
};

export default LeadModalDataTab;
