import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { useSelectModeContext } from '../../context/SelectModeContext';
import usePhotoMutationStatus from '../../hooks/usePhotoMutationStatus';
import useDeleteSelectedPhotos from './useDeleteSelectedPhotos';
import { PageTitleDeleteButtonContainer } from './PageTitleDeleteButton.styles';

const PageTitleDeleteButton = () => {
  const { t } = useTranslation();
  const { selectedPhotos } = useSelectModeContext();
  const { handleDelete } = useDeleteSelectedPhotos();
  const { isUpdatingPhotos } = usePhotoMutationStatus();

  const hasSelectedPhotos = selectedPhotos.size > 0;

  return (
    <PageTitleDeleteButtonContainer>
      <div>
        {hasSelectedPhotos
          ? t('pageProperty.photos.selectedPhotos', {
              count: selectedPhotos.size || 0,
            })
          : t('pageProperty.photos.selectPhotos')}
      </div>
      {hasSelectedPhotos && (
        <Button
          bsStyle="danger"
          onClick={handleDelete}
          data-testid="delete-selected-button"
          disabled={isUpdatingPhotos}
        >
          {t('pageProperty.photos.deleteSelected')}
        </Button>
      )}
    </PageTitleDeleteButtonContainer>
  );
};

export default PageTitleDeleteButton;
