import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const DisplayFieldRowStyled = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  & label {
    float: right;
  }
`;
