import { useTranslation } from 'react-i18next';
import { Photo } from 'models/Photos';
import useNotify from 'hooks/useNotify';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';

export const UPDATE_PHOTO_MUTATION_KEY = ['updatePhoto'];

const usePhotoMutation = () => {
  const { t } = useTranslation();
  const { notifyError } = useNotify();

  const { mutateAsync: updatePhoto, isLoading: isUpdatingPhoto } =
    useAppMutation(
      UPDATE_PHOTO_MUTATION_KEY,
      ({
        updatedPhoto,
        photoUid,
      }: {
        updatedPhoto: Partial<Photo>;
        photoUid: string;
      }) => {
        return API.patch(`/api/v3.2/photos/${photoUid}`, {
          ...updatedPhoto,
        });
      },
      {
        onError: (error: any) => {
          notifyError(
            getErrorMessage({
              apiErrorCode: error.response.data?.apiErrorCode,
              apiErrorMessage: error.response.data?.apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
        },
      },
    );

  return {
    updatePhoto,
    isUpdatingPhoto,
  };
};

export default usePhotoMutation;
