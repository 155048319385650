import { AmenityChannel } from './Amenity';
import { BaseErrorResponse, BaseResponse } from './_base';

export enum RuleType {
  ALLOWS_PETS = 'ALLOWS_PETS',
  ALLOWS_SMOKING = 'ALLOWS_SMOKING',
  CHILDREN_NOT_ALLOWED = 'CHILDREN_NOT_ALLOWED',
  INFANT_NOT_ALLOWED = 'INFANT_NOT_ALLOWED',
  IS_EVENT_FRIENDLY = 'IS_EVENT_FRIENDLY',
  IS_FAMILY_FRIENDLY = 'IS_FAMILY_FRIENDLY',
}

export interface PropertyRule {
  uid: string;
  rule: RuleType;
  propertyUid: string;
  description: string;
}

export interface GetPropertyRulesResponse extends BaseResponse {
  propertyRules: PropertyRule[];
}

export interface AvailableRule {
  rule: RuleType;
  channels: AmenityChannel[];
  supportsDescription?: boolean;

  // front-end implementations:
  disabled?: boolean;
}

export interface GetAvailableRulesResponse {
  rules: AvailableRule[];
}

export type PropertyRulesUpdatePayload = {
  rule: RuleType;
  description: string | undefined;
  state: 'OFF' | 'ON';
}[];

export interface PropertyRulesBulkUpdatePayload {
  rules: PropertyRulesUpdatePayload;
  applyToAll: boolean;
  propertyUids?: string[];
}

export interface PropertyRulesBulkUpdateResponse extends BaseErrorResponse {}
