import { MutableRefObject, useCallback, useContext, useRef } from 'react';
import { addDays, isSameDay, subDays } from 'date-fns';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  setLoadedDateRange,
  setVisibleDates,
} from 'store/slices/stackedCalendar';
import { debounce } from 'utils/funcs';
import { isSafari } from 'react-device-detect';
import CalendarContext from '../CalendarContext';
import { stackedCalendarStyleConstants } from '../Calendar.constants';
import { StackedCalendarMode } from '../Calendar.types';
import { CalendarDaysLoadDirection } from './StackedCalendarNavigation.types';

const loadBatchSizeByMode = {
  [StackedCalendarMode.BOOKING]: 46,
  [StackedCalendarMode.PRICING]: 31,
  [StackedCalendarMode.COMBINED]: 31,
};

function isSameAsCurrent(currentDate: Date | undefined, date: Date) {
  return currentDate && isSameDay(currentDate, date);
}

const useStackedCalendarLoadMoreDays = () => {
  const dispatch = useAppDispatch();
  const { mode } = useContext(CalendarContext);
  const visibleDates = useAppSelector(
    (state) => state.stackedCalendar.visibleDates,
  );
  const loadingTriggerDate = useRef<Date>();

  return useCallback(
    debounce(
      (
        daysLoadDirection: CalendarDaysLoadDirection,
        scrollableElement: HTMLDivElement,
        scrollingInProgressRef: MutableRefObject<boolean>,
      ) => {
        const loadBatchSize = loadBatchSizeByMode[mode];

        if (daysLoadDirection === CalendarDaysLoadDirection.PAST) {
          const firstDate = visibleDates[0];

          if (isSameAsCurrent(loadingTriggerDate.current, firstDate)) {
            scrollingInProgressRef.current = false;
            return;
          }

          loadingTriggerDate.current = firstDate;

          const datesToLoad = Array.from(new Array(loadBatchSize)).map(
            (_, index) => subDays(firstDate, loadBatchSize - index),
          );
          const updatedVisibleDates = [
            ...datesToLoad,
            ...visibleDates.slice(0, visibleDates.length - loadBatchSize),
          ];

          dispatch(setVisibleDates(updatedVisibleDates));
          dispatch(
            setLoadedDateRange({
              from: updatedVisibleDates[1],
              to: updatedVisibleDates[datesToLoad.length],
            }),
          );

          const scrollOffset =
            stackedCalendarStyleConstants.daysCellWidth[mode] * loadBatchSize;
          scrollableElement.scrollLeft += scrollOffset;
        }

        if (daysLoadDirection === CalendarDaysLoadDirection.FUTURE) {
          const lastDate = visibleDates[visibleDates.length - 1];

          if (isSameAsCurrent(loadingTriggerDate.current, lastDate)) {
            scrollingInProgressRef.current = false;
            return;
          }

          loadingTriggerDate.current = lastDate;

          const datesToLoad = Array.from(new Array(loadBatchSize)).map(
            (_, index) => addDays(lastDate, index + 1),
          );
          const updatedVisibleDates = [
            ...visibleDates.slice(loadBatchSize),
            ...datesToLoad,
          ];

          dispatch(setVisibleDates(updatedVisibleDates));
          dispatch(
            setLoadedDateRange({
              from: updatedVisibleDates[
                updatedVisibleDates.length - datesToLoad.length - 1
              ],
              to: updatedVisibleDates[updatedVisibleDates.length - 2],
            }),
          );

          const scrollOffset =
            stackedCalendarStyleConstants.daysCellWidth[mode] * loadBatchSize;
          scrollableElement.scrollLeft -= scrollOffset;
        }

        scrollingInProgressRef.current = false;
      },
      isSafari ? 1000 : 200,
    ),
    [visibleDates, mode],
  );
};

export default useStackedCalendarLoadMoreDays;
