import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import { useFormContext, useWatch } from 'react-hook-form';
import { ImportType } from '../modal/ImportBookingDotComPropertiesModal.utils';
import ImportBookingDotComPropertiesContext from '../modal/ImportBookingDotComPropertiesContext';
import PropertiesListInput from './form/PropertiesListInput';

const BodyModalConnectionWithProperties = () => {
  const { t } = useTranslation();
  const { importType } = useWatch();
  // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const { importableProperties } = useContext(
    ImportBookingDotComPropertiesContext,
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    if (
      importType === ImportType.SELECTED_PROPERTY &&
      importableProperties?.length > 0
    ) {
      setValue(
        'propertyIds',
        // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        importableProperties.map((property) => property.bookingDotComId),
      );
    }
  }, [importType, importableProperties, setValue]);

  return (
    <Col xs={12}>
      <p>
        {t(
          'pageProperties.importBookingDotComPropertiesModal.selectPropertiesToImport',
        )}
      </p>
      <RadioGroupField
        name="importType"
        options={[
          {
            value: ImportType.ALL_PROPERTIES,
            label: t(
              'pageProperties.importBookingDotComPropertiesModal.importAllProperties',
            ),
          },
          {
            value: ImportType.SELECTED_PROPERTY,
            label: t(
              'pageProperties.importBookingDotComPropertiesModal.importSelectedProperties',
            ),
          },
        ]}
      />

      <PropertiesListInput />
    </Col>
  );
};

export default BodyModalConnectionWithProperties;
