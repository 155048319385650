import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import axios from 'axios';
import { BaseResponse } from 'models/_base';

export type CleaningOffer = {
  value: number;
  currency: string;
  cleaner: {
    first_name: string;
    image_url: string;
    rating: string;
    cleaner_profile: string;
    number_of_reviews: number;
  };
};

export interface CleaningOffersResponse extends BaseResponse {
  data: {
    suggested_price: string;
    cleaning_offers: CleaningOffer[];
  };
}

const token = import.meta.env.VITE_TURNO_SECRET_KEY;
const partnerId = import.meta.env.VITE_TURNO_PARTNER_ID;

const useTurnoCleaners = () => {
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    agency: { zipCode },
  } = useAppUser();

  const { data: cleaners, isInitialLoading: cleanersLoading } = useAppQuery(
    ['turno-cleaning-fee', zipCode],
    async () => {
      const response = await axios.get<CleaningOffersResponse>(
        `https://api.turnoverbnb.com/v2/cleaning-fee`,
        {
          params: {
            bedrooms: 2,
            'zip-code': zipCode,
          },
          headers: {
            'Tbnb-Partner-Id': partnerId,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response?.data?.data?.cleaning_offers;
    },
    { enabled: !!zipCode && !!token && !!partnerId },
  );

  return { cleaners, cleanersLoading };
};

export default useTurnoCleaners;
