import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContainer } from 'pages/stackedCalendar/header/StackedCalendarHeader.styles';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import Button from 'react-bootstrap/lib/Button';
import * as Yup from 'yup';
import useAppDispatch from 'hooks/useAppDispatch';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import { PropertyBusinessType } from 'models/Properties';
import {
  resetPropertiesEntries,
  setBodyRefreshRequest,
} from 'store/slices/stackedCalendar';
import usePropertyEditPath from 'pages/property/usePropertyEditPath';
import { trimAndLimitCharactersNumber } from '../../../utils/stringUtils';
import PropertyChannelsWidget from '../../../components/domain/property/channelsWidget/PropertyChannelsWidget';
import { BackProperties, ButtonContainer } from './CalendarHeader.styles';

const propertySchema = () => ({
  property: Yup.string(),
});

const CalendarHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    selectedProperty,
    allProperties,
    propertiesMap,
    setSelectedProperty,
    totalPropertiesNumber,
  } = useContext(CalendarContext);
  const { isXS, isSM } = useScreenBreakpoints();
  const getPropertyEditPath = usePropertyEditPath();

  const handleBackButtonClick = () => {
    // the propertiesEntries should be resetted because units are not rendered by default,
    // and when it renders, it should consider the new dates range.
    dispatch(resetPropertiesEntries());
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    dispatch(setBodyRefreshRequest(null));
    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setSelectedProperty(null);
  };

  const getBackButton = () => {
    return (
      <Button
        type="button"
        className="btn btn-link"
        onClick={handleBackButtonClick}
        data-testid="standalone-calendar-back-button"
      >
        Back
      </Button>
    );
  };

  // @ts-expect-error TS2538 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const property = propertiesMap[selectedProperty];
  const isUnit = property?.businessType === PropertyBusinessType.UNIT;
  const trimmedPropertyName = trimAndLimitCharactersNumber({
    text: property.name,
    limit: isXS || isSM ? 15 : 25,
  });

  return (
    <HeaderContainer data-testid="calendar-header">
      <h2>
        {isUnit ? (
          <span>{trimmedPropertyName}</span>
        ) : (
          <a
            data-testid="standalone-calendar-property-name"
            href={getPropertyEditPath(property)}
            target="_blank"
            rel="noreferrer"
          >
            {trimmedPropertyName}
          </a>
        )}{' '}
        {t('pageCalendar.standalone.calendar')}
      </h2>
      {totalPropertiesNumber > 1 && (
        <BackProperties>
          {getBackButton()}
          <ButtonContainer
            data-testid="property-dropdown-test-id"
            id="property-dropdown-form"
            schema={propertySchema()}
            horizontal
            defaultValues={{
              property: selectedProperty,
            }}
          >
            <PropertySelectField
              className="btn"
              name="property"
              properties={allProperties}
              enableHotels={false}
              value={selectedProperty}
              onChange={(e: any) => {
                setSelectedProperty(e.target.value);
              }}
            />
          </ButtonContainer>
        </BackProperties>
      )}
      <PropertyChannelsWidget
        propertyName={property.name}
        // @ts-expect-error TS2322 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        propertyUid={selectedProperty}
      />
    </HeaderContainer>
  );
};

export default CalendarHeader;
