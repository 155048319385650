import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import {
  PricingRuleBulkUpdatePayload,
  PricingRuleBulkUpdateResponse,
} from 'models/pricing/PricingRule';
import API from 'services/API';

type BulkUpdatePricingRuleArgs = PricingRuleBulkUpdatePayload & {
  uid: string;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    PricingRuleBulkUpdateResponse,
    unknown,
    BulkUpdatePricingRuleArgs
  >,
  'mutationFn' | 'mutationKey'
>;

const bulkUpdatePricingRule = ({
  uid,
  ...payload
}: BulkUpdatePricingRuleArgs) => {
  return API.put<PricingRuleBulkUpdateResponse, BaseErrorResponse>(
    `/api/internal/pricing-rules/${uid}`,
    payload,
  );
};

const useBulkUpdatePricingRule = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: bulkUpdatePricingRule,
    ...options,
  });
};

export default useBulkUpdatePricingRule;
