import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import useAppUser from 'hooks/useAppUser';
import useAppConfig from 'hooks/useAppConfig';

const userVoiceProperties = {
  // eslint-disable-next-line no-script-url
  href: 'javascript:void(0)',
  'data-uv-lightbox': 'classic_widget',
  'data-uv-mode': 'feedback',
  'data-uv-primary-color': '#2c2ca8',
  'data-uv-link-color': '#3fc59f',
  'data-uv-forum-id': '271343',
};

const useIntercomUserVoice = () => {
  const { user } = useAppUser();
  const { showIntercom } = useAppConfig();
  const { boot } = useIntercom();

  useEffect(() => {
    // loads Intercom and UserVoice

    // Intercom
    if (showIntercom) {
      boot({
        // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
        name: user.firstName,
        company: {
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          companyId: user.agency.uid,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          name: user.agency.name,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          createdAt: user.agency.createdAtEpochSeconds,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          plan: user.agency.pricingPlanType,
        },
        customAttributes: {
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          name: user.firstName,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          email: user.email,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          user_id: user.intercomId,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          user_hash: user.intercomHash,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          created_at: user.created,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          plan: user.agency.pricingPlanType,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          agency: user.agency.name,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          website: user.agency.website,
          // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
          agency_id: user.agency.uid,
          source: 'cluster',
        },
      });
    }

    // UserVoice
    const uv = document.createElement('script');
    uv.type = 'text/javascript';
    uv.async = true;
    uv.src =
      'https://hostfully.uservoice.com/widget_environment/gyzodBYiFk7jcbHcPKLf7A.js';
    document.body.appendChild(uv);

    return () => {
      document.body.removeChild(uv);
    };
  }, []);

  return { userVoiceProperties };
};

export default useIntercomUserVoice;
