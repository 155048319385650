export const ACCEPT_FILE_TYPES = [
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/png',
];

export const ACCEPT_FILE_TYPES_STRING = ACCEPT_FILE_TYPES.join(',');

export const MAX_PHOTOS_LIMIT = 99;
