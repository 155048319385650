import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { queryClient } from 'App';
import * as Yup from 'yup';
import { TextHighlightGreen } from 'components/text/TextHighlightGreen';
import useAppModal from 'hooks/useAppModal';
import Button from '../../../button/Button';
import FormWithProvider from '../../../forms/form/Form';
import TextField from '../../../forms/textField/TextField';
import useChannelSettingsMutations from '../../channelSettings/useChannelSettingsMutations';
import { editExternalLinkModalId } from './PropertyChannelsWidget.constants';

const PropertyChannelsWidgetEditLinkModal = ({
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  channelName,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyName,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyUid,
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  propertyChannelExternalLink,
}) => {
  const { closeModal } = useAppModal();
  const { isUpdating, updateChannelSettings } = useChannelSettingsMutations();
  const { t } = useTranslation();

  const cancelHandler = () => {
    closeModal(editExternalLinkModalId);
  };
  // @ts-expect-error TS7031 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const submitHandler = ({ link }) => {
    updateChannelSettings({
      channelName,
      link,
      propertyUid,
      successCallback: () => {
        queryClient.invalidateQueries([
          'property-channel-settings',
          propertyUid,
        ]);
        closeModal(editExternalLinkModalId);
      },
    });
  };

  return (
    <FormWithProvider
      defaultValues={{ link: propertyChannelExternalLink }}
      onSubmit={submitHandler}
      schema={{ link: Yup.string().required() }}
    >
      <ModalHeader closeButton>
        <ModalTitle>
          <Trans
            i18nKey="componentPropertyChannels.editPropertyUrlModal.title"
            defaults="Set {{channelName}} URL for <highlight>{{propertyName}}</highlight>"
            values={{ channelName, propertyName }}
            components={{ highlight: <TextHighlightGreen /> }}
          />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Row>
          <TextField
            colSmInput={12}
            label={t(
              'componentPropertyChannels.editPropertyUrlModal.linkLabel',
            )}
            labelOnTop
            name="link"
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button bsStyle="default" disabled={isUpdating} onClick={cancelHandler}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" disabled={isUpdating} type="submit">
          {isUpdating ? t('common.updating') : t('common.update')}
        </Button>
      </ModalFooter>
    </FormWithProvider>
  );
};

export default PropertyChannelsWidgetEditLinkModal;
