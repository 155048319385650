import {
  PropertySettingsPricingTabFormDirtyFields,
  PropertySettingsPricingTabFormValues,
} from '../../PropertySettingsPricingTab.schema';
import { PropertyPriceSettingsUpdatePayload } from './PriceSettings.types';

type PropertyPriceSettingsUpdatePayloadWrapper = {
  priceSettings?: PropertyPriceSettingsUpdatePayload;
};

const fieldValuesConverters = {
  priceSettings: {},
};

function appendMutuallyDependentFields(
  payload: PropertyPriceSettingsUpdatePayloadWrapper,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formValues: PropertySettingsPricingTabFormValues,
) {
  return payload;
}

const usePropertyPriceSettingsUpdatePayload = () => {
  return ({
    dirtyFields,
    formValues,
  }: {
    dirtyFields: PropertySettingsPricingTabFormDirtyFields;
    formValues: PropertySettingsPricingTabFormValues;
  }) => {
    const payload: PropertyPriceSettingsUpdatePayloadWrapper = Object.entries(
      dirtyFields,
    ).reduce((acc, [sectionName, sectionDirtyFields]) => {
      return {
        ...acc,
        [sectionName]: Object.entries(sectionDirtyFields)
          .filter(([_, isDirty]) => isDirty)
          .reduce((sectionAcc, [fieldName, isDirtyOrDirtyObject]) => {
            // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
            const fieldValue = formValues[sectionName][fieldName];
            const fieldValueConverter =
              // @ts-expect-error TS7053 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
              fieldValuesConverters[sectionName]?.[fieldName];

            return {
              ...sectionAcc,
              [fieldName]: fieldValueConverter
                ? fieldValueConverter(fieldValue, isDirtyOrDirtyObject)
                : fieldValue,
            };
          }, {}),
      };
    }, {});

    appendMutuallyDependentFields(payload, formValues);

    return payload.priceSettings;
  };
};

export default usePropertyPriceSettingsUpdatePayload;
