import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import DropdownField from 'components/forms/dropdownField/DropdownField';
import { ALL_WEEK_DAYS } from 'models/DayOfWeek';
import PropertyFormFieldLabel from '../../../common/PropertyFormFieldLabel';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import { WeekDayListItemStyled } from './OnlyCheckInOnField.styles';
import useOnlyCheckInOnTitle from './useOnlyCheckInOnTitle';

const WeekDaysCheckboxes = () => {
  const { t } = useTranslation();
  const {
    // @ts-expect-error TS2339 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    bookingSettings: { daysOfTheWeekToCheckInOn },
  } = useWatch<PropertySettingsMainTabFormValues>();

  return (
    <>
      {ALL_WEEK_DAYS.map((weekDay) => (
        <WeekDayListItemStyled
          $isChecked={daysOfTheWeekToCheckInOn?.[weekDay]}
          key={weekDay}
        >
          <CheckboxField
            name={`bookingSettings.daysOfTheWeekToCheckInOn.${weekDay}`}
          >
            {t(`common.dayOfWeek.${weekDay}`)}
          </CheckboxField>
        </WeekDayListItemStyled>
      ))}
    </>
  );
};

const OnlyCheckInOnField = () => {
  const { t } = useTranslation();
  const title = useOnlyCheckInOnTitle();

  return (
    <DropdownField
      DropdownContent={WeekDaysCheckboxes}
      name="bookingSettings.daysOfTheWeekToCheckInOn"
      label={
        <PropertyFormFieldLabel name="bookingSettings.daysOfTheWeekToCheckInOn">
          {t(
            'pageProperty.mainSettings.bookingSettings.daysOfTheWeekToCheckInOn',
          )}
        </PropertyFormFieldLabel>
      }
      title={title}
      colSmInput={7}
      colSmLabel={5}
      rightTooltip={t(
        'pageProperty.mainSettings.bookingSettings.daysOfTheWeekToCheckInOnTooltip',
      )}
      required
    />
  );
};

export default OnlyCheckInOnField;
