import { useCallback, useContext, useEffect, useState } from 'react';
import ModeSwitcher from 'pages/stackedCalendar/header/ModeSwitcher';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import CalendarTodayButton from 'pages/stackedCalendar/header/CalendarTodayButton';
import { CalendarType } from 'models/Calendar';
import LeftArrow from '../../../components/icons/LeftArrow.svg?react';
import RightArrow from '../../../components/icons/RightArrow.svg?react';
import { calendarConstants } from '../Calendar.constant';
import StandaloneCalendarContext from '../CalendarContext';
import {
  ButtonLeftRight,
  Divider,
  NavigationContainer,
  NavigationWrapper,
  ButtonsWrapper,
} from './Navigation.styles';

const Navigation = () => {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  const { calendarFilter, setCalendarFilter } = useContext(
    StandaloneCalendarContext,
  );
  const { t } = useTranslation();
  const { isEmployee } = useAppUser();

  const onPrevious = useCallback(() => {
    const previousStartRange = subMonths(
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      calendarFilter.start,
      calendarConstants.numberOfMonthsToDisplayOnCalendar,
    );
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    calendarFilter.start = startOfMonth(previousStartRange);
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    calendarFilter.end = endOfMonth(
      addMonths(
        previousStartRange,
        calendarConstants.numberOfMonthsToDisplayOnCalendar - 1,
      ),
    );

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setCalendarFilter({ ...calendarFilter });
    return false;
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  }, [calendarFilter.start]);

  const onNext = useCallback(() => {
    const nextStartRange = addMonths(
      // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      calendarFilter.start,
      calendarConstants.numberOfMonthsToDisplayOnCalendar,
    );
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    calendarFilter.start = startOfMonth(nextStartRange);
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    calendarFilter.end = endOfMonth(
      addMonths(
        nextStartRange,
        calendarConstants.numberOfMonthsToDisplayOnCalendar - 1,
      ),
    );

    // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
    setCalendarFilter({ ...calendarFilter });
    return false;
    // @ts-expect-error TS18048 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  }, [calendarFilter.end]);

  useEffect(() => {
    const onToday = () => {
      const today = new Date();
      const startOfToday = startOfMonth(today);
      const endOfSixMonths = endOfMonth(addMonths(today, 5));

      setCalendarFilter({
        start: startOfToday,
        end: endOfSixMonths,
      });
    };

    window.addEventListener('setTodayOnStandaloneFilters', onToday);

    return () => {
      window.removeEventListener('setTodayOnStandaloneFilters', onToday);
    };
  }, [calendarFilter]);

  return (
    <NavigationContainer>
      <NavigationWrapper>
        <ButtonLeftRight
          $hover={leftHover}
          type="button"
          onPointerOver={() => setLeftHover(true)}
          onPointerOut={() => setLeftHover(false)}
          onClick={onPrevious}
          data-testid="calendar-left-arrow"
        >
          <LeftArrow />
        </ButtonLeftRight>

        <div>
          <span data-testid="calendar-filter-date">
            {calendarFilter && calendarFilter.start
              ? t('common.monthFullYear', { date: calendarFilter.start })
              : ''}
          </span>
          <Divider>-</Divider>
          <span>
            {calendarFilter && calendarFilter.end
              ? t('common.monthFullYear', { date: calendarFilter.end })
              : ''}
          </span>
        </div>

        <ButtonLeftRight
          $hover={rightHover}
          type="button"
          onPointerOver={() => setRightHover(true)}
          onPointerOut={() => setRightHover(false)}
          onClick={onNext}
        >
          <RightArrow />
        </ButtonLeftRight>
      </NavigationWrapper>

      <ButtonsWrapper>
        <CalendarTodayButton type={CalendarType.STANDALONE_CALENDAR} />
        {isEmployee && <ModeSwitcher />}
      </ButtonsWrapper>
    </NavigationContainer>
  );
};

export default Navigation;
