import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from '../../../../hooks/useAppMutation';
import API from '../../../../services/API';
import { normalizeKey } from '../../../../utils/localeUtils';
import {
  AgencyMessagingRecipientType,
  MessageState,
  MessagingChannel,
  SendMessageResponseTO,
} from '../../../../models/inbox/Messages';

export interface SendAgencyMessagePayload {
  content: {
    text: HTMLElement;
    subject: string;
  };
  channel: MessagingChannel;
  settings: {
    shouldCopyAgency: boolean;
  };
  recipient: {
    type: AgencyMessagingRecipientType;
    uid: string;
  };
}

enum MessageValidationError {
  EMPTY = 'EMPTY',
}

function validateMessageContent(messageHtmlContent: HTMLElement) {
  if (!messageHtmlContent.innerText.trim()) {
    return MessageValidationError.EMPTY;
  }

  return null;
}

const useSendAgencyMessage = () => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { mutate: sendAgencyMessagesHandler, isLoading: isSending } =
    useAppMutation(
      ['sendAgencyMessagesHandler'],
      (messagePayload: SendAgencyMessagePayload) =>
        API.post(`/v3/agency-messages`, {
          ...messagePayload,
          content: {
            ...messagePayload.content,
            text: messagePayload.content.text.innerHTML,
          },
        }),
      {
        onSuccess: ({
          data: { message },
        }: AxiosResponse<SendMessageResponseTO>) => {
          if (message.state === MessageState.FAILED) {
            setErrorMessage(t('pageInbox.messages.editor.error_SERVER_ERROR'));
            return;
          }

          closeModal('template-modal');
        },
        onError: () => {
          setErrorMessage(t('pageInbox.messages.editor.error_SERVER_ERROR'));
        },
      },
    );

  const sendAgencyMessage = useCallback(
    (messagePayload: SendAgencyMessagePayload) => {
      const validationError = validateMessageContent(
        messagePayload.content.text,
      );
      if (validationError) {
        setErrorMessage(
          t(normalizeKey(`pageInbox.messages.editor.error_${validationError}`)),
        );
        return;
      }

      // @ts-expect-error TS2345 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
      setErrorMessage(null);
      sendAgencyMessagesHandler(messagePayload);
    },
    [],
  );

  return { isSending, errorMessage, sendAgencyMessage };
};

export default useSendAgencyMessage;
